import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-signaddendum',
  templateUrl: './signaddendum.component.html',
  styleUrls: ['./signaddendum.component.css']
})
export class SignaddendumComponent implements OnInit {

  public agrDate: string ;
  public agrName: string;
  public agrSignature: string;
  Alerts: any;
  constructor(
    public _router: Router,
    public _httpClient: HttpClient,
    public r: ActivatedRoute,
  ) {

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    this.agrDate = mm + '/' + dd + '/' + yyyy;

    this.agrName= sessionStorage.getItem("firstname").trim() + ' ' + sessionStorage.getItem("lastname").trim()



    //check for alerts
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/checkalerts.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));

    this._httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        var APIresult: any = result;
        if (result == null) {

        }
        else {
          if (APIresult.success == "1") {
            this.Alerts = APIresult.alerts;
            
           

          }
        }
      },
      error => {

      });


  }

  ngOnInit(): void {
  }

  signAgreement(): void {
    
    if (this.agrName != this.agrSignature) {
        Swal.fire('Signature do not match', 'Your signature should entered exactly as your display name', 'error');
        return;
    }
    
    //check for alerts
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/signalert.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('signature', encodeURIComponent(this.agrSignature));

    this._httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        var APIresult: any = result;
        if (result == null) {

        }
        else {
          if (APIresult.success == "1") {

            Swal.fire('New configuration applied', 'Please login back to your platform.', 'success');
            this._router.navigateByUrl("/login");

          }
        }
      },
      error => {

      });

  }

}
