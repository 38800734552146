<h4>Email Management</h4>
<hr>

<div class="row">
    <div class="col-12 col-md-4">
      
        <p-table #dt [value]="EmailsList" [rows]="25" [showCurrentPageReport]="true" [rowsPerPageOptions]="[25,50,200,1000]" styleClass="p-datatable-customers" [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" [filterDelay]="0"
        [globalFilterFields]="['triggername']" [resizableColumns]="true" [autoLayout]="true" selectionMode="single">

        <ng-template pTemplate="header">
                <tr>
                    <th>
                        <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'triggername', 'contains')" placeholder="Search by Trigger Name">
                    </th>
                </tr>
         
        </ng-template>

        <ng-template pTemplate="body" let-EmailsList let-rowIndex="rowIndex">
            <tr class="FontRow f13" (click)="onRowSelect(EmailsList.triggerid)" style="cursor: pointer;" >
                <td class="SmallFont"><strong>{{EmailsList.triggername}}</strong>
                    <br>
                    <small class="text-muted">{{EmailsList.description}}</small>

                </td>
            </tr>
        </ng-template>
    </p-table>
    </div>
    <div class="col-12 col-md-8">
        <div *ngIf="EmailID" >
        <angular-editor [placeholder]=" 'Enter text here...' " [config]="editorConfig " [(ngModel)]="EmailContent"></angular-editor>
        <br><br>
        <small>Enter SuperUser password:</small>
        <input type="password" class="form-control" [(ngModel)]="superuser" style="max-width:400px" maxlength="40">
        <br>
        <button class="btn btn-primary" (click)="SaveEmail()">Save Email Content</button>
        <br><br> <br><br>
        </div>
    </div>
</div>