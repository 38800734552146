import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-corpagents',
  templateUrl: './corpagents.component.html',
  styleUrls: ['./corpagents.component.css']
})
export class CorpagentsComponent implements OnInit {
  APIresult: any;
  AgentsList: any;
  AgentType: string;
  constructor(
    public _httpClient: HttpClient,
    private _router: Router,
    private r: ActivatedRoute,
  ) {


    this.GetList("10")

  }

  GetList(status: string): void {
    if (status == '0') { this.AgentType = "AFEUSA Approval Pending" }
    if (status == '1') { this.AgentType = "Active" }
    if (status == '99') { this.AgentType = "Suspended" }
    if (status == '10') { this.AgentType = "All Agents" }
    if (status == '50') { this.AgentType = "Agent Confirmation Pending (Agents uploaded by agencies)" }
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/corporate/listagents.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('status', encodeURIComponent(status));

    this._httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.APIresult = Array.of(result);
        if (result == null) {

        }
        else {
          if (this.APIresult[0].success == "1") {
            this.AgentsList = this.APIresult[0].agents;
            return;
          }

        }

      },
      error => {

      });

  }

  onRowSelect(id: string) {
    if (id) {
      sessionStorage.setItem("temp", id)
      this._router.navigate(['../viewagent'], { relativeTo: this.r });
    }

  }


  Open(id: string): void {
    sessionStorage.setItem("temp", id);
    this._router.navigate(['../agentedition'], { relativeTo: this.r });
  }

  TempEnable(id: string): void {



    Swal.fire({
      title: 'Are you sure?',
      text: "Enable Agent?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Enable Agent'
    }).then((result) => {
      if (result.isConfirmed) {

        var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/corporate/EnableAgent.aspx";    // set header for http call
        let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
        // set body
        let body = new URLSearchParams();
        body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
        body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
        body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
        body.set('status', encodeURIComponent(status));
        body.set('pl', encodeURIComponent(id));

        this._httpClient.post(APIURL, body.toString(), options).subscribe(
          result => {
            this.APIresult = Array.of(result);
            if (result == null) {

            }
            else {
              if (this.APIresult[0].success == "1") {
                Swal.fire({
                  title: 'Agent Enabled',
                  text: 'This agent has been enabled, and the welcome email was sent.',
                  icon: 'info',
                });
                return;
              }

            }

          },
          error => {

          });
      }
    })







  }

  ngOnInit(): void {
    this.GetList("10")
  }

}
