import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
 
  public JSONResp;
  public showspinner: boolean = false;
public endpoint: string;
public APIresult: any;
  

  constructor(
    public httpClient: HttpClient,
    private _router: Router,
    private r: ActivatedRoute,
  ) {

    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/setup/viewprofile.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        let APIresult: any = result;
        if (result == null) {
          Swal.fire({
            title: 'Try again',
            text: 'There was an error connecting to our Endpoint. Please try again later.',
            icon: 'error',
          })
          return;
        }
        else {
          if (APIresult.success == "1") {
            this.JSONResp = APIresult;


          }
          else {
            Swal.fire({
              title: 'Something went wrong...',
              text: APIresult[0].message,
              icon: 'error',

            })
          }

        }

      },
      error => {

      });


     


  }





  ngOnInit(): void {
    this.endpoint=sessionStorage.getItem('endpoint');
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/setup/getdocuments.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.APIresult= Array.of(result);
      },
      error => {

      });
  }

}
