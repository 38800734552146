<h4>Invite Prospect</h4>
<hr>
<div class="w100">

    <div class="bShadow-5">
        <br>
        <h5>Enter Prospect Information</h5>
        <hr>
        <div class="row">

            <div class="col-xl-2 col-lg-4 col-sm-6 col-md-4 col-sm-6 col-12">
                <label>First Name</label>
                <input type="text" maxlength=50 class="form-control" [(ngModel)]="firstname">
            </div>
            <div class="col-xl-2 col-lg-4 col-sm-6 col-md-4 col-sm-6 col-12">
                <label>Last Name</label>
                <input type="text" maxlength=50 class="form-control" [(ngModel)]="lastname">
            </div>
            <div class="col-xl-2 col-lg-4 col-sm-6 col-md-4 col-sm-6 col-12">
                <label>Email Address</label>
                <input type="text" maxlength=100 class="form-control" [(ngModel)]="email">
            </div>
            <div class="col-xl-2 col-lg-4 col-sm-6 col-md-4 col-sm-6 col-12">
                <label>Cell Phone</label>
                <input type="text " maxlength=50 class="form-control " mask="(000) 000-0000" [(ngModel)]="cellphone" [dropSpecialCharacters]="false">
            </div>

            <div class="col-xl-3 col-lg-4 col-sm-6 col-md-4 col-sm-6 col-12">
                <label>Select Website for Invite</label>
                <select class="form-control " [(ngModel)]="website">
                        <option value="">+ Select Website</option>
                        <option *ngFor="let site of sites" value="{{site.id}}">{{site.websitename}} - ({{site.url}})</option>
                    

                    </select>

            </div>




            <div class="col-xl-1 col-lg-2 col-sm-3 col-md-2 col-sm-3 col-12">
                <label>Bcc Me </label>
                <select class="form-control" [(ngModel)]="sendemail">
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
            </div>
            <!--
            <div class="col-xl-1 col-lg-2 col-sm-3 col-md-2 col-sm-3 col-12">
                <label>Send SMS</label>
                <select class="form-control" [(ngModel)]="sendsms">
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
            </div>
-->


        </div>
        <br>
    </div>


    <br>
    <button class="btn btn-primary text-white " (click)="submitLead()" style="margin-right: 10px;"><i class="fa fa-user"></i> Save Prospect</button>
    <br>
    <br>
    <br>
    <br> <br>
    <br>
    <br>
    <br>
    <br>
    <br>

</div>


