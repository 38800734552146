import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-clientsdashboard',
  templateUrl: './clientsdashboard.component.html',
  styleUrls: ['./clientsdashboard.component.css']
})
export class ClientsdashboardComponent implements OnInit {

  @Input()
  PL: string;

  ch1data: any;
  ch1labels: any;
  options1: any;

  ch2data: any;
  ch2labels: any;
  options2: any;

  data: any = {
    "personal": {
        "summary": {
            "totalsales": 0,
            "desksales": 0,
            "websitesales": 0,
            "totalsalesdollars": 0,
            "ownsalesdollars": 0,
            "downlinesalesdollars": 0,
            "percDeskSales": 0,
            "percWebsiteSales": 0,
            "percOwnSalesDollars": 0,
            "percDownlineSalesDollars": 0
        },
        "chart1": {
            "labels": "",
            "data": "[]"
        },
        "chart2": {
            "labels": "",
            "data": "[]"
        }
    },
    "sales": {
        "today": {
            "mysalesqty": 0,
            "downlinesalesqty": 0,
            "mysalesusd": 0,
            "downlinesalesusd": 0
        },
        "yesterday": {
            "mysalesqty": 0,
            "downlinesalesqty": 0,
            "mysalesusd": 0,
            "downlinesalesusd": 0
        },
        "thisweek": {
            "mysalesqty": 0,
            "downlinesalesqty": 0,
            "mysalesusd": 0,
            "downlinesalesusd": 0
        },
        "thismonth": {
            "mysalesqty": 0,
            "downlinesalesqty": 0,
            "mysalesusd": 0,
            "downlinesalesusd": 0
        },
        "previousmonth": {
            "mysalesqty": 2,
            "downlinesalesqty": 0,
            "mysalesusd": 0,
            "downlinesalesusd": 0
        },
        "alltime": {
            "mysalesqty": 0,
            "downlinesalesqty": 0,
            "mysalesusd": 0,
            "downlinesalesusd": 0
        }
    }
}


  data2: any;

  

  constructor(
    public httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
  ) {

/*
    this.data2 = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'First Dataset',
          data: [65, 59, 80, 81, 56, 55, 40],
          backgroundColor: "rgba(160,160,160,0.5)",
          borderColor: "#A0A0A0",
          borderWidth: 2,
        },
        {
          label: 'Second Dataset',
          data: [28, 48, 40, 19, 86, 27, 90],
          backgroundColor: "rgba(63,156,208,0.5)",
          borderColor: "#44A2D2",
          borderWidth: 2,

        }
      ]
    }

    this.options1 = {
      title: {
        display: true,
        text: 'Own Sales by Product',
        fontSize: 20
      },
      tooltips: {
        mode: 'index',
        intersect: false
      },
      responsive: true,
      scales: {
        xAxes: [{
          stacked: true,
        }],
        yAxes: [{
          stacked: true
        }]
      },
      legend: {
        position: 'bottom'
      }
    };

    this.options2 = {
      title: {
        display: true,
        text: 'Sales by',
        fontSize: 20
      },
      legend: {
        position: 'bottom'
      }
    };
    */
  }


  ngOnInit(): void {
    
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/clients/analyticsown.aspx";
    var options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    var body = new URLSearchParams();

    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('pl', encodeURIComponent(this.PL));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.data = result;
        //chart 1
        var chart1labels = this.data.personal.chart1.labels.split(',');
        this.ch1data = {
          labels: chart1labels,
          datasets: [
            {
              label: 'Commission Earned',
              data: JSON.parse(this.data.personal.chart1.data),
              backgroundColor: "rgba(63,156,208,0.5)",
              borderColor: "#44A2D2",
              borderWidth: 2,
            }
          ]
        }
        this.options1 = {
          title: {
            display: true,
            text: 'Sales by Product in USD',
            fontSize: 20
          },
          legend: {
            position: 'bottom'
          }
        };

        //chart 2
        var chart2labels = this.data.personal.chart2.labels.split(',');
        this.ch2data = {
          labels: chart2labels,
          datasets: [
            {
              label: 'Quantity Sold',
              data: JSON.parse(this.data.personal.chart2.data),
              backgroundColor: "rgba(63,156,208,0.5)",
              borderColor: "#44A2D2",
              borderWidth: 2,
            }
          ]
        }
        this.options2 = {
          title: {
            display: true,
            text: 'Sales by Product (Qty)',
            fontSize: 20
          },
          legend: {
            position: 'bottom'
          }
        };

      },
      error => {

      });
  }

ngOnChanges() {
  this.ngOnInit();
}
}
