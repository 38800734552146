<h4>Agents</h4>

<hr>

<button [ngClass]="{ 'btn-dark text-white': AgentType == 'All Agents' }" (click)="GetList('10')" class="btn btn-outline-dark " style="margin-right: 10px; margin-top:10px;">All Agents</button>
<button [ngClass]="{ 'btn-dark text-white': AgentType == 'AFEUSA Approval Pending' }" (click)="GetList('0')" class="btn btn-outline-dark " style="margin-right: 10px; margin-top:10px;">AFEUSA Approval Pending</button>
<button [ngClass]="{ 'btn-dark text-white': AgentType == 'Agent Confirmation Pending (Agents uploaded by agencies)' }" (click)="GetList('50')" class="btn btn-outline-dark " style="margin-right: 10px; margin-top:10px;">Agent Confirmation Pending</button>
<button [ngClass]="{ 'btn-dark text-white': AgentType == 'Active' }" (click)="GetList('1')" class="btn btn-outline-dark " style="margin-right: 10px; margin-top:10px;">Active</button>
<button [ngClass]="{ 'btn-dark text-white': AgentType == 'Suspended' }" (click)="GetList('99')" class="btn btn-outline-dark " style="margin-right: 10px; margin-top:10px;">Suspended</button>
<br><br><br>
<h5>Listing {{AgentType}}</h5>
<hr>
<br>
<div class="minheight">
    <p-table #dt [value]="AgentsList" [rows]="25" [showCurrentPageReport]="true" [rowsPerPageOptions]="[25,50,200,1000]" styleClass="p-datatable-customers" [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} agents" [filterDelay]="0"
        [globalFilterFields]="['fullname','signupdate', 'email', 'phone','downline','policies', 'status', 'upline']" [resizableColumns]="true" [autoLayout]="true" selectionMode="single">

        <ng-template pTemplate="header">
            <tr>
                <th></th>
                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'fullname', 'contains')" placeholder="Search by name">
                </th>
                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'signupdate', 'contains')" placeholder="Search by date">
                </th>

                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'email', 'contains')" placeholder="Search by email">
                </th>

                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'phone', 'contains')" placeholder="Search by phone">
                </th>
                <th> </th>

                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'upline', 'contains')" placeholder="Search by upline">
                </th>

                <th> </th>



            </tr>
            <tr class="FontRow f13">
                <th></th>
                <th [pSortableColumn]="'fullname'">Agent Name</th>
                <th [pSortableColumn]="'signupdate'">Signup Date</th>
                <th [pSortableColumn]="'state'">Email</th>
                <th [pSortableColumn]="'phone'">Phone</th>
                <th [pSortableColumn]="''">Q&A Review</th>
                <th [pSortableColumn]="'upline'">Upline</th>
                <th [pSortableColumn]="''">Action</th>

            </tr>

        </ng-template>




        <ng-template pTemplate="body" let-AgentsList let-rowIndex="rowIndex">
            <tr class="FontRow f13">
                <td class="SmallFont">{{rowIndex +1}}</td>
                <td class="SmallFont">{{AgentsList.fullname.toUpperCase()}}</td>
                <td class="SmallFont">{{AgentsList.signupdate}}</td>
                <td class="SmallFont"><a target="_blank" href="mailto:{{AgentsList.email}}" class="btn btn-sm btn-warning text-white"><i class="fa fa-envelope"></i></a> {{AgentsList.email}}</td>
                <td class="SmallFont">{{AgentsList.phone}}</td>
                <td class="SmallFont">{{AgentsList.numyes}}</td>
                <td class="SmallFont">{{AgentsList.upline}}</td>
                <td class="SmallFont"><button class="btn btn-sm btn-primary text-white" (click)="Open(AgentsList.id)"><i class="fa fa-folder"></i> OPEN</button></td>
            </tr>
        </ng-template>
    </p-table>
</div>