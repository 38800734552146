import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-selectproducts',
  templateUrl: './selectproducts.component.html',
  styleUrls: ['./selectproducts.component.css']
})
export class SelectproductsComponent implements OnInit {
  @Input() Data: any;

  constructor() { }

  ngOnInit(): void {
  }

  Checked(e): void {
    //document.getElementById('prod')

    var prod = e.id;
    prod = prod.replace("check", "prod")
    var prodid = prod.replace("prod", "")
    var secondProduct: string
    

    for (let product of this.Data.available) {
      if (product.productid == prodid || product.productid==secondProduct) {
        if (e.checked == true) {
          product.selected = 1;
        }
        else {
          product.selected = 0;
        }
      }
    }
  }

}
