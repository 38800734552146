<!--
  <div
  class="IntroDiv text-center"
  style="
    padding-left: 60px;
    background-image: url(../../assets/background.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  "
>
-->
<div class="IntroDiv text-center">


  <img  src="assets/afelogorealwhite.png" style="width:300px; margin-top: 300px;">


  <br>
  <h5 style="color:#e1e1e1">Hello {{username}}, Welcome to your AFEUSA Desk</h5>
  <span style="color:#e1e1e1">
    Use the options located on the left of the screen to access your platform features
  </span>
  <br>
  <div style="max-width: 80%; margin: 0 auto">
    <hr>
   
    <div class="row" *ngIf="accstatus == '50'">
      <div class="col-12 text-center monoicons text-white mt1">
        <a routerLink="../activate" class="text-white"><img src="assets/icons/talk.png"
            style="width: 70px; margin-bottom: 10px" pTooltip="Activate Account" />
          <br />ACTIVATE YOUR ACCOUNT
        </a>
      </div>
    </div>
    <br /><br />
  </div>
  <div *ngIf="accstatus != '50'" >
    <div id="dock-container" style="display: inline-block; ">
      <div id="dock">
        <ul>

          <li>
            <span>Invite Prospect</span>
            <a routerLink="../crm/leads"><img src="assets/icons/invite2.png">
            </a>
          </li>
          <li *ngIf="application">
            <span>Invite Agent</span>
            <a routerLink="../agents/newagent"><img src="assets/icons/invite.png"></a>
          </li>
          <li *ngIf="application2">
            <span>Member Application</span>
            <a routerLink="../crm/membership"><img src="assets/icons/newapplication.png"></a>
          </li>

          
          <li *ngIf="application">
            <span>Agent Invite Codes</span>
            <a routerLink="../agents/agentscodesmanager/customlist"><img src="assets/icons/signup.png"></a>
          </li>
          <li>
            <span>Contracted Members</span>
            <a routerLink="../clients/contractedclients"><img src="assets/icons/doc2.png"></a>
          </li>
          <li *ngIf="application">
            <span>Contracted Agents</span>
            <a routerLink="../agents/agentshierarchy"><img src="assets/icons/doc3.png"></a>
          </li>

          <li>
            <span>Personal Notes</span>
            <a routerLink="../tools/notesmodule"><img src="assets/icons/postit.png"></a>
          </li>

        </ul>
      </div>
    </div>
  </div>
</div>


<!--FOOTER-->
<div id="xfooter">
  <div class="row">
    <div class="col-md-8">

      <span style="font-size: 14px; color:#ffffff;">CopyRight 2021 AFEUSA. All Rights Reserved.</span>
      <br>
      <span style="font-size: 12px; color:#c1c1c1;">AFEUSA Desk Version {{version}}</span>
    </div>
    <div class="col-md-4 text-right">
      <a href="https://www.henkomoss.com" target="_blank">
        <img src="assets/henkologo.png" style="height: 35px; padding-right: 100px; padding-top:5px" />
      </a>
    </div>
  </div>
</div>