<div>
    <div class="row">
        <div class="col-md-8">
            <h4>Sales Summary</h4>
        </div>
      
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-6 col-md-4">
            <div class="productbox">
                <div>
                    <div class="boxtitle"><i class="fa fa-user"></i> Total Sales</div>
                    <span class="superfont">{{+data?.personal.summary.totalsales}}</span>
                    <br><span class="smallfont">100% of sales</span>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-md-4">
            <div class="productboxlight">
                <div>
                    <div class="boxtitlelight"><i class="fa fa-user"></i> Desk Sales</div>
                    <span class="superfontlight">{{+data?.personal.summary.desksales}}</span>
                    <br><span class="smallfontlight">{{+data?.personal.summary.percDeskSales | number:'1.2-2'}}% of
                        sales</span>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-md-4">
            <div class="productboxlight">
                <div>
                    <div class="boxtitlelight"><i class="fa fa-user"></i> Website Sales</div>
                    <span class="superfontlight">{{+data?.personal.summary.websitesales}}</span>
                    <br><span class="smallfontlight">{{+data?.personal.summary.percWebsiteSales | number:'1.2-2'}}% of
                        sales</span>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-md-4">
            <div class="productbox">
                <div>
                    <div class="boxtitle"><i class="fa fa-user"></i> Total Commission</div>
                    <span class="superfont">${{+data?.personal.summary.totalsalesdollars | number:'1.2-2'}}</span>
                    <br><span class="smallfont">100% of commission</span>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-md-4">
            <div class="productboxlight">
                <div>
                    <div class="boxtitlelight"><i class="fa fa-user"></i> Own Sales Commission</div>
                    <span class="superfontlight">${{+data?.personal.summary.ownsalesdollars | number:'1.2-2'}}</span>
                    <br><span class="smallfontlight">{{+data?.personal.summary.percOwnSalesDollars | number:'1.2-2'}}% of
                        sales</span>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-md-4">
            <div class="productboxlight">
                <div>
                    <div class="boxtitlelight"><i class="fa fa-user"></i> Downline Sales Commission</div>
                    <span class="superfontlight">${{+data?.personal.summary.downlinesalesdollars | number:'1.2-2'}}</span>
                    <br><span
                        class="smallfontlight">{{+data?.personal.summary.percDownlineSalesDollars | number:'1.2-2'}}% of
                        sales</span>
                </div>
            </div>
        </div>
    </div>

    <br><br>
    <h4>Global Summary</h4>
    <hr>
    <br>
    <table class="table table-bordered w100">
        <tr class="">
            <td></td>
            <td class="tabletitle text-white" style="background-color: #3e669c;">All Time
                <div class="myNumber">${{+data?.sales.alltime | number:'1.2-2'}}</div>
            </td>
            <td class="tabletitle text-white" style="background-color: #3e669c;">Previous Month
                <div class="myNumber">${{+data?.sales.previousmonth | number:'1.2-2'}}</div>
            </td>
            <td class="tabletitle bg-info text-white">This Month
                <div class="myNumber">${{+data?.sales.thismonth | number:'1.2-2'}}</div>
            </td>
            <td class="tabletitle bg-info text-white">This Week
                <div class="myNumber">${{+data?.sales.thisweek | number:'1.2-2'}}</div>
            </td>
            <td class="tabletitle bg-info text-white">Yesterday
                <div class="myNumber">${{+data?.sales.yesterday | number:'1.2-2'}}</div>
            </td>
            <td class="tabletitle bg-info text-white">Today
                <div class="myNumber">${{+data?.sales.today | number:'1.2-2'}}</div>
            </td>
        </tr>

        <tr *ngFor="let record of data.salessummary" [ngStyle]="{'background-color': +record?.bgcolor}">
            <td class="tabletitle">{{record?.type}}</td>
            <td class="text-right margin-30">{{record?.details.alltime}}</td>
            <td class="text-right margin-30">{{record?.details.previousmonth}}</td>
            <td class="text-right margin-30">{{record?.details.thismonth}}</td>
            <td class="text-right margin-30">{{record?.details.thisweek}}</td>
            <td class="text-right margin-30">{{record?.details.yesterday}}</td>
            <td class="text-right margin-30">{{record?.details.today}}</td>
        </tr>


    </table>




    <br><br>
    <div class="row">
        <div class="col-12 col-md-8 col-lg-10">
            <h4>Products Sales Summary</h4>
        </div>
        <div class="col-12 col-md-4 col-lg-2 text-right">
           
        </div>
    </div>
   
    <hr>
    <br>
    <table class="table table-bordered w100">
        <tr class="">
            <td></td>
            <td class="tabletitle text-white" style="background-color: #3e669c;">All Time
                <div class="myNumber">${{+data?.sales.alltime | number:'1.2-2'}}</div>
            </td>
            <td class="tabletitle text-white" style="background-color: #3e669c;">Previous Month
                <div class="myNumber">${{+data?.sales.previousmonth | number:'1.2-2'}}</div>
            </td>
            <td class="tabletitle bg-info text-white">This Month
                <div class="myNumber">${{+data?.sales.thismonth | number:'1.2-2'}}</div>
            </td>
            <td class="tabletitle bg-info text-white">This Week
                <div class="myNumber">${{+data?.sales.thisweek | number:'1.2-2'}}</div>
            </td>
            <td class="tabletitle bg-info text-white">Yesterday
                <div class="myNumber">${{+data?.sales.yesterday | number:'1.2-2'}}</div>
            </td>
            <td class="tabletitle bg-info text-white">Today
                <div class="myNumber">${{+data?.sales.today | number:'1.2-2'}}</div>
            </td>
        </tr>

        <tr *ngFor="let product of data.products">
            <td class="tabletitle">{{product.productname}}</td>
            <td class="text-right margin-30">${{product.payouts.alltime| number:'1.2-2'}}<br><small class="text-primary">{{product.quantities.alltime}} sales</small></td>
            <td class="text-right margin-30">${{product.payouts.previousmonth| number:'1.2-2'}}<br><small class="text-primary">{{product.quantities.previousmonth}} sales</small></td>
            <td class="text-right margin-30">${{product.payouts.thismonth| number:'1.2-2'}}<br><small class="text-primary">{{product.quantities.thismonth}} sales</small></td>
            <td class="text-right margin-30">${{product.payouts.thisweek| number:'1.2-2'}}<br><small class="text-primary">{{product.quantities.thisweek}} sales</small></td>
            <td class="text-right margin-30">${{product.payouts.yesterday| number:'1.2-2'}}<br><small class="text-primary">{{product.quantities.yesterday}} sales</small></td>
            <td class="text-right margin-30">${{product.payouts.today| number:'1.2-2'}}<br><small class="text-primary">{{product.quantities.today}} sales</small></td>
        </tr>


    </table>




    <br><br>
    <h4>Own Production Ranking</h4>
    <hr>
    <div class="row">
        <div class="col-md-6">
            <p-chart type="bar" [data]="ch1data" [options]="options1"></p-chart>
        </div>

        <div class="col-md-6">
            <p-chart type="bar" [data]="ch2data" [options]="options2"></p-chart>
        </div>
    </div>
    <hr>

</div>

<br><br><br><br>