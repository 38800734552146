import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agentsdashboard',
  templateUrl: './agentsdashboard.component.html',
  styleUrls: ['./agentsdashboard.component.css']
})
export class AgentsdashboardComponent implements OnInit {
  data: any;
  data2: any;

  options: any;
  options2: any;

  constructor() {
    
  }


  ngOnInit(): void {
  }

}
