<div>
    <br>

    <div class="h2">
        <div>Hooray Health</div>
    </div>
    <hr>
    <br><br>

    <div class="row">
        <div class="col-md-3 text-center">
            <img src="assets/carriers/hooray.png" style="width: 150px;">
        </div>
        <div class="col-md-9">
            Sometimes, life gets in the way. You come down with a nasty cold that you think might be the flu. You take a
            spill on the trails and believe you broke your wrist. Things happen, and medical care is expensive. But not
            if you have Hooray Health Providers! The Hooray Health Mobile App puts quality healthcare in the palm of
            your hand.

        </div>
    </div>
    <br><br>
    <div class="table-responsive" style="font-size: 14px">
        <table class="table">
          
            <tr>
                <td colspan="5" class="bg-cian">
                    <h4 class="text-white">
                        Hooray Health Advantage Plan Summary
                    </h4>
                </td>
            </tr>
            <tr class="">
                <td style="width: 20%">

                </td>
                <td style="width: 20%" class="text-center">
                    Hooray Health
                    <h5>Advantage Basic</h5>
                </td>
                <td style="width: 20%" class="text-center">
                    Hooray Health
                    <h5>Advantage Plus</h5>
                </td>
                <td style="width: 20%" class="text-center">
                    Hooray Health
                    <h5>Advantage Premium</h5>
                </td>
                <td style="width: 20%" class="text-center">
                    Hooray Health
                    <h5>Advantage Ultra</h5>
                </td>
            </tr>
            <tr style="background-color: #0c7dad; color: white;">
                <td class="text-left">OUTPATIENT SICK VISIT BENEFITS
                    <br>URGENT CARE/RETAIL CLINIC OFFICE VISITS*
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td class="text-left">Hooray Health Network includes
                    Office Visit + In-House lab test, X-Rays, etc.
                </td>
                <td class="text-center">Member Pays $25 copay<br>
                    Plan Pays $175<br>
                    <strong>No Balance Bills*</strong>
                </td>
                <td class="text-center">Member Pays $25 copay<br>
                    Plan Pays $175<br>
                    <strong>No Balance Bills*</strong></td>
                <td class="text-center">Member Pays $25 copay<br>
                    Plan Pays $175<br>
                    <strong>No Balance Bills*</strong></td>
                <td class="text-center">Member Pays $25 copay<br>
                    Plan Pays $175<br>
                    <strong>No Balance Bills*</strong></td>
            </tr>

            <tr>
                <td class="text-left">First Health Network Provider
                    (Discounted Rates)**
                </td>
                <td class="text-center">Plan Pays $175</td>
                <td class="text-center">Plan Pays $175</td>
                <td class="text-center">Plan Pays $175</td>
                <td class="text-center">Plan Pays $175</td>
            </tr>

            <tr>
                <td class="text-left">Out-of-Network Provider
                    (No Discounts)***
                </td>
                <td class="text-center">Plan Pays $175</td>
                <td class="text-center">Plan Pays $175</td>
                <td class="text-center">Plan Pays $175</td>
                <td class="text-center">Plan Pays $175</td>
            </tr>

            <tr>
                <td class="text-left"><strong>Maximum urgent care or retail clinic visits</strong></td>
                <td class="text-center"><strong>2 per year</strong></td>
                <td class="text-center"><strong>3 per year</strong></td>
                <td class="text-center"><strong>3 per year</strong></td>
                <td class="text-center"><strong>4 per year</strong></td>
            </tr>
            <tr style="background-color: #0c7dad; color: white;">
                <td class="text-left">OUTPATIENT PHYSICIAN OFFICE VISITS
                </td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
            </tr>
            <tr>
                <td class="text-left">Outpatient Doctor Visit (First Health Provider Network or
                    Out-of-Network Provider)</td>
                <td class="text-center">$100 per day</td>
                <td class="text-center">$100 per day</td>
                <td class="text-center">$100 per day</td>
                <td class="text-center">$100 per day</td>

            </tr>

            <tr>
                <td class="text-left">Maximum Annual Provider
                    Visits</td>
                <td class="text-center"><strong>2 per year</strong></td>
                <td class="text-center"><strong>3 per year</strong></td>
                <td class="text-center"><strong>3 per year</strong></td>
                <td class="text-center"><strong>4 per year</strong></td>
            </tr>
            <tr style="background-color: #0c7dad; color: white;">
                <td class="text-left">ANNUAL WELLNESS VISIT
                </td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
            </tr>
            <tr>
                <td class="text-left">Wellness Visit Benefit amount per day</td>
                <td class="text-center">$180 per day x1 day per year</td>
                <td class="text-center">$180 per day x1 day per year</td>
                <td class="text-center">$180 per day x1 day per year</td>
                <td class="text-center">$180 per day x1 day per year</td>
            </tr>
            <tr style="background-color: #0c7dad; color: white;">
                <td class="text-left">IMAGING AND LAB TEST
                </td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
            </tr>
            <tr>
                <td class="text-left">Diagnostic Laboratory Indemnity Benefit</td>
                <td class="text-center">$50 per day x 2 days</td>
                <td class="text-center">$50 per day x 3 days</td>
                <td class="text-center">$50 per day x 3 days</td>
                <td class="text-center">$50 per day x 4 days</td>
            </tr>

            <tr>
                <td class="text-left">Diagnostic X-Ray Indemnity Benefit</td>
                <td class="text-center">$50 per day x 2 days</td>
                <td class="text-center">$50 per day x 2 days</td>
                <td class="text-center">$50 per day x 2 days</td>
                <td class="text-center">$50 per day x 2 days</td>
            </tr>

            <tr>
                <td class="text-left">Diagnostic Exam Indemnity Benefit</td>
                <td class="text-center">N/A</td>
                <td class="text-center">$350 per day x 1 day</td>
                <td class="text-center">$350 per day x 1 day</td>
                <td class="text-center">$350 per day x 1 day</td>
            </tr>
            <tr style="background-color: #0c7dad; color: white;">
                <td class="text-left">INPATIENT BENEFITS
                </td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
            </tr>
            <tr>
                <td class="text-left">Hospital Admission Benefit</td>
                <td class="text-center">N/A</td>
                <td class="text-center">$750 per day x 1 day</td>
                <td class="text-center">$1,000 per day x 1 day</td>
                <td class="text-center">$1,500 per day x 1 day</td>
            </tr>

            <tr>
                <td class="text-left">In-Hospital Indemnity Benefit</td>
                <td class="text-center">$50 per day x 1 day</td>
                <td class="text-center">$750 per day x 5 days</td>
                <td class="text-center">$1,000 per day x 5 days</td>
                <td class="text-center">$1,500 per day x 5 days</td>
            </tr>

            <tr>
                <td class="text-left">Surgery Benefit</td>
                <td class="text-center">N/A</td>
                <td class="text-center">$750 per day x 1 day</td>
                <td class="text-center">$1,500 per day x 1 day</td>
                <td class="text-center">$2,000 per day x 1 day</td>
            </tr>

            <tr>
                <td class="text-left">Anesthesia Benefit</td>
                <td class="text-center">N/A</td>
                <td class="text-center">$150 per day x 1 day</td>
                <td class="text-center">$350 per day x 1 day</td>
                <td class="text-center">$500 per day x 1 day</td>
            </tr>
            <tr style="background-color: #0c7dad; color: white;">
                <td class="text-left">ACCIDENT BENEFITS<br>
                    (INPATIENT AND OUTPATIENT)<br>
                    ACCIDENT MEDICAL EXPENSE BENEFIT
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            
            <tr>
                <td class="text-left">Maximum Benefit per year</td>
                <td class="text-center">Up to $5,000 per year</td>
                <td class="text-center">Up to $5,000 per year</td>
                <td class="text-center">Up to $10,000 per year</td>
                <td class="text-center">Up to $10,000 per year</td>
            </tr>

            <tr>
                <td class="text-left">Annual Deductible</td>
                <td class="text-center">$0 Deductible</td>
                <td class="text-center">$0 Deductible</td>
                <td class="text-center">$0 Deductible</td>
                <td class="text-center">$0 Deductible</td>
            </tr>

            <tr style="background-color: #0c7dad; color: white;">
                <td class="text-left">ACCIDENTAL DEATH COVERAGE
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td class="text-left">Principal Sum</td>
                <td class="text-center">$1,000</td>
                <td class="text-center">$1,000</td>
                <td class="text-center">$1,000</td>
                <td class="text-center">$1,000</td>
            </tr>

            <tr style="background-color: #0c7dad; color: white;">
                <td class="text-left">NON-INSURANCE SERVICES<sup>(1)</sup>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td class="text-left">Hooray Health Medical Concierge</td>
                <td class="text-center">Included</td>
                <td class="text-center">Included</td>
                <td class="text-center">Included</td>
                <td class="text-center">Included</td>
            </tr>

            <tr>
                <td class="text-left">Telemedicine Doctor (MyTelemedicine)</td>
                <td class="text-center">$0 consult; unlimited visits</td>
                <td class="text-center">$0 consult; unlimited visits</td>
                <td class="text-center">$0 consult; unlimited visits</td>
                <td class="text-center">$0 consult; unlimited visits</td>
            </tr>

            <tr>
                <td class="text-left">Discount Prescription Program (ScriptSave WellRX)</td>
                <td class="text-center">Included</td>
                <td class="text-center">Included</td>
                <td class="text-center">Included</td>
                <td class="text-center">Included</td>
            </tr>

            <tr>
                <td class="text-left">Discount Radiology (Green Imaging)</td>
                <td class="text-center">Included</td>
                <td class="text-center">Included</td>
                <td class="text-center">Included</td>
                <td class="text-center">Included</td>
            </tr>

            <tr style="background-color: #0c7dad; color: white;">
                <td class="text-left">MONTHLY RATES
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>

            <tr *ngFor="let record of matrix; let row = index">
                <td class="myblue">{{record.typename}} </td>
                <td class="text-center"><a
                        (click)="View(row+1, 1, record.col1, 'Advantage Basic - '+ record.typename); LightBox=true; PlanName='Advantage Basic - '+ record.typename; Fee=record.col1"
                        [ngClass]="{'btn-success': SelectedRowCol === record.row + '1'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col1}}<br><small>View
                            Plan Details</small></a>
                </td>
                <td class="text-center"><a
                        (click)="View(row+1, 2, record.col2, 'Advantage Plus - '+ record.typename); LightBox=true; PlanName='Advantage Plus - '+record.typename; Fee=record.col2"
                        [ngClass]="{'btn-success': SelectedRowCol ===  record.row + '2'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col2}}<br><small>View
                            Plan Details</small></a>
                </td>
                <td class="text-center"><a
                        (click)="View(row+1, 3, record.col3, 'Advantage Premium - '+record.typename); LightBox=true; PlanName='Advantage Premium - '+ record.typename;  Fee=record.col3"
                        [ngClass]="{'btn-success': SelectedRowCol === record.row + '3'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col3}}<br><small>View
                            Plan Details</small></a>
                </td>
                <td class="text-center"><a
                        (click)="View(row+1, 4, record.col4, 'Advantage Ultra - '+record.typename); LightBox=true; PlanName='Advantage Ultra - '+ record.typename; Fee=record.col4"
                        [ngClass]="{'btn-success': SelectedRowCol === record.row + '4'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col4}}<br><small>View
                            Plan Details</small></a>
                </td>
            </tr>




        </table>
        <br>
        <br /><br />
        <small>

            (1)THE SERVICES DESCRIBED ARE NOT INSURANCE AND ARE NOT PROVIDED BY AN INSURANCE COMPANY.
            <br>
            <strong>Pre-existing condition limitations:</strong> There is a 10 month pregnancy limitation period.
            <br>
            <strong>For association business for the Advantage plan, the age range is 18-64.</strong>
            <br>
            The Insurance benefits described above are underwritten by Zurich American Insurance Company, 1299 Zurich
            Way, Schaumburg, IL 60196, 1-800-987-3373. This document provides a general description of certain
            provisions and features of this insurance program and does not revise or amend the applicable policies. In
            the event of a discrepancy between this document and your certificate of insurance or the group policy, the
            terms of the group policy shall apply. All benefits are subject to the terms and conditions of the group
            policy. Please refer to your Certificate of Insurance for a detailed description of the insurance coverage,
            including the exclusions, limitations, reductions and termination.
            <br>
            Coverage may not be available in all states or certain terms, conditions and exclusions may be different
            where required by state law. This insurance provides limited benefits. Limited benefits plans are insurance
            products with reduced benefits and are not intended to be an alternative, it is intended to help supplement
            Comprehensive coverage. This insurance does not provide major medical or comprehensive medical coverage and
            is not designed to replace major medical insurance. Further, this insurance is not minimum essential
            benefits as set forth under the Patient Protection and Affordable Care Act.
            <br>
            *Members $25 payment only applies to sickness visits performed at a Hooray Health’s in-network provider. No
            balance billing applies to covered services received at Hooray Health’s in-network retail clinic and urgent
            care centers.
            <br>
            **First Health Network contracted providers can be found at <a href="https://hoorayhealthcare.com/FHN" class="text-primary" target="_blank">hoorayhealthcare.com/FHN</a>. Discounted rates will
            be applied after services are rendered at physician’s office through the Third Party Administrator. Member
            will be responsible for any payment balance above the plan payment of $175. Please see plan policy for
            details.
            <br>
            ***Out-of-Network provider visits are paid $175 per the plan policy. Member will be responsible for any
            payment balance above the plan payment of $175. Please see plan policy for details.






            <br><br>
        </small>
    </div>












</div>


<br><br>










<!--*******************************-->
<!--*******************************-->
<!--*******************************-->
<!--LIGHTBOXES-->
<!--*******************************-->
<!--*******************************-->
<!--*******************************-->
<p-dialog [(visible)]="LightBox" modal="true" [draggable]="false" [closeOnEscape]="true" [responsive]="true"
    [style]="{width: '80%'}" [baseZIndex]="100">

    <p-header style="width:100%">
        <h3>{{PlanName}}</h3>
        <p>Benefits and plans are offered by Dylan Consulting, LLC, a licensed independent insurance agency, and any of
            its subagents, and underwritten by licensed insurance companies and registered associations. Not all
            products are available in all states.
        </p>
    </p-header>
    <div style="overflow-y: scroll; overflow-x: scroll; height:40vh; width: 100%;">
        <div class="table-responsive" style="font-size: 14px">
            <table class="table">
                <tr>
                    <td colspan="5" class="bg-cian">
                        <h4 class="text-white">
                            Hooray Health Advantage Plan Summary
                        </h4>
                    </td>
                </tr>
                <tr style="background-color: #f1f1f1;">
                    <td style="width: 20%">

                    </td>
                    <td style="width: 20%" class="text-center" *ngIf="SelectedCol==1">
                        Hooray Health
                        <br>Advantage Basic
                    </td>
                    <td style="width: 20%" class="text-center" *ngIf="SelectedCol==2">
                        Hooray Health
                        <br>Advantage Plus
                    </td>
                    <td style="width: 20%" class="text-center" *ngIf="SelectedCol==3">
                        Hooray Health
                        <br>Advantage Premium
                    </td>
                    <td style="width: 20%" class="text-center" *ngIf="SelectedCol==4">
                        Hooray Health
                        <br>Advantage Ultra
                    </td>
                </tr>
                <tr>
                    <td class="text-left">Hooray Health Network includes
                        Office Visit + In-House lab test, X-Rays, etc
                    </td>
                    <td class="text-center" *ngIf="SelectedCol==1">Member Pays $25 copay<br>
                        <strong>No Balance Bills</strong>
                    </td>
                    <td class="text-center" *ngIf="SelectedCol==2">Zero Deductible</td>
                    <td class="text-center" *ngIf="SelectedCol==3">Zero Deductible</td>
                    <td class="text-center" *ngIf="SelectedCol==4">Zero Deductible</td>
                </tr>

                <tr>
                    <td class="text-left">First Health Network Provider
                        (Discounted Rates)
                    </td>
                    <td class="text-center" *ngIf="SelectedCol==1">Plan Pays $175</td>
                    <td class="text-center" *ngIf="SelectedCol==2">Plan Pays $175</td>
                    <td class="text-center" *ngIf="SelectedCol==3">Plan Pays $175</td>
                    <td class="text-center" *ngIf="SelectedCol==4">Plan Pays $175</td>
                </tr>

                <tr>
                    <td class="text-left">Out-of-Network Provider
                        (No Discounts)
                    </td>
                    <td class="text-center" *ngIf="SelectedCol==1">Plan Pays $175</td>
                    <td class="text-center" *ngIf="SelectedCol==2">Plan Pays $175</td>
                    <td class="text-center" *ngIf="SelectedCol==3">Plan Pays $175</td>
                    <td class="text-center" *ngIf="SelectedCol==4">Plan Pays $175</td>
                </tr>

                <tr>
                    <td class="text-left"><strong>Maximum urgent care or retail clinic visits</strong></td>
                    <td class="text-center" *ngIf="SelectedCol==1"><strong>2 per year</strong></td>
                    <td class="text-center" *ngIf="SelectedCol==2"><strong>3 per year</strong></td>
                    <td class="text-center" *ngIf="SelectedCol==3"><strong>3 per year</strong></td>
                    <td class="text-center" *ngIf="SelectedCol==4"><strong>4 per year</strong></td>
                </tr>

                <tr>
                    <td class="text-left">Outpatient Doctor Visit (First Health Provider Network or
                        Out-of-Network Provider)</td>
                    <td class="text-center" *ngIf="SelectedCol==1">$100 per day</td>
                    <td class="text-center" *ngIf="SelectedCol==2">$100 per day</td>
                    <td class="text-center" *ngIf="SelectedCol==3">$100 per day</td>
                    <td class="text-center" *ngIf="SelectedCol==4">$100 per day</td>

                </tr>

                <tr>
                    <td class="text-left">Maximum annual <strong>Outpatient</strong> Physician provider
                        visits</td>
                    <td class="text-center" *ngIf="SelectedCol==1"><strong>2 per year</strong></td>
                    <td class="text-center" *ngIf="SelectedCol==2"><strong>3 per year</strong></td>
                    <td class="text-center" *ngIf="SelectedCol==3"><strong>3 per year</strong></td>
                    <td class="text-center" *ngIf="SelectedCol==4"><strong>4 per year</strong></td>
                </tr>

                <tr>
                    <td class="text-left">Wellness Visit Benefit amount per day</td>
                    <td class="text-center" *ngIf="SelectedCol==1">$180 per day x1 day per year</td>
                    <td class="text-center" *ngIf="SelectedCol==2">$180 per day x1 day per year</td>
                    <td class="text-center" *ngIf="SelectedCol==3">$180 per day x1 day per year</td>
                    <td class="text-center" *ngIf="SelectedCol==4">$180 per day x1 day per year</td>
                </tr>

                <tr>
                    <td class="text-left">Diagnostic Laboratory Indemnity Benefit</td>
                    <td class="text-center" *ngIf="SelectedCol==1">$50 per day x 2 days</td>
                    <td class="text-center" *ngIf="SelectedCol==2">$50 per day x 3 days</td>
                    <td class="text-center" *ngIf="SelectedCol==3">$50 per day x 3 days</td>
                    <td class="text-center" *ngIf="SelectedCol==4">$50 per day x 4 days</td>
                </tr>

                <tr>
                    <td class="text-left">Diagnostic X-Ray Indemnity Benefit</td>
                    <td class="text-center" *ngIf="SelectedCol==1">$50 per day x 2 days</td>
                    <td class="text-center" *ngIf="SelectedCol==2">$50 per day x 2 days</td>
                    <td class="text-center" *ngIf="SelectedCol==3">$50 per day x 2 days</td>
                    <td class="text-center" *ngIf="SelectedCol==4">$50 per day x 2 days</td>
                </tr>

                <tr>
                    <td class="text-left">Diagnostic Exam Indemnity Benefit</td>
                    <td class="text-center" *ngIf="SelectedCol==1">N/A</td>
                    <td class="text-center" *ngIf="SelectedCol==2">$350 per day x 1 day</td>
                    <td class="text-center" *ngIf="SelectedCol==3">$350 per day x 1 day</td>
                    <td class="text-center" *ngIf="SelectedCol==4">$350 per day x 1 day</td>
                </tr>

                <tr>
                    <td class="text-left">Hospital Admission Benefit</td>
                    <td class="text-center" *ngIf="SelectedCol==1">N/A</td>
                    <td class="text-center" *ngIf="SelectedCol==2">$750 per day x 1 day</td>
                    <td class="text-center" *ngIf="SelectedCol==3">$1,000 per day x 1 day</td>
                    <td class="text-center" *ngIf="SelectedCol==4">$1,500 per day x 1 day</td>
                </tr>

                <tr>
                    <td class="text-left">In-Hospital Indemnity Benefit</td>
                    <td class="text-center" *ngIf="SelectedCol==1">$50 per day x1 day</td>
                    <td class="text-center" *ngIf="SelectedCol==2">$750 per day x 5 days</td>
                    <td class="text-center" *ngIf="SelectedCol==3">$1,000 per day x 5 days</td>
                    <td class="text-center" *ngIf="SelectedCol==4">$1,500 per day x 5 days</td>
                </tr>

                <tr>
                    <td class="text-left">Surgery Benefit</td>
                    <td class="text-center" *ngIf="SelectedCol==1">N/A</td>
                    <td class="text-center" *ngIf="SelectedCol==2">$750 per day x 1 day</td>
                    <td class="text-center" *ngIf="SelectedCol==3">$1,500 per day x 1 day</td>
                    <td class="text-center" *ngIf="SelectedCol==4">$2,000 per day x 1 day</td>
                </tr>

                <tr>
                    <td class="text-left">Anesthesia Benefit</td>
                    <td class="text-center" *ngIf="SelectedCol==1">N/A</td>
                    <td class="text-center" *ngIf="SelectedCol==2">$150 per day x 1 day</td>
                    <td class="text-center" *ngIf="SelectedCol==3">$350 per day x 1 day</td>
                    <td class="text-center" *ngIf="SelectedCol==4">$500 per day x 1 day</td>
                </tr>

                <tr>
                    <td class="text-left">Maximum Benefit per year</td>
                    <td class="text-center" *ngIf="SelectedCol==1">Up to $5,000 per year</td>
                    <td class="text-center" *ngIf="SelectedCol==2">Up to $5,000 per year</td>
                    <td class="text-center" *ngIf="SelectedCol==3">Up to $10,000 per year</td>
                    <td class="text-center" *ngIf="SelectedCol==4">Up to $10,000 per year</td>
                </tr>

                <tr>
                    <td class="text-left">Annual Deductible</td>
                    <td class="text-center" *ngIf="SelectedCol==1">$0 Deductible</td>
                    <td class="text-center" *ngIf="SelectedCol==2">$0 Deductible</td>
                    <td class="text-center" *ngIf="SelectedCol==3">$0 Deductible</td>
                    <td class="text-center" *ngIf="SelectedCol==4">$0 Deductible</td>
                </tr>

                <tr>
                    <td class="text-left">Accidental Death Principal Sum</td>
                    <td class="text-center" *ngIf="SelectedCol==1">$1,000</td>
                    <td class="text-center" *ngIf="SelectedCol==2">$1,000</td>
                    <td class="text-center" *ngIf="SelectedCol==3">$1,000</td>
                    <td class="text-center" *ngIf="SelectedCol==4">$1,000</td>
                </tr>

                <tr>
                    <td class="text-left">Hooray Health Medical Concierge</td>
                    <td class="text-center" *ngIf="SelectedCol==1">Included</td>
                    <td class="text-center" *ngIf="SelectedCol==2">Included</td>
                    <td class="text-center" *ngIf="SelectedCol==3">Included</td>
                    <td class="text-center" *ngIf="SelectedCol==4">Included</td>
                </tr>

                <tr>
                    <td class="text-left">Telemedicine Doctor (MyTelemedicine)</td>
                    <td class="text-center" *ngIf="SelectedCol==1">$0 consult; unlimited visits</td>
                    <td class="text-center" *ngIf="SelectedCol==2">$0 consult; unlimited visits</td>
                    <td class="text-center" *ngIf="SelectedCol==3">$0 consult; unlimited visits</td>
                    <td class="text-center" *ngIf="SelectedCol==4">$0 consult; unlimited visits</td>
                </tr>

                <tr>
                    <td class="text-left">Discount Prescription Program (ScriptSave WellRX)</td>
                    <td class="text-center" *ngIf="SelectedCol==1">Included</td>
                    <td class="text-center" *ngIf="SelectedCol==2">Included</td>
                    <td class="text-center" *ngIf="SelectedCol==3">Included</td>
                    <td class="text-center" *ngIf="SelectedCol==4">Included</td>
                </tr>

                <tr>
                    <td class="text-left">Discount Radiology (Green Imaging)</td>
                    <td class="text-center" *ngIf="SelectedCol==1">Included</td>
                    <td class="text-center" *ngIf="SelectedCol==2">Included</td>
                    <td class="text-center" *ngIf="SelectedCol==3">Included</td>
                    <td class="text-center" *ngIf="SelectedCol==4">Included</td>
                </tr>
                <!--
                <tr>
                    <td class="text-left">Employee Only Monthly Rate</td>
                    <td class="text-center" *ngIf="SelectedCol==1">$0.00</td>
                    <td class="text-center" *ngIf="SelectedCol==2">$0.00</td>
                    <td class="text-center" *ngIf="SelectedCol==3">$0.00</td>
                    <td class="text-center" *ngIf="SelectedCol==4">$0.00</td>
                </tr>

                <tr>
                    <td class="text-left">Employee + Spouse Monthly Rate</td>
                    <td class="text-center" *ngIf="SelectedCol==1">$0.00</td>
                    <td class="text-center" *ngIf="SelectedCol==2">$0.00</td>
                    <td class="text-center" *ngIf="SelectedCol==3">$0.00</td>
                    <td class="text-center" *ngIf="SelectedCol==4">$0.00</td>
                </tr>

                <tr>
                    <td class="text-left">Employee + Child(ren) Monthly Rate</td>
                    <td class="text-center" *ngIf="SelectedCol==1">$0.00</td>
                    <td class="text-center" *ngIf="SelectedCol==2">$0.00</td>
                    <td class="text-center" *ngIf="SelectedCol==3">$0.00</td>
                    <td class="text-center" *ngIf="SelectedCol==4">$0.00</td>
                </tr>

                <tr>
                    <td class="text-left">Family Monthly Rate</td>
                    <td class="text-center" *ngIf="SelectedCol==1">$0.00</td>
                    <td class="text-center" *ngIf="SelectedCol==2">$0.00</td>
                    <td class="text-center" *ngIf="SelectedCol==3">$0.00</td>
                    <td class="text-center" *ngIf="SelectedCol==4">$0.00</td>
                </tr>
            -->
            </table>
        </div>


    </div>
    <p-footer>
        <div class="text-right">
            <button (click)="SetPlan()" class="btn btn-danger">+ Add to Cart (${{Fee}} per month)</button>
        </div>
    </p-footer>
</p-dialog>