<div>
    <br />

    <div class="h2">
        <div >Accident Medical Expense and AD&D</div>
    </div>
    <hr>
   
    <br /><br />
    <div class="row">
        <div class="col-md-3 text-center">
          <img
            src="assets/carriers/chubblogo.png "
            style="width: 150px; margin-top: 30px; margin-bottom: 60px"
          />
        </div>
        <div class="col-md-9">
          The Accident Medical Expense Benefit reimburses up to the Maximum
          Benefit Amount if an Accidental Bodily Injury causes the Insured
          Person to first incur Medical Expenses for care and treatment within
          90 days of a covered Accident. The Benefit Amount for Accident Medical
          Expense is payable only for Medical Expenses incurred within 52 weeks
          after the date of the Accident causing the Accidental Bodily injury,
          subject to any limitations and exclusions set forth in the policy.
        </div>
      </div>

    <div class="table-responsive">
        <table class="table">
            <tr class="bg-light text-center">
                <td ></td>
                <td style="width: 18%">AME/AD&D 1</td>
                <td style="width: 18%">AME/AD&D 2</td>
                <td style="width: 18%">AME/AD&D 3</td>
                <td style="width: 18%">AME/AD&D 4</td>
            </tr>

            <tr class="text-center">
                <td class="myblue text-left">Accident Medical Expense</td>
                <td>$2,500</td>
                <td>$5,000</td>
                <td>$7,500</td>
                <td>$10,000</td>
            </tr>
            <tr class="text-center">
                <td class="myblue text-left">AD&D</td>
                <td>$5,000</td>
                <td>$10,000</td>
                <td>$15,000</td>
                <td>$20,000</td>
            </tr>
            <tr class="text-center bg-light">
                <td class="text-center" colspan="5">
                    <h5>Zero Deductible</h5>
                </td>
            </tr>

            <tr *ngFor="let record of matrix; let row = index">
                <td class="myblue">{{ record.typename }}</td>
                <td class="text-center"><a
                        (click)="View(row+1, 1, record.col1, 'AME/AD&D 1 ($0 deductible) '+ record.typename, 0); LightBox=true; PlanName='AME/AD&D 1 ($0 deductible) '+ record.typename; "
                        [ngClass]="{'btn-success': SelectedRowCol === record.row + '1'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col1}}<br><small>View
                            Plan Details</small></a>
                </td>
                <td class="text-center"><a
                        (click)="View(row+1, 2, record.col2, 'AME/AD&D 2 ($0 deductible) '+ record.typename, 0); LightBox=true; PlanName='AME/AD&D 2 ($0 deductible) '+record.typename; "
                        [ngClass]="{'btn-success': SelectedRowCol ===  record.row + '2'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col2}}<br><small>View
                            Plan Details</small></a>
                </td>
                <td class="text-center"><a
                        (click)="View(row+1, 3, record.col3, 'AME/AD&D 3 ($0 deductible) '+record.typename, 0); LightBox=true; PlanName='AME/AD&D 3 ($0 deductible) '+ record.typename; "
                        [ngClass]="{'btn-success': SelectedRowCol === record.row + '3'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col3}}<br><small>View
                            Plan Details</small></a>
                </td>
                <td class="text-center"><a
                        (click)="View(row+1, 4, record.col4, 'AME/AD&D 4 ($0 deductible) '+record.typename, 0); LightBox=true; PlanName='AME/AD&D 4 ($0 deductible) '+ record.typename; "
                        [ngClass]="{'btn-success': SelectedRowCol === record.row + '4'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col4}}<br><small>View
                            Plan Details</small></a>
                </td>
            </tr>
            <tr class="text-center bg-light">
                <td class="text-center" colspan="5">
                    <h5>$250 Deductible</h5>
                </td>
            </tr>
            <tr *ngFor="let record of matrix2; let row = index">
                <td class="myblue">{{ record.typename }}</td>
                <td class="text-center"><a
                        (click)="View(row+5, 1, record.col1, 'AME/AD&D 1 ($250 deductible) '+ record.typename, 250); LightBox=true; PlanName='AME/AD&D 1 ($250 deductible) '+ record.typename; "
                        [ngClass]="{'btn-success': SelectedRowCol === record.row + '1'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col1}}<br><small>View
                            Plan Details</small></a>
                </td>
                <td class="text-center"><a
                        (click)="View(row+5, 2, record.col2, 'AME/AD&D 2 ($250 deductible) '+ record.typename, 250); LightBox=true; PlanName='AME/AD&D 2 ($250 deductible) '+record.typename; "
                        [ngClass]="{'btn-success': SelectedRowCol ===  record.row + '2'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col2}}<br><small>View
                            Plan Details</small></a>
                </td>
                <td class="text-center"><a
                        (click)="View(row+5, 3, record.col3, 'AME/AD&D 3 ($250 deductible) '+record.typename, 250); LightBox=true; PlanName='AME/AD&D 3 ($250 deductible) '+ record.typename; "
                        [ngClass]="{'btn-success': SelectedRowCol === record.row + '3'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col3}}<br><small>View
                            Plan Details</small></a>
                </td>
                <td class="text-center"><a
                        (click)="View(row+5, 4, record.col4, 'AME/AD&D 4 ($250 deductible) '+record.typename, 250); LightBox=true; PlanName='AME/AD&D 4 ($250 deductible) '+ record.typename; "
                        [ngClass]="{'btn-success': SelectedRowCol === record.row + '4'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col4}}<br><small>View
                            Plan Details</small></a>
                </td>
            </tr>
        </table>
    </div>
    <br />
    <small>
        THIS IS AN ACCIDENT ONLY POLICY AND IT DOES NOT PAY BENEFITS FOR LOSS
        FROM SICKNESS. THIS POLICY PROVIDES SUPPLEMENTAL BENEFITS ON AN EXPENSE
        INCURRED BASIS. IT DOES NOT CONSTITUTE COMPREHENSIVE HEALTH INSURANCE
        COVERAGE (OFTEN REFERRED TO AS “MAJOR MEDICAL COVERAGE”) AND DOES NOT
        SATISFY A PERSON’S INDIVIDUAL OBLIGATION TO SECURE THE REQUIREMENT OF
        MINIMUM ESSENTIAL COVERAGE UNDER THE AFFORDABLE CARE ACT (ACA). FOR MORE
        INFORMATION ABOUT THE ACA, PLEASE REFER TO
        <a class="text-primary" href="www.HealthCare.gov" target="_blank"
          >www.HealthCare.gov</a
        >
        <br /><br /> Quoted plans underwritten by Federal Insurance Company, a Chubb
        company.
      </small>
</div>


<br /><br />

<!--*******************************-->
<!--*******************************-->
<!--*******************************-->
<!--LIGHTBOXES-->
<!--*******************************-->
<!--*******************************-->
<!--*******************************-->

<p-dialog [(visible)]="LightBox" modal="true" [draggable]="false" [closeOnEscape]="true" [responsive]="true"
    [style]="{ width: '80%' }" [baseZIndex]="100">
    <p-header style="width: 100%">
        <h3>{{ PlanName }}</h3>
        <p>
            Benefits and plans are offered by Dylan Consulting, LLC, a licensed
            independent insurance agency, and any of its subagents, and underwritten
            by licensed insurance companies. Not all products are available in all
            states
        </p>
    </p-header>
    <div style="overflow-y: scroll; overflow-x: scroll; height: 40vh; width: 100%">
        <h4>Accident Medical Expense and AD&D</h4>
        <div class="table-responsive" style="font-size: 14px">
            <table class="table">
                <tr>
                    <td style="width: 20%">Disclaimer</td>
                    <td style="width: 80%">
                        For complete terms and conditions, including limitations and
                        exclusions, please click <b>Plan Documents</b> to review.<br /><br />
                        This information is a brief description of the important features of
                        this insurance plan. It is not an insurance contract. Insurance
                        underwritten and provided by Federal Insurance Company, a Chubb
                        company. Chubb is the marketing name used to refer to subsidiaries
                        of Chubb Limited providing insurance and related services. For a
                        list of these subsidiaries, please visit our website at
                        <a href="http://www.chubb.com" target="_blank">
                            http://www.chubb.com</a>. All products may not be available in all states or certain terms
                        may be different where required by state law. This communication
                        contains product summaries only. Coverage is subject to the language
                        of the policies as actually issued. Chubb, 202 Hall's Mill Road,
                        Whitehouse Station, NJ 08889-1600.<br /><br />

                        THIS IS AN ACCIDENT ONLY POLICY AND IT DOES NOT PAY BENEFITS FOR
                        LOSS FROM SICKNESS. THIS POLICY PROVIDES SUPPLEMENTAL BENEFITS ON
                        EITHER AN EXPENSE INCURRED OR A FIXED INDEMNITY BASIS. IT DOES NOT
                        CONSTITUTE COMPREHENSIVE HEALTH INSURANCE COVERAGE (OFTEN REFERRED
                        TO AS “MAJOR MEDICAL COVERAGE”) AND DOES NOT SATISFY A PERSON’S
                        INDIVIDUAL OBLIGATION TO SECURE THE REQUIREMENT OF MINIMUM ESSENTIAL
                        COVERAGE UNDER THE AFFORDABLE CARE ACT (ACA). FOR MORE INFORMATION
                        ABOUT THE ACA, PLEASE REFER TO
                        <a href="http://www.HealthCare.gov" target="_blank">
                            www.HealthCare.gov</a>. <br /><br />
                        Quoted plans underwritten by Federal Insurance Company, a Chubb
                        company. <br /><br />
                        W121720-DT
                    </td>
                </tr>
                <tr>
                    <td>Accidental Death & Dismemberment</td>
                    <td>
                        <table style="width: 50%">
                            <tr>
                                <th>Benefit</th>
                                <th>Amount</th>
                            </tr>
                            <tr>
                                <td>AD&D Primary Member Principal Sum</td>
                                <td>${{ principalSum }}</td>
                            </tr>
                            <tr>
                                <td>Accidental Medical Expense Maximum Benefit</td>
                                <td>${{ maximumBenefit }}</td>
                            </tr>
                            <tr>
                                <td>Accidental Medical Expense Deductible</td>
                                <td>${{ selecteddeductible }}</td>
                            </tr>
                        </table>
                        <br />
                        The following are Losses insured and the corresponding Benefit
                        Amount expressed as a percentage of the Primary Member Principal
                        Sum:<br /><br />

                        <head>
                            <style>
                                table {
                                    width: 100%;
                                }
                            </style>
                        </head>

                        <body>
                            <table>
                                <tr>
                                    <th>Accidental:</th>
                                    <th>
                                        Benefits Amounts (Percentage of Primary Member Principal
                                        Sum)
                                    </th>
                                </tr>
                                <tr>
                                    <td>Loss of Life</td>
                                    <td>100%</td>
                                </tr>
                                <tr>
                                    <td>Loss of Speech and Loss of Hearing</td>
                                    <td>100%</td>
                                </tr>
                                <tr>
                                    <td>
                                        Loss of Speech and one of Loss of Hand, Loss of Foot, or
                                        Loss of Sight of One Eye
                                    </td>
                                    <td>100%</td>
                                </tr>
                                <tr>
                                    <td>
                                        Loss of Hands (Both), Loss of Feet (Both), Loss of Sight or
                                        a combination of any two of Loss of Hand, Loss of Foot, or
                                        Loss of Sight of One Eye
                                    </td>
                                    <td>100%</td>
                                </tr>
                                <tr>
                                    <td>
                                        Loss of Hand, Loss of Foot, or Loss of Sight of One Eye (Any
                                        one of each)
                                    </td>
                                    <td>50%</td>
                                </tr>
                                <tr>
                                    <td>Loss of Speech or Loss of Hearing</td>
                                    <td>50%</td>
                                </tr>
                                <tr>
                                    <td>Loss of Thumb ansd Index Finger of the same hand</td>
                                    <td>25%</td>
                                </tr>
                            </table>
                        </body>
                    </td>
                </tr>

                <tr>
                    <td>Accident Medical Expense</td>
                    <td>
                        AME provides reimbursement for medical expenses if accidental bodily
                        injury causes the insured person to first incur medical expenses
                        within 90 days of an accident. The Benefit Amount for Accident
                        Medical Expense is payable only for Medical Expenses incurred within
                        52 weeks after the date of the Accident causing the Accidental
                        Bodily Injury, subject to any limitations and exclusions set forth
                        in the policy.<br /><br />
                        For AL, AZ, CO, CT, DC, DE, GA, HI, IL, IA, KS, KY, LA, MA, MI, MS,
                        MO, NE, NV, ND, RI, SC, TN, VA, WV, WI, WY, the Accident Medical
                        Expense Benefit is payable on an Excess basis.
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <p-footer>
        <div class="row">
            <div class="col-md-6 text-left">
                <a href="assets/pdf/ame{{ SelectedCol }}-{{ selecteddeductible }}{{
              FileCA
            }}.pdf" target="_blank" class="btn btn-outline-danger"><i
                        class="fas fa-file-pdf text-danger btn-danger-"></i> Download Plan
                    Documents</a>
            </div>
            <div class="col-md-6 text-right">
                <button (click)="SetPlan()" class="btn btn-danger">
                    + Add to Cart (${{ SelectedAmount }} per month)
                </button>
            </div>
        </div>
    </p-footer>
</p-dialog>