<div>
    <br>
   
    <div class="h2">
        <div >Delta Dental Insurance</div>
    </div>
    <hr>
    <br><br>

    <div class="row">
        <div class="col-md-3 text-center">
            <img src="assets/carriers/deltadental2.PNG" style="width: 150px; margin-top: 30px; margin-bottom: 60px;">
        </div>
        <div class="col-md-9">
            AFEUSA provides members access to high-quality affordable dental care through Delta Dental of Illinois. There are No Waiting Periods/First Day Coverage. Implants Covered Under Major Services. Members can carryover qualified unused portions of their annual
            maximum benefit amount from one benefit year to the next. The maximum carryover benefits will not exceed two times your annual maximum benefit. Guaranteed Acceptance: the primary member must be a minimum age of 18 and coverage is available
            to all eligible family members. Membership and coverage is effective on the first day of the following month after purchase.
            <br><br>
            Choose a dentist from one of the largest networks with Delta Dental.
            <br><br>
            <a href="https://www.deltadentalil.com/find-a-provider/dental/" target="_blank" class="btn btn-sm btn-primary">Find a Dentist <i
                class="flaticon-right-chevron"></i><span></span></a>
            <!--End New Table-->

            <br>
            <br>
        </div>
    </div>

    <div class="table-responsive">
        <table class="table">

            <tr class="bg-light  text-center">
                <td style="width:40%"></td>
                <td style="width:20%;">
                    Select Silver
                </td>
                <td style="width:20%;">
                    Select Gold
                </td>
                <td style="width:20%;">
                    Select Platinum
                </td>

            </tr>
            <tr class="text-center">
                <td class="text-left myblue">Benefit Highlights
                </td>
                <td>No Waiting Period
                    <br>To Go Carryover Feature
                </td>
                <td>No Waiting Period
                    <br>To Go Carryover Feature
                </td>
                <td>No Waiting Period
                    <br>To Go Carryover Feature
                </td>
            </tr>
            <tr class="text-center">
                <td class="text-left myblue">Annual Deductible
                </td>
                <td>$50
                </td>
                <td>$100
                </td>
                <td>$150
                </td>
            </tr>
            <tr class="text-center">
                <td class="text-left myblue">Deductible waived for preventative & diagnostic services
                </td>
                <td>Waived
                </td>
                <td>N/A
                </td>
                <td>Waived
                </td>
            </tr>
            <tr class="text-center">
                <td class="text-left myblue">Maximum Benefit
                </td>
                <td>$1,000/person
                </td>
                <td>$3,000/person
                </td>
                <td>$3,000/person
                </td>
            </tr>
            <tr class="text-center">
                <td class="text-left myblue">Preventative/Diagnostic Services - No Waiting Period
                </td>
                <td>50%
                </td>
                <td>100%
                </td>
                <td>100%
                </td>
            </tr>
            <tr class="text-center">
                <td class="text-left myblue">Basic Services - No Waiting Period
                </td>
                <td>50%
                </td>
                <td>70%
                </td>
                <td>70%
                </td>
            </tr>
            <tr class="text-center">
                <td class="text-left myblue">Major Restorative Services - No Waiting Period
                </td>
                <td>50%
                </td>
                <td>70%
                </td>
                <td>70%
                </td>
            </tr>
            <tr class="text-center">
                <td class="text-left myblue">Orthodontics
                </td>
                <td>Not included
                </td>
                <td>Not included
                </td>
                <td>Not included
                </td>
            </tr>
            <tr class="bg-light text-center">
                <td colspan="4">Tier Cost per Month </td>
            </tr>


            <tr *ngFor="let record of matrix; let row = index">
                <td class="myblue">{{record.typename}} </td>
                <td class="text-center"><a
                        (click)="View(row+1, 1, record.col1, 'Delta Dental Select Silver '+ record.typename); LightBox=true; PlanName='Delta Dental Select Silver '+ record.typename; AnnualDeductible='$50'; MaximumBenefit='$1,000'; Preventive='50%'; BasicServices='50%'; Fee=record.col1"
                        [ngClass]="{'btn-success': SelectedRowCol === record.row + '1'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col1}}<br><small>View
                            Plan Details</small></a>
                </td>
                <td class="text-center"><a
                        (click)="View(row+1, 2, record.col2, 'Delta Dental Select Gold '+ record.typename); LightBox=true; PlanName='Delta Dental Select Gold '+record.typename; AnnualDeductible='$100'; MaximumBenefit='$3,000'; Preventive='100%'; BasicServices='70%'; Fee=record.col2"
                        [ngClass]="{'btn-success': SelectedRowCol ===  record.row + '2'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col2}}<br><small>View
                            Plan Details</small></a>
                </td>
                <td class="text-center"><a
                        (click)="View(row+1, 3, record.col3, 'Delta Dental Select Platinum '+record.typename); LightBox=true; PlanName='Delta Dental Select Platinum '+ record.typename; AnnualDeductible='$150'; MaximumBenefit='$3,000'; Preventive='100%'; BasicServices='70%'; Fee=record.col3"
                        [ngClass]="{'btn-success': SelectedRowCol === record.row + '3'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col3}}<br><small>View
                            Plan Details</small></a>
                </td>
            </tr>

        </table>
    </div>
</div>


<br><br>










<!--*******************************-->
<!--*******************************-->
<!--*******************************-->
<!--LIGHTBOXES-->
<!--*******************************-->
<!--*******************************-->
<!--*******************************-->
<p-dialog [(visible)]="LightBox" modal="true" [draggable]="false" [closeOnEscape]="true" [responsive]="true"
    [style]="{width: '80%'}" [baseZIndex]="100">

    <p-header style="width:100%">
        <h3>{{PlanName}}</h3>
        <p>Benefits and plans are offered by Dylan Consulting, LLC, a licensed independent insurance agency, and any of
            its subagents, and underwritten by licensed insurance companies and registered associations. Not all
            products are available in all states.
        </p>
    </p-header>
    <div style="overflow-y: scroll; overflow-x: scroll; height:40vh; width: 100%;">
        <div class="table-responsive" style="font-size:14px;">
            <table class="table">
                <tr>
                    <td style="width:20%">TO GO Carryover Feature</td>
                    <td style="width:80%">
                        Allows enrollees to carryover qualified unused portions of their annual maximum benefit amount
                        from one year to the next. The maximum carryover benefits will not exceed two times your annual
                        maximum benefit.
                    </td>
                </tr>

                <tr>
                    <td style="width:20%">Annual Deductible</td>
                    <td style="width:80%">
                        {{AnnualDeductible}} <span *ngIf="AnnualDeductible !='$100'">- Waived for Preventative &
                            Diagnostics.</span>
                    </td>
                </tr>

                <tr>
                    <td style="width:20%">Maximum Coverage</td>
                    <td style="width:80%">
                        {{MaximumBenefit}}/person
                    </td>
                </tr>

                <tr>
                    <td style="width:20%">Preventative/Diagnostic Services<br>No Waiting Period</td>
                    <td style="width:80%">
                        Covered at {{Preventive}}:
                        <ul class="EligQuestions">
                            <li>Routine exams (two per benefit year)</li>
                            <li>Cleanings (two per benefit year)</li>
                            <li>X-rays (bitewings - 2 per benefit year)</li>
                            <li>Fluoride treatments (once per benefit year to age 16)</li>
                            <li>Space maintainers (to age 16)</li>
                            <li>Sealants (to age 16)</li>
                        </ul>
                    </td>
                </tr>


                <tr>
                    <td style="width:20%">Basic Services<br>No Waiting Period</td>
                    <td style="width:80%">
                        Covered at {{BasicServices}}:
                        <ul class="EligQuestions">
                            <li>X-rays (full mouth-1 per 5 years)</li>
                            <li>Emergency exams and palliative (pain relief) treatment</li>
                            <li>Fillings (silver (amalgam) and tooth colored (composite) on front teeth)</li>
                            <li>Oral surgery (simple extractions)</li>
                            <li>Sedative fillings</li>
                            <li>Recementation of bridges, crowns, onlays</li>
                        </ul>
                    </td>
                </tr>

                <tr>
                    <td style="width:20%">Major Restorative Services<br>No Waiting Period</td>
                    <td style="width:80%">
                        Covered at {{BasicServices}}:
                        <ul class="EligQuestions">
                            <li>Oral surgery (surgical extractions including general anesthesia, IV sedation)</li>
                            <li>Oral surgery (all other)</li>
                            <li>Endodontics (root canals and pulpal therapy)</li>
                            <li>Non-surgical Periodontic (gum) maintenance</li>
                            <li>Surgical Periodontic (gum) maintenance</li>
                            <li>Crowns, onlays, and other ceramic restorations to permanent teeth</li>
                            <li>Partial/full dentures</li>
                            <li>Denture (repair, reline, rebase and adjustments)</li>
                            <li>Fixed/removable bridges</li>
                            <li>Implants</li>
                            <li>Bridge and crown repair</li>
                            <li>Tissue conditioning</li>
                            <li>Labial veneers</li>
                        </ul>
                    </td>
                </tr>

                <tr>
                    <td style="width:20%">Orthodontics</td>
                    <td style="width:80%">
                        Not Included
                    </td>
                </tr>
            </table>
        </div>

    </div>
    <p-footer>
        <div class="text-right">
            <button (click)="SetPlan()" class="btn btn-danger">+ Add to Cart (${{Fee}} per month)</button>
        </div>
    </p-footer>
</p-dialog>