<h4>Editing User</h4>
<hr>


<div class="minheight">
    <div class="bShadow-5">
        <br>
        <div class="row">
            <div class="col-12 col-md-12 col-lg-6">
                <h5>User Information</h5>
                <hr>
                <div class="row">
                    <div class="col-12 col-md-4">
                        First Name
                        <input type="text" class="form-control" maxlength="40" [(ngModel)]="payload.firstname">
                    </div>
                    <div class="col-12 col-md-4">
                        Last Name
                        <input type="text" class="form-control" maxlength="40" [(ngModel)]="payload.lastname">
                    </div>
                    <div class="col-12 col-md-4">
                        Email Address
                        <input type="text" class="form-control" maxlength="70" [(ngModel)]="payload.email">
                    </div>
                    <div class="col-12 col-md-4">
                        <br>
                        Password
                        <input type="password" class="form-control" maxlength="40" [(ngModel)]="payload.password">
                    </div>
                    <div class="col-12 col-md-4">
                        <br>
                        Retype Password
                        <input type="password" class="form-control" maxlength="40" [(ngModel)]="payload.password2">
                    </div>
                    <div class="col-12 col-md-4">
                        <br>
                        Status
                        <select class="form-control" class="form-control" [(ngModel)]="payload.status">
                            <option value="1">Enabled</option>
                            <option value="0">Disabled</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-12 col-lg-6">
                <div class="alert alert-warning">
                <h5>Permissions & Features</h5>
                <hr>
                <table class="table table-responsive table-borderless">
                    <tr>
                        <td style="width: 40px">
                            <input type="checkbox" binary="true" inputId="binary" [(ngModel)]="payload.permissions.application">
                        </td>
                        <td>
                            Allow user to take a member application through the Platform
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 40px">
                            <input type="checkbox" binary="true" inputId="binary" [(ngModel)]="payload.permissions.impersonation">
                        </td>
                        <td>
                            Allow this user to impersonate a member
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 40px">
                            <input type="checkbox" binary="true" inputId="binary" [(ngModel)]="payload.permissions.agentsmodule">
                        </td>
                        <td>
                            Allow this user to access the agents module
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 40px">
                            <input type="checkbox" binary="true" inputId="binary" [(ngModel)]="payload.permissions.websites">
                        </td>
                        <td>
                            Allow this user to create new websites
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 40px">
                            <input type="checkbox" binary="true" inputId="binary" [(ngModel)]="payload.permissions.commissions">
                        </td>
                        <td>
                            Allow this user to access to the commissions module
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 40px">
                            <input type="checkbox" binary="true" inputId="binary" [(ngModel)]="payload.permissions.banking">
                        </td>
                        <td>
                            Allow this user to change the organization's banking and licensig information
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 40px">
                            <input type="checkbox" binary="true" inputId="binary" [(ngModel)]="payload.permissions.reports">
                        </td>
                        <td>
                            Allow this user to view and download reports
                        </td>
                    </tr>
                   
                </table>
                </div>
            </div>
            
        </div>
        <br>
        <button (click)="saveChanges()" class="btn btn-primary text-white " style="margin-right: 10px;"><i
            class="fa fa-user"></i>
        Save Changes</button>
        <br><br>
    </div>
</div>