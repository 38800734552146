<div>
    <br>

    <div class="h2">
        <div>United Concordia Dental</div>
    </div>
    <hr>
    <br><br>

    <div class="row">
        <div class="col-md-3 text-center">
            <img src="assets/carriers/concordia.png" style="width: 150px;">
        </div>
        <div class="col-md-9">
            With United Concordia Dental you can choose best in class dental coverage for you and your eligible family
            members. Superior dental hygiene is important – “healthy teeth, healthy you”! These dental plans give
            members peace of mind and security knowing they have quality coverage – plus guaranteed acceptance.
            <br><br>
            <a href="https://www.unitedconcordia.com/find-a-dentist/?network=074" target="_blank"
            class="btn btn-primary">Find a Dentist</a>
        </div>
    </div>
    <br><br>
    <div class="table-responsive" style="font-size: 14px">
        <table class="table">
            <tr>
                <td colspan="5" class="bg-cian">
                    <h4 class="text-white">
                        United Concordia Dental Plan Summary
                    </h4>
                </td>
            </tr>
            <tr class="">
                <td>

                </td>
                <td style="width: 18%" class="text-center">
                    United Concordia
                    <br>Dental Silver Plan
                </td>
                <td style="width: 18%" class="text-center">
                    United Concordia
                    <br>Dental Gold Plan
                </td>
                <td style="width: 18%" class="text-center">
                    United Concordia
                    <br>Dental Platinum Plan
                </td>
            </tr>
            <tr>
                <td class="text-left">
                    PREVENTIVE/DIAGNOSTIC SERVICES
                    <ul>
                        <li>
                            Routine Exams
                        </li>
                        <li>Cleanings</li>
                        <li>Bitewing X‐rays</li>
                        <li>Fluoride Treatments</li>
                        <li>Space Maintainers</li>
                        <li>Sealants</li>
                    </ul>
                </td>
                <td class="text-center">
                    50%
                </td>
                <td class="text-center">100%</td>
                <td class="text-center">100%</td>
            </tr>

            <tr>
                <td class="text-left">
                    BASIC SERVICES
                    <ul>
                        <li>Full Mouth X‐rays</li>
                        <li>Emergency Exams and Palliative (pain relief) Treatment</li>
                        <li>Fillings (silver (amalgam) and tooth colored (composite) on front teeth)</li>
                        <li>Oral Surgery (simple extractions)</li>
                        <li>Sedative Fillings</li>
                        <li>Recementation of Bridges, Crowns, Onlays</li>
                    </ul>
                </td>
                <td class="text-center">50%</td>
                <td class="text-center">70%</td>
                <td class="text-center">70%</td>
            </tr>

            <tr>
                <td class="text-left">MAJOR RESTORATIVE SERVICES
                    <ul>
                        <li>Oral Surgery (surgical extractions including general anesthesia, IV sedation)</li>
                        <li>Oral Surgery (all other)</li>
                        <li>Endodontics (root canals and pulpal therapy)</li>
                        <li>Non‐Surgical Periodontic (gum) Maintenance</li>
                        <li>Surgical Periodontic (gum) Maintenance</li>
                        <li>Crowns, Onlays (and other ceramic restorations to permanent teeth)</li>
                        <li>Partial/Full Dentures</li>
                        <li>Denture (repair, reline, rebase and adjustments)</li>
                        <li>Fixed/Removable Bridges</li>
                        <li>Implants</li>
                        <li>Bridge and Crown Repair</li>
                        <li>Tissue Conditioning</li>
                        <li>Labial Veneers</li>
                    </ul>
                </td>
                <td class="text-center">50%</td>
                <td class="text-center">70%</td>
                <td class="text-center">70%</td>
            </tr>

            <tr>
                <td class="text-left">Annual Program Deductible</td>
                <td class="text-center">$50 (waived for Preventive/Diagnostic)</td>
                <td class="text-center">$100</td>
                <td class="text-center">$150 (waived for Preventive/Diagnostic)</td>
            </tr>
            <tr>
                <td class="text-left">Annual Program Maximum (per person)</td>
                <td class="text-center" >$1,500</td>
                <td class="text-center" >$3,500</td>
                <td class="text-center" >$5,000</td>
            </tr>
           
            <tr>
                <td class="text-left">Out-of-Network Reimbursement</td>
                <td class="text-center" colspan="4">MAC (Maximum Allowable Charge)</td>
               
            </tr>


            <tr *ngFor="let record of matrix; let row = index">
                <td class="myblue">{{record.typename}} </td>
                <td class="text-center"><a
                        (click)="View(row+1, 1, record.col1, 'United Concordia Dental Silver Plan - '+ record.typename, record.remitSilver); LightBox=true; PlanName='United Concordia Dental Silver Plan - '+ record.typename; Fee=record.col1"
                        [ngClass]="{'btn-success': SelectedRowCol === record.row + '1'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col1}}<br><small>View
                            Plan Details</small></a>
                </td>
                <td class="text-center"><a
                        (click)="View(row+1, 2, record.col2, 'United Concordia Dental Gold Plan - '+ record.typename, record.remitGold); LightBox=true; PlanName='United Concordia Dental Gold Plan - '+record.typename; Fee=record.col2"
                        [ngClass]="{'btn-success': SelectedRowCol ===  record.row + '2'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col2}}<br><small>View
                            Plan Details</small></a>
                </td>
                <td class="text-center"><a
                        (click)="View(row+1, 3, record.col3, 'United Concordia Dental Platinum Plan - '+record.typename, record.remitPlatinum); LightBox=true; PlanName='United Concordia Dental Platinum Plan - '+ record.typename;  Fee=record.col3"
                        [ngClass]="{'btn-success': SelectedRowCol === record.row + '3'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col3}}<br><small>View
                            Plan Details</small></a>
                </td>
            </tr>




        </table>
    </div>












</div>


<br><br>










<!--*******************************-->
<!--*******************************-->
<!--*******************************-->
<!--LIGHTBOXES-->
<!--*******************************-->
<!--*******************************-->
<!--*******************************-->
<p-dialog [(visible)]="LightBox" modal="true" [draggable]="false" [closeOnEscape]="true" [responsive]="true"
    [style]="{width: '80%'}" [baseZIndex]="100">

    <p-header style="width:100%">
        <h3>{{PlanName}}</h3>
        <p>Benefits and plans are offered by Dylan Consulting, LLC, a licensed independent insurance agency, and any of
            its subagents, and underwritten by licensed insurance companies and registered associations. Not all
            products are available in all states.
        </p>
    </p-header>
    <div style="overflow-y: scroll; overflow-x: scroll; height:40vh; width: 100%;">
        <div class="table-responsive" style="font-size: 14px">
            <table class="table">
                <tr>
                    <td colspan="5" class="bg-cian">
                        <h4 class="text-white">
                            United Concordia Dental Plan Summary
                        </h4>
                    </td>
                </tr>
                <tr class="">
                    <td style="width: 20%">
    
                    </td>
                    <td style="width: 20%" *ngIf="SelectedCol==1">
                        United Concordia
                        <br>Dental Silver Plan
                    </td>
                    <td style="width: 20%"  *ngIf="SelectedCol==2">
                        United Concordia
                        <br>Dental Gold Plan
                    </td>
                    <td style="width: 20%"  *ngIf="SelectedCol==3">
                        United Concordia
                        <br>Dental Platinum Plan
                    </td>
                </tr>
                <tr>
                    <td class="text-left">
                        PREVENTIVE/DIAGNOSTIC SERVICES
                        <ul>
                            <li>
                                Routine Exams
                            </li>
                            <li>Cleanings</li>
                            <li>Bitewing X‐rays</li>
                            <li>Fluoride Treatments</li>
                            <li>Space Maintainers</li>
                            <li>Sealants</li>
                        </ul>
                    </td>
                    <td  *ngIf="SelectedCol==1">
                        50%
                    </td>
                    <td  *ngIf="SelectedCol==2">100%</td>
                    <td  *ngIf="SelectedCol==3">100%</td>
                </tr>
    
                <tr>
                    <td class="text-left">
                        BASIC SERVICES
                        <ul>
                            <li>Full Mouth X‐rays</li>
                            <li>Emergency Exams and Palliative (pain relief) Treatment</li>
                            <li>Fillings (silver (amalgam) and tooth colored (composite) on front teeth)</li>
                            <li>Oral Surgery (simple extractions)</li>
                            <li>Sedative Fillings</li>
                            <li>Recementation of Bridges, Crowns, Onlays</li>
                        </ul>
                    </td>
                    <td  *ngIf="SelectedCol==1">50%</td>
                    <td  *ngIf="SelectedCol==2">70%</td>
                    <td  *ngIf="SelectedCol==3">70%</td>
                </tr>
    
                <tr>
                    <td class="text-left">MAJOR RESTORATIVE SERVICES
                        <ul>
                            <li>Oral Surgery (surgical extractions including general anesthesia, IV sedation)</li>
                            <li>Oral Surgery (all other)</li>
                            <li>Endodontics (root canals and pulpal therapy)</li>
                            <li>Non‐Surgical Periodontic (gum) Maintenance</li>
                            <li>Surgical Periodontic (gum) Maintenance</li>
                            <li>Crowns, Onlays (and other ceramic restorations to permanent teeth)</li>
                            <li>Partial/Full Dentures</li>
                            <li>Denture (repair, reline, rebase and adjustments)</li>
                            <li>Fixed/Removable Bridges</li>
                            <li>Implants</li>
                            <li>Bridge and Crown Repair</li>
                            <li>Tissue Conditioning</li>
                            <li>Labial Veneers</li>
                        </ul>
                    </td>
                    <td  *ngIf="SelectedCol==1">50%</td>
                    <td  *ngIf="SelectedCol==2">70%</td>
                    <td  *ngIf="SelectedCol==3">70%</td>
                </tr>
    
                <tr>
                    <td class="text-left">Annual Program Deductible</td>
                    <td *ngIf="SelectedCol==1">$50 (waived for Preventive/Diagnostic)</td>
                    <td *ngIf="SelectedCol==2">$100</td>
                    <td *ngIf="SelectedCol==3">$150 (waived for Preventive/Diagnostic)</td>
                </tr>

              
                <tr>
                    <td class="text-left">Annual Program Maximum (per person)</td>
                    <td *ngIf="SelectedCol==1" >$1,500</td>
                    <td *ngIf="SelectedCol==2" >$3,500</td>
                    <td *ngIf="SelectedCol==3" >$5,000</td>
                </tr>
               
                <tr>
                    <td class="text-left">Out-of-Network Reimbursement</td>
                    <td >MAC (Maximum Allowable Charge)</td>
                   
                </tr>
            </table>
        </div>


    </div>
    <p-footer>
        <div class="text-right">
            <button (click)="SetPlan()" class="btn btn-danger">+ Add to Cart (${{Fee}} per month)</button>
        </div>
    </p-footer>
</p-dialog>