<h4>
    Website Pages</h4>
<p>Click on a page below to edit, or add a new one.</p>
<hr>
<button class="btn btn-sm btn-outline-dark margin-right-5" routerLink="home">Home Page</button>
<button class="btn btn-sm btn-outline-dark margin-right-5" routerLink="productspage">Products</button>
<button class="btn btn-sm btn-outline-dark margin-right-5" routerLink="aboutus">About Us</button>
<button class="btn btn-sm btn-outline-dark margin-right-5" routerLink="contactus">Contact Us</button>
<button class="btn btn-sm btn-outline-dark margin-right-5" routerLink="blog">Blog & News</button>
<br>
<hr>
<router-outlet></router-outlet>