<div>
    <br>


    <div class="h2">
        <div>Standard Dental Insurance</div>
    </div>
    <hr>

    <br><br>
    <div class="row">
        <div class="col-md-3 text-center">
            <img src="assets/carriers/standardlogo.png " style="width: 150px; margin-top: 30px; margin-bottom: 60px" />
        </div>
        <div class="col-md-9">
            AFEUSA provides members access to high-quality affordable dental care through The Standard Dental. There are No
            Waiting Periods/First Day Coverage. Implants Covered Under Major Services. Members can carryover qualified
            unused portions. Guaranteed Acceptance: the primary member must be a minimum age of 18, and coverage is
            available to all eligible family members. Membership and coverage are effective on the first day of the
            following month after purchase.
            <br><br>
            Choose a dentist from over 535,000 locations.
            <br><br>
            <a href="https://dentalnetworkpartners.ameritas.com" target="_blank" class="btn btn-sm btn-primary">Find a Dentist <i
                class="flaticon-right-chevron"></i><span></span></a>
            <!--End New Table-->

            <br>

        </div>
    </div>
    <br><br>


    <div class="table-responsive">
        <table class="table">


            <tr>
                <td colspan="5" class="bg-cian">
                    <h4 class="text-white">Dental Plan Details
                    </h4>
                </td>
            </tr>

            <tr class="bg-light  text-center">
                <td ></td>
                <td style="width:18%">
                    The Standard Dental<br>Plan 1
                </td>
                <td style="width:18%">
                    The Standard Dental<br>Plan 2
                </td>
                <td style="width:18%">
                    The Standard Dental<br>Plan 3
                </td>
                <td style="width:18%">
                    The Standard Dental<br>Plan 4
                </td>
            </tr>

            <tr class="text-center">
                <td class="text-left myblue">Annual Deductible/Amount covered<br><small>Member pays each calendar year before benefits payable</small> </td>
                <td>
                    $50
                </td>
                <td>
                    $50
                </td>
                <td>
                    $250
                </td>
                <td>
                    $100
                </td>
            </tr>

            <tr class="text-center">
                <td class="text-left myblue">Deductible waived for preventative and diagnostic services</td>
                <td>
                    Waived
                </td>
                <td>
                    Waived
                </td>
                <td>
                    N/A
                </td>
                <td>
                    N/A
                </td>
            </tr>

            <tr class="text-center">
                <td class="text-left myblue">Preventitve and diagnostic co-insurance amounts</td>
                <td>
                    100%
                </td>
                <td>
                    100%
                </td>
                <td>
                    70%
                </td>
                <td>
                    70%
                </td>
            </tr>


            <tr class="text-center">
                <td class="text-left myblue">Plan Options - Annual Maximum Benefit</td>
                <td>
                    $1,000 per calender year
                </td>
                <td>
                    $3,000 per calender year
                </td>
                <td>
                    $3,000 per calender year
                </td>
                <td>
                    $3,000 per calender year
                </td>
               
            </tr>



            <tr class="text-center">
                <td class="text-left myblue">Max Builder</td>
                <td>
                    Up to $1,000 carryover 
                </td>
                <td>
                    Up to $1,200 carryover
                </td>
                <td>
                    Up to $1,200 carryover
                </td>
                <td>
                    Up to $1,200 carryover
                </td>
            </tr>

            <tr class="text-center">
                <td class="text-left myblue">The Standard Dental Network</td>
                <td colspan="4">
                    Ameritas 535,000 dental access points
                </td>
            </tr>


            <tr class="text-center">
                <td class="text-left myblue">PREVENTIVE/DIAGNOSTIC SERVICES (no waiting period)
                    <br><br>
                    <small>
                        <ul>
                            <li>Routine exams (two per benefit year)</li>
                            <li>Cleanings (two per benefit year)</li>
                            <li>X-rays (bitewings - 2 per benefit year)</li>
                            <li> Fluoride treatments (once per benefit year to age 16)</li>
                            <li>Space maintainers (to age 16)</li>
                            <li>Sealants (to age 16)</li>
                        </ul>
                    </small>
                </td>
                <td>
                   100%
                </td>
                <td>
                    100%
                </td>
                <td>
                    70%
                </td>
                <td>
                    70%
                </td>
            </tr>



            <tr class="text-center">
                <td class="text-left myblue">BASIC SERVICES (no waiting period)
                    <br><br>
                    <small>
                        <ul>
                            <li>X-rays (full mouth-1 per 5 years)</li>
                            <li>Emergency exams and palliative (pain relief) treatment</li>
                            <li>Fillings (silver (amalgam) and tooth colored (composite) on front teeth)</li>
                            <li>Oral surgery (simple extractions)</li>
                            <li>Sedative fillings</li>
                            <li>Recementation of bridges, crowns, onlays</li>
                        </ul>
                    </small>
                </td>
                <td>
                    Year 1: 60%<br>Year 2: 70%<br>Year 3+: 80%
                </td>
                <td>
                    Year 1: 60%<br>Year 2: 70%<br>Year 3+: 80%
                </td>
                <td>
                    All Years: 70%
                </td>
                <td>
                    All Years: 70%
                </td>
            </tr>

            <tr class="text-center">
                <td class="text-left myblue">MAJOR RESTORATIVE SERVICES (no waiting period)
                    <br><br>
                    <small>
                        <ul>
                            <li>Oral surgery (surgical extractions including general anesthesia, IV sedation)</li>
                            <li>Oral surgery (all other)</li>
                            <li>Endodontics (root canals and pulpal therapy)</li>
                            <li>Non-surgical Periodontic (gum) maintenance</li>
                            <li>Surgical Periodontic (gum) maintenance</li>
                            <li>Crowns, onlays, and other ceramic restorations to permanent teeth</li>
                            <li>Partial/full dentures</li>
                            <li>Denture (repair, reline, rebase and adjustments)</li>
                            <li>Fixed/removable bridges</li>
                            <li>Implant supported crowns</li>
                            <li>Bridge and crown repair</li>
                            <li>Tissue conditioning</li>
                            <li>Labial veneers</li>
                        </ul>
                    </small>
                </td>
                <td>
                    Year 1: 60%<br>Year 2: 70%<br>Year 3+: 80%
                </td>
                <td>
                    Year 1: 60%<br>Year 2: 70%<br>Year 3+: 80%
                </td>
                <td>
                    All Years: 70%
                </td>
                <td>
                    All Years: 70%
                </td>
            </tr>

            <tr class="text-center">
                <td class="text-left myblue">ORTHODONTICS</td>
                <td colspan="4">
                    Not Covered
                </td>
            </tr>

            <tr class="text-center">
                <td class="text-left myblue">Vision***  (Policy Link Dental + Vision Design)
                    <br><br>
                    <small>
                        <ul>
                            <li>Exams</li>
                            <li>Lenses; single vision, bifocal, lenticular, progressive</li>
                            <li>Contacts; elective, medically necessary</li>
                            <li>Frames</li>
                        </ul>
                    </small>
                </td>
                <td>
                    $150 Max
                </td>
                <td>
                    $150 Max
                </td>
                <td>
                    N/A
                </td>
                <td>
                    N/A
                </td>
            </tr>



            <tr class="bg-light text-center">
                <td colspan="5">Tier Cost per Month </td>
            </tr>


            <tr *ngFor="let record of matrix; let row = index">
                <td class="myblue">{{record.typename}} </td>
                <td class="text-center"><a
                        (click)="View(row+1, 1, record.col1, 'The Standard Dental Plan 1 '+ record.typename); LightBox=true; PlanName='The Standard Dental Plan 1 '+ record.typename; AnnualDeductible='$50'; MaximumBenefit='$1,000'; Preventive='50%'; BasicServices='50%'; Fee=record.col1"
                        [ngClass]="{'btn-success': SelectedRowCol === record.row + '1'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col1}}<br><small>View
                            Plan Details</small></a>
                </td>
                <td class="text-center"><a
                        (click)="View(row+1, 2, record.col2, 'The Standard Dental Plan 2 '+ record.typename); LightBox=true; PlanName='The Standard Dental Plan 2 '+record.typename; AnnualDeductible='$100'; MaximumBenefit='$3,000'; Preventive='100%'; BasicServices='70%'; Fee=record.col2"
                        [ngClass]="{'btn-success': SelectedRowCol ===  record.row + '2'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col2}}<br><small>View
                            Plan Details</small></a>
                </td>
                <td class="text-center"><a
                        (click)="View(row+1, 3, record.col3, 'The Standard Dental Plan 3 '+record.typename); LightBox=true; PlanName='The Standard Dental Plan 3 '+ record.typename; AnnualDeductible='$150'; MaximumBenefit='$3,000'; Preventive='100%'; BasicServices='70%'; Fee=record.col3"
                        [ngClass]="{'btn-success': SelectedRowCol === record.row + '3'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col3}}<br><small>View
                            Plan Details</small></a>
                </td>
                <td class="text-center"><a
                        (click)="View(row+1, 4, record.col4, 'The Standard Dental Plan 4 '+record.typename); LightBox=true; PlanName='The Standard Dental Plan 4 '+ record.typename; AnnualDeductible='$150'; MaximumBenefit='$3,000'; Preventive='100%'; BasicServices='70%'; Fee=record.col4"
                        [ngClass]="{'btn-success': SelectedRowCol === record.row + '4'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col4}}<br><small>View
                            Plan Details</small></a>
                </td>
            </tr>

        </table>
        <br>
        <br>
      
        *If you go to an out of network Dentist, you will be responsible for paying the difference between what the
        Dentist submits for payment and the amount we pay.
        <br><br>
        **Maximum is per calendar year for both in network and out of network.
        <br><br>
        <span class="text-left">
        *** This feature combines the annual maximum benefit between the dental and vision plans. Total benefits paid between the two coverages will not exceed the policy link maximum of Plan 1 or Plan 2 depending on plan chosen.
        </span>
        <br><br>
    </div>
</div>


<br><br>










<!--*******************************-->
<!--*******************************-->
<!--*******************************-->
<!--LIGHTBOXES-->
<!--*******************************-->
<!--*******************************-->
<!--*******************************-->
<p-dialog [(visible)]="LightBox" modal="true" [draggable]="false" [closeOnEscape]="true" [responsive]="true"
    [style]="{width: '80%'}" [baseZIndex]="100">

    <p-header style="width:100%">
        <h3>{{PlanName}}</h3>
        <p>Benefits and plans are offered by Dylan Consulting, LLC, a licensed independent insurance agency, and any of
            its subagents, and underwritten by licensed insurance companies and registered associations. Not all
            products are available in all states.
        </p>
    </p-header>
    <div style="overflow-y: scroll; overflow-x: scroll; height:40vh; width: 100%;">
        <div class="table-responsive" style="font-size:14px;">
            <!--PLAN 1-->
            <table class="table" *ngIf="SelectedCol==1">
                <tr>
                    <td style="width:20%">Type 1</td>
                    <td style="width:80%">
                        • Routine Exam (2 per benefit period)<br>
                        • Bitewing X-rays (2 per benefit period)<br>
                        • Full Mouth/Panoramic X-rays (1 in 3 years)<br>
                        • Periapical X-rays<br>
                        • Cleaning (2 per benefit period)<br>
                        • Fluoride for Children 18 and under (1 per benefit period)<br>
                        • Sealants (age 16 and under)<br>
                        • Space Maintainers
                    </td>
                </tr>
                <tr>
                    <td style="width:20%">Type 2</td>
                    <td style="width:80%">
                        • Restorative Amalgams<br>
                        • Restorative Composites<br>
                        • Endodontics (nonsurgical)<br>
                        • Endodontics (surgical)<br>
                        • Periodontics (nonsurgical)<br>
                        • Periodontics (surgical)<br>
                        • Denture Repair<br>
                        • Simple Extractions<br>
                        • Complex Extractions<br>
                        • Anesthesia

                    </td>
                </tr>
                <tr>
                    <td style="width:20%">Type 3</td>
                    <td style="width:80%">
                        • Onlays<br>
                        • Crowns (1 in 5 years per tooth)<br>
                        • Crown Repair<br>
                        • Prosthodontics (fixed bridge; removable complete/partial dentures) (1 in 5 years)


                    </td>
                </tr>
                <tr>
                    <td style="width:20%">Max Builder℠</td>
                    <td style="width:80%">
                        This dental plan includes a valuable feature that allows plan participants to carry over part of
                        their unused annual maximum
                    </td>
                </tr>
                <tr>
                    <td style="width:20%">Benefit Threshold</td>
                    <td style="width:80%">
                        $500
                    </td>
                </tr>
                <tr>
                    <td style="width:20%">Annual Carryover Amount</td>
                    <td style="width:80%">
                        $250
                    </td>
                </tr>
                <tr>
                    <td style="width:20%">Annual PPO Bonus</td>
                    <td style="width:80%">
                        $100
                    </td>
                </tr>
                <tr>
                    <td style="width:20%">Maximum Carryover</td>
                    <td style="width:80%">
                        $1,000
                    </td>
                </tr>
            </table>

            <!--PLAN 2-->
            <table class="table" *ngIf="SelectedCol==2">
                <tr>
                    <td style="width:20%">Type 1</td>
                    <td style="width:80%">
                        • Routine Exam (2 per benefit period)<br>
                        • Bitewing X-rays (2 per benefit period)<br>
                        • Full Mouth/Panoramic X-rays (1 in 3 years)<br>
                        • Periapical X-rays<br>
                        • Cleaning (2 per benefit period)<br>
                        • Fluoride for Children 18 and under (1 per benefit period)<br>
                        • Sealants (age 16 and under)<br>
                        • Space Maintainers
                    </td>
                </tr>
                <tr>
                    <td style="width:20%">Type 2</td>
                    <td style="width:80%">
                        • Restorative Amalgams<br>
                        • Restorative Composites<br>
                        • Endodontics (nonsurgical)<br>
                        • Endodontics (surgical)<br>
                        • Periodontics (nonsurgical)<br>
                        • Periodontics (surgical)<br>
                        • Denture Repair<br>
                        • Simple Extractions<br>
                        • Complex Extractions<br>
                        • Anesthesia

                    </td>
                </tr>
                <tr>
                    <td style="width:20%">Type 3</td>
                    <td style="width:80%">
                        • Onlays<br>
                        • Crowns (1 in 5 years per tooth)<br>
                        • Crown Repair<br>
                        • Prosthodontics (fixed bridge; removable complete/partial dentures) (1 in 5 years)


                    </td>
                </tr>
                <tr>
                    <td style="width:20%">Max Builder℠</td>
                    <td style="width:80%">
                        This dental plan includes a valuable feature that allows plan participants to carry over part of
                        their unused annual maximum
                    </td>
                </tr>
                <tr>
                    <td style="width:20%">Benefit Threshold</td>
                    <td style="width:80%">
                        $750
                    </td>
                </tr>
                <tr>
                    <td style="width:20%">Annual Carryover Amount</td>
                    <td style="width:80%">
                        $400
                    </td>
                </tr>
                <tr>
                    <td style="width:20%">Annual PPO Bonus</td>
                    <td style="width:80%">
                        $200
                    </td>
                </tr>
                <tr>
                    <td style="width:20%">Maximum Carryover</td>
                    <td style="width:80%">
                        $1,200
                    </td>
                </tr>
            </table>
            <!--PLAN 3-->
            <table class="table" *ngIf="SelectedCol==3">
                <tr>
                    <td style="width:20%">Hybrid Dental Care℠</td>
                    <td style="width:80%">
                        This plan simplifies the benefit process by removing the procedure types from the dental
                        insurance equation. You pay for dental services until the first benefit level is met – and then
                        the plan covers the percentage noted on the second benefit level for all remaining covered
                        dental procedures until you meet the annual maximum. You can also take advantage of the Ameritas
                        dental network, with more than 472,000 access points for quality dental care, for even more
                        out-of-pocket savings. Pair the plan with an FSA or HAS, or other enhancements, to help meet
                        your unique coverage needs.
                    </td>
                </tr>
                <tr>
                    <td style="width:20%">Type 1</td>
                    <td style="width:80%">
                        • Routine Exam (1 in 6 months)<br>
                        • Bitewing X-rays (1 in 12 months)<br>
                        • Full Mouth/Panoramic X-rays (1 in 5 years)<br>
                        • Periapical X-rays<br>
                        • Cleaning (1 in 6 months)<br>
                        • Fluoride for Children 13 and under (1 in 12 months)<br>
                        • Sealants (age 13 and under)

                    </td>
                </tr>
                <tr>
                    <td style="width:20%">Type 2</td>
                    <td style="width:80%">
                        • Space Maintainers<br>
                        • Restorative Amalgams<br>
                        • Restorative Composites<br>
                        • Onlays<br>
                        • Crowns (1 in 10 years per tooth)<br>
                        • Crown Repair<br>
                        • Endodontics (nonsurgical)<br>


                    </td>
                </tr>
                <tr>
                    <td style="width:20%">Type 3</td>
                    <td style="width:80%">
                        • Endodontics (surgical)<br>
                        • Periodontics (nonsurgical)<br>
                        • Periodontics (surgical)<br>
                        • Denture Repair<br>
                        • Prosthodontics (fixed bridge; removable complete/partial dentures) (1 in 10 years)<br>
                        • Simple Extractions<br>
                        • Complex Extractions<br>
                        • Anesthesia

                    </td>
                </tr>
                <tr>
                    <td style="width:20%">Max Builder℠</td>
                    <td style="width:80%">
                        This dental plan includes a valuable feature that allows plan participants to carry over part of
                        their unused annual maximum
                    </td>
                </tr>
                <tr>
                    <td style="width:20%">Benefit Threshold</td>
                    <td style="width:80%">
                        $750
                    </td>
                </tr>
                <tr>
                    <td style="width:20%">Annual Carryover Amount</td>
                    <td style="width:80%">
                        $400
                    </td>
                </tr>
                <tr>
                    <td style="width:20%">Annual PPO Bonus</td>
                    <td style="width:80%">
                        $200
                    </td>
                </tr>
                <tr>
                    <td style="width:20%">Maximum Carryover</td>
                    <td style="width:80%">
                        $1,200
                    </td>
                </tr>
            </table>
            <!--PLAN 4-->
            <table class="table" *ngIf="SelectedCol==4">
                <tr>
                    <td style="width:20%">Hybrid Dental Care℠</td>
                    <td style="width:80%">
                        This plan simplifies the benefit process by removing the procedure types from the dental
                        insurance equation. You pay for dental services until the first benefit level is met – and then
                        the plan covers the percentage noted on the second benefit level for all remaining covered
                        dental procedures until you meet the annual maximum. You can also take advantage of the Ameritas
                        dental network, with more than 472,000 access points for quality dental care, for even more
                        out-of-pocket savings. Pair the plan with an FSA or HAS, or other enhancements, to help meet
                        your unique coverage needs.
                    </td>
                </tr>
                <tr>
                    <td style="width:20%">Type 1</td>
                    <td style="width:80%">
                        • Routine Exam (1 in 6 months)<br>
                        • Bitewing X-rays (1 in 12 months)<br>
                        • Full Mouth/Panoramic X-rays (1 in 5 years)<br>
                        • Periapical X-rays<br>
                        • Cleaning (1 in 6 months)<br>
                        • Fluoride for Children 18 and under (1 in 12 months)<br>
                        • Sealants (age 13 and under)<br>

                    </td>
                </tr>
                <tr>
                    <td style="width:20%">Type 2</td>
                    <td style="width:80%">
                        • Space Maintainers<br>
                        • Restorative Amalgams<br>
                        • Restorative Composites<br>
                        • Onlays<br>
                        • Crowns (1 in 10 years per tooth)<br>
                        • Crown Repair<br>
                        • Endodontics (nonsurgical)



                    </td>
                </tr>
                <tr>
                    <td style="width:20%">Type 3</td>
                    <td style="width:80%">
                        • Endodontics (surgical)<br>
                        • Periodontics (nonsurgical)<br>
                        • Periodontics (surgical)<br>
                        • Denture Repair<br>
                        • Prosthodontics (fixed bridge; removable complete/partial dentures) (1 in 10 years)<br>
                        • Simple Extractions<br>
                        • Complex Extractions<br>
                        • Anesthesia<br>


                    </td>
                </tr>
                <tr>
                    <td style="width:20%">Max Builder℠</td>
                    <td style="width:80%">
                        This dental plan includes a valuable feature that allows plan participants to carry over part of
                        their unused annual maximum
                    </td>
                </tr>
                <tr>
                    <td style="width:20%">Benefit Threshold</td>
                    <td style="width:80%">
                        $750
                    </td>
                </tr>
                <tr>
                    <td style="width:20%">Annual Carryover Amount</td>
                    <td style="width:80%">
                        $400
                    </td>
                </tr>
                <tr>
                    <td style="width:20%">Annual PPO Bonus</td>
                    <td style="width:80%">
                        $200
                    </td>
                </tr>
                <tr>
                    <td style="width:20%">Maximum Carryover</td>
                    <td style="width:80%">
                        $1,200
                    </td>
                </tr>
            </table>
        </div>

    </div>
    <p-footer>
        <div class="row">
            <div class="col-md-6 text-left">
                <a href="assets/pdf/Standard_Dental_Plan_{{SelectedCol}}.pdf" target="_blank"
                    class="btn btn-outline-danger"><i class="fas fa-file-pdf text-danger btn-danger-"></i> Download Plan
                    Documents</a>
            </div>
            <div class="col-md-6 text-right">
                <button (click)="SetPlan()" class="btn btn-danger">+ Add to Cart (${{Fee}} per month)</button>
            </div>
        </div>
    </p-footer>
</p-dialog>