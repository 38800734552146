<h4>
    Products</h4>
<p>Click on a product below to edit, or add a new one.</p>
<button class="btn btn-outline-dark margin-right-5 btn-sm" (click)="NewProduct();">+ Add New Product</button>
<hr>
<div class="minheight">
    <p-table #dt [value]="ProductsList" [rows]="25" [showCurrentPageReport]="true" [rowsPerPageOptions]="[25,50,200,1000]" styleClass="p-datatable-customers" [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} contacts"
        [filterDelay]="0" [globalFilterFields]="['productname','hascustomcontent','status','dateadded','actions']" [resizableColumns]="true" [autoLayout]="true" selectionMode="single">

        <ng-template pTemplate="header">
            <tr class="FontRow f13">
                <th [pSortableColumn]="'productname'" class="smallfont">Product</th>
                <th [pSortableColumn]="'hascustomcontent'">Content Management</th>
                <th [pSortableColumn]="'status'">Status</th>
                <th [pSortableColumn]="'dateadded'">Last Modified</th>
                <th [pSortableColumn]="'actions'">Number of Actions</th>
            </tr>
            <tr>
                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'productname', 'contains')" placeholder="Enter Product Name">
                </th>
                <th>
                    <select class="form-control" (input)="dt.filter($event.target.value, 'hascustomcontent', 'contains')">
                        <option value="">List All</option>
                        <option value="Standard Product">Standard Product</option>
                        <option value="User Defined">User Defined</option>
                    </select>
                </th>

                <th>
                    <select class="form-control" (input)="dt.filter($event.target.value, 'hascustomcontent', 'contains')">
                        <option value="">List All</option>
                        <option value="Enabled">Enabled</option>
                        <option value="Disabled">Disabled</option>
                    </select>
                </th>
                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'dateadded', 'contains')" placeholder="Enter Date">
                </th>
                <th>

                </th>


            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-ProductsList>
            <tr class="FontRow f13" (click)="onRowSelect(ProductsList.productid)" style="cursor: pointer;">
                <td class="SmallFont">{{ProductsList.productname}}</td>
                <td class="SmallFont">{{ProductsList.hascustomcontent}}</td>
                <td class="SmallFont">{{ProductsList.status}}</td>
                <td class="SmallFont">{{ProductsList.dateadded}}</td>
                <td class="SmallFont">{{ProductsList.actions}}</td>

            </tr>
        </ng-template>
    </p-table>
</div>






<!--LIGHTBOX -->
<div id="overlay" *ngIf="OpenOverlay">
    <div id="overlay2">
        <div>
            <div class="row">
                <div class="col-md-10 text-left">
                    <div *ngIf="ShowTopMenu">
                        <button class="btn btn-outline-dark margin-right-5 btn-sm" (click)="NewProduct();">+ Standard Product</button>
                        <button class="btn btn-outline-dark margin-right-5 btn-sm" (click)="SelectBaseProduct('', '')">+ Custom Product</button>
                    </div>
                </div>
                <div class="col-md-2 text-right">
                    <button class="btn btn-danger btn-sm" (click)="OpenOverlay=false">X</button>
                </div>

            </div>

        </div>

        <!--stuck-->
        <div class="stuck">
            <div class="w100">
                <!--Standard Products-->
                <div *ngIf="ShowStandard">
                    <h3>Standard Product</h3>
                    <p>Select a base/standard product to add to your website. You will be able to modify this product after your selection</p>
                    <hr>
                    <div *ngFor="let product of StandardProductsList" style="height: 30px;">
                        <a class="text-primary mousepointer" (click)="SelectBaseProduct(product.productid, product.productname)">(+)
                            {{product.productname}}</a>
                    </div>
                </div>

                <!--End Standard Products-->


                <!--Edit Product-->
                <div *ngIf="ShowEdit">
                    <h3>{{productName}} Page Content</h3>
                    <p>All changes you perform on this screen will take place immediatelly after submit.</p>
                    <hr>
                    <div class="row">
                        <div class="col-sm-12 col-12 col-md-9">
                            <label>Product Name (as you want to display it on your website)</label>
                            <input class="form-control" maxlength="50" [(ngModel)]="productName">
                        </div>
                        <div class="col-sm-12 col-12 col-md-3">
                            <label>Product Status</label>
                            <select class="form-control" [(ngModel)]="status">
                                <option value="0">Disabled</option>
                                <option value="1">Enabled</option>
                            </select>
                        </div>
                    </div>



                    <label>Produt Page Content</label>
                    <div style="background-color: white;">
                        <angular-editor [placeholder]=" 'Enter text here...' " [config]="editorConfig " [(ngModel)]="productContent"></angular-editor>
                    </div>
                    <br>
                    <h4>Page Actions</h4>
                    <p>You can add up to 4 action buttons to this page. These actions can be OneSiteBox actions, or custom actions. Setup your actions below:</p>
                    <hr>

                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                            <label style="color: black;"><strong>Action 1</strong></label>
                            <select class="form-control" [(ngModel)]="action1">
                                <option value="">None</option>
                                <option value="quote">Quote Form</option>
                                <option value="contact">Contact Form</option>
                                <option value="email">Send Email</option>
                                <option value="url">Redirect to URL</option>
                            </select>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                            <label>Button Text</label>
                            <input type="text" class="form-control" maxlength="20" [(ngModel)]="button1">
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-4">
                            <div *ngIf="action1=='email' || action1=='url'">
                                <label>Destination {{action1}}</label>
                                <input type="text" class="form-control" maxlength="20" [(ngModel)]="destination1">
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                            <label style="color: black;"><strong>Action 2</strong></label>
                            <select class="form-control" [(ngModel)]="action2">
                                <option value="">None</option>
                                <option value="quote">Quote Form</option>
                                <option value="contact">Contact Form</option>
                                <option value="email">Send Email</option>
                                <option value="url">Redirect to URL</option>
                            </select>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                            <label>Button Text</label>
                            <input type="text" class="form-control" maxlength="20" [(ngModel)]="button2">
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-4">
                            <div *ngIf="action2=='email' || action2=='url'">
                                <label>Destination {{action2}}</label>
                                <input type="text" class="form-control" maxlength="20" [(ngModel)]="destination2">
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                            <label style="color: black;"><strong>Action 3</strong></label>
                            <select class="form-control" [(ngModel)]="action3">
                                <option value="">None</option>
                                <option value="quote">Quote Form</option>
                                <option value="contact">Contact Form</option>
                                <option value="email">Send Email</option>
                                <option value="url">Redirect to URL</option>
                            </select>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                            <label>Button Text</label>
                            <input type="text" class="form-control" maxlength="20" [(ngModel)]="button3">
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-4">
                            <div *ngIf="action3=='email' || action3=='url'">
                                <label>Destination {{action3}}</label>
                                <input type="text" class="form-control" maxlength="20" [(ngModel)]="destination3">
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                            <label style="color: black;"><strong>Action 4</strong></label>
                            <select class="form-control" [(ngModel)]="action4">
                                <option value="">None</option>
                                <option value="quote">Quote Form</option>
                                <option value="contact">Contact Form</option>
                                <option value="email">Send Email</option>
                                <option value="url">Redirect to URL</option>
                            </select>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                            <label>Button Text</label>
                            <input type="text" class="form-control" maxlength="20" [(ngModel)]="button4">
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-4">
                            <div *ngIf="action4=='email' || action4=='url'">
                                <label>Destination {{action4}}</label>
                                <input type="text" class="form-control" maxlength="20" [(ngModel)]="destination4">
                            </div>
                        </div>
                    </div>
                    <br>
                    <button class="btn btn-primary" (click)="SaveProduct()">Save Product</button>







                </div>

                <!--End Edit Product-->

            </div>
        </div>
        <!--end stuck-->
    </div>
</div>