import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'app-nipr',
  templateUrl: './nipr.component.html',
  styleUrls: ['./nipr.component.css']
})
export class NiprComponent implements OnInit {
  States: any;
  constructor(
    public httpClient: HttpClient,
  ) { }

  ngOnInit(): void {
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/setup/nipr.aspx";    // set header for http call
        let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
        // set body
        let body = new URLSearchParams();
        body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
        body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
        body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));

     
        this.httpClient.post(APIURL, body.toString(), options).subscribe(
          result => {
            this.States = result;
            if (result == null) {
              return;
            }
            else {
    
            }
    
          },
          error => {
    
          });
  }

}
