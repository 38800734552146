import { Component, OnInit, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit {

  @Input() NoteType: string;
  @Input() ElementID: string;

  public notes: string;
  public notesList: any;

  public Summary: string;
  public Status: string = "None";
  public Note: string;

  public openSummary: string;
  public openStatus: string = "None";
  public openNote: string;
  public openID: string;

  public showNote: boolean = false;


  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    height: '10rem',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: '',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize'],
      ['insertImage'],
      ['insertVideo'],
    ]
  };


  editorConfig2: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    height: '20rem',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: '',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize'],
      ['insertImage'],
      ['insertVideo'],
    ]
  };

  constructor(
    public httpClient: HttpClient,
  ) {

  }

  ngOnInit(): void {

    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/general/listnotes.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('NoteType', encodeURIComponent(this.NoteType));
    body.set('ElementID', encodeURIComponent(this.ElementID));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        var APIresult: any = result;
        if (result == null) {
        }
        else {
          this.notesList = APIresult.notes;
        }
      },
      error => {
      });
  }

  DeleteNote(id): void {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this operation",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/general/deletenote.aspx";    // set header for http call
        let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
        // set body
        let body = new URLSearchParams();
        body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
        body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
        body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
        body.set('NoteType', encodeURIComponent(this.NoteType));
        body.set('ElementID', encodeURIComponent(this.ElementID));
        body.set('noteid', encodeURIComponent(id));

        this.httpClient.post(APIURL, body.toString(), options).subscribe(
          result => {
            var APIresult: any = result;
            if (result == null) {
            }
            else {
              this.notesList = APIresult.notes;
            }
          },
          error => {
          });


      }
    })

  }

  SaveNote(): void {
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/general/addnote.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('NoteType', encodeURIComponent(this.NoteType));
    body.set('ElementID', encodeURIComponent(this.ElementID));
    body.set('summary', encodeURIComponent(this.Summary));
    body.set('note', encodeURIComponent(this.Note));
    body.set('status', encodeURIComponent(this.Status));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        var APIresult: any = result;
        if (result == null) {
        }
        else {
          if (APIresult.success == "1") {
            Swal.fire('Note Added', 'Your note has been added to this element', 'info')
            this.notesList = APIresult.notes;
            this.Summary = "";
            this.Note = "";
            this.Status = "None";

          }
        }
      },
      error => {
      });
  }


  OpenNote(id): void {
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/general/opennote.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('NoteType', encodeURIComponent(this.NoteType));
    body.set('ElementID', encodeURIComponent(this.ElementID));
    body.set('noteid', encodeURIComponent(id));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        var APIresult: any = result;
        if (result == null) {
        }
        else {
          this.openSummary = APIresult.notes[0].summary;
          this.openStatus = APIresult.notes[0].status;
          this.openNote = APIresult.notes[0].note;
          this.openID=id;
          this.showNote = true;
        }
      },
      error => {
      });
  }





  SaveNoteChanges(): void {
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/general/savenotechanges.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('NoteType', encodeURIComponent(this.NoteType));
    body.set('ElementID', encodeURIComponent(this.ElementID));
    body.set('summary', encodeURIComponent(this.openSummary));
    body.set('note', encodeURIComponent(this.openNote));
    body.set('status', encodeURIComponent(this.openStatus));
    body.set('noteid', encodeURIComponent(this.openID));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        var APIresult: any = result;
        if (result == null) {
        }
        else {
          if (APIresult.success == "1") {
            Swal.fire('Note Saved', 'Your note changes were saved', 'info')
            this.notesList = APIresult.notes;
            this.Summary = "";
            this.Note = "";
            this.Status = "None";
            this.showNote = false;
          }
        }
      },
      error => {
      });
  }

}
