<h4>Superuser Features</h4>
<p>
  This section allows to modify AFEDB.
</p>
<br>
<nav class="navbar navbar-expand-lg navbar-dark" style="background-color: #000;  margin-right: -20px; margin-left: -20px">
       <button class="navbar-toggler" type="button" (click)="toggleNavbar2()">
          <span class="navbar-toggler-icon"></span>
        </button>


    <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen2 }">
        <ul class="navbar-nav mr-auto">

            <li class="nav-item">
                <a class="nav-link" routerLink="supaycommission" routerLinkActive="btn btn-sm btn-dark">Pay Commission</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="sudeletecommission" routerLinkActive="btn btn-sm btn-dark">Delete Commission</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="sudeletemember" routerLinkActive="btn btn-sm btn-dark">Delete Member or Plan</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="sudeleteagent" routerLinkActive="btn btn-sm btn-dark">Delete Agent</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="updatenipr" routerLinkActive="btn btn-sm btn-dark">Update NIPR data</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="auditfile" routerLinkActive="btn btn-sm btn-dark">Audit File</a>
            </li>

        </ul>
    </div>
</nav>
<br /><br />
<router-outlet></router-outlet>
