import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2'
import { SelectedProducts } from '../../../classes/classes'
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-agentedition',
  templateUrl: './agentedition.component.html',
  styleUrls: ['./agentedition.component.css']
})
export class AgenteditionComponent implements OnInit {
  @Input() ID: string;
  //new

  npnInd: any;
  npnAg: any;
  JSONResp2: any;

  ownPL: boolean=false;

  public newAgentType: string = "individual"
  //npn agency
  
  npnaname: string = "";
  npnaresidentnumber: string = "";
  npnatype: string = "";
  npnaein: string = "";
  npnanumber: string = "";
  npnaresidentstate: string = "";
  npnatypeoflicense: string="";
  npnaaddress: string = "";
  npnacity: string = "";
  npnastate: string = "";
  npnazip: string = "";
  
  //npn individual
  npnifirstname: string = "";
  npnimiddlename: string = "";
  npnilastname: string = "";
  npnidob: string = "";
  npnissn: string = "";
  npnitypeofbusiness: string = "Individual";
  npnitypeoflicense: string = "";
  rnpniesidentlicense: string = "";
  npniaddress: string = "";
  npnicity: string = "";
  npnistate: string = "";
  npnizip: string = "";




  public Data: any;
  public Data2: any;
  public Data3: any;
  public AdditionalData: any;
  public AdditionalData2: any;
  public AdditionalData3: any;
  public Sponsorpl: string;

  public newNote: boolean = false;

  public TempHideLog: boolean = true;

  public CurrentAgent: string;
  public endpoint: string;
  public CurrentPL: string;
  public JSONResp;
  public AllowCreate: boolean = false;
  public AllowViewMembers: boolean = false;
  public AllowDownline: boolean = false;
  public AllowViewAgentPassword: boolean = false;



  public LogActions: any;

  public ShowSponsor: boolean = false;
  public APISponsor: any;
  public editAgent: boolean = false;
  public newAgent: boolean = false;

  BackofficeType: string;
  agreement: string;
  eo: string;

  public productslist: SelectedProducts[] = [];

  DownlineType: string = "Direct Downline";
  ShowProductsBox: boolean = false;
  public AgentType: string; //business or individual

 
  public ResendEmailDialog: boolean = false;

  public showspinner: boolean = false;

  public adm: boolean = false;

  ClientsList: any;
  clientAPIresult: any;
  ProductsList: any;
  tempAPIresult: any;
  APIedit: any;
  status: string;


  logfrom: string;
  logto: string;
  logaction: string;
  logactionby: string;

  logHeaders: any;
  logRecords: any;

  logFullName: string;
  logDate: string;
  LogActionName: string;
  logIP: string;
  logTitle: string;
  logDetails: string;

  showLog: boolean = false;
  logDescription: string;


  public NewSponsorID: string;
  public NewSponsorName: string;
  public NewSponsorHCI: string;

  //**********************+ */
  //step 1 Personal Data
  //**********************+ */
  fullcompanyname: string = "";
  firstname: string = "";
  middlename: string = "";
  lastname: string = "";
  address: string = "";
  address2: string = "";
  city: string = "";
  state: string = "";
  zip: string = "";
  cellphone: string = "";
  businessphone: string = "";
  faxnumber: string = "";
  contactemail: string = "";
  email: string = "";
  password: string = "";
  password2: string = "";
  agreemobile: boolean = false;
  dob: string = "";
  dlnumber: string = "";
  dlstate: string = "";


  //business only
  domicilestate: string = "";
  owners: string = "";
  authfirstname: string = "";
  authlastname: string = "";
  authemail: string = "";
  authphone: string = "";
  hasstaffmember: string = "N";


  //**********************+ */
  //step 2 Compensation
  //**********************+ */
  payee: string = "";
  taxid: string = "";
  routing: string = "";
  account: string = "";
  accounttype: string = "";
  bankname: string = "";
  beneficiaryname: string = "";

  //**********************+ */
  //step 3 License
  //**********************+ */
  typeofbusiness: string = "";
  typeoflicense: string = "";
  residentlicense: string = "";
  residentstate: string = "";
  npmnumber: string = "";
  haseocoverage: string = "y";
  eoamount: string = "";
  eofile: File;
  eoexpiration: string = "";

  public APIresult: any = [];

  public APIresult2: any;
  public APIresult3: any;
  public APIresult4: any;

  public APIresult5: any;

  public AgentsList: any;
  public TotalAgents: number;

  public Q1: string;
  public Q2: string;
  public Q3: string;
  public Q4: string;
  public Q5: string;
  public Q6: string;
  public Q7: string;
  public Q8: string;
  public Q9: string;
  public Q10: string;
  public Q11: string;
  public Q12: string;
  public Q13: string;
  public Q14: string;
  public Q15: string;

  A1: string = "";
  A2: string = "";
  A3: string = "";
  A4: string = "";
  A5: string = "";
  A6: string = "";
  A7: string = "";
  A8: string = "";
  A9: string = "";
  A10: string = "";
  A11: string = "";
  A12: string = "";
  A13: string = "";
  A14: string = "";
  A15: string = "";


  //**********************+ */
  //step 4 Website
  //**********************+ */
  webcompanyname: string = "";
  webfirstname: string = "";
  weblastname: string = "";
  webemail: string = "";
  webphone: string = "";
  webfulladdress: string = "";
  webaboutus: string = "";

  //**********************+ */
  //step 5 Review and Sign
  //**********************+ */
  agreesales: boolean = false;
  agreemarketing: boolean = false;
  esignature: string = "";
  pdfSrc: string;


  //json variables
  public products: any[];

  //titles and operations not sent to the API
  public addressTitle: string;
  public havestaff: boolean = false;
  public tabindex: number = 0;
  public inputError: boolean = true;
  public doPDF: boolean = false;


  public USstates = [
    {
      "Id": 83,
      "Code": "AL",
      "Name": "Alabama"
    },
    {
      "Id": 82,
      "Code": "AK",
      "Name": "Alaska"
    },
    {
      "Id": 87,
      "Code": "AZ",
      "Name": "Arizona"
    },
    {
      "Id": 85,
      "Code": "AR",
      "Name": "Arkansas"
    },
    {
      "Id": 88,
      "Code": "CA",
      "Name": "California"
    },
    {
      "Id": 89,
      "Code": "CO",
      "Name": "Colorado"
    },
    {
      "Id": 90,
      "Code": "CT",
      "Name": "Connecticut"
    },
    {
      "Id": 92,
      "Code": "DE",
      "Name": "Delaware"
    },
    {
      "Id": 91,
      "Code": "DC",
      "Name": "District of Columbia"
    },
    {
      "Id": 93,
      "Code": "FL",
      "Name": "Florida"
    },
    {
      "Id": 95,
      "Code": "GA",
      "Name": "Georgia"
    },
    {
      "Id": 96,
      "Code": "GU",
      "Name": "Guam"
    },
    {
      "Id": 97,
      "Code": "HI",
      "Name": "Hawaii"
    },
    {
      "Id": 99,
      "Code": "ID",
      "Name": "Idaho"
    },
    {
      "Id": 100,
      "Code": "IL",
      "Name": "Illinois"
    },
    {
      "Id": 101,
      "Code": "IN",
      "Name": "Indiana"
    },
    {
      "Id": 98,
      "Code": "IA",
      "Name": "Iowa"
    },
    {
      "Id": 102,
      "Code": "KS",
      "Name": "Kansas"
    },
    {
      "Id": 103,
      "Code": "KY",
      "Name": "Kentucky"
    },
    {
      "Id": 104,
      "Code": "LA",
      "Name": "Louisiana"
    },
    {
      "Id": 107,
      "Code": "ME",
      "Name": "Maine"
    },
    {
      "Id": 106,
      "Code": "MD",
      "Name": "Maryland"
    },
    {
      "Id": 105,
      "Code": "MA",
      "Name": "Massachusetts"
    },
    {
      "Id": 109,
      "Code": "MI",
      "Name": "Michigan"
    },
    {
      "Id": 110,
      "Code": "MN",
      "Name": "Minnesota"
    },
    {
      "Id": 113,
      "Code": "MS",
      "Name": "Mississippi"
    },
    {
      "Id": 111,
      "Code": "MO",
      "Name": "Missouri"
    },
    {
      "Id": 114,
      "Code": "MT",
      "Name": "Montana"
    },
    {
      "Id": 117,
      "Code": "NE",
      "Name": "Nebraska"
    },
    {
      "Id": 121,
      "Code": "NV",
      "Name": "Nevada"
    },
    {
      "Id": 118,
      "Code": "NH",
      "Name": "New Hampshire"
    },
    {
      "Id": 119,
      "Code": "NJ",
      "Name": "New Jersey"
    },
    {
      "Id": 120,
      "Code": "NM",
      "Name": "New Mexico"
    },
    {
      "Id": 122,
      "Code": "NY",
      "Name": "New York"
    },
    {
      "Id": 115,
      "Code": "NC",
      "Name": "North Carolina"
    },
    {
      "Id": 116,
      "Code": "ND",
      "Name": "North Dakota"
    },
    {
      "Id": 79,
      "Code": "00",
      "Name": "Not Determined"
    },
    {
      "Id": 123,
      "Code": "OH",
      "Name": "Ohio"
    },
    {
      "Id": 124,
      "Code": "OK",
      "Name": "Oklahoma"
    },
    {
      "Id": 125,
      "Code": "OR",
      "Name": "Oregon"
    },
    {
      "Id": 126,
      "Code": "PA",
      "Name": "Pennsylvania"
    },
    {
      "Id": 127,
      "Code": "PR",
      "Name": "Puerto Rico"
    },
    {
      "Id": 128,
      "Code": "RI",
      "Name": "Rhode Island"
    },
    {
      "Id": 129,
      "Code": "SC",
      "Name": "South Carolina"
    },
    {
      "Id": 130,
      "Code": "SD",
      "Name": "South Dakota"
    },
    {
      "Id": 131,
      "Code": "TN",
      "Name": "Tennessee"
    },
    {
      "Id": 132,
      "Code": "TX",
      "Name": "Texas"
    },
    {
      "Id": 133,
      "Code": "UT",
      "Name": "Utah"
    },
    {
      "Id": 136,
      "Code": "VT",
      "Name": "Vermont"
    },
    {
      "Id": 11142,
      "Code": "VI",
      "Name": "Virgin Islands"
    },
    {
      "Id": 134,
      "Code": "VA",
      "Name": "Virginia"
    },
    {
      "Id": 137,
      "Code": "WA",
      "Name": "Washington"
    },
    {
      "Id": 139,
      "Code": "WV",
      "Name": "West Virginia"
    },
    {
      "Id": 138,
      "Code": "WI",
      "Name": "Wisconsin"
    },
    {
      "Id": 140,
      "Code": "WY",
      "Name": "Wyoming"
    }
  ]


  public EmailContent: string;
  public EmailTo: string;
  public EmailCc: string;
  public EmailBcc: string;


  editorConfig: AngularEditorConfig = {
    editable: false,
    spellcheck: false,
    height: '25rem',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: false,
    placeholder: '',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize'],
      ['insertImage'],
      ['insertVideo'],
    ]
  };

  // custom paygrid


  isCode: boolean = false;

  APIafe1: any;
  APIafe2: any;
  APIdental: any;
  APIlife: any;
  APIchubb: any;
  APItemplates: any;
  APIdownline: any;

  uplineAPIafe1: any;
  uplineAPIafe2: any;
  uplineAPIdental: any;
  uplineAPIlife: any;
  uplineAPIchubb: any;
  uplineAPItemplates: any;
  uplineAPIdownline: any;


  allowcode: boolean = true;

  templatecode: any;

  mycode: string;
  sendemail: boolean = true;
  sendsms: boolean = true;
  myself: string = "";
  myid: string = "";


  /*Gone*/
  myafe1: number;
  myafe2: number;
  mydental: number;
  mylife: number;
  mychubb: number;
  /*Gone*/
  uplinemyafe1: number;
  uplinemyafe2: number;
  uplinemydental: number;
  uplinemylife: number;
  uplinemychubb: number;

  selectedafe1: number;
  selectedafe2: number;
  selecteddental: number;
  selectedlife: number;
  selectedchubb: number;

  uplineselectedafe1: number;
  uplineselectedafe2: number;
  uplineselecteddental: number;
  uplineselectedlife: number;
  uplineselectedchubb: number;

  showdental: boolean = false;
  showlife: boolean = false;
  showchubb: boolean = false;

  uplineshowdental: boolean = false;
  uplineshowlife: boolean = false;
  uplineshowchubb: boolean = false;
  selectedPaygrid: string;

  //for this specific module
  agentname: string;


  constructor(
    public httpClient: HttpClient,
    private _router: Router,
    private r: ActivatedRoute,
    private toastr: ToastrService
  ) {
    this.CurrentAgent = sessionStorage.getItem('plid');
    this.endpoint = sessionStorage.getItem('endpoint');
    this.CurrentPL = this.ID;
    if (!this.ID) {
      this.ID=sessionStorage.getItem("temp");
    }
    if (sessionStorage.getItem("afetype") == "24485168") {
      this.adm = true;
    }

    if (sessionStorage.getItem("agency") == "1") {
      this.AllowCreate = true;
      this.AllowViewMembers = true;
      this.AllowDownline = true;
      this.AllowViewAgentPassword = true
    }

    if (sessionStorage.getItem("agency") == "2") {
      this.AllowCreate = false;
      this.AllowViewMembers = true;
      this.AllowDownline = true;
      this.AllowViewAgentPassword = true;
    }

    if (sessionStorage.getItem('plid')==sessionStorage.getItem('temp')){
      this.ownPL=true;
    }

    


  }

  SelectAgent(id, action): void {
    this.ID=id;
    this.ngOnInit()
    return;
    var DoAllow: boolean = false;
    if (this.AllowCreate) { DoAllow = true };
    if (this.AllowViewMembers) { DoAllow = true };

    if (DoAllow) {


      this.uplineAPIlife = undefined;
      this.uplineAPIdental = undefined;
      this.uplineAPIchubb = undefined;
      sessionStorage.setItem("temp", id);

      this.showspinner = true;
      //this.agentname=sessionStorage.getItem("firstname").trim() + " " + sessionStorage.getItem("lastname").trim()


      let currentUrl = this._router.url;
      this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this._router.navigate([currentUrl]);
      });
      return

      this.GetAgentInfo('n')
      this.GetProducts()
      this.ListAgents(this.ID, "direct")
      this.ListMembers(this.ID)
      this.showspinner = false;

    }
  }





  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9

    if (charCode >= 48 && charCode <= 57) {
      return true;
      return;
    }
    if (charCode == 46) {
      return true;
      return;
    }

    event.preventDefault();
    return false;

  }



  TryParse(item): boolean {
    item = typeof item !== "string"
      ? JSON.stringify(item)
      : item;

    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }

    if (typeof item === "object" && item !== null) {
      return true;
    }

    return false;
  }

  onFileChanged(event) {
    this.eofile = event.target.files[0]
    if (this.eofile) {
      if (this.eofile.size > 10240000) {
        Swal.fire({
          title: 'File Too Big',
          text: 'Your E&O Certificate file size cannot be greater than 10Mb',
          icon: 'error',
        })

        return;
      }
    }
  }



  SaveAgent(): void {




    if (!this.esignature) {
      this.WarningMessage("Please eSign the agreement.");
      return;
    }

    if (this.esignature != this.agentname.trim()) {
      this.WarningMessage("The expected eSignature is: " + this.agentname.trim());
      return;
    }


    this.inputError == false;
    this.CheckEntry(4);
    if (this.inputError == true) {
      this.WarningMessage("Please correct your entry errors.");
      return;
    }

    if (this.eofile) {
      if (this.eofile.size > 10024000) {
        Swal.fire({
          title: 'File Too Big',
          text: 'Your E&O Certificate file size cannot be greater than 10Mb',
          icon: 'error',
        })
        return;
      }
    }




    this.showspinner = true;
    //call API

    var APIURL = sessionStorage.getItem('endpoint') + "afeapi/desk/agents/agencyaddagent.aspx";    // set header for http call
    let formData = new FormData();
    if (this.eofile) {
      formData.append("myfile", this.eofile, this.eofile.name);
    }

    formData.append('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    formData.append('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    formData.append('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    formData.append('pl', encodeURIComponent(this.ID));

    formData.set('products', encodeURIComponent(JSON.stringify(this.Data2)));


    formData.append('type', encodeURIComponent(this.newAgentType));
    formData.append('code', encodeURIComponent(sessionStorage.getItem('paygrid')));
    formData.append('authkey', encodeURIComponent(sessionStorage.getItem('agentkey')));
    formData.append('id', encodeURIComponent(sessionStorage.getItem("id")));
    //parameters
    formData.append('hasstaffmember', encodeURIComponent(this.hasstaffmember));
    formData.append('fullcompanyname', encodeURIComponent(this.firstname + " " + this.lastname));
    formData.append('domicilestate', encodeURIComponent(this.domicilestate));
    formData.append('owners', encodeURIComponent(this.owners));
    formData.append('authfirstname', encodeURIComponent(this.authfirstname));
    formData.append('authlastname', encodeURIComponent(this.authlastname));
    formData.append('authemail', encodeURIComponent(this.authemail));
    formData.append('authphone', encodeURIComponent(this.authphone));
    formData.append('firstname', encodeURIComponent(this.firstname));
    formData.append('middlename', encodeURIComponent(this.middlename));
    formData.append('lastname', encodeURIComponent(this.lastname));
    formData.append('dob', encodeURIComponent(this.dob));
    formData.append('dlnumber', encodeURIComponent(this.dlnumber));
    formData.append('dlstate', encodeURIComponent(this.dlstate));
    formData.append('address', encodeURIComponent(this.address));
    formData.append('address2', encodeURIComponent(this.address2));
    formData.append('city', encodeURIComponent(this.city));
    formData.append('state', encodeURIComponent(this.state));
    let newObj = this.USstates.filter((value) => {
      return value.Code.indexOf(this.state) != -1 ? value : null
    });
    let StateID: number = newObj[0].Id;
    let StateName: string = newObj[0].Name
    formData.append('stateid', encodeURIComponent(StateID));
    formData.append('statename', encodeURIComponent(StateName));

    formData.append('zip', encodeURIComponent(this.zip));
    formData.append('cellphone', encodeURIComponent(this.cellphone));
    formData.append('businessphone', encodeURIComponent(this.businessphone));
    formData.append('faxnumber', encodeURIComponent(this.faxnumber));
    formData.append('contactemail', encodeURIComponent(this.contactemail));
    formData.append('agreemobile', encodeURIComponent(this.agreemobile));
    formData.append('email', encodeURIComponent(this.contactemail));
    formData.append('password', encodeURIComponent(this.password));

    formData.append('payee', encodeURIComponent(this.payee));
    formData.append('taxid', encodeURIComponent(this.taxid));
    formData.append('routing', encodeURIComponent(this.routing));
    formData.append('account', encodeURIComponent(this.account));
    formData.append('accounttype', encodeURIComponent(this.accounttype));
    formData.append('bankname', encodeURIComponent(this.bankname));
    formData.append('beneficiaryname', encodeURIComponent(this.beneficiaryname));

    formData.append('typeofbusiness', encodeURIComponent(this.typeofbusiness));
    formData.append('typeoflicense', encodeURIComponent(this.typeoflicense));
    formData.append('residentlicense', encodeURIComponent(this.residentlicense));
    formData.append('residentstate', encodeURIComponent(this.residentstate));
    formData.append('npmnumber', encodeURIComponent(this.npmnumber));
    formData.append('haseocoverage', encodeURIComponent(this.haseocoverage));

    if (!this.isNumber(this.eoamount)) { this.eoamount = "0" }

    formData.append('agenttype', encodeURIComponent(this.newAgentType));

    formData.append('eoamount', encodeURIComponent(this.eoamount));
    formData.append('eoexpiration', encodeURIComponent(this.eoexpiration));
    //    body.set('eofile', encodeURIComponent(this.eofile));
    formData.append('Q1', encodeURIComponent(this.Q1));
    formData.append('Q2', encodeURIComponent(this.Q2));
    formData.append('Q3', encodeURIComponent(this.Q3));
    formData.append('Q4', encodeURIComponent(this.Q4));
    formData.append('Q5', encodeURIComponent(this.Q5));
    formData.append('Q6', encodeURIComponent(this.Q6));
    formData.append('Q7', encodeURIComponent(this.Q7));
    formData.append('Q8', encodeURIComponent(this.Q8));
    formData.append('Q9', encodeURIComponent(this.Q9));
    formData.append('Q10', encodeURIComponent(this.Q10));
    formData.append('Q11', encodeURIComponent(this.Q11));
    formData.append('Q12', encodeURIComponent(this.Q12));
    formData.append('Q13', encodeURIComponent(this.Q13));
    formData.append('Q14', encodeURIComponent(this.Q14));
    formData.append('Q15', encodeURIComponent(this.Q15));

    formData.append('A1', encodeURIComponent(this.A1.substr(0, 100)));
    formData.append('A2', encodeURIComponent(this.A2.substr(0, 100)));
    formData.append('A3', encodeURIComponent(this.A3.substr(0, 100)));
    formData.append('A4', encodeURIComponent(this.A4.substr(0, 100)));
    formData.append('A5', encodeURIComponent(this.A5.substr(0, 100)));
    formData.append('A6', encodeURIComponent(this.A6.substr(0, 100)));
    formData.append('A7', encodeURIComponent(this.A7.substr(0, 100)));
    formData.append('A8', encodeURIComponent(this.A8.substr(0, 100)));
    formData.append('A9', encodeURIComponent(this.A9.substr(0, 100)));
    formData.append('A10', encodeURIComponent(this.A10.substr(0, 100)));
    formData.append('A11', encodeURIComponent(this.A11.substr(0, 100)));
    formData.append('A12', encodeURIComponent(this.A12.substr(0, 100)));
    formData.append('A13', encodeURIComponent(this.A13.substr(0, 100)));
    formData.append('A14', encodeURIComponent(this.A14.substr(0, 100)));
    formData.append('A15', encodeURIComponent(this.A15.substr(0, 100)));


    //new fields
    formData.append('npnaname', encodeURIComponent(this.npnaname));
    formData.append('npnaresidentnumber', encodeURIComponent(this.npnaresidentnumber));
    formData.append('npnatype', encodeURIComponent(this.npnatype));
    formData.append('npnaein', encodeURIComponent(this.npnaein));
    formData.append('npnanumber', encodeURIComponent(this.npnanumber));
    formData.append('npnaaddress', encodeURIComponent(this.npnaaddress));
    formData.append('npnacity', encodeURIComponent(this.npnacity));
    formData.append('npnastate', encodeURIComponent(this.npnastate));
    formData.append('npnazip', encodeURIComponent(this.npnazip));    
    formData.append('npnaresidentstate', encodeURIComponent(this.npnaresidentstate));
    formData.append('npnatypeoflicense', encodeURIComponent(this.npnatypeoflicense));

    formData.append('npnifirstname', encodeURIComponent(this.npnifirstname));
    formData.append('npnimiddlename', encodeURIComponent(this.npnimiddlename));
    formData.append('npnilastname', encodeURIComponent(this.npnilastname));
    formData.append('npnidob', encodeURIComponent(this.npnidob));
    formData.append('npnissn', encodeURIComponent(this.npnissn));
    formData.append('npnitypeofbusiness', encodeURIComponent(this.npnitypeofbusiness));
    formData.append('npnitypeoflicense', encodeURIComponent(this.npnitypeoflicense));
    formData.append('rnpniesidentlicense', encodeURIComponent(this.rnpniesidentlicense));
    formData.append('npniaddress', encodeURIComponent(this.npniaddress));
    formData.append('npnicity', encodeURIComponent(this.npnicity));
    formData.append('npnistate', encodeURIComponent(this.npnistate));
    formData.append('npnizip', encodeURIComponent(this.npnizip));
    formData.append('npninumber', encodeURIComponent(this.npmnumber));


    formData.append('webcompanyname', encodeURIComponent(this.firstname + " " + this.lastname));
    formData.append('webfirstname', encodeURIComponent(this.webfirstname));
    formData.append('weblastname', encodeURIComponent(this.weblastname));
    formData.append('webemail', encodeURIComponent(this.webemail));
    formData.append('webphone', encodeURIComponent(this.webphone));
    formData.append('webfulladdress', encodeURIComponent(this.webfulladdress));
    formData.append('webaboutus', encodeURIComponent(this.webaboutus));
    formData.append('esignature', encodeURIComponent(this.esignature));


    this.httpClient.post(APIURL, formData).subscribe(
      result => {
        this.APIresult2 = Array.of(result);
        if (result == null) {
          this.showspinner = false;
          Swal.fire({
            title: 'Connection aborted',
            text: "A connection to AFEUSA servers could not be established.",
            icon: 'error',

          })
        }
        else {
          this.showspinner = false;
          if (this.APIresult2[0].success == "1") {
            sessionStorage.removeItem("temp");

            Swal.fire({
              title: 'Agent Created',
              text: "Thank you. Your new agent has been created. You will be notified once it is approved by AFEUSA.",
              icon: 'success',

            })
            this.newAgent=false;

            return;
          }

          if (this.APIresult2[0].success == "0") {
            Swal.fire({
              title: 'Error',
              text: this.APIresult2[0].message,
              icon: 'error',

            })
          }


        }

      },
      error => {
        this.showspinner = false;
        Swal.fire({
          title: 'Connection aborted',
          text: "A connection to AFEUSA servers could not be established.",
          icon: 'error',

        })
      });

  }


  WarningMessage(message: string): void {
    Swal.fire({
      title: 'Check the required fields',
      text: message,
      icon: 'warning',

    })
  }

  changestaff(values: any): void {
    if (values.currentTarget.checked == true) {
      this.havestaff = true;
      this.hasstaffmember = "Y";
    }
    else {
      this.havestaff = false;
      this.hasstaffmember = "N";
    }
  }

  IsFutureDate(mydate: string): boolean {
    var isdate = Date.parse(mydate);
    if (isNaN(isdate)) {
      return false;
    }

    var EnteredDate = new Date(isdate)
    var TodayIs = new Date()
    if (EnteredDate > TodayIs) {
      return true;
    }
    return false;
  }

  IsDate(mydate: string): boolean {
    var isdate = Date.parse(mydate);
    if (isNaN(isdate)) {
      return false;
    }

    var EnteredDate = new Date(isdate)
    var TodayIs = new Date()
    if (EnteredDate > TodayIs) {
      return false;
    }
    var Difference_In_Time = EnteredDate.getTime() - new Date().getTime();
    var Difference_In_Days = ((Difference_In_Time / (1000 * 3600 * 24)) / 365) * (-1);
    if (Difference_In_Days > 100) {
      return false;
    }
    if (Difference_In_Days < 18) {
      return false;
    }
    return true;
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


  isNumber(value: string | number): boolean {
    return ((value != null) &&
      (value !== '') &&
      !isNaN(Number(value.toString())));
  }



  CheckEntry(tab: number): void {
    this.inputError = false;

    //check tab 1

    if (!this.firstname) { this.WarningMessage("Enter your first name"); this.tabindex = 0; this.inputError = true; return; }
    if (!this.lastname) { this.WarningMessage("Enter your last name"); this.tabindex = 0; this.inputError = true; return; }
    //    if (!this.IsDate(this.dob)) { this.WarningMessage("Enter a valid date of birth. Must be 18 or older to join AFEUSA."); this.tabindex = 0; this.inputError = true; return; }
    //    if (!this.address) { this.WarningMessage("Enter your address"); this.tabindex = 0; this.inputError = true; return; }
    //    if (!this.city) { this.WarningMessage("Enter your city"); this.tabindex = 0; this.inputError = true; return; }
    //    if (!this.state) { this.WarningMessage("Select your state"); this.tabindex = 0; this.inputError = true; return; }
    //    if (this.isNumber(this.zip) == false) { this.WarningMessage("Enter your ZIP code"); this.tabindex = 0; this.inputError = true; return; }

    if (!this.fullcompanyname && this.AgentType == 'individual') {
      this.fullcompanyname = this.firstname + " " + this.lastname;
    }


    //    if (!this.cellphone) { this.WarningMessage("Enter your Cell Phone number"); this.tabindex = 0; this.inputError = true; return; }
    //    if (this.cellphone.length != 14) { this.WarningMessage("Enter a valid Cell Phone number"); this.tabindex = 0; this.inputError = true; return; }
    if (!this.contactemail) { this.WarningMessage("Enter the email address you want to utilize for contacting you"); this.tabindex = 0; this.inputError = true; return; }
    if (this.validateEmail(this.contactemail) == false) { this.WarningMessage("Enter a valid email address for us to contact you"); this.tabindex = 0; this.inputError = true; return; }


    if (!this.password) { this.WarningMessage("Enter the password you will utilize for accessing your backoffice"); this.tabindex = 0; this.inputError = true; return; }
    if (!this.password2) { this.WarningMessage("Re-enter the password you will utilize for accessing your backoffice"); this.tabindex = 0; this.inputError = true; return; }
    if (this.password.length < 8) { this.WarningMessage("Your password should be at least 8 characters long"); this.tabindex = 0; this.inputError = true; return; }
    if (this.password != this.password2) { this.WarningMessage("Password and password confirmation do not match"); this.tabindex = 0; this.inputError = true; return; }

    if (tab == 1) {
      this.tabindex = tab;
      window.scrollTo(0, 0)
      return
    }
    // end check tab 1


    //check step 2

    if (tab == 2) {
      this.tabindex = tab;
      window.scrollTo(0, 0)
      return
    }
    //end step 2



    if (tab == 3) {
      this.tabindex = tab;
      window.scrollTo(0, 0)
      return
    }
    //end check step 3

    if (tab == 4) {
      this.tabindex = tab;
      window.scrollTo(0, 0)
      return
    }


  }


  changeTab(value: number): void {

    this.CopyToWebsite();
    this.CheckEntry(value);

  }


  onTabChange(event): void {
    this.tabindex = event.index;
    this.doPDF = false;
    this.CopyToWebsite();
  }


  CopyToWebsite(): void {
    if (!this.webcompanyname) {
      this.webcompanyname = this.fullcompanyname;
    }
    if (!this.webfirstname) {
      this.webfirstname = this.firstname;
    }
    if (!this.weblastname) {
      this.weblastname = this.lastname;
    }
    if (!this.webemail) {
      this.webemail = this.contactemail;
    }

    if (!this.webphone) {
      this.webphone = this.cellphone;
    }

  }



  GetProducts(): void {
    this.mychubb = undefined;
    this.mylife = undefined;
    this.mydental = undefined;
    this.myafe1 = undefined;
    this.myafe2 = undefined;
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/getproductsforDownline.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('pl', encodeURIComponent(this.ID));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.APIresult3 = Array.of(result);
        if (result == null) {
          Swal.fire({
            title: 'Try again',
            text: 'Invalid credentials. Please try again.',
            icon: 'error',
          })
        }
        else {
          if (this.APIresult3[0].success == "1") {

            this.myself = this.APIresult3[0].myself;
            this.myid = sessionStorage.getItem('plid');
            //get templates
            if (this.APIresult3[0].hasOwnProperty('templates')) {
              this.APItemplates = this.APIresult3[0].templates
            }
            //get downline
            if (this.APIresult3[0].hasOwnProperty('downline')) {
              this.APIdownline = this.APIresult3[0].downline
            }
            //get AFE Basic
            if (this.APIresult3[0].hasOwnProperty('AFEUSA')) {
              this.APIafe1 = this.APIresult3[0].AFEUSA
              this.myafe1 = this.APIafe1[0].comp

            }
            //get AFE Premium
            if (this.APIresult3[0].hasOwnProperty('AFEUSA2')) {
              this.APIafe2 = this.APIresult3[0].AFEUSA2
              this.myafe2 = this.APIafe2[0].comp
            }
            //get Dental
            if (this.APIresult3[0].hasOwnProperty('Dental')) {
              this.APIdental = this.APIresult3[0].Dental
              this.mydental = this.APIdental[0].comp
            }
            //get Chubb
            if (this.APIresult3[0].hasOwnProperty('Chubb')) {
              this.APIchubb = this.APIresult3[0].Chubb
              this.mychubb = this.APIchubb[0].comp
            }

            //get Life
            if (this.APIresult3[0].hasOwnProperty('Life')) {
              this.APIlife = this.APIresult3[0].Life
              this.mylife = this.APIlife[0].comp
            }

            for (let result of this.APIresult[0].products) {
              if (result.productid == 1) {
                this.myafe1 = result.Y1;
              }
              if (result.productid == 7) {
                this.myafe2 = result.Y1;
              }
              if (result.productid == 2) {
                this.mydental = result.Y1;
              }
              if (result.productid == 3) {
                this.mychubb = result.Y1;
              }
              if (result.productid == 6) {
                this.mylife = result.Y1;
              }
            }




            //UPLINE VARIABLES
            //get AFE Basic
            if (this.APIresult3[0].upline.hasOwnProperty('AFEUSA')) {
              this.uplineAPIafe1 = this.APIresult3[0].upline.AFEUSA
              this.uplinemyafe1 = this.uplineAPIafe1[0].comp
            }
            //get AFE Premium
            if (this.APIresult3[0].upline.hasOwnProperty('AFEUSA2')) {
              this.uplineAPIafe2 = this.APIresult3[0].upline.AFEUSA2
              this.uplinemyafe2 = this.uplineAPIafe2[0].comp
            }
            //get Dental
            if (this.APIresult3[0].upline.hasOwnProperty('Dental')) {
              this.uplineAPIdental = this.APIresult3[0].upline.Dental
              this.uplinemydental = this.uplineAPIdental[0].comp
              this.uplineshowdental = true;
            }
            //get Chubb
            if (this.APIresult3[0].upline.hasOwnProperty('Chubb')) {
              this.uplineAPIchubb = this.APIresult3[0].upline.Chubb
              this.uplinemychubb = this.uplineAPIchubb[0].comp
              this.uplineshowchubb = true;
            }

            //get Life
            if (this.APIresult3[0].upline.hasOwnProperty('Life')) {
              this.uplineAPIlife = this.APIresult3[0].upline.Life
              this.uplinemylife = this.uplineAPIlife[0].comp
              this.uplineshowlife = true;
            }


          }
          else {
            Swal.fire({
              title: 'Please check your input fields',
              text: this.APIresult3[0].message,
              icon: 'error',

            })
          }

        }

      },
      error => {

      });
  }



  yyyymmdd() {
    var x = new Date();
    var y = x.getFullYear().toString();
    var m = (x.getMonth() + 1).toString();
    var d = x.getDate().toString();
    var hs = x.getHours().toString();
    var mn = x.getMinutes().toString();
    var sc = x.getSeconds().toString();

    (d.length == 1) && (d = '0' + d);
    (m.length == 1) && (m = '0' + m);
    (hs.length == 1) && (hs = '0' + hs);
    (mn.length == 1) && (mn = '0' + mn);
    (sc.length == 1) && (sc = '0' + sc);
    var yyyymmdd = y + m + d + hs + mn + sc;
    return yyyymmdd;
  }

  getCode(): void {
    this.templatecode = "CM-" + sessionStorage.getItem("codeidentifier") + "-" + this.yyyymmdd();
  }

  getPaygrid(): void {

    if (this.selectedPaygrid == "") {
      this.isCode = false;
      //get Dental
      if (this.APIresult3[0].hasOwnProperty('Dental')) {
        this.showdental = true;
      }
      //get Chubb
      if (this.APIresult3[0].hasOwnProperty('Chubb')) {
        this.showchubb = true;
      }

      //get Life
      if (this.APIresult3[0].hasOwnProperty('Life')) {
        this.showlife = true;
      }
      this.allowcode = true;
      this.getCode();
    }
    else {
      this.isCode = true;
      this.allowcode = false;
      for (let result of this.APIresult3[0].templates) {
        if (result.id == this.selectedPaygrid) {
          this.templatecode = result.code
          if ("AFEUSA" in result.payout[0]) {
            this.selectedafe1 = result.payout[0].AFEUSA;
          }

          if ("AFEUSA2" in result.payout[0]) {
            this.selectedafe2 = result.payout[0].AFEUSA2;
          }

          if ("Dental" in result.payout[0]) {
            this.selecteddental = result.payout[0].Dental;
            this.showdental = true;
          }
          else {
            this.showdental = false;
          }


          if ("Chubb" in result.payout[0]) {
            this.selectedchubb = result.payout[0].Chubb;
            this.showchubb = true;
          }
          else {
            this.showchubb = false;
          }


          if ("Life" in result.payout[0]) {
            this.selectedlife = result.payout[0].Life;
            this.showlife = true;
          }
          else {
            this.showlife = false;
          }


        }
      }




    }





  }




  keyPressLettersNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9

    if (charCode >= 48 && charCode <= 57) {
      return true;
      return;
    }

    if (charCode >= 65 && charCode <= 90) {
      return true;
      return;
    }

    if (charCode >= 97 && charCode <= 122) {
      return true;
      return;
    }

    event.preventDefault();
    return false;

  }





  GetAgentInfo(track): void {
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/viewagent.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('pl', encodeURIComponent(this.ID));
    body.set('track', track);

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.APIresult = Array.of(result);
        if (result == null) {

        }
        else {
          if (this.APIresult[0].success == "1") {

            try {
              this.EmailTo = this.APIresult[0].agentinfo.email
              this.BackofficeType = this.APIresult[0].type;
              this.LogActions = this.APIresult[0].actions

              this.status = this.APIresult[0].agentinfo.status.toString();
              this.Q1 = this.APIresult[0].questions[0].qAnswer;
              this.A1 = this.APIresult[0].questions[0].qExplanation;

              this.Q2 = this.APIresult[0].questions[1].qAnswer;
              this.A2 = this.APIresult[0].questions[1].qExplanation;

              this.Q3 = this.APIresult[0].questions[2].qAnswer;
              this.A3 = this.APIresult[0].questions[2].qExplanation;

              this.Q4 = this.APIresult[0].questions[3].qAnswer;
              this.A4 = this.APIresult[0].questions[3].qExplanation;

              this.Q5 = this.APIresult[0].questions[4].qAnswer;
              this.A5 = this.APIresult[0].questions[4].qExplanation;

              this.Q6 = this.APIresult[0].questions[5].qAnswer;
              this.A6 = this.APIresult[0].questions[5].qExplanation;

              this.Q7 = this.APIresult[0].questions[6].qAnswer;
              this.A7 = this.APIresult[0].questions[6].qExplanation;

              this.Q8 = this.APIresult[0].questions[7].qAnswer;
              this.A8 = this.APIresult[0].questions[7].qExplanation;

              this.Q9 = this.APIresult[0].questions[8].qAnswer;
              this.A9 = this.APIresult[0].questions[8].qExplanation;

              this.Q10 = this.APIresult[0].questions[9].qAnswer;
              this.A10 = this.APIresult[0].questions[9].qExplanation;

              this.Q11 = this.APIresult[0].questions[10].qAnswer;
              this.A11 = this.APIresult[0].questions[10].qExplanation;

              this.Q12 = this.APIresult[0].questions[11].qAnswer;
              this.A12 = this.APIresult[0].questions[11].qExplanation;

              this.Q13 = this.APIresult[0].questions[12].qAnswer;
              this.A13 = this.APIresult[0].questions[12].qExplanation;

              this.Q14 = this.APIresult[0].questions[13].qAnswer;
              this.A14 = this.APIresult[0].questions[13].qExplanation;

              this.Q15 = this.APIresult[0].questions[14].qAnswer;
              this.A15 = this.APIresult[0].questions[14].qExplanation;
            }
            catch {

            }

            if (!this.APIresult[0].agentinfo.agentprincipalid) {
              this.AllowCreate = false;
            }
            else {
              this.AllowCreate = true;
            }

            this.Sponsorpl = this.APIresult[0].agentinfo.SponsorPL;

            if (this.APIresult[0].agentinfo.status == 50) {
              this.AllowViewMembers = false;
            }
            else {
              this.AllowViewMembers = true;
            }

            //files

            if (this.APIresult[0].files.agr) {
              this.agreement = sessionStorage.getItem("endpoint") + "afeapi/desk/corporate/getdocument.aspx?filename=" + this.APIresult[0].files.agr + "&sessionkey=" + encodeURIComponent(sessionStorage.getItem("sessionkey")) + "&plid=" + encodeURIComponent(sessionStorage.getItem("plid")) + "&userkey=" + encodeURIComponent(sessionStorage.getItem("userkey"))
            }
            if (this.APIresult[0].files.eo) {
              this.eo = sessionStorage.getItem("endpoint") + "afeapi/desk/corporate/getdocument.aspx?filename=" + this.APIresult[0].files.eo + "&sessionkey=" + encodeURIComponent(sessionStorage.getItem("sessionkey")) + "&plid=" + encodeURIComponent(sessionStorage.getItem("plid")) + "&userkey=" + encodeURIComponent(sessionStorage.getItem("userkey"))

            }

            this.toastr.success('You switched the agent to ' + this.APIresult[0].agentinfo.firstname + " " + this.APIresult[0].agentinfo.lastname, 'Agent Switched');

            this.GetProducts();
            this.GetComp();
            this.GetMyComp();
            this.GetUplineComp();

            return;
          }

        }

      },
      error => {

      });
  }


  FormatMoney(amount) {
    return (Number(amount).toLocaleString())

  }




  Me(): void {
    this.DownlineType = "Direct Downline"
    this.ListAgents(this.ID, "direct")
  }

  Full(): void {
    this.DownlineType = "Full Downline"
    this.ListAgents(this.ID, "all")
  }

  ListAgents(ID, type): void {
    this.showspinner = true;
    if (type == 'direct') { this.DownlineType = "Direct Downline" };
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/listagents.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('pl', encodeURIComponent(ID));
    body.set('type', type);
    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.APIresult4 = Array.of(result);
        if (result == null) {
        }
        else {
          if (this.APIresult4[0].success == "1") {
            this.AgentsList = this.APIresult4[0].agents;
            var count = 0;
            for (var i = 0; i < this.AgentsList.length; ++i) {
              count++;
            }
            this.TotalAgents = count;
          }
        }
        this.showspinner = false;
      },
      error => {
      });
  }




  ListMembers(id: string): void {
    this.ClientsList = []
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/GetPolicies.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('pl', encodeURIComponent(id));
    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.APIresult5 = Array.of(result);
        if (result == null) {

        }
        else {
          if (this.APIresult5[0].success == "1") {
            this.ClientsList = this.APIresult5[0].clients;
          }
        }
      },
      error => {

      });
  }


  ShowProducts(id): void {
    this.ProductsList = []
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/GetProductsForPL.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('pl', encodeURIComponent(id));
    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.clientAPIresult = Array.of(result);
        if (result == null) {

        }
        else {
          if (this.clientAPIresult[0].success == "1") {
            this.ShowProductsBox = true;
            this.ProductsList = this.clientAPIresult[0].products;
          }
        }
      },
      error => {

      });
  }

  ResendEmailAPI(): void {


    var APIURL2 = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/ResendSignupEmail.aspx";    // set header for http call
    var options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    var body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('pl', encodeURIComponent(this.ID));
    body.set('to', encodeURIComponent(this.EmailTo));
    body.set('cc', encodeURIComponent(this.EmailCc));
    body.set('bcc', encodeURIComponent(this.EmailBcc));

    this.httpClient.post(APIURL2, body.toString(), options).subscribe(
      result => {
        var emailAPIresult: any = result;
        if (result == null) {

        }
        else {
          if (emailAPIresult.success == "1") {
            this.EmailCc = "";
            this.EmailBcc = "";
            this.EmailCc = "";
            Swal.fire({
              title: "Welcome email sent",
              text: 'The Welcome Email has been sent',
              icon: 'info',
            });
            this.ResendEmailDialog = false;
            return;
          }
          else {
            Swal.fire({
              title: "Error",
              text: emailAPIresult.message,
              icon: 'error',
            });

            return;
          }

        }

      },
      error => {

      });

  }



  ResendEmail(): void {

    this.ResendEmailDialog = true;
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/getemailcontent.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('pl', encodeURIComponent(this.ID));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        if (result == null) {

        }
        else {

          var EmailAPIresults: any = result;

          this.EmailContent = EmailAPIresults.emailcontent;

          return;
        }



      },
      error => {

      });


  }




  RevealPassword(): void {
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/GetPassword.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('pl', encodeURIComponent(this.ID));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.tempAPIresult = Array.of(result);
        if (result == null) {

        }
        else {
          if (this.tempAPIresult[0].success == "1") {

            Swal.fire({
              title: this.tempAPIresult[0].password,
              text: 'Find above the unencrypted password for this agent.',
              icon: 'info',
            });

            return;
          }

        }

      },
      error => {

      });
  }


  SaveCompensation(): void {


    let has1: boolean=false;
    let has7: boolean=false;

    for (let result of this.Data3) {
      if (result.productid == 1 && result.enabled=="1") {
        has1=true;
      }
      if (result.productid == 7 && result.enabled=="1") {
        has7=true;
      }
    }

    
    if (!has1) {
      Swal.fire({
        title: 'Basic Membership Missing',
        text: 'The Basic Membership is a mandatory selection',
        icon: 'warning',
      });
      return;
    }

    if (!has7) {
      Swal.fire({
        title: 'Silver Membership Missing',
        text: 'The Silver Membership is a mandatory selection',
        icon: 'warning',
      });
      return;
    }

    this.showspinner = true;
    var APIURL = sessionStorage.getItem('endpoint') + "afeapi/desk/agents/changecompensation.aspx";    // set header for http call
    let formData = new FormData();
    if (this.eofile) {
      formData.append("myfile", this.eofile, this.eofile.name);
    }

    this.APIedit.SSN = this.APIedit.BankInfo.EIN;
    this.APIedit.TaxID = this.APIedit.BankInfo.EIN;

    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    formData.append('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    formData.append('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    formData.append('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    formData.append('pl', encodeURIComponent(this.ID));
    formData.append('payload', encodeURIComponent(JSON.stringify(this.Data3)));
    formData.append('agentinfopayload', encodeURIComponent(JSON.stringify(this.APIedit)));

    this.httpClient.post(APIURL, formData).subscribe(
      result => {
        this.showspinner = false;
        if (result == null) {

        }
        else {
          var saveAPIresult: any = result;
          if (saveAPIresult.success == "1") {
            this.uplineAPIdental = undefined;
            this.uplineAPIafe1 = undefined;
            this.uplineAPIafe2 = undefined;
            this.uplineAPIchubb = undefined;
            this.uplineAPIlife = undefined;
            this.GetAgentInfo('n');
            this.GetProducts();
            this.ShowSponsor = false;
            this.editAgent = false;
            Swal.fire({
              title: 'Changes Saved',
              text: 'Compensation changes saved.',
              icon: 'info',
            });
            this.GetComp();
          }
          else {
            Swal.fire({
              title: 'Warning',
              text: saveAPIresult.message,
              icon: 'warning',
            })
          }

        }



      },
      error => {

      });


  }



  EnableAgent(): void {



    Swal.fire({
      title: 'Enable Agent?',
      text: "Please confirm if you want to enable this account. A notification email will be sent to this agent.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Enable Agent'
    }).then((result) => {
      if (result.isConfirmed) {
        this.showspinner=true;
        var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/corporate/EnableAgent.aspx";    // set header for http call
        let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
        // set body
        let body = new URLSearchParams();
        body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
        body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
        body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
        body.set('status', encodeURIComponent(status));
        body.set('pl', encodeURIComponent(this.ID));

        this.httpClient.post(APIURL, body.toString(), options).subscribe(
          result => {
            this.tempAPIresult = Array.of(result);
            if (result == null) {
              this.showspinner=false;
            }
            else {
              if (this.tempAPIresult[0].success == "1") {
                Swal.fire({
                  title: 'Agent Enabled',
                  text: 'This agent has been enabled, and the welcome email was sent.',
                  icon: 'info',
                });
                this.GetAgentInfo('n');
                this.showspinner=false;
                return;
              }
              else {
                Swal.fire({
                  title: 'Warning',
                  text: this.tempAPIresult[0].message,
                  icon: 'error',
                });
                this.showspinner=false;
                return;
              }

            }

          },
          error => {
            this.showspinner=false;
          });
      }
    })

  }






  DisableAgent(): void {



    Swal.fire({
      title: 'Disable Agent?',
      text: "Please confirm if you want to disable this account.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Disable Agent'
    }).then((result) => {
      if (result.isConfirmed) {

        var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/corporate/DisableAgent.aspx";    // set header for http call
        let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
        // set body
        let body = new URLSearchParams();
        body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
        body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
        body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
        body.set('status', encodeURIComponent(status));
        body.set('pl', encodeURIComponent(this.ID));

        this.httpClient.post(APIURL, body.toString(), options).subscribe(
          result => {
            this.tempAPIresult = Array.of(result);
            if (result == null) {

            }
            else {
              if (this.tempAPIresult[0].success == "1") {
                Swal.fire({
                  title: 'Agent Disabled',
                  text: 'This agent has been disabled.',
                  icon: 'info',
                });
                this.GetAgentInfo('n');
                return;
              }

            }

          },
          error => {

          });
      }
    })

  }



  ChangeAgencyType(type: string): void {
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/corporate/ChangeAgencyType.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('type', encodeURIComponent(type));
    body.set('pl', encodeURIComponent(this.ID));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.tempAPIresult = Array.of(result);
        if (result == null) {

        }
        else {
          if (this.tempAPIresult[0].success == "1") {
            Swal.fire({
              title: 'Agent Type Changed',
              text: 'The platform model for this account has been changed.',
              icon: 'info',
            });
            return;
          }
          else {
            Swal.fire({
              title: 'Error',
              text: 'Invalid Parameters.',
              icon: 'error',
            });
          }

        }

      },
      error => {

      });
  }





  EditMyAgent(): void {

    //updates paygrid for user
    //for selected code

    for (let code of this.AdditionalData) {
      for (let comp of this.Data) {
        if (comp.productid == code.productid) {
          comp.agentpercent = code.Y1;
          comp.enabled = 1;
        }
      }
    }
    //force DATA to refresh
    var NewElement: any = {
      "productid": 0,
      "agentpercent": 0,
      "enabled": "1",
      "newelement": Math.floor(Math.random() * 362548547889)
    }
    //forces DATA to update on SetComp.ts
    this.Data.push(NewElement);
    this.Data = this.Data.slice(0, -1);



    this.APIedit = undefined
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/Agents/getpayload.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('pl', encodeURIComponent(this.ID));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.APIedit = result;
        if (result == null) {

        }
        else {
          if (this.APIedit.success == "1") {
            this.editAgent = true;
            
            for (let recs of this.APIedit.npnInfo) {
              if (recs.type == "individual") {
                this.npnInd = recs;
              };
              if (recs.type == "agency") {
                this.npnAg = recs;
              };
            }
            return;
          }
          else {
            Swal.fire({
              title: 'Error',
              text: this.APIedit.message,
              icon: 'error',
            });
          }

        }

      },
      error => {

      });

  }



  ViewDownline(): void {


    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/corporate/getnewsponsorinfo.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('sponsor', this.NewSponsorID);
    body.set('pl', encodeURIComponent(this.ID));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.APISponsor = result;
        if (result == null) {

        }
        else {
          if (this.APISponsor.success == "1") {
            if (this.APISponsor.agentinfo.agentprincipalid == this.APIresult[0].agentinfo.agentprincipalid) {
              Swal.fire({
                title: 'Wrong Hierarchy',
                text: 'An agent upline cannot contain his own Agent ID.',
                icon: 'error',
              });
              this.ShowSponsor = false;
              return;
            }
            else {
              this.ShowSponsor = true;
            }

          }
          else {
            Swal.fire('Invalid Sponsor', this.APISponsor.message, 'info')
            this.ShowSponsor = false;
            return;
          }

        }

      },
      error => {

      });

  }


  SetDownline(): void {
    Swal.fire({
      title: 'Change Hierarchy?',
      text: "Do you want to set " + this.APISponsor.agentinfo.firstname + " " + this.APISponsor.agentinfo.lastname + " as the upline agent for " + this.APIresult[0].agentinfo.firstname + " " + this.APIresult[0].agentinfo.lastname + "?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, set new upline'
    }).then((result) => {
      if (result.isConfirmed) {
        this.APIedit.UplineAgentId = this.APISponsor.agentinfo.agentprincipalid;
        Swal.fire('Hierarchy Changed', 'The new hierarchy will not be live until you save the changes for this user', 'info')


      }
    })

  }


  ViewLog(): void {
    var logPayload = {
      "logfrom": "",
      "logto": "",
      "logaction": "0",
      "logactionby": "0"
    }

    if (this.logfrom) { logPayload.logfrom = this.logfrom }
    if (this.logto) { logPayload.logto = this.logto }
    if (this.logaction) { logPayload.logaction = this.logaction }
    if (this.logactionby) { logPayload.logactionby = this.logactionby }

    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/getlogs.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('payload', JSON.stringify(logPayload));
    body.set('pl', encodeURIComponent(this.ID));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        var ApiResponse: any = result;
        if (result == null) {
          Swal.fire('API ERROR', ApiResponse.message, 'error')
          this.ShowSponsor = false;
          this.logHeaders = undefined;
          this.logRecords = undefined;
          return;
        }
        else {
          if (ApiResponse.success == "1") {
            this.logHeaders = ApiResponse.fields;
            this.logRecords = ApiResponse.dataset;

            return;
          }

          if (ApiResponse.success == "99") {
            Swal.fire('No records found', ApiResponse.message, 'warning')
            this.ShowSponsor = false;
            this.logHeaders = undefined;
            this.logRecords = undefined;
            return;
          }

          if (ApiResponse.success == "0") {
            Swal.fire('API ERROR', ApiResponse.message, 'error')
            this.ShowSponsor = false;
            this.logHeaders = undefined;
            this.logRecords = undefined;
            return;
          }

        }

      },
      error => {

      });


  }

  OpenLog(id): void {

    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/getlogdetails.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('pl', encodeURIComponent(this.ID));
    body.set('id', id);

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        var ApiResponse: any = result;
        if (result == null) {
          Swal.fire('API ERROR', ApiResponse.message, 'error')
          this.ShowSponsor = false;
          this.logHeaders = undefined;
          this.logRecords = undefined;
          return;
        }
        else {
          if (ApiResponse.success == "1") {
            if (ApiResponse.lightboxtype == "1") {
              //this shows lightbox only for actions < 100 coming from the Actions Table
              this.logFullName = ApiResponse.firstname + ' ' + ApiResponse.lastname;
              this.logDate = ApiResponse.datetime;
              this.LogActionName = ApiResponse.ActionName;
              this.logIP = ApiResponse.ipaddress;
              this.logTitle = ApiResponse.actiontitle;
              this.logDetails = ApiResponse.description;
              this.showLog = true;
            }

            return;
          }

          if (ApiResponse.success == "99") {
            Swal.fire('No records found', ApiResponse.message, 'warning')
            this.ShowSponsor = false;
            this.logHeaders = undefined;
            this.logRecords = undefined;
            return;
          }

          if (ApiResponse.success == "0") {
            Swal.fire('API ERROR', ApiResponse.message, 'error')
            this.ShowSponsor = false;
            this.logHeaders = undefined;
            this.logRecords = undefined;
            return;
          }

        }

      },
      error => {

      });



  }

  GetComp(): void {
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/getagentcomp.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('pl', encodeURIComponent(sessionStorage.getItem('plid')));
    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.Data = result;
        var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/getagentproducts.aspx";    // set header for http call
        body = new URLSearchParams();
        body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
        body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
        body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
        body.set('pl', encodeURIComponent(this.ID));
        this.httpClient.post(APIURL, body.toString(), options).subscribe(
          result => {
            this.AdditionalData = result;

          }
        );
      }
    );


  }


  GetMyComp(): void {
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/getagentcomp.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('pl', encodeURIComponent(this.ID));
    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.Data2 = result;
        var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/getagentproducts.aspx";    // set header for http call
        body = new URLSearchParams();
        body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
        body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
        body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
        body.set('pl', encodeURIComponent(this.ID));
        this.httpClient.post(APIURL, body.toString(), options).subscribe(
          result => {
            this.AdditionalData2 = result;

          }
        );
      }
    );


  }

  GetUplineComp(): void {
    
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/getagentcomp.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('pl', encodeURIComponent(this.Sponsorpl));
    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.Data3 = result;
        var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/getagentproducts.aspx";    // set header for http call
        body = new URLSearchParams();
        body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
        body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
        body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
        body.set('pl', encodeURIComponent(this.ID));
        this.httpClient.post(APIURL, body.toString(), options).subscribe(
          result => {
            this.AdditionalData3 = result;
            for (let code of this.AdditionalData3) {
              for (let comp of this.Data3) {
                if (comp.productid == code.productid) {
                  comp.agentpercent = code.Y1;
                  comp.enabled = 1;
                }
              }
            }
          }
        );
      }
    );


  }





  ngOnInit(): void {
    this.agentname = sessionStorage.getItem("firstname").trim() + " " + sessionStorage.getItem("lastname").trim()
    this.GetAgentInfo('y');
    this.ListAgents(this.ID, "direct");
    this.ListMembers(this.ID);

  }


  resetAgencyType(): void {
    if(this.APIedit.type=='individual') {
      this.npnAg.AgencyName="";
      this.npnAg.ResidentInsuranceNumber="";
      this.npnAg.ResidentStateLicense="";
      this.npnAg.AgencyType="";
      this.npnAg.EIN="";
      this.npnAg.TypeOfLicense="";
      this.npnAg.Address="";
      this.npnAg.city="";
      this.npnAg.state="";
      this.npnAg.zip="";
      this.npnAg.NPNnumber="";


    }
  }
}
