

<div class="level1page ">

    <div class="stick-ontop">
        <h4 style="color:#f1f1f1; padding-top: 20px; ">
            Members Management
        </h4>
        <hr>
        <div>
            <div class="row">
                <div class="col-6 col-md-4 col-xl-2" *ngIf="application">
                    <div class="productbox2 mousepointer" [routerLink]="['clientsdashboard']" >
                        <div class="text-left">
                            <span class="superfont">Analytics</span>
                            <br><span class="smallfont">Display member's analytics and stats</span>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-xl-2">
                    <div class="productbox2 mousepointer" [routerLink]="['contractedclients']" >
                        <div class="text-left">
                            <span class="superfont">List Members</span>
                            <br><span class="smallfont">List your members</span>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-xl-2" *ngIf="application">
                    <div class="productbox2 mousepointer" [routerLink]="['querybuilder']" >
                        <div class="text-left">
                            <span class="superfont">Report Builder</span>
                            <br><span class="smallfont">Generate your own members views</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
    </div>

   
    <div >
        <div class="subbox text-left" >
            <router-outlet></router-outlet>
        </div>
    </div>
    <br>
</div>

<!--

<div class="fixed-top" style="z-index: 90;">

    <div style="padding-left: 90px; ">
        <nav class="navbar navbar-expand-lg navbar-dark" style="background-color: #3e669c;">
            <a class="navbar-brand" href="#">
                  Member Management: 
                </a>

            <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
                  <span class="navbar-toggler-icon"></span>
                </button>


            <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item">
                        <a class="nav-link" routerLink="clientsdashboard" routerLinkActive="btn btn-sm btn-dark">Analytics</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="contractedclients" routerLinkActive="btn btn-sm btn-dark">List Members</a>
                    </li>

                  
                </ul>
            </div>
        </nav>
    </div>
</div>
<div class="body-text" style="z-index: 1; padding-right:20px;">
    <router-outlet></router-outlet>
</div>

/
-->