import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'app-hardcodedlist',
  templateUrl: './hardcodedlist.component.html',
  styleUrls: ['./hardcodedlist.component.css']
})
export class HardcodedlistComponent implements OnInit {
  APIresult: any;
  APIafe1: any;
  APIafe2: any;
  APIdental: any;
  APIlife: any;
  APIchubb: any;
  UNIQUECODE: string;
  constructor(
    public _httpClient: HttpClient,
  ) {


    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/getproducts.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));

    this._httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.APIresult = Array.of(result);
        if (result == null) {
       
        }
        else {
          if (this.APIresult[0].success == "1") {
            //get AFE Basic
           
            //get Dental
            if (this.APIresult[0].hasOwnProperty('Dental')) {
              this.APIdental = this.APIresult[0].Dental
              this.UNIQUECODE=  sessionStorage.getItem('codeidentifier')
            }
            //get Chubb
            if (this.APIresult[0].hasOwnProperty('Chubb')) {
              this.APIchubb = this.APIresult[0].Chubb
            }
            //get Life
            if (this.APIresult[0].hasOwnProperty('Life')) {
              this.APIlife = this.APIresult[0].Life
            }
            return;
          }
         
        }

      },
      error => {

      });

  }

  ShowProducts(id): void {
    
  }
  

  ngOnInit(): void {
  }

}
