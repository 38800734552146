import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { FormGroup } from '@angular/forms'
import { SelectedProducts } from '../../../classes/classes'
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-viewprospect',
  templateUrl: './viewprospect.component.html',
  styleUrls: ['./viewprospect.component.css']
})
export class ViewprospectComponent implements OnInit {

  public productslist: SelectedProducts[] = [];

  Data: any;
  isCode: boolean = false;

  APIresult: any;

  APItemplates: any;
  APIdownline: any;

  allowcode: boolean = true;

  templatecode: any;
  public preloader: boolean = false;

  mycode: string;
  sendemail: boolean = true;
  sendsms: boolean = true;
  myself: string = "";
  myid: string = "";



  firstname: string = "";
  lastname: string = "";
  email: string = "";
  cellphone: string = "";
  selectedHierarchy: string;
  selectedPaygrid: string;

  HierarchyUserMatch: boolean = true;

  constructor(
    public _httpClient: HttpClient,
    private _router: Router,
    private r: ActivatedRoute,

  ) {

    this.GetProducts();
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/getagentcomp.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('pl', encodeURIComponent(sessionStorage.getItem('plid')));
    this._httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.Data = result;
        this.preloader = true;
        var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/getprospect.aspx";    // set header for http call
        let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
        // set body
        let body = new URLSearchParams();
        body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
        body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
        body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));

        body.set('prospectid', encodeURIComponent(sessionStorage.getItem('temp')));


        this._httpClient.post(APIURL, body.toString(), options).subscribe(
          result => {
            this.APIresult = Array.of(result);
            if (result == null) {
              Swal.fire({
                title: 'Try again',
                text: 'Invalid credentials. Please try again.',
                icon: 'error',
              })
            }
            else {
              if (this.APIresult[0].success == "1") {
                this.firstname = this.APIresult[0].prospectinfo.firstname;
                this.lastname = this.APIresult[0].prospectinfo.lastname;
                this.email = this.APIresult[0].prospectinfo.email;
                this.cellphone = this.APIresult[0].prospectinfo.phone;
                this.templatecode = this.APIresult[0].prospectinfo.code
                let Mycomp: any = this.APIresult[0].prospectinfo.comp;

                for (let code of Mycomp) {
                  for (let comp of this.Data) {
                    if (comp.productid == code.productid) {
                      comp.agentpercent = code.year1;
                      comp.enabled = 1;
                    }
                  }
                }


              }
              else {
                Swal.fire({
                  title: 'Something went wrong...',
                  text: this.APIresult[0].message,
                  icon: 'error',

                })
              }

            }

          },
          error => {

          });
      },
      error => {
        return;
      });
  }

  GetProducts(): void {

  }



  checkAgent(): void {
    if (this.selectedHierarchy == sessionStorage.getItem('plid')) {
      this.HierarchyUserMatch = true;
    }
    else {
      this.HierarchyUserMatch = false;
    }
  }

  yyyymmdd() {
    var x = new Date();
    var y = x.getFullYear().toString();
    var m = (x.getMonth() + 1).toString();
    var d = x.getDate().toString();
    var hs = x.getHours().toString();
    var mn = x.getMinutes().toString();
    var sc = x.getSeconds().toString();

    (d.length == 1) && (d = '0' + d);
    (m.length == 1) && (m = '0' + m);
    (hs.length == 1) && (hs = '0' + hs);
    (mn.length == 1) && (mn = '0' + mn);
    (sc.length == 1) && (sc = '0' + sc);
    var yyyymmdd = y + m + d + hs + mn + sc;
    return yyyymmdd;
  }






  keyPressLettersNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9

    if (charCode >= 48 && charCode <= 57) {
      return true;
      return;
    }

    if (charCode >= 65 && charCode <= 90) {
      return true;
      return;
    }

    if (charCode >= 97 && charCode <= 122) {
      return true;
      return;
    }

    event.preventDefault();
    return false;

  }



  InviteAgent(): void {
    if (!this.templatecode) {
      Swal.fire({
        title: 'Enter Enrollment Code',
        text: 'Enter the code your prospect agents will utilize to enroll',
        icon: 'warning',
      });
      return;
    }


    Swal.fire({
      title: 'Are you sure?',
      text: "Sending a new email invite will disable/erase your previous invitation. Do you want to continue?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, send new invite'
    }).then((result) => {
      if (result.isConfirmed) {
        this.templatecode = this.templatecode.trim();

        //all fine

        let newproduct = new SelectedProducts();
        this.productslist = [];


        //call API
        var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/inviteagent.aspx";    // set header for http call
        let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
        // set body
        let body = new URLSearchParams();
        body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
        body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
        body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
        body.set('prospectid', encodeURIComponent(sessionStorage.getItem('temp')));

        //api specific
        body.set('description', encodeURIComponent("Single Signup Code for " + this.firstname + " " + this.lastname));
        body.set('code', encodeURIComponent(this.templatecode));
        body.set('data', encodeURIComponent(JSON.stringify(this.Data)));
        body.set('timesallowed', encodeURIComponent("1"));

        body.set('firstname', encodeURIComponent(this.firstname));
        body.set('lastname', encodeURIComponent(this.lastname));
        body.set('email', encodeURIComponent(this.email));
        body.set('cellphone', encodeURIComponent(this.cellphone));
        body.set('newcode', encodeURIComponent(this.allowcode));



        this._httpClient.post(APIURL, body.toString(), options).subscribe(
          result => {
            this.APIresult = Array.of(result);
            if (result == null) {
              Swal.fire({
                title: 'Template Not Saved',
                text: 'There was an error saving this template. Please contact our support team.',
                icon: 'error',
              });
              return;
            }
            else {
              if (this.APIresult[0].success == "1") {
                Swal.fire({
                  title: 'Prospect Agent Invited ',
                  text: this.firstname + " has been invited to join AFEUSA.",
                  icon: 'success',
                });
                this._router.navigate(['../agentsprospects'], { relativeTo: this.r });
                return;
              }

              if (this.APIresult[0].success == "0") {
                Swal.fire({
                  title: 'Review your entry',
                  text: this.APIresult[0].message,
                  icon: 'warning',
                });
                return;
              }

              if (this.APIresult[0].success == "99") {
                Swal.fire({
                  title: 'Error',
                  text: this.APIresult[0].message,
                  icon: 'error',
                });
                return;
              }




            }

          },
          error => {
            Swal.fire({
              title: 'Template Not Saved',
              text: 'There was an error saving this template. Please contact our support team.',
              icon: 'error',
            });
            return;
          });
      }
    })




  }

  deleteProspect(): void {
    Swal.fire({
      title: 'Are you sure?',
      text: "Are you sure you want to delete this prospect agent?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete prospect',
    }).then((result2) => {
      if (result2.isConfirmed) {
        //call API
        var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/deleteagent.aspx";    // set header for http call
        let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
        // set body
        let body = new URLSearchParams();
        body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
        body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
        body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
        body.set('prospectid', encodeURIComponent(sessionStorage.getItem('temp')));
        this._httpClient.post(APIURL, body.toString(), options).subscribe(
          result => {
            this.APIresult = Array.of(result);
            if (result == null) {
              Swal.fire({
                title: 'Prospect Agent Not Deleted',
                text: 'There was an error deleting this agent. Please contact our support team.',
                icon: 'error',
              });
              return;
            }
            else {
              if (this.APIresult[0].success == "1") {
                Swal.fire({
                  title: 'Prospect Deleted ',
                  text: this.firstname + " has been deleted.",
                  icon: 'success',
                });
                this._router.navigate(['../agentsprospects'], { relativeTo: this.r });
                return;
              }

              if (this.APIresult[0].success == "0") {
                Swal.fire({
                  title: 'Prospect Not Deleted',
                  text: 'There was an error deleting this prospect. Please contact our support team.',
                  icon: 'error',
                });
                return;
              }

              if (this.APIresult[0].success == "99") {
                Swal.fire({
                  title: 'Error',
                  text: this.APIresult[0].message,
                  icon: 'error',
                });
                return;
              }




            }

          },
          error => {
            Swal.fire({
              title: 'Prospect Not Deleted',
              text: 'There was an error deleting this prospect. Please contact our support team.',
              icon: 'error',
            });
            return;
          });
      }
    })
  }

  resendEmail(): void {
    Swal.fire({
      title: 'Are you sure?',
      text: "Are you sure you want to resend the invitation email?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, send email'
    }).then((result2) => {
      if (result2.isConfirmed) {
        //call API
        var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/resendemail.aspx";    // set header for http call
        let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
        // set body
        let body = new URLSearchParams();
        body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
        body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
        body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
        body.set('prospectid', encodeURIComponent(sessionStorage.getItem('temp')));
        this._httpClient.post(APIURL, body.toString(), options).subscribe(
          result => {
            this.APIresult = Array.of(result);
            if (result == null) {
              Swal.fire({
                title: 'Email could not be send',
                text: 'There was an error sending the invite email. Please contact our support team.',
                icon: 'error',
              });
              return;
            }
            else {
              if (this.APIresult[0].success == "1") {
                Swal.fire({
                  title: 'Prospect Emailed ',
                  text: this.firstname + " has been emailed.",
                  icon: 'success',
                });
                this._router.navigate(['../agentsprospects'], { relativeTo: this.r });
                return;
              }

              if (this.APIresult[0].success == "99") {
                Swal.fire({
                  title: 'Email could not be send',
                  text: 'There was an error sending the invite email. Please contact our support team.',
                  icon: 'error',
                });
                return;
              }




            }

          },
          error => {
            Swal.fire({
              title: 'Prospect Not Deleted',
              text: 'There was an error deleting this prospect. Please contact our support team.',
              icon: 'error',
            });
            return;
          });
      }
    })
  }


  ngOnInit(): void {
  }

}
