<div class="fixed-top" style="z-index: 90;">

    <div style="padding-left: 90px;">
        <nav class="navbar navbar-expand-lg navbar-dark" style="background-color: #3e669c;">
            <a class="navbar-brand" href="#">
                  Reports: 
                </a>

            <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
                  <span class="navbar-toggler-icon"></span>
                </button>


            <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item">
                        <a class="nav-link" href="#">Production Report</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" href="#">Conversions</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" href="#">Downline Metrics</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" href="#">Websites</a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</div>
<div class="fixed-bottom text-right text-muted" style="background-color: #f1f1f1; z-index: -1;">CopyRight 2020 Henko Moss, LLC - All rights reserverd</div>
<div class="body-text" style="z-index: -2;">
    <router-outlet></router-outlet>
</div>