import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SelectItem, FilterService, FilterMatchMode } from 'primeng/api';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-corpmembers',
  templateUrl: './corpmembers.component.html',
  styleUrls: ['./corpmembers.component.css']
})
export class CorpmembersComponent implements OnInit {

  APIresult: any; //api result for listing all products - Permanent

  referenceId: string = "";
  firstName: string = "";
  lastName: string = "";
  email: string = "";
  ssn: string = "";
  projectId: string = "";

  spinner: boolean = false;
  public ProductsList: any;




  //SIDE VARIABLES
  openSide: boolean=false;
  public APIresults: any;
  public Members: any;
  public memberData: any;
  public agentinfo: any;
  public agentproducts: any = [];
  public dependents: any;
  public beneficiaries: any;
  public upline: any;
  public personData: any = {
    FirstName: "",
    LastName: "",
    BirthDate: "",
    Gender: "",
    Relationship: "",
    Address1: "",
    City: "",
    State: "",
    State_Name: "",
    Zip: "",
    Phone: "",
    IsDisabled: "",
    AFEtype: "",
    PersonCode: ""
  }

  public showDependedBox: boolean = false;
  public showBeneficiaryBox: boolean = false;

  public preloader: boolean = false;
  APISponsor: any;

  public selectedPlanName: string = "";
  public selectedPlanId: string = "";
  public selectedTierCode: string = ""
  public selectedFee: string = ""
  public selectedProductId: string = "";

  NewSponsorID: string;
  ShowSponsor: boolean=false;
  agentRecodeShow: boolean=false;


  constructor(

    public httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private filterService: FilterService
  ) {

  }


  searchMembers(): void {
    var HasParameter: boolean = false;
    if (this.referenceId) { HasParameter = true; };
    if (this.firstName) { HasParameter = true; };
    if (this.lastName) { HasParameter = true; };
    if (this.email) { HasParameter = true; };
    if (this.ssn) { HasParameter = true; };
    if (this.projectId) { HasParameter = true; };
    if (!HasParameter) {
      Swal.fire('Enter Parameter', 'Enter at least one search criteria', 'warning');
      return;
    }
    this.spinner = true;
    this.ReadAPI()
      .then((data) => {
        this.APIresult = data;
        if (this.APIresult.success == "1") {
          this.ProductsList = this.APIresult.clients;
        }

      })
      .catch((error) => {

      })
    this.spinner = false;
  }

  ReadAPI() {

    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/corporate/listclients.aspx";
    var options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    var body = new URLSearchParams();

    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));

    body.set('referenceId', encodeURIComponent(this.referenceId));
    body.set('firstName', encodeURIComponent(this.firstName));
    body.set('lastName', encodeURIComponent(this.lastName));
    body.set('email', encodeURIComponent(this.email));
    body.set('ssn', encodeURIComponent(this.ssn));
    body.set('projectId', encodeURIComponent(this.projectId));
    return this.httpClient.post(APIURL, body.toString(), options).toPromise();
  }


  onRowSelect(id): void {
    this.APIresults = undefined;
    this.dependents = undefined;
    this.selectedPlanName = "";
    this.selectedPlanId = "";
    this.selectedTierCode = "";
    this.agentinfo = undefined;
    this.agentproducts = undefined;
    this.memberData = undefined;
    this.upline = undefined;
    

  
    this.openSide = true;
    this.spinner = true;
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/clients/clientdata.aspx";
    var options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    var body = new URLSearchParams();

    

    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('policy', encodeURIComponent(id));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.APIresults = result;
        this.agentinfo = this.APIresults.agentinfo;
        this.agentproducts = this.APIresults.agentproducts;
        this.memberData = this.APIresults.memberdata;
        this.upline = this.APIresults.uplineCompensation;
        this.spinner=false;
      },
      error => {
        this.spinner=false;
      });



  }


  cancelFullMembership(): void {

    Swal.fire({
      title: 'Are you sure?',
      text: "This action cannot be reversed. The AFEUSA Membership and all its associated plans will be cancelled immediately. Please confirm this operation.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Cancel Memberhsip'
    }).then((result) => {
      if (result.isConfirmed) {

        if (result.isConfirmed) {
          Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this operation.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Cancel Membership'
          }).then((result) => {
            if (result.isConfirmed) {
              this.preloader = true;
              var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/clients/cancelMember.aspx";
              var options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
              var body = new URLSearchParams();

              body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
              body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
              body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
              body.set('policy', encodeURIComponent(this.APIresults.memberdata.policyid));
              body.set('projectid', encodeURIComponent(this.APIresults.memberdata.projectid));
              body.set('ReferenceID', encodeURIComponent(this.APIresults.memberdata.ReferenceID));
              body.set('pl', encodeURIComponent(this.APIresults.memberdata.plid));

              this.httpClient.post(APIURL, body.toString(), options).subscribe(
                result => {
                  if (result == null) {
                    Swal.fire({
                      title: 'API Error',
                      text: "A connection to AFEUSA servers could not be established.",
                      icon: 'error',
                    })
                    this.preloader = false;
                    return;
                  }
                  var cancelAPI: any = result;

                  if (cancelAPI.success == "1") {
                    Swal.fire({
                      title: 'Membership Terminated',
                      text: cancelAPI.message,
                      icon: 'info',
                    })
                    this.openSide = false
                    this.preloader = false;
                    return;
                  }


                  if (cancelAPI.success != "1") {
                    Swal.fire({
                      title: 'Error',
                      text: cancelAPI.message,
                      icon: 'error',
                    })
                    this.preloader = false;
                    return;
                  }

                },
                error => {
                  this.preloader = false;
                });

            }
          })
        }






      }
    })

  }



  PlanDetails(id, name, fee): void {
    this.dependents = undefined;
    this.beneficiaries = undefined;
    this.selectedPlanName = name;
    this.selectedPlanId = id;
    this.selectedTierCode = "Individual";
    this.selectedFee = fee;
    this.selectedProductId = ""

    for (let result of this.APIresults.dependents) {
      if (result.ProductId == id) {
        this.selectedPlanName = name;
        this.selectedPlanId = id;
        this.dependents = result;
        this.selectedProductId = id;
        switch (result.SelectedTierCode) {
          case "ISP": {
            this.selectedTierCode = "Individual + Spouse"
            break;
          }
          case "ICH": {
            this.selectedTierCode = "Individual + Children"
            break;
          }
          case "FAM": {
            this.selectedTierCode = "Family Group"
            break;
          }
          case "IND": {
            this.selectedTierCode = "Individual"
            break;
          }
          case "IND+1": {
            this.selectedTierCode = "Individual + One"
            break;
          }
        }
      }
    }

    if (!this.dependents) {
      if (id == 8339 || id == 8676) {
        this.selectedPlanName = name;
        this.selectedPlanId = id;
        this.beneficiaries = this.APIresults.beneficiaries;
        this.selectedProductId = id;
      }
    }


  }


  DependentDetails(productId, PersonCode): void {
    this.personData = undefined;
    for (let result of this.dependents.POLICIES_DependentDemographics) {
      if (result.PersonCode == PersonCode) {
        this.personData = result;
        this.showDependedBox = true;
      }
    }
  }

  BeneficiaryDetails(beneficiaryId): void {
    this.personData = undefined;
    for (let result of this.beneficiaries) {
      if (result.BeneficiaryId == beneficiaryId) {
        this.personData = result;
        this.showBeneficiaryBox = true;
      }
    }
  }


  cancelPlan(id): void {
    Swal.fire({
      title: 'Cancel ' + this.selectedPlanName + "?",
      text: "This will cancel ONLY the " + this.selectedPlanName + ' plan. To cancel the entire membership and all the associated plans, please click the CANCEL MEMBERSHIP button above.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Cancel This Plan'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this operation.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then((result) => {
          if (result.isConfirmed) {


            var Plan: any = this.APIresults.products

            for (let result of Plan) {
              if (result.ProductId == id) {
                var PlanToCancel: any = result;

                var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/clients/cancelPlan.aspx";
                var options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
                var body = new URLSearchParams();

                body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
                body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
                body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
                body.set('policy', encodeURIComponent(this.APIresults.memberdata.policyid));
                body.set('projectid', encodeURIComponent(this.APIresults.memberdata.projectid));
                body.set('ReferenceID', encodeURIComponent(this.APIresults.memberdata.ReferenceID));
                body.set('pl', encodeURIComponent(this.APIresults.memberdata.plid));
                body.set('plan', id);
                body.set('selectedplanid', encodeURIComponent(PlanToCancel.SelectedPlanId));
                body.set('afeid', encodeURIComponent(PlanToCancel.afeplanid));
                


                this.httpClient.post(APIURL, body.toString(), options).subscribe(
                  result => {
                    if (result == null) {
                      Swal.fire({
                        title: 'API Error',
                        text: "A connection to AFEUSA servers could not be established.",
                        icon: 'error',
                      })
                      return;
                    }
                    var cancelAPI: any = result;

                    if (cancelAPI.success == "1") {
                      Swal.fire({
                        title: this.selectedPlanName + ' Terminated',
                        text: cancelAPI.message,
                        icon: 'info',
                      })
                      return;
                    }


                    if (cancelAPI.success != "1") {
                      Swal.fire({
                        title: 'Error',
                        text: cancelAPI.message,
                        icon: 'error',
                      })
                      return;
                    }

                  },
                  error => {

                  });





              }
            }





          }
        })


      }
    })

  }

  ViewDownline(): void {

    
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/corporate/getagentinfo.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('sponsor', this.NewSponsorID);
    body.set('pl', encodeURIComponent(sessionStorage.getItem('temp')));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.APISponsor = result;
        if (result == null) {

        }
        else {
          if (this.APISponsor.success == "1") {
           
              this.ShowSponsor = true;

          }
          else {
            Swal.fire('Invalid Sponsor', this.APISponsor.message, 'info')
            this.ShowSponsor = false;
            return;
          }

        }

      },
      error => {

      });

  }

  SetDownline(): void {
    Swal.fire({
      title: 'Are you sure?',
      text: "Are you sure you want to recode this member under agent " + this.APISponsor.agentinfo.firstname + ' ' +this.APISponsor.agentinfo.lastname + "?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Recode'
    }).then((result) => {
      if (result.isConfirmed) {
       
        //start recoding
        var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/corporate/recodemember.aspx";    // set header for http call
        let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
        // set body
        let body = new URLSearchParams();
        body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
        body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
        body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
        body.set('sponsor', this.APISponsor.agentinfo.plid);
        body.set('policy', this.memberData.policyid);
    
        this.httpClient.post(APIURL, body.toString(), options).subscribe(
          result => {
            this.APISponsor = result;
            if (result == null) {
    
            }
            else {
              if (this.APISponsor.success == "1") {
               
                Swal.fire('Operation Complete', this.APISponsor.message, 'info')
                this.ShowSponsor = false;
                this.openSide=false;
                this.agentRecodeShow=false;
              }
              else {
                Swal.fire('Invalid Sponsor', this.APISponsor.message, 'warning')
                this.ShowSponsor = false;
                return;
              }
    
            }
    
          },
          error => {
    
          });
    
      }
    })
    
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9

    if (charCode >= 48 && charCode <= 57) {
      return true;
      return;
    }
    if (charCode == 46) {
      return true;
      return;
    }

    event.preventDefault();
    return false;

  }

  FormatMoney(amount) {
    return (Number(amount).toLocaleString())

  }

  recodeAgent(): void {
    this.agentRecodeShow=true;
  }
  ngOnInit(): void {
  }

}
