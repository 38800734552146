  <div class="IntroDiv text-center" style="
    padding-left: 60px;
    background-image: url(../assets/images/learning.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  ">
      <!--

<div class="IntroDiv text-center">
-->

      <div class="text-right" style="padding-right: 30px;" class="text-right">
          <img src="assets/afelogorealwhite.png" style="width:150px; margin-top: 40px;">
      </div>
      <div style="padding-left: 70px; padding-right: 30px;" class="text-left">
          <br>
          <h4 style="color:#f1f1f1">
              Welcome to our Training Portal
          </h4>
          <div style="margin: 0 auto">
              <hr>
              <br />
          </div>
          <div class="row">
              <div class="col-12 col-md-3 col-lg-2">
                  <div class="productbox mousepointer" *ngFor="let category of APItraining"
                      (click)="enableCategory(category.categoryid)">
                      <div class="text-left">
                          <span class="superfont">{{category.categorytext}}</span>
                          <br><span class="smallfont">Click for details</span>
                      </div>
                  </div>
              </div>
              <div class="col-12 col-md-4 col-lg-3">
                  <div class="subbox text-left" *ngIf="activecategory">
                      <h5>Content for {{BreadCrumb}}</h5>
                      <hr>
                      <ul>
                          <li *ngFor="let category of SELECTEDcategory">
                              <a class="mousepointer link"
                                  (click)="enableSection(category.sectionid)">{{category.sectionname}}</a>
                          </li>
                      </ul>
                  </div>
              </div>
              <div class="col-12 col-md-5 col-lg-7">
                  <div class="subbox text-left" *ngIf="activesection">
                      <h5>Content for {{BreadCrumbSub}}</h5>
                      <hr>
                      <div class="table-responsive table-borderless">
                          <table class="table">
                              <tr *ngFor="let content of SELECTEDsection ">
                                  <td style="width: 50px">
                                      <img *ngIf="content.contenttype=='video'" src="assets/icons/icoyoutube.png"
                                          style="width: 40px">
                                      <img *ngIf="content.contenttype=='file'" src="assets/icons/icopdf.png"
                                          style="width: 40px">
                                  </td>
                                  <td><strong>{{content.title}}</strong>
                                      <br>
                                      <span class="text-muted">{{content.description}}</span></td>
                                  <td class="text-right">
                                      <button class="btn btn-xs btn-primary" style="margin-right: 10px;"
                                          (click)="viewContent(content.contentid)"> Open </button>
                                  </td>
                              </tr>
                          </table>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>


  <!--FOOTER-->
  <div id="xfooter">
      <div class="row">
          <div class="col-md-8">

              <span style="font-size: 14px;">CopyRight 2021 AFEUSA. All Rights Reserved.</span>
              <br>
              <span style="font-size: 12px;">This platform is to be used exclusively by AFEUSA registered agents.</span>
          </div>
          <div class="col-md-4 text-right">
              <a href="https://www.henkomoss.com" target="_blank">
                  <img src="assets/henkologo.png" style="height: 35px; padding-right: 100px; padding-top:5px" />
              </a>
          </div>
      </div>
  </div>




  <!--DISPLAY VIDEO-->


  <p-dialog [(visible)]="OpenVideo" modal="true" [draggable]="false" [closeOnEscape]="true" [responsive]="true"
      [baseZIndex]="100">

      <p-header style="width:100%">
          <h3>{{VideoTitle}}</h3>

      </p-header>
      <iframe width="840" height="473" [src]="VideoURL" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
  </p-dialog>