import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PlatformComponent } from './platform/platform.component';
import { CrmComponent } from './platform/crm/crm.component';
import { WebsiteComponent } from './platform/website/website.component';
import { ProductivityComponent } from './platform/productivity/productivity.component';
import { MetricsComponent } from './platform/metrics/metrics.component';
import { SetupComponent } from './platform/setup/setup.component';

//intro
import { IntroComponent } from './platform/intro/intro.component';
import { LoginComponent } from './login/login.component';
import { ActivateComponent } from './platform/activate/activate.component';

//crm
import { DashboardComponent } from './platform/crm/dashboard/dashboard.component';
import { LeadsComponent } from './platform/crm/leads/leads.component';
import { ViewcontactsComponent } from './platform/crm/viewcontacts/viewcontacts.component';
import { PendingapplicationsComponent } from './platform/crm/pendingapplications/pendingapplications.component';


//website
import { BrandingComponent } from './platform/website/branding/branding.component';
import { ProductsComponent } from './platform/website/products/products.component';
import { PagesComponent } from './platform/website/pages/pages.component';
import { FrameworkComponent } from './platform/website/framework/framework.component';
import { SeoComponent } from './platform/website/seo/seo.component';
import { AnalyticsComponent } from './platform/website/analytics/analytics.component';
import { WebdashboardComponent } from './platform/website/webdashboard/webdashboard.component';
import { BrandingeditComponent } from './platform/website/brandingedit/brandingedit.component';


//website//pages
import { HomeComponent } from './platform/website/pages/home/home.component';
import { AboutusComponent } from './platform/website/pages/aboutus/aboutus.component';
import { ContactusComponent } from './platform/website/pages/contactus/contactus.component';
import { BlogComponent } from './platform/website/pages/blog/blog.component';
import { ProductspageComponent } from './platform/website/pages/productspage/productspage.component';


// agents

import { AgentsComponent } from './platform/agents/agents.component';
import { AgentsdashboardComponent } from './platform/agents/agentsdashboard/agentsdashboard.component';
import { NewagentComponent } from './platform/agents/newagent/newagent.component';
import { AgentshierarchyComponent } from './platform/agents/agentshierarchy/agentshierarchy.component';
import { AgentscodesmanagerComponent } from './platform/agents/agentscodesmanager/agentscodesmanager.component';
import { CustomlistComponent } from './platform/agents/agentscodesmanager/customlist/customlist.component';
import { CustomnewComponent } from './platform/agents/agentscodesmanager/customnew/customnew.component';
import { HardcodedlistComponent } from './platform/agents/agentscodesmanager/hardcodedlist/hardcodedlist.component';
import { ApplicationComponent } from './platform/crm/application/application.component';
import { Application2Component } from './platform/crm/application2/application2.component';
import { AgentsprospectsComponent } from './platform/agents/agentsprospects/agentsprospects.component';
import { ViewagentComponent } from './platform/agents/viewagent/viewagent.component';
import { ViewprospectComponent } from './platform/agents/viewprospect/viewprospect.component';
import { UpdateaccountComponent } from './platform/setup/updateaccount/updateaccount.component';
import { CustomeditComponent } from './platform/agents/agentscodesmanager/customedit/customedit.component';
import { LogsComponent } from './platform/setup/manageusers/logs/logs.component';


//profile
import { ProfileComponent } from './platform/setup/profile/profile.component';
import { PasswordComponent } from './platform/setup/password/password.component';
import { SupportComponent } from './platform/setup/support/support.component';



//clients
import { ClientsComponent } from './platform/clients/clients.component';
import { ContractedComponent } from './platform/clients/contracted/contracted.component';
import { ClientsdashboardComponent } from './platform/clients/clientsdashboard/clientsdashboard.component';
//import { NewclientComponent } from './platform/clients/newclient/newclient.component';
//import { NewclientenrollmentComponent } from './platform/clients/newclientenrollment/newclientenrollment.component';
//import { NewclientquoteinfoComponent } from './platform/clients/newclientquoteinfo/newclientquoteinfo.component';
import { QuerybuilderComponent } from './platform/clients/querybuilder/querybuilder.component';


//commissions
import { CommissionsComponent } from './platform/commissions/commissions.component';
import { HciComponent } from './platform/commissions/hci/hci.component';

//corporate
import { CorporateComponent } from './platform/corporate/corporate.component';
import { CorporatedashboardComponent } from './platform/corporate/corporatedashboard/corporatedashboard.component';
import { CorpagentsComponent } from './platform/corporate/corpagents/corpagents.component';
import { CorpmembersComponent } from './platform/corporate/corpmembers/corpmembers.component';
import { SuperuserComponent } from './platform/corporate/superuser/superuser.component';

import { SupaycommissionComponent } from './platform/corporate/superuser/supaycommission/supaycommission.component';
import { SudeletememberComponent } from './platform/corporate/superuser/sudeletemember/sudeletemember.component';
import { SudeleteagentComponent } from './platform/corporate/superuser/sudeleteagent/sudeleteagent.component';

import { SiteadminComponent } from './platform/corporate/siteadmin/siteadmin.component';
import { EmailmanagementComponent } from './platform/corporate/siteadmin/emailmanagement/emailmanagement.component';
import { AfeusaadministratorsComponent } from './platform/corporate/siteadmin/afeusaadministrators/afeusaadministrators.component';
import { WebsitestatusComponent } from './platform/corporate/siteadmin/websitestatus/websitestatus.component';
import { SudeletecommissionComponent } from './platform/corporate/superuser/sudeletecommission/sudeletecommission.component';

import { UpdateniprComponent } from './platform/corporate/superuser/updatenipr/updatenipr.component';
import { AuditfileComponent } from './platform/corporate/superuser/auditfile/auditfile.component';


import { SignaddendumComponent } from './platform/signaddendum/signaddendum.component';
import { MembershipComponent } from './platform/clients/membership/membership.component';


import { ToolsComponent } from './platform/tools/tools.component';
import { NotesmoduleComponent } from './platform/tools/notesmodule/notesmodule.component';

import { ManageusersComponent } from './platform/setup/manageusers/manageusers.component';
import { ListusersComponent } from './platform/setup/manageusers/listusers/listusers.component';
import { NewuserComponent } from './platform/setup/manageusers/newuser/newuser.component';
import { EdituserComponent } from './platform/setup/manageusers/edituser/edituser.component';
import { AgenteditionComponent } from './platform/agents/agentedition/agentedition.component';
import { NiprComponent } from './platform/setup/nipr/nipr.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: 'platform', component: PlatformComponent,
    children: [
      {
        path: 'tools', component: ToolsComponent,
        children: [
          { path: 'notesmodule', component: NotesmoduleComponent },

        ]
      },
      {
        path: 'corporate', component: CorporateComponent,
        children: [
          { path: 'dashboard', component: CorporatedashboardComponent },
          { path: 'corpagents', component: CorpagentsComponent },
          { path: 'corpmembers', component: CorpmembersComponent },
          { path: 'viewagent', component: ViewagentComponent },
          { path: 'agentedition', component: AgenteditionComponent },
          {
            path: 'superuser', component: SuperuserComponent,
            children: [
              { path: 'supaycommission', component: SupaycommissionComponent },
              { path: 'sudeletecommission', component: SudeletecommissionComponent },
              { path: 'sudeletemember', component: SudeletememberComponent },
              { path: 'sudeleteagent', component: SudeleteagentComponent },
              { path: 'updatenipr', component: UpdateniprComponent },
              { path: 'auditfile', component: AuditfileComponent },


            ]
          },
          {
            path: 'siteadmin', component: SiteadminComponent,
            children: [
              { path: 'emailmanagement', component: EmailmanagementComponent },
              { path: 'administratorsmanagement', component: AfeusaadministratorsComponent },
              { path: 'websitemanagement', component: WebsitestatusComponent },
            ]
          },
        ]
      },
      { path: 'activate', component: ActivateComponent },

      { path: 'intro', component: IntroComponent },
      { path: 'signaddendum', component: SignaddendumComponent },

      {
        path: 'commissions', component: CommissionsComponent,
        children: [
          { path: 'hci', component: HciComponent },
        ]
      },

      {
        path: 'clients', component: ClientsComponent,
        children: [
          { path: 'contractedclients', component: ContractedComponent },
          { path: 'clientsdashboard', component: ClientsdashboardComponent },
          { path: 'querybuilder', component: QuerybuilderComponent },
          //{ path: 'applicationEnrollment', component: NewclientenrollmentComponent },
          //{ path: 'applicationQuoteInfo', component: NewclientquoteinfoComponent },


        ]
      },
      {
        path: 'crm', component: CrmComponent,
        children: [
          { path: 'dashboard', component: DashboardComponent },
          { path: 'leads', component: LeadsComponent },
          { path: 'viewcontacts', component: ViewcontactsComponent },
          //{ path: 'application', component: NewclientComponent },
          //{ path: 'applicationEnrollment', component: NewclientenrollmentComponent },
          //{ path: 'applicationQuoteInfo', component: NewclientquoteinfoComponent },
          { path: 'pendingapplications', component: PendingapplicationsComponent },
          { path: 'membership', component: MembershipComponent },


        ]
      },
      {
        path: 'website', component: WebsiteComponent,
        children: [
          { path: 'webdashboard', component: WebdashboardComponent },
          { path: 'branding', component: BrandingComponent },
          { path: 'products', component: ProductsComponent },
          { path: 'brandingedit', component: BrandingeditComponent },
          {
            path: 'pages', component: PagesComponent,
            children: [
              { path: 'home', component: HomeComponent },
              { path: 'aboutus', component: AboutusComponent },
              { path: 'contactus', component: ContactusComponent },
              { path: 'blog', component: BlogComponent },
              { path: 'productspage', component: ProductspageComponent },
            ]
          },
          { path: 'framework', component: FrameworkComponent },
          { path: 'seo', component: SeoComponent },
          { path: 'analytics', component: AnalyticsComponent },
        ]
      },
      {
        path: 'productivity', component: ProductivityComponent
      },
      {
        path: 'metrics', component: MetricsComponent,
        children: [

        ]
      },
      {
        path: 'setup', component: SetupComponent,
        children: [
          { path: 'profile', component: ProfileComponent },
          { path: 'updateaccount', component: UpdateaccountComponent },
          { path: 'password', component: PasswordComponent },
          { path: 'support', component: SupportComponent },
          {
            path: 'manageusers', component: ManageusersComponent,
            children: [
              { path: 'listusers', component: ListusersComponent },
              { path: 'newuser', component: NewuserComponent },
              { path: 'logs/:id', component: LogsComponent },
              { path: 'edituser/:id', component: EdituserComponent },
            ]
          },
          { path: 'nipr', component: NiprComponent },

          {
            path: 'agentscodesmanager', component: AgentscodesmanagerComponent,
            children: [
              { path: 'customlist', component: CustomlistComponent },
              { path: 'customnew', component: CustomnewComponent },
              { path: 'customedit', component: CustomeditComponent },


              { path: 'hardcodedlist', component: HardcodedlistComponent },
            ]
          },
        ]
      },
      {
        path: 'agents', component: AgentsComponent,
        children: [
          { path: 'agentsprospects', component: AgentsprospectsComponent },
          { path: 'agentsdashboard', component: AgentsdashboardComponent },
          { path: 'newagent', component: NewagentComponent },
          { path: 'agentshierarchy', component: AgentshierarchyComponent },
          {
            path: 'agentscodesmanager', component: AgentscodesmanagerComponent,
            children: [
              { path: 'customlist', component: CustomlistComponent },
              { path: 'customnew', component: CustomnewComponent },
              { path: 'customedit', component: CustomeditComponent },
              { path: 'hardcodedlist', component: HardcodedlistComponent },
            ]
          },
          { path: 'viewagent', component: ViewagentComponent },
          { path: 'agentedition', component: AgenteditionComponent },
          { path: 'viewprospect', component: ViewprospectComponent },

        ]
      },
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
