import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-platform',
  templateUrl: './platform.component.html',
  styleUrls: ['./platform.component.css']
})
export class PlatformComponent implements OnInit {
  application: boolean=false;
  application2: boolean=false;
  accstatus: string;
  Afetype: string;
  PLID: string;
  Alerts: any;
  AlertsPending: boolean = false;
  NumAlerts: number = 0
  constructor(
    public _router: Router,
    public _httpClient: HttpClient,
    public r: ActivatedRoute,
  ) {

    let selections = sessionStorage.getItem("selections")
    if (selections) {
      this.application=selections.includes("rvN8MtrKYJ5RrXAHLWZu2A==")
      this.application2=selections.includes("nITaM2cKc2uXK2Kr34lbmQ==")
    }
    

    if (!sessionStorage.getItem('plid')) {
      this._router.navigateByUrl("/login");
    }
    this.accstatus = sessionStorage.getItem("status");
    this.Afetype = sessionStorage.getItem("afetype");
    this.PLID = sessionStorage.getItem("plid");


    //check for alerts
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/checkalerts.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));

    this._httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        var APIresult: any = result;
        if (result == null) {

        }
        else {
          if (APIresult.success == "1") {
            this.Alerts = APIresult.alerts;
            
            for (let alerts of this.Alerts) {
              this.NumAlerts = this.NumAlerts + 1

            }
            if (this.NumAlerts > 0) {
              Swal.fire('Pending Tasks', 'There are pending tasks ('+ this.NumAlerts + ') for you to complete before you can utilize AFEUSA Desk','info');
              this._router.navigate(['signaddendum'], { relativeTo: this.r });

            }

          }
        }
      },
      error => {

      });



  }

  LogOut(): void {

    Swal.fire({
      title: 'Are you sure?',
      text: "Are you sure you want to exit?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        var endpoint = sessionStorage.getItem("endpoint");
        var server = sessionStorage.getItem("server");
        sessionStorage.clear();
        sessionStorage.setItem("endpoint", endpoint);
        sessionStorage.setItem("server", server);
        this._router.navigateByUrl("/login");
      }
    })



  }

  ngOnInit(): void {
  }


  doAgents(): void {
    sessionStorage.removeItem('temp');

    this._router.navigate(['/platform/agents/agentshierarchy']);
  }

}
