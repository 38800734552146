import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-siteadmin',
  templateUrl: './siteadmin.component.html',
  styleUrls: ['./siteadmin.component.css']
})
export class SiteadminComponent implements OnInit {
  navbarOpen2 = false;
  constructor() { }
  toggleNavbar2() {
    this.navbarOpen2 = !this.navbarOpen2;
  }
  ngOnInit(): void {
  }

}
