import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SelectItem, FilterService, FilterMatchMode } from 'primeng/api';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-querybuilder',
  templateUrl: './querybuilder.component.html',
  styleUrls: ['./querybuilder.component.css']
})
export class QuerybuilderComponent implements OnInit {
  public timeframe: boolean = false;
  public data: any;
  public showBuilder: boolean = true;
  cols: any[];
  filename: string = "File"
  //report members
  public APIresults: any;
  public Members: any;
  public memberData: any;
  public agentinfo: any;
  public agentproducts: any = [];
  public dependents: any;
  public beneficiaries: any;
  public upline: any;
  ContinuationUrl: string = "";
  AccessToken: string = "";
  ImpersonationSuccess: boolean = false;
  RegistrationDetails_UserName: string = "";
  public personData: any = {
    FirstName: "",
    LastName: "",
    BirthDate: "",
    Gender: "",
    Relationship: "",
    Address1: "",
    City: "",
    State: "",
    State_Name: "",
    Zip: "",
    Phone: "",
    IsDisabled: "",
    AFEtype: "",
    PersonCode: ""
  }

  public showDependedBox: boolean = false;
  public showBeneficiaryBox: boolean = false;

  public openSide: boolean = false;
  public preloader: boolean = false;


  public selectedPlanName: string = "";
  public selectedPlanId: string = "";
  public selectedTierCode: string = ""
  public selectedFee: string = ""
  public selectedProductId: string = "";

  public payload: any = {
    timeframe: 'timeframe',
    timeframevalue: 'All Time',
    timefrom: '',
    timeto: '',
    groupby: 'transaction',
    basereport: 'MyFull',
    basereportagent: '',
    basereportagentdownline: '',
    includedownline: false
  }
  //member reports
  public reportTransaction: any;
  public reportData: any;
  public validTransaction: boolean = false;
  public stats: any;



  constructor(
    public httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    //BRING AGENTS
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/treeview.aspx";
    var options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    var body = new URLSearchParams();

    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.data = result;


      },
      error => {

      });

    this.cols = [
      { field: 'ReferenceID', header: 'Reference ID' },
      { field: 'PLfirstname', header: 'First Name' },
      { field: 'PLlastname', header: 'Last Name' },
      { field: 'DateAPIRequested', header: 'Date' },
      { field: 'MemberFname', header: 'Member First Name' },
      { field: 'MemberLname', header: 'Member Last Name' },

      { field: 'Premium', header: 'Premium' },
      { field: 'Products', header: 'Products' },
      { field: 'Origin', header: 'Origin' },
      { field: 'SalePLcommission', header: 'Agent Commission' },
      { field: 'MyCommission', header: 'Your Commission' }
    ];

  }


  selectTimeFrame(timeframe): void {
    if (timeframe == 'timeframe') { this.payload.timeframe = 'timeframe' }
    if (timeframe == 'fromto') { this.payload.timeframe = 'fromto' }
  }

  selectAnalysis(analysis): void {
    this.payload.basereport = analysis;
    
  }

  selectReport(report): void {
    this.payload.reporttype = report;
  }

  selectGroup(group): void {
    this.payload.groupby = group;
  }

  onFocusEvent(event: any): void {
    this.payload.timeframe = 'fromto';
  }
  processReport(): void {

    if (this.payload.timeframe == 'fromto') {
      if (!Date.parse(this.payload.timefrom)) {
        Swal.fire('Check your dates', 'Enter a valid starting date', 'error');
        return;
      }
      if (!Date.parse(this.payload.timeto)) {
        Swal.fire('Check your dates', 'Enter a valid ending date', 'error');
        return;
      }
      if (Date.parse(this.payload.timefrom) > Date.parse(this.payload.timeto)) {
        Swal.fire('Check your dates', 'The sarting date cannot be greater than the ending date', 'error');
        return;
      }

      let currentDate = new Date(this.payload.timefrom);
      let dateSent = new Date(this.payload.timeto);
      let days = (Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24))) * (-1);
      if (days > 366) {
        Swal.fire('Check your dates', 'This report cannot process more than mobile year', 'error');
        return;
      }
    }
    //console.log(this.payload)
    //START REPORT API

    //report by transaction
    if (this.payload.groupby=="transaction") {
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/reportMembers.aspx";
    var options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    var body = new URLSearchParams();

    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('payload', encodeURIComponent(JSON.stringify(this.payload)));
    this.preloader = true;
    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.preloader = false;
        var response: any = result;
        
          this.reportTransaction = response.grid;
          this.reportData = response.data;
          this.validTransaction = true;
          this.stats = response.stats;
          this.showBuilder = false;
          this.filename = this.reportData.includes + " from " + this.reportData.from + " to " + this.reportData.todate + " for " + this.reportData.agentname
      

      },
      error => {
        this.preloader = false;
      });
    }


  }


  startOver(): void {
    this.validTransaction = false;
    this.reportTransaction = [];
    this.showBuilder = true;
    this.showBuilder = true;
  }





  onRowSelect(id): void {
    this.APIresults = undefined;
    this.dependents = undefined;
    this.selectedPlanName = "";
    this.selectedPlanId = "";
    this.selectedTierCode = "";
    this.agentinfo = undefined;
    this.agentproducts = undefined;
    this.memberData = undefined;
    this.upline = undefined;
    this.ImpersonationSuccess = false;



    this.openSide = true;
    this.preloader = true;
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/clients/clientdata.aspx";
    var options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    var body = new URLSearchParams();

    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('policy', encodeURIComponent(id));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.APIresults = result;
        this.agentinfo = this.APIresults.agentinfo;
        this.agentproducts = this.APIresults.agentproducts;
        this.memberData = this.APIresults.memberdata;
        this.upline = this.APIresults.uplineCompensation;
        this.memberPortal();
      },
      error => {

      });
    this.preloader = false;
  }


  memberPortal(): void {
    let AgentID: string = ""
    let doApi = false;
    try {
      AgentID = this.agentinfo.agentprincipalid
      doApi = true;
    }
    catch {
      AgentID = sessionStorage.getItem("AgentPrincipalId")
    };
    let Payload = {
      "MemberPrincipalId": this.memberData.HCImemberid.toString(),
      "AgentId": AgentID
    }
    if (doApi == true) {
      this.preloader = true;
      var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/clients/impersonate.aspx";
      var options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
      var body = new URLSearchParams();

      body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
      body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
      body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
      body.set('payload', JSON.stringify(Payload));

      this.httpClient.post(APIURL, body.toString(), options).subscribe(
        result => {
          let ImpersonationAPI: any = result;
          if (ImpersonationAPI.success == "1") {
            this.ContinuationUrl = ImpersonationAPI.ContinuationURL;
            this.AccessToken = ImpersonationAPI.AccessToken;
            this.ImpersonationSuccess = true;
            this.RegistrationDetails_UserName = this.memberData.RegistrationDetails_UserName;
          }
        },
        error => {

        });
      this.preloader = false;
    }
  }



  ResendWelcomeEmail(): void {
    let Payload = {
      "EnrollProjectId": this.memberData.projectid.toString()
    }

    this.preloader = true;
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/clients/resendemail.aspx";
    var options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    var body = new URLSearchParams();

    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('payload', JSON.stringify(Payload));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {

      },
      error => {

      });
    Swal.fire({
      title: 'Email Sent',
      text: "The welcome email has been resent.",
      icon: 'info',
    })
    this.preloader = false;
  }

  FormatMoney(amount) {
    return (Number(amount).toLocaleString())

  }

  PlanDetails(id, name, fee): void {
    this.dependents = undefined;
    this.beneficiaries = undefined;
    this.selectedPlanName = name;
    this.selectedPlanId = id;
    this.selectedTierCode = "Individual";
    this.selectedFee = fee;
    this.selectedProductId = ""

    for (let result of this.APIresults.dependents) {
      if (result.ProductId == id) {
        this.selectedPlanName = name;
        this.selectedPlanId = id;
        this.dependents = result;
        this.selectedProductId = id;
        switch (result.SelectedTierCode) {
          case "ISP": {
            this.selectedTierCode = "Individual + Spouse"
            break;
          }
          case "ICH": {
            this.selectedTierCode = "Individual + Children"
            break;
          }
          case "FAM": {
            this.selectedTierCode = "Family Group"
            break;
          }
          case "IND": {
            this.selectedTierCode = "Individual"
            break;
          }
          case "IND+1": {
            this.selectedTierCode = "Individual + One"
            break;
          }
        }
      }
    }

    if (!this.dependents) {
      if (id == 8339 || id == 8676) {
        this.selectedPlanName = name;
        this.selectedPlanId = id;
        this.beneficiaries = this.APIresults.beneficiaries;
        this.selectedProductId = id;
      }
    }


  }


  DependentDetails(productId, PersonCode): void {
    this.personData = undefined;
    for (let result of this.dependents.POLICIES_DependentDemographics) {
      if (result.PersonCode == PersonCode) {
        this.personData = result;
        this.showDependedBox = true;
      }
    }
  }

  BeneficiaryDetails(beneficiaryId): void {
    this.personData = undefined;
    for (let result of this.beneficiaries) {
      if (result.BeneficiaryId == beneficiaryId) {
        this.personData = result;
        this.showBeneficiaryBox = true;
      }
    }
  }

  GoToAgent(id): void {
    sessionStorage.setItem("temp", id)
    this.router.navigate(['../../agents/viewagent'], { relativeTo: this.route });
  }

}
