<h4>Delta Dental Codes</h4>
<p>Your Delta Dental codes are used to enroll agents to sell Delta Dental products at the specified commission levels.</p>
<hr>
<div class="w100">

    <div class="row">
        <div *ngIf="APIdental" class="col-xl-4 col-lg-4 col-sm-12 col-md-4 col-12">
            <div class="alert alert-white square">
                <img src="assets/images/logodental.png" style="height: 25px;">
                <hr>
                <div class="row" style="height: 30px">
                    <div class="col-8">
                        <strong>Code</strong>
                    </div>
                    <div class="col-2 text-right">
                        <strong>Year 1</strong>
                    </div>
                    <div class="col-2 text-right">
                        <strong>Year 2+</strong>
                    </div>
                </div>
                <div class="row" *ngFor="let commission of APIdental" style="height: 30px">
                    <div class="col-8">
                        <small>{{commission.code}}</small>
                    </div>
                    <div class="col-2 text-right">
                        {{commission.comp}}%
                    </div>
                    <div class="col-2 text-right">
                        {{commission.compy2}}%
                    </div>
                </div>
            </div>
        </div>
        <!--
        <div *ngIf="APIchubb" class="col-xl-4 col-lg-4 col-sm-12 col-md-4 col-12">
            <div class="alert alert-white square">
                <img src="assets/images/logochubb.png" style="height: 20px;">
                <hr>
                <div class="row" *ngFor="let commission of APIchubb" style="height: 30px">
                    <div class="col-md-6">
                        {{commission.code}}
                    </div>
                    <div class="col-md-6 text-right">
                        {{commission.comp}}%
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="APIlife" class="col-xl-4 col-lg-4 col-sm-12 col-md-4 col-12">
            <div class="alert alert-white square">
                <img src="assets/images/logostandard.png" style="height: 25px;">
                <hr>
                <div class="row" *ngFor="let commission of APIlife" style="height: 30px">
                    <div class="col-md-6">
                        {{commission.code}}
                    </div>
                    <div class="col-md-6 text-right">
                        {{commission.comp}}%
                    </div>
                </div>
            </div>
        </div>
-->
    </div>


</div>