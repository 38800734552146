

<div class="level1page ">

    <div class="stick-ontop">
        <h4 style="color:#f1f1f1; padding-top: 20px; ">
            Websites Management
        </h4>
        <hr>

        <div>
            <div class="row">
                <div class="col-6 col-md-4 col-xl-2">
                    <div class="productbox2 mousepointer" [routerLink]="['products']" >
                        <div class="text-left">
                            <span class="superfont">List Websites</span>
                            <br><span class="smallfont">List your current websites</span>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-xl-2" *ngIf="application">
                    <div class="productbox2 mousepointer" [routerLink]="['branding']" >
                        <div class="text-left">
                            <span class="superfont">New Website</span>
                            <br><span class="smallfont">Creates a new website</span>
                        </div>
                    </div>
                </div>
              
            </div>
        </div>
        <br>



    </div>

   
    <div >
        <div class="subbox text-left" >
            <router-outlet></router-outlet>
        </div>
    </div>
    <br>
</div>

<!--
<div class="fixed-top" style="z-index: 90;">

    <div style="padding-left: 90px;">
        <nav class="navbar navbar-expand-lg navbar-dark" style="background-color: #3e669c;">
            <a class="navbar-brand" href="#">
                  Website Management: 
                </a>

            <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
                  <span class="navbar-toggler-icon"></span>
                </button>


            <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }">
                <ul class="navbar-nav mr-auto">
                   
                    <li class="nav-item">
                        <a class="nav-link" routerLink="products" routerLinkActive="btn btn-sm btn-dark">List Websites</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="branding" routerLinkActive="btn btn-sm btn-dark">New Website</a>
                    </li>




                </ul>
            </div>
        </nav>
    </div>
</div>
<div class="body-text" style="z-index: -2; padding-right:20px;">
    <router-outlet></router-outlet>
</div>

-->