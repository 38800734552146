<div class="bShadow-5">
    <h5>New Note</h5>
    <hr>
    <div class="row">
        <div class="col-12 col-md-8">
            <label>Summary / Title (Optional - Up to 100 characters)</label>
            <input type="text" class="form-control" maxlength="100" [(ngModel)]="Summary"> </div>
        <div class="col-12 col-md-4">
            <label>Status</label>
            <select class="form-control" [(ngModel)]="Status">
                <option value="None">None</option>
                <option value="Active">Active</option>
                <option value="Working">Working</option>
                <option value="Closed">Closed</option>
            </select>
        </div>
    </div>


    <label>Notes (html content allowed)</label>
    <angular-editor [placeholder]=" 'Enter text here...' " [config]="editorConfig " [(ngModel)]="Note">
    </angular-editor>
    <br>
    <button class="btn btn-sm btn-primary text-white" (click)="SaveNote()">Save Note</button>
    <br>
</div>
<br>
<br>
<div class="bShadow-5">
    <h5>Notes</h5>
    <hr>
    <p-table #dt [value]="notesList" [rows]="100" [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[100, 250, 500, 1000]" styleClass="p-datatable-customers" [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} notes" [filterDelay]="0"
        [globalFilterFields]="[
                    'fullname',
                    'email',
                    'phone',
                    'downline',
                    'companyname',
                    'signupdate',
                    'city',
                    'state',
                    'products',
                    'status',
                    'policies',
                    'statustext'
                  ]" [resizableColumns]="true" [autoLayout]="true">
        <ng-template pTemplate="header">
            <tr class="FontRow" style="font-size: 11px !important">
                <th [pSortableColumn]="'companyname'">Date</th>
                <th [pSortableColumn]="'fullname'"><select class="form-control"
                        (input)="dt.filter($event.target.value, 'status', 'equals')">
                        <option value="">List All Statuses</option>
                        <option value="None">None Specified</option>
                        <option value="Active">Active</option>
                        <option value="Working">Working</option>
                        <option value="Closed">Closed</option>
                    </select></th>
                <th [pSortableColumn]="'email'">Summary</th>
                <th [pSortableColumn]="''">Actions</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-notesList let-rowIndex="rowIndex">
            <tr class="FontRow f13" style="cursor: pointer; font-size: 11px">
                <td>
                    {{notesList.datetime}}
                </td>
                <td>
                    {{notesList.status}}
                </td>
                <td>
                    {{notesList.summary}}
                </td>
                <td class="text-right">
                    <button class="btn btn-sm btn-primary" style="margin-right: 10px"
                        (click)="OpenNote(notesList.noteid)">OPEN</button>
                    <button class="btn btn-sm btn-danger" style="margin-right: 10px"
                        (click)="DeleteNote(notesList.noteid)">DELETE</button>
                </td>
            </tr>

        </ng-template>
    </p-table>
</div>


<p-dialog [(visible)]="showNote" modal="true" [draggable]="false" [closeOnEscape]="true" [responsive]="true"
    [style]="{ width: '1000px' }" [baseZIndex]="1">
    <p-header style="width: 100%">
        <h5>{{openSummary}}</h5>
    </p-header>
    <hr>
    <div>
        <div class="row">
            <div class="col-12 col-md-8">
                <label>Summary / Title (Optional - Up to 100 characters)</label>
                <input type="text" class="form-control" maxlength="100" [(ngModel)]="openSummary"> </div>
            <div class="col-12 col-md-4">
                <label>Status</label>
                <select class="form-control" [(ngModel)]="openStatus">
                    <option value="None">None</option>
                    <option value="Active">Active</option>
                    <option value="Working">Working</option>
                    <option value="Closed">Closed</option>
                </select>
            </div>
        </div>


        <label>Notes (html content allowed)</label>
        <angular-editor [placeholder]=" 'Enter text here...' " [config]="editorConfig2" [(ngModel)]="openNote">
        </angular-editor>
        <br>
        <button class="btn btn-sm btn-warning text-white" (click)="SaveNoteChanges()">Save Changes</button>
        <br>
    </div>
</p-dialog>