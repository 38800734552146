import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SelectItem, FilterService, FilterMatchMode } from 'primeng/api';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-contracted',
  templateUrl: './contracted.component.html',
  styleUrls: ['./contracted.component.css']
})
export class ContractedComponent implements OnInit {
  @Input()
  PL: string;
  application: boolean=false;
  application2: boolean=false;

  ContinuationUrl: string = "";
  AccessToken: string = "";
  ImpersonationSuccess: boolean = false;
  RegistrationDetails_UserName: string="";
  public APIresults: any;
  public Members: any;
  public memberData: any;
  public agentinfo: any;
  public agentproducts: any = [];
  public dependents: any;
  public beneficiaries: any;
  public upline: any;
  public personData: any = {
    FirstName: "",
    LastName: "",
    BirthDate: "",
    Gender: "",
    Relationship: "",
    Address1: "",
    City: "",
    State: "",
    State_Name: "",
    Zip: "",
    Phone: "",
    IsDisabled: "",
    AFEtype: "",
    PersonCode: ""
  }

  public showDependedBox: boolean = false;
  public showBeneficiaryBox: boolean = false;

  public openSide: boolean = false;
  public preloader: boolean = false;


  public selectedPlanName: string = "";
  public selectedPlanId: string = "";
  public selectedTierCode: string = ""
  public selectedFee: string = ""
  public selectedProductId: string = "";
  constructor(

    public httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private filterService: FilterService
  ) {
    let selections = sessionStorage.getItem("selections");
    this.application=selections.includes("1tkSm93qe962qnJ82PPUxQ==");
    this.application2=selections.includes("nITaM2cKc2uXK2Kr34lbmQ==");

  }


  memberPortal(): void {
    let AgentID: string = ""
    let doApi=false;
    try {
      AgentID = this.agentinfo.agentprincipalid
      doApi=true;
    }
    catch {
      AgentID = sessionStorage.getItem("AgentPrincipalId")
    };
    let Payload = {
      "MemberPrincipalId": this.memberData.HCImemberid.toString(),
      "AgentId": AgentID
    }
    if (doApi==true){
    this.preloader = true;
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/clients/impersonate.aspx";
    var options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    var body = new URLSearchParams();

    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('payload', JSON.stringify(Payload));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        let ImpersonationAPI: any = result;
        if (ImpersonationAPI.success == "1") {
          this.ContinuationUrl = ImpersonationAPI.ContinuationURL;
          this.AccessToken = ImpersonationAPI.AccessToken;
          this.ImpersonationSuccess = true;
          this.RegistrationDetails_UserName=this.memberData.RegistrationDetails_UserName;
        }
      },
      error => {

      });
    this.preloader = false;
    }
  }


  ResendWelcomeEmail(): void {
    let Payload = {
      "EnrollProjectId": this.memberData.projectid.toString()
    }

    this.preloader = true;
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/clients/resendemail.aspx";
    var options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    var body = new URLSearchParams();

    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('payload', JSON.stringify(Payload));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        
      },
      error => {

      });
      Swal.fire({
        title: 'Email Sent',
        text: "The welcome email has been resent.",
        icon: 'info',
      })
    this.preloader = false;
  }


  LoadProducts(): void {
    this.preloader = true;
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/clients/listclients.aspx";
    var options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    var body = new URLSearchParams();

    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('pl', encodeURIComponent(this.PL));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.Members = result;
      },
      error => {

      });
    this.preloader = false;
  }

  onRowSelect(id): void {
    window.scrollTo(0, 0);
    this.APIresults = undefined;
    this.dependents = undefined;
    this.selectedPlanName = "";
    this.selectedPlanId = "";
    this.selectedTierCode = "";
    this.agentinfo = undefined;
    this.agentproducts = undefined;
    this.memberData = undefined;
    this.upline = undefined;
    this.ImpersonationSuccess=false;



    this.openSide = true;
    this.preloader = true;
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/clients/clientdata.aspx";
    var options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    var body = new URLSearchParams();

    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('policy', encodeURIComponent(id));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.APIresults = result;
        this.agentinfo = this.APIresults.agentinfo;
        this.agentproducts = this.APIresults.agentproducts;
        this.memberData = this.APIresults.memberdata;
        this.upline = this.APIresults.uplineCompensation;
        this.memberPortal();
      },
      error => {

      });
    this.preloader = false;



  }


  FormatMoney(amount) {
    return (Number(amount).toLocaleString())

  }

  PlanDetails(id, name, fee): void {
    this.dependents = undefined;
    this.beneficiaries = undefined;
    this.selectedPlanName = name;
    this.selectedPlanId = id;
    this.selectedTierCode = "Individual";
    this.selectedFee = fee;
    this.selectedProductId = ""

    for (let result of this.APIresults.dependents) {
      if (result.ProductId == id) {
        this.selectedPlanName = name;
        this.selectedPlanId = id;
        this.dependents = result;
        this.selectedProductId = id;
        switch (result.SelectedTierCode) {
          case "ISP": {
            this.selectedTierCode = "Individual + Spouse"
            break;
          }
          case "ICH": {
            this.selectedTierCode = "Individual + Children"
            break;
          }
          case "FAM": {
            this.selectedTierCode = "Family Group"
            break;
          }
          case "IND": {
            this.selectedTierCode = "Individual"
            break;
          }
          case "IND+1": {
            this.selectedTierCode = "Individual + One"
            break;
          }
        }
      }
    }
    if (!this.dependents) {
      if (id == 8339 || id == 8676) {
        this.selectedPlanName = name;
        this.selectedPlanId = id;
        this.beneficiaries = this.APIresults.beneficiaries;
        this.selectedProductId = id;
      }
    }


  }


  DependentDetails(productId, PersonCode): void {
    this.personData = undefined;
    for (let result of this.dependents.POLICIES_DependentDemographics) {
      if (result.PersonCode == PersonCode) {
        this.personData = result;
        this.showDependedBox = true;
      }
    }
  }

  BeneficiaryDetails(beneficiaryId): void {
    this.personData = undefined;
    for (let result of this.beneficiaries) {
      if (result.BeneficiaryId == beneficiaryId) {
        this.personData = result;
        this.showBeneficiaryBox = true;
      }
    }
  }

  GoToAgent(id): void {
    sessionStorage.setItem("temp", id)
    this.router.navigate(['../../agents/viewagent'], { relativeTo: this.route });
  }

  ngOnInit(): void {
    this.LoadProducts();
    
  }


  cancelFullMembership(): void {

    Swal.fire({
      title: 'Are you sure?',
      text: "This action cannot be reversed. The AFEUSA Membership and all its associated plans will be cancelled immediately. Please confirm this operation.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Cancel Memberhsip'
    }).then((result) => {
      if (result.isConfirmed) {

        if (result.isConfirmed) {
          Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this operation.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Cancel Membership'
          }).then((result) => {
            if (result.isConfirmed) {
              this.preloader = true;
              var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/clients/cancelMember.aspx";
              var options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
              var body = new URLSearchParams();

              body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
              body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
              body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
              body.set('policy', encodeURIComponent(this.APIresults.memberdata.policyid));
              body.set('projectid', encodeURIComponent(this.APIresults.memberdata.projectid));
              body.set('ReferenceID', encodeURIComponent(this.APIresults.memberdata.ReferenceID));
              body.set('pl', encodeURIComponent(this.APIresults.memberdata.plid));

              this.httpClient.post(APIURL, body.toString(), options).subscribe(
                result => {
                  if (result == null) {
                    Swal.fire({
                      title: 'API Error',
                      text: "A connection to AFEUSA servers could not be established.",
                      icon: 'error',
                    })
                    this.preloader = false;
                    return;
                  }
                  var cancelAPI: any = result;

                  if (cancelAPI.success == "1") {
                    Swal.fire({
                      title: 'Membership Terminated',
                      text: cancelAPI.message,
                      icon: 'info',
                    })
                    this.openSide = false
                    this.preloader = false;
                    return;
                  }


                  if (cancelAPI.success != "1") {
                    Swal.fire({
                      title: 'Error',
                      text: cancelAPI.message,
                      icon: 'error',
                    })
                    this.preloader = false;
                    return;
                  }

                },
                error => {
                  this.preloader = false;
                });

            }
          })
        }






      }
    })

  }


  cancelPlan(id): void {
    Swal.fire({
      title: 'Cancel ' + this.selectedPlanName + "?",
      text: "This will cancel ONLY the " + this.selectedPlanName + ' plan. To cancel the entire membership and all the associated plans, please click the CANCEL MEMBERSHIP button above.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Cancel This Plan'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this operation.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then((result) => {
          if (result.isConfirmed) {


            var Plan: any = this.APIresults.products

            for (let result of Plan) {
              if (result.ProductId == id) {
                var PlanToCancel: any = result;

                var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/clients/cancelPlan.aspx";
                var options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
                var body = new URLSearchParams();

                body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
                body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
                body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
                body.set('policy', encodeURIComponent(this.APIresults.memberdata.policyid));
                body.set('projectid', encodeURIComponent(this.APIresults.memberdata.projectid));
                body.set('ReferenceID', encodeURIComponent(this.APIresults.memberdata.ReferenceID));
                body.set('pl', encodeURIComponent(this.APIresults.memberdata.plid));
                body.set('plan', id);
                body.set('selectedplanid', encodeURIComponent(PlanToCancel.SelectedPlanId));
                body.set('afeid', encodeURIComponent(PlanToCancel.afeplanid));



                this.httpClient.post(APIURL, body.toString(), options).subscribe(
                  result => {
                    if (result == null) {
                      Swal.fire({
                        title: 'API Error',
                        text: "A connection to AFEUSA servers could not be established.",
                        icon: 'error',
                      })
                      return;
                    }
                    var cancelAPI: any = result;

                    if (cancelAPI.success == "1") {
                      Swal.fire({
                        title: this.selectedPlanName + ' Terminated',
                        text: cancelAPI.message,
                        icon: 'info',
                      })
                      return;
                    }


                    if (cancelAPI.success != "1") {
                      Swal.fire({
                        title: 'Error',
                        text: cancelAPI.message,
                        icon: 'error',
                      })
                      return;
                    }

                  },
                  error => {

                  });





              }
            }





          }
        })


      }
    })

  }

  ngOnChanges() {
    // create header using child_id
    let selections = sessionStorage.getItem("selections");
    this.application=selections.includes("1tkSm93qe962qnJ82PPUxQ==");
    this.application2=selections.includes("nITaM2cKc2uXK2Kr34lbmQ==");
    this.LoadProducts();

  }

}
