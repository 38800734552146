<h4>Report Builder <span class="text-danger">(BETA)</span></h4>
<hr>
<div *ngIf="showBuilder">
  <div class="row">
    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
      <div class="productboxlightnoheight text-dark" style="min-height: 270px;">
        <h5>Select Report Timeframe</h5>
        <hr>
        <div class="row">
          <div class="col-5">
            <label (click)="selectTimeFrame('timeframe')">
              <input type="radio" name="timeoption" value="timeframe" [(ngModel)]="payload.timeframe">
              Timeframe
            </label>

          </div>

          <div class="col-7">
            <select class="form-control" [(ngModel)]="payload.timeframevalue" (change)="selectTimeFrame('timeframe')">
              <option value="Today">Today</option>
              <option value="Yesterday">Yesterday</option>
              <option value="This Week">This Week</option>
              <option value="Last Week">Last Week</option>
              <option value="This Month">This Month</option>
              <option value="Last Month">Last Month</option>
              <option value="Last 3 Months">Last 3 Months (excluding this month)</option>
              <option value="Last 6 Months">Last 6 Months (excluding this month)</option>
              <option value="Year To Date">Year To Date</option>
              <option value="Previous Year">Previous Year</option>
              <option value="All Time">All Time</option>
            </select>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-5">
            <label (click)="selectTimeFrame('fromto')">
              <input type="radio" name="timeoption" value="fromto">
              Date Range
            </label>

          </div>

          <div class="col-7">
            <input (focus)="onFocusEvent($event)" class="form-control textfield" maxlength="20"
              style="border-radius: 7px" mask="M0/d0/0000" [dropSpecialCharacters]="false" placeholder="mm/dd/yyyy"
              [(ngModel)]="payload.timefrom" />
            <br>
            <input (focus)="onFocusEvent($event)" class="form-control textfield" maxlength="20"
              style="border-radius: 7px" mask="M0/d0/0000" [dropSpecialCharacters]="false" placeholder="mm/dd/yyyy"
              [(ngModel)]="payload.timeto" />
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
      <div class="productboxlightnoheight text-dark" style="min-height: 270px;">
        <h5>Base Analysis / Report</h5>
        <hr>
        <div class="row">
          <div class="col-12">
            <label (click)="selectAnalysis('MyFull')">
              <input type="radio" name="agent" value="MyFull" [(ngModel)]="payload.basereport">
              My Own, and my Downline Production
            </label>
          </div>
          <div class="col-12">
            <label (click)="selectAnalysis('MyProduction')">
              <input type="radio" name="agent" value="MyProduction" [(ngModel)]="payload.basereport">
              Only My Production
            </label>
          </div>
          <div class="col-12">
            <label (click)="selectAnalysis('MyDownline')">
              <input type="radio" name="agent" value="MyDownline" [(ngModel)]="payload.basereport">
              Only My Downline Production
            </label>
          </div>

          <div class="col-5">
            <label (click)="selectAnalysis('SpecificAgent')">
              <input type="radio" name="agent" value="SpecificAgent" [(ngModel)]="payload.basereport">
              Specific Agent
            </label>

          </div>

          <div class="col-7">
            <select class="form-control" (change)="selectAnalysis('SpecificAgent')"
              [(ngModel)]="payload.basereportagent">
              <option *ngFor="let agent of data" value="{{agent.PLID}}"> (DL: {{agent.level}}) - 
                {{agent.firstname}} {{agent.lastname}}</option>
            </select>
            <label (click)="selectTimeFrame('frometo')">
              <input type="checkbox" [(ngModel)]="payload.includedownline" (click)="selectAnalysis('SpecificAgent')">
              Include Downline
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
      <div class="productboxlightnoheight text-dark" style="min-height: 270px;">
        <h5>Group Criteria</h5>
        <hr>
        <div class="row">
          <div class="col-12">
            <label (click)="selectGroup('transaction')">
              <input type="radio" name="data" value="transaction" [(ngModel)]="payload.groupby">
              By Transaction
            </label>
          </div>
          <!--
          <div class="col-12">
            <label (click)="selectGroup('product')">
              <input type="radio" name="data" value="product" [(ngModel)]="payload.groupby">
              By Product
            </label>
          </div>
          <div class="col-12">
            <label (click)="selectGroup('agent')">
              <input type="radio" name="data" value="agent" [(ngModel)]="payload.groupby">
              By Agent
            </label>
          </div>
-->
        </div>
      </div>
    </div>

  </div>
  <br>
  <button class="btn btn-primary" (click)="processReport()">Process Commissions Report</button>
  <br><br>
</div>

<!--
    *************************************************
    ************** TRANSACTION REPORT ***************
    *************************************************
-->
<div *ngIf="validTransaction">
  <h6>{{reportData.includes}} from {{reportData.from}} to {{reportData.todate}} for {{reportData.agentname}}</h6>
  <hr>



  <div class="row">
    <div class="col-12 col-md-4">
      <div class="productbox boxheight">
        <div>
          <div class="boxtitle"><i class="fa fa-user"></i> Your Total Commission</div>
          <span class="superfont">${{stats.mycommission + stats.downlinecommission | number:'1.2-2'}}</span>
          <br><span class="smallfont">100%</span>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-4">
      <div class="productbox boxheight">
        <div>
          <div class="boxtitle"><i class="fa fa-user"></i> Total Sales ($)</div>
          <span class="superfont">${{(stats.myproduction + stats.downlineproduction) | number:'1.2-2'}}</span>
          <br><span class="smallfont">100%</span>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-4">
      <div class="productbox boxheight">
        <div>
          <div class="boxtitle"><i class="fa fa-user"></i> Total Transactions</div>
          <span class="superfont">{{stats.mytransactions + stats.downlinetransactions }}</span>
          <br><span class="smallfont">100%</span>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-2">
      <div class="productboxlightnoheight boxheight">
        <div>
          <div class="boxtitlelight"><i class="fa fa-user"></i> Personal</div>
          <span class="superfontlight">${{(stats.mycommission) | number:'1.2-2'}}</span>
          <br><span
            class="smallfontlight">{{stats.mycommission*100/(stats.mycommission + stats.downlinecommission) | number:'1.2-2'}}%</span>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-2">
      <div class="productboxlightnoheight boxheight">
        <div>
          <div class="boxtitlelight"><i class="fa fa-user"></i> Downline</div>
          <span class="superfontlight">${{(stats.downlinecommission) | number:'1.2-2'}}</span>
          <br><span
            class="smallfontlight">{{stats.downlinecommission*100/(stats.mycommission + stats.downlinecommission) | number:'1.2-2'}}%</span>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-2">
      <div class="productboxlightnoheight boxheight">
        <div>
          <div class="boxtitlelight"><i class="fa fa-user"></i> Personal</div>
          <span class="superfontlight">${{stats.myproduction | number:'1.2-2'}}</span>
          <br><span
            class="smallfontlight">{{stats.myproduction*100/(stats.myproduction + stats.downlineproduction) | number:'1.2-2'}}%
            of
            sales</span>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-2">
      <div class="productboxlightnoheight boxheight">
        <div>
          <div class="boxtitlelight"><i class="fa fa-user"></i> Downline</div>
          <span class="superfontlight">${{stats.downlineproduction | number:'1.2-2'}}</span>
          <br><span
            class="smallfontlight">{{stats.downlineproduction*100/(stats.myproduction + stats.downlineproduction) | number:'1.2-2'}}%
            of
            sales</span>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-2">
      <div class="productboxlightnoheight boxheight">
        <div>
          <div class="boxtitlelight"><i class="fa fa-user"></i> Personal</div>
          <span class="superfontlight">{{stats.mytransactions}}</span>
          <br><span
            class="smallfontlight">{{stats.mytransactions*100/(stats.mytransactions + stats.downlinetransactions) | number:'1.2-2'}}%
            of
            transactions</span>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-2">
      <div class="productboxlightnoheight boxheight">
        <div>
          <div class="boxtitlelight"><i class="fa fa-user"></i> Downline</div>
          <span class="superfontlight">{{stats.downlinetransactions}}</span>
          <br><span
            class="smallfontlight">{{stats.downlinetransactions*100/(stats.mytransactions + stats.downlinetransactions) | number:'1.2-2'}}%
            of
            transactions</span>
        </div>
      </div>
    </div>
  </div>




  <br>
  <p-table #dt [value]="reportTransaction" [rows]="1000" [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[100, 200, 500, 1000]" styleClass="p-datatable-customers" [paginator]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" [filterDelay]="0" [resizableColumns]="true"
    [autoLayout]="true" selectionMode="single" [exportFilename]="filename" [columns]="cols" selectionMode=" multiple">
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        

          <button type="button" class="btn btn-primary p-mr-2 margin-right-5" (click)="dt.exportCSV(reportTransaction)" ><i
            class="pi pi-file-o"></i> Export to CSV file</button>
        <button type="button" class="btn btn-warning text-white p-mr-2" (click)="startOver()" >Build a new report</button>
      </div>
  </ng-template>
    <ng-template pTemplate="header">
       

        <br> <br>
      <tr class="FontRow f13">
        <th [pSortableColumn]="'ReferenceID'">
          Reference No
        </th>
        <th [pSortableColumn]="'PLfirstname'">Sold By</th>
        <th [pSortableColumn]="'Premium'">Premium</th>
        <th [pSortableColumn]="'Products'">Products</th>
        <th [pSortableColumn]="'Origin'">Sale Origin</th>
        <th [pSortableColumn]="'MemberFname'">Member Name</th>
        <th [pSortableColumn]="'SalePLcommission'">Agent $</th>
        <th [pSortableColumn]="'MyCommission'">Your $</th>
        <th [pSortableColumn]="'CommissionOrigin'">Billing Origin</th>
      </tr>
      <tr>
        <th>
          <input class="form-control" pInputText type="text"
            (input)="dt.filter($event.target.value, 'ReferenceID', 'contains')" />
        </th>
        <th>
          <input class="form-control" pInputText type="text"
            (input)="dt.filter($event.target.value, 'PLfirstname', 'contains')" />
        </th>
        <th>
          <input class="form-control" pInputText type="text"
            (input)="dt.filter($event.target.value, 'Premium', 'contains')" />
        </th>
        <th>
          <select class="form-control" (input)="dt.filter($event.target.value, 'Products', 'contains')">
            <option value="">List All</option>
            <option value=1>1 Product</option>
            <option value="2">2 Products</option>
            <option value="3">3 Products</option>
            <option value="4">4 Products</option>
            <option value="5">5 Products</option>
            <option value="6">6 Products</option>
            <option value="7">7 Products</option>
            <option value="8">8 Products</option>
            <option value="9">9 Products</option>
            <option value="10">10 Products</option>
          </select>
        </th>
        <th>
          <select class="form-control" (input)="dt.filter($event.target.value, 'Origin', 'contains')">
            <option value="">List All</option>
            <option value="Desk">Desk</option>
            <option value="Website">Website</option>
          </select>
        </th>
        <th>
          <input class="form-control" pInputText type="text"
            (input)="dt.filter($event.target.value, 'MemberFname', 'contains')" />
        </th>
        <th>
          <input class="form-control" pInputText type="text"
            (input)="dt.filter($event.target.value, 'SalePLcommission', 'contains')" />
        </th>

        <th>
          <input class="form-control" pInputText type="text"
            (input)="dt.filter($event.target.value, 'MyCommission', 'contains')" />
        </th>
        <th>
          <select class="form-control" (input)="dt.filter($event.target.value, 'CommissionOrigin', 'contains')">
            <option value="">List All</option>
            <option value="AFE">AFE</option>
            <option value="Recurring">Recurring</option>           
          </select>
        </th>
        
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-reportTransaction>
      <tr (click)="onRowSelect(reportTransaction.policyid)">
        <td class="SmallFont ">

          {{ reportTransaction.ReferenceID }}
          <br>
          <small class="text-success"><span>Sale Date: {{ reportTransaction.DateAPIRequested }}</span></small>

        </td>
        <td class="SmallFont">{{ reportTransaction.PLfirstname }} {{ reportTransaction.PLlastname }}
          <br>
          <small class="text-success"><span *ngIf="reportTransaction.SponsorFname">Sponsored by
              {{ reportTransaction.SponsorFname }} {{ reportTransaction.SponsorLname }}</span></small>
        </td>
        <td class="SmallFont">${{ reportTransaction.Premium | number:'1.2-2' }}</td>
        <td class="SmallFont text-info">
          <div *ngFor="let product of reportTransaction.products">
            <small>{{product.planname}} - {{product.SelectedTierCode}}</small>
          </div>
        </td>
        <td class="SmallFont">{{ reportTransaction.Origin }}</td>
        <td class="SmallFont">{{ reportTransaction.MemberFname }} {{ reportTransaction.MemberLname }}</td>
        <td class="SmallFont text-info">${{ reportTransaction.SalePLcommission | number:'1.2-2' }}</td>
        <td class="SmallFont text-danger">${{ reportTransaction.MyCommission | number:'1.2-2'}}</td>
        <td class="SmallFont">{{ reportTransaction.CommissionOrigin }}</td>


        
      </tr>
    </ng-template>


  </p-table>



  <!--LIGHTBOX LEAD-->
  <div id="overlay" *ngIf="openSide">
    <div id="overlay2" style="overflow: auto">
      <div class="text-right">
        <button class="btn btn-sm btn-danger" (click)="openSide= false">
          X
        </button>
      </div>

      <br />
      <h4>
        {{ memberData.EmpDemographics_FirstName }}
        {{ memberData.EmpDemographics_LastName }}
        -
        <span class="text-info">
          Customer Reference ID: {{ memberData.ReferenceID }}</span>
      </h4>
      <div class="alert alert-success" style="margin-top: 20px;" *ngIf="memberData.status == 1">This is an active
        membership.</div>
      <div class="alert alert-danger" style="margin-top: 20px;" *ngIf="memberData.status == 99">This membership is no
        longer active. You cannot perform any action for this member, except to visualize the account information.</div>
      <hr />
      <div class="row">
        <div class="col-12 col-sm-12 col-md-6 col-lg-4">
          <div class="bShadow-5">
            <div style="height: 270px">
              <h6>About This Member</h6>
              <hr />
              <table class="w100">
                <tr class="spaceUnder">
                  <td class="text-info">Birth Date:</td>
                  <td class="text-black">
                    {{ memberData.EmpDemographics_BirthDate }}
                  </td>
                </tr>
                <tr class="spaceUnder">
                  <td class="text-info">Gender:</td>
                  <td class="text-black">
                    {{ memberData.EmpDemographics_Gender }}
                  </td>
                </tr>
                <tr class="spaceUnder">
                  <td class="text-info">Address:</td>
                  <td class="text-black">
                    {{ memberData.EmpDemographics_Address1 }}
                  </td>
                </tr>
                <tr class="spaceUnder">
                  <td class="text-info">City:</td>
                  <td class="text-black">
                    {{ memberData.EmpDemographics_City }}
                  </td>
                </tr>
                <tr class="spaceUnder">
                  <td class="text-info">State:</td>
                  <td class="text-black">
                    {{ memberData.EmpDemographics_State_Name }}
                  </td>
                </tr>
                <tr class="spaceUnder">
                  <td class="text-info">ZIP Code:</td>
                  <td class="text-black">
                    {{ memberData.EmpDemographics_Zip }}
                  </td>
                </tr>
                <tr class="spaceUnder">
                  <td class="text-info">Phone Number:</td>
                  <td class="text-black">
                    {{ memberData.EmpDemographics_Phone }}
                  </td>
                </tr>
                <tr class="spaceUnder">
                  <td class="text-info">Email Address:</td>
                  <td class="text-black">
                    {{ memberData.EmpDemographics_Email }}
                  </td>
                </tr>
              </table>
              <br>
              <div class="row">
                <div class="col-6">
                  <form method="POST" action="{{ContinuationUrl}}" ngNoForm id="form1" target="_blank"
                    *ngIf="ImpersonationSuccess">
                    <input type="hidden" value="{{ContinuationUrl}}" id="ContinuationUrl" name="ContinuationUrl">
                    <input type="hidden" value="{{AccessToken}}" id="AccessToken" name="AccessToken">
                    <input type="hidden" value="{{RegistrationDetails_UserName}}" id="UserName" name="UserName">
                    <button type="submit" form="form1" class="btn btn-primary btn-sm">Impersonate Member</button>
                  </form>
                </div>
                <div class="col-6 text-right">
                  <button class="btn btn-sm btn-primary" (click)="ResendWelcomeEmail()">Resend Welcome Email</button>
                </div>
              </div>



              <!--
              <button *ngIf="memberData.status == 1" class="btn btn-danger btn-sm" style="margin-top: 15px; margin-right: 10px;" (click)="cancelFullMembership()">Cancel Membership</button>
              -->
              <!--
              <button *ngIf="memberData.status == 1" class="btn btn-primary btn-sm" style="margin-top: 15px;" >Edit Member</button>
              -->
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-6 col-lg-4">
          <div class="bShadow-5">
            <div style="height: 270px">
              <h6>References</h6>
              <hr />
              <table class="w100">
                <tr class="spaceUnder">
                  <td class="text-info">Reference ID:</td>
                  <td class="text-black">
                    {{ memberData.ReferenceID }}
                  </td>
                </tr>
                <tr class="spaceUnder">
                  <td class="text-info">Transaction Origin:</td>
                  <td class="text-black">
                    {{ memberData.Origin }}
                  </td>
                </tr>
                <tr class="spaceUnder">
                  <td class="text-info">Transaction Date:</td>
                  <td class="text-black">
                    {{ memberData.Date }}
                  </td>
                </tr>
                <tr class="spaceUnder">
                  <td class="text-info">AFE ID:</td>
                  <td class="text-black">
                    {{ memberData.policyid }}
                  </td>
                </tr>
                <tr class="spaceUnder">
                  <td class="text-info">HCI Project ID:</td>
                  <td class="text-black">
                    {{ memberData.projectid }}
                  </td>
                </tr>
                <tr class="spaceUnder">
                  <td class="text-info">HCI Member ID:</td>
                  <td class="text-black">
                    {{ memberData.HCImemberid }}
                  </td>
                </tr>
              </table>

            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-4">
          <div class="row">
            <div class="col-12">
              <div class="alert alert-info">
                <div style="min-height: 50px">
                  <span style="font-size: 28px">{{
                    APIresults.moneyrelated.products
                    }}</span>
                  <br />
                  PRODUCTS
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="alert alert-info">
                <div style="min-height: 50px">
                  <span style="font-size: 28px">${{ APIresults.moneyrelated.amount | number:'1.2-2'}}</span>
                  <br />
                  PREMIUM
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="alert alert-info">
                <div style="min-height: 50px">
                  <span style="font-size: 28px">${{
                    APIresults.moneyrelated.commission| number:'1.2-2' }}</span>
                  <br />
                  GENERATED COMMISSION
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="bShadow-5">
        <div style="min-height: 250px">
          <h6>Products Acquired</h6>
          <hr />
          <div class="row">
            <div class="col-12 col-sm-12 col-md-6">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-12 col-lg-6 " *ngFor="let product of APIresults.products">
                  <div class="text-left text-white productbox mousepointer"
                    style="background-color: #012a4f; padding: 15px; height: 170px;"
                    (click)="PlanDetails(product.ProductId,product.planname, product.fee)">
                    <br>
                    <strong>{{ product.planname }}</strong>
                    <hr style="border-top: 1px solid #a0cade;" />
                    <div class="row">
                      <div class="col-12">
                        <small> Monthly Fee </small><br />
                        <span style="font-size: 26px">${{ product.fee | number:'1.2-2' }}</span>
                        <div class="link text-right" style="color: #eaeaea;">Click for Details >></div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-6" *ngIf="selectedPlanName">
              <div class="text-left text-white productbox"
                style="background-color: #3e669c; padding: 15px; height: 170px;">
                <br />
                <div class="row">
                  <div class="col-8">
                    <h4 class="text-white">{{ selectedPlanName }}</h4>
                    <strong style="color: white">SELECTED TIER: {{ selectedTierCode }}</strong>
                  </div>
                  <div class="col-4 text-right">
                    <!--
                    <button class="btn btn-sm btn-danger" *ngIf="selectedPlanId !='11423' && memberData.status=='1'"
                      (click)="cancelPlan(selectedPlanId)">Cancel This Plan</button>
                      -->
                  </div>
                </div>


                <hr />
                <div style="font-size: 18px;">Product billed on the 20th of each month. Premium:
                  ${{selectedFee | number:'1.2-2'}}</div>
              </div>
              <br>
              <!--dependents-->
              <table class="table table-bordered w100" *ngIf="dependents">
                <tr style="background-color: #012a4f">
                  <td colspan="2">
                    <strong class="text-white">Dependent Full Name</strong>
                  </td>
                  <td><strong class="text-white">Relationship</strong></td>
                  <td colspan="2"><strong class="text-white">Date of Birth</strong></td>

                </tr>
                <tr *ngFor="let dependent of
                    dependents.POLICIES_DependentDemographics" style="background-color: white;">
                  <td style="width: 30px">
                    <img src="assets/images/icouser.png" style="margin-right: 5px" height="15px" />
                  </td>
                  <td>{{ dependent.FirstName }} {{ dependent.LastName }}</td>
                  <td>{{ dependent.Relationship }}</td>
                  <td>{{ dependent.BirthDate }}</td>
                  <td class="text-center">
                    <button class="btn btn-sm btn-primary"
                      (click)="DependentDetails(selectedProductId, dependent.PersonCode)">
                      <i class="fa fa-search"></i>
                    </button>
                  </td>
                </tr>
              </table>
              <!--beneficiaries-->
              <table class="table table-bordered w100" *ngIf="beneficiaries">
                <tr style="background-color: #012a4f">
                  <td colspan="2">
                    <strong class="text-white">Beneficiary Full Name</strong>
                  </td>
                  <td><strong class="text-white">Type</strong></td>
                  <td colspan="2"><strong class="text-white">% Benefit</strong></td>

                </tr>
                <tr *ngFor="let beneficiary of beneficiaries"
                  [ngStyle]="{backgroundColor: beneficiary.BType == 'P' ? '#ffffff' : '#f5f5f5'}">
                  <td style="width: 30px">
                    <img src="assets/images/icouser.png" style="margin-right: 5px" height="15px" />
                  </td>
                  <td>{{ beneficiary.FirstName }} {{ beneficiary.LastName }}</td>
                  <td><span
                      [ngClass]="(beneficiary.BType == 'P'?'text-success':'text-danger')">{{ beneficiary.type }}</span>
                  </td>
                  <td>{{ beneficiary.Percentage }}%</td>
                  <td class="text-center">
                    <button class="btn btn-sm btn-primary" (click)="BeneficiaryDetails(beneficiary.BeneficiaryId)">
                      <i class="fa fa-search"></i>
                    </button>
                  </td>
                </tr>
              </table>
            </div>
            <div class="col-12 col-sm-12 col-md-6 text-center" *ngIf="!selectedPlanName">
              <div style="font-size: 20px; color:#c5c5c5; padding-top: 25px;">
                <i class="fa fa-arrow-left" style="font-size: 50px;"></i>
                <br>
                Click on a Product for Details
              </div>
            </div>



          </div>
          <br /><br />
        </div>
      </div>

      <!--AGENT INFORMATION-->


      <div *ngIf="APIresults.agency == '1'">
        <br /><br />
        <h4>Agent Information</h4>
        <hr />
        <div class="row">
          <div class="col-12 col-sm-12 col-md-6 col-lg-4">
            <div class="bShadow-5 backwhite">
              <div style="min-height: 240px">
                <h6>Agent</h6>
                <hr />
                <table class="w100">
                  <tr class="spaceUnder">
                    <td class="text-info">Agent ID:</td>
                    <td class="text-black">
                      {{ agentinfo.plid }}
                    </td>
                  </tr>
                  <tr class="spaceUnder">
                    <td class="text-info">Full Name:</td>
                    <td class="text-black">
                      {{ agentinfo.firstname }}
                      {{ agentinfo.middlename }}
                      {{ agentinfo.lastname }}
                    </td>
                  </tr>
                  <tr class="spaceUnder">
                    <td class="text-info">Address:</td>
                    <td class="text-black">
                      {{ agentinfo.address }}
                      {{ agentinfo.city }},
                      {{ agentinfo.state }}
                      {{ agentinfo.zip }}
                    </td>
                  </tr>
                  <tr class="spaceUnder">
                    <td class="text-info">Cell Phone:</td>
                    <td class="text-black">
                      {{ agentinfo.cellphone }}
                    </td>
                  </tr>
                  <tr class="spaceUnder">
                    <td class="text-info">Business Phone:</td>
                    <td class="text-black">
                      {{ agentinfo.businessphone }}
                    </td>
                  </tr>
                  <tr class="spaceUnder">
                    <td class="text-info">Fax Number:</td>
                    <td class="text-black">
                      {{ agentinfo.faxnumber }}
                    </td>
                  </tr>
                  <tr class="spaceUnder">
                    <td class="text-info">Email Address:</td>
                    <td class="text-black">
                      {{ agentinfo.email }}
                    </td>
                  </tr>
                  <tr class="spaceUnder" *ngIf="AllowViewMembers">
                    <td class="text-black" colspan="2">
                      <br />
                      <button class="btn btn-warning text-white" (click)="RevealPassword()" style="margin-right: 10px">
                        Reveal Password
                      </button>
                      <button class="btn btn-warning text-white" (click)="ResendEmail()"
                        *ngIf="status == 1 || status == 50">
                        Resend Welcome Email
                      </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-12 col-md-6 col-lg-4">
            <div class="bShadow-5 backwhite">
              <div style="min-height: 240px">
                <h6>Miscellaneous</h6>
                <hr />
                <table class="w100">
                  <tr class="spaceUnder">
                    <td class="text-info" style="width: 150px">Sponsor:</td>
                    <td class="text-black">
                      ({{ agentinfo.SponsorPL }}) -
                      {{ agentinfo.SponsorFname }}
                      {{ agentinfo.SponsorLname }}
                    </td>
                  </tr>
                  <tr class="spaceUnder">
                    <td class="text-info">Type of Business:</td>
                    <td class="text-black">
                      {{ agentinfo.licTypeOfBusiness }}
                    </td>
                  </tr>
                  <tr class="spaceUnder">
                    <td class="text-info">License:</td>
                    <td class="text-black">
                      {{ agentinfo.licTypeInsuranceLicense }}
                    </td>
                  </tr>
                  <tr class="spaceUnder">
                    <td class="text-info">Insurance #:</td>
                    <td class="text-black">
                      {{ agentinfo.licResidentInsuranceLicense }}
                    </td>
                  </tr>
                  <tr class="spaceUnder">
                    <td class="text-info">Resident State:</td>
                    <td class="text-black">
                      {{ agentinfo.licResidentState }}
                    </td>
                  </tr>
                  <tr class="spaceUnder">
                    <td class="text-info">NPN Number:</td>
                    <td class="text-black">
                      {{ agentinfo.licNPMnumber }}
                    </td>
                  </tr>
                  <tr class="spaceUnder">
                    <td class="text-info">E&O Coverage?</td>
                    <td class="text-black">
                      {{ agentinfo.eoCoverage }}
                    </td>
                  </tr>
                  <tr class="spaceUnder">
                    <td class="text-info">$ and Expiration:</td>
                    <td class="text-black">
                      ${{ FormatMoney(agentinfo.eoAmount) }} -
                      {{ agentinfo.eoExp }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-12 col-md-6 col-lg-4">
            <div class="bShadow-5 backwhite">
              <div style="min-height: 240px">
                <h6>Products and Grid</h6>
                <hr />
                <table class="w100">
                  <tr class="spaceUnder">
                    <td></td>
                    <td class="text-title">Year 1</td>
                    <td class="text-title">Year 2+</td>
                  </tr>
                  <tr class="spaceUnder" *ngFor="let product of agentproducts">
                    <td class="text-info">{{ product.RealName }}</td>
                    <td class="text-black">{{ product.Y1 }}%</td>
                    <td class="text-black">{{ product.Y2 }}%</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <br /><br />

        <h4>Compensation</h4>
        <hr />
        <table class="table table-bordered w100 bShadow-5">
          <tr style="background-color: #012a4f">
            <td>
              <strong class="text-white">ID</strong>
            </td>
            <td><strong class="text-white">Agent Name</strong></td>
            <td><strong class="text-white">Products Compensation</strong></td>
            <td><strong class="text-white">Total by Agent</strong></td>
          </tr>
          <tr style="background-color: white;" *ngFor="let record of upline">
            <td>
              <div class="row">
                <div class="col-10">
                  <h5>{{record.plid}}</h5>
                </div>
              </div>

            </td>
            <td>
              <h5>{{record.AgentName}}</h5>
              <br>


            </td>
            <td>
              <table class="w100 table table-borderless">
                <tr *ngFor="let compensation of record.compensation">
                  <td>{{compensation.product}}</td>
                  <td style="width:20%" class="text-right">{{compensation.grid}}%</td>
                  <td style="width:20%" class="text-right text-danger">
                    <strong>${{compensation.commission | number:'1.2-2'}}</strong></td>
                </tr>
              </table>
            </td>
            <td class="text-center">
              <br>
              Total for {{record.AgentName}}
              <br>
              <span style="font-size: 30px;">${{record.totalCommission | number:'1.2-2'}}</span></td>
          </tr>
        </table>
        <br>
        <h4>Notes</h4>
        <hr />

        <app-notes [NoteType]="'member'" [ElementID]="memberData.policyid"></app-notes>


      </div>
    </div>
  </div>


  <p-dialog [(visible)]="showDependedBox" modal="true" [draggable]="false" [closeOnEscape]="true" [responsive]="true"
    [style]="{ width: '500px' }" [baseZIndex]="1">
    <p-header style="width: 100%">
      <h3>{{personData.FirstName}} {{personData.LastName}}</h3>
    </p-header>
    <hr>
    <table class="w100">
      <tr>
        <td class="text-info" width="30%">Birth Date</td>
        <td>{{personData.BirthDate}}</td>
      </tr>
      <tr>
        <td class="text-info" width="30%">Gender</td>
        <td>{{personData.Gender}}</td>
      </tr>
      <tr>
        <td class="text-info" width="30%">Relationship</td>
        <td>{{personData.Relationship}}</td>
      </tr>
      <tr>
        <td class="text-info" width="30%">Address</td>
        <td>{{personData.Address1}}</td>
      </tr>
      <tr>
        <td class="text-info" width="30%">City</td>
        <td>{{personData.City}}</td>
      </tr>
      <tr>
        <td class="text-info" width="30%">State</td>
        <td>{{personData.State_Name}}</td>
      </tr>
      <tr>
        <td class="text-info" width="30%">ZIP Code</td>
        <td>{{personData.Zip}}</td>
      </tr>
      <tr>
        <td class="text-info" width="30%">Phone</td>
        <td>{{personData.Phone}}</td>
      </tr>
      <tr>
        <td class="text-info" width="30%">Disabled?</td>
        <td>{{personData.IsDisabled}}</td>
      </tr>
      <tr>
        <td class="text-info" width="30%">PersonCode</td>
        <td>{{personData.PersonCode}}</td>
      </tr>

    </table>
  </p-dialog>

  <p-dialog [(visible)]="showBeneficiaryBox" modal="true" [draggable]="false" [closeOnEscape]="true" [responsive]="true"
    [style]="{ width: '500px' }" [baseZIndex]="1">
    <p-header style="width: 100%">
      <h3>{{personData.FirstName}} {{personData.LastName}}</h3>
    </p-header>
    <hr>
    <table class="w100">
      <tr>
        <td class="text-info" width="30%">Beneficiary Type</td>
        <td>{{personData.type}}</td>
      </tr>
      <tr>
        <td class="text-info" width="30%">Percentage</td>
        <td>{{personData.Percentage}}%</td>
      </tr>
      <tr>
        <td class="text-info" width="30%">Relationship</td>
        <td>{{personData.Relationship}}</td>
      </tr>
      <tr>
        <td class="text-info" width="30%">Address</td>
        <td>{{personData.Address1}}</td>
      </tr>
      <tr>
        <td class="text-info" width="30%">City</td>
        <td>{{personData.City}}</td>
      </tr>
      <tr>
        <td class="text-info" width="30%">State</td>
        <td>{{personData.State_Name}}</td>
      </tr>
      <tr>
        <td class="text-info" width="30%">ZIP Code</td>
        <td>{{personData.Zip}}</td>
      </tr>


    </table>
  </p-dialog>

</div>











<div id="spinner" style="height: 100vh" *ngIf="preloader">
  <div class="centered">
    <img src="assets/images/processing.gif" style="width: 50px" />
    <br /><br />
    <span class="text-white">Processing. Please wait...</span>
  </div>
</div>