<div class=" text-center" style="padding-left: 120px">


    <img src="assets/afelogoreal.png" style="width:300px; margin-top: 30px;">


    <br>
    <h5 style="color:#000">Welcome to your AFEUSA Desk</h5>
    <span style="color:#000">
        Please review and sign the following addendum to your AFEUSA contract
    </span>
    <br>
    <hr style="border-top: 1px solid #000; margin-top:20px;">
    <br>
    <h5 style="color:#000">Schedule of Commissions Attached to Dylan Consulting LLC Agreement</h5>
    <br>
    <h4 style="color:#000">PRODUCT AND COMPENSATION LEVEL</h4>

    <div id="outer-div">
        <div id="inner-div" style="max-width: 800px; color: #000; text-align: left !important;">
            <div class="bShadow-5 text-dark" [innerHTML]="Alerts[0].alertcontent"></div>

            <br><br>
            This Schedule of Commissions is a part of your Agreement with Dylan Consulting LLC. It is in effect and attached to the original Agreement. Commissions are due and payable prospectively as Agent's credited members pay for their plan and it remains active, per the terms of the Agreement.
            <br><br>
            Commissions are calculated as a percentage of the monthly payment made by members when the member enrolls, not including any billing fees. The commission percentage rate used is the rate which is in effect for the Agent on the date the member enrolls and purchases the Product.
            Commissions for the Products are vested and may be credited to Agent after termination, if the member's plan continues to be paid for and remains active, per terms of the Agreement.
            <br><br>
            If Agent has contracted Sub-Agents assigned to them; the override commission due Agent will be the difference between their applicable commission rate, when the member originally purchased the Product, and the commission rate provided to the assigned Sub-Agent on the date the member purchased the Product.
            <br><br>
            <br>
            <span>Date Signed</span>
            <input type="text" class="form-control" style="max-width: 400px;" [(ngModel)]="agrDate" disabled>
            <br>
            <span>Display Name</span>
            <input type="text" class="form-control" style="max-width: 400px;" [(ngModel)]="agrName" disabled>
            <br>
            <span>Electronic Signature (enter your name exactly as it appears above)</span>
            <input type="text" class="form-control" style="max-width: 400px;" maxlength="100" [(ngModel)]="agrSignature">
            <br>
            <button class="btn btn-danger btn-sm" (click)="signAgreement()">Agree and Sign</button>
            <br><br><br><br><br><br><br>
        </div>
    </div>
</div>

