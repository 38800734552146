import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manageusers',
  templateUrl: './manageusers.component.html',
  styleUrls: ['./manageusers.component.css']
})
export class ManageusersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
