<div class="row">
  <div class="col-4">
    <h4>Contracted Agents</h4>
  </div>
  <div class="col-8 text-right"></div>
</div>
<hr />

<button *ngIf="AllowFullDownline" (click)="Full()" class="btn btn-outline-dark btn-sm"
  [ngClass]="{ 'btn-dark text-white': DownlineType == 'Full Downline' }" style="margin-right: 10px; margin-top: 10px">
  Full Downline
</button>

<button (click)="Me()" class="btn btn-outline-dark btn-sm"
  [ngClass]="{ 'btn-dark text-white': DownlineType == 'Direct Downline' }" style="margin-right: 10px; margin-top: 10px">
  Direct Downline
</button>



<button *ngIf="AllowFullDownline" (click)="SelectAgent(CurrentAgent)" class="btn btn-outline-dark btn-sm"
  style="margin-right: 10px; margin-top: 10px">
  View My Account
</button>
<br /><br />
<div class="alert alert-info">
  <span style="padding-top: 14px">{{ TotalAgents }} Agents Found for {{ DownlineType }}</span>
</div>
<ol class="breadcrumb" style="width: 100%; background-color: white;">
  <li *ngFor="let agent of this.APIresult.upline" class="breadcrumb-item">
    <img src="assets/images/icouser.png" style="margin-right: 5px" pTooltip="Summary" height="15px" />
    <a class="link text-primary mousepointer" style="font-size: 11px;"
      (click)="switchAgent(agent.id)">{{ agent.fullname }}</a>
  </li>
</ol>
<div class="minheight">
  <p-table #dt [value]="AgentsList" [rows]="100" [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[100, 250, 500, 1000]" styleClass="p-datatable-customers" [paginator]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} agents" [filterDelay]="0"
    [globalFilterFields]="[
      'fullname',
      'email',
      'phone',
      'downline',
      'companyname',
      'signupdate',
      'city',
      'state',
      'products',
      'status',
      'policies',
      'statustext'
    ]" [resizableColumns]="true" [autoLayout]="true">
    <ng-template pTemplate="header">
      <tr class="FontRow" style="font-size: 11px !important">
        <th></th>
        <th class="text-center" [pSortableColumn]="'companyname'">Company Name</th>
        <th class="text-center" [pSortableColumn]="'fullname'">Agent Name</th>

        <th class="text-center" [pSortableColumn]="'downline'">Direct Agents</th>
        <th class="text-center" [pSortableColumn]="'signupdate'">Created</th>
        <th class="text-center" [pSortableColumn]="'products'">Products</th>
        <th class="text-center" [pSortableColumn]="'city'">City</th>
        <th class="text-center" [pSortableColumn]="'state'">State</th>
        <th class="text-center" [pSortableColumn]="'policies'">Sales</th>
        <th class="text-center" [pSortableColumn]="'statustext'">Status</th>
        <th class="text-center" [pSortableColumn]="''"></th>
      </tr>
      <tr>
        <th></th>
        <th>
          <input class="form-control" pInputText type="text"
            (input)="dt.filter($event.target.value, 'companyname', 'contains')" />
        </th>
        <th>
          <input class="form-control" pInputText type="text"
            (input)="dt.filter($event.target.value, 'fullname', 'contains')" />
        </th>

        <th>
          <input class="form-control" pInputText type="text"
            (input)="dt.filter($event.target.value, 'downline', 'equals')" />
        </th>

        <th>
          <input class="form-control" pInputText type="text"
            (input)="dt.filter($event.target.value, 'signupdate', 'contains')" />
        </th>

        <th>
          <input class="form-control" pInputText type="text"
            (input)="dt.filter($event.target.value, 'products', 'equal')" />
        </th>

        <th>
          <input class="form-control" pInputText type="text"
            (input)="dt.filter($event.target.value, 'city', 'contains')" />
        </th>

        <th>
          <input class="form-control" pInputText type="text"
            (input)="dt.filter($event.target.value, 'state', 'contains')" />
        </th>

        <th>
          <input class="form-control" pInputText type="text"
            (input)="dt.filter($event.target.value, 'sales', 'equals')" />
        </th>

        <th>
          <select class="form-control" (input)="dt.filter($event.target.value, 'statustext', 'equals')">
            <option value="">List All</option>
            <option value="Active">Active</option>
            <option value="Suspended">Suspended</option>
            <option value="Pending">Pending</option>
            <option value="Agent Pending">Agent Pending</option>
          </select>
        </th>

        <th></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-AgentsList let-rowIndex="rowIndex">
      <tr class="FontRow f13" style="cursor: pointer; font-size: 11px" [ngStyle]="{
          backgroundColor:
            AgentsList.status == 0
              ? '#FFF2B5'
              : AgentsList.status == 50
              ? '#EAEAEA'
              : AgentsList.status == 99
              ? '#FFE0E0'
              : 'transparent'
        }">
        <td class="SmallFont">{{ rowIndex + 1 }}</td>
        <td>{{ AgentsList.companyname }}</td>
        <td class="SmallFont">
          <a (click)="switchAgent(AgentsList.id)" class="mousepointer">
            <img src="assets/images/icotreeview.png" style="margin-right: 5px" />
            <strong>{{ AgentsList.fullname.toUpperCase() }}</strong>
          </a>
        </td>

        <td class="SmallFont text-center">
          <div *ngIf="AgentsList.downline > 0">
            {{ AgentsList.downline }}
          </div>
        </td>

        <td>{{ AgentsList.signupdate }}</td>
        <td>
          <a class="mousepointer" (click)="ShowProducts(AgentsList.id)" *ngIf="AgentsList.products > 0">
            <img src="assets/images/icoproducts.png" style="margin-right: 5px" />
            {{ AgentsList.products }}
          </a>
        </td>
        <td>{{ AgentsList.city }}</td>
        <td class="text-center">{{ AgentsList.state }}</td>
        <td class="text-center">
          <a *ngIf="AgentsList.policies > 0" (click)="ListMembers(AgentsList.id, 'direct')">
            {{ AgentsList.policies }}
          </a>
        </td>
        <td>{{ AgentsList.statustext }}</td>
        <td class="SmallFont text-right">
          <button class="btn btn-sm btn-primary" (click)="SelectAgent(AgentsList.id)"
            >OPEN AGENT</button>

        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<!--LIGHTBOX LEAD-->
<div id="overlay" *ngIf="ShowOverlay">
  <div id="overlay2">
    <div>
      <div class="row">
        <div class="col-md-10 text-left"></div>
        <div class="col-md-2 text-right">
          <button class="btn btn-danger btn-sm" (click)="ShowOverlay = false">
            X
          </button>
        </div>
      </div>
    </div>
    <div class="stuck">
      <div class="w100">
        <div class="bShadow-5" style="background-color: white">
          <br />
          <div class="row">
            <div class="col-md-12">
              <h5>Clients</h5>
            </div>
          </div>

          <hr />
          <br />

          <!--Customers-->

          <p-table #dt [value]="ClientsList" [rows]="100" [showCurrentPageReport]="true"
            [rowsPerPageOptions]="[100, 250, 500, 1000]" styleClass="p-datatable-customers" [paginator]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} agents" [filterDelay]="0"
            [globalFilterFields]="[
              'referenceid',
              'fullname',
              'email',
              'phone',
              'signupdate',
              'productsdescription'
            ]" [resizableColumns]="true" [autoLayout]="true">
            <ng-template pTemplate="header">
              <tr class="FontRow" style="font-size: 11px !important">
                <th></th>
                <th [pSortableColumn]="'referenceid'">Reference ID</th>
                <th [pSortableColumn]="'fullname'">Client Name</th>
                <th [pSortableColumn]="'email'">Email</th>
                <th [pSortableColumn]="'phone'">Phone</th>
                <th [pSortableColumn]="'signupdate'">Member Since</th>
                <th [pSortableColumn]="'productsdescription'">Products</th>
                <th [pSortableColumn]="''"></th>
              </tr>
              <tr>
                <th></th>
                <th>
                  <input class="form-control" pInputText type="text" (input)="
                      dt.filter($event.target.value, 'referenceid', 'contains')
                    " />
                </th>
                <th>
                  <input class="form-control" pInputText type="text" (input)="
                      dt.filter($event.target.value, 'fullname', 'contains')
                    " />
                </th>
                <th>
                  <input class="form-control" pInputText type="text" (input)="
                      dt.filter($event.target.value, 'email', 'contains')
                    " />
                </th>

                <th>
                  <input class="form-control" pInputText type="text" (input)="
                      dt.filter($event.target.value, 'phone', 'contains')
                    " />
                </th>

                <th>
                  <input class="form-control" pInputText type="text" (input)="
                      dt.filter($event.target.value, 'signupdate', 'contains')
                    " placeholder="Number" />
                </th>

                <th>
                  <input class="form-control" pInputText type="text" (input)="
                      dt.filter(
                        $event.target.value,
                        'productsdescription',
                        'contains'
                      )
                    " />
                </th>

                <th></th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-ClientsList let-rowIndex="rowIndex">
              <tr class="FontRow f13" style="cursor: pointer; font-size: 11px" [ngStyle]="{
                  backgroundColor:
                    AgentsList.status == 0
                      ? '#FFF2B5'
                      : AgentsList.status == 50
                      ? '#EAEAEA'
                      : AgentsList.status == 99
                      ? '#FFE0E0'
                      : 'transparent'
                }">
                <td class="SmallFont">{{ rowIndex + 1 }}</td>
                <td>{{ ClientsList.referenceid }}</td>
                <td class="SmallFont">
                  <strong>{{ ClientsList.fullname.toUpperCase() }}</strong>
                </td>
                <td class="SmallFont">
                  <a href="mailto:{{ ClientsList.email }}"
                    pTooltip="Send {{ ClientsList.email }} an email">{{ ClientsList.email }}</a>
                </td>
                <td class="SmallFont">
                  <a href="tel:{{ ClientsList.phone }}" pTooltip="Call {{ ClientsList.phone }}">
                    {{ ClientsList.phone }}
                  </a>
                </td>

                <td>{{ ClientsList.signupdate }}</td>
                <td>
                  ({{ ClientsList.numproducts }})
                  {{ ClientsList.productsdescription }}
                </td>

                <td></td>
              </tr>
            </ng-template>
          </p-table>
        </div>

        <br />
      </div>
    </div>
  </div>
</div>

<p-dialog [(visible)]="ShowProductsBox" modal="true" [draggable]="false" [closeOnEscape]="true" [responsive]="true"
  [style]="{ width: '800px' }" [baseZIndex]="1">
  <p-header style="width: 100%">
    <h3>Products Available</h3>
  </p-header>

  <div class="table-responsive">
    <table class="table">
      <tr>
        <td>
          <strong>PRODUCT NAME</strong>
        </td>
        <td>
          <strong>YEAR 1</strong>
        </td>
        <td>
          <strong>YEAR 2+</strong>
        </td>
        <td>
          <strong>SELLING SINCE</strong>
        </td>
      </tr>
      <tr *ngFor="let product of ProductsList">
        <td>
          {{ product.productname }}
        </td>
        <td>{{ product.year1 }}%</td>
        <td>{{ product.year2 }}%</td>
        <td>
          {{ product.sellingsince }}
        </td>
      </tr>
    </table>
  </div>
</p-dialog>




<div id="overlay" *ngIf="openSide">
  <div id="overlay2" >
    <div class="text-right alert alert-info">
      <button class="btn btn-sm btn-danger" (click)="openSide= false">
        <i class="fa fa-window-close" aria-hidden="true"></i> Close
      </button>
    </div>
    <br>
    <app-agentedition [ID]='selectedAgentID'></app-agentedition>
  

   


  </div>
</div>


<div id="spinner" style="height: 100vh" *ngIf="preloader">
  <div class="centered">
    <img src="assets/images/processing.gif" style="width: 50px" />
    <br /><br />
    <span class="text-white">Processing. Please wait...</span>
  </div>
</div>