<h4>
    Pending Applications (${{TotalPending.toFixed(2) | number}} total)</h4>
<hr> Below is the list of all pending applications.
<br><br>
<span>

    <input class="form-control" pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
        placeholder="Search criteria for every field..." />
</span>
<br>

<div class="minheight">
    <p-table #dt [value]="LeadsList" [rows]="25" [showCurrentPageReport]="true" [rowsPerPageOptions]="[25,50,200,1000]" styleClass="p-datatable-customers" [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} applications"
        [filterDelay]="0" [globalFilterFields]="['firstname','lastname','email','datetime', 'origin', 'products', 'amount']" [resizableColumns]="true" [autoLayout]="true" >

        <ng-template pTemplate="header">
            <tr class="FontRow f13">
                <th [pSortableColumn]="'firstname'">First Name</th>
                <th [pSortableColumn]="'lastname'">Last Name</th>
                <th [pSortableColumn]="'email'">Email</th>
                <th [pSortableColumn]="'datetime'">Last Updated</th>
                <th [pSortableColumn]="'origin'">Origin</th>
                <th [pSortableColumn]="'products'">Products</th>
                <th [pSortableColumn]="'amount'">Amount</th>
                <th ></th>
            </tr>
            <tr>
                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'firstname', 'contains')" >
                </th>
                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'lastname', 'contains')" >
                </th>

                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'email', 'contains')" >
                </th>

                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'datetime', 'contains')" >

                </th>

                <th>
                    <select class="form-control " (input)="dt.filter($event.target.value, 'origin', 'contains')">
                        <option value="">All Pending</option>
                        <option value="Desk">Desk</option>
                        <option value="Website">Website</option>
                       
                    </select>
                </th>

                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'products', 'contains')" >

                </th>
                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'amount', 'contains')" >

                </th>
                <th>
                   
                </th>


            </tr>
        </ng-template>




        <ng-template pTemplate="body" let-LeadsList>
            <tr class="FontRow f13" >
                <td class="SmallFont"><a (click)="SelectApplication(LeadsList.pendingid)" class="mousepointer">{{LeadsList.firstname}}</a></td>
                <td class="SmallFont"><a (click)="SelectApplication(LeadsList.pendingid)" class="mousepointer">{{LeadsList.lastname}}</a></td>
                <td class="SmallFont"><a href="mailto:{{LeadsList.email}}" target = "_blank">{{LeadsList.email}}</a></td>
                <td class="SmallFont">{{LeadsList.datetime}}</td>
                <td class="SmallFont">{{LeadsList.origin}}</td>
                <td class="SmallFont">{{LeadsList.products}}</td>
                <td class="SmallFont">${{LeadsList.amount.toFixed(2) | number}}</td>
                <td>
                   <button class="btn btn-sm btn-primary" (click)="SelectApplication(LeadsList.pendingid)"> OPEN</button>
                      
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>



