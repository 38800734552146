

<div class="level1page ">

    <div class="stick-ontop">
        <h4 style="color:#f1f1f1; padding-top: 20px; ">
            Corporate Backoffice
        </h4>
        <hr>

        <div>
            <div class="row">
                <div class="col-6 col-md-4 col-xl-2">
                    <div class="productbox2 mousepointer" [routerLink]="['corpagents']" (click)="isclicked=true">
                        <div class="text-left">
                            <span class="superfont">Agent Management</span>
                            <br><span class="smallfont">View AFEUSA agents by status</span>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-xl-2">
                    <div class="productbox2 mousepointer" [routerLink]="['corpmembers']" (click)="isclicked=true">
                        <div class="text-left">
                            <span class="superfont">Members Management</span>
                            <br><span class="smallfont">List All AFEUSA members</span>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-xl-2">
                    <div class="productbox2 mousepointer" [routerLink]="['siteadmin']" (click)="isclicked=true">
                        <div class="text-left">
                            <span class="superfont">Website Management</span>
                            <br><span class="smallfont">Manage AFEUSA hardcoded features</span>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-xl-2">
                    <div class="productbox2 mousepointer" [routerLink]="['superuser']" (click)="isclicked=true">
                        <div class="text-left">
                            <span class="superfont">Superuser Features</span>
                            <br><span class="smallfont">Manage database related features</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <br>



    </div>

   
    <div >
        <div class="subbox text-left" >
            <router-outlet></router-outlet>
        </div>
    </div>
    <br>
</div>