<h4>
    List Websites</h4>
<p>The websites below have been created with your agent code, they are imbedded to track sales and commissions.</p>
<hr>
<div class="minheight">
    <p-table #dt [value]="ProductsList" [rows]="25" [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[25,50,200,1000]" styleClass="p-datatable-customers" [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} websites" [filterDelay]="0"
        [globalFilterFields]="['websitename','url','email','phone','status']" [resizableColumns]="true"
        [autoLayout]="true">

        <ng-template pTemplate="header">
            <tr class="FontRow f13">
                <th [pSortableColumn]="'websitename'" class="smallfont">Website Display Name</th>
                <th [pSortableColumn]="'url'">WEBSITE URL</th>
                <th [pSortableColumn]="'email'">Display Email</th>
                <th [pSortableColumn]="'phone'">Display Phone</th>
                <th [pSortableColumn]="'status'">Status</th>
                <th></th>
            </tr>
            <tr>
                <th>
                    <input class="form-control" pInputText type="text"
                        (input)="dt.filter($event.target.value, 'websitename', 'contains')"
                        placeholder="Enter Website Name">
                </th>
                <th>
                    <input class="form-control" pInputText type="text"
                        (input)="dt.filter($event.target.value, 'url', 'contains')" placeholder="Enter URL">
                </th>

                <th>
                    <input class="form-control" pInputText type="text"
                        (input)="dt.filter($event.target.value, 'email', 'contains')" placeholder="Enter Email">
                </th>
                <th>
                    <input class="form-control" pInputText type="text"
                        (input)="dt.filter($event.target.value, 'phone', 'contains')" placeholder="Enter Phone">
                </th>
                <th>
                    <select class="form-control" (input)="dt.filter($event.target.value, 'status', 'contains')">
                        <option value="">List All</option>
                        <option value="Enabled">Enabled</option>
                        <option value="Disabled">Disabled</option>
                    </select>
                </th>
                <th></th>

            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-ProductsList>
            <tr class="FontRow f13">
                <td class="SmallFont">{{ProductsList.websitename}}</td>
                <td class="SmallFont">
                    <a style="margin-right:10px;" pTooltip="Copy to Clipboard" class="btn btn-primary btn-sm text-white"
                        target="_blank"
                        (click)="copyMessage('https://www.afeusaenroll.com/vip/' + ProductsList.url.toUpperCase())"><i
                            class="fa fa-copy"></i></a>
                    <!--
                    <a style="margin-left:8px; margin-right:10px;" pTooltip="Open Website" class="btn btn-warning btn-sm text-white"
                        href="https://www.afeusaenroll.com/vip/{{ProductsList.url}}" target="_blank"><i
                            class="fa fa-folder-open"></i></a>-->https://www.afeusaenroll.com/vip/{{ProductsList.url.toUpperCase()}}
                </td>
                <td class="SmallFont">{{ProductsList.email}}</td>
                <td class="SmallFont">{{ProductsList.phone}}</td>
                <td class="SmallFont">{{ProductsList.status}}</td>
                <td>
                    <button *ngIf="application" class="btn btn-primary btn-sm" (click)="onRowSelect(ProductsList.id, 'e')" style="margin-right: 10px;"><i
                            class="fa fa-folder"></i> EDIT WEBSITE</button>
                    <button  *ngIf="application" class="btn btn-danger btn-sm" (click)="onRowSelect(ProductsList.id, 'd')"><i
                            class="fa fa-exclamation-triangle"></i> DELETE</button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>