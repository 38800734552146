

<div class="level1page ">

    <div class="stick-ontop">
        <h4 style="color:#f1f1f1; padding-top: 20px; ">
            Agents Management
        </h4>
        <hr>

        <div>
            <div class="row">
                <div class="col-6 col-md-4 col-xl-2">
                    <div class="productbox2 mousepointer" [routerLink]="['newagent']" >
                        <div class="text-left">
                            <span class="superfont">Invite Agent</span>
                            <br><span class="smallfont">Invite an agent to join AFEUSA</span>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-xl-2">
                    <div class="productbox2 mousepointer" [routerLink]="['agentsprospects']" >
                        <div class="text-left">
                            <span class="superfont">List Prospect Agents</span>
                            <br><span class="smallfont">List previously invited agents</span>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-xl-2">
                    <div class="productbox2 mousepointer" [routerLink]="['agentshierarchy']" >
                        <div class="text-left">
                            <span class="superfont">Contracted Agents</span>
                            <br><span class="smallfont">List your contracted downline agents</span>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-xl-2">
                    <div class="productbox2 mousepointer" [routerLink]="['agentscodesmanager/customlist']" >
                        <div class="text-left">
                            <span class="superfont">Invite Codes</span>
                            <br><span class="smallfont">Manage your agent invite codes</span>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-xl-2">
                    <div class="productbox2 mousepointer" [routerLink]="['agentsdashboard']" >
                        <div class="text-left">
                            <span class="superfont">Analytics</span>
                            <br><span class="smallfont">Display agent's analytics and stats</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>



    </div>

   
    <div >
        <div class="subbox text-left" >
            <router-outlet></router-outlet>
        </div>
    </div>
    <br>
</div>

<!--
<div class="fixed-top" style="z-index: 90;">

    <div style="padding-left: 90px; ">
        <nav class="navbar navbar-expand-lg navbar-dark" style="background-color: #3e669c;">
            <a class="navbar-brand" href="#">
                  Recruit Agents: 
                </a>

            <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
                  <span class="navbar-toggler-icon"></span>
                </button>


            <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }">
                <ul class="navbar-nav mr-auto">

                    <li class="nav-item">
                        <a class="nav-link" routerLink="newagent" routerLinkActive="btn btn-sm btn-dark">Invite Agent</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="agentsprospects" routerLinkActive="btn btn-sm btn-dark">Prospect Agents</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="agentshierarchy" routerLinkActive="btn btn-sm btn-dark">Contracted Agents</a>
                    </li>
                   
                    <li class="nav-item">
                        <a class="nav-link" routerLink="agentscodesmanager/hardcodedlist" routerLinkActive="btn btn-sm btn-dark">Invite Codes</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="agentsdashboard" routerLinkActive="btn btn-sm btn-dark">Analytics</a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</div>
<div class="body-text" style="z-index: 1; padding-right:20px;">
    <router-outlet></router-outlet>
</div>

-->