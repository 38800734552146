<div>

    <div class="sidebar text-center mydiv" style="text-align: left; z-index: 99;">
        <div *ngIf="NumAlerts==0">
            <div style="display: table; font-size: 18px; color: #ffffff; height:43px;">
                <a routerLink="/platform/intro" class="mousepointer text-white"><img src="assets/aelogowhite.png"
                        style="width:100%"></a>
            </div>


            <hr style="border-top: 1px solid #2C4360; margin-top:20px;">

            <div *ngIf="accstatus=='1'">
                <a routerLink="/platform/intro"><img src="assets/icons/tasks.png" style="width:30px" pTooltip="Intro">
                    <br><small class="text-white">Home</small>
                </a>
                <br *ngIf="Afetype=='24485168'">
                <a *ngIf="Afetype=='24485168'" routerLink="/platform/corporate/corpagents"><img
                        src="assets/icons/bank.png" style="width:30px; margin-top:25px" pTooltip="Corporate Backoffice">
                    <br><small class="text-white">Corporate</small>
                </a>
                <br>
                <a routerLink="crm/leads"><img src="assets/icons/users.png" style="width:30px; margin-top:25px"
                        pTooltip="Prospects">
                    <br><small class="text-white">Prospects</small>
                </a>
                <br>
                <a routerLink="clients/contractedclients"><img src="assets/icons/clients.png"
                        style="width:30px; margin-top:20px" pTooltip="Members">
                    <br><small class="text-white">Members</small>
                </a>
                <div *ngIf="application">
                    <a (click)="doAgents()"><img src="assets/icons/agents.png"
                            style="width:30px; margin-top:17px" pTooltip="Agents">
                        <br><small class="text-white">Agents</small>
                    </a>
                    <br>
                </div>

                <a routerLink="website/products"><img src="assets/icons/stats.png" style="width:30px; margin-top:25px"
                        pTooltip="Websites">
                    <br><small class="text-white">Websites</small>
                </a>
                <div *ngIf="application2">
                    <a routerLink="commissions/hci"><img src="assets/icons/commissions.png"
                            style="width:30px; margin-top:25px" pTooltip="Commissions">
                        <br><small class="text-white" style="margin-left:-7px">Commisions</small>
                    </a>
                </div>
                <a routerLink="tools/notesmodule"><img src="assets/icons/tools.png" style="width:30px; margin-top:25px"
                        pTooltip="Tools">
                    <br><small class="text-white">Tools</small>
                </a>
                <br>
                <a routerLink="productivity"><img src="assets/icons/charts.png" style="width:30px; margin-top:25px"
                        pTooltip="Training">
                    <br><small class="text-white">Training</small>
                </a>
                <br>
                <a routerLink="setup/password"><img src="assets/icons/talk.png" style="width:30px; margin-top:25px"
                        pTooltip="Account Setup">
                    <br><small class="text-white">My Account</small>
                </a>
                <br>
            </div>

            <div *ngIf="accstatus=='50'">
                <a routerLink="activate"><img src="assets/icons/talk.png" style="width:30px; margin-top:25px"
                        pTooltip="Activate Account">
                    <br><small class="text-white">Activate</small>
                </a>

            </div>
            <a (click)="LogOut()"><img src="assets/icons/exit.png" style="width:30px; margin-top:25px" pTooltip="Exit">
                <br><small class="text-white">Exit</small>
            </a>
        </div>
    </div>

    <router-outlet></router-outlet>





</div>