import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SelectItem, FilterService, FilterMatchMode } from 'primeng/api';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pendingapplications',
  templateUrl: './pendingapplications.component.html',
  styleUrls: ['./pendingapplications.component.css']
})
export class PendingapplicationsComponent implements OnInit {
  public FilterType: string = "";
  //NG Table Setup
  public type;
  public TotalPending: number = 0

  LeadsList: any;

  cols: any[];
  matchModeOptions: SelectItem[];


  //controls





  constructor(
    public httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private filterService: FilterService

  ) {
    this.TotalPending = 0;

    var APIURL = sessionStorage.getItem('endpoint') + "afeapi/desk/agents/getPendingApplications.aspx";    // set header for http call
    var options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    var body = new URLSearchParams();

    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        //this.LeadsList = Object.assign([], result);
        this.LeadsList = result
        for (let res of this.LeadsList) {
          this.TotalPending = this.TotalPending + res.amount
        }


      },
      error => {

      });
  }


  SelectApplication(id): void {


    sessionStorage.setItem("temp", id)
    this.router.navigate(['../membership'], { relativeTo: this.route });

    

  }

  ngOnInit(): void {

  }







}


