<div class="table-responsive" *ngIf="Data">
    <table class="table">
        <tr style="font-weight: 700; background-color: #666666; color:white;">
            <td style="width: 30px;"></td>
            <td colspan="2">Product</td>
            <td class="text-center">Max Comp</td>
            <td class="text-center" *ngIf="isDisabled !='1'">Select Compensation</td>
            <td class="text-center" *ngIf="isDisabled !='1'">Upline Compensation</td>
            <td class="text-center" *ngIf="isDisabled !='1'">Agent Compensation</td>
        </tr>
        <tr *ngFor="let product of Data" [ngStyle]="{backgroundColor:product.enabled == '1'? '#ffffff' : product.enabled == '0' ? '#f9f9f9' : 'transparent' }">
            <ng-container *ngIf="product.productid !=0">
            <td style="height: 60px;"><input  type="checkbox"
                    id="{{ 'check' + product.productid }}" binary="true" inputId="binary"
                    (change)="Checked($event.target)" [checked]="product.enabled=='1' ? true : false">
            <td style="width: 130px;"><img src="{{product.logo}}" style="max-height: 20px; max-width: 130px;"></td>
            <td><strong class="text-info">{{product.RealName}}</strong></td>
            <td class="text-center" >
                <ng-container *ngIf="product.enabled=='1'">
                {{product.Y1}}%
                </ng-container></td>
            <td class="text-center" *ngIf="isDisabled !='1'">
                <select class="form-control" id="{{ 'prod' + product.productid }}" (change)="GetValue($event.target)" [ngStyle]="{display:product.enabled == '1'? 'block' : product.enabled == '0' ? 'none' : 'block' }">
                        
                        <option [attr.selected]="num==product.agentpercent ? true : null" [value]="num" *ngFor='let num of counter(product.Y1, product.c3min, product.productid) ;let i = index'>{{num}}%</option>
                </select>
            </td>
            <td class="text-center" *ngIf="isDisabled !='1'">
                <div *ngIf="product.enabled=='1'">
                    {{product.Y1 - product.agentpercent | number:'1.2-2'}}%
                </div>
            </td>
            <td class="text-center" *ngIf="isDisabled !='1'">
                <div *ngIf="product.enabled=='1'">
                    {{product.agentpercent | number:'1.2-2'}}%
                </div>
            </td>
        </ng-container>
        </tr>
    </table>
</div>