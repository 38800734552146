import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'app-setcomp',
  templateUrl: './setcomp.component.html',
  styleUrls: ['./setcomp.component.css']
})
export class SetcompComponent implements OnInit {
  @Input() Data: any;
  @Input() isDisabled: string;

  constructor(
    public _httpClient: HttpClient,
    
  ) {
    
   }

  ngOnInit(): void {

  }


  ngOnChanges(changes: SimpleChanges) {
    //if DATA changes on parent for selecting new paygrid
    //delete all
    try {
      

      for (let product of this.Data) {
        if (product.productid != 0) {
          var y = document.getElementById("check" + product.productid) as HTMLInputElement;
          if (product.enabled == "1") {
            if (y) { y.checked = true };
          }
          else {
            if (y) { y.checked = false };
          }
          var x = document.getElementById("prod" + product.productid) as HTMLInputElement;
          if (x) { x.value = product.agentpercent; };
        }
      }
    }
    catch {

    }
  }



  //set percentages to display
  counter(max: number, min: number, productid) {
    let MyArray = [];
    MyArray.push(0)
    if (productid == 7) {
      for (let i = min; i <= max; i += 2) {
        MyArray.push(i)
      }
    }
    else {
      for (let i = min; i <= max; i++) {
        MyArray.push(i)
      }
    }

    return MyArray;
  }
  //dropdown click
  GetValue(e): void {
    var prod = e.id;
    prod = prod.replace("prod", "")
    for (let product of this.Data) {
      if (product.productid == prod) {
        product.agentpercent = parseInt(e.value);
      }
    }
    /*if product 1, modify product 7
    if (prod == "1") {
      for (let product of this.Data) {
        if (product.productid == 7) {
          var x = document.getElementById("prod7") as HTMLInputElement;
          x.value = (e.value * 2).toString();
          product.agentpercent = parseInt(e.value) * 2;
        }
      }
    }
    if (prod == "7") {
      for (let product of this.Data) {
        if (product.productid == 1) {
          var x = document.getElementById("prod1") as HTMLInputElement;
          x.value = (e.value / 2).toString();
          product.agentpercent = parseInt(e.value) / 2;
        }
      }
    }
    */
  }
  //checkbox enable/disable
  Checked(e): void {
    //document.getElementById('prod')

    var prod = e.id;
    prod = prod.replace("check", "prod")
    var prodid = prod.replace("prod", "")
    var secondProduct: string
    

    for (let product of this.Data) {
      if (product.productid == prodid || product.productid==secondProduct) {
        if (e.checked == true) {
          product.enabled = "1";
        }
        else {
          product.enabled = "0";
        }
      }
    }
  }

}





/*
  ON NG INIT FROM PARENT, COPY THIS:
  var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/getagentcomp.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('pl', encodeURIComponent(sessionStorage.getItem('plid')));
    this._httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.Data = result;
        for (let product of this.Data) {
          product.agentpercent = 0;
          if (product.productid == 1) {
            product.enabled = "1";
          }
          if (product.productid == 7) {
            product.enabled = "1";
          }
        }
      },
      error => {
        return;
      });

*/



/*
      TO POPULATE, CALL COMPONENT LIKE THIS:
<app-setcomp [Data]="Data" ></app-setcomp>
*/
