<h4>Update Account</h4>
<hr />
<div class="w100">
  <p-tabView [activeIndex]="tabindex" (onChange)="onTabChange($event)">
    <p-tabPanel header="About You">
      <div class="bShadow-5">
        <!--
        <div *ngIf="AgentType == 'business'">
          <br />
          <h5>Your Business Information</h5>
          <div class="row">
            <div class="col-xl-8 col-md-6 col-12">
              <label>Legal Name</label><br />
              <input type="text" maxlength="100" class="form-control" [(ngModel)]="JSONResp.businessdata.legalname" />
            </div>
            <div class="col-xl-4 col-md-6 col-12">
              <label>Domicile State</label><br />
              <select [(ngModel)]="JSONResp.businessdata.domicilestate" class="form-control" name="residentstate"
                id="residentstate" style="border-radius: 7px">
                <option value=""></option>
                <option *ngFor="let USstate of USstates" [value]="" [attr.selected]="
                    USstate.Code == JSONResp.businessdata.domicilestate
                      ? true
                      : null
                  ">
                  {{ USstate.Name }}
                </option>
              </select>
            </div>
            <div class="col-md-12">
              <label>Owner(s) / Member(s)</label><br />
              <textarea type="text" rows="5" class="form-control" [(ngModel)]="JSONResp.businessdata.owners"></textarea>
            </div>
          </div>
          <div>
            <br />
            <h5>
              Authorized person assisting in licensing or contracting agents
            </h5>
            <hr />
            <p>
              If you would have an authorized staff that assists you in
              licensing or contracting of agents. Please complete the
              information below to setup a login for this support person. They
              will not have access to the commission module.
            </p>
            <div class="row">
              <div class="col-xl-3 col-md-6 col-12">
                <label>First Name</label><br />
                <input type="text" maxlength="30" class="form-control"
                  [(ngModel)]="JSONResp.businessdata.authorizedperson.firstname" />
              </div>

              <div class="col-xl-3 col-md-6 col-12">
                <label>Last Name</label><br />
                <input type="text" maxlength="30" class="form-control"
                  [(ngModel)]="JSONResp.businessdata.authorizedperson.lastname" />
              </div>
              <div class="col-xl-3 col-md-6 col-12">
                <label>Email</label><br />
                <input type="text" maxlength="70" class="form-control"
                  [(ngModel)]="JSONResp.businessdata.authorizedperson.email" />
              </div>
              <div class="col-xl-3 col-md-6 col-12">
                <label>Phone Number</label><br />
                <input type="text" maxlength="30" class="form-control"
                  [(ngModel)]="JSONResp.businessdata.authorizedperson.phone" mask="(000) 000-0000"
                  [dropSpecialCharacters]="false" />
              </div>
            </div>
          </div>
        </div>
        -->
        <br />
        <h5>Your Personal Information</h5>
        <hr />
        <div class="row">
          <!--
                <div class="col-md-12" *ngIf="AgentType=='individual'">
                    <label>Company Name OR Full Name</label><br>
                    <input type="text" maxlength="100" class="form-control" [(ngModel)]="fullcompanyname">
                </div>
                -->
          <div class="col-xl-3 col-md-6 col-12">
            <label>First Name</label><br />
            <input type="text" maxlength="30" class="form-control" [(ngModel)]="JSONResp.FirstName" />
          </div>

          <div class="col-xl-3 col-md-6 col-12">
            <label>Last Name</label><br />
            <input type="text" maxlength="30" class="form-control" [(ngModel)]="JSONResp.LastName" />
          </div>

          <div class="col-xl-3 col-md-6 col-12">
            <label>Date of Birth</label><br />
            <input type="text" maxlength="30" class="form-control" [(ngModel)]="JSONResp.BirthDate" mask="M0/d0/0000"
              [dropSpecialCharacters]="false" placeholder="mm/dd/yyyy" />
          </div>

        </div>
        <br />
        <h5>{{ addressTitle }}</h5>
        <hr />
        <div class="row">
          <div class="col-xl-6 col-md-6 col-12">
            <label>Address</label><br />
            <input type="text" maxlength="100" class="form-control" [(ngModel)]="JSONResp.Address1" />
          </div>
          <div class="col-xl-6 col-md-6 col-12">
            <label>Address 2</label><br />
            <input type="text" maxlength="100" class="form-control" [(ngModel)]="JSONResp.Address2" />
          </div>
          <div class="col-xl-6 col-md-6 col-12">
            <label>City</label><br />
            <input type="text" maxlength="50" class="form-control" [(ngModel)]="JSONResp.City" />
          </div>
          <div class="col-xl-3 col-md-6 col-12">
            <label>State</label><br />
            <select [(ngModel)]="JSONResp.StateCode" class="form-control" name="residentstate" id="residentstate"
              style="border-radius: 7px">
              <option value=""></option>
              <option *ngFor="let USstate of USstates" value="{{ USstate.Code }}" [attr.selected]="
                  USstate.Code == JSONResp.StateCode ? true : null
                ">
                {{ USstate.Name }}
              </option>
            </select>
          </div>
          <div class="col-xl-3 col-md-6 col-12">
            <label>ZIP Code</label><br />
            <input type="text" maxlength="5" class="form-control" [(ngModel)]="JSONResp.Zip" />
          </div>
        </div>
        <br />

        <h5>Contact Information</h5>
        <hr />
        <div class="row">
          <div class="col-xl-3 col-md-6 col-12">
            <label>Cell Phone Number</label><br />
            <input [(ngModel)]="JSONResp.Phone" type="text" maxlength="30" class="form-control" mask="(000) 000-0000"
              [dropSpecialCharacters]="true" />
          </div>
          <div class="col-xl-3 col-md-6 col-12">
            <label>Business Phone Number</label><br />
            <input [(ngModel)]="JSONResp.BusinessPhone" type="text" maxlength="30" class="form-control"
              mask="(000) 000-0000" [dropSpecialCharacters]="true" />
          </div>
          <div class="col-xl-3 col-md-6 col-12">
            <label>Fax Number</label><br />
            <input [(ngModel)]="JSONResp.FaxPhone" type="text" maxlength="30" class="form-control" mask="(000) 000-0000"
              [dropSpecialCharacters]="true" />
          </div>
          <div class="col-xl-3 col-md-6 col-12">
            <label>Email Address</label><br />
            <input [(ngModel)]="JSONResp.Email" type="text" maxlength="70" class="form-control" />
          </div>
        </div>
        <br />
        <button class="btn btn-warning btn-sm text-white" (click)="SaveData1()" style="margin-right: 10px">
          <i class="fa fa-user"></i> Update Personal Information
        </button>
        <br /><br />
      </div>
    </p-tabPanel>

    <p-tabPanel header="Deposit Information">
      <div class="bShadow-5">
        <br>
        <h5>Deposit Information</h5>

        <hr>
        
        <div *ngIf="!showACH">
          <div>Click the button below to update your payment information on file.
            <br><br>
            <div class="alert alert-info">
              Current Bank on File: {{JSONResp.BankInfo.BankName}}
            </div>
          </div>

          <br>
          <button class="btn btn-danger btn-sm" (click)="showACH=true">Update Deposit Information</button>
        </div>
        <div *ngIf="showACH">
          <label>Type of Business</label><br>
        <select class="form-control" style="border-radius: 7px;" [(ngModel)]="JSONResp.LicenseInfo.BusinessType">
          <option value="Individual">Individual</option>
          <option value="C Corporation">C Corporation</option>
          <option value="S Corporation">S Corporation</option>
          <option value="Partnership">Partnership</option>
          <option value="Trust/estate">Trust/estate</option>
          <option value="LLC">Limited Liability Company</option>
        </select>
        <br>
          <div *ngIf="JSONResp.LicenseInfo.BusinessType=='Individual'">
            <div class="row">
              <div class="col-md-6">
                <label>First and Last Name</label><br>
                <input type="text" maxlength="60" class="form-control" [(ngModel)]="JSONResp.BankInfo.AccountName">
              </div>
              <div class="col-md-6">
                <label>SSN (Social Security Number)</label><br>
                <input type="text" maxlength="60" class="form-control" [(ngModel)]="SSN" maxlength="9"
                  (keypress)="keyPressNumbers($event)">
              </div>
            </div>
          </div>
          <div *ngIf="JSONResp.LicenseInfo.BusinessType!='Individual'">
            <div class="row">
              <div class="col-md-6">
                <label>Business Name</label><br>
                <input type="text" maxlength="60" class="form-control" [(ngModel)]="JSONResp.BankInfo.AccountName">
              </div>
              <div class="col-md-6">
                <label>EIN (Employer Identification Number)</label><br>
                <input type="text" maxlength="60" class="form-control" [(ngModel)]="SSN" mask="00-0000000"
                  [dropSpecialCharacters]="false">
              </div>
            </div>
          </div>
          <br>

          <div class="row">
            <div class="col-md-4">
              <label>Account Beneficiary Name</label><br>
              <input type="text" maxlength="40" class="form-control" [(ngModel)]="JSONResp.BankInfo.AccountName">
            </div>
            <div class="col-md-4">
              <label>Bank Routing Number</label><br>
              <input type="text" maxlength="9" class="form-control" [(ngModel)]="JSONResp.BankInfo.BankRoutingNumber"
                (keypress)="keyPressNumbers($event)">
            </div>
            <div class="col-md-4">
              <label>Bank Account Number</label><br>
              <input type="text" maxlength="17" class="form-control" [(ngModel)]="JSONResp.BankInfo.BankAccountNumber"
                (keypress)="keyPressNumbers($event)">
            </div>
            <div class="col-md-4">
              <label>Bank Account Type</label><br>
              <select class="form-control" [(ngModel)]="JSONResp.BankInfo.BankAccountType">
                <option value=""></option>
                <option value="Checking" [attr.selected]="JSONResp.BankInfo.BankAccountType=='Checking' ? true : null">
                  Checking Account</option>
                <option value="Savings" [attr.selected]="JSONResp.BankInfo.BankAccountType=='Savings' ? true : null">
                  Savings Account</option>
              </select>
            </div>
            <div class="col-md-4">
              <label>Bank Name</label><br>
              <input type="text" maxlength="60" class="form-control" [(ngModel)]="JSONResp.BankInfo.BankName">
            </div>

          </div>
          <br>
          <button class="btn btn-warning btn-sm text-white" (click)="SaveData2()" style="margin-right: 10px">
            <i class="fa fa-user"></i> Update Payment Information
          </button>
        </div>
        <br /><br />
      </div>
    </p-tabPanel>

    <p-tabPanel header="License">
      <div class="bShadow-5">
        <br />
        <h3>License Information</h3>
        <hr>
        <label>Type of Business</label><br>
        <select class="form-control" [(ngModel)]="JSONResp.type" (click)="resetAgencyType()">
          <option value="individual">Individual</option>
          <option value="business">Agency</option>
        </select>
        <br>
        <span class="text-danger">IMPORTANT: </span>

        <div *ngIf="JSONResp.type=='business'">
          AFEUSA will validate all Agency licenses and Individual licenses against NIPR. Please fill out both
          Agency and Individual information for contracting. If you have an Agency license, we will appoint
          using the Agency License, however if you don’t have an Agency License in a specific state, we will
          appoint using your Individual license though NIPR. Please complete the following information, all
          contract information is private and for internal use only.
        </div>
        <div *ngIf="JSONResp.type=='business'">
          Please complete the following information based upon your
          <strong>Resident State & NPN information</strong>. Licensing
          must match your resident license information. Dylan Consulting, LLC validates all licenses against
          NIPR database.
        </div>


        <br>
        <div *ngIf="JSONResp.type=='business'">
          <h5>Agency Resident License Information <span class="text-danger">(AS SHOWN ON RESIDENT
              LICENSE)</span></h5>
          <hr>
          <div class="row">
            <div class="col-xl-4 col-sm-6 col-md-6 col-12">
              <label>Licensed Agency Name (as shown on NPN)</label><br>
              <input type="text" maxlength=30 class="form-control" [(ngModel)]="npnAg.AgencyName">
            </div>
            <div class="col-xl-4 col-sm-6 col-md-6 col-12">
              <label>Resident Insurance License #</label><br>
              <input type="text" maxlength=30 class="form-control" [(ngModel)]="npnAg.ResidentInsuranceNumber">
            </div>
            <div class="col-xl-4 col-sm-6 col-md-6 col-12">
              <label>Resident State License</label><br>
              <select class="form-control" style="border-radius: 7px;" [(ngModel)]="npnAg.ResidentStateLicense">
                <option value=""></option>
                <option *ngFor="let USstate of USstates" value="{{USstate.Code}}">{{USstate.Name}}
                </option>
              </select>
            </div>
            <div class="col-xl-4 col-sm-6 col-md-6 col-12">
              <label>Agency Type </label><br>
              <select class="form-control" style="border-radius: 7px;" [(ngModel)]="npnAg.AgencyType">
                <option value=""></option>
                <option value="Sole Proprietor">Sole Proprietor</option>
                <option value="C Corporation">C Corporation</option>
                <option value="S Corporation">S Corporation</option>
                <option value="Partnership">Partnership</option>
                <option value="Trust/estate">Trust/estate</option>
                <option value="LLC">Limited Liability Company</option>
              </select>
            </div>
            <div class="col-xl-4 col-sm-6 col-md-6 col-12">
              <label>EIN</label><br>
              <input type="text" maxlength="60" class="form-control" [(ngModel)]="npnAg.EIN" mask="00-0000000"
                [dropSpecialCharacters]="false">
            </div>
            <div class="col-xl-4 col-sm-6 col-md-6 col-12">
              <label>Type of Insurance License</label><br>
              <select class="form-control" style="border-radius: 7px;" [(ngModel)]="npnAg.TypeOfLicense">
                <option value=""></option>
                <option value="Life Only">Life Only</option>
                <option value="Health Only">Health Only</option>
                <option value="Life and Health">Life and Health</option>
                <option value="P&C">P&C</option>
                <option value="Life, Health, and P&C">Life, Health, and P&C</option>
              </select>
            </div>

            <div class="col-xl-6 col-sm-6 col-md-6 col-12">
              <label>Agency Address (as Listed on NPN)</label>
              <input type="text" maxlength="50" class="form-control" [(ngModel)]="npnAg.Address">
            </div>
            <div class="col-xl-2 col-sm-6 col-md-6 col-12">
              <label>City</label>
              <input type="text" maxlength="50" class="form-control" [(ngModel)]="npnAg.city">
            </div>

            <div class="col-xl-2 col-sm-6 col-md-6 col-12">
              <label>State</label><br>
              <select class="form-control" style="border-radius: 7px;" [(ngModel)]="npnAg.state">
                <option value=""></option>
                <option *ngFor="let USstate of USstates" value="{{USstate.Code}}">{{USstate.Name}}</option>
              </select>
            </div>
            <div class="col-xl-2 col-sm-6 col-md-6 col-12">
              <label>ZIP Code</label>
              <input type="text" maxlength="5" class="form-control" [(ngModel)]="npnAg.zip">
            </div>

            <div class="col-md-6">
              <label>Agency NPN Number</label><br>
              <input type="text" maxlength=30 class="form-control" [(ngModel)]="npnAg.NPNnumber">
            </div>
            <div class="col-md-6">
              <label> </label><br><br>
              <a target="_blank" href="https://nipr.com/help/look-up-your-npn" class="text-primary">Click
                here
                to search</a>
            </div>

          </div>
          <br>
        </div>
        <h5>Resident Individual License Information <span class="text-danger">(AS SHOWN ON RESIDENT
            LICENSE)</span></h5>
        <div class="row">
          <div class="col-xl-2 col-sm-6 col-md-6 col-12">
            <label>First Name</label><br>
            <input type="text" maxlength="50" class="form-control" [(ngModel)]="npnInd.firstname">
          </div>
          <div class="col-xl-2 col-sm-6 col-md-6 col-12">
            <label>Middle Name</label><br>
            <input type="text" maxlength="50" class="form-control" [(ngModel)]="npnInd.middlename">
          </div>
          <div class="col-xl-2 col-sm-6 col-md-6 col-12">
            <label>Last Name</label><br>
            <input type="text" maxlength="50" class="form-control" [(ngModel)]="npnInd.lastname">
          </div>
          <div class="col-xl-2 col-sm-6 col-md-6 col-12">
            <label>DOB</label><br>
            <input type="text" maxlength="50" class="form-control" mask="M0/d0/0000" [(ngModel)]="npnInd.DOB"
              placeholder="mm/dd/yyyy" [dropSpecialCharacters]="false">
          </div>
          <div class="col-xl-4 col-sm-6 col-md-6 col-12">
            <label>SSN</label><br>
            <input type="text" maxlength="60" class="form-control" [(ngModel)]="npnInd.SSN" mask="000-00-0000"
              [dropSpecialCharacters]="false">
          </div>

          <div class="col-xl-4 col-sm-6 col-md-6 col-12">
            <label>Type of Insurance License</label><br>
            <select class="form-control" style="border-radius: 7px;" [(ngModel)]="npnInd.TypeOfLicense">
              <option value=""></option>
              <option value="Life Only">Life Only</option>
              <option value="Health Only">Health Only</option>
              <option value="Life and Health">Life and Health</option>
              <option value="P&C">P&C</option>
              <option value="Life, Health, and P&C">Life, Health, and P&C</option>
            </select>
          </div>
          <div class="col-xl-4 col-sm-6 col-md-6 col-12">
            <label>Resident Insurance License #</label><br>
            <input type="text" maxlength=30 class="form-control" [(ngModel)]="npnInd.ResidentInsuranceNumber">
          </div>
          <div class="col-xl-4 col-sm-6 col-md-6 col-12">
            <label>Resident State License</label><br>
            <select class="form-control" style="border-radius: 7px;" [(ngModel)]="npnInd.ResidentStateLicense">
              <option value=""></option>
              <option *ngFor="let USstate of USstates" value="{{USstate.Code}}">{{USstate.Name}}</option>
            </select>
          </div>

          <div class="col-xl-6 col-sm-6 col-md-6 col-12">
            <label>Resident Address (as Listed on NPN)</label>
            <input type="text" maxlength="50" class="form-control" [(ngModel)]="npnInd.Address">
          </div>
          <div class="col-xl-2 col-sm-6 col-md-6 col-12">
            <label>City</label>
            <input type="text" maxlength="50" class="form-control" [(ngModel)]="npnInd.city">
          </div>

          <div class="col-xl-2 col-sm-6 col-md-6 col-12">
            <label>State</label><br>
            <select class="form-control" style="border-radius: 7px;" [(ngModel)]="npnInd.state">
              <option value=""></option>
              <option *ngFor="let USstate of USstates" value="{{USstate.Code}}">{{USstate.Name}}</option>
            </select>
          </div>
          <div class="col-xl-2 col-sm-6 col-md-6 col-12">
            <label>ZIP Code</label>
            <input type="text" maxlength="5" class="form-control" [(ngModel)]="npnInd.zip">
          </div>

        </div>
        <div class="row">
          <div class="col-md-6">
            <label>Individual NPN Number</label><br>
            <input type="text" maxlength="50" class="form-control" [(ngModel)]="npnInd.NPNnumber">
          </div>
          <div class="col-md-6">
            <label> </label><br><br>
            <a target="_blank" href="https://nipr.com/help/look-up-your-npn" class="text-primary">Click here
              to search</a>
          </div>
        </div>
        <br>
        <h5>E&O Coverage</h5>
        <hr />
        <div class="row">
          <div class="col-xl-3 col-sm-6 col-md-6 col-12">
            <label>E&O Coverage</label><br />
            <select class="form-control" style="border-radius: 7px" [(ngModel)]="JSONResp.LicenseInfo.EOICoverage">
              <option [ngValue]="true" [attr.selected]="
              JSONResp.LicenseInfo.EOICoverage == true ? true : null
                ">
                Yes
              </option>
              <option [ngValue]="false" [attr.selected]="
              JSONResp.LicenseInfo.EOICoverage == false ? true : null
                ">
                No
              </option>
            </select>
          </div>
          <div class="col-xl-3 col-sm-6 col-md-6 col-12" *ngIf="JSONResp.LicenseInfo.EOICoverage == true">
            <label>E&O Amount</label><br />
            <input type="text" maxlength="70" class="form-control" [(ngModel)]="JSONResp.LicenseInfo.EOIAmount"
              (keypress)="keyPressNumbers($event)" maxlength="7" />
          </div>
          <div class="col-xl-3 col-sm-6 col-md-6 col-12" *ngIf="JSONResp.LicenseInfo.EOICoverage == true">
            <label>E&O Expiration</label><br />
            <input type="text" maxlength="70" class="form-control" [(ngModel)]="JSONResp.LicenseInfo.EOIExpiration"
              mask="M0/d0/0000" placeholder="mm/dd/yyyy" [dropSpecialCharacters]="false" />
          </div>
          <div class="col-xl-3 col-sm-6 col-md-6 col-12" *ngIf="JSONResp.LicenseInfo.EOICoverage == true">
            <label>Update E&O Certificate on file</label><br>
            <input type="file" class="form-control" name="eofile" id="eofile" (change)="onFileChanged($event)"
              accept="application/pdf,image/png,image/jpeg">
            <div *ngIf="JSONResp.LicenseInfo.EOIfile">
              <small>
                {{JSONResp.LicenseInfo.EOIfile}}<br>
                <a routerLink="../profile">Click here to browse your files</a>
              </small>
            </div>
          </div>
        </div>
        <br />
        <button class="btn btn-warning btn-sm text-white" (click)="SaveData3()" style="margin-right: 10px">
          <i class="fa fa-user"></i> Update License Information
        </button>
        <br /><br />
      </div>



















    </p-tabPanel>


  </p-tabView>

</div>

<div id="spinner" style="height: 100vh;" *ngIf="showspinner">
  <div class="centered">
    <img src="assets/images/processing.gif" style="width:50px;">
    <br><br>
    <span class="text-white">Processing Enrollment. Please wait...</span>
  </div>
</div>