<div class="table-responsive" >
    <table class="table">
        <tr style="font-weight: 700; background-color: #666666; color:white;">
            <td style="width: 30px;"></td>
            <td colspan="2">Product</td>
           
        </tr>
        <tr *ngFor="let product of Data.available" [ngStyle]="{backgroundColor:product.enabled == '1'? '#ffffff' : product.enabled == '0' ? '#f9f9f9' : 'transparent' }">
            <ng-container>
            <td style="height: 60px;"><input  type="checkbox"
                    id="{{ 'check' + product.productid }}" binary="true" inputId="binary"
                    (change)="Checked($event.target)" [checked]="product.selected=='1' ? true : false">
            <td style="width: 130px;"><img src="{{product.logo}}" style="max-height: 20px; max-width: 130px;"></td>
            <td><strong class="text-info">{{product.productname}}</strong></td>
            
        </ng-container>
        </tr>
    </table>
</div>