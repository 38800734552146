<div class="container pt-100">
    <div class="about-content">
        <br>
        <span>AFEUSA</span>
        <h2>Terms & Conditions</h2>

        Election Of Coverage And Authorization<br>
        The participant, in consideration of membership in the Association and participation in the plan, hereby
        acknowledges that the Association, its third-party administrator, their agents, owners, successors and assigns,
        assumes no liabilities or obligations other than those specifically and expressly identified herein. Participant
        hereby agrees to indemnify them from and against any and all claims, damages, losses, costs or expenses
        (including, without limitation, attorney’s fees and disbursements) for any claims that may arise by the
        participation of the plan or membership in the association. <strong>ENROLLMENT IN MEMBERSHIP IS PENDING APPROVAL BY
        AFEUSA</strong>.
        <br><br>
        I understand this authority is to remain in full force and effect until the company has received written
        notification from me of its termination in such time and such manner as to afford the company and depositor a
        reasonable opportunity to act on it. I have the right to stop payment of a debit entry (deduction) by
        notification to MyBenefitsKeeper at 844-792-6985 three business days or more before this payment is scheduled to
        be made
        <br><br>
        <strong>ENROLLMENT</strong>. By signing this Member Enrollment Form I understand that I am enrolling as a Member in the
        Association for Entrepreneurship USA (the “Association”). The Association is a corporation, having members,
        formed under the nonprofit corporation laws of the State of Illinois. All members have full voting rights but
        the Association is managed by the board of directors of the Association. Nothing shall create any
        employer-employee relationship between the Association and any Member.
        <br><br>
        <strong>PROXY</strong>. I appoint the Secretary of the Association in office at any particular time as my proxy to receive notice
        of and attend all meetings of the members of the Association and vote on my behalf and to otherwise act for me
        in the same manner and with the same effect as if I were personally present. This proxy shall be valid until
        revoked. It may be revoked at any time by sending another signed proxy with a later date to the Secretary,
        notifying the Secretary in writing that you have revoked your proxy or by voting in person at any meeting.
        <br><br>
        <strong>MEMBER TERMS AND CONDITIONS</strong>. These Terms and Conditions constitute an agreement between the Association and the
        Member (referred to herein as “Member”, “You” or “Your”). You may only apply for a membership on your own behalf
        and may only enroll in one membership in the Association. You must abide by the Terms and Conditions of the
        Association. To be a member you must (1) be 18 years of age or older, (2) live in any state in the United States
        or Washington, DC, (3) be an Entrepreneur as defined in the bylaws of the Association and (4) be actively at
        work or capable of active employment. Members must remain active and be in good standing with the Association to
        be eligible for any benefits. The Association reserves the right, at its sole discretion, at any time with or
        without notice, to change the Terms and Conditions or cancel a membership or any benefits offered by the
        Association. Members will receive a Membership Fulfillment Kit with identification cards which will briefly
        describe the benefits being provided through the Association. Fulfillment may be provided electronically.
        The cost of the membership and of any benefits is your sole responsibility. Membership is effective on the first
        of the month following enrollment acceptance by the Association. Failure of a member to pay dues will be cause
        for cancellation of the membership by the Association. Returned checks, insufficient fund notices on bank drafts
        or denial by the member’s credit card company for payment of the periodic amount due is deemed evidence of
        non-payment by a member. Any bank service fees charged because of such action will be the responsibility of the
        member.
        <br><br>
        As authorized at the time of this enrollment application, your monthly payment must be paid through an automatic
        draft of a checking or savings account by an ACH transaction or through an automatic debit transaction to a
        credit card. By agreeing to make your monthly payment though either an ACH transaction or automatic debit
        transaction to your credit card, you hereby waive the right to any future notice of the transfer of funds via
        either an ACH transaction or automatic debit to your credit card. The Association retains the services of a
        third-party administrator to perform the collection services or other administrative services required for this
        membership. As a member, you agree that inquiries or challenges to ACH or credit card charges shall be limited
        to two (2) monthly payments, and you waive all right to inquire into or challenge any and all other monthly
        payments. Your authority shall remain in effect until the Association receives a signed written request from you
        to cancel your membership and any plan benefits. If any payment is dishonored (with or without cause,
        intentionally or inadvertently), the Association assumes no liability whatsoever even if the result of the
        dishonored payment is a termination of your membership in the Association.
        <br><br>
        Members may change the mode of payment, make a change, or cancel their membership at any time upon written
        notification to the Association. If you choose to cancel your membership, it is your responsibility to make sure
        that your written request for cancellation is sent to the Association at least five (5) days prior to your next
        draft date in order for your account not to be charged the membership dues for the following month. Such written
        notice may be sent by regular mail to AFEUSA 16427 N Scottsdale Road, Suite 410, Scottsdale, AZ 85254 or by an
        electronic message if an email address has been provided. Notice shall be considered delivered on the date of
        receipt. Dues for memberships cancelled within 30 days of the initial effective date shall be refunded in full.
        Enrollment fees are not refundable unless required by law. Any refund to which a Member may be entitled shall be
        processed within 10 business days from the date the written request for cancellation is received or 10 days from
        the date that such funds were received by the Association, whichever is later.
        <br><br>
        Your Association membership may provide access to certain insurance benefits. You or your family may not apply
        for or purchase any insured product offered through the Association more than once. All group policies providing
        insured benefits are issued by licensed insurance companies to the Association as the group master insurance
        policyholder. In the event insurance benefits are provided as part of a benefit package, you are notified that
        such insurance coverage may terminate on the earlier of the date the group master policy is terminated or on the
        premium due date on or next following the date a person ceases to be a member or eligible person. A Certificate
        of Insurance will be provided in membership fulfillment materials. The certificate will contain only a brief
        description or summary of benefits. Any difference between the certificate and the actual insurance policy will
        be settled according to the specific terms, limitations and exclusions contained in the policy. Insurance
        benefits may not be available in all states and will vary by availability based on the insurance laws,
        regulations, rules and bulletins in the member’s state of residence and approval needed from a department of
        insurance, if required. To receive a reimbursement of an insurance benefit, you must complete and submit
        standard claim forms which must be mailed to the insurance company or its designated third-party administrator
        in order to receive payment for any covered insured benefits. You may contact Member Services at 1-833-973-2166
        to obtain instructions on how to obtain a claim form from the insurance company. Insurance benefits, <strong>if any, are
        NOT basic health insurance or major medical coverage and are not designed as a substitute or replacement for
        basic health insurance or major medical insurance</strong>.
        <br><br>
        <strong>Disclaimer</strong>: The Association is not owned or controlled by any insurance company. The Association is not an
        insurer, insurance agent, producer, broker, third party administrator, underwriter or guarantor. The Association
        does not handle, adjust, settle or pay any claims under any group insurance policy issued to the Association.
        The Association does not provide any medical treatment, advice or other medical services or products to any
        Member.





    </div>
</div>