<h4>NIPR States</h4>
<hr>
<div class="w100">

    <div class="bShadow-5">
        <br>
        <h5>NIPR returned the following information for your licenses</h5>
        <hr>
       
        <table class="table " style="width: 450px;">
            <tr class="bg-primary text-white">
                <td>State</td>
                <td>Last Updated</td>
                <td>Is Appointed</td>
            </tr>
            <tr *ngFor="let state of States">
                <td>{{state.StateAllowed}}</td>
                <td>{{state.lastUpdated}}</td>
                <td>{{state.isAppointed}}</td>
            </tr>
        </table>

    </div>
    <br><br>
   
</div>