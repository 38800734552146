<h4>Prospect Agents</h4>

<hr>




<div class="minheight">
    <p-table #dt [value]="ProspectsList" [rows]="25" [showCurrentPageReport]="true" [rowsPerPageOptions]="[25,50,200,1000]" styleClass="p-datatable-customers" [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} agents"
        [filterDelay]="0" [globalFilterFields]="['fullname','email','phone','dayinvited','dayopened', 'dayused', 'dayconverted', 'code']" [resizableColumns]="true" [autoLayout]="true" selectionMode="single">

        <ng-template pTemplate="header">
            <tr class="FontRow f13">
                <th></th>
                <th [pSortableColumn]="'fullname'">Agent Name</th>
                <th [pSortableColumn]="'email'">Email</th>
                <th [pSortableColumn]="'phone'">Phone</th>
                <th [pSortableColumn]="'dayinvited'">Day Invited</th>
                <th [pSortableColumn]="'dayopened'">Email Opened</th>
                <th [pSortableColumn]="'dayused'">Link Clicked</th>
                <th [pSortableColumn]="'dayconverted'">Converted</th>
                <th [pSortableColumn]="'code'">Code</th>
            </tr>
            <tr>
                <th></th>
                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'fullname', 'contains')" placeholder="Search by name">
                </th>
                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'email', 'contains')" placeholder="Search by email">
                </th>

                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'phone', 'contains')" placeholder="Search by phone">

                </th>

                <th>

                </th>

                <th>

                </th>

                <th>

                </th>

                <th>

                </th>

                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'code', 'contains')" placeholder="Search by code">

                </th>


            </tr>
        </ng-template>




        <ng-template pTemplate="body" let-ProspectsList let-rowIndex="rowIndex">
            <tr class="FontRow f13" (click)="onRowSelect(ProspectsList.id)" style="cursor: pointer;">
                <td class="SmallFont">{{rowIndex +1}}</td>
                <td class="SmallFont">{{ProspectsList.fullname.toUpperCase()}}</td>
                <td class="SmallFont">{{ProspectsList.email}}</td>
                <td class="SmallFont">{{ProspectsList.phone}}</td>
                <td class="SmallFont">{{ProspectsList.dayinvited}}</td>
                <td class="SmallFont">{{ProspectsList.dayopened}}</td>
                <td class="SmallFont">{{ProspectsList.dayused}}</td>
                <td class="SmallFont">{{ProspectsList.dayconverted}}</td>
                <td class="SmallFont">{{ProspectsList.code}}</td>

            </tr>
        </ng-template>
    </p-table>
</div>