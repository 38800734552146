import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SelectItem, FilterService, FilterMatchMode } from 'primeng/api';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-productspage',
  templateUrl: './productspage.component.html',
  styleUrls: ['./productspage.component.css']
})
export class ProductspageComponent implements OnInit {

  APIresult: any[]; //api result for listing all products - Permanent
  ProductsList: any[]; //user loaded products - Permanent
  StandardProductsList: any[]; //list of standard products
  OpenOverlay: boolean = false; //shows or hides overlap

  //add product
  ShowStandard: boolean = true;  //selection of a standard product
  ShowEdit: boolean;  //edition of a product
  ShowTopMenu: boolean = true; //show top menu on overlay

  //OVERLAY VARIABLES
  //product edition - base
  plProductid: string;
  baseProductid: string;
  productName: string;
  productContent: string;
  customcontent: number;
  status: number;

  //product edition - actions
  action1: string = "quote";
  action2: string = "contact";
  action3: string;
  action4: string;
  button1: string = "Quote Now";
  button2: string = "Contact Us";
  button3: string;
  button4: string;
  destination1: string;
  destination2: string;
  destination3: string;
  destination4: string;

  //END OVERLAY VARIABLES


  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize'],
      ['insertImage'],
      ['insertVideo'],
    ]
  };


  constructor(

    public httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private filterService: FilterService
  ) {
    this.LoadProducts();
  }



  LoadProducts(): void {
    var APIURL = sessionStorage.getItem('endpoint') + "website/getproducts.aspx";
    var options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    var body = new URLSearchParams();

    body.set('userid', encodeURIComponent(sessionStorage.getItem('userid')));
    body.set('pl', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('plkey', encodeURIComponent(sessionStorage.getItem('plkey')));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.APIresult = Array.of(result);
        if (this.APIresult[0].success == "1") {
          this.ProductsList = this.APIresult[0].products;
          this.StandardProductsList = this.APIresult[0].standardproducts;
        }
      },
      error => {

      });
  }

  ClearDivs(): void {
    this.ShowStandard = false;
    this.ShowEdit = false;
  }

  CleanInput(): void {
    this.action1="";
    this.action2="";
    this.action3="";
    this.action4="";
    
    this.button1="";
    this.button2="";
    this.button3="";
    this.button4="";

    this.destination1="";
    this.destination2="";
    this.destination3="";
    this.destination4="";

    this.productName="";
    this.productContent="";
    this.status=1;
    
  }

  SelectBaseProduct(id: string, productname: string): void {
    this.plProductid = "";
    this.baseProductid = id;
    this.productName = productname;
    this.productContent = "";
    if (id == "0") {
      this.customcontent = 1;
    }
    else {
      this.customcontent = 0;
    }
    
    this.status = 1;
    this.ClearDivs();
    this.ShowEdit = true;
    this.CleanInput();

  }

  onRowSelect(id: string) {
    alert(id)
    this.plProductid = id;

  }

  NewProduct(): void {
    this.ClearDivs();
    this.OpenOverlay = true;
    this.ShowTopMenu = true;
    this.ShowStandard = true;
    this.action1 = "quote";
    this.action2 = "contact";
    this.button1 = "Quote Now";
    this.button2 = "Contact Us";
    this.baseProductid = "1";
  }

  SaveProduct(): void {

    if (!this.productName) {
      Swal.fire({
        title: 'Warning',
        text: "Product Name is Required",
        icon: 'warning',
      })
      return;
    }

    if (!this.productContent) {
      Swal.fire({
        title: 'Warning',
        text: "Product Content is Required",
        icon: 'warning',
      })
      return;
    }

    var B1required: boolean = false;
    var B2required: boolean = false;
    var B3required: boolean = false;
    var B4required: boolean = false;

    if (this.action1 != "") { B1required = true }
    if (this.action2 != "") { B1required = true }
    if (this.action3 != "") { B1required = true }
    if (this.action4 != "") { B1required = true }

    if (B1required) {
      if (!this.button1) { Swal.fire({ title: 'Warning', text: 'Enter text for the Action 1 button', icon: 'warning', }); return; }
      if (this.action1 == "email" || this.action1 == "url") {
        if (!this.destination1) { Swal.fire({ title: 'Warning', text: 'Enter destination for Action 1', icon: 'warning', }); return; }
      }
    }

    if (B2required) {
      if (!this.button2) { Swal.fire({ title: 'Warning', text: 'Enter text for the Action 2 button', icon: 'warning', }); return; }
      if (this.action2 == "email" || this.action2 == "url") {
        if (!this.destination2) { Swal.fire({ title: 'Warning', text: 'Enter destination for Action 2', icon: 'warning', }); return; }
      }
    }

    if (B3required) {
      if (!this.button3) { Swal.fire({ title: 'Warning', text: 'Enter text for the Action 3 button', icon: 'warning', }); return; }
      if (this.action3 == "email" || this.action3 == "url") {
        if (!this.destination3) { Swal.fire({ title: 'Warning', text: 'Enter destination for Action 3', icon: 'warning', }); return; }
      }
    }

    if (B4required) {
      if (!this.button4) { Swal.fire({ title: 'Warning', text: 'Enter text for the Action 4 button', icon: 'warning', }); return; }
      if (this.action4 == "email" || this.action4 == "url") {
        if (!this.destination4) { Swal.fire({ title: 'Warning', text: 'Enter destination for Action 4', icon: 'warning', }); return; }
      }
    }

    

    var APIURL = sessionStorage.getItem('endpoint') + "website/setProduct.aspx";
    var options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    var body = new URLSearchParams();

    body.set('userid', encodeURIComponent(sessionStorage.getItem('userid')));
    body.set('pl', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('plkey', encodeURIComponent(sessionStorage.getItem('plkey')));
    //API Specifics
    body.set('plProductid', encodeURIComponent(this.plProductid));
    body.set('productid', encodeURIComponent(this.baseProductid));
    body.set('productname', encodeURIComponent(this.productName));
    body.set('productcontent', encodeURIComponent(this.productContent));
    body.set('iscustomcontent', encodeURIComponent(this.customcontent));
    body.set('sortorder', "0");
    body.set('Status', encodeURIComponent(this.status));

    body.set('action1', encodeURIComponent(this.action1));
    body.set('button1', encodeURIComponent(this.button1));
    body.set('destination1', encodeURIComponent(this.destination1));

    body.set('action2', encodeURIComponent(this.action2));
    body.set('button2', encodeURIComponent(this.button2));
    body.set('destination2', encodeURIComponent(this.destination2));


    body.set('action3', encodeURIComponent(this.action3));
    body.set('button3', encodeURIComponent(this.button3));
    body.set('destination3', encodeURIComponent(this.destination3));

    body.set('action4', encodeURIComponent(this.action1));
    body.set('button4', encodeURIComponent(this.button1));
    body.set('destination4', encodeURIComponent(this.destination4));

  

    
    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.APIresult = Array.of(result);
        if (this.APIresult[0].success == "1") {
          Swal.fire({
            title: 'Product Updated',
            text: this.APIresult[0].message,
            icon: 'success',
          })
          this.ClearDivs();
          this.OpenOverlay=false;
          this.LoadProducts();
          return;
        }
        if (this.APIresult[0].success == "0") {
          Swal.fire({
            title: 'Warning',
            text: this.APIresult[0].message,
            icon: 'warning',
          })
          return;
        }
        if (this.APIresult[0].success == "99") {
          Swal.fire({
            title: 'Warning',
            text: this.APIresult[0].message,
            icon: 'error',
          })
          return;
        }

      },
      error => {
        Swal.fire({
          title: 'Warning',
          text: this.APIresult[0].message,
          icon: 'error',
        })
        return;
      });


  }


  ngOnInit(): void {
  }

}
