<div>
    <br>

    <div class="h2">
        <div>Basic + Voluntary Term Life</div>
    </div>
    <hr>


    <br><br>
    <div class="row">
        <div class="col-md-3 text-center">
            <img src="assets/carriers/standardlogo.png " style="width: 150px; margin-top: 30px; margin-bottom: 60px" />
        </div>
        <div class="col-md-9">
            The Base Group Term Life insurance from Standard Insurance Company is designed to help provide financial
            support and stability for your family upon your death. This coverage is available to all eligible and
            approved AFEUSA members. This plan has a 6-month waiting period, meaning coverage for death during the
            waiting period is not eligible to be covered; Members will be eligible for benefits after the waiting period
            is completed. The waiting period is completed on the first of the month following 6 consecutive months of
            becoming a Member and purchasing a Group Term Life plan.
        </div>
    </div>
    <br><br>


    <div class="table-responsive">
        <table class="table">

            <tr class="bg-light  text-center">
                <td style="width:50%"></td>
                <td style="width:25%;">Basic Term Life</td>
                <td style="width:25%;">
                    Basic + Voluntary Term Life
                </td>
            </tr>
            <tr class="  text-center">
                <td class="text-left myblue">Coverage Amount</td>
                <td>${{matrix.plan25coverage}}</td>
                <td>${{matrix.plan75coverage}}</td>
            </tr>
            <tr class="  text-center">
                <td class="text-left myblue">Monthly Premium</td>
                <td>${{matrix.plan25premium}}</td>
                <td>${{matrix.plan75premium}}</td>
            </tr>

            <!--
            <tr class="text-center">
                <td></td>
                <td><a [ngClass]="{'btn-success': SelectedPlan === '25'}" (click)="SetPlan('25', matrix.plan25premium, 'Basic Term Life')"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{matrix.plan25premium}}<br><small>Click to Add
                            to Cart</small></a></td>
                <td><a [ngClass]="{'btn-success': SelectedPlan === '75'}" (click)="SetPlan('75', matrix.plan75premium, 'Basic + Voluntary Term Life' )"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{matrix.plan75premium}}<br><small>Click to Add
                            to Cart</small></a></td>
            </tr>
-->

            <tr class="text-center">
                <td></td>
                <td><a [ngClass]="{'btn-success': SelectedPlan === '25'}" (click)="Click25()"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{matrix.plan25premium}}<br><small>Click
                            to Add
                            to Cart</small></a></td>
                <td><a [ngClass]="{'btn-success': SelectedPlan === '75'}" (click)="Click75()"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{matrix.plan75premium}}<br><small>Click
                            to Add
                            to Cart</small></a></td>
            </tr>

        </table>
    </div>

</div>


<br><br>




<!--*******************************-->
<!--*******************************-->
<!--*******************************-->
<!--LIGHTBOX 25.000-->
<!--*******************************-->
<!--*******************************-->
<!--*******************************-->
<p-dialog [(visible)]="LightBox" modal="true" [draggable]="false" [closeOnEscape]="true" [responsive]="true"
    [style]="{width: '80%'}" [baseZIndex]="100">

    <p-header style="width:100%">
        <h3>Basic Term Life</h3>
        <p>Benefits and plans are offered by Dylan Consulting, LLC, a licensed independent insurance agency, and any of its subagents, and underwritten by licensed insurance companies and registered associations. Not all products are available in all states.

        </p>
    </p-header>
    <div style="overflow-y: scroll; overflow-x: scroll; height:40vh; width: 100%;">
        <div class="table-responsive" style="font-size:14px;">
            <table class="table">
                <tr class="bg-secondary text-white">
                    <td style="width:20%">ELIGIBILITY WAITING
                        PERIOD
                        </td>
                    <td style="width:80%">
                        <strong>LIFE INSURANCE:</strong> YOU ARE ELIGIBLE ON THE FIRST DAY OF THE CALENDAR MONTH FOLLOWING SIX (6) CONSECUTIVE MONTHS AS A MEMBER.
                        <br>
                        <strong>AD&D:</strong> YOU ARE ELIGIBLE ON THE FIRST DAY OF THE FOLLOWING MONTH THAT YOU BECOME AN ACTIVE MEMBER.

                    </td>
                </tr>
                <tr>
                    <td style="width:20%">Maximum Coverage Amount Ages 18-60</td>
                    <td style="width:80%">
                        $25,000
                    </td>
                </tr>
                <tr>
                    <td>Maximum Coverage Amount Ages 65-69</td>
                    <td>
                        $16,250
                    </td>
                </tr>
                <tr>
                    <td>Maximum Coverage Amount Ages 70-74</td>
                    <td>
                        $12,500
                    </td>
                </tr>
                <tr >
                    <td>Maximum Coverage Amount Ages 75+</td>
                    <td>
                        $8,750
                    </td>
                </tr>
                
            </table>
        </div>

    </div>
    <p-footer>
        <div class="text-right">
            <button (click)="SetPlan('25', matrix.plan25premium, 'Basic Term Life')" class="btn btn-danger">+ Add to
                Cart (${{matrix.plan25premium}} per month)</button>
        </div>
    </p-footer>
</p-dialog>





<!--*******************************-->
<!--*******************************-->
<!--*******************************-->
<!--LIGHTBOX 25.000-->
<!--*******************************-->
<!--*******************************-->
<!--*******************************-->
<p-dialog [(visible)]="LightBox2" modal="true" [draggable]="false" [closeOnEscape]="true" [responsive]="true"
    [style]="{width: '80%'}" [baseZIndex]="100">

    <p-header style="width:100%">
        <h3>Basic + Voluntary Term Life</h3>
        <p>Benefits and plans are offered by Dylan Consulting, LLC, a licensed independent insurance agency, and any of its subagents, and underwritten by licensed insurance companies and registered associations. Not all products are available in all states.

        </p>
    </p-header>
    <div style="overflow-y: scroll; overflow-x: scroll; height:40vh; width: 100%;">
        <div class="table-responsive" style="font-size:14px;">
            <table class="table">
                <tr class="bg-secondary text-white">
                    <td style="width:20%">ELIGIBILITY WAITING
                        PERIOD                        
                        </td>
                    <td style="width:30%">
                        <strong>LIFE INSURANCE:</strong> YOU ARE ELIGIBLE ON THE FIRST DAY OF THE CALENDAR MONTH FOLLOWING SIX (6) CONSECUTIVE MONTHS AS A MEMBER.
                        <br>
                        <strong>AD&D:</strong> YOU ARE ELIGIBLE ON THE FIRST DAY OF THE FOLLOWING MONTH THAT YOU BECOME AN ACTIVE MEMBER.

                    </td>
                    <td>Voluntary Term Life Insurance Monthly Rate</td>
                    <td>Basic Term Life Insurance Benefit Amount</td>
                    <td>Basic Term Life Insurance Monthly Rate</td>
                    <td>Total Benefit Amount</td>
                    <td>Total Monthly Rate </td>
                </tr>
                <tr>
                    <td >Maximum Coverage Amount Ages 18-34</td>
                    <td >$50,000</td>
                    <td>$12.42</td>
                    <td>$25,000</td>
                    <td>$37.18</td>
                    <td>$75,000</td>
                    <td>$49.60</td>
                </tr>
                <tr>
                    <td >Maximum Coverage Amount Ages 35-39</td>
                    <td >$50,000</td>
                    <td>$13.86</td>
                    <td>$25,000</td>
                    <td>$37.18</td>
                    <td>$75,000</td>
                    <td>$51.04</td>
                </tr>
                <tr>
                    <td >Maximum Coverage Amount Ages 40-44</td>
                    <td >$50,000</td>
                    <td>$21.03</td>
                    <td>$25,000</td>
                    <td>$37.18</td>
                    <td>$75,000</td>
                    <td>$58.21</td>
                </tr>
                <tr>
                    <td >Maximum Coverage Amount Ages 45-49</td>
                    <td >$50,000</td>
                    <td>$28.21</td>
                    <td>$25,000</td>
                    <td>$37.18</td>
                    <td>$75,000</td>
                    <td>$65.39</td>
                </tr>

                <tr>
                    <td >Maximum Coverage Amount Ages 50-54</td>
                    <td >$50,000</td>
                    <td>$42.55</td>
                    <td>$25,000</td>
                    <td>$37.18</td>
                    <td>$75,000</td>
                    <td>$79.73</td>
                </tr>

                <tr>
                    <td >Maximum Coverage Amount Ages 55-59</td>
                    <td >$50,000</td>
                    <td>$78.42</td>
                    <td>$25,000</td>
                    <td>$37.18</td>
                    <td>$75,000</td>
                    <td>$115.60</td>
                </tr>

                <tr>
                    <td >Maximum Coverage Amount Ages 60-64</td>
                    <td >$50,000</td>
                    <td>$157.34</td>
                    <td>$25,000</td>
                    <td>$37.18</td>
                    <td>$75,000</td>
                    <td>$194.52</td>
                </tr>

                <tr>
                    <td >Maximum Coverage Amount Ages 65-69</td>
                    <td >$48,750</td>
                    <td>$315.17</td>
                    <td>$16,250</td>
                    <td>$37.18</td>
                    <td>$65,000</td>
                    <td>$352.35</td>
                </tr>

                <tr>
                    <td >Maximum Coverage Amount Ages 70-74</td>
                    <td >$37,500</td>
                    <td>$587.82</td>
                    <td>$12,500</td>
                    <td>$37.18</td>
                    <td>$50,000</td>
                    <td>$625.00</td>
                </tr>

                <tr>
                    <td >Maximum Coverage Amount Ages 75+</td>
                    <td >$26,250</td>
                    <td>$1,649.55</td>
                    <td>$8,750</td>
                    <td>$37.18</td>
                    <td>$35,000</td>
                    <td>$1,686.73</td>
                </tr>
                
                
                
            </table>
        </div>

    </div>
    <p-footer>
        <div class="text-right">
            <button (click)="SetPlan('75', matrix.plan75premium, 'Basic + Voluntary Term Life' )" class="btn btn-danger">+ Add to
                Cart (${{matrix.plan75premium}} per month)</button>
        </div>
    </p-footer>
</p-dialog>