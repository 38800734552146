import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-customlist',
  templateUrl: './customlist.component.html',
  styleUrls: ['./customlist.component.css']
})
export class CustomlistComponent implements OnInit {
  APIresult: any;
CodesList: any;
  
  constructor(
    public _httpClient: HttpClient,
    private _router: Router,
    private r: ActivatedRoute,
  ) {


    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/getcodeslist.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));

    this._httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.APIresult = Array.of(result);
        if (result == null) {
       
        }
        else {
          if (this.APIresult[0].success == "1") {
            this.CodesList = this.APIresult[0].codes;
            return;
          }
         
        }

      },
      error => {

      });

  }


  onRowSelect(code): void {
    
    sessionStorage.setItem("temp", code);
    this._router.navigate(['../customedit'], { relativeTo: this.r });
  }

  DeleteCode(code): void {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this operation",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
       
        var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/deletecode.aspx";    // set header for http call
        let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
        // set body
        let body = new URLSearchParams();
        body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
        body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
        body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
        body.set('code', encodeURIComponent(code));
    
        this._httpClient.post(APIURL, body.toString(), options).subscribe(
          result => {
            this.APIresult = Array.of(result);
            if (result == null) {
           
            }
            else {
              if (this.APIresult[0].success == "1") {
                this.CodesList = this.APIresult[0].codes;
                return;
              }
             
            }
    
          },
          error => {
    
          });
    
      }
    })
    
  }
  ngOnInit(): void {
  }

}
