import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.css']
})
export class IntroComponent implements OnInit {
  accstatus: string;
  username: string;
  version: string;
  application: boolean=false;
  application2: boolean=false;
  constructor() {
    this.accstatus=sessionStorage.getItem("status");
    this.username=sessionStorage.getItem("firstname");
    this.version=sessionStorage.getItem("version");

    let selections = sessionStorage.getItem("selections")
    if (selections) {
      this.application=selections.includes("rvN8MtrKYJ5RrXAHLWZu2A==")
      this.application2=selections.includes("E1aGAqwxBH0RFQjdnkITNg==")

    }

   }

  ngOnInit(): void {
  }

}
