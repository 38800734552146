import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-p5',
  templateUrl: './p5.component.html',
  styleUrls: ['./p5.component.css']
})
export class P5Component implements OnInit {
  @Output("Calculate") Calculate: EventEmitter<any> = new EventEmitter();
  @Input() Data: any;
  public matrix: any;
  public selection: any;

  public LightBox: boolean = false;
  public PlanName: string = "";
  public AnnualDeductible: string = "";
  public MaximumBenefit: string = "";
  public Preventive: string = "";
  public BasicServices: string = "";
  public Fee: number = 0;


  public SelectedCol: number = 0;
  public SelectedRow: number = 0;
  public SelectedAmount: number = 0
  public SelectedName: string = "";
  public SelectedRowCol: string = "";

  constructor(
    private toastr: ToastrService
  ) {



  }

  ngOnInit(): void {
    this.matrix = this.Data.critical;
    this.selection = this.Data.selections.critical;
    this.SelectedRowCol = this.Data.selections.critical.row.toString() + this.Data.selections.critical.col.toString()

  }

  View(row, col, amount, name): void {
    this.SelectedCol = col;
    this.SelectedRow = row;
    this.SelectedAmount = amount;
    this.SelectedName = name

  }

  SetPlan(): void {
    this.SelectedRowCol = this.SelectedRow.toString() + this.SelectedCol.toString()
    this.Data.selections.critical.selection = this.SelectedRow.toString() + this.SelectedCol.toString()
    this.Data.selections.critical.amount = +this.SelectedAmount;
    this.Data.selections.critical.col = this.SelectedCol;
    this.Data.selections.critical.row = this.SelectedRow;
    this.Data.selections.critical.name = this.SelectedName;
    this.toastr.success('Plan added to your cart!', 'Plan Added');
    this.Calculate.emit();
    this.LightBox = false;
  }

}
