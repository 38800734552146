<h4>Site Management</h4>
<p>
  This section allows to setup AFEUSA website specifications and operations.
</p>
<br>
<nav class="navbar navbar-expand-lg navbar-light" style="background-color: #000;  margin-right: -20px; margin-left: -20px">
  
    <button class="navbar-toggler" type="button" (click)="toggleNavbar2()">
          <span class="navbar-toggler-icon"></span>
        </button>


    <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen2 }">
        <ul class="navbar-nav mr-auto">

            <li class="nav-item">
                <a class="nav-link" routerLink="emailmanagement" routerLinkActive="btn btn-sm btn-dark">Email Management</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="websitemanagement" routerLinkActive="btn btn-sm btn-dark">Training Tab</a>
            </li>
            <!--
            <li class="nav-item">
                <a class="nav-link" routerLink="administratorsmanagement" routerLinkActive="btn btn-sm btn-dark">AFEUSA Administrators</a>
            </li>
          -->
          
            

        </ul>
    </div>
</nav>
<br /><br />
<router-outlet></router-outlet>
