<h4>Platform Users</h4>
<hr>
<div class="w100">

    <button routerLink="listusers" routerLinkActive="btn-dark text-white" class="btn btn-outline-dark " style="margin-right: 10px;" ><i class="fa fa-user"></i>
        List Users</button>
    <button routerLink="newuser" routerLinkActive="btn-dark text-white" class="btn btn-outline-dark " style="margin-right: 10px;" ><i class="fa fa-user"></i>
        New User</button>
    <br><br><br>
    <router-outlet></router-outlet>

   
    <br><br>
   
</div>