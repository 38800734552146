import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
import Swal from 'sweetalert2';
import { AngularEditorConfig } from '@kolkov/angular-editor';


@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})
export class LogsComponent implements OnInit {
  userName: string="";
  userid: string;
  Users: any;
  payload: any;

  public LogActions: any;
  logfrom: string;
  logto: string;
  logaction: string;
  logactionby: string;
  public ShowSponsor: boolean = false;
  logHeaders: any;
  logRecords: any;

  logFullName: string;
  logDate: string;
  LogActionName: string;
  logIP: string;
  logTitle: string;
  logDetails: string;

  showLog: boolean = false;
  logDescription: string;

  editorConfig: AngularEditorConfig = {
    editable: false,
    spellcheck: false,
    height: '25rem',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: false,
    placeholder: '',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize'],
      ['insertImage'],
      ['insertVideo'],
    ]
  };

  constructor(
    public httpClient: HttpClient,
    private _router: Router,
    private r: ActivatedRoute,
  ) {
    this.r.params.subscribe((params: Params) => {
      this.userid = params.id;
      this.ViewLog();
    });


  }



  ViewLog(): void {
    var logPayload = {
      "logfrom": "",
      "logto": "",
      "logaction": "0",
      "logactionby": "1"
    }

    if (this.logfrom) { logPayload.logfrom = this.logfrom }
    if (this.logto) { logPayload.logto = this.logto }
    if (this.logaction) { logPayload.logaction = this.logaction }
    if (this.logactionby) { logPayload.logactionby = this.logactionby }

    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/userslogs.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('payload', JSON.stringify(logPayload));
    body.set('pl', encodeURIComponent(sessionStorage.getItem('temp')));
    body.set('userid', encodeURIComponent(this.userid));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        var ApiResponse: any = result;
        if (result == null) {
          Swal.fire('API ERROR', ApiResponse.message, 'error')
          this.ShowSponsor = false;
          this.logHeaders = undefined;
          this.logRecords = undefined;
          return;
        }
        else {
          if (ApiResponse.success == "1") {
            this.logHeaders = ApiResponse.fields;
            this.logRecords = ApiResponse.dataset;

            return;
          }

          if (ApiResponse.success == "99") {
            Swal.fire('No records found', ApiResponse.message, 'warning')
            this.ShowSponsor = false;
            this.logHeaders = undefined;
            this.logRecords = undefined;
            return;
          }

          if (ApiResponse.success == "0") {
            Swal.fire('API ERROR', ApiResponse.message, 'error')
            this.ShowSponsor = false;
            this.logHeaders = undefined;
            this.logRecords = undefined;
            return;
          }

        }

      },
      error => {

      });


  }


  OpenLog(id): void {

    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/usersgetlogdetails.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('pl', encodeURIComponent(sessionStorage.getItem('temp')));
    body.set('id', id);

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        var ApiResponse: any = result;
        if (result == null) {
          Swal.fire('API ERROR', ApiResponse.message, 'error')
          this.ShowSponsor = false;
          this.logHeaders = undefined;
          this.logRecords = undefined;
          return;
        }
        else {
          if (ApiResponse.success == "1") {
            if (ApiResponse.lightboxtype == "1") {
              //this shows lightbox only for actions < 100 coming from the Actions Table
              this.logFullName = ApiResponse.firstname + ' ' + ApiResponse.lastname;
              this.logDate = ApiResponse.datetime;
              this.LogActionName = ApiResponse.ActionName;
              this.logIP = ApiResponse.ipaddress;
              this.logTitle = ApiResponse.actiontitle;
              this.logDetails = ApiResponse.description;
              this.showLog = true;
            }

            return;
          }

          if (ApiResponse.success == "99") {
            Swal.fire('No records found', ApiResponse.message, 'warning')
            this.ShowSponsor = false;
            this.logHeaders = undefined;
            this.logRecords = undefined;
            return;
          }

          if (ApiResponse.success == "0") {
            Swal.fire('API ERROR', ApiResponse.message, 'error')
            this.ShowSponsor = false;
            this.logHeaders = undefined;
            this.logRecords = undefined;
            return;
          }

        }

      },
      error => {

      });



  }

  ngOnInit(): void {
  }

}
