<h4>
    Members</h4>
<p>Search for member(s) and then click on a record for full details.</p>
<hr>
<h5>Search</h5>
<p>You can utilize only one search criteria, or a combination of criterias below:</p>
<hr>
<div style="padding: 10px 10px 10px 10px; background-color: #f1f1f1;">
    <div class="row">
        <div class="col-12 col-md-6 col-lg-3 col-xl-2">
            Reference ID<br>
            <input class="form-control" maxlength="50" [(ngModel)]="referenceId">
        </div>
        <div class="col-12 col-md-6 col-lg-3 col-xl-2">
            First Name<br>
            <input class="form-control" maxlength="50" [(ngModel)]="firstName">
        </div>
        <div class="col-12 col-md-6 col-lg-3 col-xl-2">
            Last Name<br>
            <input class="form-control" maxlength="50" [(ngModel)]="lastName">
        </div>
        <div class="col-12 col-md-6 col-lg-3 col-xl-2">
            Email Address<br>
            <input class="form-control" maxlength="50" [(ngModel)]="email">
        </div>
        <div class="col-12 col-md-6 col-lg-3 col-xl-2">
            SSN<br>
            <input class="form-control" maxlength="50" [(ngModel)]="ssn">
        </div>
        <div class="col-12 col-md-6 col-lg-3 col-xl-2">
            HCI Project ID<br>
            <input class="form-control" maxlength="50" [(ngModel)]="projectId">
        </div>
    </div>
    <br>
    <button class="btn btn-primary btn-sm" (click)="searchMembers()">Search Member(s)</button>
</div>
<br>




<div class="minheight" *ngIf="ProductsList">
    <p-table #dt [value]="ProductsList" [rows]="25" [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[25,50,200,1000]" styleClass="p-datatable-customers" [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" [filterDelay]="0"
        [globalFilterFields]="['referenceid','firstname','lastname','gender','email','phone','products']"
        [resizableColumns]="true" [autoLayout]="true" selectionMode="single">

        <ng-template pTemplate="header">
            <tr class="FontRow f13">
                <th [pSortableColumn]="'referenceid'" class="smallfont">Reference ID</th>
                <th [pSortableColumn]="'firstname'" class="smallfont">First Name</th>
                <th [pSortableColumn]="'lastname'">Last Name</th>
                <th [pSortableColumn]="'gender'">Gender</th>
                <th [pSortableColumn]="'email'">Email</th>
                <th [pSortableColumn]="'phone'">Phone</th>
                <th [pSortableColumn]="'products'">Products</th>
                <th></th>
            </tr>
            <tr>
                <th>
                    <input class="form-control" pInputText type="text"
                        (input)="dt.filter($event.target.value, 'referenceid', 'contains')" placeholder="">
                </th>
                <th>
                    <input class="form-control" pInputText type="text"
                        (input)="dt.filter($event.target.value, 'firstname', 'contains')" placeholder="">
                </th>
                <th>
                    <input class="form-control" pInputText type="text"
                        (input)="dt.filter($event.target.value, 'lastname', 'contains')" placeholder="">
                </th>

                <th>
                    <select class="form-control" (input)="dt.filter($event.target.value, 'gender', 'contains')">
                        <option value="">List All</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                    </select>
                </th>
                <th>
                    <input class="form-control" pInputText type="text"
                        (input)="dt.filter($event.target.value, 'email', 'contains')" placeholder="">
                </th>
                <th>
                    <input class="form-control" pInputText type="text"
                        (input)="dt.filter($event.target.value, 'phone', 'contains')" placeholder="">
                </th>
                <th>
                    <input class="form-control" pInputText type="text"
                        (input)="dt.filter($event.target.value, 'products', 'contains')" placeholder="">
                </th>
                <th></th>

            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-ProductsList>
            <tr class="FontRow f13" [ngStyle]="{
                backgroundColor: ProductsList.status == 99 ? '#FFE0E0' : 'transparent'
                }">
                <td class="SmallFont">{{ProductsList.referenceid}}</td>
                <td class="SmallFont">{{ProductsList.firstname}}</td>
                <td class="SmallFont">{{ProductsList.lastname}}</td>
                <td class="SmallFont">{{ProductsList.gender}}</td>
                <td class="SmallFont">{{ProductsList.email}}</td>
                <td class="SmallFont">{{ProductsList.phone}}</td>
                <td class="SmallFont">{{ProductsList.products}}</td>
                <th><button class="btn btn-primary btn-sm" (click)="onRowSelect(ProductsList.policyid)"> OPEN </button>
                </th>
            </tr>
        </ng-template>
    </p-table>
</div>


<!--LIGHTBOX LEAD-->
<div id="overlay" *ngIf="openSide">
    <div id="overlay2" style="overflow: auto">
        <div class="text-right">
            <button class="btn btn-sm btn-danger" (click)="openSide= false">
                X
            </button>
        </div>

        <br />
        <h4>
            {{ memberData.EmpDemographics_FirstName }}
            {{ memberData.EmpDemographics_LastName }}
            -
            <span class="text-info">
                Customer Reference ID: {{ memberData.ReferenceID }}</span>
        </h4>
        <div class="alert alert-success" style="margin-top: 20px;" *ngIf="memberData.status == 1">This is an active
            membership.</div>
        <div class="alert alert-danger" style="margin-top: 20px;" *ngIf="memberData.status == 99">This membership is no
            longer active. You cannot perform any action for this member, except to visualize the account information.
        </div>
        <hr />
        <div class="row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="bShadow-5">
                    <div style="height: 270px">
                        <h6>About This Member</h6>
                        <hr />
                        <table class="w100">
                            <tr class="spaceUnder">
                                <td class="text-info">Birth Date:</td>
                                <td class="text-black">
                                    {{ memberData.EmpDemographics_BirthDate }}
                                </td>
                            </tr>
                            <tr class="spaceUnder">
                                <td class="text-info">Gender:</td>
                                <td class="text-black">
                                    {{ memberData.EmpDemographics_Gender }}
                                </td>
                            </tr>
                            <tr class="spaceUnder">
                                <td class="text-info">Address:</td>
                                <td class="text-black">
                                    {{ memberData.EmpDemographics_Address1 }}
                                </td>
                            </tr>
                            <tr class="spaceUnder">
                                <td class="text-info">City:</td>
                                <td class="text-black">
                                    {{ memberData.EmpDemographics_City }}
                                </td>
                            </tr>
                            <tr class="spaceUnder">
                                <td class="text-info">State:</td>
                                <td class="text-black">
                                    {{ memberData.EmpDemographics_State_Name }}
                                </td>
                            </tr>
                            <tr class="spaceUnder">
                                <td class="text-info">ZIP Code:</td>
                                <td class="text-black">
                                    {{ memberData.EmpDemographics_Zip }}
                                </td>
                            </tr>
                            <tr class="spaceUnder">
                                <td class="text-info">Phone Number:</td>
                                <td class="text-black">
                                    {{ memberData.EmpDemographics_Phone }}
                                </td>
                            </tr>
                            <tr class="spaceUnder">
                                <td class="text-info">Email Address:</td>
                                <td class="text-black">
                                    {{ memberData.EmpDemographics_Email }}
                                </td>
                            </tr>
                        </table>
                        <!--
                        <button *ngIf="memberData.status == 1" class="btn btn-danger btn-sm"
                            style="margin-top: 15px; margin-right: 10px;" (click)="cancelFullMembership()">Cancel
                            Membership</button>
                            -->
                    </div>
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="bShadow-5">
                    <div style="height: 270px">
                        <h6>References</h6>
                        <hr />
                        <table class="w100">
                            <tr class="spaceUnder">
                                <td class="text-info">Reference ID:</td>
                                <td class="text-black">
                                    {{ memberData.ReferenceID }}
                                </td>
                            </tr>
                            <tr class="spaceUnder">
                                <td class="text-info">Transaction Origin:</td>
                                <td class="text-black">
                                    {{ memberData.Origin }}
                                </td>
                            </tr>
                            <tr class="spaceUnder">
                                <td class="text-info">Transaction Date:</td>
                                <td class="text-black">
                                    {{ memberData.Date }}
                                </td>
                            </tr>
                            <tr class="spaceUnder">
                                <td class="text-info">Project ID:</td>
                                <td class="text-black">
                                    {{ memberData.projectid }}
                                </td>
                            </tr>
                            <tr class="spaceUnder">
                                <td class="text-info">Policy ID:</td>
                                <td class="text-black">
                                    {{ memberData.policyid }}
                                </td>
                            </tr>
                        </table>

                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="row">
                    <div class="col-12">
                        <div class="alert alert-info">
                            <div style="min-height: 50px">
                                <span style="font-size: 28px">{{
                    APIresults.moneyrelated.products
                    }}</span>
                                <br />
                                PRODUCTS
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="alert alert-info">
                            <div style="min-height: 50px">
                                <span
                                    style="font-size: 28px">${{ APIresults.moneyrelated.amount | number:'1.2-2'}}</span>
                                <br />
                                PREMIUM
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="alert alert-info">
                            <div style="min-height: 50px">
                                <span style="font-size: 28px">${{
                    APIresults.moneyrelated.commission| number:'1.2-2' }}</span>
                                <br />
                                GENERATED COMMISSION
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="bShadow-5" *ngIf="memberData.status == 1">
            <h6>Corporate Features</h6>
            <hr />
            <button class="btn btn-primary btn-sm marginleft" (click)="recodeAgent()">Recode Agent</button>
            <!--
            <button class="btn btn-primary btn-sm marginleft">Update Member Information</button>
           
            <button class="btn btn-sm btn-danger marginleft">Delete Member</button>
             -->
        </div>
        <br />
        <div class="bShadow-5">
            <div style="min-height: 250px">
                <h6>Products Acquired</h6>
                <hr />
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-12 col-lg-6 "
                                *ngFor="let product of APIresults.products">
                                <div class="text-left text-white productbox mousepointer"
                                    style="background-color: #012a4f; padding: 15px; height: 170px;"
                                    (click)="PlanDetails(product.ProductId,product.planname, product.fee)">
                                    <br>
                                    <strong>{{ product.planname }}</strong>
                                    <hr style="border-top: 1px solid #a0cade;" />
                                    <div class="row">
                                        <div class="col-12">
                                            <small> Monthly Fee </small><br />
                                            <span style="font-size: 26px">${{ product.fee | number:'1.2-2' }}</span>
                                            <div class="link text-right" style="color: #eaeaea;">Click for Details >>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-6" *ngIf="selectedPlanName">
                        <div class="text-left text-white productbox"
                            style="background-color: #3e669c; padding: 15px; height: 170px;">
                            <br />
                            <div class="row">
                                <div class="col-8">
                                    <h4 class="text-white">{{ selectedPlanName }}</h4>
                                    <strong style="color: white">SELECTED TIER: {{ selectedTierCode }}</strong>
                                </div>
                                <div class="col-4 text-right">
                                    <!--
                                    <button class="btn btn-sm btn-danger"
                                        *ngIf="selectedPlanId !='11423' && memberData.status=='1'"
                                        (click)="cancelPlan(selectedPlanId)">Cancel This Plan</button>
                                        -->
                                </div>
                            </div>


                            <hr />
                            <div style="font-size: 18px;">Product billed on the 20th of each month. Premium:
                                ${{selectedFee | number:'1.2-2'}}</div>
                        </div>
                        <br>
                        <!--dependents-->
                        <table class="table table-bordered w100" *ngIf="dependents">
                            <tr style="background-color: #012a4f">
                                <td colspan="2">
                                    <strong class="text-white">Dependent Full Name</strong>
                                </td>
                                <td><strong class="text-white">Relationship</strong></td>
                                <td colspan="2"><strong class="text-white">Date of Birth</strong></td>

                            </tr>
                            <tr *ngFor="let dependent of
                    dependents.POLICIES_DependentDemographics" style="background-color: white;">
                                <td style="width: 30px">
                                    <img src="assets/images/icouser.png" style="margin-right: 5px" height="15px" />
                                </td>
                                <td>{{ dependent.FirstName }} {{ dependent.LastName }}</td>
                                <td>{{ dependent.Relationship }}</td>
                                <td>{{ dependent.BirthDate }}</td>
                                <td class="text-center">
                                    <button class="btn btn-sm btn-primary"
                                        (click)="DependentDetails(selectedProductId, dependent.PersonCode)">
                                        <i class="fa fa-search"></i>
                                    </button>
                                </td>
                            </tr>
                        </table>
                        <!--beneficiaries-->
                        <table class="table table-bordered w100" *ngIf="beneficiaries">
                            <tr style="background-color: #012a4f">
                                <td colspan="2">
                                    <strong class="text-white">Beneficiary Full Name</strong>
                                </td>
                                <td><strong class="text-white">Type</strong></td>
                                <td colspan="2"><strong class="text-white">% Benefit</strong></td>

                            </tr>
                            <tr *ngFor="let beneficiary of beneficiaries"
                                [ngStyle]="{backgroundColor: beneficiary.BType == 'P' ? '#ffffff' : '#f5f5f5'}">
                                <td style="width: 30px">
                                    <img src="assets/images/icouser.png" style="margin-right: 5px" height="15px" />
                                </td>
                                <td>{{ beneficiary.FirstName }} {{ beneficiary.LastName }}</td>
                                <td><span
                                        [ngClass]="(beneficiary.BType == 'P'?'text-success':'text-danger')">{{ beneficiary.type }}</span>
                                </td>
                                <td>{{ beneficiary.Percentage }}%</td>
                                <td class="text-center">
                                    <button class="btn btn-sm btn-primary"
                                        (click)="BeneficiaryDetails(beneficiary.BeneficiaryId)">
                                        <i class="fa fa-search"></i>
                                    </button>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 text-center" *ngIf="!selectedPlanName">
                        <div style="font-size: 20px; color:#c5c5c5; padding-top: 25px;">
                            <i class="fa fa-arrow-left" style="font-size: 50px;"></i>
                            <br>
                            Click on a Product for Details
                        </div>
                    </div>



                </div>
                <br /><br />
            </div>
        </div>

        <!--AGENT INFORMATION-->


        <div *ngIf="APIresults.agency == '1'">
            <br /><br />
            <h4>Agent Information</h4>
            <hr />
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                    <div class="bShadow-5 backwhite">
                        <div style="min-height: 240px">
                            <h6>Agent</h6>
                            <hr />
                            <table class="w100">
                                <tr class="spaceUnder">
                                    <td class="text-info">Agent ID:</td>
                                    <td class="text-black">
                                        {{ agentinfo.plid }}
                                    </td>
                                </tr>
                                <tr class="spaceUnder">
                                    <td class="text-info">Full Name:</td>
                                    <td class="text-black">
                                        {{ agentinfo.firstname }}
                                        {{ agentinfo.middlename }}
                                        {{ agentinfo.lastname }}
                                    </td>
                                </tr>
                                <tr class="spaceUnder">
                                    <td class="text-info">Address:</td>
                                    <td class="text-black">
                                        {{ agentinfo.address }}
                                        {{ agentinfo.city }},
                                        {{ agentinfo.state }}
                                        {{ agentinfo.zip }}
                                    </td>
                                </tr>
                                <tr class="spaceUnder">
                                    <td class="text-info">Cell Phone:</td>
                                    <td class="text-black">
                                        {{ agentinfo.cellphone }}
                                    </td>
                                </tr>
                                <tr class="spaceUnder">
                                    <td class="text-info">Business Phone:</td>
                                    <td class="text-black">
                                        {{ agentinfo.businessphone }}
                                    </td>
                                </tr>
                                <tr class="spaceUnder">
                                    <td class="text-info">Fax Number:</td>
                                    <td class="text-black">
                                        {{ agentinfo.faxnumber }}
                                    </td>
                                </tr>
                                <tr class="spaceUnder">
                                    <td class="text-info">Email Address:</td>
                                    <td class="text-black">
                                        {{ agentinfo.email }}
                                    </td>
                                </tr>
                                <tr class="spaceUnder" *ngIf="AllowViewMembers">
                                    <td class="text-black" colspan="2">
                                        <br />
                                        <button class="btn btn-warning text-white" (click)="RevealPassword()"
                                            style="margin-right: 10px">
                                            Reveal Password
                                        </button>
                                        <button class="btn btn-warning text-white" (click)="ResendEmail()"
                                            *ngIf="status == 1 || status == 50">
                                            Resend Welcome Email
                                        </button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                    <div class="bShadow-5 backwhite">
                        <div style="min-height: 240px">
                            <h6>Miscellaneous</h6>
                            <hr />
                            <table class="w100">
                                <tr class="spaceUnder">
                                    <td class="text-info" style="width: 150px">Sponsor:</td>
                                    <td class="text-black">
                                        ({{ agentinfo.SponsorPL }}) -
                                        {{ agentinfo.SponsorFname }}
                                        {{ agentinfo.SponsorLname }}
                                    </td>
                                </tr>
                                <tr class="spaceUnder">
                                    <td class="text-info">Type of Business:</td>
                                    <td class="text-black">
                                        {{ agentinfo.licTypeOfBusiness }}
                                    </td>
                                </tr>
                                <tr class="spaceUnder">
                                    <td class="text-info">License:</td>
                                    <td class="text-black">
                                        {{ agentinfo.licTypeInsuranceLicense }}
                                    </td>
                                </tr>
                                <tr class="spaceUnder">
                                    <td class="text-info">Insurance #:</td>
                                    <td class="text-black">
                                        {{ agentinfo.licResidentInsuranceLicense }}
                                    </td>
                                </tr>
                                <tr class="spaceUnder">
                                    <td class="text-info">Resident State:</td>
                                    <td class="text-black">
                                        {{ agentinfo.licResidentState }}
                                    </td>
                                </tr>
                                <tr class="spaceUnder">
                                    <td class="text-info">NPN Number:</td>
                                    <td class="text-black">
                                        {{ agentinfo.licNPMnumber }}
                                    </td>
                                </tr>
                                <tr class="spaceUnder">
                                    <td class="text-info">E&O Coverage?</td>
                                    <td class="text-black">
                                        {{ agentinfo.eoCoverage }}
                                    </td>
                                </tr>
                                <tr class="spaceUnder">
                                    <td class="text-info">$ and Expiration:</td>
                                    <td class="text-black">
                                        ${{ FormatMoney(agentinfo.eoAmount) }} -
                                        {{ agentinfo.eoExp }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                    <div class="bShadow-5 backwhite">
                        <div style="min-height: 240px">
                            <h6>Products and Grid</h6>
                            <hr />
                            <table class="w100">
                                <tr class="spaceUnder">
                                    <td></td>
                                    <td class="text-title">Year 1</td>
                                    <td class="text-title">Year 2+</td>
                                </tr>
                                <tr class="spaceUnder" *ngFor="let product of agentproducts">
                                    <td class="text-info">{{ product.RealName }}</td>
                                    <td class="text-black">{{ product.Y1 }}%</td>
                                    <td class="text-black">{{ product.Y2 }}%</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <br /><br />

            <h4>Compensation</h4>
            <hr />
            <table class="table table-bordered w100 bShadow-5">
                <tr style="background-color: #012a4f">
                    <td>
                        <strong class="text-white">ID</strong>
                    </td>
                    <td><strong class="text-white">Agent Name</strong></td>
                    <td><strong class="text-white">Products Compensation</strong></td>
                    <td><strong class="text-white">Total by Agent</strong></td>
                </tr>
                <tr style="background-color: white;" *ngFor="let record of upline">
                    <td>
                        <div class="row">
                            <div class="col-10">
                                <h5>{{record.plid}}</h5>
                            </div>
                        </div>

                    </td>
                    <td>
                        <h5>{{record.AgentName}}</h5>
                        <br>


                    </td>
                    <td>
                        <table class="w100 table table-borderless">
                            <tr *ngFor="let compensation of record.compensation">
                                <td>{{compensation.product}}</td>
                                <td style="width:20%" class="text-right">{{compensation.grid}}%</td>
                                <td style="width:20%" class="text-right text-danger">
                                    <strong>${{compensation.commission | number:'1.2-2'}}</strong></td>
                            </tr>
                        </table>
                    </td>
                    <td class="text-center">
                        <br>
                        Total for {{record.AgentName}}
                        <br>
                        <span style="font-size: 30px;">${{record.totalCommission | number:'1.2-2'}}</span></td>
                </tr>
            </table>

        </div>
    </div>
</div>

<!--LIGHTBOX PERMISSIONS-->
<div id="overlay3" *ngIf="agentRecodeShow" style="background-color: #2C4360;">
    <div>
        <div class="row">
            <div class="col-md-10 text-left">
                <h4 class="text-white">Recode Agent</h4>
                <span class="text-white">This feature allows to recode a current member to a new hierarchy.</span>

            </div>
            <div class="col-md-2 text-right">
                <button class="btn btn-danger btn-sm" (click)="agentRecodeShow=false">X</button>
            </div>

        </div>

        <hr>
    </div>
    <div class="stuck">
        <br><br>
        <h5 class="text-white">Enter Recoding Information</h5>
        <hr>
        <div class="bShadow-5">
            <div>
                <h6>Current Sponsor</h6>
                <hr>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-3">
                        <label>Sponsor ID:</label><br>
                        <span class="form-control">
                            {{ agentinfo.plid }}
                        </span>
                    </div>

                    <div class="col-12 col-sm-6 col-md-3">
                        <label>Sponsor:</label><br>
                        <span class="form-control">
                            {{ agentinfo.firstname }}
                            {{ agentinfo.lastname }}
                        </span>
                    </div>
                </div>

            </div>
        </div>
        <br>
        <div class="bShadow-5">
            <div>
                <h6>New Sponsor</h6>
                <hr>
                <div class="row">
                    <div class="col-6 col-sm-4 col-md-2">
                        <label>Search Sponsor ID:</label><br>
                        <input type="text" class="form-control" [(ngModel)]="NewSponsorID"
                            (keypress)="keyPressNumbers($event)" maxlength="7">
                    </div>
                    <div class="col-6 col-sm-2 col-md-1 text-right">
                        <label><br></label><br>
                        <button class="btn btn-sm btn-primary" style="width: 100%" (click)="ViewDownline()">Search</button>
                    </div>

                    <div class="col-12 col-sm-6 col-md-9" *ngIf="ShowSponsor">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                                <div class="bShadow-5">
                                    <div style="min-height: 340px;">
                                        <h6>Sponsor Agent</h6>
                                        <hr>
                                        <table class="w100">

                                            <tr class="spaceUnder">
                                                <td class="text-info">
                                                    Agent ID:
                                                </td>
                                                <td class="text-black">
                                                    {{APISponsor.agentinfo.plid}}
                                                </td>
                                            </tr>
                                            <tr class="spaceUnder">
                                                <td class="text-info">
                                                    Full Name:
                                                </td>
                                                <td class="text-black">
                                                    {{APISponsor.agentinfo.firstname}}
                                                    {{APISponsor.agentinfo.lastname}}
                                                </td>
                                            </tr>
                                            <tr class="spaceUnder">
                                                <td class="text-info">
                                                    Address:
                                                </td>
                                                <td class="text-black">
                                                    {{APISponsor.agentinfo.address}}
                                                    {{APISponsor.agentinfo.city}},
                                                    {{APISponsor.agentinfo.state}} {{APISponsor.agentinfo.zip}}
                                                </td>
                                            </tr>
                                            <tr class="spaceUnder">
                                                <td class="text-info">
                                                    Cell Phone:
                                                </td>
                                                <td class="text-black">
                                                    {{APISponsor.agentinfo.cellphone}}
                                                </td>
                                            </tr>
                                            <tr class="spaceUnder">
                                                <td class="text-info">
                                                    Business Phone:
                                                </td>
                                                <td class="text-black">
                                                    {{APISponsor.agentinfo.businessphone}}
                                                </td>
                                            </tr>
                                            <tr class="spaceUnder">
                                                <td class="text-info">
                                                    Fax Number:
                                                </td>
                                                <td class="text-black">
                                                    {{APISponsor.agentinfo.faxnumber}}
                                                </td>
                                            </tr>
                                            <tr class="spaceUnder">
                                                <td class="text-info">
                                                    Email Address:
                                                </td>
                                                <td class="text-black">
                                                    {{APISponsor.agentinfo.email}}
                                                </td>
                                            </tr>
                                            

                                        </table>
                                    </div>
                                </div>
                            </div>




                            <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                                <div class="bShadow-5">
                                    <div style="min-height: 340px;">
                                        <h6>Products and Grid</h6>
                                        <hr>
                                        <table class="w100">
                                            <tr class="spaceUnder">
                                                <td></td>
                                                <td class="text-title">Year 1</td>
                                                <td class="text-title">Year 2+</td>
                                            </tr>
                                            <tr class="spaceUnder" *ngFor="let product of APISponsor.products ">
                                                <td class="text-info">{{product.RealName}}</td>
                                                <td class="text-black">{{product.Y1}}%</td>
                                                <td class="text-black">{{product.Y2}}%</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <button class="btn btn-danger btn-sm" (click)="SetDownline()">Recode Member under this agent</button>

                    </div>


                </div>


            </div>

        </div>
    </div>
</div>


<p-dialog [(visible)]="showDependedBox" modal="true" [draggable]="false" [closeOnEscape]="true" [responsive]="true"
    [style]="{ width: '500px' }" [baseZIndex]="1">
    <p-header style="width: 100%">
        <h3>{{personData.FirstName}} {{personData.LastName}}</h3>
    </p-header>
    <hr>
    <table class="w100">
        <tr>
            <td class="text-info" width="30%">Birth Date</td>
            <td>{{personData.BirthDate}}</td>
        </tr>
        <tr>
            <td class="text-info" width="30%">Gender</td>
            <td>{{personData.Gender}}</td>
        </tr>
        <tr>
            <td class="text-info" width="30%">Relationship</td>
            <td>{{personData.Relationship}}</td>
        </tr>
        <tr>
            <td class="text-info" width="30%">Address</td>
            <td>{{personData.Address1}}</td>
        </tr>
        <tr>
            <td class="text-info" width="30%">City</td>
            <td>{{personData.City}}</td>
        </tr>
        <tr>
            <td class="text-info" width="30%">State</td>
            <td>{{personData.State_Name}}</td>
        </tr>
        <tr>
            <td class="text-info" width="30%">ZIP Code</td>
            <td>{{personData.Zip}}</td>
        </tr>
        <tr>
            <td class="text-info" width="30%">Phone</td>
            <td>{{personData.Phone}}</td>
        </tr>
        <tr>
            <td class="text-info" width="30%">Disabled?</td>
            <td>{{personData.IsDisabled}}</td>
        </tr>
        <tr>
            <td class="text-info" width="30%">PersonCode</td>
            <td>{{personData.PersonCode}}</td>
        </tr>

    </table>
</p-dialog>

<p-dialog [(visible)]="showBeneficiaryBox" modal="true" [draggable]="false" [closeOnEscape]="true" [responsive]="true"
    [style]="{ width: '500px' }" [baseZIndex]="1">
    <p-header style="width: 100%">
        <h3>{{personData.FirstName}} {{personData.LastName}}</h3>
    </p-header>
    <hr>
    <table class="w100">
        <tr>
            <td class="text-info" width="30%">Beneficiary Type</td>
            <td>{{personData.type}}</td>
        </tr>
        <tr>
            <td class="text-info" width="30%">Percentage</td>
            <td>{{personData.Percentage}}%</td>
        </tr>
        <tr>
            <td class="text-info" width="30%">Relationship</td>
            <td>{{personData.Relationship}}</td>
        </tr>
        <tr>
            <td class="text-info" width="30%">Address</td>
            <td>{{personData.Address1}}</td>
        </tr>
        <tr>
            <td class="text-info" width="30%">City</td>
            <td>{{personData.City}}</td>
        </tr>
        <tr>
            <td class="text-info" width="30%">State</td>
            <td>{{personData.State_Name}}</td>
        </tr>
        <tr>
            <td class="text-info" width="30%">ZIP Code</td>
            <td>{{personData.Zip}}</td>
        </tr>


    </table>
</p-dialog>

















<div id="spinner" style="height: 100vh" *ngIf="spinner">
    <div class="centered">
        <img src="assets/images/processing.gif" style="width: 50px" />
        <br /><br />
        <span class="text-white">Processing. Please wait...</span>
    </div>
</div>