import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-superuser',
  templateUrl: './superuser.component.html',
  styleUrls: ['./superuser.component.css']
})
export class SuperuserComponent implements OnInit {
  navbarOpen2 = false;
  constructor() { }
  toggleNavbar2() {
    this.navbarOpen2 = !this.navbarOpen2;
  }
  ngOnInit(): void {
  }

}
