import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2'
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-agentshierarchy',
  templateUrl: './agentshierarchy.component.html',
  styleUrls: ['./agentshierarchy.component.css']
})
export class AgentshierarchyComponent implements OnInit {

  selectedAgentID: string;
  AllowFullDownline: boolean = false;
  public AllowCreate: boolean = false;
  public AllowViewMembers: boolean = false;
  public AllowDownline: boolean = false;
  public AllowViewAgentPassword: boolean = false;

  openSide: boolean=false;
  preloader: boolean = false;
  ShowProductsBox: boolean = false;
  APIresult: any = [];
  clientAPIresult: any;
  AgentsList: any;
  ProductsList: any;
  TotalAgents: number;
  DownlineType: string = "Direct Downline";

  ClientsList: any;
  CurrentCustomer: any;
  ShowOverlay: boolean = false;
  public CurrentAgent: string;
  constructor(
    public _httpClient: HttpClient,
    private _router: Router,
    private r: ActivatedRoute,
    private toastr: ToastrService
  ) {

    this.CurrentAgent = sessionStorage.getItem('plid');
    this.DownlineType = "Full Downline"
    let Sponsor: string = sessionStorage.getItem("plid");
    if (sessionStorage.getItem('temp')) {
      Sponsor=sessionStorage.getItem('temp');
    }
    this.ListAgents(Sponsor, "all")

    if (sessionStorage.getItem("agency") == "1") {
      this.AllowFullDownline = true;
    }

    if (sessionStorage.getItem("agency") == "20") {
      this.AllowFullDownline = true;

    }

    if (sessionStorage.getItem("agency") == "1") {
      this.AllowCreate = true;
      this.AllowViewMembers = true;
      this.AllowDownline = true;
      this.AllowViewAgentPassword = true
    }

    if (sessionStorage.getItem("agency") == "2") {
      this.AllowCreate = false;
      this.AllowViewMembers = true;
      this.AllowDownline = true;
      this.AllowViewAgentPassword = true;
    }

  }
  Me(): void {
    this.DownlineType = "Full Downline"
    this.ListAgents(this.CurrentAgent, "direct")
  }

  Full(): void {
    this.DownlineType = "Full Downline"
    this.ListAgents(this.CurrentAgent, "all")
  }

  ListAgents(ID, type): void {
    
      if (type == 'direct') { this.DownlineType = "Direct Downline" };
      this.preloader = true;
      this.CurrentAgent = ID;
      var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/listagents.aspx";    // set header for http call
      let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
      // set body
      let body = new URLSearchParams();
      body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
      body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
      body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
      body.set('pl', encodeURIComponent(ID));
      body.set('type', type);
      this._httpClient.post(APIURL, body.toString(), options).subscribe(
        result => {
          this.APIresult = result;
          if (result == null) {
            this.preloader = false;
          }
          else {
            if (this.APIresult.success == "1") {
              this.AgentsList = this.APIresult.agents;
              var count = 0;
              for (var i = 0; i < this.AgentsList.length; ++i) {
                count++;
              }
              this.TotalAgents = count;
              //this.toastr.success('Downline view switched to selected agent', 'View Changed');

            }
          }
          this.preloader = false;
        },
        error => {
          this.preloader = false;
        });
  }


  SelectAgent(id: string) {
    if (id) {
      this.openSide=true;
      this.selectedAgentID=id;
      sessionStorage.setItem("temp", id)
      //this._router.navigate(['../viewagent'], { relativeTo: this.r });
    }

  }


  ListMembers(id: string): void {
    this.preloader = true;
    this.ClientsList = []
    this.ShowOverlay = true;
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/GetPolicies.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('pl', encodeURIComponent(id));
    this._httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.clientAPIresult = Array.of(result);
        if (result == null) {

        }
        else {
          if (this.clientAPIresult[0].success == "1") {
            this.ClientsList = this.clientAPIresult[0].clients;
          }
        }
        this.preloader = false;
      },
      error => {
        this.preloader = false;

      });
  }


  ShowProducts(id): void {
    this.preloader = true;
    this.ProductsList = []
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/GetProductsForPL.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('pl', encodeURIComponent(id));
    this._httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.clientAPIresult = Array.of(result);
        if (result == null) {

        }
        else {
          if (this.clientAPIresult[0].success == "1") {
            this.ShowProductsBox = true;
            this.ProductsList = this.clientAPIresult[0].products;
          }
        }
        this.preloader = false;
      },
      error => {
        this.preloader = false;

      });
  }

  MyInfo(): void {
    this.SelectAgent(sessionStorage.getItem("plid"));
  }

  ngOnInit(): void {
  }

  switchAgent(ID): void {
    sessionStorage.setItem('temp', ID);
    let currentUrl = this._router.url;
    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this._router.navigate([currentUrl]);
    });

  }
}
