<h4>List Custom Codes</h4>
<hr>


<div class="minheight">
    <p-table #dt [value]="CodesList" [rows]="25" [showCurrentPageReport]="true" [rowsPerPageOptions]="[25,50,200,1000]" styleClass="p-datatable-customers" [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} codes" [filterDelay]="0"
        [globalFilterFields]="['code','description','status','timesallowed','timesused']" [resizableColumns]="true" [autoLayout]="true" selectionMode="single">

        <ng-template pTemplate="header">
            <tr class="FontRow f13">
                <th [pSortableColumn]="'code'">CODE</th>
                <th [pSortableColumn]="'description'">Description</th>
                <th [pSortableColumn]="'status'">Status</th>
                <th [pSortableColumn]="'timesused'">Times Utilized</th>
                <th></th>
            </tr>
            <tr>
                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'code', 'contains')" placeholder="Code">
                </th>
                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'description', 'contains')" placeholder="Description">
                </th>

                <th>
                    <select class="form-control " (input)="dt.filter($event.target.value, 'status', 'contains')">
                        <option value="">List All</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                    </select>
                </th>
                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'timesused', 'contains')" placeholder="Times Used">
                </th>
                <th>

                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-CodesList>
            <tr class="FontRow f13">
                <td class="SmallFont">{{CodesList.code.toUpperCase()}}</td>
                <td class="SmallFont">{{CodesList.description}}</td>
                <td class="SmallFont">{{CodesList.status}}</td>
                <td class="SmallFont">{{CodesList.timesused}}</td>
                <td class="text-right">
                    <button class="btn btn-sm btn-primary" (click)="onRowSelect(CodesList.id)" style="margin-right: 10px;">EDIT</button>
                    <button class="btn btn-sm btn-danger" (click)="DeleteCode(CodesList.id)">DELETE</button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>