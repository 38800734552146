<h4>Invite Agent</h4>
<hr>
<div class="w100">

    <div class="bShadow-5">
        <br>
        <h6>Prospect Agent Information</h6>
        <hr>
        <div class="row">

            <div class="col-xl-2 col-lg-4 col-sm-6 col-md-4 col-sm-6 col-12">
                <label>First Name</label>
                <input type="text" maxlength=50 class="form-control" [(ngModel)]="firstname">
            </div>
            <div class="col-xl-2 col-lg-4 col-sm-6 col-md-4 col-sm-6 col-12">
                <label>Last Name</label>
                <input type="text" maxlength=50 class="form-control" [(ngModel)]="lastname">
            </div>
            <div class="col-xl-2 col-lg-4 col-sm-6 col-md-4 col-sm-6 col-12">
                <label>Email Address</label>
                <input type="text" maxlength=100 class="form-control" [(ngModel)]="email">
            </div>
            <div class="col-xl-2 col-lg-4 col-sm-6 col-md-4 col-sm-6 col-12">
                <label>Cell Phone</label>
                <input type="text " maxlength=50 class="form-control " mask="(000) 000-0000" [(ngModel)]="cellphone"
                    [dropSpecialCharacters]="false">
            </div>
            <!--
            <div class="col-xl-2 col-lg-4 col-sm-6 col-md-4 col-sm-6 col-12">
                <label>Select Hierarchy</label>
                <select class="form-control" (change)="checkAgent()" [(ngModel)]="selectedHierarchy">
                    <option value="{{myid}}">+ {{myself}}</option>
                    <option value="{{myid}}">---------------------------------------------</option>
                    <option *ngFor="let agent of APIdownline" value="{{agent.id}}">{{agent.agentname}}</option>

                </select>
            </div>
            -->
            <div class="col-xl-2 col-lg-4 col-sm-6 col-md-4 col-sm-6 col-12" *ngIf="HierarchyUserMatch">
                <label>Select Pay Grid</label>
                <select class="form-control" [(ngModel)]="selectedPaygrid" (change)="updatePaygrid()">
                    <option value="">+ Build Custom Paygrid</option>
                    <option value="">---------------------------------------------</option>

                    <option *ngFor="let template of Codes" value="{{template.Code}}">Use Code: {{template.Code}}
                    </option>
                </select>
            </div>

            <div class="col-xl-2 col-lg-4 col-sm-6 col-md-4 col-sm-6 col-12" style="display: none">
                <label>Enrollment Code</label>
                <input [disabled]="!allowcode" class="form-control" (keypress)="keyPressLettersNumbers($event)"
                    style="text-transform:uppercase" [(ngModel)]="templatecode" type="text" maxlength="15">

            </div>

        </div>
        <br>
        <h6>
            Products and Payout Setup
        </h6>
        <hr>
        <app-setcomp [Data]="Data" ></app-setcomp>
    </div>
    <br>

    <button class="btn btn-primary text-white " style="margin-right: 10px;" (click)="InviteAgent()"><i
        class="fa fa-user"></i>
    Invite Agent to Join</button>

    <br>

    <br>
    <br>
    <br>
    <br> <br>
    <br>
    <br>
    <br>
    <br>
    <br>




    <div class="loader" *ngIf="preloader"></div>