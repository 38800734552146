<h4>Training Materials</h4>
<span class="text-danger">
{{BreadCrumb}} {{BreadCrumbSub}}
</span>
<hr>

<div class="row">
    <div class="col-12 col-md-3 col-lg-2 " style="background-color: #ffffff;border-right: #e5e5e5 1px solid; ">
        <div style="height: 100vh; ">
        <br>
            <div class="row">
                <div class="col-8">
                    <h5>Categories</h5>
                </div>
                <div class="col-4 text-right">
                    <button class="btn btn-sm btn-warning text-white" (click)="NewCategory()">NEW</button>
                </div>
            </div>
            <hr>
            <ul>
                <li *ngFor="let button of APItraining">
                    <a class="mousepointer link"
                        (click)="enableCategory(button.categoryid)">{{button.categorytext}}</a>
                </li>
            </ul>
        </div>
    </div>
    <div class="col-12 col-md-3 col-lg-2" style="background-color: #ffffff;border-right: #e5e5e5 1px solid;">
        <div *ngIf="activecategory">
            <br>
            <div class="row">
                <div class="col-8">
                    <h5>Sections</h5>
                </div>
                <div class="col-4 text-right">
                    <button class="btn btn-sm btn-warning text-white" (click)="NewSection()">NEW</button>
                </div>
            </div>
            <hr>
            
            <ul>
                <li *ngFor="let category of SELECTEDcategory">
                    <a class="mousepointer link"
                        (click)="enableSection(category.sectionid)">{{category.sectionname}}</a>
                </li>
            </ul>
            <br>
        </div>
    </div>
    <div class="col-12 col-md-6 col-lg-8" style="background-color: #ffffff; height: 100vh;">
        <div *ngIf="activesection">
            <br>
            <div class="row">
                <div class="col-8">
                    <h5>Content</h5>
                </div>
                <div class="col-4 text-right">
                    <button class="btn btn-sm btn-warning text-white" (click)="NewContent()">NEW</button>
                </div>
            </div>
            <hr>
            <br>
            <div class="table-responsive" >
                <table class="table">
                    <tr style="background-color: #f1f1f1;">
                        <td style="width: 50px" class="text-left">Type</td>
                        <td style="width: 50px" class="text-center">Order</td>
                        <td>Title</td>
                        <td ></td>
                    </tr>
                    <tr *ngFor="let content of SELECTEDsection ">
                        <td>
                            <img *ngIf="content.contenttype=='video'" src="assets/icons/icoyoutube.png" style="width: 40px">
                            <img *ngIf="content.contenttype=='file'" src="assets/icons/icopdf.png" style="width: 40px">
                        </td>
                        <td style="width: 50px" class="text-center">{{content.sortorder}}</td>
                        <td>{{content.title}}</td>
                        <td class="text-right">
                            <button class="btn btn-xs btn-danger" style="margin-right: 10px;" (click)="deleteContent(content.contentid, 'delete')">Delete</button>
                            <button class="btn btn-xs btn-warning text-white" style="margin-right: 10px;" (click)="editContent(content.contentid, 'delete')"> Edit </button>
                            <button class="btn btn-xs btn-primary" style="margin-right: 10px;" (click)="viewContent(content.contentid)"> Open </button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>










<!--DIALOGS-->

<!--NEW category-->
<p-dialog [(visible)]="newcategory" modal="true" appendToBody="true" [draggable]="true" [closeOnEscape]="false"
    [responsive]="true" [style]="{ width: '400px' }" [baseZIndex]="1">
    <p-header style="width: 100%">
        <h3>New Category</h3>
    </p-header>

    New Category Name:<br>
    <input class="form-control" maxlength="30" [(ngModel)]="newcategoryname">
    <br>
    <button class="btn btn-sm btn-warning text-white" (click)="SaveCategory()">Add Category</button>
    <br>
    <br>
</p-dialog>

<!--NEW SECTION-->
<p-dialog [(visible)]="newsection" modal="true" appendToBody="true" [draggable]="true" [closeOnEscape]="false"
    [responsive]="true" [style]="{ width: '400px' }" [baseZIndex]="1">
    <p-header style="width: 100%">
        <h3>New Section</h3>
    </p-header>

    New Section Name:<br>
    <input class="form-control" maxlength="30" [(ngModel)]="newsectionname">
    <br>
    <button class="btn btn-sm btn-warning text-white" (click)="SaveSection()">Add Section</button>
    <br>
    <br>
</p-dialog>




<!--NEW content-->
<p-dialog [(visible)]="newcontent" modal="true" appendToBody="true" [draggable]="true" [closeOnEscape]="false"
    [responsive]="true" [style]="{ width: '800px' }" [baseZIndex]="1">
    <p-header style="width: 100%">
        <h3>New Content</h3>
    </p-header>

    Content Type:
    <select class="form-control" [(ngModel)]="newcontentType">
        <option value="video">Youtube Video</option>
        <option value="file">Downloadable Content</option>
    </select>
    <br>
    Sort Order:
    <select class="form-control" [(ngModel)]="newcontentSortorder">
        <option *ngFor="let order of numbers" value="{{order}}">{{order}}</option>
    </select>
    <br>
    <div *ngIf="newcontentType=='video'">
        YouTube URL:
        <input class="form-control" maxlength="14" [(ngModel)]="newcontentValue">
        <br>
    </div>
    <div *ngIf="newcontentType=='file'">
        File to Upload:
        <input type="file" class="form-control" name="eofile2" id="eofile2"
        (change)="onFileChanged($event)" accept="application/pdf">
        <br>
    </div>
    Content Title:
    <input class="form-control" maxlength="80" [(ngModel)]="newcontentTitle" >
    <br>
    Content Brief Description (300 characters max):
    <textarea maxlength="300" class="form-control" [(ngModel)]="newcontentDescription" style="height:250px"></textarea>
    <br>
    
    <br>
    <button class="btn btn-sm btn-warning text-white" (click)="SaveContent()">Add New Element</button>
    <br>
    <br>
</p-dialog>


<div id="spinner" style="height: 100vh" *ngIf="showspinner">
    <div class="centered">
        <img src="assets/images/processing.gif" style="width: 50px" />
        <br /><br />
        <span class="text-white">Processing. Please wait...</span>
    </div>
</div>







<!--edit content-->
<p-dialog [(visible)]="editContentID" modal="true" appendToBody="true" [draggable]="true" [closeOnEscape]="false"
    [responsive]="true" [style]="{ width: '800px' }" [baseZIndex]="1">
    <p-header style="width: 100%">
        <h3>Editing Content</h3>
    </p-header>

    Content ID:
    <label class="form-control">{{SELECTEDcontent.contentid}}</label>
    <br>

    Content Type:
    <label class="form-control">{{SELECTEDcontent.contenttype}}</label>
    <br>
    Sort Order:
    <select class="form-control" [(ngModel)]="SELECTEDcontent.sortorder">
        <option *ngFor="let order of numbers" value="{{order}}">{{order}}</option>
    </select>
    <br>
    Content Title:
    <input class="form-control" maxlength="80" [(ngModel)]="SELECTEDcontent.title" >
    <br>
    Content Brief Description (300 characters max):
    <textarea maxlength="300" class="form-control" [(ngModel)]="SELECTEDcontent.description" style="height:250px"></textarea>
    <br>
    
    <br>
    <button class="btn btn-sm btn-warning text-white" (click)="saveEdittedContent()">Add New Element</button>
    <br>
    <br>
</p-dialog>


<div id="spinner" style="height: 100vh" *ngIf="showspinner">
    <div class="centered">
        <img src="assets/images/processing.gif" style="width: 50px" />
        <br /><br />
        <span class="text-white">Processing. Please wait...</span>
    </div>
</div>



<!--DISPLAY VIDEO-->


<p-dialog [(visible)]="OpenVideo" modal="true" [draggable]="false" [closeOnEscape]="true" [responsive]="true" [baseZIndex]="100">

    <p-header style="width:100%">
        <h3>{{VideoTitle}}</h3>

    </p-header>
    <iframe width="840" height="473" [src]="VideoURL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</p-dialog>

