import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-p12',
  templateUrl: './p12.component.html',
  styleUrls: ['./p12.component.scss']
})
export class P12Component implements OnInit {
  @Output("Calculate") Calculate: EventEmitter<any> = new EventEmitter();
  @Input() Data: any;
  public matrix: any;
  public selection: any;

  public docgroup: string;
  public LightBox: boolean = false;
  public PlanName: string = "";
  public AnnualDeductible: string = "";
  public MaximumBenefit: string = "";
  public Preventive: string = "";
  public BasicServices: string = "";
  public Fee: number = 0;


  public SelectedCol: number = 0;
  public SelectedRow: number = 0;
  public SelectedAmount: number = 0
  public SelectedName: string = "";
  public SelectedRowCol: string = "";

  constructor(
    private toastr: ToastrService
  ) {



  }

  ngOnInit(): void {
    this.matrix = this.Data.critical65;
    this.selection = this.Data.selections.critical65;
    this.SelectedRowCol = this.Data.selections.critical65.row.toString() + this.Data.selections.critical65.col.toString()
    
  }

  View(row, col, amount, name): void {
    this.SelectedCol = col;
    this.SelectedRow = row;
    this.SelectedAmount = amount;
    this.SelectedName = name
    if (this.SelectedRow==1) {this.docgroup="1"}
    if (this.SelectedRow==2) {this.docgroup="1"}
    if (this.SelectedRow==3) {this.docgroup="2"}
    if (this.SelectedRow==4) {this.docgroup="2"}

  }

  SetPlan(): void {
    this.SelectedRowCol = this.SelectedRow.toString() + this.SelectedCol.toString()
    this.Data.selections.critical65.selection = this.SelectedRow.toString() + this.SelectedCol.toString()
    this.Data.selections.critical65.amount = +this.SelectedAmount;
    this.Data.selections.critical65.col = this.SelectedCol;
    this.Data.selections.critical65.row = this.SelectedRow;
    this.Data.selections.critical65.name = this.SelectedName;

    

    this.toastr.success('Plan added to your cart!', 'Plan Added');
    this.Calculate.emit();
    this.LightBox = false;
  }

}
