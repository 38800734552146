import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-updateaccount',
  templateUrl: './updateaccount.component.html',
  styleUrls: ['./updateaccount.component.css']
})
export class UpdateaccountComponent implements OnInit {
  public AgentType: string; //business or individual
  public JSONResp;
  public showspinner: boolean = false;

  public showACH: boolean = false;

  eofile: File;

  public SSN: string;
  public APIresult: any;
  public npnInd: any;
  public npnAg: any;

  //titles and operations not sent to the API
  public addressTitle: string;
  public tabindex: number = 0;
  public inputError: boolean = true;

  public USstates = [
    {
      "Id": 83,
      "Code": "AL",
      "Name": "Alabama"
    },
    {
      "Id": 82,
      "Code": "AK",
      "Name": "Alaska"
    },
    {
      "Id": 87,
      "Code": "AZ",
      "Name": "Arizona"
    },
    {
      "Id": 85,
      "Code": "AR",
      "Name": "Arkansas"
    },
    {
      "Id": 88,
      "Code": "CA",
      "Name": "California"
    },
    {
      "Id": 89,
      "Code": "CO",
      "Name": "Colorado"
    },
    {
      "Id": 90,
      "Code": "CT",
      "Name": "Connecticut"
    },
    {
      "Id": 92,
      "Code": "DE",
      "Name": "Delaware"
    },
    {
      "Id": 91,
      "Code": "DC",
      "Name": "District of Columbia"
    },
    {
      "Id": 93,
      "Code": "FL",
      "Name": "Florida"
    },
    {
      "Id": 95,
      "Code": "GA",
      "Name": "Georgia"
    },
    {
      "Id": 96,
      "Code": "GU",
      "Name": "Guam"
    },
    {
      "Id": 97,
      "Code": "HI",
      "Name": "Hawaii"
    },
    {
      "Id": 99,
      "Code": "ID",
      "Name": "Idaho"
    },
    {
      "Id": 100,
      "Code": "IL",
      "Name": "Illinois"
    },
    {
      "Id": 101,
      "Code": "IN",
      "Name": "Indiana"
    },
    {
      "Id": 98,
      "Code": "IA",
      "Name": "Iowa"
    },
    {
      "Id": 102,
      "Code": "KS",
      "Name": "Kansas"
    },
    {
      "Id": 103,
      "Code": "KY",
      "Name": "Kentucky"
    },
    {
      "Id": 104,
      "Code": "LA",
      "Name": "Louisiana"
    },
    {
      "Id": 107,
      "Code": "ME",
      "Name": "Maine"
    },
    {
      "Id": 106,
      "Code": "MD",
      "Name": "Maryland"
    },
    {
      "Id": 105,
      "Code": "MA",
      "Name": "Massachusetts"
    },
    {
      "Id": 109,
      "Code": "MI",
      "Name": "Michigan"
    },
    {
      "Id": 110,
      "Code": "MN",
      "Name": "Minnesota"
    },
    {
      "Id": 113,
      "Code": "MS",
      "Name": "Mississippi"
    },
    {
      "Id": 111,
      "Code": "MO",
      "Name": "Missouri"
    },
    {
      "Id": 114,
      "Code": "MT",
      "Name": "Montana"
    },
    {
      "Id": 117,
      "Code": "NE",
      "Name": "Nebraska"
    },
    {
      "Id": 121,
      "Code": "NV",
      "Name": "Nevada"
    },
    {
      "Id": 118,
      "Code": "NH",
      "Name": "New Hampshire"
    },
    {
      "Id": 119,
      "Code": "NJ",
      "Name": "New Jersey"
    },
    {
      "Id": 120,
      "Code": "NM",
      "Name": "New Mexico"
    },
    {
      "Id": 122,
      "Code": "NY",
      "Name": "New York"
    },
    {
      "Id": 115,
      "Code": "NC",
      "Name": "North Carolina"
    },
    {
      "Id": 116,
      "Code": "ND",
      "Name": "North Dakota"
    },
    {
      "Id": 79,
      "Code": "00",
      "Name": "Not Determined"
    },
    {
      "Id": 123,
      "Code": "OH",
      "Name": "Ohio"
    },
    {
      "Id": 124,
      "Code": "OK",
      "Name": "Oklahoma"
    },
    {
      "Id": 125,
      "Code": "OR",
      "Name": "Oregon"
    },
    {
      "Id": 126,
      "Code": "PA",
      "Name": "Pennsylvania"
    },
    {
      "Id": 127,
      "Code": "PR",
      "Name": "Puerto Rico"
    },
    {
      "Id": 128,
      "Code": "RI",
      "Name": "Rhode Island"
    },
    {
      "Id": 129,
      "Code": "SC",
      "Name": "South Carolina"
    },
    {
      "Id": 130,
      "Code": "SD",
      "Name": "South Dakota"
    },
    {
      "Id": 131,
      "Code": "TN",
      "Name": "Tennessee"
    },
    {
      "Id": 132,
      "Code": "TX",
      "Name": "Texas"
    },
    {
      "Id": 133,
      "Code": "UT",
      "Name": "Utah"
    },
    {
      "Id": 136,
      "Code": "VT",
      "Name": "Vermont"
    },
    {
      "Id": 11142,
      "Code": "VI",
      "Name": "Virgin Islands"
    },
    {
      "Id": 134,
      "Code": "VA",
      "Name": "Virginia"
    },
    {
      "Id": 137,
      "Code": "WA",
      "Name": "Washington"
    },
    {
      "Id": 139,
      "Code": "WV",
      "Name": "West Virginia"
    },
    {
      "Id": 138,
      "Code": "WI",
      "Name": "Wisconsin"
    },
    {
      "Id": 140,
      "Code": "WY",
      "Name": "Wyoming"
    }
  ]



  constructor(
    public httpClient: HttpClient,
    private _router: Router,
    private r: ActivatedRoute,
  ) {

    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/setup/viewprofile.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        let APIresult: any = result;
        if (result == null) {
          Swal.fire({
            title: 'Try again',
            text: 'There was an error connecting to our Endpoint. Please try again later.',
            icon: 'error',
          })
          return;
        }
        else {
          if (APIresult.success == "1") {
            this.JSONResp = APIresult;
            for (let recs of this.JSONResp.npnInfo) {
              if (recs.type == "individual") {
                this.npnInd = recs;
              };
              if (recs.type == "agency") {
                this.npnAg = recs;
              };
            }
          }
          else {
            Swal.fire({
              title: 'Something went wrong...',
              text: APIresult[0].message,
              icon: 'error',

            })
          }

        }

      },
      error => {

      });


  }


  onFileChanged(event) {
    this.eofile = event.target.files[0]
    if (this.eofile) {
      if (this.eofile.size > 10240000) {
        Swal.fire({
          title: 'File Too Big',
          text: 'Your E&O Certificate file size cannot be greater than 10Mb',
          icon: 'error',
        })

        return;
      }
    }
  }

  IsFutureDate(mydate: string): boolean {
    var isdate = Date.parse(mydate);
    if (isNaN(isdate)) {
      return false;
    }

    var EnteredDate = new Date(isdate)
    var TodayIs = new Date()
    if (EnteredDate > TodayIs) {
      return true;
    }
    return false;
  }

  IsDate(mydate: string): boolean {
    var isdate = Date.parse(mydate);
    if (isNaN(isdate)) {
      return false;
    }

    var EnteredDate = new Date(isdate)
    var TodayIs = new Date()
    if (EnteredDate > TodayIs) {
      return false;
    }
    var Difference_In_Time = EnteredDate.getTime() - new Date().getTime();
    var Difference_In_Days = ((Difference_In_Time / (1000 * 3600 * 24)) / 365) * (-1);
    if (Difference_In_Days > 100) {
      return false;
    }
    if (Difference_In_Days < 18) {
      return false;
    }
    return true;
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


  isNumber(value: string | number): boolean {
    return ((value != null) &&
      (value !== '') &&
      !isNaN(Number(value.toString())));
  }


  WarningMessage(message: string): void {
    Swal.fire({
      title: 'Check the required fields',
      text: message,
      icon: 'warning',

    })
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9

    if (charCode >= 48 && charCode <= 57) {
      return true;
      return;
    }

    event.preventDefault();
    return false;

  }

  changeTab(value: number): void {


  }


  onTabChange(event): void {
    this.tabindex = event.index;
  }


  TryParse(item): boolean {
    item = typeof item !== "string"
      ? JSON.stringify(item)
      : item;

    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }

    if (typeof item === "object" && item !== null) {
      return true;
    }

    return false;
  }



  SaveData(action): void {

    if (this.eofile) {
      if (this.eofile.size > 10024000) {
        Swal.fire({
          title: 'File Too Big',
          text: 'Your E&O Certificate file size cannot be greater than 10Mb',
          icon: 'error',
        })
        return;
      }
    }

    this.showspinner = true;
    //call API

    var APIURL = sessionStorage.getItem('endpoint') + "afeapi/desk/setup/savechanges.aspx";    // set header for http call
    let formData = new FormData();
    if (this.eofile) {
      formData.append("myfile", this.eofile, this.eofile.name);
    }
    this.JSONResp.npnInfo=[];
    this.JSONResp.npnInfo.push(this.npnInd);
    if (this.JSONResp.type=="individual"){
      this.npnAg.AgencyName="";
      this.npnAg.ResidentInsuranceNumber="";
      this.npnAg.ResidentStateLicense="";
      this.npnAg.AgencyType="";
      this.npnAg.EIN="";
      this.npnAg.TypeOfLicense="";
      this.npnAg.Address="";
      this.npnAg.city="";
      this.npnAg.state="";
      this.npnAg.zip="";
      this.npnAg.NPNnumber="";
    }
    this.JSONResp.npnInfo.push(this.npnAg);
    

    formData.append('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    formData.append('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    formData.append('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    formData.append('payload', encodeURIComponent(JSON.stringify(this.JSONResp)));
    formData.append('action', action);


    if (action == "3") {
      if (this.eofile) {
        formData.append("myfile", this.eofile, this.eofile.name);
      }
    }





    this.httpClient.post(APIURL, formData).subscribe(
      result => {
        let APIresult2: any = result;
        if (result == null) {
          this.showspinner = false;
          Swal.fire({
            title: 'Connection aborted',
            text: "A connection to AFEUSA servers could not be established.",
            icon: 'error',

          })
        }
        else {
          this.showspinner = false;
          if (APIresult2.success == "1") {

            Swal.fire({
              title: 'Profile Updated',
              text: APIresult2.message,
              icon: 'success',

            })
            this._router.navigate(['../../intro'], { relativeTo: this.r });
          }

          if (APIresult2.success == "99") {
            Swal.fire({
              title: 'Error',
              text: APIresult2.message,
              icon: 'error',

            })
          }


        }

      },
      error => {
        this.showspinner = false;
        Swal.fire({
          title: 'Connection aborted',
          text: "A connection to AFEUSA servers could not be established.",
          icon: 'error',

        })
      });


  }

  //PERSONAL INFORMATION
  SaveData1(): void {
    /*
    if (this.JSONResp.type == 'business') {
      if (!this.JSONResp.businessdata.legalname) { this.WarningMessage("Enter your legal name"); this.tabindex = 0; this.inputError = true; return; }
      if (!this.JSONResp.businessdata.domicilestate) { this.WarningMessage("Select your domicile state"); this.tabindex = 0; this.inputError = true; return; }
      if (!this.JSONResp.businessdata.owners) { this.WarningMessage("Enter your company's owners names"); this.tabindex = 0; this.inputError = true; return; }

    }
    */
    if (!this.JSONResp.FirstName) { this.WarningMessage("Enter your first name"); this.tabindex = 0; this.inputError = true; return; }
    if (!this.JSONResp.LastName) { this.WarningMessage("Enter your last name"); this.tabindex = 0; this.inputError = true; return; }
    if (!this.IsDate(this.JSONResp.BirthDate)) { this.WarningMessage("Enter a valid date of birth. Must be 18 or older to join AFEUSA."); this.tabindex = 0; this.inputError = true; return; }
    if (!this.JSONResp.Address1) { this.WarningMessage("Enter your address"); this.tabindex = 0; this.inputError = true; return; }
    if (!this.JSONResp.City) { this.WarningMessage("Enter your city"); this.tabindex = 0; this.inputError = true; return; }
    if (!this.JSONResp.StateCode) { this.WarningMessage("Select your state"); this.tabindex = 0; this.inputError = true; return; }
    if (this.isNumber(this.JSONResp.Zip) == false) { this.WarningMessage("Enter your ZIP code"); this.tabindex = 0; this.inputError = true; return; }


    if (!this.JSONResp.Phone) { this.WarningMessage("Enter your Cell Phone number"); this.tabindex = 0; this.inputError = true; return; }
    if (this.JSONResp.Phone.length != 10) { this.WarningMessage("Enter a valid Cell Phone number"); this.tabindex = 0; this.inputError = true; return; }
    if (!this.JSONResp.Email) { this.WarningMessage("Enter the email address you want to utilize for contacting you"); this.tabindex = 0; this.inputError = true; return; }
    if (this.validateEmail(this.JSONResp.Email) == false) { this.WarningMessage("Enter a valid email address for us to contact you"); this.tabindex = 0; this.inputError = true; return; }

    let newObj = this.USstates.filter((value) => {
      return value.Code.indexOf(this.JSONResp.StateCode) != -1 ? value : null
    });
    let StateID: number = newObj[0].Id;
    let StateName: string = newObj[0].Name
    this.JSONResp.StateId = StateID;
    this.JSONResp.StateName = StateName;

    this.SaveData("1")
  }
  //deposit information
  SaveData2(): void {
    if (!this.JSONResp.BankInfo.AccountName) { this.WarningMessage("Enter first and last name"); this.tabindex = 1; this.inputError = true; return; }
    if (!this.SSN) { this.WarningMessage("Enter Tax ID (SSN or EIN) for payee"); this.tabindex = 1; this.inputError = true; return; }
    if (!this.JSONResp.BankInfo.BankRoutingNumber) { this.WarningMessage("Enter routing number"); this.tabindex = 1; this.inputError = true; return; }
    if (this.JSONResp.BankInfo.BankRoutingNumber.length < 8) { this.WarningMessage("Your routing number should be at least 8 characters long"); this.tabindex = 1; this.inputError = true; return; }

    if (!this.JSONResp.BankInfo.BankAccountNumber) { this.WarningMessage("Enter bank account number"); this.tabindex = 1; this.inputError = true; return; }

    if (this.JSONResp.BankInfo.BankAccountNumber.length < 5) { this.WarningMessage("Your account number should be at least 8 characters long"); this.tabindex = 1; this.inputError = true; return; }
    if (!this.JSONResp.BankInfo.BankAccountType) { this.WarningMessage("Select bank account type"); this.tabindex = 1; this.inputError = true; return; }
    if (!this.JSONResp.BankInfo.BankName) { this.WarningMessage("Enter Bank Name"); this.tabindex = 1; this.inputError = true; return; }
    
    if (this.JSONResp.LicenseInfo.BusinessType=="Individual") {
      this.JSONResp.BankInfo.SSN = this.SSN;
      this.JSONResp.BankInfo.EIN = "";
    }
    else
    {
      this.JSONResp.BankInfo.EIN = this.SSN;
      this.JSONResp.BankInfo.SSN="";
    }
    this.JSONResp.BankInfo.TaxID=this.SSN;
    this.SaveData("2")
  }
  //License Information
  SaveData3(): void {
    if (this.JSONResp.type=='business'){
      if (!this.npnAg.AgencyName) { this.WarningMessage("All fields are mandatory."); this.tabindex = 2; this.inputError = true; return; }
      if (!this.npnAg.ResidentInsuranceNumber) { this.WarningMessage("All fields are mandatory."); this.tabindex = 2; this.inputError = true; return; }
      if (!this.npnAg.ResidentStateLicense) { this.WarningMessage("All fields are mandatory."); this.tabindex = 2; this.inputError = true; return; }
      if (!this.npnAg.AgencyType) { this.WarningMessage("All fields are mandatory."); this.tabindex = 2; this.inputError = true; return; }
      if (!this.npnAg.EIN) { this.WarningMessage("All fields are mandatory."); this.tabindex = 2; this.inputError = true; return; }
      if (!this.npnAg.TypeOfLicense) { this.WarningMessage("All fields are mandatory."); this.tabindex = 2; this.inputError = true; return; }
      if (!this.npnAg.Address) { this.WarningMessage("All fields are mandatory."); this.tabindex = 2; this.inputError = true; return; }
      if (!this.npnAg.city) { this.WarningMessage("All fields are mandatory."); this.tabindex = 2; this.inputError = true; return; }
      if (!this.npnAg.state) { this.WarningMessage("All fields are mandatory."); this.tabindex = 2; this.inputError = true; return; }
      if (!this.npnAg.zip) { this.WarningMessage("All fields are mandatory."); this.tabindex = 2; this.inputError = true; return; }
    }
    
    if (!this.npnInd.firstname) { this.WarningMessage("All fields are mandatory."); this.tabindex = 2; this.inputError = true; return; }
    if (!this.npnInd.lastname) { this.WarningMessage("All fields are mandatory."); this.tabindex = 2; this.inputError = true; return; }
    if (!this.npnInd.DOB) { this.WarningMessage("All fields are mandatory."); this.tabindex = 2; this.inputError = true; return; }
    if (!this.npnInd.SSN) { this.WarningMessage("All fields are mandatory."); this.tabindex = 2; this.inputError = true; return; }
    if (!this.npnInd.TypeOfLicense) { this.WarningMessage("All fields are mandatory."); this.tabindex = 2; this.inputError = true; return; }
    if (!this.npnInd.ResidentInsuranceNumber) { this.WarningMessage("All fields are mandatory."); this.tabindex = 2; this.inputError = true; return; }
    if (!this.npnInd.ResidentStateLicense) { this.WarningMessage("All fields are mandatory."); this.tabindex = 2; this.inputError = true; return; }
    if (!this.npnInd.Address) { this.WarningMessage("All fields are mandatory."); this.tabindex = 2; this.inputError = true; return; }
    if (!this.npnInd.city) { this.WarningMessage("All fields are mandatory."); this.tabindex = 2; this.inputError = true; return; }
    if (!this.npnInd.state) { this.WarningMessage("All fields are mandatory."); this.tabindex = 2; this.inputError = true; return; }
    if (!this.npnInd.zip) { this.WarningMessage("All fields are mandatory."); this.tabindex = 2; this.inputError = true; return; }
    if (!this.npnInd.NPNnumber) { this.WarningMessage("All fields are mandatory."); this.tabindex = 2; this.inputError = true; return; }

    if (this.JSONResp.LicenseInfo.EOICoverage == true) {
      if (!this.JSONResp.LicenseInfo.EOIAmount) { this.WarningMessage("Enter your E&O coverage amount"); this.tabindex = 2; this.inputError = true; return; }
      if (!this.JSONResp.LicenseInfo.EOIExpiration) { this.WarningMessage("Enter your E&O expiration date"); this.tabindex = 2; this.inputError = true; return; }
    }
    if (!this.isNumber(this.JSONResp.LicenseInfo.EOIAmount)) { this.JSONResp.LicenseInfo.EOIAmount = "0" }

    this.SaveData("3")
  }


  resetAgencyType(): void {
    if(this.JSONResp.type=='individual') {
      this.npnAg.AgencyName="";
      this.npnAg.ResidentInsuranceNumber="";
      this.npnAg.ResidentStateLicense="";
      this.npnAg.AgencyType="";
      this.npnAg.EIN="";
      this.npnAg.TypeOfLicense="";
      this.npnAg.Address="";
      this.npnAg.city="";
      this.npnAg.state="";
      this.npnAg.zip="";
      this.npnAg.NPNnumber="";
      

    }
  }


  ngOnInit(): void {
  }

}
