import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.css']
})
export class SetupComponent implements OnInit {
  Afetype: string;
  allowUsers: string;
  PLID: string;
  application: boolean=false;
  application2: boolean=false;
  application3: boolean=false;

  constructor() { 
    let selections = sessionStorage.getItem("selections")
    this.application=selections.includes("FDErnJufh6c=")
    this.application2=selections.includes("pVwAI0489rpQ2HQJ74wRkQ==")
    this.application3=selections.includes("nITaM2cKc2uXK2Kr34lbmQ==")

    this.Afetype=sessionStorage.getItem("afetype");
    this.allowUsers=sessionStorage.getItem("agency");
    this.PLID = sessionStorage.getItem("plid");
  }
  navbarOpen = false;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
  ngOnInit(): void {
  }

}
