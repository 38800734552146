<h4>
    View Commissions</h4>
<p>Click the button below to get redirect to your commission module at HCI.</p>
<hr>
<div class="minheight">

    <form method="POST" action="{{ContinuationUrl}}" ngNoForm id="form1" target="_blank" *ngIf="success">
        <input type="hidden" value="{{ContinuationUrl}}" id="ContinuationUrl" name="ContinuationUrl">
        <input type="hidden" value="{{AccessToken}}" id="AccessToken" name="AccessToken">
        <input type="hidden" value="{{username}}" id="UserName" name="UserName">
        <input type="hidden" value="{{password}}" id="Password" name="Password">
        <input type="hidden" value="{{agentid}}" id="AgentId" name="AgentId">
        <button type="submit" form="form1" class="btn btn-warning btn-sm text-white">Login to HCI</button>

    </form>
</div>