<h4>Download HCI Audit Report</h4>
<hr>
<br>
<div class="alert alert-danger">
    <h4>Warning</h4>
    <div>This process may take several minutes to Execute.</div>
</div>
<br>
Superuser Password: <br>
<input type="password" class="form-control" style="width:400px" maxlength="50" [(ngModel)]="Password">
<br>
<input class="form-control textfield" maxlength="20" style="border-radius: 7px" mask="M0/d0/0000"
    [dropSpecialCharacters]="false" placeholder="mm/dd/yyyy" style="width:400px" [(ngModel)]="from" />
<br>
<input class="form-control textfield" maxlength="20" style="border-radius: 7px" mask="M0/d0/0000"
    [dropSpecialCharacters]="false" placeholder="mm/dd/yyyy" style="width:400px" [(ngModel)]="to" />
<br><br>

<button class="btn btn-danger" (click)="runQuery()">RUN QUERY</button>
<br>
<div *ngIf="showGrid">
    <br><br>
    <p-table #dt [value]="reportTransaction" [rows]="1000" [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[100, 200, 500, 1000]" styleClass="p-datatable-customers" [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" [filterDelay]="0"
        [resizableColumns]="true" [autoLayout]="true" selectionMode="single" [exportFilename]="filename"
        [columns]="cols" selectionMode=" multiple">
        <ng-template pTemplate="caption">
            <div class="p-d-flex">


                <button type="button" class="btn btn-primary p-mr-2 margin-right-5"
                    (click)="dt.exportCSV(reportTransaction)"><i class="pi pi-file-o"></i> Export to CSV file</button>
                <button type="button" class="btn btn-warning text-white p-mr-2" (click)="startOver()">Build a new
                    report</button>
            </div>
        </ng-template>
        <ng-template pTemplate="header">


            <br> <br>
            <tr class="FontRow f13">
              
                <th [pSortableColumn]="'clientCode'">clientCode</th>
                <th [pSortableColumn]="'firstName'">firstName</th>
                <th [pSortableColumn]="'lastName'">lastName</th>
                <th [pSortableColumn]="'DOB'">DOB</th>
                <th [pSortableColumn]="'hciMemberId'">hciMemberId</th>
                <th [pSortableColumn]="'PersonCode'">PersonCode</th>
                <th [pSortableColumn]="'planIdentifier'">planIdentifier</th>
                <th [pSortableColumn]="'tierCode'">tierCode</th>
                <th [pSortableColumn]="'planId'">planId</th>
                <th [pSortableColumn]="'productcode'">productCode</th>
                <th [pSortableColumn]="'effectiveDate'">effectiveDate</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-reportTransaction>
            <tr>
                <td class="SmallFont ">{{ reportTransaction.clientCode }}</td>
                <td class="SmallFont ">{{ reportTransaction.firstName }}</td>
                <td class="SmallFont ">{{ reportTransaction.lastName }}</td>
                <td class="SmallFont ">{{ reportTransaction.DOB }}</td>
                <td class="SmallFont ">{{ reportTransaction.hciMemberId }}</td>
                <td class="SmallFont ">{{ reportTransaction.PersonCode }}</td>
                <td class="SmallFont ">{{ reportTransaction.planIdentifier }}</td>
                <td class="SmallFont ">{{ reportTransaction.tierCode }}</td>
                <td class="SmallFont ">{{ reportTransaction.planId }}</td>
                <td class="SmallFont ">{{ reportTransaction.productcode }}</td>
                <td class="SmallFont ">{{ reportTransaction.effectiveDate }}</td>

            </tr>
        </ng-template>


    </p-table>
</div>

<div id="spinner" style="height: 100vh;" *ngIf="ShowSpinner">
    <div class="centered">
        <img src="assets/images/processing.gif" style="width:50px;">
        <br><br>
        <span class="text-white">Updating NIPR data. This may take a few minutes.<br>PLEASE WAIT...</span>
    </div>
</div>