import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'AFEportal';
  APIdata: any;
  constructor(
    private _router: Router,
    private httpClient:HttpClient
  ){
   
    this._router.navigateByUrl("/platform/intro");
  }


}
