<button (click)="Me()" class="btn btn-outline-dark btn-sm"
                [ngClass]="{ 'btn-dark text-white': DownlineType == 'Direct Downline' }"
                style="margin-right: 10px; margin-top: 10px">
                Direct Downline
            </button>
            <button (click)="Full()" class="btn btn-outline-dark btn-sm"
                [ngClass]="{ 'btn-dark text-white': DownlineType == 'Full Downline' }"
                style="margin-right: 10px; margin-top: 10px">
                Full Downline
            </button>
            <button *ngIf="AllowCreate && status =='1'" (click)="newAgent=true" class="btn btn-outline-dark btn-sm"
                style="margin-right: 10px; margin-top: 10px">
                Create a New Agent Under {{APIresult[0].agentinfo.firstname}} {{APIresult[0].agentinfo.lastname}}
            </button>
            <br /><br />
            <div class="alert alert-info">
                <span style="padding-top: 14px">{{ TotalAgents }} Agents Found for {{ DownlineType }}</span>
            </div>

            <ol class="breadcrumb" style="width: 100%; background-color: white;">
                <li *ngFor="let agent of this.APIresult4[0]?.upline" class="breadcrumb-item">
                    <img src="assets/images/icouser.png" style="margin-right: 5px" pTooltip="Summary" height="15px" />
                    <a class="link text-primary mousepointer" style="font-size: 11px;"
                        (click)="SelectAgent(agent.id, 'n')">{{ agent.fullname }}</a>
                </li>
            </ol>

            <div class="minheight">
                <p-table #dt [value]="AgentsList" [rows]="100" [showCurrentPageReport]="true"
                    [rowsPerPageOptions]="[100, 250, 500, 1000]" styleClass="p-datatable-customers" [paginator]="true"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} agents" [filterDelay]="0"
                    [globalFilterFields]="[
                    'fullname',
                    
                    'downline',
                    'companyname',
                    'signupdate',
                    'city',
                    'state',
                    'products',
                    'status',
                    'policies',
                    'statustext'
                  ]" [resizableColumns]="true" [autoLayout]="true">
                    <ng-template pTemplate="header">
                        <tr class="FontRow" style="font-size: 11px !important">
                            <th></th>
                            <th [pSortableColumn]="'companyname'">Company Name</th>
                            <th [pSortableColumn]="'fullname'">Agent Name</th>
                           
                            <th [pSortableColumn]="'downline'">Direct Agents</th>
                            <th [pSortableColumn]="'signupdate'">Created</th>
                            <th [pSortableColumn]="'products'">Products</th>
                            <th [pSortableColumn]="'city'">City</th>
                            <th [pSortableColumn]="'state'">State</th>
                            <th [pSortableColumn]="'policies'">Sales</th>
                            <th [pSortableColumn]="'statustext'">Status</th>
                            <th [pSortableColumn]="''"></th>
                        </tr>
                        <tr>
                            <th></th>
                            <th>
                                <input class="form-control" pInputText type="text"
                                    (input)="dt.filter($event.target.value, 'companyname', 'contains')" />
                            </th>
                            <th>
                                <input class="form-control" pInputText type="text"
                                    (input)="dt.filter($event.target.value, 'fullname', 'contains')" />
                            </th>
                            

                            <th>
                                <input class="form-control" pInputText type="text"
                                    (input)="dt.filter($event.target.value, 'downline', 'equals')" />
                            </th>

                            <th>
                                <input class="form-control" pInputText type="text"
                                    (input)="dt.filter($event.target.value, 'signupdate', 'contains')" />
                            </th>

                            <th>
                                <input class="form-control" pInputText type="text"
                                    (input)="dt.filter($event.target.value, 'products', 'equal')" />
                            </th>

                            <th>
                                <input class="form-control" pInputText type="text"
                                    (input)="dt.filter($event.target.value, 'city', 'contains')" />
                            </th>

                            <th>
                                <input class="form-control" pInputText type="text"
                                    (input)="dt.filter($event.target.value, 'state', 'contains')" />
                            </th>

                            <th>
                                <input class="form-control" pInputText type="text"
                                    (input)="dt.filter($event.target.value, 'sales', 'equals')" />
                            </th>

                            <th>
                                <select class="form-control"
                                    (input)="dt.filter($event.target.value, 'statustext', 'equals')">
                                    <option value="">List All</option>
                                    <option value="Active">Active</option>
                                    <option value="Suspended">Suspended</option>
                                    <option value="Pending">Pending</option>
                                    <option value="Agent Pending">Agent Pending</option>
                                </select>
                            </th>

                            <th></th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-AgentsList let-rowIndex="rowIndex">
                        <tr class="FontRow f13" style="cursor: pointer; font-size: 11px" [ngStyle]="{
                        backgroundColor:
                          AgentsList.status == 0
                            ? '#FFF2B5'
                            : AgentsList.status == 50
                            ? '#EAEAEA'
                            : AgentsList.status == 99
                            ? '#FFE0E0'
                            : 'transparent'
                      }">
                            <td class="SmallFont">{{ rowIndex + 1 }}</td>
                            <td>{{ AgentsList.companyname }}</td>
                            <td class="SmallFont">
                                <a (click)="SelectAgent(AgentsList.id, 'n')" class="mousepointer">
                                    <img src="assets/images/icolupa.png" style="margin-right: 5px" pTooltip="Summary" />
                                    <strong>{{ AgentsList.fullname.toUpperCase() }}</strong>
                                </a>
                            </td>
                           
                            <td class="SmallFont">
                                <div *ngIf="AgentsList.downline > 0">
                                    <a (click)="ListAgents(AgentsList.id, 'direct')" class="mouserpointer"
                                        pTooltip="View Hierarchy">
                                        <img src="assets/images/icotreeview.png" style="margin-right: 5px" />
                                    </a>
                                    {{ AgentsList.downline }} agents
                                </div>
                            </td>

                            <td>{{ AgentsList.signupdate }}</td>
                            <td>
                                <a class="mousepointer" (click)="ShowProducts(AgentsList.id)"
                                    *ngIf="AgentsList.products > 0">
                                    <img src="assets/images/icoproducts.png" style="margin-right: 5px" />
                                    {{ AgentsList.products }}
                                </a>
                            </td>
                            <td>{{ AgentsList.city }}</td>
                            <td>{{ AgentsList.state }}</td>
                            <td>{{ AgentsList.policies }}</td>
                            <td>{{ AgentsList.statustext }}</td>
                            <td class="SmallFont text-right">

                                <a>
                                    <img src="assets/images/icolupa.png" style="margin-right: 5px" pTooltip="Summary"
                                        (click)="SelectAgent(AgentsList.id, 'n')" />
                                </a>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>