<div class="w100">
    <div class="">
        <h4>
            New Custom Code</h4>
        Create a new custom code to set the commission level for multiple insurance products.
        <hr>
        <div class="bShadow-5">
            <div class="row">
                <div class="col-12 col-md-3">
                    <label>Enrollment Code</label>
                    <br>
                    <input (keypress)="keyPressLettersNumbers($event)" style="text-transform:uppercase; " class="form-control"
                        [(ngModel)]="templatecode" type="text" maxlength="20" placeholder="Enter Code">
                </div>
                <div class="col-12 col-md-9">
                    <label>Code Description</label>
                    <input [(ngModel)]="templatedescription" type="text" class="form-control" maxlength="150"
                        placeholder='Enter your code description, for example "Code for Dental at 12%" '>
                </div>
            </div>
            <br>
            <h6>
                Products and Payout Setup
            </h6>
            <app-setcomp [Data]="Data"></app-setcomp>
        </div>
    </div>
</div>
<br><br>
<button (click)="SaveTemplate()" class="btn btn-primary text-white " style="margin-right: 10px;"><i
        class="fa fa-user"></i>
    Save Template</button>
<br><br><br><br><br><br><br><br>