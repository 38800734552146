import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { isNull } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.css']
})

export class MembershipComponent implements OnInit {

  showPP: boolean = false;
  showTOS: boolean = false;

  addToChubbContract: string="";
  public onlyMembership: boolean = false;
  //quote required parameters
  public DOB: string;
  public firstname: string;
  public lastname: string;
  public state: string;
  public email: string;
  public ZIP: string;
  public Temp: string;
  public upassword2: string;
  public origin: string = "";
  public eSignature: string = "";

  //visual variables
  public Step: string = "0";
  public ShowChubbDisclosures: boolean = false;
  public Plan: string = "0";
  public ShowAddChildren: boolean = false;
  public ShowAddBeneficiary: boolean = false;
  public ShowAddContingentBeneficiary: boolean = false;
  public overlapContent: string;
  public lifeStatement1: boolean = false;
  public lifeStatement2: boolean = false;
  public lifeStatement3: boolean = false;
  public dentalStatement: boolean = false;
  public showWife: boolean = false
  public showChild: boolean = false

  //Chubb enrollment disclosures
  public chk1: boolean = false;
  public chk2: boolean = false;
  public chk3: boolean = false;
  public chk4: boolean = false;
  public chk5: boolean = false;
  public chk6: boolean = false;
  public chk7: boolean = false;
  public chk8: boolean = false;
  public chk9: boolean = false;
  public chk11: boolean = false;
  public ReviewDocuments: boolean = false;
  public overlapOpen: boolean = false;
  public groupplan: string;
  public TOS: boolean = false;
  public ShowSpinner: boolean = false;
  public VIPCode: string;

  // Chubb enrollment disclosures if state DC or CA
  public chk10: boolean = true;
  public chk10text: string = "";
  public chk10no: boolean = false;
  public Brochure: string = "MB071420-AP AFEUSA_Lifestyle_Select_Brochure_v24.pdf"

  //API Calls
  public Quote: any; //get quote
  IsProcessing: boolean = false;
  //cart items
  public TotalProducts: number = 0;
  public TotalToDisplay: number = 0;

  public SpouseAge: number

  //API
  ContinuationURL: string = "";
  MemberAccessToken: string = "";
  HCItoken: string = "";
  Endpoint: string = "";


  //employee
  /*
  public middlename: string = "";
  public dob: string = "";
  public gender: string = "";
  public lifeplan: string = "0";
*/
  /*
  public address: string = "";
  public address2: string = "";
  public city: string = "";
  public zip: string = "";
  public phone: string = "";
  public ssn: string = "";
  public marritalstatus: string = "";
  */

  //life primary beneficiaries for filling out class
  public litype: string = "Person";
  public lifirstname: string = "";
  public lilastname: string = "";
  public liaddress: string = "";
  public licity: string = "";
  public lizip: string = "";
  public listate: string = "";

  public lipercentage: number = 0; // selected percentage
  public lisumpercentage: number = 0; //sumatory of %
  public lirestpercentage: number = 101; //remaining % to split

  public liContpercentage: number = 0; // selected percentage
  public liContsumpercentage: number = 0; //sumatory of %
  public liContrestpercentage: number = 101; //remaining % to split


  //contingent beneficiary
  public liconttype: string = "Person";
  public licontfirstname: string = "";
  public licontlastname: string = "";
  public licontaddress: string = "";
  public licontcity: string = "";
  public licontzip: string = "";
  public licontstate: string = "";

  public spadded: boolean = false;


  //children
  public chrelationship: string = "";
  public chfirstname: string = "";
  public chmiddlename: string = "";
  public chlastname: string = "";
  public chgender: string = "";
  public chdob: string = "";
  public chssn: string = "";
  public chphone: string = "";
  public chemail: string = "";
  public chaddress: string = "";
  public chaddress2: string = "";
  public chcity: string = "";
  public chstate: string = "";
  public chzip: string = "";
  public chdisabled: string = "";
  public ShowAddCWife: boolean = false;
  public chAction: any = undefined;



  public USstates: any;


  constructor(
    private _router: Router,
    private r: ActivatedRoute,
    public httpClient: HttpClient,
  ) {

    /*
    this.firstname = "Pablo";
    this.lastname = "Varela";
    this.ZIP = "36036";
    this.email = "pablo@henkomoss.com";
    this.DOB = "05/09/1975";
*/

    this.origin = sessionStorage.getItem('origin')


    if (sessionStorage.getItem('vip') == "afeusa") {
      this.VIPCode = "";
    }
    else {
      this.VIPCode = sessionStorage.getItem('vip')
    }

    //read states
    this.ShowSpinner = true;
    var APIURL = sessionStorage.getItem('endpoint') + "afeapi/sales/states.json";    // set header for http call
    this.httpClient.get(APIURL).subscribe(
      result => {
        this.USstates = result;
      },
      error => {
        this.ShowSpinner = false;
        Swal.fire('Check your Internet connection', "We could not connect to our servers. Please check your internet connection.", 'error');
      },
      () => {
        this.ShowSpinner = false;

      });

    try {
      var Qdata: any = JSON.parse(sessionStorage.getItem('quotedata'));
      this.firstname = Qdata.firstname;
      this.lastname = Qdata.lastname;
      this.DOB = Qdata.dob;
      this.ZIP = Qdata.zip;
      this.email = Qdata.email;
      this.NewQuote();

    }
    catch {
      this.Step = "1";
    }
    sessionStorage.removeItem('quotedata')


  }

  ngOnInit(): void {
    /*
        var key = CryptoJS.MD5('Germania!24485168?EIA#418557424?');
        var message = 'F1kLlh5DCsxUo4p9eakE/Q=='
        const decrypted = CryptoJS.TripleDES.decrypt(
          message,
          key,
          {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
          }
        ).toString(CryptoJS.enc.Utf8);
        console.log("result: " + decrypted.toString())
        return
        */

    //get from SAVED
    if (this.isNumber(sessionStorage.getItem('temp'))) {
      this.Temp = sessionStorage.getItem('temp');
      var APIURL = sessionStorage.getItem('endpoint') + "afeapi/desk/agents/getPendingApp.aspx";    // set header for http call
      let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
      let body = new URLSearchParams();
      body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
      body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
      body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
      body.set('appid', sessionStorage.getItem('temp'));

      this.httpClient.post(APIURL, body.toString(), options).subscribe(
        result => {
          this.Quote = result;
          if (this.Quote.success == "1") {
            sessionStorage.setItem('quotesessionkey', this.Quote.result);
            sessionStorage.setItem('qk', this.Quote.quotekey);
            sessionStorage.setItem('state', this.Quote.user.state);
            sessionStorage.setItem('zip', this.Quote.EmpDemographics.Zip);
            //selects basic membership by default
            this.Step = "2"
            //hardcoded
            this.state = this.Quote.user.state;
            this.chstate = this.Quote.user.state;
            this.chzip = this.ZIP
            this.Calculate();
            this.CalculateBeneficiaries();
            //this._router.navigate(['../applicationEnrollment'], { relativeTo: this.r });
            return;
          }

        },
        error => {

        });
      sessionStorage.removeItem("temp")
    }

    if (sessionStorage.getItem('quoteid')) {
      var APIURL = sessionStorage.getItem('endpoint') + "afeapi/sales/getPendingQuote.aspx";    // set header for http call
      let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
      let body = new URLSearchParams();
      body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
      body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
      body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
      body.set('quoteid', sessionStorage.getItem('quoteid'));

      this.httpClient.post(APIURL, body.toString(), options).subscribe(
        result => {
          this.Quote = result;
          if (this.Quote.success == "1") {
            sessionStorage.setItem('quotesessionkey', this.Quote.result);
            sessionStorage.setItem('qk', this.Quote.quotekey);
            sessionStorage.setItem('state', this.Quote.user.state);
            sessionStorage.setItem('zip', this.Quote.EmpDemographics.Zip);
            //selects basic membership by default
            this.Step = "2"
            //hardcoded
            this.state = this.Quote.user.state;
            this.chstate = this.Quote.user.state;
            this.chzip = this.ZIP
            this.Calculate();
            //this._router.navigate(['../applicationEnrollment'], { relativeTo: this.r });
            return;
          }

        },
        error => {

        });
    }
  }


  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode >= 48 && charCode <= 57) {
      return true;
      return;
    }
    event.preventDefault();
    return false;
  }


  checkData(): void {


    if (!this.firstname) {
      Swal.fire('Enter Your First Name', "", 'warning');
      return;
    }

    if (!this.lastname) {
      Swal.fire('Enter Your Last Name', "", 'warning');
      return;
    }

    if (!this.ZIP) {
      Swal.fire('Enter Your State', "", 'warning');
      return;
    }

    if (this.validateEmail(this.email) == false) {
      Swal.fire('Enter a Valid Email Address', "", 'warning');
      return;
    }

    if (!this.IsDate(this.DOB)) {
      Swal.fire('Enter Your Date of Birth', "", 'warning');
      return
    }






    this.NewQuote();

  }




  NewQuote(): void {

    this.ShowSpinner = true;

    var APIURL = sessionStorage.getItem('endpoint') + "afeapi/sales/step1.aspx";    // set header for http call
    var APIURL = sessionStorage.getItem('endpoint') + "afeapi/sales/newquote.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    let body = new URLSearchParams();
    body.set('agentid', encodeURIComponent(sessionStorage.getItem("AgentPrincipalId")));
    body.set('firstname', encodeURIComponent(this.firstname));
    body.set('lastname', encodeURIComponent(this.lastname));
    body.set('ZIP', encodeURIComponent(this.ZIP));
    body.set('email', encodeURIComponent(this.email));
    body.set('dob', encodeURIComponent(this.DOB));
    body.set('origin', sessionStorage.getItem('origin'));
    body.set('vip', sessionStorage.getItem('vip'));
    body.set('ClientPrincipalId', sessionStorage.getItem('ClientPrincipalId'));
    body.set('AgentPrincipalId', sessionStorage.getItem('AgentPrincipalID'));
    body.set('dsc', encodeURIComponent(sessionStorage.getItem("dsc")));



    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.Quote = result;
        if (result == null) {
          Swal.fire('We cannot process your quote', "We appologize, but we cannot retrieve your quote now. Please try again later. ", 'error');
          return;
        }
        else {
          if (this.Quote.success == "1") {
            sessionStorage.setItem('quotesessionkey', this.Quote.result);
            sessionStorage.setItem('qk', this.Quote.quotekey);
            sessionStorage.setItem('state', this.Quote.user.state);
            sessionStorage.setItem('zip', this.ZIP);
            this.Quote.EmpDemographics.BirthDate = this.DOB;
            this.Quote.EmpDemographics.FirstName = this.firstname
            this.Quote.EmpDemographics.LastName = this.lastname
            this.Quote.EmpDemographics.Zip = this.ZIP
            this.Quote.EmpDemographics.Email = this.email




            this.Step = "2"
            //hardcoded
            this.state = this.Quote.user.state;
            this.chstate = this.Quote.user.state;
            this.chzip = this.ZIP
            if (this.state == "CA") {
              this.Brochure = "MB090220-AP AFEUSA_Lifestyle_Select_Brochure_Calif_v5.pdf"
            }
            //this.Calculate();
            //this._router.navigate(['../applicationEnrollment'], { relativeTo: this.r });
            this.Plan = "1";
            return;
          }
          if (this.Quote.success == "0") {
            Swal.fire(this.Quote.message, "", 'error');
            this.Step = "1";
            return;
          }
          if (this.Quote.success == "99") {
            Swal.fire("Too many quotes detected", this.Quote.message, 'error');

            return;
          }

        }

      },

      error => {
        this.ShowSpinner = false;
        Swal.fire('We cannot process your quote', "We appologize, but we cannot retrieve your quote now. Please try again later.", 'error');
      },
      () => {
        this.ShowSpinner = false;

      });

  }



  TryParse(item): boolean {
    item = typeof item !== "string"
      ? JSON.stringify(item)
      : item;

    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }

    if (typeof item === "object" && item !== null) {
      return true;
    }

    return false;
  }



  IsDate(mydate: string): boolean {
    var isdate = Date.parse(mydate);


    if (!moment(isdate, "MM/DD/YYYY")) {
      return false;
    }

    var EnteredDate = new Date(isdate)
    var TodayIs = new Date()
    if (EnteredDate > TodayIs) {
      return false;
    }
    return true;
  }

  //check if email is valid
  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  //CHUBB CHECKs
  ReviewDocumentsOk(): void {
    this.ReviewDocuments = true;

    window.open('assets/pdf/Consent-e-delivery.pdf');
  }
  ViewDoc(e): void {
    if (e.target.checked) {
      this.ReviewDocuments = true;
      window.open('assets/pdf/Consent-e-delivery.pdf');
    }
  }
  GoQuoteInfo(): void {

    if (this.ReviewDocuments == false) {
      Swal.fire({ title: 'Attention', text: 'Please review the Concent to Electronic Delivery, Disclosures and Signatures under the "Electronic Delivery Disclosure" Term & Conditions in order to move forward with enrollment. (Red Button Above)', icon: 'warning' });
      return
    }
    if (this.chk1 && this.chk2 && this.chk3 && this.chk4 && this.chk5 && this.chk6 && this.chk7 && this.chk8 && this.chk9 && this.chk10 && this.chk11) {
      this.Step = '4';
    }
    else {
      if (this.chk10no) {
        Swal.fire({ title: 'Attention', text: 'If you do not have other health coverage that is minimum essential coverage from an insurance policy, an HMO Plan, or employer Health Benefit Plan and which is required under the Affordable Care Act, the Company is prohibited by law from issuing this insurance policy.', icon: 'warning' });
        return
      }
      Swal.fire({ title: 'Attention', text: 'Please review the above disclosures in order to be eligible for these products.', icon: 'warning' });
    }
  }
  CAchangeY(): void {
    if (this.chk10 == true) { this.chk10no = false }
  }

  CAchangeN(): void {
    if (this.chk10no == true) { this.chk10 = false }
  }


  DeletePlan(PlanID): void {
    if (PlanID != '0') {
      Swal.fire({
        title: 'Delete this plan?',
        text: "Please confirm if you want to delete this plan.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          switch (PlanID) {
            case "1": {
              this.Quote.selections.membership.selection = 0;
              this.Quote.selections.membership.amount = 0;
              this.Quote.selections.membership.col = 0;
              this.Quote.selections.membership.row = 0;
              this.Quote.selections.membership.name = "";
              break;
            }
            case "2": {
              this.Quote.selections.dental.selection = 0;
              this.Quote.selections.dental.amount = 0;
              this.Quote.selections.dental.col = 0;
              this.Quote.selections.dental.row = 0;
              this.Quote.selections.dental.name = "";
              break;
            }
            case "3": {
              this.Quote.selections.medical.selection = 0;
              this.Quote.selections.medical.amount = 0;
              this.Quote.selections.medical.col = 0;
              this.Quote.selections.medical.row = 0;
              this.Quote.selections.medical.name = "";
              break;
            }
            case "4": {
              this.Quote.selections.hospital.selection = 0;
              this.Quote.selections.hospital.amount = 0;
              this.Quote.selections.hospital.col = 0;
              this.Quote.selections.hospital.row = 0;
              this.Quote.selections.hospital.name = "";
              break;
            }
            case "11": {
              this.Quote.selections.hospital65.selection = 0;
              this.Quote.selections.hospital65.amount = 0;
              this.Quote.selections.hospital65.col = 0;
              this.Quote.selections.hospital65.row = 0;
              this.Quote.selections.hospital65.name = "";
              break;
            }
            case "5": {
              this.Quote.selections.critical.selection = 0;
              this.Quote.selections.critical.amount = 0;
              this.Quote.selections.critical.col = 0;
              this.Quote.selections.critical.row = 0;
              this.Quote.selections.critical.name = "";
              break;
            }
            case "12": {
              this.Quote.selections.critical65.selection = 0;
              this.Quote.selections.critical65.amount = 0;
              this.Quote.selections.critical65.col = 0;
              this.Quote.selections.critical65.row = 0;
              this.Quote.selections.critical65.name = "";
              break;
            }
            case "6": {
              this.Quote.selections.life.selection = 0;
              this.Quote.selections.life.amount = 0;
              this.Quote.selections.life.col = 0;
              this.Quote.selections.life.row = 0;
              this.Quote.selections.life.name = "";
              break;
            }
            case "8": {
              this.Quote.selections.stdental.selection = 0;
              this.Quote.selections.stdental.amount = 0;
              this.Quote.selections.stdental.col = 0;
              this.Quote.selections.stdental.row = 0;
              this.Quote.selections.stdental.name = "";
              break;
            }

            case "9": {
              this.Quote.selections.hooray.selection = 0;
              this.Quote.selections.hooray.amount = 0;
              this.Quote.selections.hooray.col = 0;
              this.Quote.selections.hooray.row = 0;
              this.Quote.selections.hooray.name = "";
              break;
            }

            case "10": {
              this.Quote.selections.concordia.selection = 0;
              this.Quote.selections.concordia.amount = 0;
              this.Quote.selections.concordia.col = 0;
              this.Quote.selections.concordia.row = 0;
              this.Quote.selections.concordia.name = "";
              break;
            }

          }

          this.Calculate()
        }

      })
    }
    else {
      this.Plan = '0';
      window.scrollTo(0, 0);
    }
  }

  DoStep3() {

    if (this.TotalToDisplay == this.Quote.selections.membership.amount) {
      if (!this.onlyMembership) {
        Swal.fire('Membership Only Confirmation Missing', "Please confirm that you only want to sign up for the membership, without any insurance product.", 'warning');
        return;
      }

    }

    if (this.Quote.selections.membership.selection == 0) {
      Swal.fire('Select a Membership', "You need to select an AFEUSA membership to continue.", 'warning');
      return;
    }

    this.addToChubbContract="";
    if (this.Quote.selections.hospital65.amount > 0) {
      this.addToChubbContract="ACE";
    }
    if (this.Quote.selections.critical65.amount > 0) {
      this.addToChubbContract="ACE";
    }


    //check if allowed
    var APIURL = sessionStorage.getItem('endpoint') + "afeapi/sales/checkplid.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    let body = new URLSearchParams();
    body.set('payload', JSON.stringify(this.Quote));
    body.set('qk', sessionStorage.getItem('qk'));
    body.set('origin', sessionStorage.getItem('origin'));
    body.set('agentprincipalid', sessionStorage.getItem('AgentPrincipalId'));


    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        let apires: any = result;
        if (apires.success != "1") {
          Swal.fire('We cannot process your application', apires.message, 'error');
          return;
        }
        else {
          this.showChild = false;
          this.showWife = false;
          //dental delta
          var product = this.Quote.selections.dental.row
          if (product == "2") { this.showWife = true; };
          if (product == "3") { this.showWife = true; this.showChild = true; };

          //critical
          product = this.Quote.selections.critical.row
          if (product == "2") { this.showWife = true; };
          if (product == "3") { this.showChild = true; };
          if (product == "4") { this.showWife = true; this.showChild = true; };


          //critical 65
          product = this.Quote.selections.critical65.row
          if (product == "2") { this.showWife = true; };
          if (product == "4") { this.showWife = true; };

          //HOORAY !
          product = this.Quote.selections.hooray.row
          if (product == "2") { this.showWife = true; };
          if (product == "3") { this.showChild = true; };
          if (product == "4") { this.showWife = true; this.showChild = true; };

          //CONCORDIA
          product = this.Quote.selections.concordia.row
          if (product == "2") { this.showWife = true; };
          if (product == "3") { this.showWife = true; this.showChild = true; };

          //AME
          product = this.Quote.selections.medical.row
          if (product == "2") { this.showWife = true; };
          if (product == "3") { this.showChild = true; };
          if (product == "4") { this.showWife = true; this.showChild = true; };
          if (product == "6") { this.showWife = true; };
          if (product == "7") { this.showChild = true; };
          if (product == "8") { this.showWife = true; this.showChild = true; };

          //aship
          product = this.Quote.selections.hospital.row
          if (product == "2") { this.showWife = true; };
          if (product == "3") { this.showChild = true; };
          if (product == "4") { this.showWife = true; this.showChild = true; };
          if (product == "6") { this.showWife = true; };
          if (product == "7") { this.showChild = true; };
          if (product == "8") { this.showWife = true; this.showChild = true; };

          //aship 65
          product = this.Quote.selections.hospital65.row
          if (product == "2") { this.showWife = true; };

          //standard dental
          product = this.Quote.selections.stdental.row
          if (product == "2") { this.showWife = true; };
          if (product == "3") { this.showChild = true; };
          if (product == "4") { this.showWife = true; this.showChild = true; };


          window.scrollTo(0, 0);


          if (this.ShowChubbDisclosures) {
            this.Step = '3';
          }
          else {
            this.Step = '4';
          }
        }

      },
      error => {
        this.ShowSpinner = false;
        Swal.fire('We cannot process your request', "We appologize, but we cannot process your request. Please try again later.", 'error');
        return;
      },
      () => {
        // 'onCompleted' callback.
        // No errors, route to new page here

      });








  }


  isNumber(value: string | number): boolean {
    return ((value != null) &&
      (value !== '') &&
      !isNaN(Number(value.toString())));
  }

  CalculateAge(mydate: string): number {
    var isdate = Date.parse(mydate);
    if (isNaN(isdate)) {
      return 999;
    }

    var EnteredDate = new Date(isdate)
    var TodayIs = new Date()
    if (EnteredDate > TodayIs) {
      return 999;
    }
    var Difference_In_Time = EnteredDate.getTime() - new Date().getTime();
    var Difference_In_Days = ((Difference_In_Time / (1000 * 3600 * 24)) / 365) * (-1);

    return Difference_In_Days;
  }


  //children and spouse

  DeleteChildden(i: number): void {
    Swal.fire({
      title: 'Delete Dependent?',
      text: "Do you want to delete this dependent?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, Delete',
      cancelButtonText: 'No, Keep It'
    }).then((result) => {
      if (result.isConfirmed) {
        this.Quote.DependentDemographics.splice(i, 1);
      }
    })

  }

  editChildren(i: number, Dtype: string) {
    console.log(this.Quote.DependentDemographics)


    this.chmiddlename = this.Quote.DependentDemographics[i].MI;
    this.chrelationship = this.Quote.DependentDemographics[i].Relationship;
    this.chfirstname = this.Quote.DependentDemographics[i].FirstName;
    this.chlastname = this.Quote.DependentDemographics[i].LastName;
    this.chgender = this.Quote.DependentDemographics[i].Gender;
    this.chaddress = this.Quote.DependentDemographics[i].Address1;
    this.chphone = this.Quote.DependentDemographics[i].Phone;
    this.chdob = this.Quote.DependentDemographics[i].BirthDate;
    this.chaddress2 = this.Quote.DependentDemographics[i].Address2;
    this.chcity = this.Quote.DependentDemographics[i].City;
    this.chstate = this.Quote.DependentDemographics[i].State_Name;
    this.chzip = this.Quote.DependentDemographics[i].Zip;
    this.chdisabled = this.Quote.DependentDemographics[i].IsDisabled;
    this.chAction = i
    if (Dtype == 'S') {
      this.ShowAddCWife = true
    }
    else {
      this.ShowAddChildren = true
    }
  }


  AddDependent(DType): void {
    this.chrelationship = "";
    this.chfirstname = "";
    this.chlastname = "";
    this.chmiddlename = "";
    this.chmiddlename = "";
    this.chgender = "";
    this.chdob = "";
    this.chssn = "";
    this.chphone = "";
    this.chemail = "";
    this.chaddress = "";
    this.chaddress2 = "";
    this.chcity = "";
    this.chzip = "";
    this.chAction = undefined;
    this.chdisabled = "";
    this.ShowAddCWife = false;
    this.ShowAddChildren = false;
    this.chaddress = this.Quote.EmpDemographics.Address1;
    this.chaddress2 = this.Quote.EmpDemographics.Address2;
    this.chcity = this.Quote.EmpDemographics.City;
    this.chstate = this.Quote.EmpDemographics.State_Name;
    this.chzip = this.Quote.EmpDemographics.Zip;

    if (DType == 'w') {


      var HasSpouse: boolean = false;
      for (let result of this.Quote.DependentDemographics) {
        if (result.Relationship == 'Spouse') { HasSpouse = true };
        if (result.Relationship == 'Domestic Partner') { HasSpouse = true };
      }
      if (HasSpouse == true) {
        Swal.fire('You already entered a spouse/partner', "You already entered a spouse/partner dependent. Please check your entry.", 'error');
        return;
      }
      this.ShowAddCWife = true
    }
    if (DType == 'c') {
      this.ShowAddChildren = true
    }
  }

  SaveChildren(type: string): void {
    //check inputs
    if (!this.chrelationship) { Swal.fire({ title: 'Enter Relationship', text: '', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (!this.chfirstname) { Swal.fire({ title: 'Enter First Name', text: '', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (!this.chlastname) { Swal.fire({ title: 'Enter Last Name', text: '', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (!this.chgender) { Swal.fire({ title: 'Select Gender', text: '', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (!this.chdob) { Swal.fire({ title: 'Enter Date of Birth', text: '', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (!this.IsDate(this.chdob)) { Swal.fire({ title: 'Enter Date of Birth', text: '', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    /*
    if (type == "S") {
      if (this.chssn.length != 11) { Swal.fire({ title: 'Enter A Valid SSN', text: '', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    }
    */
    if (type == "C") {
      if (!this.chdisabled) { Swal.fire({ title: 'Indicate if Disabled', text: '', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    }
    if (!this.chaddress) { Swal.fire({ title: 'Enter Address', text: '', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (!this.chcity) { Swal.fire({ title: 'Enter City', text: '', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (!this.chstate) { Swal.fire({ title: 'Enter State', text: '', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (!this.chzip) { Swal.fire({ title: 'Enter ZIP Code', text: '', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (this.isNumber(this.chzip) == false) { Swal.fire({ title: 'Enter A Valid US ZIP Code', text: '', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (this.chzip.length < 5) { Swal.fire({ title: 'Enter a valid ZIP Code', text: 'The ZIP code should be 5 characters long', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    /*
    if (!this.chphone) { Swal.fire({ title: 'Enter Phone Number', text: '', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (this.chphone.length != 14) { Swal.fire({ title: 'Enter A Valid US Phone Number', text: '', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
*/
    if (type == "C") {
      var Age = this.CalculateAge(this.chdob)
      if (this.chdisabled == "N" && Age > 26) {
        Swal.fire('You cannot add ' + this.chfirstname, this.chfirstname + " cannot be added to your plan, since children should be 25 years of age or younger", 'error');
        return
      }
      if (this.chdisabled == "Y" && Age > 65) {
        Swal.fire('You cannot add ' + this.chfirstname, this.chfirstname + " cannot be added to your plan, since disabled children should be 65 years of age or younger", 'error');
        return
      }

    }

    if (Age < 18 && type == "S") {
      Swal.fire('Please read', 'Spouse under age 18 cannot enroll on these plans.', 'error');
      return;
    }



    var Age = this.CalculateAge(this.chdob)

    if (type == "S") {
      this.SpouseAge = Age;
      if (this.chdisabled == "N" && Age > 110) {
        Swal.fire('Enter a valid date of birth', 'error');
        return
      }
      if (this.chdisabled == "N" && Age < 16) {
        Swal.fire('Enter a valid date of birth', 'error');
        return
      }

    }

    if (this.Quote.selections.hooray.amount > 0 && Age > 65 && type == "S") {
      Swal.fire('Please read', 'Spouses over the age of 65 can enroll on all plans except on $25 Urgent Care and Accident. Your spouse will be added to your records, but not enrolled on this plan.', 'error');
    }

    if (this.isNumber(this.chAction)) {
      //edit dependent
      this.Quote.DependentDemographics[this.chAction].Relationship = this.chrelationship;
      this.Quote.DependentDemographics[this.chAction].FirstName = this.chfirstname;
      this.Quote.DependentDemographics[this.chAction].LastName = this.chlastname;
      this.Quote.DependentDemographics[this.chAction].Address1 = this.chaddress;
      this.Quote.DependentDemographics[this.chAction].Phone = this.chphone;
      this.Quote.DependentDemographics[this.chAction].BirthDate = this.chdob;
      this.Quote.DependentDemographics[this.chAction].Gender = this.chgender;
      this.Quote.DependentDemographics[this.chAction].Address2 = this.chaddress2;
      this.Quote.DependentDemographics[this.chAction].City = this.chcity;
      this.Quote.DependentDemographics[this.chAction].State_Name = this.chstate;
      this.Quote.DependentDemographics[this.chAction].Zip = this.chzip;
      this.Quote.DependentDemographics[this.chAction].IsDisabled = this.chdisabled;
      this.Quote.DependentDemographics[this.chAction].MI = this.chmiddlename;
      this.chAction = undefined;
    }
    else {
      //add dependent
      if (this.chrelationship == "Spouse" || this.chrelationship == "Domestic Partner" || this.chrelationship == "Spouse Divorced") {

        var HasSpouse: boolean = false;
        for (let result of this.Quote.DependentDemographics) {
          if (result.Relationship == 'Spouse') { HasSpouse = true };
          if (result.Relationship == 'Domestic Partner') { HasSpouse = true };
          if (result.Relationship == 'Spouse Divorced') { HasSpouse = true };
        }

        if (HasSpouse == true) {
          Swal.fire('You already entered a spouse/partner', "You already entered a spouse/partner dependent. Please check your entry.", 'error');



          this.chrelationship = "";
          this.chfirstname = "";
          this.chlastname = "";
          this.chmiddlename = "";
          this.chgender = "";
          this.chdob = "";
          this.chssn = "";
          this.chphone = "";
          this.chemail = "";
          this.chaddress = "";
          this.chaddress2 = "";
          this.chcity = "";
          this.chzip = "";
          this.chdisabled = ""
          this.chmiddlename = ""

          this.chdisabled = "";
          this.ShowAddCWife = false;
          this.ShowAddChildren = false;

          return;
        }

      }


      let newObj = this.USstates.filter((value) => {
        return value.Code.indexOf(this.chstate) != -1 ? value : null
      });
      let EMPStateCode: number = newObj[0].Id;
      let EMPStateName: string = newObj[0].Name


      let IsDisabled: boolean
      if (this.chdisabled == "Y") { IsDisabled = true } else { IsDisabled = false }
      let dep = {
        "AFEtype": type,
        "PersonCode": "",
        "ActionType": "ADD",
        "ESSN": "",
        "SSN": this.chssn,
        "FirstName": this.chfirstname,
        "LastName": this.chlastname,
        "MI": this.chmiddlename,
        "BirthDate": this.chdob,
        "Gender": this.chgender,
        "Relationship": this.chrelationship,
        "Address1": this.chaddress,
        "Address2": this.chaddress2,
        "City": this.chcity,
        "State": EMPStateCode,
        "State_Name": EMPStateName,
        "Zip": this.chzip,
        "Country": "US",
        "Phone": this.chphone,
        "Email": this.chemail,
        "IsDisabled": this.chdisabled,
        "age": Age
      }

      //marks spouse as added
      //if (type == "S") { this.spadded = true };

      this.Quote.DependentDemographics.push(dep);
      //this.children.push(child);
    }


    this.chrelationship = "";
    this.chfirstname = "";
    this.chlastname = "";
    this.chmiddlename = "";
    this.chgender = "";
    this.chdob = "";
    this.chssn = "";
    this.chphone = "";
    this.chemail = "";
    this.chaddress = "";
    this.chaddress2 = "";
    this.chcity = "";
    this.chzip = "";
    this.chAction = undefined;
    this.chdisabled = "";
    this.ShowAddCWife = false;
    this.ShowAddChildren = false;

  }


  //LIFE INSURANCE BENEFICIARIES
  SaveLifeContingent(): void {
    if (!this.lilastname) { Swal.fire({ title: 'Enter Last Name', text: '', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (!this.liaddress) { Swal.fire({ title: 'Enter Address', text: '', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (!this.licity) { Swal.fire({ title: 'Enter City', text: '', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (!this.listate) { Swal.fire({ title: 'Enter State', text: '', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (!this.lizip) { Swal.fire({ title: 'Enter ZIP Code', text: '', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }

    let newObj = this.USstates.filter((value) => {
      return value.Code.indexOf(this.listate) != -1 ? value : null
    });
    let EMPStateCode: number = newObj[0].Id;
    let EMPStateName: string = newObj[0].Name

    if (this.liContpercentage == 0) {
      Swal.fire('Select a Percentage', "Selected % cannot be zero", 'warning');
      return;
    }
    else {
      let SelPlan
      if (this.Quote.selections.life.selection == "25") {
        SelPlan = "08b159bb-707e-48a4-a7cd-8b3588756528"
      }
      if (this.Quote.selections.life.selection == "75") {
        SelPlan = "5c5ccb7b-9ba9-489c-bb54-064303a4fec3"
      }
      let benef = {
        "BeneficiaryId": "",
        "Type": this.litype,
        "BType": "C",
        "LastName": this.lilastname,
        "FirstName": this.lifirstname,
        "Address1": this.liaddress,
        "City": this.licity,
        "State": EMPStateCode,
        "State_Name": EMPStateName,
        "Zip": this.lizip,
        "Allocations": [{
          "PlanPrincipalId": SelPlan,
          "Percentage": this.liContpercentage,
          "BeneType": 2
        }]
      }

      this.Quote.Beneficiaries.push(benef);
      this.ShowAddContingentBeneficiary = false;
      this.lipercentage = 0;
      this.litype = "Person";
      this.lifirstname = "";
      this.lilastname = "";
      this.liaddress = "";
      this.licity = "";
      this.lizip = "";
      this.liContsumpercentage = 0;
      this.liContrestpercentage = 100;
      for (let result of this.Quote.Beneficiaries) {
        if (result.BType == "C") {
          let Allocation: any = result.Allocations;
          let Percent: number = parseInt(Allocation[0].Percentage)
          this.liContsumpercentage = this.liContsumpercentage + Percent;
        }
      }
      this.liContrestpercentage = 100 - this.liContsumpercentage + 1;
    }

  }

  SaveLifePrimary(): void {
    if (!this.lilastname) { Swal.fire({ title: 'Enter Last Name', text: '', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (!this.liaddress) { Swal.fire({ title: 'Enter Address', text: '', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (!this.licity) { Swal.fire({ title: 'Enter City', text: '', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (!this.listate) { Swal.fire({ title: 'Enter State', text: '', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (!this.lizip) { Swal.fire({ title: 'Enter ZIP Code', text: '', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }


    let newObj = this.USstates.filter((value) => {
      return value.Code.indexOf(this.listate) != -1 ? value : null
    });
    let EMPStateCode: number = newObj[0].Id;
    let EMPStateName: string = newObj[0].Name

    if (this.lipercentage == 0) {
      Swal.fire('Select a Percentage', "Selected % cannot be zero", 'warning');
      return;
    }
    else {
      let SelPlan
      if (this.Quote.selections.life.selection == "25") {
        SelPlan = "08b159bb-707e-48a4-a7cd-8b3588756528"
      }
      if (this.Quote.selections.life.selection == "75") {
        SelPlan = "5c5ccb7b-9ba9-489c-bb54-064303a4fec3"
      }
      let benef = {
        "BeneficiaryId": "",
        "Type": this.litype,
        "BType": "P",
        "LastName": this.lilastname,
        "FirstName": this.lifirstname,
        "Address1": this.liaddress,
        "City": this.licity,
        "State": EMPStateCode,
        "State_Name": EMPStateName,
        "Zip": this.lizip,
        "Allocations": [{
          "PlanPrincipalId": SelPlan,
          "Percentage": this.lipercentage,
          "BeneType": 1,
        }]
      }

      this.Quote.Beneficiaries.push(benef);
      this.ShowAddBeneficiary = false;
      this.lipercentage = 0;
      this.litype = "Person";
      this.lifirstname = "";
      this.lilastname = "";
      this.liaddress = "";
      this.licity = "";
      this.lizip = "";
      this.lisumpercentage = 0;
      this.lirestpercentage = 100;
      for (let result of this.Quote.Beneficiaries) {
        if (result.BType == "P") {
          let Allocation: any = result.Allocations;
          let Percent: number = parseInt(Allocation[0].Percentage)
          this.lisumpercentage = this.lisumpercentage + Percent;
        }
      }
      this.lirestpercentage = 100 - this.lisumpercentage + 1;
    }

  }


  CalculateBeneficiaries(): void {
    this.lisumpercentage = 0;
    this.lirestpercentage = 100;
    for (let result of this.Quote.Beneficiaries) {
      if (result.BType == "P") {
        let Allocation: any = result.Allocations;
        let Percent: number = parseInt(Allocation[0].Percentage)
        this.lisumpercentage = this.lisumpercentage + Percent;
      }
    }
    this.lirestpercentage = 100 - this.lisumpercentage + 1;

    this.liContsumpercentage = 0;
    this.liContrestpercentage = 100;
    for (let result of this.Quote.Beneficiaries) {
      if (result.BType == "C") {
        let Allocation: any = result.Allocations;
        let Percent: number = parseInt(Allocation[0].Percentage)
        this.liContsumpercentage = this.liContsumpercentage + Percent;
      }
    }
    this.liContrestpercentage = 100 - this.liContsumpercentage + 1;

  }
  DeleteBeneficiary(i: number): void {
    Swal.fire({
      title: 'Delete Beneficiary?',
      text: "Do you want to delete this beneficiary?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, Delete',
      cancelButtonText: 'No, Keep It'
    }).then((result) => {
      if (result.isConfirmed) {
        this.Quote.Beneficiaries.splice(i, 1);
        this.lisumpercentage = 0;
        this.lirestpercentage = 100;
        for (let result of this.Quote.Beneficiaries) {
          if (result.BType == "P") {
            let Allocation: any = result.Allocations;
            let Percent: number = parseInt(Allocation[0].Percentage)
            this.lisumpercentage = this.lisumpercentage + Percent;
          }
        }
        this.lirestpercentage = 100 - this.lisumpercentage + 1;
      }
    })

  }

  DeleteContingentBeneficiary(i: number): void {
    Swal.fire({
      title: 'Delete Contingent Beneficiary?',
      text: "Do you want to delete this beneficiary?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, Delete',
      cancelButtonText: 'No, Keep It'
    }).then((result) => {
      if (result.isConfirmed) {

        this.Quote.Beneficiaries.splice(i, 1);
        this.liContsumpercentage = 0;
        this.liContrestpercentage = 100;
        for (let result of this.Quote.Beneficiaries) {
          if (result.BType == "C") {
            let Allocation: any = result.Allocations;
            let Percent: number = parseInt(Allocation[0].Percentage)
            this.liContsumpercentage = this.liContsumpercentage + Percent;
          }
        }
        this.liContrestpercentage = 100 - this.liContsumpercentage + 1;
      }
    })

  }


  // SAVE FOR LATER
  SaveForLater(): void {

    this.Quote.ClientPrincipalId = sessionStorage.getItem('ClientPrincipalID');
    this.Quote.AgentPrincipalId = sessionStorage.getItem('AgentPrincipalId');

    if (!this.Quote.EmpDemographics.FirstName) { Swal.fire({ title: 'First Name missing', text: 'Please enter your first name', icon: 'warning', timer: 4000, showConfirmButton: false }); return; }
    if (!this.Quote.EmpDemographics.LastName) { Swal.fire({ title: 'Last Name missing', text: 'Please enter your last name', icon: 'warning', timer: 4000, showConfirmButton: false }); return; }
    if (!this.Quote.EmpDemographics.BirthDate) { Swal.fire({ title: 'DOB missing', text: 'Please enter your DOB', icon: 'warning', timer: 4000, showConfirmButton: false }); return; }
    if (!this.validateEmail(this.Quote.EmpDemographics.Email)) { Swal.fire({ title: 'Email missing', text: 'Enter a valid email address', icon: 'warning', timer: 4000, showConfirmButton: false }); return; }


    Swal.fire({
      title: 'Save Application?',
      text: "Save this application and continue it later?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Save It'
    }).then((result) => {
      if (result.isConfirmed) {




        var APIURL = sessionStorage.getItem('endpoint') + "afeapi/sales/saveforlater.aspx";    // set header for http call
        let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
        let body = new URLSearchParams();
        body.set('payload', JSON.stringify(this.Quote));
        body.set('qk', sessionStorage.getItem('qk'));
        body.set('origin', sessionStorage.getItem('origin'));
        body.set('totalproducts', this.TotalProducts.toString());
        body.set('totalmoney', this.TotalToDisplay.toString());
        body.set('appid', this.Temp);
        body.set('vip', sessionStorage.getItem('vip'));

        this.httpClient.post(APIURL, body.toString(), options).subscribe(
          result => {
            var APIresult: any = result;
            if (result == null) {
              Swal.fire('We cannot process your request', "We appologize, but we cannot process your request. Please try again later. ", 'error');
              return;
            }
            if (APIresult.success == "1") {

              if (sessionStorage.getItem('origin') == 'backoffice') {
                Swal.fire('Application Saved', "Application Saved. You can retrieve this application from the Pending Applications menu. ", 'success');
                this._router.navigate(['../pendingapplications'], { relativeTo: this.r });
              }
              else {
                Swal.fire('Application Saved', "Application Saved. You can retrieve this application from the link sent to your email address. ", 'success');
                this._router.navigate(['../default'], { relativeTo: this.r });

              }

            }
            if (APIresult.success == "99") {
              this.ShowSpinner = false;
              Swal.fire('We cannot process your request', APIresult.message, 'error');

            }
          },
          error => {
            this.ShowSpinner = false;
            Swal.fire('We cannot process your request', "We appologize, but we cannot process your request. Please try again later.", 'error');
            return;
          },
          () => {
            // 'onCompleted' callback.
            // No errors, route to new page here

          });


      }
    })

  }

  // calculation of plans

  Calculate(): void {
    this.TotalProducts = 0;
    this.TotalToDisplay = 0;
    this.ShowChubbDisclosures = false;
    //Plan
    if (this.Quote.selections.membership.amount > 0) {
      this.TotalProducts = this.TotalProducts + 1;
      this.TotalToDisplay = this.TotalToDisplay + this.Quote.selections.membership.amount
    }
    //Plan
    if (this.Quote.selections.dental.amount > 0) {
      this.TotalProducts = this.TotalProducts + 1;
      this.TotalToDisplay = this.TotalToDisplay + this.Quote.selections.dental.amount
    }
    //Plan
    if (this.Quote.selections.stdental.amount > 0) {
      this.TotalProducts = this.TotalProducts + 1;
      this.TotalToDisplay = this.TotalToDisplay + this.Quote.selections.stdental.amount
    }
    //Plan
    if (this.Quote.selections.medical.amount > 0) {
      this.TotalProducts = this.TotalProducts + 1;
      this.TotalToDisplay = this.TotalToDisplay + this.Quote.selections.medical.amount;
      this.ShowChubbDisclosures = true;
    }
    //Plan
    if (this.Quote.selections.hospital.amount > 0) {
      this.TotalProducts = this.TotalProducts + 1;
      this.TotalToDisplay = this.TotalToDisplay + this.Quote.selections.hospital.amount
      this.ShowChubbDisclosures = true;
    }
    //Plan
    if (this.Quote.selections.critical.amount > 0) {
      this.TotalProducts = this.TotalProducts + 1;
      this.TotalToDisplay = this.TotalToDisplay + this.Quote.selections.critical.amount;
      this.ShowChubbDisclosures = true;
    }
    //Plan
    if (this.Quote.selections.life.amount > 0) {
      this.TotalProducts = this.TotalProducts + 1;
      this.TotalToDisplay = this.TotalToDisplay + this.Quote.selections.life.amount
    }

    //Plan
    if (this.Quote.selections.hooray.amount > 0) {
      this.TotalProducts = this.TotalProducts + 1;
      this.TotalToDisplay = this.TotalToDisplay + this.Quote.selections.hooray.amount
    }

    //Plan
    if (this.Quote.selections.concordia.amount > 0) {
      this.TotalProducts = this.TotalProducts + 1;
      this.TotalToDisplay = this.TotalToDisplay + this.Quote.selections.concordia.amount
    }

    //Plan
    if (this.Quote.selections.critical65.amount > 0) {
      this.TotalProducts = this.TotalProducts + 1;
      this.TotalToDisplay = this.TotalToDisplay + this.Quote.selections.critical65.amount;
      this.ShowChubbDisclosures = true;
    }

    //Plan
    if (this.Quote.selections.hospital65.amount > 0) {
      this.TotalProducts = this.TotalProducts + 1;
      this.TotalToDisplay = this.TotalToDisplay + this.Quote.selections.hospital65.amount
      this.ShowChubbDisclosures = true;
    }

    //Any Group Plan Selected?
    this.groupplan = "0"
    if (this.Quote.selections.dental.row > 1) { this.groupplan = "1" }
    if (this.Quote.selections.stdental.row > 1) { this.groupplan = "1" }
    if (this.Quote.selections.medical.row > 1) { this.groupplan = "1" }
    if (this.Quote.selections.hospital.row > 1) { this.groupplan = "1" }
    if (this.Quote.selections.critical.row > 1) { this.groupplan = "1" }
    if (this.Quote.selections.hooray.row > 1) { this.groupplan = "1" }
    if (this.Quote.selections.concordia.row > 1) { this.groupplan = "1" }
    if (this.Quote.selections.critical65.row == 2) { this.groupplan = "1" }
    if (this.Quote.selections.critical65.row == 4) { this.groupplan = "1" }
    if (this.Quote.selections.hospital65.row == 2) { this.groupplan = "1" }




    this.Plan = '0';
    window.scrollTo(0, 0);

  }



  AddBeneficiaries(Btype): void {
    this.liaddress = this.Quote.EmpDemographics.Address1;
    this.licity = this.Quote.EmpDemographics.City;
    this.listate = this.Quote.EmpDemographics.State_Name;
    this.lizip = this.Quote.EmpDemographics.Zip;
    if (Btype == 'p') {
      this.ShowAddBeneficiary = true
    }
    if (Btype == 'c') {
      this.ShowAddContingentBeneficiary = true
    }
  }


  Finish(): void {

    //alert(this.Quote.EmpDemographics.Phone.toString().length)
    //return;
    this.Quote.RegistrationDetails.UserName = this.Quote.EmpDemographics.Email;
    /*this.showChild = false;
    this.showWife = false;
*/

    if (this.showWife) {
      let hasWifeEntered: boolean = false;
      for (let result of this.Quote.DependentDemographics) {
        if (result.AFEtype == "S") {
          hasWifeEntered = true;
        }
      }
      if (hasWifeEntered == false) {
        Swal.fire('Enter your spouse information', '', 'warning');
        return;
      }
    }

    if (this.showChild) {
      let hasChildEntered: boolean = false;
      for (let result of this.Quote.DependentDemographics) {
        if (result.AFEtype == "C") {
          hasChildEntered = true;
        }
      }
      if (hasChildEntered == false) {
        Swal.fire('Enter your child(ren) information', '', 'warning');
        return;
      }
    }

    if (sessionStorage.getItem('agentname')) {
      this.Quote.selections.additional.agentname = sessionStorage.getItem('agentname')
    }
    if (!this.Quote.EmpDemographics.FirstName) { Swal.fire({ title: 'First Name missing', text: 'Please enter your first name', icon: 'warning', timer: 4000, showConfirmButton: false }); return; }
    if (!this.Quote.EmpDemographics.LastName) { Swal.fire({ title: 'Last Name missing', text: 'Please enter your last name', icon: 'warning', timer: 4000, showConfirmButton: false }); return; }
    if (!this.Quote.EmpDemographics.BirthDate) { Swal.fire({ title: 'DOB missing', text: 'Please enter your DOB', icon: 'warning', timer: 4000, showConfirmButton: false }); return; }
    if (!this.Quote.EmpDemographics.Address1) { Swal.fire({ title: 'Address missing', text: 'Please enter your address', icon: 'warning', timer: 4000, showConfirmButton: false }); return; }
    if (!this.Quote.EmpDemographics.City) { Swal.fire({ title: 'City missing', text: 'Please enter your city', icon: 'warning', timer: 4000, showConfirmButton: false }); return; }
    if (!this.Quote.EmpDemographics.Phone) { Swal.fire({ title: 'Phone missing', text: 'Please enter your phone number', icon: 'warning', timer: 4000, showConfirmButton: false }); return; }
    if (!this.Quote.EmpDemographics.Gender) { Swal.fire({ title: 'Gender missing', text: 'Please select your gender', icon: 'warning', timer: 4000, showConfirmButton: false }); return; }
    if (!this.Quote.EmpDemographics.MaritalStatus) { Swal.fire({ title: 'Marrital status missing', text: 'Please indicate your marrital status', icon: 'warning', timer: 4000, showConfirmButton: false }); return; }
    if (!this.validateEmail(this.Quote.EmpDemographics.Email)) { Swal.fire({ title: 'Email missing', text: 'Enter a valid email address', icon: 'warning', timer: 4000, showConfirmButton: false }); return; }
    if (!this.validateEmail(this.Quote.RegistrationDetails.UserName)) { Swal.fire({ title: 'Login email missing', text: 'Enter the email address you will utilize to login to your account', icon: 'warning', timer: 4000, showConfirmButton: false }); return; }
    if (this.Quote.EmpDemographics.Phone.toString().length != 10) { Swal.fire({ title: 'Invalid Phone Number', text: 'Please enter a valid phone number', icon: 'warning', timer: 4000, showConfirmButton: false }); return; }

    if (!this.Quote.EmpDemographics.ESSN) { Swal.fire({ title: 'SSN missing', text: 'Please enter your SSN', icon: 'warning', timer: 4000, showConfirmButton: false }); return; }
    if (this.Quote.EmpDemographics.ESSN.length < 9) { Swal.fire({ title: 'Enter A Valid SSN', text: '', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }

    if (!this.Quote.RegistrationDetails.Password) { Swal.fire({ title: 'Enter Password', text: 'Enter the password you want to utilize to access your account', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (this.Quote.RegistrationDetails.Password.length < 8) { Swal.fire({ title: 'Enter Password', text: 'Your password should be at least 8 characters long', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (this.Quote.RegistrationDetails.Password != this.upassword2) { Swal.fire({ title: 'Password Do Not Match', text: 'Your password and password confirmation do not match', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }

    if (this.origin == 'backoffice') {
      if (!this.Quote.EmpDemographics.MothersMaidenName) {
        Swal.fire("Enter Mother's Maiden Name", "Enter Member's Mother's Maiden Name to continue.", 'warning');
        return
      }
    }


    //all checks done, start processing

    let newObj = this.USstates.filter((value) => {
      return value.Code.indexOf(this.state) != -1 ? value : null
    });
    let EMPStateCode: number = newObj[0].Id;
    let EMPStateName: string = newObj[0].Name

    this.Quote.EmpDemographics.State = EMPStateCode;
    this.Quote.EmpDemographics.State_Name = EMPStateName;
    this.Quote.EmpDemographics.Location = this.state;
    this.Quote.EmpDemographics.Area = this.Quote.EmpDemographics.Zip.substr(0, 3);
    //REdo Beneficiaries for PERSONCODE
    let myindex: number = 0;
    for (let result of this.Quote.Beneficiaries) {
      myindex = myindex + 1;
      let stringindex: string = "0" + myindex;
      stringindex = stringindex.slice(stringindex.length - 2, stringindex.length) //right 2 characters
      result.BeneficiaryId = stringindex;
      let Allocation: any = result.Allocations;
      Allocation[0].BeneficiaryId = stringindex;
    }
    //redo Dependents for PERSONCODE
    myindex = 0;
    for (let result of this.Quote.DependentDemographics) {
      myindex = myindex + 1;
      let stringindex: string = "0" + myindex;
      stringindex = stringindex.slice(stringindex.length - 2, stringindex.length) //right 2 characters
      result.PersonCode = stringindex;
    }

    //agent
    this.Quote.ClientPrincipalId = sessionStorage.getItem('ClientPrincipalID');
    this.Quote.AgentPrincipalId = sessionStorage.getItem('AgentPrincipalId');

    //products selection
    this.Quote.ProductSelections.splice(0, 100);

    //first day of next month
    const EffectiveDate = moment().add(1, 'M').startOf('month').format('MM/DD/YYYY');

    this.Quote.extras = {};
    this.Quote.extras.hci = {};
    if (sessionStorage.getItem("params")) {
      this.Quote.extras.hci = JSON.parse(sessionStorage.getItem("params"));
    }
    this.Quote.extras.afe = {};
    if (sessionStorage.getItem("vip")) {
      this.Quote.extras.afe.vipcode = sessionStorage.getItem("vip");
    }
    this.Quote.extras.afe.firstname = this.Quote.EmpDemographics.FirstName;
    this.Quote.extras.afe.lastname = this.Quote.EmpDemographics.LastName;

    //membership
    //***************************/

    let product: string;
    let SelPlan: string;
    let SelTier: string;
    let memProduct: number;
    if (this.Quote.selections.membership.amount > 0) {

      memProduct = 1;
      if (this.Quote.selections.membership.selection == 1) { SelPlan = ""; product = "1" }
      if (this.Quote.selections.membership.selection == 7) { SelPlan = ""; product = "7" }
      var mySelPlan = product;
      let productJson =
      {
        "ProductId": memProduct,
        "AFEprodesk": "_MEMBERSHIP",
        "AFEproduct": product,
        "AFEplan": product,
        "SelectedPlanId": SelPlan,
        "SelectedTierCode": "IND",
        "EffectiveDate": EffectiveDate,
        "MemberElections": [
          {
            "PersonCode": "00",
            "CurrentlyElecting": true,
          }
        ]
      }
      for (let result of this.Quote.DependentDemographics) {
        let MemElectNew = {
          "PersonCode": "",
          "CurrentlyElecting": true,
        }
        //if individual + 1
        if (SelTier == "IND+1") {
          if (result.AFEtype == "S") {
            MemElectNew.PersonCode = result.PersonCode;
            productJson.MemberElections.push(MemElectNew)
          }
        }
        //if family
        if (SelTier == "FAM") {
          MemElectNew.PersonCode = result.PersonCode;
          productJson.MemberElections.push(MemElectNew)
        }
      }
      this.Quote.ProductSelections.push(productJson)
    }



    //DENTAL
    //***************************/
    if (this.Quote.selections.dental.amount > 0) {
      product = this.Quote.selections.dental.row
      SelPlan = this.Quote.selections.dental.col
      //TIER
      if (product == "1") { SelTier = "IND" };
      if (product == "2") { SelTier = "IND+1"; this.showWife = true; };
      if (product == "3") { SelTier = "FAM"; this.showWife = true; this.showChild = true; };
      //PLAN
      var mySelPlan = SelPlan;

      let productJson =
      {
        "ProductId": 2,
        "AFEprodesk": "_DELTA",
        "AFEproduct": product,
        "AFEplan": mySelPlan,
        "SelectedPlanId": SelPlan,
        "SelectedTierCode": SelTier,
        "EffectiveDate": EffectiveDate,
        "MemberElections": [
          {
            "PersonCode": "00",
            "CurrentlyElecting": true,
          }
        ]
      }

      for (let result of this.Quote.DependentDemographics) {
        let MemElectNew = {
          "PersonCode": "",
          "CurrentlyElecting": true,
        }
        //if individual + 1
        if (SelTier == "IND+1") {
          if (result.AFEtype == "S") {
            MemElectNew.PersonCode = result.PersonCode;
            productJson.MemberElections.push(MemElectNew)
          }
        }
        //if family
        if (SelTier == "FAM") {
          MemElectNew.PersonCode = result.PersonCode;
          productJson.MemberElections.push(MemElectNew)
        }
      }
      this.Quote.ProductSelections.push(productJson)

    }


    //CRITICAL
    //***************************/
    if (this.Quote.selections.critical.amount > 0) {
      product = this.Quote.selections.critical.row
      SelPlan = this.Quote.selections.critical.col
      var mySelPlan = SelPlan;
      //TIER
      if (product == "1") { SelTier = "IND" };
      if (product == "2") { SelTier = "ISP"; this.showWife = true; };
      if (product == "3") { SelTier = "ICH"; this.showChild = true; };
      if (product == "4") { SelTier = "FAM"; this.showWife = true; this.showChild = true; };
      //PLAN

      let productJson =
      {
        "ProductId": 5,
        "AFEprodesk": "_CRITICAL",
        "AFEproduct": product,
        "AFEplan": mySelPlan,
        "SelectedPlanId": SelPlan,
        "SelectedTierCode": SelTier,
        "EffectiveDate": EffectiveDate,
        "MemberElections": [
          {
            "PersonCode": "00",
            "CurrentlyElecting": true,
          }
        ]
      }
      for (let result of this.Quote.DependentDemographics) {
        let MemElectNew = {
          "PersonCode": "",
          "CurrentlyElecting": true,
        }
        //if spouse
        if (SelTier == "ISP") {
          if (result.AFEtype == "S") {
            MemElectNew.PersonCode = result.PersonCode;
            productJson.MemberElections.push(MemElectNew)
          }
        }
        //if children
        if (SelTier == "ICH") {
          if (result.AFEtype == "C") {
            MemElectNew.PersonCode = result.PersonCode;
            productJson.MemberElections.push(MemElectNew)
          }
        }
        //if family
        if (SelTier == "FAM") {
          MemElectNew.PersonCode = result.PersonCode;
          productJson.MemberElections.push(MemElectNew)
        }
      }
      this.Quote.ProductSelections.push(productJson)
    }







    //CRITICAL 65
    //***************************/
    if (this.Quote.selections.critical65.amount > 0) {
      product = this.Quote.selections.critical65.row
      SelPlan = this.Quote.selections.critical65.col
      var mySelPlan = SelPlan;
      //TIER
      if (product == "1") { SelTier = "IND" };
      if (product == "2") { SelTier = "ISP"; this.showWife = true; };

      //PLAN

      let productJson =
      {
        "ProductId": 11,
        "AFEprodesk": "_CRITICAL65",
        "AFEproduct": product,
        "AFEplan": mySelPlan,
        "SelectedPlanId": SelPlan,
        "SelectedTierCode": SelTier,
        "EffectiveDate": EffectiveDate,
        "MemberElections": [
          {
            "PersonCode": "00",
            "CurrentlyElecting": true,
          }
        ]
      }
      for (let result of this.Quote.DependentDemographics) {
        let MemElectNew = {
          "PersonCode": "",
          "CurrentlyElecting": true,
        }
        //if spouse
        if (SelTier == "ISP") {
          if (result.AFEtype == "S") {
            MemElectNew.PersonCode = result.PersonCode;
            productJson.MemberElections.push(MemElectNew)
          }
        }
      }
      this.Quote.ProductSelections.push(productJson)
    }






    //AME
    //***************************/
    if (this.Quote.selections.medical.amount > 0) {
      let HCIproductid: number
      product = this.Quote.selections.medical.row
      SelPlan = this.Quote.selections.medical.col
      var mySelPlan = SelPlan;

      //TIER
      if (product == "1") { SelTier = "IND" };
      if (product == "2") { SelTier = "ISP"; this.showWife = true; };
      if (product == "3") { SelTier = "ICH"; this.showChild = true; };
      if (product == "4") { SelTier = "FAM"; this.showWife = true; this.showChild = true; };
      if (product == "5") { SelTier = "IND" };
      if (product == "6") { SelTier = "ISP"; this.showWife = true; };
      if (product == "7") { SelTier = "ICH"; this.showChild = true; };
      if (product == "8") { SelTier = "FAM"; this.showWife = true; this.showChild = true; };

      if (product == "5" || product == "6" || product == "7" || product == "8") {
        if (SelPlan == "1") { SelPlan = "5" }
        if (SelPlan == "2") { SelPlan = "6" }
        if (SelPlan == "3") { SelPlan = "7" }
        if (SelPlan == "4") { SelPlan = "8" }
      }


      let productJson =
      {
        "ProductId": 3,
        "AFEprodesk": "_MEDICAL",
        "AFEproduct": product,
        "AFEplan": mySelPlan,
        "SelectedPlanId": SelPlan,
        "SelectedTierCode": SelTier,
        "EffectiveDate": EffectiveDate,
        "MemberElections": [
          {
            "PersonCode": "00",
            "CurrentlyElecting": true,
          }
        ]
      }
      for (let result of this.Quote.DependentDemographics) {
        let MemElectNew = {
          "PersonCode": "",
          "CurrentlyElecting": true,
        }
        //if spouse
        if (SelTier == "ISP") {
          if (result.AFEtype == "S") {
            MemElectNew.PersonCode = result.PersonCode;
            productJson.MemberElections.push(MemElectNew)
          }
        }
        //if children
        if (SelTier == "ICH") {
          if (result.AFEtype == "C") {
            MemElectNew.PersonCode = result.PersonCode;
            productJson.MemberElections.push(MemElectNew);
            this.showChild = true;
          }
        }
        //if family
        if (SelTier == "FAM") {
          MemElectNew.PersonCode = result.PersonCode;
          productJson.MemberElections.push(MemElectNew)
          this.showWife = true; this.showChild = true;
        }
      }
      this.Quote.ProductSelections.push(productJson)

    }


    //aship
    //***************************/
    if (this.Quote.selections.hospital.amount > 0) {
      let HCIproductid: number
      product = this.Quote.selections.hospital.row
      SelPlan = this.Quote.selections.hospital.col
      //TIER
      if (product == "1") { SelTier = "IND" };
      if (product == "2") { SelTier = "ISP"; this.showWife = true; };
      if (product == "3") { SelTier = "ICH"; this.showChild = true; };
      if (product == "4") { SelTier = "FAM"; this.showWife = true; this.showChild = true; };
      if (product == "5") { SelTier = "IND" };
      if (product == "6") { SelTier = "ISP"; this.showWife = true; };
      if (product == "7") { SelTier = "ICH"; this.showChild = true; };
      if (product == "8") { SelTier = "FAM"; this.showWife = true; this.showChild = true; };

      if (product == "5" || product == "6" || product == "7" || product == "8") {
        if (SelPlan == "1") { SelPlan = "5" }
        if (SelPlan == "2") { SelPlan = "6" }
        if (SelPlan == "3") { SelPlan = "7" }
        if (SelPlan == "4") { SelPlan = "8" }
      }

      //PLAN
      var mySelPlan = SelPlan;

      let productJson =
      {
        "ProductId": 4,
        "AFEprodesk": "_SICKNESS",
        "AFEproduct": product,
        "AFEplan": mySelPlan,
        "SelectedPlanId": SelPlan,
        "SelectedTierCode": SelTier,
        "EffectiveDate": EffectiveDate,
        "MemberElections": [
          {
            "PersonCode": "00",
            "CurrentlyElecting": true,
          }
        ]
      }
      for (let result of this.Quote.DependentDemographics) {
        let MemElectNew = {
          "PersonCode": "",
          "CurrentlyElecting": true,
        }
        //if spouse
        if (SelTier == "ISP") {
          if (result.AFEtype == "S") {
            MemElectNew.PersonCode = result.PersonCode;
            productJson.MemberElections.push(MemElectNew)
          }
        }
        //if children
        if (SelTier == "ICH") {
          if (result.AFEtype == "C") {
            MemElectNew.PersonCode = result.PersonCode;
            productJson.MemberElections.push(MemElectNew);
          }
        }
        //if family
        if (SelTier == "FAM") {
          MemElectNew.PersonCode = result.PersonCode;
          productJson.MemberElections.push(MemElectNew);
        }
      }
      this.Quote.ProductSelections.push(productJson)
    }




    //hospital 65
    //***************************/
    if (this.Quote.selections.hospital65.amount > 0) {
      let HCIproductid: number
      product = this.Quote.selections.hospital65.row
      SelPlan = this.Quote.selections.hospital65.col
      //TIER
      if (product == "1") { SelTier = "IND" };
      if (product == "2") { SelTier = "ISP"; this.showWife = true; };




      //PLAN sickness
      var mySelPlan = SelPlan;

      let productJson =
      {
        "ProductId": 12,
        "AFEprodesk": "_SICKNESS65",
        "AFEproduct": product,
        "AFEplan": mySelPlan,
        "SelectedPlanId": SelPlan,
        "SelectedTierCode": SelTier,
        "EffectiveDate": EffectiveDate,
        "MemberElections": [
          {
            "PersonCode": "00",
            "CurrentlyElecting": true,
          }
        ]
      }
      for (let result of this.Quote.DependentDemographics) {
        let MemElectNew = {
          "PersonCode": "",
          "CurrentlyElecting": true,
        }
        //if spouse
        if (SelTier == "ISP") {
          if (result.AFEtype == "S") {
            MemElectNew.PersonCode = result.PersonCode;
            productJson.MemberElections.push(MemElectNew)
          }
        }
      }
      this.Quote.ProductSelections.push(productJson)
    }






    //life
    //***************************/
    if (this.Quote.selections.life.amount > 0) {
      let HCIproductid: number
      let SelPlan: string;
      var mySelPlan: string;
      if (this.Quote.selections.life.selection == "25") {
        HCIproductid = 8339
        //        SelPlan = "08b159bb-707e-48a4-a7cd-8b3588756528"
        mySelPlan = "25";
      }
      if (this.Quote.selections.life.selection == "75") {
        HCIproductid = 8676
        //        SelPlan = "5c5ccb7b-9ba9-489c-bb54-064303a4fec3"
        mySelPlan = "75";
      }
      let productJson =
      {
        "ProductId": 6,
        "AFEprodesk": "_LIFE",
        "AFEproduct": "6",
        "AFEplan": this.Quote.selections.life.selection,
        "SelectedPlanId": "",
        "EffectiveDate": EffectiveDate,
        "SelectedTierCode": "IND",
        "MemberElections": [
          {
            "PersonCode": "00",
            "CurrentlyElecting": true,
          }
        ]
      }
      this.Quote.ProductSelections.push(productJson)
    }






    //DENTAL STANDARD
    //***************************/
    if (this.Quote.selections.stdental.amount > 0) {
      product = this.Quote.selections.stdental.row
      SelPlan = this.Quote.selections.stdental.col
      //TIER
      if (product == "1") { SelTier = "IND" };
      if (product == "2") { SelTier = "ISP"; this.showWife = true; };
      if (product == "3") { SelTier = "ICH"; this.showChild = true; };
      if (product == "4") { SelTier = "FAM"; this.showWife = true; this.showChild = true; };
      //PLAN
      var mySelPlan = SelPlan;

      let productJson =
      {
        "ProductId": 8,
        "AFEprodesk": "_STDENTAL",
        "AFEproduct": product,
        "AFEplan": mySelPlan,
        "SelectedPlanId": SelPlan,
        "SelectedTierCode": SelTier,
        "EffectiveDate": EffectiveDate,
        "MemberElections": [
          {
            "PersonCode": "00",
            "CurrentlyElecting": true,
          }
        ]
      }

      for (let result of this.Quote.DependentDemographics) {
        let MemElectNew = {
          "PersonCode": "",
          "CurrentlyElecting": true,
        }
        //if individual + 1
        if (SelTier == "ISP") {
          if (result.AFEtype == "S") {
            MemElectNew.PersonCode = result.PersonCode;
            productJson.MemberElections.push(MemElectNew)
          }
        }
        if (SelTier == "ICH") {
          if (result.AFEtype == "C") {
            MemElectNew.PersonCode = result.PersonCode;
            productJson.MemberElections.push(MemElectNew)
          }
        }
        //if family
        if (SelTier == "FAM") {
          MemElectNew.PersonCode = result.PersonCode;
          productJson.MemberElections.push(MemElectNew)
        }
      }
      this.Quote.ProductSelections.push(productJson)

    }

    //HOORAY
    //***************************/
    if (this.Quote.selections.hooray.amount > 0) {
      product = this.Quote.selections.hooray.row
      SelPlan = this.Quote.selections.hooray.col
      //TIER
      if (product == "1") { SelTier = "IND" };
      if (product == "2") { SelTier = "ISP"; this.showWife = true; };
      if (product == "3") { SelTier = "ICH"; this.showChild = true; };
      if (product == "4") { SelTier = "FAM"; this.showWife = true; this.showChild = true; };

      //PLAN
      var mySelPlan = SelPlan;

      let productJson =
      {
        "ProductId": 9,
        "AFEprodesk": "_HOORAY",
        "AFEproduct": product,
        "AFEplan": mySelPlan,
        "SelectedPlanId": SelPlan,
        "SelectedTierCode": SelTier,
        "EffectiveDate": EffectiveDate,
        "MemberElections": [
          {
            "PersonCode": "00",
            "CurrentlyElecting": true,
          }
        ]
      }

      for (let result of this.Quote.DependentDemographics) {
        let MemElectNew = {
          "PersonCode": "",
          "CurrentlyElecting": true,
        }
        //if individual + 1
        if (SelTier == "ISP") {
          if (result.AFEtype == "S") {
            if (result.age >= 18 && result.age <= 65) {
              MemElectNew.PersonCode = result.PersonCode;
              productJson.MemberElections.push(MemElectNew)
            }
          }
        }
        //if children
        if (SelTier == "ICH") {
          if (result.AFEtype == "C") {
            MemElectNew.PersonCode = result.PersonCode;
            productJson.MemberElections.push(MemElectNew);
          }
        }
        //if family
        if (SelTier == "FAM") {
          if (result.AFEtype == "S") {
            if (result.age >= 18 && result.age <= 65) {
              MemElectNew.PersonCode = result.PersonCode;
              productJson.MemberElections.push(MemElectNew)
            }
          }
        }
      }
      this.Quote.ProductSelections.push(productJson)

    }


    //CONCORDIA
    //***************************/
    if (this.Quote.selections.concordia.amount > 0) {
      product = this.Quote.selections.concordia.row
      SelPlan = this.Quote.selections.concordia.col
      //TIER
      if (product == "1") { SelTier = "IND" };
      if (product == "2") { SelTier = "ISP"; this.showWife = true; };
      if (product == "3") { SelTier = "FAM"; this.showWife = true; this.showChild = true; };

      //PLAN
      var mySelPlan = SelPlan;

      let productJson =
      {
        "ProductId": 10,
        "AFEprodesk": "_CONCORDIA",
        "AFEproduct": product,
        "AFEplan": mySelPlan,
        "SelectedPlanId": SelPlan,
        "SelectedTierCode": SelTier,
        "EffectiveDate": EffectiveDate,
        "MemberElections": [
          {
            "PersonCode": "00",
            "CurrentlyElecting": true,
          }
        ]
      }

      for (let result of this.Quote.DependentDemographics) {
        let MemElectNew = {
          "PersonCode": "",
          "CurrentlyElecting": true,
        }
        //if individual + 1
        if (SelTier == "ISP") {
          if (result.AFEtype == "S") {
            MemElectNew.PersonCode = result.PersonCode;
            productJson.MemberElections.push(MemElectNew)
          }
        }
        //if family
        if (SelTier == "FAM") {
          MemElectNew.PersonCode = result.PersonCode;
          productJson.MemberElections.push(MemElectNew)
        }
      }
      this.Quote.ProductSelections.push(productJson)

    }
    //console.log(this.Quote)
    //return;

    //Send API to AFE Servers
    this.Step = "5";
    var APIURL = sessionStorage.getItem('endpoint') + "afeapi/sales/checkout.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    let body = new URLSearchParams();
    body.set('payload', JSON.stringify(this.Quote));
    body.set('qk', sessionStorage.getItem('qk'));
    let Origin = sessionStorage.getItem('origin')
    if (Origin == "backoffice") {
      Origin = "Desk"
    }
    else {
      Origin = "Website"
    }
    body.set('origin', Origin);

    
    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {

        var APIresult: any = result;
        if (result == null) {
          Swal.fire('We cannot process your quote', "We appologize, but we cannot retrieve your quote now. Please try again later. ", 'error');
          return;
        }

        if (APIresult.success == "0") {
          this.Step = '4';
          Swal.fire('Check your information', APIresult.message, 'error');
          return;
        }

        if (APIresult.success == "1") {

          window.scrollTo(0, 0);

          this.ContinuationURL = APIresult.ContinuationURL;
          this.MemberAccessToken = APIresult.MemberAccessToken;
          this.HCItoken = APIresult.HCItoken;
          this.Endpoint = APIresult.EndPoint

          if (this.ContinuationURL) {

            var ifr: any = document.getElementById("myiframe")
            var doc = ifr.contentDocument;
            doc.getElementsByName('ReferrerCallbackUrl')[0].value = this.Endpoint;
            doc.getElementsByName('healthspace-token')[0].value = this.MemberAccessToken;
            doc.getElementsByName('ReferrerToken')[0].value = this.HCItoken;
            doc.getElementsByName('myform')[0].action = this.ContinuationURL;
            doc.getElementsByName('ContinuationURL')[0].value = this.ContinuationURL;
            doc.getElementsByName('Referrer')[0].value = "https://afeusaenroll.com";
            doc.getElementsByName('myform')[0].submit();





            var _AgentPrincipalId = sessionStorage.getItem("AgentPrincipalId")
            var _ClientPrincipalID = sessionStorage.getItem("ClientPrincipalID")
            var _config = sessionStorage.getItem("config")
            var _endpoint = sessionStorage.getItem("endpoint")

            var n = sessionStorage.length;
            while (n--) {
              var key = sessionStorage.key(n);
              if (/foo/.test(key)) {
                sessionStorage.removeItem(key);
              }
            }

            sessionStorage.setItem("AgentPrincipalId", _AgentPrincipalId);
            sessionStorage.setItem("ClientPrincipalID", _ClientPrincipalID);
            sessionStorage.setItem("config", _config);
            sessionStorage.setItem("endpoint", _endpoint);
          }

        }
        else {
          this.Step = '4';
          Swal.fire("Enrollment Failed", "There was an error processing this Enrollment. Please confirm your information.", "warning");
        }
      },
      error => {
      },
      () => {

      });



  }


  doCheckOut(): void {

    if (!this.TOS) { Swal.fire({ title: 'Agree with the terms of service', text: 'Please agree with AFEUSA Terms & Conditions and Privacy Policy', icon: 'warning', timer: 4000, showConfirmButton: false }); return; }

    if (this.Quote.selections.life.selection > 0) {
      if (Object.keys(this.Quote.Beneficiaries).length == 0) {
        Swal.fire({ title: 'Enter Beneficiaries', text: 'You selected a product (Life Insurance) that require you to enter your beneficiaries.', icon: 'warning', timer: 4000 });
        return;
      }

      if (this.lisumpercentage != 100) {
        Swal.fire({ title: 'Enter all primary beneficiaries', text: 'The sumatory of the % assigned to each primary beneficiary must equal 100%.', icon: 'warning', timer: 4000 });
        return;
      }
      if (this.liContsumpercentage != 100 && this.liContsumpercentage != 0) {
        Swal.fire({ title: 'Enter contingent beneficiaries', text: 'The sumatory of the % assigned to each contingent beneficiary must equal 100%.', icon: 'warning', timer: 4000 });
        return;
      }

      if (!this.lifeStatement1) {
        Swal.fire({ title: 'Confirmation Missing', text: 'Confirm you are personally enrolling for membership in AFEUSA, and no other person is enrolling on your behalf.', icon: 'warning', timer: 4000 });
        return;
      }

      if (!this.lifeStatement2) {
        Swal.fire({ title: 'Confirmation Missing', text: 'Confirm you are actively working, or if not, you are fully capable of actively working for compensation.', icon: 'warning', timer: 4000 });
        return;
      }
      if (!this.lifeStatement3) {
        Swal.fire({ title: 'Confirmation Missing', text: 'Confirm you understand that providing false or inaccurate information will cause the life insurance being applied for to be canceled and voided back to the original effective date.', icon: 'warning', timer: 4000 });
        return;
      }
    }

    if (this.Quote.selections.dental.amount > 0 || this.Quote.selections.stdental.amount > 0) {
      if (!this.dentalStatement) {
        Swal.fire({ title: 'Confirmation Missing', text: 'Confirm you enrolling in the dental coverage offered as a AFEUSA member.', icon: 'warning', timer: 4000 });
        return;
      }
    }




    if (!this.eSignature) {
      Swal.fire('Electronic Signature Missing', 'Please enter your electronic signature to checkout', 'warning');
      return;
    }

    this.Quote.extras = {};
    this.Quote.extras.hci = {};
    if (sessionStorage.getItem("params")) {
      this.Quote.extras.hci = JSON.parse(sessionStorage.getItem("params"));
    }
    this.Quote.extras.afe = {};
    if (sessionStorage.getItem("vip")) {
      this.Quote.extras.afe.vipcode = sessionStorage.getItem("vip");
    }
    this.Quote.extras.afe.firstname = this.Quote.EmpDemographics.FirstName;
    this.Quote.extras.afe.lastname = this.Quote.EmpDemographics.LastName;


    this.Step = '20';
    window.scrollTo(0, 0);
  }

  changeProductsSelection(): void {


    var myImg = document.getElementById('myiframe') as HTMLImageElement;
    myImg.setAttribute('src', '/assets/iframe.html');
    this.Step = '2';

  }

}
