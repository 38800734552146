import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-p4',
  templateUrl: './p4.component.html',
  styleUrls: ['./p4.component.css']
})
export class P4Component implements OnInit {
  @Output("Calculate") Calculate: EventEmitter<any> = new EventEmitter();
  @Input() Data: any;
  public matrix: any;
  public matrix2: any;
  public selection: any;

  public SelectedCol: number = 0;
  public SelectedRow: number = 0;
  public SelectedAmount: number = 0
  public SelectedName: string = "";
  public SelectedRowCol: string = "";

  public LightBox: boolean = false;
  public PlanName: string = "";

  //product variables
  public showVisits: boolean = false;
  public ERVisits: string;
  public DOCvisit: string;
  public inHospital: string;
  //public intensiveCare: string;
  public FileCA: string="";

  constructor(
    private toastr: ToastrService
  ) {
  
    
   
  }

  View(row, col, amount, name, deductible): void {
    this.SelectedCol = col;
    this.SelectedRow = row;
    this.SelectedAmount = amount;
    this.SelectedName = name

    if (col == "1") { this.inHospital="$250" };
    if (col == "2") {  this.inHospital="$500" };
    if (col == "3") {  this.inHospital="$750" };
    if (col == "4") { this.inHospital="$1,000" };

    if (col == "5") { this.ERVisits = "$100"; this.DOCvisit = "$25"; this.inHospital="$250" };
    if (col == "6") { this.ERVisits = "$150"; this.DOCvisit = "$50"; this.inHospital="$500" };
    if (col == "7") { this.ERVisits = "$200"; this.DOCvisit = "$75"; this.inHospital="$750" };
    if (col == "8") { this.ERVisits = "$250"; this.DOCvisit = "$100"; this.inHospital="$1,000" };

    this.showVisits = false;
    switch (row) {
      case "5": {
        this.showVisits = true;
        break;
      }
     
      case "6": {
        this.showVisits = true;
        break;
      }
    
      case "7": {
        this.showVisits = true;
        break;
      }
     
      case "8": {
        this.showVisits = true;
        break;
      }
     
    }

  }

  SetPlan(): void {
    this.SelectedRowCol=this.SelectedRow.toString() + this.SelectedCol.toString()
    this.Data.selections.hospital.selection=this.SelectedRow.toString() + this.SelectedCol.toString()
    this.Data.selections.hospital.amount= +this.SelectedAmount;
    this.Data.selections.hospital.col=this.SelectedCol;
    this.Data.selections.hospital.row=this.SelectedRow;
    this.Data.selections.hospital.name = this.SelectedName;
    this.toastr.success('Plan added to your cart!', 'Plan Added');
    this.Calculate.emit();
    this.LightBox=false;
  }

  ngOnInit(): void {
    this.matrix = this.Data.hospital;
    this.matrix2 = this.Data.hospital2;
    this.selection = this.Data.selections.medical;
    this.SelectedRowCol = this.Data.selections.hospital.row.toString() + this.Data.selections.hospital.col.toString()
    if (sessionStorage.getItem("state") == "CA") {
      this.FileCA="CA";
    }
  }

}
