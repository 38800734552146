<h4>New Application{{iContent}}</h4>
<hr>
<div class="w100">
    <div *ngIf="IsProcessing && !showfinal">

        <br><br>
        <div class="alert alert-warning">
            <h5>Thank you for enrolling a new member to AFEUSA.</h5>
            <h6>You will be redirected to our payment processing screen to input your member's Credit Card or ACH information.
            </h6>
        </div>
        <br><br>
        <img src="assets/images/processing.gif" style="width:50px;">
        <p>Please wait...</p>
    </div>
    <div *ngIf="showfinal">

        <br><br>
        <div class="alert alert-info" id="done" name="done" style="display: none;">
            <h5>Congratulations on signing up a new member.</h5>
            <p>If the payment was successful, the new member will receive a payment transaction receipt and instructions on accessing the benefits shortly.</p>
        </div>
        <br><br>
    </div>
    <div *ngIf="!IsProcessing">
        <div class="bShadow-5" *ngIf="!ShowEnroll">
            <br>
            <p>To start a new application, please enter the new member date of birth and the state. Then click the "Get Rates" button.</p>
            <hr>
            <div class="row">


                <div class="col-xl-2 col-lg-4 col-sm-6 col-md-4 col-sm-6 col-12">
                    <label>DOB</label>
                    <input [(ngModel)]="dob" class="text" class="form-control" maxlength="20" style="border-radius: 7px;" mask="M0/d0/0000" [dropSpecialCharacters]="false" placeholder="mm/dd/yyyy">
                </div>
                <div class="col-xl-2 col-lg-4 col-sm-6 col-md-4 col-sm-6 col-12">
                    <label>State</label>
                    <select class="form-control " [(ngModel)]="state">
                        <option value=""></option>
                        <option *ngFor="let state of USstates" value="{{state.Code}}">{{state.Name}}</option>
                    </select>
                </div>
            </div>
            <br>
            <button class="btn btn-primary btn-sm" (click)="GetRates()">Get Rates</button>
            <br>
        </div>



        <div *ngIf="ShowEnroll">

            <div>

                <div class="row">
                    <!--AFE Membership-->
                    <div class="col-xl-2 col-lg-4 col-sm-6 col-md-4 col-sm-6 col-12">
                        <div class="text-center text-white productbox " [ngClass]="colorMembershipQuote" style="cursor: pointer;" (click)="overlapOpen=true; overlapContent='membership'">
                            <div class="bg-white" style="height: 45px; border-radius: 10px; background-color: white; margin-left: 10px; margin-right: 10px; margin-bottom:20px;">
                                <img src="assets/afelogoreal.png" style="width: 70%; margin-top: 10px; margin-bottom:10px;">
                            </div>
                            <strong class="celeste ">AFEUSA<br>Membership</strong>
                            <hr style="border-top: 1px solid #a0cade; margin-left: 15px; margin-right: 15px;   ">
                            <span class="text-white ">
                                <h2 class="text-white">${{MembershipQuote}}</h2>
                            </span>
                            <br>
                        </div>
                    </div>
                    <!--Dental-->
                    <div class="col-xl-2 col-lg-4 col-sm-6 col-md-4 col-sm-6 col-12" *ngIf="DoDental">
                        <div class="text-center text-white productbox " [ngClass]="colorDentalQuote" (click)="overlapOpen=true; overlapContent='dental'" style="cursor: pointer;">
                            <div class="bg-white" style="height: 45px; border-radius: 10px; background-color: white; margin-left: 10px; margin-right: 10px; margin-bottom:20px;">
                                <img src="assets/images/logodental.png" style="width: 70%; margin-top: 10px; margin-bottom:10px;">
                            </div>
                            <strong class="celeste ">Dental<br>Insurance</strong>
                            <hr style="border-top: 1px solid #a0cade; margin-left: 15px; margin-right: 15px;   ">
                            <span class="text-white ">
                                <h2 class="text-white">${{DentalQuote}}</h2>
                            </span>
                            <br>
                        </div>
                    </div>
                    <!--Medical expense-->
                    <div class="col-xl-2 col-lg-4 col-sm-6 col-md-4 col-sm-6 col-12" *ngIf="DoMedical">
                        <div class="text-center text-white productbox " [ngClass]="colorMedicalQuote" (click)="overlapOpen=true; overlapContent='medical'" style="cursor: pointer;">
                            <div class="bg-white" style="height: 45px; border-radius: 10px; background-color: white; margin-left: 10px; margin-right: 10px; margin-bottom:20px;">
                                <img src="assets/images/logochubb.png" style="width: 70%; margin-top: 10px; margin-bottom:10px;">
                            </div>
                            <strong class="celeste ">Accident<br>Medical</strong>
                            <hr style="border-top: 1px solid #a0cade; margin-left: 15px; margin-right: 15px;   ">
                            <span class="text-white ">
                                <h2 class="text-white">${{MedicalQuote}}</h2>
                            </span>
                            <br>
                        </div>
                    </div>
                    <!--Accident and sickness-->
                    <div class="col-xl-2 col-lg-4 col-sm-6 col-md-4 col-sm-6 col-12" *ngIf="DoHospital">
                        <div class="text-center text-white productbox " [ngClass]="colorHospitalQuote" (click)="overlapOpen=true; overlapContent='hospital'" style="cursor: pointer;">
                            <div class="bg-white" style="height: 45px; border-radius: 10px; background-color: white; margin-left: 10px; margin-right: 10px; margin-bottom:20px;">
                                <img src="assets/images/logochubb.png" style="width: 70%; margin-top: 10px; margin-bottom:10px;">
                            </div>
                            <strong class="celeste ">Accident &<br>Sickness</strong>
                            <hr style="border-top: 1px solid #a0cade; margin-left: 15px; margin-right: 15px;   ">
                            <span class="text-white ">
                                <h2 class="text-white">${{HospitalQuote}}</h2>
                            </span>
                            <br>
                        </div>
                    </div>
                    <!--Critical-->
                    <div class="col-xl-2 col-lg-4 col-sm-6 col-md-4 col-sm-6 col-12" *ngIf="DoCritical">
                        <div class="text-center text-white productbox " [ngClass]="colorCriticalQuote" (click)="overlapOpen=true; overlapContent='critical'" style="cursor: pointer;">
                            <div class="bg-white" style="height: 45px; border-radius: 10px; background-color: white; margin-left: 10px; margin-right: 10px; margin-bottom:20px;">
                                <img src="assets/images/logochubb.png" style="width: 70%; margin-top: 10px; margin-bottom:10px;">
                            </div>
                            <strong class="celeste ">Critical<br>Illness</strong>
                            <hr style="border-top: 1px solid #a0cade; margin-left: 15px; margin-right: 15px;   ">
                            <span class="text-white ">
                                <h2 class="text-white">${{CriticalQuote}}</h2>
                            </span>
                            <br>
                        </div>
                    </div>
                    <!--Life-->
                    <div class="col-xl-2 col-lg-4 col-sm-6 col-md-4 col-sm-6 col-12" *ngIf="DoLife">
                        <div class="text-center text-white productbox " [ngClass]="colorLife75Quote" (click)="overlapOpen=true; overlapContent='life'" style="cursor: pointer;">
                            <div class="bg-white" style="height: 45px; border-radius: 10px; background-color: white; margin-left: 10px; margin-right: 10px; margin-bottom:20px;">
                                <img src="assets/images/logostandard.png" style="width: 70%; margin-top: 10px; margin-bottom:10px;">
                            </div>
                            <strong class="celeste ">Life<br>Insurance</strong>
                            <hr style="border-top: 1px solid #a0cade; margin-left: 15px; margin-right: 15px;   ">
                            <span class="text-white ">
                                <h2 class="text-white">${{Life25Quote}}</h2>
                            </span>
                            <br>
                        </div>
                    </div>
                </div>
                <div class="text-right">
                    <br>
                    <div class="row">
                        <div class="col-6 col-md-6 col-lg-6 text-left">
                            <a class="link text-primary" (click)="startover()" style="cursor: pointer;">&#60;&#60; Start
                                Over (New Enrollment)</a>
                            <br><br>
                        </div>
                        <div class="col-6 col-md-6 col-lg-6 text-right">
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="ShowCart">
                <div class="alert alert-success">
                    <h5>Shopping Cart Content</h5>
                    <hr>
                    <!--Product Row-->
                    <div class="row" *ngIf="deleteMembership">
                        <div class="col-md-8"><button class="btn btn-danger btn-sm btn-xs " style="margin-right: 15px;" (click)="DeleteMembershipQuote()" *ngIf="deleteMembership">X</button>{{legendMembership}}
                        </div>
                        <div class="col-md-4 text-right">${{MembershipQuote}}</div>
                    </div>
                    <!--Product Row-->
                    <div class="row mt10" *ngIf="deleteDental">
                        <div class="col-md-8"><button class="btn btn-danger btn-sm btn-xs " style="margin-right: 15px;" (click)="DeleteDentalQuote()" *ngIf="deleteDental">X</button>{{legendDental}}</div>
                        <div class="col-md-4 text-right">${{DentalQuote}}</div>
                    </div>
                    <!--Product Row-->
                    <div class="row mt10" *ngIf="deleteMedical">
                        <div class="col-md-8"><button class="btn btn-danger btn-sm btn-xs " style="margin-right: 15px;" (click)="DeleteMedicalQuote()" *ngIf="deleteMedical">X</button>{{legendMedical}}</div>
                        <div class="col-md-4 text-right">${{MedicalQuote}}</div>
                    </div>
                    <!--Product Row-->
                    <div class="row mt10" *ngIf="deleteHospital">
                        <div class="col-md-8"><button class="btn btn-danger btn-sm btn-xs " style="margin-right: 15px;" (click)="DeleteHospitalQuote()" *ngIf="deleteHospital">X</button>{{legendHospital}}
                        </div>
                        <div class="col-md-4 text-right">${{HospitalQuote}}</div>
                    </div>
                    <!--Product Row-->
                    <div class="row mt10" *ngIf="deleteCritical">
                        <div class="col-md-8"><button class="btn btn-danger btn-sm btn-xs " style="margin-right: 15px;" (click)="DeleteCriticalQuote()" *ngIf="deleteCritical">X</button>{{legendCritical}}
                        </div>
                        <div class="col-md-4 text-right">${{CriticalQuote}}</div>
                    </div>
                    <!--Product Row-->
                    <div class="row mt10" *ngIf="deleteLife25">
                        <div class="col-md-8"><button class="btn btn-danger btn-sm btn-xs " style="margin-right: 15px;" (click)="DeleteLife25Quote()" *ngIf="deleteLife25">X</button>{{legendLife25}}</div>
                        <div class="col-md-4 text-right">${{Life25Quote}}</div>
                    </div>
                    <!--Product Row-->
                    <div class="row mt10" *ngIf="deleteLife75">
                        <div class="col-md-8"><button class="btn btn-danger btn-sm btn-xs " style="margin-right: 15px;" (click)="DeleteLife75Quote()" *ngIf="deleteLife75">X</button>{{legendLife75}}</div>
                        <div class="col-md-4 text-right">${{Life75Quote}}</div>
                    </div>
                    <hr>
                    <!--Product Row-->
                    <div class="row mt10" *ngIf="TotalToDisplay">
                        <div class="col-md-8"><strong>Your Total Cost Per Month</strong></div>
                        <div class="col-md-4 text-right"><strong>${{TotalToDisplay}}</strong></div>
                    </div>

                </div>
                <br>
                <!--MEMBER INFORMATION-->
                <div class="bShadow-5">
                    <h5>Member Information</h5>
                    <hr>
                    <div class="row">
                        <div class="col-md-3">
                            <label>First Name</label><br>
                            <input type="text" maxlength=30 class="form-control" [(ngModel)]="firstname">
                        </div>
                        <div class="col-md-3">
                            <label>Middle Name</label><br>
                            <input type="text" maxlength=30 class="form-control" [(ngModel)]="middlename">
                        </div>
                        <div class="col-md-3">
                            <label>Last Name</label><br>
                            <input type="text" maxlength=30 class="form-control" [(ngModel)]="lastname">
                        </div>
                        <div class="col-md-3">
                            <label>Date of Birth</label><br>
                            <input type="text" maxlength=30 class="form-control" disabled [(ngModel)]="dob" placeholder="mm/dd/yyyy">
                        </div>

                        <div class="col-md-6">
                            <label>Address</label><br>
                            <input type="text" maxlength=30 class="form-control" [(ngModel)]="address">
                        </div>
                        <div class="col-md-6">
                            <label>Address 2</label><br>
                            <input type="text" maxlength=30 class="form-control" [(ngModel)]="address2">
                        </div>
                        <div class="col-md-3">
                            <label>City</label><br>
                            <input type="text" maxlength=30 class="form-control" [(ngModel)]="city">
                        </div>
                        <div class="col-md-3">
                            <label>State</label><br>
                            <input type="text" maxlength=30 class="form-control" disabled [(ngModel)]="state">
                        </div>
                        <div class="col-md-3">
                            <label>ZIP Code</label><br>
                            <input type="text" maxlength=5 class="form-control" [(ngModel)]="zip">
                        </div>
                        <div class="col-md-3">
                            <label>Phone Number</label><br>
                            <input type="text" maxlength=30 class="form-control" [(ngModel)]="phone" mask="(000) 000-0000" [dropSpecialCharacters]="false">
                        </div>


                        <div class="col-md-3">
                            <label>Gender</label><br>
                            <select class="form-control" [(ngModel)]="gender">
                                <option value=""></option>
                                <option value="M">Male</option>
                                <option value="F">Female</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label>Social Security Number</label><br>
                            <input type="text" maxlength=30 class="form-control" [(ngModel)]="ssn" mask="000-00-0000" [dropSpecialCharacters]="false">
                        </div>
                        <div class="col-md-3">
                            <label>Marital Status</label><br>
                            <select class="form-control" [(ngModel)]="marritalstatus">
                                <option value=""></option>
                                <option value="Single">Single</option>
                                <option value="Married">Married</option>
                                <option value="Divorced">Divorced</option>
                                <option value="Widowed">Widowed</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label>Email Address</label><br>
                            <input type="text" maxlength=70 class="form-control" [(ngModel)]="email">
                        </div>

                        <div class="col-md-3">
                            <label>Enter Mother’s Maiden name as a Unique Identifier</label>
                            <input type="text" maxlength=70 class="form-control" [(ngModel)]="mothersmaiden">
                        </div>


                    </div>
                </div>

                <!--FAMILY MEMBERS-->
                <div *ngIf="groupplan=='1'">
                    <br>
                    <div class="bShadow-5">
                        <h5>Family Members</h5>
                        <hr>
                        <div>
                            <div *ngFor="let child of PayLoad.DependentDemographics; let i=index">
                                <div class="row">
                                    <div class="col-md-3">
                                        {{child.FirstName}} {{child.LastName}}
                                    </div>
                                    <div class="col-md-2">
                                        {{child.Gender}}
                                    </div>
                                    <div class="col-md-2">
                                        {{child.BirthDate}}
                                    </div>
                                    <div class="col-md-3">
                                        {{child.Relationship}}
                                    </div>
                                    <div class="col-md-2 text-right">
                                        <button class="btn btn-danger btn-sm" (click)="DeleteChildden(i)">X</button>
                                    </div>
                                </div>
                                <hr>
                            </div>
                        </div>
                        <button class="btn btn-outline-dark btn-xs" (click)="ShowAddCWife=true" style="margin-right: 10px;" *ngIf="!spadded">+ Add Spouse</button>
                        <button class="btn btn-outline-dark btn-xs" (click)="ShowAddChildren=true">+ Add
                            Children</button>
                        <br>
                        <br>
                    </div>
                </div>


                <!--LIFE INSURANCE BENEFICIARIES-->
                <div *ngIf="lifeplan=='1'">
                    <br>
                    <div class="bShadow-5">
                        <h5>Life Insurance Beneficiaries</h5>
                        <hr>
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                                <div class="single-events-box pad">
                                    <br>
                                    <h6>Primary Beneficiaries</h6>
                                    <hr>
                                    <div *ngFor="let beneficiary of PayLoad.Beneficiaries; let i=index">
                                        <div *ngIf="beneficiary.BType=='P'">
                                            <div class="row">
                                                <div class="col-md-8">
                                                    {{beneficiary.FirstName}} {{beneficiary.LastName}} ({{beneficiary.Type}})
                                                </div>

                                                <div class="col-md-2">
                                                    {{beneficiary.Allocations[0].Percentage}}%
                                                </div>

                                                <div class="col-md-2 text-left">
                                                    <button class="btn btn-danger btn-sm" (click)="DeleteBeneficiary(i)">X</button>
                                                </div>
                                            </div>
                                            <hr>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-md-8">
                                            <strong>Total %</strong>
                                        </div>

                                        <div class="col-md-2">
                                            <strong>
                                                {{lisumpercentage}}%
                                            </strong>
                                        </div>

                                        <div class="col-md-2 text-left">

                                        </div>
                                    </div>
                                    <br>
                                    <button *ngIf="lisumpercentage < 100" class="btn btn-outline-dark btn-xs" (click)="ShowAddBeneficiary=true" style="margin-right: 10px;">+ Add
                                        Beneficiary</button>
                                </div>

                            </div>

                            <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                                <div class="single-events-box pad">
                                    <br>
                                    <h6>Contingent Beneficiaries</h6>
                                    <hr>
                                    <div *ngFor="let beneficiary of PayLoad.Beneficiaries; let i=index">
                                        <div *ngIf="beneficiary.BType=='C'">
                                            <div class="row">
                                                <div class="col-md-8">
                                                    {{beneficiary.FirstName}} {{beneficiary.LastName}} ({{beneficiary.Type}})
                                                </div>
                                                <div class="col-md-2">
                                                    {{beneficiary.Allocations[0].Percentage}}%
                                                </div>

                                                <div class="col-md-2 text-left">
                                                    <button class="btn btn-danger btn-sm" (click)="DeleteContingentBeneficiary(i)">X</button>
                                                </div>
                                            </div>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-8">
                                            <strong>Total %</strong>
                                        </div>

                                        <div class="col-md-2">
                                            <strong>
                                                {{liContsumpercentage}}%
                                            </strong>
                                        </div>

                                        <div class="col-md-2 text-left">

                                        </div>
                                    </div>

                                    <br>
                                    <button *ngIf="liContsumpercentage < 100" class="btn btn-outline-dark btn-xs" (click)="ShowAddContingentBeneficiary=true" style="margin-right: 10px;">+ Add
                                        Beneficiary</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <br>
                <!--MEMBER INFORMATION-->
                <div class="bShadow-5">
                    <h5>Create Member Portal Access</h5>
                    <hr>
                    <div class="row">
                        <div class="col-md-4">
                            <label>Login Email</label><br>
                            <input type="text" maxlength=30 class="form-control" [(ngModel)]="email" disabled>
                        </div>
                        <div class="col-md-4">
                            <label>Password</label><br>
                            <input type="text" maxlength=30 class="form-control" [(ngModel)]="upassword">
                        </div>
                        <div class="col-md-4">
                            <label>Confirm password</label><br>
                            <input type="text" maxlength=30 class="form-control" [(ngModel)]="upassword2">
                        </div>
                    </div>
                </div>

                <br>
                <button class="btn btn-primary" (click)="Finish()">SUBMIT APPLICATION >></button>
            </div>

        </div>
        <br>
        <br><br>
        <br><br>
        <br><br>
        <br><br>
        <br>



    </div>

    <div id="spinner" style="height: 100vh;" *ngIf="ShowSpinner">
        <div class="centered">
            <img src="assets/images/processing.gif" style="width:50px;">
            <br><br>
            <span class="text-white">Processing Enrollment. Please wait...</span>
        </div>
    </div>


    <div id="overlay" *ngIf="overlapOpen">
        <div id="overlay2">
            <div>
                <div class="row">
                    <div class="col-md-10 text-left">

                    </div>
                    <div class="col-md-2 text-right">
                        <button class="btn btn-danger btn-sm" (click)="overlapOpen=false"><i
                                class="fa fa-window-close"></i>
                            Close</button>
                    </div>

                </div>

            </div>
            <div class="stuck">
                <div *ngIf="overlapContent=='membership'">
                    <app-sectionmembership (Calculate)="Calculate()"></app-sectionmembership>
                </div>
                <div *ngIf="overlapContent=='dental'">
                    <app-sectiondental (Calculate)="Calculate()"></app-sectiondental>
                </div>
                <div *ngIf="overlapContent=='hospital'">
                    <app-sectionsickness (Calculate)="Calculate()"></app-sectionsickness>
                </div>
                <div *ngIf="overlapContent=='medical'">
                    <app-sectionmedical (Calculate)="Calculate()"></app-sectionmedical>
                </div>
                <div *ngIf="overlapContent=='critical'">
                    <app-sectioncritical (Calculate)="Calculate()"></app-sectioncritical>
                </div>
                <div *ngIf="overlapContent=='life'">
                    <app-sectionlife75 (Calculate)="Calculate()"></app-sectionlife75>
                </div>
                <div *ngIf="overlapContent=='tos'">
                    <app-tos></app-tos>
                </div>
                <div *ngIf="overlapContent=='pp'">
                    <app-pp></app-pp>
                </div>
            </div>
        </div>
    </div>
    <!--iframe solution-->

    <div *ngIf="IsProcessing && showfinal" id="getready">

        <br><br>
        <h5>Press CONTINUE to be redirected to our payment processing screen and input your client's Credit Card or ACH information.</h5>
        <br><br>
        <form method="POST" action="{{ContinuationURL}}" ngNoForm id="form1" target="_blank">
            <input type="hidden" value="{{Endpoint}}" id="ReferrerCallbackUrl" name="ReferrerCallbackUrl">
            <input type="hidden" value="{{MemberAccessToken}}" id="healthspace-token" name="healthspace-token">
            <input type="hidden" value="{{HCItoken}}" id="ReferrerToken" name="ReferrerToken">
            <input type="hidden" value="{{ContinuationURL}}" id="ContinuationURL" name="ContinuationURL">
            <input type="hidden" value="https://afeusaenroll.com" id="Referrer" name="Referrer">
            <button type="submit" form="form1" class="btn btn-primary" onclick="getElementById('getready').style.display = 'none'; getElementById('done').style.display = 'block'">Continue</button>

        </form>


    </div>
</div>






<!--*******************************-->
<!--*******************************-->
<!--*******************************-->
<!--LIGHTBOXES FAMILY for all but life-->
<!--*******************************-->
<!--*******************************-->
<!--*******************************-->

<p-dialog [(visible)]="ShowAddCWife" modal="true" [draggable]="false" [closeOnEscape]="true" [responsive]="true" [style]="{width: '1100px'}" [baseZIndex]="1">
    <p-header style="width:100%">
        <h3>Add Spouse</h3>

    </p-header>
    <div>
        <div class="row">
            <div class="col-md-3">
                <label>Relationship</label><br>
                <select class="form-control" [(ngModel)]="chrelationship">
                    <option value=""></option>
                    <option value="Spouse">Spouse</option>
                    <option value="Domestic Partner">Domestic Partner</option>
                    <option value="Spouse Divorced">Spouse Divorced</option>
                </select>
            </div>
            <div class="col-md-3">
                <label>First Name</label><br>
                <input type="text" maxlength=100 class="form-control" [(ngModel)]="chfirstname">
            </div>
            <div class="col-md-3">
                <label>Middle Name</label><br>
                <input type="text" maxlength=100 class="form-control" [(ngModel)]="chmiddlename">
            </div>
            <div class="col-md-3">
                <label>Last Name</label><br>
                <input type="text" maxlength=100 class="form-control" [(ngModel)]="chlastname">
            </div>
            <div class="col-md-3">
                <label>Gender</label><br>
                <select class="form-control" [(ngModel)]="chgender">
                    <option value=""></option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                </select>
            </div>
            <div class="col-md-3">
                <label>Date of Birth</label><br>
                <input type="text" maxlength=100 class="form-control" [(ngModel)]="chdob" mask="M0/d0/0000" [dropSpecialCharacters]="false" placeholder="mm/dd/yyyy">
            </div>
            <div class="col-md-3">
                <label>Social Security Number</label><br>
                <input type="text" maxlength=100 class="form-control" [(ngModel)]="chssn" mask="000-00-0000" [dropSpecialCharacters]="false">
            </div>
            <div class="col-md-3">
                <label>Is this dependent disabled</label><br>
                <select class="form-control" [(ngModel)]="chdisabled">
                    <option value=""></option>
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                </select>
            </div>
            <div class="col-md-6">
                <label>Address</label><br>
                <input type="text" maxlength=30 class="form-control" [(ngModel)]="chaddress">
            </div>
            <div class="col-md-6">
                <label>Address 2</label><br>
                <input type="text" maxlength=30 class="form-control" [(ngModel)]="chaddress2">
            </div>
            <div class="col-md-3">
                <label>City</label><br>
                <input type="text" maxlength=30 class="form-control" [(ngModel)]="chcity">
            </div>
            <div class="col-md-3">
                <label>State</label><br>
                <input type="text" maxlength=30 class="form-control" disabled [(ngModel)]="chstate">
            </div>
            <div class="col-md-3">
                <label>ZIP Code</label><br>
                <input type="text" maxlength=5 class="form-control" [(ngModel)]="chzip">
            </div>
            <div class="col-md-3">
                <label>Phone Number</label><br>
                <input type="text" maxlength=30 class="form-control" [(ngModel)]="chphone" mask="(000) 000-0000" [dropSpecialCharacters]="false">
            </div>

        </div>
    </div>
    <p-footer>
        <div class="text-right">
            <button class="btn btn-danger" (click)="SaveChildren('S')">+ Add Dependent</button>
        </div>
    </p-footer>
</p-dialog>


<!--Add Children-->
<p-dialog [(visible)]="ShowAddChildren" modal="true" [draggable]="false" [closeOnEscape]="true" [responsive]="true" [style]="{width: '1100px'}" [baseZIndex]="1">

    <p-header style="width:100%">
        <h3>Add Dependent Child</h3>

    </p-header>
    <div>
        <div class="row">
            <div class="col-md-3">
                <label>Relationship</label><br>
                <select class="form-control" [(ngModel)]="chrelationship">
                    <option value=""></option>
                    <option value="Dependent Child">Dependent Child</option>
                    <option value="Dependend of Domestic Partner">Dependend of Domestic Partner</option>
                </select>
            </div>
            <div class="col-md-3">
                <label>First Name</label><br>
                <input type="text" maxlength=100 class="form-control" [(ngModel)]="chfirstname">
            </div>
            <div class="col-md-3">
                <label>Middle Name</label><br>
                <input type="text" maxlength=100 class="form-control" [(ngModel)]="chmiddlename">
            </div>
            <div class="col-md-3">
                <label>Last Name</label><br>
                <input type="text" maxlength=100 class="form-control" [(ngModel)]="chlastname">
            </div>
            <div class="col-md-3">
                <label>Gender</label><br>
                <select class="form-control" [(ngModel)]="chgender">
                    <option value=""></option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                </select>
            </div>
            <div class="col-md-3">
                <label>Date of Birth</label><br>
                <input type="text" maxlength=100 class="form-control" [(ngModel)]="chdob" mask="M0/d0/0000" [dropSpecialCharacters]="false" placeholder="mm/dd/yyyy">
            </div>
            <div class="col-md-3">
                <label>SSN (Optional)</label><br>
                <input type="text" maxlength=100 class="form-control" [(ngModel)]="chssn" mask="000-00-0000" [dropSpecialCharacters]="false">
            </div>
            <div class="col-md-3">
                <label>Is this dependent disabled</label><br>
                <select class="form-control" [(ngModel)]="chdisabled">
                    <option value=""></option>
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                </select>
            </div>
            <div class="col-md-6">
                <label>Address</label><br>
                <input type="text" maxlength=30 class="form-control" [(ngModel)]="chaddress">
            </div>
            <div class="col-md-6">
                <label>Address 2</label><br>
                <input type="text" maxlength=30 class="form-control" [(ngModel)]="chaddress2">
            </div>
            <div class="col-md-3">
                <label>City</label><br>
                <input type="text" maxlength=30 class="form-control" [(ngModel)]="chcity">
            </div>
            <div class="col-md-3">
                <label>State</label><br>
                <input type="text" maxlength=30 class="form-control" disabled [(ngModel)]="chstate">
            </div>
            <div class="col-md-3">
                <label>ZIP Code</label><br>
                <input type="text" maxlength=5 class="form-control" [(ngModel)]="chzip">
            </div>
            <div class="col-md-3">
                <label>Phone Number</label><br>
                <input type="text" maxlength=30 class="form-control" [(ngModel)]="chphone" mask="(000) 000-0000" [dropSpecialCharacters]="false">
            </div>

        </div>
    </div>
    <p-footer>
        <div class="text-right">
            <button class="btn btn-danger" (click)="SaveChildren('C')">+ Add Dependent</button>
        </div>
    </p-footer>
</p-dialog>



<!--Add Life Beneficiary-->
<p-dialog [(visible)]="ShowAddBeneficiary" modal="true" [draggable]="false" [closeOnEscape]="true" [responsive]="true" [style]="{width: '1100px'}" [baseZIndex]="1">

    <p-header style="width:100%">
        <h3>Add Life Insurance Beneficiary</h3>

    </p-header>
    <div>
        <div class="row">
            <div class="col-md-2 col-sm-12 col-12">
                <label>Beneficiary Type</label><br>
                <select class="form-control" [(ngModel)]="litype">
                    <option value="Person">Person</option>
                    <option value="Trust">Trust Fund</option>
                </select>
            </div>
            <div class="col-md-2 col-sm-12 col-12">
                <label>Percentage</label><br>
                <select class="form-control" [(ngModel)]="lipercentage">
                    <option *ngFor="let item of [].constructor(lirestpercentage); let i = index" value="{{i}}">{{i}}
                        %</option>
                </select>
            </div>
            <div class="col-md-4 col-sm-12 col-12" *ngIf="litype=='Person'">
                <label>First Name</label><br>
                <input type="text" maxlength=30 class="form-control" [(ngModel)]="lifirstname">
            </div>
            <div class="col-md-4 col-sm-12 col-12" *ngIf="litype=='Person'">
                <label>Last Name</label><br>
                <input type="text" maxlength=30 class="form-control" [(ngModel)]="lilastname">
            </div>
            <div class="col-md-8 col-sm-12 col-12" *ngIf="litype=='Trust'">
                <label>Trust Name</label><br>
                <input type="text" maxlength=30 class="form-control" [(ngModel)]="lilastname">
            </div>


            <div class="col-md-4 col-sm-12 col-12">
                <label>Address</label><br>
                <input type="text" maxlength=30 class="form-control" [(ngModel)]="liaddress">
            </div>

            <div class="col-md-4 col-sm-12 col-12">
                <label>City</label><br>
                <input type="text" maxlength=30 class="form-control" [(ngModel)]="licity">
            </div>
            <div class="col-md-2 col-sm-12 col-12">
                <label>State</label><br>
                <select [(ngModel)]="listate" class="form-control" name="residentstate" id="residentstate" style="border-radius: 7px;">
                    <option value=""></option>
                    <option *ngFor="let USstate of USstatesFull" value="{{USstate.Code}}">{{USstate.Name}}</option>
                </select>
            </div>
            <div class="col-md-2 col-sm-12 col-12">
                <label>ZIP Code</label><br>
                <input type="text" maxlength=5 class="form-control" [(ngModel)]="lizip">
            </div>


        </div>

    </div>
    <p-footer>
        <div class="text-right">
            <button class="btn btn-danger" (click)="SaveLifePrimary()">+ Add Beneficiary</button>
        </div>
    </p-footer>
</p-dialog>


<!--Add Contingent Life Beneficiary-->
<p-dialog [(visible)]="ShowAddContingentBeneficiary" modal="true" [draggable]="false" [closeOnEscape]="true" [responsive]="true" [style]="{width: '1100px'}" [baseZIndex]="1">

    <p-header style="width:100%">
        <h3>Add Life Insurance Contingent Beneficiary</h3>

    </p-header>
    <div>
        <div class="row">
            <div class="col-md-2 col-sm-12 col-12">
                <label>Beneficiary Type</label><br>
                <select class="form-control" [(ngModel)]="litype">
                    <option value="Person">Person</option>
                    <option value="Trust">Trust Fund</option>
                </select>
            </div>
            <div class="col-md-2 col-sm-12 col-12">
                <label>Percentage</label><br>
                <select class="form-control" [(ngModel)]="liContpercentage">
                    <option *ngFor="let item of [].constructor(liContrestpercentage); let i = index" value="{{i}}">
                        {{i}}
                        %</option>
                </select>
            </div>
            <div class="col-md-4 col-sm-12 col-12" *ngIf="litype=='Person'">
                <label>First Name</label><br>
                <input type="text" maxlength=30 class="form-control" [(ngModel)]="lifirstname">
            </div>
            <div class="col-md-4 col-sm-12 col-12" *ngIf="litype=='Person'">
                <label>Last Name</label><br>
                <input type="text" maxlength=30 class="form-control" [(ngModel)]="lilastname">
            </div>
            <div class="col-md-8 col-sm-12 col-12" *ngIf="litype=='Trust'">
                <label>Trust Name</label><br>
                <input type="text" maxlength=30 class="form-control" [(ngModel)]="lilastname">
            </div>


            <div class="col-md-4 col-sm-12 col-12">
                <label>Address</label><br>
                <input type="text" maxlength=30 class="form-control" [(ngModel)]="liaddress">
            </div>

            <div class="col-md-4 col-sm-12 col-12">
                <label>City</label><br>
                <input type="text" maxlength=30 class="form-control" [(ngModel)]="licity">
            </div>
            <div class="col-md-2 col-sm-12 col-12">
                <label>State</label><br>
                <select [(ngModel)]="listate" class="form-control" name="residentstate" id="residentstate" style="border-radius: 7px;">
                    <option value=""></option>
                    <option *ngFor="let USstate of USstatesFull" value="{{USstate.Code}}">{{USstate.Name}}</option>
                </select>
            </div>
            <div class="col-md-2 col-sm-12 col-12">
                <label>ZIP Code</label><br>
                <input type="text" maxlength=5 class="form-control" [(ngModel)]="lizip">
            </div>


        </div>

    </div>

    <p-footer>
        <div class="text-right">
            <button class="btn btn-danger" (click)="SaveLifeContingent()">+ Add Beneficiary</button>
        </div>
    </p-footer>
</p-dialog>




<!--
<div id="footer" *ngIf="ShowCart">
    <div class="row">
        <div class="col-sm-6">
            <button class="btn btn-primary " (click)="CheckOut()" style="margin-right: 10px;">Continue
                Application</button>
            <button class="btn btn-warning " style="margin-right: 10px;">Save for Later</button>
            <button class="btn btn-outline-dark " routerLink="../dashboard">Cancel</button>
        </div>
        <div class="col-sm-6 text-right" style="padding-top:15px; padding-right:140px;">
            <h4>Monthly Payment: ${{TotalToDisplay}}</h4>
        </div>
    </div>

</div>
-->