import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-listusers',
  templateUrl: './listusers.component.html',
  styleUrls: ['./listusers.component.css']
})
export class ListusersComponent implements OnInit {
  APIresult: any;
  Users: any;
    
    constructor(
      public _httpClient: HttpClient,
      private _router: Router,
      private r: ActivatedRoute,
    ) {
  
  
      var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/usersList.aspx";    // set header for http call
      let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
      // set body
      let body = new URLSearchParams();
      body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
      body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
      body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
  
      this._httpClient.post(APIURL, body.toString(), options).subscribe(
        result => {
          this.APIresult = Array.of(result);
              this.Users = result;
        },
        error => {
  
        });
  
    }
  
  
    onRowSelect(code): void {
      this._router.navigate(['../edituser/' + code], { relativeTo: this.r });
    }
  
    
      
    
    ngOnInit(): void {
    }
  


  }
  