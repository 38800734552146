import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SelectItem, FilterService, FilterMatchMode } from 'primeng/api';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-hci',
  templateUrl: './hci.component.html',
  styleUrls: ['./hci.component.css']
})
export class HciComponent implements OnInit {
  APIresult: any;
  ContinuationUrl: string;
  AccessToken: string;
  username: string;
  password: string;
  agentid: string;
  success:boolean=false;
  constructor(
    public httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private filterService: FilterService


  ) {

    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/hcilogin.aspx";
    var options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    var body = new URLSearchParams();

    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        
        this.APIresult = result;

        if (this.APIresult.success=="1"){
          if (this.APIresult.ContinuationURL =="") {
            Swal.fire({
              title: 'Connection Failed',
              text: "HCI responded with an invalid Continuation URL",
              icon: 'error',
                
            });
            return;
          }
          this.ContinuationUrl=this.APIresult.ContinuationURL;
          this.AccessToken=this.APIresult.AccessToken;
          this.username=this.APIresult.email;
          this.password=this.APIresult.password;
          this.agentid=this.APIresult.agentid;
          this.success=true;
        }
        else
        {
          Swal.fire({
            title: 'Connection Failed',
            text: this.APIresult[0].message,
            icon: 'error',
              
          })
        }
        
      },
      error => {
        
      });

  }

  ngOnInit(): void {
  }

  Login(): void {
    alert("caca")
   
  }

  }

