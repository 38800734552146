import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2'
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-productivity',
  templateUrl: './productivity.component.html',
  styleUrls: ['./productivity.component.css']
})
export class ProductivityComponent implements OnInit {
  APItraining: any;
  OpenVideo: boolean = false;
  VideoTitle: string="";
  VideoURL: any;
  
  BreadCrumb: string;
  BreadCrumbSub: string;

  SELECTEDcategory: any;
  activecategory: string;

  SELECTEDsection: any;
  activesection: string;

  SELECTEDcontent: any;

  constructor(
    public httpClient: HttpClient,
    private toastr: ToastrService,
    private _sanitizer: DomSanitizer,
  ) { 
    this.ReadAPI()
      .then((data) => {
        this.APItraining = (data);
      })
      .catch((error) => {

      });
  }
  navbarOpen = false;

  

  ReadAPI() {
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/training/viewcontent.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    return this.httpClient.post(APIURL, body.toString(), options).toPromise();
  }

  ngOnInit(): void {
  }



  enableCategory(category): void {
    this.BreadCrumb = "Browsing: "
    for (let result of this.APItraining) {
      if (result.categoryid.toString() == category) {
        this.SELECTEDcategory = result.TrainingSections
        this.activecategory = category;
        this.BreadCrumb = result.categorytext;
        this.BreadCrumbSub = "";
        console.log(this.SELECTEDcategory)
      }
    }
    this.SELECTEDsection = undefined;
    this.activesection = undefined;

  }


  enableSection(section): void {
    //this.SELECTEDsection = this.SELECTEDcategory[section - 1].TrainingContent
    //this.activesection = section
    for (let result of this.SELECTEDcategory) {
      if (result.sectionid.toString() == section) {
        this.SELECTEDsection = result.TrainingContent
        this.activesection = section;
        this.BreadCrumbSub = result.sectionname;
      }
    }


  }


  viewContent(id): void {

    for (let result of this.SELECTEDsection) {
      if (result.contentid == id) {
        this.SELECTEDcontent = result;
        if (result.contenttype=='video') {
          this.OpenVideo=true;
          this.VideoTitle=result.title;
          this.VideoURL=this._sanitizer.bypassSecurityTrustResourceUrl(result.destination)
        }
        if (result.contenttype=='file') {
          window.open(result.destination, '_blank').focus();
        }
      }
    }

    

  }

}
