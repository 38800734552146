<h4>
    New Application</h4>
<hr>

<p-tabView [activeIndex]="tabindex" (onChange)="onTabChange($event)">
    <!--Personal Information Tab-->
    <p-tabPanel header="Member Information">
        <h3>About The Member</h3>
        <hr>
        <div class="row">
            <div class="col-md-3">
                <label>First Name</label><br>
                <input type="text" maxlength=30 class="form-control" [(ngModel)]="firstname">
            </div>
            <div class="col-md-3">
                <label>Middle Name</label><br>
                <input type="text" maxlength=30 class="form-control" [(ngModel)]="middlename">
            </div>
            <div class="col-md-3">
                <label>Last Name</label><br>
                <input type="text" maxlength=30 class="form-control" [(ngModel)]="lastname">
            </div>
            <div class="col-md-3">
                <label>Date of Birth</label><br>
                <input type="text" maxlength=30 class="form-control" disabled [(ngModel)]="dob" placeholder="mm/dd/yyyy">
            </div>

            <div class="col-md-6">
                <label>Address</label><br>
                <input type="text" maxlength=30 class="form-control" [(ngModel)]="address">
            </div>
            <div class="col-md-6">
                <label>Address 2</label><br>
                <input type="text" maxlength=30 class="form-control" [(ngModel)]="address2">
            </div>
            <div class="col-md-3">
                <label>City</label><br>
                <input type="text" maxlength=30 class="form-control" [(ngModel)]="city">
            </div>
            <div class="col-md-3">
                <label>State</label><br>
                <input type="text" maxlength=30 class="form-control" disabled [(ngModel)]="state">
            </div>
            <div class="col-md-3">
                <label>ZIP Code</label><br>
                <input type="text" maxlength=5 class="form-control" [(ngModel)]="zip">
            </div>
            <div class="col-md-3">
                <label>Phone Number</label><br>
                <input type="text" maxlength=30 class="form-control" [(ngModel)]="phone" mask="(000) 000-0000" [dropSpecialCharacters]="false">
            </div>


            <div class="col-md-3">
                <label>Gender</label><br>
                <select class="form-control" [(ngModel)]="gender">
                    <option value=""></option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                </select>
            </div>
            <div class="col-md-3">
                <label>Social Security Number</label><br>
                <input type="text" maxlength=30 class="form-control" [(ngModel)]="ssn" mask="000-00-0000" [dropSpecialCharacters]="false">
            </div>
            <div class="col-md-3">
                <label>Marital Status</label><br>
                <select class="form-control" [(ngModel)]="marritalstatus">
                    <option value=""></option>
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                    <option value="Divorced">Divorced</option>
                    <option value="Widowed">Widowed</option>
                </select>
            </div>
            <div class="col-md-3">
                <label>Email Address</label><br>
                <input type="text" maxlength=70 class="form-control" [(ngModel)]="email">
            </div>



        </div>
        <br>
        <h3>Enter your member portal password</h3>
        <hr>
        <div class="row">
            <div class="col-md-4">
                <label>Login Email</label><br>
                <input type="text" maxlength=30 class="form-control" [(ngModel)]="email" disabled>
            </div>
            <div class="col-md-4">
                <label>Password</label><br>
                <input type="password" maxlength=30 class="form-control" [(ngModel)]="upassword">
            </div>
            <div class="col-md-4">
                <label>Confirm your password</label><br>
                <input type="password" maxlength=30 class="form-control" [(ngModel)]="upassword2">
            </div>
        </div>
        <br>
        <button class="btn default-btn" (click)="CheckStep1()">Continue </button>
    </p-tabPanel>
    <!--Family Information Tab-->
    <p-tabPanel header="Family Members" *ngIf="groupplan=='1'">
        <h3>Family Members</h3>
        <hr>


        <div>
            <div *ngFor="let child of PayLoad.DependentDemographics; let i=index">
                <div class="row">
                    <div class="col-md-3">
                        {{child.FirstName}} {{child.LastName}}
                    </div>
                    <div class="col-md-2">
                        {{child.Gender}}
                    </div>
                    <div class="col-md-2">
                        {{child.BirthDate}}
                    </div>
                    <div class="col-md-3">
                        {{child.Relationship}}
                    </div>
                    <div class="col-md-2 text-right">
                        <button class="btn btn-danger btn-sm" (click)="DeleteChildden(i)">X</button>
                    </div>
                </div>
                <hr>
            </div>
        </div>
        <button class="btn btn-warning btn-xs text-white" (click)="ShowAddCWife=true" style="margin-right: 10px;" *ngIf="!spadded">+ Add Spouse</button>
        <button class="btn btn-warning btn-xs text-white" (click)="ShowAddChildren=true">+ Add
            Children</button>
        <br>
        <br>
        <hr>
        <button class="btn default-btn" (click)="CheckStep2()">Continue </button>
    </p-tabPanel>

    <!--Life Insurance-->
    <p-tabPanel header="Life Insurance" *ngIf="lifeplan=='1'">
        <h3>Life Insurance Beneficiaries</h3>
        <hr>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                <div class="single-events-box pad">
                    <br>
                    <h5>Primary Beneficiaries</h5>
                    <hr>
                    <div *ngFor="let beneficiary of PayLoad.Beneficiaries; let i=index">
                        <div *ngIf="beneficiary.BType=='P'">
                            <div class="row">
                                <div class="col-md-8">
                                    {{beneficiary.FirstName}} {{beneficiary.LastName}} ({{beneficiary.Type}})
                                </div>

                                <div class="col-md-2">
                                    {{beneficiary.Allocations[0].Percentage}}%
                                </div>

                                <div class="col-md-2 text-left">
                                    <button class="btn btn-danger btn-sm" (click)="DeleteBeneficiary(i)">X</button>
                                </div>
                            </div>
                            <hr>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <strong>Total %</strong>
                        </div>

                        <div class="col-md-2">
                            <strong>
                                {{lisumpercentage}}%
                            </strong>
                        </div>

                        <div class="col-md-2 text-left">

                        </div>
                    </div>
                    <br>
                    <button *ngIf="lisumpercentage < 100" class="btn btn-warning btn-xs text-white" (click)="ShowAddBeneficiary=true" style="margin-right: 10px;">+ Add Beneficiary</button>
                </div>

            </div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                <div class="single-events-box pad">
                    <br>
                    <h5>Contingent Beneficiaries</h5>
                    <hr>
                    <div *ngFor="let beneficiary of PayLoad.Beneficiaries; let i=index">
                        <div *ngIf="beneficiary.BType=='C'">
                            <div class="row">
                                <div class="col-md-8">
                                    {{beneficiary.FirstName}} {{beneficiary.LastName}} ({{beneficiary.Type}})
                                </div>
                                <div class="col-md-2">
                                    {{beneficiary.Allocations[0].Percentage}}%
                                </div>

                                <div class="col-md-2 text-left">
                                    <button class="btn btn-danger btn-sm" (click)="DeleteContingentBeneficiary(i)">X</button>
                                </div>
                            </div>
                            <hr>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <strong>Total %</strong>
                        </div>

                        <div class="col-md-2">
                            <strong>
                                {{liContsumpercentage}}%
                            </strong>
                        </div>

                        <div class="col-md-2 text-left">

                        </div>
                    </div>

                    <br>
                    <button *ngIf="liContsumpercentage < 100" class="btn btn-warning btn-xs text-white" (click)="ShowAddContingentBeneficiary=true" style="margin-right: 10px;">+ Add
                        Beneficiary</button>
                </div>

            </div>
        </div>
        <br>
        <button class="btn default-btn" (click)="CheckStep3()">Continue </button>
    </p-tabPanel>
    <!--Life Insurance-->
    <p-tabPanel header="Checkout">
        <h3>Checkout </h3>
        <hr>
        <h6>Shopping Cart Content</h6>
        <hr>


        <!--Product Row-->
        <div class="single-events-box pad" *ngIf="legendMembership">
            <div class="row">
                <div class="col-md-11">
                    <strong class="DarkBlue TitleItem">{{legendMembership}}</strong>
                    <br>

                    <a (click)="overlapOpen=true; overlapContent='membership'" class="mousepointer text-primary font11">(Click to Change Plan)</a>

                </div>
                <div class="col-md-1 text-right DarkBlue TitleItem">${{MembershipQuote}}</div>
            </div>
        </div>


        <!--Product Row-->
        <div class="single-events-box pad" *ngIf="legendDental">
            <div class="row">
                <div class="col-md-11">
                    <strong class="DarkBlue TitleItem">{{legendDental}}</strong>
                    <br>
                    <div class="GreyText">
                        <span class="ThatBlue">This Plan Covers: </span>
                        <span class="GreyText">{{firstname}} {{lastname}}</span>
                        <span class="GreyText" *ngFor="let familymember of PayLoad.DependentDemographics">
                            <span class="GreyText"
                                *ngIf="MemberShowDental=='S' && familymember.AFEtype=='S'">,
                                {{familymember.FirstName}} {{familymember.LastName}}</span>
                        <span class="GreyText" *ngIf="MemberShowDental=='A'">,
                                {{familymember.FirstName}}
                                {{familymember.LastName}}</span>
                        </span>
                    </div>
                    <a (click)="overlapOpen=true; overlapContent='dental'" class="mousepointer text-primary font11">(Click to Change Plan)</a>

                </div>
                <div class="col-md-1 text-right DarkBlue TitleItem">${{DentalQuote}}</div>
            </div>
        </div>

        <!--Product Row-->
        <div class="single-events-box pad" *ngIf="legendMedical">
            <div class="row">
                <div class="col-md-11">
                    <strong class="DarkBlue TitleItem">{{legendMedical}}</strong>
                    <br>
                    <div class="GreyText">
                        <span class="ThatBlue">This Plan Covers: </span>
                        <span class="GreyText">{{firstname}} {{lastname}}</span>
                        <span class="GreyText" *ngFor="let familymember of PayLoad.DependentDemographics">
                            <span class="GreyText"
                                *ngIf="MemberShowMedical=='S' && familymember.AFEtype=='S'">,
                                {{familymember.FirstName}} {{familymember.LastName}}</span>
                        <span class="GreyText" *ngIf="MemberShowMedical=='C' && familymember.AFEtype=='C'">,
                                {{familymember.FirstName}} {{familymember.LastName}}</span>
                        <span class="GreyText" *ngIf="MemberShowMedical=='A'">,
                                {{familymember.FirstName}} {{familymember.LastName}}</span>
                        </span>
                    </div>
                    <a (click)="overlapOpen=true; overlapContent='medical'" class="mousepointer text-primary font11">(Click to Change Plan)</a>

                </div>
                <div class="col-md-1 text-right DarkBlue TitleItem">${{MedicalQuote}}</div>
            </div>
        </div>




        <!--Product Row-->
        <div class="single-events-box pad" *ngIf="legendHospital">
            <div class="row">
                <div class="col-md-11">
                    <strong class="DarkBlue TitleItem">{{legendHospital}}</strong>
                    <br>
                    <div class="GreyText">
                        <span class="ThatBlue">This Plan Covers: </span>
                        <span class="GreyText">{{firstname}} {{lastname}}</span>
                        <span class="GreyText" *ngFor="let familymember of PayLoad.DependentDemographics">
                            <span class="GreyText"
                                *ngIf="MemberShowHospital=='S' && familymember.AFEtype=='S'">,
                                {{familymember.FirstName}} {{familymember.LastName}}</span>
                        <span class="GreyText" *ngIf="MemberShowHospital=='C' && familymember.AFEtype=='C'">,
                                {{familymember.FirstName}} {{familymember.LastName}}</span>
                        <span class="GreyText" *ngIf="MemberShowHospital=='A'">,
                                {{familymember.FirstName}} {{familymember.LastName}}</span>
                        </span>
                    </div>
                    <a (click)="overlapOpen=true; overlapContent='hospital'" class="mousepointer text-primary font11">(Click to Change Plan)</a>

                </div>
                <div class="col-md-1 text-right DarkBlue TitleItem">${{HospitalQuote}}</div>
            </div>
        </div>




        <!--Product Row-->
        <div class="single-events-box pad" *ngIf="legendCritical">
            <div class="row">
                <div class="col-md-11">
                    <strong class="DarkBlue TitleItem">{{legendCritical}}</strong>
                    <br>
                    <div class="GreyText">
                        <span class="ThatBlue">This Plan Covers: </span>
                        <span class="GreyText">{{firstname}} {{lastname}}</span>
                        <span class="GreyText" *ngFor="let familymember of PayLoad.DependentDemographics">
                            <span class="GreyText"
                                *ngIf="MemberShowCritical=='S' && familymember.AFEtype=='S'">,
                                {{familymember.FirstName}} {{familymember.LastName}}</span>
                        <span class="GreyText" *ngIf="MemberShowCritical=='C' && familymember.AFEtype=='C'">,
                                {{familymember.FirstName}} {{familymember.LastName}}</span>
                        <span class="GreyText" *ngIf="MemberShowCritical=='A'">,
                                {{familymember.FirstName}} {{familymember.LastName}}</span>
                        </span>
                    </div>
                    <a (click)="overlapOpen=true; overlapContent='critical'" class="mousepointer text-primary font11">(Click to Change Plan)</a>

                </div>
                <div class="col-md-1 text-right DarkBlue TitleItem">${{CriticalQuote}}</div>
            </div>
        </div>




        <!--Product Row-->
        <div class="single-events-box pad" *ngIf="legendLife25">
            <div class="row">
                <div class="col-md-11">
                    <strong class="DarkBlue TitleItem">{{legendLife25}}</strong>
                    <br>
                    <div class="GreyText">
                        <span class="ThatBlue">This Plan Covers: </span>
                        <span class="GreyText">{{firstname}} {{lastname}}</span>
                    </div>
                    <a (click)="overlapOpen=true; overlapContent='life'" class="mousepointer text-primary font11">(Click to Change Plan)</a>

                </div>
                <div class="col-md-1 text-right DarkBlue TitleItem">${{Life25Quote}}</div>
            </div>
        </div>

        <!--Product Row-->

        <div class="single-events-box pad" *ngIf="legendLife75">
            <div class="row">
                <div class="col-md-11">
                    <strong class="DarkBlue TitleItem">{{legendLife75}}</strong>
                    <br>
                    <div class="GreyText">
                        <span class="ThatBlue">This Plan Covers: </span>
                        <span class="GreyText">{{firstname}} {{lastname}}</span>
                    </div>
                    <a (click)="overlapOpen=true; overlapContent='life'" class="mousepointer text-primary font11">(Click to Change Plan)</a>

                </div>
                <div class="col-md-1 text-right DarkBlue TitleItem">${{Life75Quote}}</div>
            </div>
        </div>

        <hr>
        <!--Product Row-->
        <div class="single-events-box pad">
            <div class="row" *ngIf="TotalToDisplay">
                <div class="col-md-10 DarkBlue TitleItem"><strong>Your Total Cost Per Month</strong></div>
                <div class="col-md-2 text-right DarkBlue TitleItem"><strong>${{TotalToDisplay}}</strong>
                </div>
            </div>
        </div>
        <br>

        <div class="text-right">
            <button class="btn default-btn-warning mr5" routerLink="../enrollment">&#60;&#60; Modify Plan
                Selections</button>

            <button class="btn btn-primary" (click)="Finish()">Checkout >></button>
        </div>
        <br>
        <br>
    </p-tabPanel>
</p-tabView>




<div id="footer">
    <div class="row">
        <div class="col-sm-6">
            <button class="btn btn-primary " routerLink="../application" style="margin-right: 10px;">Back to Products Selection</button>
            <button class="btn btn-warning " style="margin-right: 10px;">Save for Later</button>
            <button class="btn btn-outline-dark " routerLink="../dashboard">Cancel</button>
        </div>
        <div class="col-sm-6 text-right" style="padding-top:15px; padding-right:140px;">
            <h4>Monthly Payment: ${{TotalToDisplay}}</h4>
        </div>
    </div>

</div>