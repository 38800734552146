import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SelectItem, FilterService, FilterMatchMode } from 'primeng/api';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-viewcontacts',
  templateUrl: './viewcontacts.component.html',
  styleUrls: ['./viewcontacts.component.css']
})
export class ViewcontactsComponent implements OnInit {
  public FilterType: string="";
  //NG Table Setup
  public type;
  public ShowLead: boolean=false;
  public DivPipeline: boolean=false;
  public DivPermissions: boolean=false;
  public DivTimeline: boolean=false;
  public DivFunnel: boolean=false;
  public DivLeadID: string;
  public DivNotes: boolean=false;
  public DivNotesShowNew: boolean=false;
  public allowWrite: boolean=true;
  public BestTimeToContact: string="";
  public sendemail: string;
  public sendsms: string;

  public datesent: string;
  public dateclicked: string;
  public dateopened: string;
  
  
  LeadsList: any[];
  NotesList: any[];
  usersList: any[];
  cols: any[];
  matchModeOptions: SelectItem[];


  //controls

  //notes
  public MyNoteColor: string;
  public MyNoteTitle: string;
  public MyNoteText: string;
  //form general

  public fullleadview: boolean = false;
  public MyColor: string = "bg-primary";
  public APIresult: any;

  //basic
  public contacttype: string = "";
  public salutation: string = "";
  public firstname: string = "";
  public lastname: string = "";
  public email: string = "";
  public cellphone: string = "";
  public dob: string = "";
  public workingdeal: string = "";


  //clients
  public clienttype: string = "";
  public pipelinestatus: string = "";
  public funnelstatus: string = "";
  public source: string = "";
  public sourceother: string = "";
  public preferredcontact: string = "";

  //deal
  public dealname: string = "";
  public dealamount: string = "";
  public dealstartdate: string = "";
  public dealenddate: string = "";
  public dealsource: string = "";

  //additional
  public address: string = "";
  public city: string = "";
  public state: string = "";
  public zip: string = "";
  public country: string = "United States";
  public homephone: string = "";
  public workphone: string = "";
  public fax: string = "";
  public sms: string = "";
  public otherphone: string = "";
  public company: string = "";
  public possition: string = "";
  public website: string = "";
  public skype: string = "";

  // social
  public facebook: string = "";
  public linkedin: string = "";
  public twitter: string = "";
  public instagram: string = "";
  public youtube: string = "";
  public onesitebox: string = "";

  //notes
  public notetitle: string = "";
  public note: string = "";


  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize'],
      ['insertImage'],
      ['insertVideo'],
    ]
  };

  
  public leadID: string;

  constructor(
    public httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private filterService: FilterService

  ) { 

    
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/getallleads.aspx";    // set header for http call
    var options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    var body = new URLSearchParams();
    
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.LeadsList = Object.assign([], result);
      },
      error => {

      });
  }

  

  OpenFull(): void {
    this.fullleadview = true;
  }

  ChangeColor(mycolor: string): void {
    this.MyColor = mycolor;

  }

  ChangeColor2(mycolor: string): void {
    this.MyNoteColor = mycolor;

  }


  ngOnInit(): void {
    
  }

  ClearDivs(): void {
    this.DivPipeline=false;
    this.DivPermissions=false;
    this.DivTimeline=false;
    this.DivFunnel=false;
    this.DivNotes=false;
  }
  


  CloseDiv(): void {
    this.ClearDivs();
    this.ShowLead=false;
  }
  
  decode(content: string){
    return decodeURIComponent(content);
  }

  


  EmailLead(id, target): void {
    
    Swal.fire({
      title: 'Resend Email?',
      text: "Please confirm if you want to resend the invite email to the prospect",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, resend email!'
    }).then((result) => {
      if (result.isConfirmed) {
       
        var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/resendemail.aspx";    // set header for http call
        var options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
        var body = new URLSearchParams();
        
        body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
        body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
        body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
        body.set('id', encodeURIComponent(id));
        body.set('target', encodeURIComponent(target));
    
        this.httpClient.post(APIURL, body.toString(), options).subscribe(
          result => {
            Swal.fire({ title: 'Email Sent', text: 'The prospect invite email has been sent', icon: 'info' });
          },
          error => {
    
          });
    
      }
    })

    

  } 
 
 
 
  

}


