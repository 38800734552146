<div class="body-text-nonavbar" style="z-index: 1; padding-right:20px;">
    <h4>Activate Your Account</h4>
    <hr>



    <div class="bShadow-5">
        <h5>Please confirm the following information is accurate</h5>
        <hr>



        <p-tabView [activeIndex]="tabindex" (onChange)="onTabChange($event)">
            <p-tabPanel header="Personal Information">




                <div *ngIf="AgentType=='business'">
                    <h5>Business Information</h5>
                    <hr>
                    <div class="row">
                        <div class="col-xl-8 col-md-6 col-12">
                            <label>Legal Name</label><br>
                            <input type="text" maxlength="100" class="form-control" [(ngModel)]="fullcompanyname">
                        </div>
                        <div class="col-xl-4 col-md-6 col-12">
                            <label>Domicile State</label><br>
                            <select [(ngModel)]="domicilestate" class="form-control" name="residentstate"
                                id="residentstate" style="border-radius: 7px;">
                                <option value=""></option>
                                <option *ngFor="let USstate of USstates" value="{{USstate.Code}}">{{USstate.Name}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-12">
                            <label>Owner(s) / Member(s)</label><br>
                            <textarea type="text" rows="5" class="form-control" [(ngModel)]="owners"></textarea>
                        </div>
                        <div class="col-md-12">
                            <br>
                            <input type="checkbox" class="form-check-input" id="havestaff" name="havestaff" value="y"
                                style="margin-left: 0px;" (change)="changestaff($event)">
                            <label class="form-check-label nolabel" for="havestaff" style="margin-left: 20px;">I have an
                                authorized staff member that assists me in licensing or contracting of agents.</label>

                        </div>
                    </div>
                    <div *ngIf="havestaff==true">
                        <br>
                        <h5>Authorized person assisting in licensing or contracting agents</h5>
                        <hr>
                        <p>If you would have an authorized staff that assists you in licensing or contracting of agents.
                            Please complete the information below to setup a login for this support person. They will
                            not have access to the commission module.</p>
                        <div class="row">
                            <div class="col-xl-3 col-md-6 col-12">
                                <label>First Name</label><br>
                                <input type="text" maxlength=30 class="form-control" [(ngModel)]="authfirstname">
                            </div>

                            <div class="col-xl-3 col-md-6 col-12">
                                <label>Last Name</label><br>
                                <input type="text" maxlength=30 class="form-control" [(ngModel)]="authlastname">
                            </div>
                            <div class="col-xl-3 col-md-6 col-12">
                                <label>Email</label><br>
                                <input type="text" maxlength=30 class="form-control" [(ngModel)]="authemail">
                            </div>
                            <div class="col-xl-3 col-md-6 col-12">
                                <label>Phone Number</label><br>
                                <input type="text" maxlength=30 class="form-control" [(ngModel)]="authphone"
                                    mask="(000) 000-0000" [dropSpecialCharacters]="false">
                            </div>

                        </div>
                    </div>
                </div>
                <br>
                <h5>Personal Information</h5>
                <hr>

                <div class="row">
                    <!--
                    <div class="col-md-12" *ngIf="AgentType=='individual'">
                        <label>Company Name OR Full Name</label><br>
                        <input type="text" maxlength="100" class="form-control" [(ngModel)]="fullcompanyname">
                    </div>
                    -->
                    <div class="col-xl-3 col-md-6 col-12">
                        <label>First Name</label><br>
                        <input type="text" maxlength=30 class="form-control"
                            [(ngModel)]="APIresult[0].agentinfo.firstname">
                    </div>
                    <div class="col-xl-3 col-md-6 col-12">
                        <label>Middle Name</label><br>
                        <input type="text" maxlength=30 class="form-control"
                            [(ngModel)]="APIresult[0].agentinfo.middlename">
                    </div>
                    <div class="col-xl-3 col-md-6 col-12">
                        <label>Last Name</label><br>
                        <input type="text" maxlength=30 class="form-control"
                            [(ngModel)]="APIresult[0].agentinfo.lastname">
                    </div>

                    <div class="col-xl-3 col-md-6 col-12">
                        <label>Date of Birth</label><br>
                        <input type="text" maxlength=30 class="form-control" mask="M0/d0/0000"
                            [(ngModel)]="APIresult[0].agentinfo.DOB" placeholder="mm/dd/yyyy"
                            [dropSpecialCharacters]="false">
                    </div>
                    <!--
                    <div class="col-xl-3 col-md-6 col-12">
                        <label>Drivers License Number</label><br>
                        <input type="text" maxlength=15 class="form-control" [(ngModel)]="dlnumber">
                    </div>
                    <div class="col-xl-3 col-md-6 col-12">
                        <label>DL Issuing State</label><br>
                        <select [(ngModel)]="dlstate" class="form-control" name="residentstate" id="residentstate" style="border-radius: 7px;">
                            <option value=""></option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                        </select>
                    </div>
                    -->
                </div>
                <br>
                <h5>{{addressTitle}}</h5>
                <hr>
                <div class="row">

                    <div class="col-xl-6 col-md-4 col-12">
                        <label>Address</label><br>
                        <input type="text" maxlength="100" class="form-control"
                            [(ngModel)]="APIresult[0].agentinfo.address">
                    </div>
                    <div class="col-xl-6 col-md-4 col-12">
                        <label>Address 2</label><br>
                        <input type="text" maxlength="100" class="form-control"
                            [(ngModel)]="APIresult[0].agentinfo.address2">
                    </div>
                    <div class="col-xl-6 col-md-6 col-12">
                        <label>City</label><br>
                        <input type="text" maxlength="50" class="form-control"
                            [(ngModel)]="APIresult[0].agentinfo.city">
                    </div>
                    <div class="col-xl-3 col-md-6 col-12">
                        <label>State</label><br>
                        <select [(ngModel)]="APIresult[0].agentinfo.state" class="form-control" name="residentstate"
                            id="residentstate" style="border-radius: 7px;">
                            <option value=""></option>
                            <option *ngFor="let USstate of USstates" value="{{USstate.Code}}">{{USstate.Name}}</option>
                        </select>
                    </div>
                    <div class="col-xl-3 col-md-6 col-12">
                        <label>ZIP Code</label><br>
                        <input type="text" maxlength=5 class="form-control" [(ngModel)]="APIresult[0].agentinfo.zip">
                    </div>
                </div>
                <br>

                <h5>Contact Information</h5>
                <hr>
                <div class="row">
                    <div class="col-xl-3 col-md-6 col-12">
                        <label>Cell Phone Number</label><br>
                        <input [(ngModel)]="APIresult[0].agentinfo.cellphone" type="text" maxlength=30
                            class="form-control" mask="(000) 000-0000" [dropSpecialCharacters]="false">
                    </div>
                    <div class="col-xl-3 col-md-6 col-12">
                        <label>Business Phone Number</label><br>
                        <input [(ngModel)]="APIresult[0].agentinfo.businessphone" type="text" maxlength=30
                            class="form-control" mask="(000) 000-0000" [dropSpecialCharacters]="false">
                    </div>
                    <div class="col-xl-3 col-md-6 col-12">
                        <label>Fax Number</label><br>
                        <input [(ngModel)]="APIresult[0].agentinfo.faxnumber" type="text" maxlength=30
                            class="form-control" mask="(000) 000-0000" [dropSpecialCharacters]="false">
                    </div>

                </div>
                <br>




                <button class="btn btn-warning text-white" (click)="changeTab(1)">Continue </button>
            </p-tabPanel>

            <p-tabPanel header="Compensation">
                <br>
                <h5>
                    Products and Payout Setup</h5>
                <hr>

                <div class="bShadow-5">
                    <div class="table-responsive">
                        <table class="table">
                            <tr>
                                <td>Product</td>
                                <td>Year 1 Compensation</td>
                                <td>Year 2 Compensation</td>
                            </tr>
                            <tr *ngFor="let product of APIresult[0].products">
                                <td>{{product.RealName}}</td>
                                <td>{{product.Y1}}%</td>
                                <td>{{product.Y2}}%</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <br>
                <h5>W9 Information</h5>
                <hr>
                <div class="bShadow-5">

                    <div class="row">
                        <div class="col-12 col-md-4">
                            <label>Type of Business</label><br>
                            <select class="form-control" [(ngModel)]="APIresult[0].agentinfo.licTypeOfBusiness">
                                <option value="Individual">Individual</option>
                                <option value="C Corporation">C Corporation</option>
                                <option value="S Corporation">S Corporation</option>
                                <option value="Partnership">Partnership</option>
                                <option value="Trust/estate">Trust/estate</option>
                                <option value="LLC">LLC</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        <div class="col-12 col-md-4">
                            <label>First and Last Name</label><br>
                            <input type="text" maxlength="60" class="form-control" [(ngModel)]="APIresult[0].w9.Payee">
                        </div>
                        <div class="col-12 col-md-4" *ngIf="APIresult[0].agentinfo.licTypeOfBusiness=='Individual'">
                            <label>SSN</label><br>
                            <input type="text" maxlength="60" class="form-control" [(ngModel)]="APIresult[0].w9.TaxID"
                                maxlength="11" mask="000-00-0000">
                        </div>
                        <div class="col-12 col-md-4" *ngIf="APIresult[0].agentinfo.licTypeOfBusiness!='Individual'">
                            <label>EIN</label><br>
                            <input type="text" maxlength="60" class="form-control" [(ngModel)]="APIresult[0].w9.TaxID" mask="00-0000000"
                                maxlength="11">
                        </div>
                    </div>
                </div>
                <br>
                <h5>Deposit Information</h5>
                <hr>
                <div class="bShadow-5">
                    <div class="row">
                        <div class="col-md-4">
                            <label>Account Beneficiary Name</label><br>
                            <input type="text" maxlength="40" class="form-control" [(ngModel)]="APIresult[0].w9.Payee">
                        </div>
                        <div class="col-md-4">
                            <label>Bank Routing Number</label><br>
                            <input type="text" maxlength="9" class="form-control" [(ngModel)]="APIresult[0].w9.Routing"
                                (keypress)="keyPressNumbers($event)">
                        </div>
                        <div class="col-md-4">
                            <label>Bank Account Number</label><br>
                            <input type="text" maxlength="17" class="form-control" [(ngModel)]="APIresult[0].w9.Account"
                                (keypress)="keyPressNumbers($event)">
                        </div>
                        <div class="col-md-4">
                            <label>Bank Account Type</label><br>
                            <select class="form-control" [(ngModel)]="APIresult[0].w9.BankType">
                                <option value=""></option>
                                <option value="Checking">Checking Account</option>
                                <option value="Savings">Savings Account</option>
                            </select>
                        </div>
                        <div class="col-md-4">
                            <label>Bank Name</label><br>
                            <input type="text" maxlength="60" class="form-control"
                                [(ngModel)]="APIresult[0].w9.BankName">
                        </div>

                    </div>
                </div>
                <br>
                <button class="btn btn-warning text-white" (click)="changeTab(2)">Continue </button>
            </p-tabPanel>

            <p-tabPanel header="License">
                <br />
                <h3>License Information</h3>
                <hr>
                <label>Type of Business</label><br>
                <select class="form-control" [(ngModel)]="APIresult[0].agentinfo.typeofbusiness">
                    <option value="individual">Individual</option>
                    <option value="business">Agency</option>
                </select>
                <br>
                <span class="text-danger">IMPORTANT: </span>

                <div *ngIf="APIresult[0].agentinfo.typeofbusiness != 'individual'">
                    AFEUSA will validate all Agency licenses and Individual licenses against NIPR. Please fill out both
                    Agency and Individual information for contracting. If you have an Agency license, we will appoint
                    using the Agency License, however if you don’t have an Agency License in a specific state, we will
                    appoint using your Individual license though NIPR. Please complete the following information, all
                    contract information is private and for internal use only.
                </div>
                <div *ngIf="APIresult[0].agentinfo.typeofbusiness != 'individual'">
                    Please complete the following information based upon your
                    <strong>Resident State & NPN information</strong>. Licensing
                    must match your resident license information. Dylan Consulting, LLC validates all licenses against
                    NIPR database.
                </div>


                <br>
                <div *ngIf="APIresult[0].agentinfo.typeofbusiness != 'individual'">
                    <h5>Agency Resident License Information <span class="text-danger">(AS SHOWN ON RESIDENT
                            LICENSE)</span></h5>
                    <hr>
                    <div class="row">
                        <div class="col-xl-4 col-sm-6 col-md-6 col-12">
                            <label>Licensed Agency Name (as shown on NPN)</label><br>
                            <input type="text" maxlength=30 class="form-control" [(ngModel)]="npnAg.AgencyName">
                        </div>
                        <div class="col-xl-4 col-sm-6 col-md-6 col-12">
                            <label>Resident Insurance License #</label><br>
                            <input type="text" maxlength=30 class="form-control"
                                [(ngModel)]="npnAg.ResidentInsuranceNumber">
                        </div>
                        <div class="col-xl-4 col-sm-6 col-md-6 col-12">
                            <label>Resident State License</label><br>
                            <select class="form-control" style="border-radius: 7px;"
                                [(ngModel)]="npnAg.ResidentStateLicense">
                                <option value=""></option>
                                <option *ngFor="let USstate of USstates" value="{{USstate.Code}}">{{USstate.Name}}
                                </option>
                            </select>
                        </div>
                        <div class="col-xl-4 col-sm-6 col-md-6 col-12">
                            <label>Agency Type </label><br>
                            <select class="form-control" style="border-radius: 7px;" [(ngModel)]="npnAg.AgencyType">
                                <option value=""></option>
                                <option value="Sole Proprietor">Sole Proprietor</option>
                                <option value="C Corporation">C Corporation</option>
                                <option value="S Corporation">S Corporation</option>
                                <option value="Partnership">Partnership</option>
                                <option value="Trust/estate">Trust/estate</option>
                                <option value="LLC">Limited Liability Company</option>
                            </select>
                        </div>
                        <div class="col-xl-4 col-sm-6 col-md-6 col-12">
                            <label>EIN</label><br>
                            <input type="text" maxlength="60" class="form-control" [(ngModel)]="npnAg.EIN"
                                mask="00-0000000" [dropSpecialCharacters]="false">
                        </div>
                        <div class="col-xl-4 col-sm-6 col-md-6 col-12">
                            <label>Type of Insurance License</label><br>
                            <select class="form-control" style="border-radius: 7px;" [(ngModel)]="npnAg.TypeOfLicense">
                                <option value=""></option>
                                <option value="Life Only">Life Only</option>
                                <option value="Health Only">Health Only</option>
                                <option value="Life and Health">Life and Health</option>
                                <option value="P&C">P&C</option>
                                <option value="Life, Health, and P&C">Life, Health, and P&C</option>
                            </select>
                        </div>

                        <div class="col-xl-6 col-sm-6 col-md-6 col-12">
                            <label>Agency Address (as Listed on NPN)</label>
                            <input type="text" maxlength="50" class="form-control" [(ngModel)]="npnAg.Address">
                        </div>
                        <div class="col-xl-2 col-sm-6 col-md-6 col-12">
                            <label>City</label>
                            <input type="text" maxlength="50" class="form-control" [(ngModel)]="npnAg.city">
                        </div>

                        <div class="col-xl-2 col-sm-6 col-md-6 col-12">
                            <label>State</label><br>
                            <select class="form-control" style="border-radius: 7px;" [(ngModel)]="npnAg.state">
                                <option value=""></option>
                                <option *ngFor="let USstate of USstates" value="{{USstate.Code}}">{{USstate.Name}}
                                </option>
                            </select>
                        </div>
                        <div class="col-xl-2 col-sm-6 col-md-6 col-12">
                            <label>ZIP Code</label>
                            <input type="text" maxlength="5" class="form-control" [(ngModel)]="npnAg.zip">
                        </div>

                        <div class="col-md-6">
                            <label>Agency NPN Number</label><br>
                            <input type="text" maxlength=30 class="form-control" [(ngModel)]="npnAg.NPNnumber">
                        </div>
                        <div class="col-md-6">
                            <label> </label><br><br>
                            <a target="_blank" href="https://nipr.com/help/look-up-your-npn" class="text-primary">Click
                                here
                                to search</a>
                        </div>

                    </div>
                    <br>
                </div>
                <h5>Resident Individual License Information <span class="text-danger">(AS SHOWN ON RESIDENT
                        LICENSE)</span></h5>
                <div class="row">
                    <div class="col-xl-2 col-sm-6 col-md-6 col-12">
                        <label>First Name</label><br>
                        <input type="text" maxlength="50" class="form-control" [(ngModel)]="npnInd.firstname">
                    </div>
                    <div class="col-xl-2 col-sm-6 col-md-6 col-12">
                        <label>Middle Name</label><br>
                        <input type="text" maxlength="50" class="form-control" [(ngModel)]="npnInd.middlename">
                    </div>
                    <div class="col-xl-2 col-sm-6 col-md-6 col-12">
                        <label>Last Name</label><br>
                        <input type="text" maxlength="50" class="form-control" [(ngModel)]="npnInd.lastname">
                    </div>
                    <div class="col-xl-2 col-sm-6 col-md-6 col-12">
                        <label>DOB</label><br>
                        <input type="text" maxlength="50" class="form-control" mask="M0/d0/0000"
                            [(ngModel)]="npnInd.DOB" placeholder="mm/dd/yyyy" [dropSpecialCharacters]="false">
                    </div>
                    <div class="col-xl-4 col-sm-6 col-md-6 col-12">
                        <label>SSN</label><br>
                        <input type="text" maxlength="60" class="form-control" [(ngModel)]="npnInd.SSN"
                            mask="000-00-0000" [dropSpecialCharacters]="false">
                    </div>

                    <div class="col-xl-4 col-sm-6 col-md-6 col-12">
                        <label>Type of Insurance License</label><br>
                        <select class="form-control" style="border-radius: 7px;" [(ngModel)]="npnInd.TypeOfLicense">
                            <option value=""></option>
                            <option value="Life Only">Life Only</option>
                            <option value="Health Only">Health Only</option>
                            <option value="Life and Health">Life and Health</option>
                            <option value="P&C">P&C</option>
                            <option value="Life, Health, and P&C">Life, Health, and P&C</option>
                        </select>
                    </div>
                    <div class="col-xl-4 col-sm-6 col-md-6 col-12">
                        <label>Resident Insurance License #</label><br>
                        <input type="text" maxlength=30 class="form-control"
                            [(ngModel)]="npnInd.ResidentInsuranceNumber">
                    </div>
                    <div class="col-xl-4 col-sm-6 col-md-6 col-12">
                        <label>Resident State License</label><br>
                        <select class="form-control" style="border-radius: 7px;"
                            [(ngModel)]="npnInd.ResidentStateLicense">
                            <option value=""></option>
                            <option *ngFor="let USstate of USstates" value="{{USstate.Code}}">{{USstate.Name}}</option>
                        </select>
                    </div>

                    <div class="col-xl-6 col-sm-6 col-md-6 col-12">
                        <label>Resident Address (as Listed on NPN)</label>
                        <input type="text" maxlength="50" class="form-control" [(ngModel)]="npnInd.Address">
                    </div>
                    <div class="col-xl-2 col-sm-6 col-md-6 col-12">
                        <label>City</label>
                        <input type="text" maxlength="50" class="form-control" [(ngModel)]="npnInd.city">
                    </div>

                    <div class="col-xl-2 col-sm-6 col-md-6 col-12">
                        <label>State</label><br>
                        <select class="form-control" style="border-radius: 7px;" [(ngModel)]="npnInd.state">
                            <option value=""></option>
                            <option *ngFor="let USstate of USstates" value="{{USstate.Code}}">{{USstate.Name}}</option>
                        </select>
                    </div>
                    <div class="col-xl-2 col-sm-6 col-md-6 col-12">
                        <label>ZIP Code</label>
                        <input type="text" maxlength="5" class="form-control" [(ngModel)]="npnInd.zip">
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label>Individual NPN Number</label><br>
                        <input type="text" maxlength="50" class="form-control" [(ngModel)]="npnInd.NPNnumber">
                    </div>
                    <div class="col-md-6">
                        <label> </label><br><br>
                        <a target="_blank" href="https://nipr.com/help/look-up-your-npn" class="text-primary">Click here
                            to search</a>
                    </div>
                </div>
                <br>

                <br>
                <h5>E&O Coverage</h5>
                <hr>
                <div class="row">
                    <div class="col-xl-3 col-sm-6 col-md-6 col-12">
                        <label>E&O Coverage</label><br>
                        <select class="form-control" style="border-radius: 7px;"
                            [(ngModel)]="APIresult[0].agentinfo.eoCoverage">
                            <option value=""></option>
                            <option value="y">Yes</option>
                            <option value="n">No</option>
                        </select>
                    </div>
                    <div class="col-xl-3 col-sm-6 col-md-6 col-12" *ngIf="APIresult[0].agentinfo.eoCoverage=='y'">
                        <label>E&O Amount</label><br>
                        <input type="text" maxlength="70" class="form-control"
                            [(ngModel)]="APIresult[0].agentinfo.eoAmount" (keypress)="keyPressNumbers($event)"
                            mask="separator.0" thousandSeparator="," maxlength="9">
                    </div>
                    <div class="col-xl-3 col-sm-6 col-md-6 col-12" *ngIf="APIresult[0].agentinfo.eoCoverage=='y'">
                        <label>E&O Expiration</label><br>
                        <input type="text" maxlength="70" class="form-control"
                            [(ngModel)]="APIresult[0].agentinfo.eoExp" mask="M0/d0/0000" placeholder="mm/dd/yyyy"
                            [dropSpecialCharacters]="false">
                    </div>
                    <div class="col-xl-3 col-sm-6 col-md-6 col-12" *ngIf="APIresult[0].agentinfo.eoCoverage=='y'">
                        <label>Attach E&O Certificate</label><br>
                        <input type="file" class="form-control" name="eofile" id="eofile"
                            (change)="onFileChanged($event)" accept="application/pdf,image/png,image/jpeg">
                    </div>
                </div>
                <br>
                <h5>Background questions</h5>
                <hr>
                <div class="table-responsive">
                    <table class="table table-responsive table-borderless">
                        <tr>
                            <td class="text-left">
                                1. Are you now or have you ever been included in litigation with an insurance company?
                                <textarea [(ngModel)]="APIresult[0].questions[0].qExplanation" class="form-control"
                                    rows="5" placeholder="Please explain your affirmative answer for the above question"
                                    *ngIf="APIresult[0].questions[0].qAnswer=='1'"></textarea>
                            </td>
                            <td style="width: 70px"></td>
                            <td style="width: 70px">
                                <input type="radio" class="form-check-input" id="lq1-1" name="q1" value="1"
                                    [(ngModel)]="APIresult[0].questions[0].qAnswer">
                                <label class="form-check-label nolabel" for="lq1-1">Yes</label>
                            </td>
                            <td style="width: 70px">
                                <input type="radio" class="form-check-input" id="lq1-2" name="q1" value="0"
                                    [(ngModel)]="APIresult[0].questions[0].qAnswer">
                                <label class="form-check-label nolabel" for="lq1-2">No</label>
                            </td>

                        </tr>
                        <tr>
                            <td class="text-left">
                                2. Do you currently have a debit balance with any insurance company?
                                <textarea [(ngModel)]="APIresult[0].questions[1].qExplanation" class="form-control"
                                    rows="5" placeholder="Please explain your affirmative answer for the above question"
                                    *ngIf="APIresult[0].questions[1].qAnswer=='1'"></textarea>
                            </td>
                            <td style="width: 70px"></td>
                            <td style="width: 70px">
                                <input type="radio" class="form-check-input" id="lq2-1" name="q2" value="1"
                                    [(ngModel)]="APIresult[0].questions[1].qAnswer">
                                <label class="form-check-label nolabel" for="lq2-1">Yes</label>
                            </td>
                            <td style="width: 70px">
                                <input type="radio" class="form-check-input" id="lq2-2" name="q2" value="0"
                                    [(ngModel)]="APIresult[0].questions[1].qAnswer">
                                <label class="form-check-label nolabel" for="lq2-2">No</label>
                            </td>

                        </tr>
                        <tr>
                            <td class="text-left">
                                3. Have you ever had your insurance or securities license suspended or revoked?
                                <textarea [(ngModel)]="APIresult[0].questions[2].qExplanation" class="form-control"
                                    rows="5" placeholder="Please explain your affirmative answer for the above question"
                                    *ngIf="APIresult[0].questions[2].qAnswer=='1'"></textarea>
                            </td>
                            <td style="width: 70px"></td>
                            <td style="width: 70px">
                                <input type="radio" class="form-check-input" id="lq3-1" name="q3" value="1"
                                    [(ngModel)]="APIresult[0].questions[2].qAnswer">
                                <label class="form-check-label nolabel" for="lq3-1">Yes</label>
                            </td>
                            <td style="width: 70px">
                                <input type="radio" class="form-check-input" id="lq3-2" name="q3" value="0"
                                    [(ngModel)]="APIresult[0].questions[2].qAnswer">
                                <label class="form-check-label nolabel" for="lq3-2">No</label>
                            </td>

                        </tr>
                        <tr>
                            <td class="text-left">
                                4a. Have you ever been charged with, convicted of, or plead guilty or nolo contendere
                                ("no contest") to a felony of any kind?
                                <textarea [(ngModel)]="APIresult[0].questions[3].qExplanation" class="form-control"
                                    rows="5" placeholder="Please explain your affirmative answer for the above question"
                                    *ngIf="APIresult[0].questions[3].qAnswer=='1'"></textarea>
                            </td>
                            <td style="width: 70px"></td>
                            <td style="width: 70px">
                                <input type="radio" class="form-check-input" id="lq4-1" name="q4" value="1"
                                    [(ngModel)]="APIresult[0].questions[3].qAnswer">
                                <label class="form-check-label nolabel" for="lq4-1">Yes</label>
                            </td>
                            <td style="width: 70px">
                                <input type="radio" class="form-check-input" id="lq4-2" name="q4" value="0"
                                    [(ngModel)]="APIresult[0].questions[3].qAnswer">
                                <label class="form-check-label nolabel" for="lq4-2">No</label>
                            </td>

                        </tr>


                        <tr>
                            <td class="text-left">
                                4b. Have you ever been charged with, convicted of, or plead guilty or nolo contendere
                                ("no contest") to a misdemeanor involving insurance or fraud?
                                <textarea [(ngModel)]="APIresult[0].questions[4].qExplanation" class="form-control"
                                    rows="5" placeholder="Please explain your affirmative answer for the above question"
                                    *ngIf="APIresult[0].questions[4].qAnswer=='1'"></textarea>
                            </td>
                            <td style="width: 70px"></td>
                            <td style="width: 70px">
                                <input type="radio" class="form-check-input" id="lq5-1" name="q5" value="1"
                                    [(ngModel)]="APIresult[0].questions[4].qAnswer">
                                <label class="form-check-label nolabel" for="lq5-1">Yes</label>
                            </td>
                            <td style="width: 70px">
                                <input type="radio" class="form-check-input" id="lq5-2" name="q5" value="0"
                                    [(ngModel)]="APIresult[0].questions[4].qAnswer">
                                <label class="form-check-label nolabel" for="lq5-2">No</label>
                            </td>

                        </tr>

                        <tr>
                            <td class="text-left">
                                5a. Has any domestic or foreign court ever found you guilty in connection with any
                                insurance or investment-related activity?
                                <textarea [(ngModel)]="APIresult[0].questions[5].qExplanation" class="form-control"
                                    rows="5" placeholder="Please explain your affirmative answer for the above question"
                                    *ngIf="APIresult[0].questions[5].qAnswer=='1'"></textarea>
                            </td>
                            <td style="width: 70px"></td>
                            <td style="width: 70px">
                                <input type="radio" class="form-check-input" id="lq6-1" name="q6" value="1"
                                    [(ngModel)]="APIresult[0].questions[5].qAnswer">
                                <label class="form-check-label nolabel" for="lq6-1">Yes</label>
                            </td>
                            <td style="width: 70px">
                                <input type="radio" class="form-check-input" id="lq6-2" name="q6" value="0"
                                    [(ngModel)]="APIresult[0].questions[5].qAnswer">
                                <label class="form-check-label nolabel" for="lq6-2">No</label>
                            </td>

                        </tr>

                        <tr>
                            <td class="text-left">
                                5b. Has any domestic or foreign court ever found that you violated any insurance-related
                                statutes or regulations?
                                <textarea [(ngModel)]="APIresult[0].questions[6].qExplanation" class="form-control"
                                    rows="5" placeholder="Please explain your affirmative answer for the above question"
                                    *ngIf="APIresult[0].questions[6].qAnswer=='1'"></textarea>
                            </td>
                            <td style="width: 70px"></td>
                            <td style="width: 70px">
                                <input type="radio" class="form-check-input" id="lq7-1" name="q7" value="1"
                                    [(ngModel)]="APIresult[0].questions[6].qAnswer">
                                <label class="form-check-label nolabel" for="lq7-1">Yes</label>
                            </td>
                            <td style="width: 70px">
                                <input type="radio" class="form-check-input" id="lq7-2" name="q7" value="0"
                                    [(ngModel)]="APIresult[0].questions[6].qAnswer">
                                <label class="form-check-label nolabel" for="lq7-2">No</label>
                            </td>

                        </tr>

                        <tr>
                            <td class="text-left">
                                6a. Has any Federal regulatory agency or any state regulatory agency or foreign
                                financial regulatory authority ever found you to have made a false statement or omission
                                or been dishonest, unfair or unethical?
                                <textarea [(ngModel)]="APIresult[0].questions[7].qExplanation" class="form-control"
                                    rows="5" placeholder="Please explain your affirmative answer for the above question"
                                    *ngIf="APIresult[0].questions[7].qAnswer=='1'"></textarea>
                            </td>
                            <td style="width: 70px"></td>
                            <td style="width: 70px">
                                <input type="radio" class="form-check-input" id="lq8-1" name="q8" value="1"
                                    [(ngModel)]="APIresult[0].questions[7].qAnswer">
                                <label class="form-check-label nolabel" for="lq8-1">Yes</label>
                            </td>
                            <td style="width: 70px">
                                <input type="radio" class="form-check-input" id="lq8-2" name="q8" value="0"
                                    [(ngModel)]="APIresult[0].questions[7].qAnswer">
                                <label class="form-check-label nolabel" for="lq8-2">No</label>
                            </td>

                        </tr>

                        <tr>
                            <td class="text-left">
                                6b. Has any Federal regulatory agency or any state regulatory agency or foreign
                                financial regulatory authority ever found you to have been involved in a violation of
                                insurance or investment regulations or statutes?
                                <textarea [(ngModel)]="APIresult[0].questions[8].qExplanation" class="form-control"
                                    rows="5" placeholder="Please explain your affirmative answer for the above question"
                                    *ngIf="APIresult[0].questions[8].qAnswer=='1'"></textarea>
                            </td>
                            <td style="width: 70px"></td>
                            <td style="width: 70px">
                                <input type="radio" class="form-check-input" id="lq9-1" name="q9" value="1"
                                    [(ngModel)]="APIresult[0].questions[8].qAnswer">
                                <label class="form-check-label nolabel" for="lq9-1">Yes</label>
                            </td>
                            <td style="width: 70px">
                                <input type="radio" class="form-check-input" id="lq9-2" name="q9" value="0"
                                    [(ngModel)]="APIresult[0].questions[8].qAnswer">
                                <label class="form-check-label nolabel" for="lq9-2">No</label>
                            </td>

                        </tr>

                        <tr>
                            <td class="text-left">
                                6c. Has any Federal regulatory agency or any state regulatory agency or foreign
                                financial regulatory authority ever found you to have been the cause of any insurance or
                                investment-related business having its authorization to do business denied, suspended,
                                revoked or restricted?
                                <textarea [(ngModel)]="APIresult[0].questions[9].qExplanation" class="form-control"
                                    rows="5" placeholder="Please explain your affirmative answer for the above question"
                                    *ngIf="APIresult[0].questions[9].qAnswer=='1'"></textarea>
                            </td>
                            <td style="width: 70px"></td>
                            <td style="width: 70px">
                                <input type="radio" class="form-check-input" id="lq10-1" name="q10" value="1"
                                    [(ngModel)]="APIresult[0].questions[9].qAnswer">
                                <label class="form-check-label nolabel" for="lq10-1">Yes</label>
                            </td>
                            <td style="width: 70px">
                                <input type="radio" class="form-check-input" id="lq10-2" name="q10" value="0"
                                    [(ngModel)]="APIresult[0].questions[9].qAnswer">
                                <label class="form-check-label nolabel" for="lq10-2">No</label>
                            </td>

                        </tr>

                        <tr>
                            <td class="text-left">
                                6d. Has any Federal regulatory agency or any state regulatory agency or foreign
                                financial regulatory authority ever entered an order against you in connection with any
                                insurance or investment-related activity?
                                <textarea [(ngModel)]="APIresult[0].questions[10].qExplanation" class="form-control"
                                    rows="5" placeholder="Please explain your affirmative answer for the above question"
                                    *ngIf="APIresult[0].questions[10].qAnswer=='1'"></textarea>
                            </td>
                            <td style="width: 70px"></td>
                            <td style="width: 70px">
                                <input type="radio" class="form-check-input" id="lq11-1" name="q11" value="1"
                                    [(ngModel)]="APIresult[0].questions[10].qAnswer">
                                <label class="form-check-label nolabel" for="lq11-1">Yes</label>
                            </td>
                            <td style="width: 70px">
                                <input type="radio" class="form-check-input" id="lq11-2" name="q11" value="0"
                                    [(ngModel)]="APIresult[0].questions[10].qAnswer">
                                <label class="form-check-label nolabel" for="lq11-2">No</label>
                            </td>

                        </tr>

                        <tr>
                            <td class="text-left">
                                6e. Has any Federal regulatory agency or any state regulatory agency or foreign
                                financial regulatory authority ever denied, suspended, or revoked your registration or
                                license or otherwise prevented you from associating with any insurance or
                                investment-related
                                business, or disciplined you by restricting your activities?
                                <textarea [(ngModel)]="APIresult[0].questions[11].qExplanation" class="form-control"
                                    rows="5" placeholder="Please explain your affirmative answer for the above question"
                                    *ngIf="APIresult[0].questions[11].qAnswer=='1'"></textarea>
                            </td>
                            <td style="width: 70px"></td>
                            <td style="width: 70px">
                                <input type="radio" class="form-check-input" id="lq12-1" name="q12" value="1"
                                    [(ngModel)]="APIresult[0].questions[11].qAnswer">
                                <label class="form-check-label nolabel" for="lq12-1">Yes</label>
                            </td>
                            <td style="width: 70px">
                                <input type="radio" class="form-check-input" id="lq12-2" name="q12" value="0"
                                    [(ngModel)]="APIresult[0].questions[11].qAnswer">
                                <label class="form-check-label nolabel" for="lq12-2">No</label>
                            </td>

                        </tr>

                        <tr>
                            <td class="text-left">
                                7a. Have you ever been the subject of any insurance or investment-related, consumer-
                                initiated complaint or proceeding that alleged compensatory damages of $2,500 or more,
                                or found fraud or the wrongful taking of property?
                                <textarea [(ngModel)]="APIresult[0].questions[12].qExplanation" class="form-control"
                                    rows="5" placeholder="Please explain your affirmative answer for the above question"
                                    *ngIf="APIresult[0].questions[12].qAnswer=='1'"></textarea>
                            </td>
                            <td style="width: 70px"></td>
                            <td style="width: 70px">
                                <input type="radio" class="form-check-input" id="lq13-1" name="q13" value="1"
                                    [(ngModel)]="APIresult[0].questions[12].qAnswer">
                                <label class="form-check-label nolabel" for="lq13-1">Yes</label>
                            </td>
                            <td style="width: 70px">
                                <input type="radio" class="form-check-input" id="lq13-2" name="q13" value="0"
                                    [(ngModel)]="APIresult[0].questions[12].qAnswer">
                                <label class="form-check-label nolabel" for="lq13-2">No</label>
                            </td>

                        </tr>

                        <tr>
                            <td class="text-left">
                                7b. Have you ever been the subject of any insurance or investment-related, consumer-
                                initiated complaint or proceeding that was settled or decided against you for $1,000 or
                                more, or found fraud or the wrongful taking of property?
                                <textarea [(ngModel)]="APIresult[0].questions[13].qExplanation" class="form-control"
                                    rows="5" placeholder="Please explain your affirmative answer for the above question"
                                    *ngIf="APIresult[0].questions[13].qAnswer=='1'"></textarea>
                            </td>
                            <td style="width: 70px"></td>
                            <td style="width: 70px">
                                <input type="radio" class="form-check-input" id="lq14-1" name="q14" value="1"
                                    [(ngModel)]="APIresult[0].questions[13].qAnswer">
                                <label class="form-check-label nolabel" for="lq14-1">Yes</label>
                            </td>
                            <td style="width: 70px">
                                <input type="radio" class="form-check-input" id="lq14-2" name="q14" value="0"
                                    [(ngModel)]="APIresult[0].questions[13].qAnswer">
                                <label class="form-check-label nolabel" for="lq14-2">No</label>
                            </td>

                        </tr>

                        <tr>
                            <td class="text-left">
                                8. Are you now the subject of any complaint, investigation, or proceeding that could
                                result in a "yes" answer to questions 1 - 7?
                                <textarea [(ngModel)]="APIresult[0].questions[14].qExplanation" class="form-control"
                                    rows="5" placeholder="Please explain your affirmative answer for the above question"
                                    *ngIf="APIresult[0].questions[14].qAnswer=='1'"></textarea>
                            </td>
                            <td style="width: 70px"></td>
                            <td style="width: 70px">
                                <input type="radio" class="form-check-input" id="lq15-1" name="q15" value="1"
                                    [(ngModel)]="APIresult[0].questions[14].qAnswer">
                                <label class="form-check-label nolabel" for="lq15-1">Yes</label>
                            </td>
                            <td style="width: 70px">
                                <input type="radio" class="form-check-input" id="lq15-2" name="q15" value="0"
                                    [(ngModel)]="APIresult[0].questions[14].qAnswer">
                                <label class="form-check-label nolabel" for="lq15-2">No</label>
                            </td>

                        </tr>



                    </table>
                </div>
                <br>
                <button class="btn btn-warning text-white" (click)="changeTab(3)">Continue </button>
            </p-tabPanel>


            <p-tabPanel header="Activate Account">
                <h3>Review & Sign</h3>
                <hr> We consider it a great privilege to partner with you. We believe the marketplace needs solutions
                that can solve immediate needs with products and lifestyle benefits. We look forward to working with you
                and getting to know you.
                <div *ngIf="!doPDF">


                    <br><br>




                    <button class="btn btn-warning text-white" (click)="PrepareAgreement()">Review your Sales
                        Agreement</button>
                </div>
                <div *ngIf="doPDF">
                    <br><br>
                    <h5>Review your Sales Agreement</h5>
                    <hr>
                    <pdf-viewer [src]="pdfSrc" [render-text]="true" style="display: block;"
                        (after-load-complete)="this.showspinner = false"></pdf-viewer>

                    <br><br>


                    <h5>AFEUSA Marketing Agreement</h5>
                    <hr>
                    <ol>
                        <li>
                            All AFEUSA communications to recruit agents/reps to market AFEUSA memberships and
                            subsequently, the other services and insured products offered by AFEUSA must be approved for
                            use in writing by Dylan Consulting, the exclusive marketer for AFEUSA memberships
                            and endorsed products made available upon membership.
                        </li>
                        <li>
                            All AFEUSA communications regarding promotion of the AFEUSA memberships, endorsed services
                            and insurance products with prospective AFEUSA members or existing members must be approved
                            in writing by Dylan Consulting before use; examples are collateral material,
                            websites, all similar communications – visual promotional material.
                        </li>
                        <li>
                            Dylan Consulting will provide approved templates of recruiting communications to engage
                            sales reps to promote AFEUSA memberships and Dylan Consulting will make approved
                            communications available for use with prospective AFEUSA members (or for existing
                            members).
                        </li>
                        <li>
                            If a AFEUSA marketer has special needs for advertising/communication, please submit these
                            drafts to Dylan Consulting; Dylan will be the intermediary to work with AFEUSA, plus any
                            applicable carriers - for review and written approval, prior to use.
                        </li>
                        <li>
                            If you are not currently using approved AFEUSA communications or materials – please
                            immediately discontinue use of any messaging regarding AFEUSA or the services/products
                            offered by AFEUSA.
                        </li>
                        <li>
                            No communications for recruiting AFEUSA sales representatives OR sales materials to
                            prospective AFEUSA members (or existing members) are permitted without written approval from
                            Dylan Consulting.
                        </li>
                    </ol>

                    <strong>
                        How we market all AFEUSA memberships and subsequently the services and insured coverages
                        endorsed by the AFEUSA:
                    </strong>
                    <br>
                    <br> Everything starts with the AFEUSA membership; services and insured products are accessed after
                    becoming a AFEUSA Member. Qualifications of membership:
                    <br><br>
                    <ol>
                        <li>
                            Must be age 18+ and residing in the USA.
                        </li>
                        <li>
                            Member is actively working, or capable of working and must be in good standing to obtain,
                            access or purchase any programs offered by AFEUSA.
                            <br>
                            <ol type="I">
                                <li>
                                    Member may be currently working as an entrepreneur (gig economy) or is considering
                                    doing so.
                                </li>
                                <li>
                                    Member is joining the AFEUSA personally; only the enrolling member may join/enroll
                                    in AFEUSA membership (family members may be included in the enrollee’s membership,
                                    if applicable).
                                </li>
                                <li>
                                    Member can have one AFEUSA membership.
                                </li>
                                <li>
                                    Member can have one of the ‘same’; benefit, product, program, or service provided by
                                    AFEUSA.
                                </li>
                                <li>
                                    Membership does not create any relationship of an employer-employee.
                                </li>
                            </ol>
                        </li>
                        <li>
                            After an enrollee purchases the AFEUSA membership – the member has access to AFEUSA
                            membership benefits; members have the option to purchase available insured products such as
                            Dental coverage, Group Term Life insurance and ancillary health products (i.e.
                            Critical Illness, Accident Medical Expense, Hospital Indemnity).
                        </li>
                    </ol>
                    <br><br>

                    <input type="checkbox" class="form-check-input" id="agree" name="agree" value="y"
                        style="margin-left: 0px;" [(ngModel)]="agreesales">
                    <label class="form-check-label nolabel" for="agree" style="margin-left: 20px;">Check this box if you
                        have read,
                        understand and agree to the Sales Agreement.</label>
                    <br>
                    <input type="checkbox" class="form-check-input" id="agree2" name="agree2" value="y"
                        style="margin-left: 0px;" [(ngModel)]="agreemarketing">
                    <label class="form-check-label nolabel" for="agree2" style="margin-left: 20px;">Check this box if
                        you have read,
                        understand and agree to the Marketing Agreement.</label>
                    <br><br>
                    <h6>With this signature, I certify that all information provided is true, accurate and complete to
                        the best of my knowledge:</h6>
                    <br>
                    <label class="text-danger">Expected Signature: {{APIresult[0].agentinfo.firstname}}
                        {{APIresult[0].agentinfo.lastname}}</label>
                    <input [(ngModel)]="esignature" type="text" maxlength="100" class="form-control"
                        placeholder="Type your name...">
                    <br>
                    <button class="btn btn-warning text-white" (click)="SignAgreement()">Accept Agreement & Activate
                        Account</button>
                </div>
            </p-tabPanel>
        </p-tabView>
        <br><br><br>
    </div>
</div>

<div id="spinner" style="height: 100vh;" *ngIf="showspinner">
    <div class="centered">
        <img src="assets/images/processing.gif" style="width:50px;">
        <br><br>
        <span class="text-white">Processing Enrollment. Please wait...</span>
    </div>
</div>