<h4>Invite Codes</h4>
<hr>
<!--
<button routerLink="hardcodedlist" routerLinkActive="btn-dark text-white" class="btn btn-outline-dark " style="margin-right: 10px;"><i class="fa fa-user"></i>
    Delta Dental Codes</button>
    -->
<button routerLink="customlist" routerLinkActive="btn-dark text-white" class="btn btn-outline-dark " style="margin-right: 10px;" *ngIf="dOnly==0"><i class="fa fa-user"></i>
    List Custom Codes</button>
<button routerLink="customnew" routerLinkActive="btn-dark text-white" class="btn btn-outline-dark " style="margin-right: 10px;" *ngIf="dOnly==0"><i class="fa fa-user"></i>
    New Custom Code</button>
<br><br><br>
<router-outlet></router-outlet>