<!--
    ****************************************
    ****************************************
    ****************************************
    STEP 1 - QUOTE INDICATORS
    ****************************************
    ****************************************
    ****************************************=
-->
<div class="container-fluid" style="background-color: #012A4F; height:250px;" *ngIf="origin=='enrollment'"></div>
<div class="container-fluid" style="height:50px;" *ngIf="origin=='enrollment'"></div>


<div class="container" style="min-height: 100vh;" *ngIf="Step=='1'">
    <h2>Member Application</h2>
    <p>To start your member application, please fill out the form below.</p>
    <hr />
    <div style="
        background-color: #2ab9f9;
        border-radius: 10px;
        padding: 20px;
      ">
        <br>
        <h2 class="text-white">Explore Benefit Opportunities</h2>
        <hr style="
          display: block;
          height: 1px;
          border: 0;
          border-top: 1px solid #ccc;
          margin: 1em 0;
          padding: 0;
        " />
        <br />
        <div class="row">
            <div class="col-6">
                <p class="text-white">First Name</p>
                <input [(ngModel)]="firstname" class="form-control textfield" maxlength="20"
                    style="border-radius: 7px" />
            </div>
            <div class="col-6">
                <p class="text-white">Last Name</p>
                <input [(ngModel)]="lastname" class="form-control textfield" maxlength="20"
                    style="border-radius: 7px" />
            </div>
        </div>

        <div class="row" style="margin-top: 20px">
            <div class="col-6">
                <p class="text-white">Date of Birth</p>
                <input [(ngModel)]="DOB" class="form-control textfield" maxlength="20" style="border-radius: 7px"
                    mask="M0/d0/0000" [dropSpecialCharacters]="false" placeholder="mm/dd/yyyy"
                    [leadZeroDateTime]="true" />
            </div>
            <div class="col-6">
                <p class="text-white">ZIP Code</p>
                <input [(ngModel)]="ZIP" class="form-control textfield" maxlength="5" style="border-radius: 7px"
                    (keypress)="keyPressNumbers($event)" />
            </div>
        </div>

        <div class="row" style="margin-top: 20px">
            <div class="col-6">
                <p class="text-white">Email Address</p>
                <input [(ngModel)]="email" class="form-control textfield" maxlength="70" style="border-radius: 7px" />
            </div>

        </div>
        <br><br>
        <button (click)="checkData()" class="btn btn-primary text-white">
            View Opportunities
        </button>
    </div>
</div>


<!--
    ****************************************
    ****************************************
    ****************************************
    STEP 2 - QUOTE INDICATORS
    ****************************************
    ****************************************
    ****************************************=
-->
<div class="container">
    <div style="min-height: 100vh;" *ngIf="Step=='2'">
        <div class="row">
            <div class="col-8">
                <h2>Select a product below.</h2>
                <p>All products in GREEN indicate that they are already selected and included in your cart.</p>
            </div>
            <div class="col-4 text-right">

            </div>
        </div>

        <hr>

        <div class="row">
            <div class="col-12 col-md-4 col-lg-3" style="border-right: 1px dashed #e5e5e5;">
                <div *ngIf="Quote?.selections.membership.amount >0">
                    <div class="bShadow-5" style="min-height: 100vh;">

                        <button class="btn btn-primary text-white" style="width: 100%; border-radius: 10px;"
                            (click)="DeletePlan('0')"><i class="fa fa-shopping-cart"></i> &nbsp; View Your Cart</button>
                        <!--AFEUSA MEMBERSHIP-->
                        <div class="text-center text-white roundedproducts btn-primary mousepointer" (click)="Plan='1'"
                            [ngClass]="{'roundedproductselected': Quote.selections.membership.amount >  0}">
                            <div>
                                <span class="superfont">AFEUSA Membership</span>
                                <br><span class="text-white">Starting at ${{Quote.membership[0].fee}}</span>
                            </div>
                        </div>
                        <!--DELTA DENTAL-->
                        <div *ngIf="Quote?.dental.length > 0"
                            class="text-center text-white roundedproducts btn-primary mousepointer" (click)="Plan='2'"
                            [ngClass]="{'roundedproductselected': Quote.selections.dental.amount >  0}">
                            <div>
                                <span class="superfont">Delta Dental Insurance</span>
                                <br><span class="text-white">Starting at ${{Quote.dental[0].col1}}</span>
                            </div>
                        </div>
                        <!--STANDARD DENTAL-->
                        <div *ngIf="Quote?.stdental.length > 0"
                            class="text-center text-white roundedproducts btn-primary mousepointer" (click)="Plan='8'"
                            [ngClass]="{'roundedproductselected': Quote.selections.stdental.amount >  0}">
                            <div>
                                <span class="superfont">Standard Dental Insurance</span>
                                <br><span class="text-white">Starting at ${{Quote.stdental[0].col3}}</span>
                            </div>
                        </div>
                        <!--CONCORDIA-->
                        <div *ngIf="Quote?.concordia.length > 0"
                            class="text-center text-white roundedproducts btn-primary mousepointer" (click)="Plan='10'"
                            [ngClass]="{'roundedproductselected': Quote.selections.concordia.amount >  0}">
                            <div>
                                <span class="superfont">United Concordia Dental</span>
                                <br><span class="text-white">Starting at ${{Quote.concordia[0].col1}}</span>

                            </div>
                        </div>
                        <!--AME-->
                        <div *ngIf="Quote?.medical0.length > 0"
                            class="text-center text-white roundedproducts btn-primary mousepointer" (click)="Plan='3'"
                            [ngClass]="{'roundedproductselected': Quote.selections.medical.amount >  0}">
                            <div>
                                <span class="superfont">Accident Medical</span>
                                <br><span class="text-white">Starting at ${{Quote.medical0[0].col1}}</span>
                            </div>
                        </div>
                        <!--SICKNESS-->
                        <div *ngIf="Quote?.hospital.length > 0"
                            class="text-center text-white roundedproducts btn-primary mousepointer" (click)="Plan='4'"
                            [ngClass]="{'roundedproductselected': Quote.selections.hospital.amount >  0}">
                            <div>
                                <span class="superfont">Accident and Sickness</span>
                                <br><span class="text-white">Starting at ${{Quote.hospital[0].col1}}</span>
                            </div>
                        </div>
                        <!--SICKNESS 65-->
                        <div *ngIf="Quote?.hospital65.length > 0"
                            class="text-center text-white roundedproducts btn-primary mousepointer" (click)="Plan='11'"
                            [ngClass]="{'roundedproductselected': Quote.selections.hospital65.amount >  0}">
                            <div>
                                <span class="superfont">Group Hospital Indemnity</span>
                                <br><span class="text-white">Starting at ${{Quote.hospital65[0].col1}}</span>
                            </div>
                        </div>
                        <!--critical-->
                        <div *ngIf="Quote?.critical.length > 0"
                            class="text-center text-white roundedproducts btn-primary mousepointer" (click)="Plan='5'"
                            [ngClass]="{'roundedproductselected': Quote.selections.critical.amount >  0}">
                            <div>
                                <span class="superfont">Critical Illness</span>
                                <br><span class="text-white">Starting at ${{Quote.critical[0].col1}}</span>
                            </div>
                        </div>
                        <!--critical65-->
                        <div *ngIf="Quote?.critical65.length > 0"
                            class="text-center text-white roundedproducts btn-primary mousepointer" (click)="Plan='12'"
                            [ngClass]="{'roundedproductselected': Quote.selections.critical65.amount >  0}">
                            <div>
                                <span class="superfont">Critical Illness</span>
                                <br><span class="text-white">Starting at ${{Quote.critical65[0].col1}}</span>
                            </div>
                        </div>
                        <!--Life Insurance-->
                        <div *ngIf="Quote?.life.hasOwnProperty('plan25premium')"
                            class="text-center text-white roundedproducts btn-primary mousepointer" (click)="Plan='6'"
                            [ngClass]="{'roundedproductselected': Quote.selections.life.amount >  0}">
                            <div>
                                <span class="superfont">Life Insurance</span>
                                <br><span class="text-white">Starting at ${{Quote.life.plan25premium}}</span>
                            </div>
                        </div>
                        <!--Hooray-->
                        <div *ngIf="Quote?.hooray.length > 0"
                            class="text-center text-white roundedproducts btn-primary mousepointer" (click)="Plan='9'"
                            [ngClass]="{'roundedproductselected': Quote.selections.hooray.amount >  0}">
                            <div>
                                <span class="superfont">$25 Urgent Care and Accident</span>
                                <br><span class="text-white">Starting at ${{Quote.hooray[0].col1}}</span>
                            </div>
                        </div>

                    </div>
                </div>
                <div *ngIf="Quote?.selections.membership.amount ==0">
                    <div class="alert alert-info">
                        <br>
                        <h5>Attention</h5>
                        <hr>
                        You must be a member of AFEUSA to gain exclusive access to insurance products and pricing.
                        Please
                        select a membership plan to get your free quote. You will need to purchase a membership plan
                        with
                        AFEUSA association to enroll with any of the insurance products. A membership gives you access
                        to
                        these incredible benefits.
                        <br><br>
                    </div>
                </div>

            </div>
            <div class="col-12 col-md-8 col-lg-9">
                <div class="bShadow-5" style="min-height: 100vh;">
                    <div *ngIf="Plan=='0'">
                        <br>
                        <div class="roundedtitle">
                            <h4>Shopping Cart</h4>
                        </div>
                        <hr>
                        <div *ngIf="Quote?.selections.membership.amount > 0">
                            <div class="row text-muted">
                                <div class="col-8">
                                    <button class="btn btn-sm btn-success" (click)="Plan='1'"><i
                                            class="fa fa-edit"></i></button>&nbsp;&nbsp;
                                    {{Quote.selections.membership.name}}
                                </div>
                                <div class="col-4 text-right">
                                    ${{Quote.selections.membership.amount  | number:'1.2-2'}}
                                </div>
                            </div>
                            <hr class="greyhr">
                        </div>

                        <div *ngIf="Quote?.selections.dental.amount > 0">
                            <div class="row text-muted">
                                <div class="col-8">
                                    <button class="btn btn-sm btn-danger" (click)="DeletePlan('2')"><i
                                            class="fa fa-trash"></i></button>&nbsp;&nbsp;
                                    <button class="btn btn-sm btn-success" (click)="Plan='2'"><i
                                            class="fa fa-edit"></i></button>&nbsp;&nbsp;
                                    {{Quote.selections.dental.name}}
                                </div>
                                <div class="col-4 text-right">
                                    ${{Quote.selections.dental.amount  | number:'1.2-2'}}
                                </div>
                            </div>
                            <hr class="greyhr">
                        </div>

                        <div *ngIf="Quote?.selections.stdental.amount > 0">
                            <div class="row text-muted">
                                <div class="col-8">
                                    <button class="btn btn-sm btn-danger" (click)="DeletePlan('8')"><i
                                            class="fa fa-trash"></i></button>&nbsp;&nbsp;
                                    <button class="btn btn-sm btn-success" (click)="Plan='8'"><i
                                            class="fa fa-edit"></i></button>&nbsp;&nbsp;
                                    {{Quote.selections.stdental.name}}
                                </div>
                                <div class="col-4 text-right">
                                    ${{Quote.selections.stdental.amount  | number:'1.2-2'}}
                                </div>
                            </div>
                            <hr class="greyhr">
                        </div>

                        <div *ngIf="Quote?.selections.medical.amount > 0">
                            <div class="row text-muted">
                                <div class="col-8">
                                    <button class="btn btn-sm btn-danger" (click)="DeletePlan('3')"><i
                                            class="fa fa-trash"></i></button>&nbsp;&nbsp;
                                    <button class="btn btn-sm btn-success" (click)="Plan='3'"><i
                                            class="fa fa-edit"></i></button>&nbsp;&nbsp;
                                    {{Quote.selections.medical.name}}
                                </div>
                                <div class="col-4 text-right">
                                    ${{Quote.selections.medical.amount  | number:'1.2-2'}}
                                </div>
                            </div>
                            <hr class="greyhr">
                        </div>

                        <div *ngIf="Quote?.selections.hospital.amount > 0">
                            <div class="row text-muted">
                                <div class="col-8">
                                    <button class="btn btn-sm btn-danger" (click)="DeletePlan('4')"><i
                                            class="fa fa-trash"></i></button>&nbsp;&nbsp;
                                    <button class="btn btn-sm btn-success" (click)="Plan='4'"><i
                                            class="fa fa-edit"></i></button>&nbsp;&nbsp;
                                    {{Quote.selections.hospital.name}}
                                </div>
                                <div class="col-4 text-right">
                                    ${{Quote.selections.hospital.amount  | number:'1.2-2'}}
                                </div>
                            </div>
                            <hr class="greyhr">
                        </div>

                        <div *ngIf="Quote?.selections.hospital65.amount > 0">
                            <div class="row text-muted">
                                <div class="col-8">
                                    <button class="btn btn-sm btn-danger" (click)="DeletePlan('11')"><i
                                            class="fa fa-trash"></i></button>&nbsp;&nbsp;
                                    <button class="btn btn-sm btn-success" (click)="Plan='11'"><i
                                            class="fa fa-edit"></i></button>&nbsp;&nbsp;
                                    {{Quote.selections.hospital65.name}}
                                </div>
                                <div class="col-4 text-right">
                                    ${{Quote.selections.hospital65.amount  | number:'1.2-2'}}
                                </div>
                            </div>
                            <hr class="greyhr">
                        </div>

                        <div *ngIf="Quote?.selections.critical.amount > 0">
                            <div class="row text-muted">
                                <div class="col-8">
                                    <button class="btn btn-sm btn-danger" (click)="DeletePlan('5')"><i
                                            class="fa fa-trash"></i></button>&nbsp;&nbsp;
                                    <button class="btn btn-sm btn-success" (click)="Plan='5'"><i
                                            class="fa fa-edit"></i></button>&nbsp;&nbsp;
                                    {{Quote.selections.critical.name}}
                                </div>
                                <div class="col-4 text-right">
                                    ${{Quote.selections.critical.amount  | number:'1.2-2'}}
                                </div>
                            </div>
                            <hr class="greyhr">
                        </div>

                        <div *ngIf="Quote?.selections.critical65.amount > 0">
                            <div class="row text-muted">
                                <div class="col-8">
                                    <button class="btn btn-sm btn-danger" (click)="DeletePlan('12')"><i
                                            class="fa fa-trash"></i></button>&nbsp;&nbsp;
                                    <button class="btn btn-sm btn-success" (click)="Plan='12'"><i
                                            class="fa fa-edit"></i></button>&nbsp;&nbsp;
                                    {{Quote.selections.critical65.name}}
                                </div>
                                <div class="col-4 text-right">
                                    ${{Quote.selections.critical65.amount  | number:'1.2-2'}}
                                </div>
                            </div>
                            <hr class="greyhr">
                        </div>

                        <div *ngIf="Quote?.selections.life.amount > 0">
                            <div class="row text-muted">
                                <div class="col-8">
                                    <button class="btn btn-sm btn-danger" (click)="DeletePlan('6')"><i
                                            class="fa fa-trash"></i></button>&nbsp;&nbsp;
                                    <button class="btn btn-sm btn-success" (click)="Plan='6'"><i
                                            class="fa fa-edit"></i></button>&nbsp;&nbsp;
                                    {{Quote.selections.life.name}}
                                </div>
                                <div class="col-4 text-right">
                                    ${{Quote.selections.life.amount  | number:'1.2-2'}}
                                </div>
                            </div>
                            <hr class="greyhr">
                        </div>

                        <div *ngIf="Quote?.selections.concordia.amount > 0">
                            <div class="row text-muted">
                                <div class="col-8">
                                    <button class="btn btn-sm btn-danger" (click)="DeletePlan('10')"><i
                                            class="fa fa-trash"></i></button>&nbsp;&nbsp;
                                    <button class="btn btn-sm btn-success" (click)="Plan='10'"><i
                                            class="fa fa-edit"></i></button>&nbsp;&nbsp;
                                    {{Quote.selections.concordia.name}}
                                </div>
                                <div class="col-4 text-right">
                                    ${{Quote.selections.concordia.amount  | number:'1.2-2'}}
                                </div>
                            </div>
                            <hr class="greyhr">
                        </div>


                        <div *ngIf="Quote?.selections.hooray.amount > 0">
                            <div class="row text-muted">
                                <div class="col-8">
                                    <button class="btn btn-sm btn-danger" (click)="DeletePlan('9')"><i
                                            class="fa fa-trash"></i></button>&nbsp;&nbsp;
                                    <button class="btn btn-sm btn-success" (click)="Plan='9'"><i
                                            class="fa fa-edit"></i></button>&nbsp;&nbsp;
                                    {{Quote.selections.hooray.name}}
                                </div>
                                <div class="col-4 text-right">
                                    ${{Quote.selections.hooray.amount  | number:'1.2-2'}}
                                </div>
                            </div>
                            <hr class="greyhr">
                        </div>

                        <div *ngIf="TotalToDisplay > 0">
                            <div class="row">
                                <div class="col-8">
                                    <h6>TOTAL MONTHLY:</h6>
                                </div>
                                <div class="col-4 text-right">
                                    <h6>${{TotalToDisplay | number:'1.2-2'}}</h6>
                                </div>
                            </div>
                            <hr class="greyhr">
                        </div>
                        <br>
                        <div *ngIf="TotalToDisplay==Quote.selections.membership.amount">
                            <table>
                                <tr>
                                    <td style="width: 10px">
                                        <input type="checkbox" [(ngModel)]="onlyMembership" binary="true"
                                            inputId="binary" />
                                    </td>
                                    <td><strong>
                                            &nbsp; Check this if you only want to sign up for the membership, without
                                            any insurance product.
                                        </strong>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <br>
                        <button class="btn btn-primary" (click)="DoStep3()" *ngIf="TotalProducts">Continue</button>
                    </div>
                    <app-p1 [Data]="Quote" (Calculate)="Calculate()" *ngIf="Plan=='1'"></app-p1>
                    <app-p2 [Data]="Quote" (Calculate)="Calculate()" *ngIf="Plan=='2'"></app-p2>
                    <app-p3 [Data]="Quote" (Calculate)="Calculate()" *ngIf="Plan=='3'"></app-p3>
                    <app-p4 [Data]="Quote" (Calculate)="Calculate()" *ngIf="Plan=='4'"></app-p4>
                    <app-p5 [Data]="Quote" (Calculate)="Calculate()" *ngIf="Plan=='5'"></app-p5>
                    <app-p6 [Data]="Quote" (Calculate)="Calculate()" *ngIf="Plan=='6'"></app-p6>
                    <app-p8 [Data]="Quote" (Calculate)="Calculate()" *ngIf="Plan=='8'"></app-p8>
                    <app-p9 [Data]="Quote" (Calculate)="Calculate()" *ngIf="Plan=='9'"></app-p9>
                    <app-p10 [Data]="Quote" (Calculate)="Calculate()" *ngIf="Plan=='10'"></app-p10>
                    <app-p11 [Data]="Quote" (Calculate)="Calculate()" *ngIf="Plan=='11'"></app-p11>
                    <app-p12 [Data]="Quote" (Calculate)="Calculate()" *ngIf="Plan=='12'"></app-p12>

                </div>
            </div>
        </div>


    </div>
</div>
<!--
    ****************************************
    ****************************************
    ****************************************
    STEP 3 - CHUBB DISCLOSURES IF CHUBB PRODUCTS
    ****************************************
    ****************************************
    ****************************************=
-->
<div *ngIf="ShowChubbDisclosures && Step=='3'">
    <div class="container">
        <p style="margin-top: 40px">
            Benefits and plans are offered by Dylan Consulting, LLC, a licensed independent
            insurance agency, and any of its subagents, and underwritten by licensed
            insurance companies. Not all products are
            available in all states.
        </p>
        <div>
            <br />
            <h2>Confirm Eligibility</h2>
            <hr />
            <h4 style="margin-top: 30px">
                The following Terms and Conditions only apply to the insurance products
                underwritten by <span *ngIf="addToChubbContract=='ACE'">ACE American Insurance Company and </span>
                Federal Insurance Company.
            </h4>
            <br />

            <table class="table table-responsive">
                <tr>
                    <td style="width: 50px">
                        <input type="checkbox" [(ngModel)]="chk1" binary="true" inputId="binary">
                    </td>
                    <td>
                        <span *ngIf="addToChubbContract=='ACE'">
                            The Group Critical Illness Insurance and Group Hospital
                            Indemnity Insurance benefits are underwritten by Federal Insurance Company and ACE American
                            Insurance Company, member insurers of the Chubb Group of Companies. Coverage may not be
                            available in all states or certain terms may be different where required by state law. Chubb
                            NA is the U.S.-based operating division of the Chubb Group of Companies, headed by Chubb
                            Ltd. (NYSE:CB) Insurance products and services are provided by Chubb insurance underwriting
                            companies and not by the parent company itself.</span>
                        <span *ngIf="addToChubbContract!='ACE'">
                            The Accidental Death and Dismemberment, Accident Medical Expense,
                            Critical Illness, Accident and Sickness Hospital Indemnity benefits
                            are underwritten by Federal Insurance Company, a Chubb Company.
                            Coverage may not be available in all states or certain terms may be
                            different where required by state law. Chubb NA is the U.S.-based
                            operating division of the Chubb Group of Companies, headed by Chubb
                            Ltd. (NYSE:CB) Insurance products and services are provided by Chubb
                            insurance underwriting companies and not by the parent company
                            itself.
                        </span>

                    </td>
                </tr>
            </table>

            <table class="table table-responsive" *ngIf="chk10text">
                <tr>
                    <td style="width: 50px">

                    </td>
                    <td>
                        <div [innerHTML]="chk10text"></div>
                        <br> <br>
                        <input type="checkbox" [(ngModel)]="chk10" binary="true" inputId="binary" label="Yes"
                            (change)="CAchangeY()">
                        &nbsp;&nbsp;
                        <input type="checkbox" [(ngModel)]="chk10no" binary="true" inputId="binary" label="No"
                            (change)="CAchangeN()">


                    </td>
                </tr>
            </table>

            <table class="table table-responsive">
                <tr>
                    <td style="width: 50px">
                        <input type="checkbox" [(ngModel)]="chk2" binary="true" inputId="binary">
                    </td>
                    <td>
                        <strong>Trade Sanctions Disclosure</strong><br />
                        I understand that once I have completed the enrollment process, it
                        will be subject to underwriting verification by the Insurance
                        Company. This offer is not binding to the extent that United States
                        trade or economic sanctions or other laws or regulations prohibit
                        <span *ngIf="addToChubbContract=='ACE'"> ACE American Insurance Company and </span>
                        Federal Insurance Company from offering or providing insurance. To
                        the extent any such prohibitions apply, this offer is void ab
                        initio.
                    </td>
                </tr>
            </table>

            <table class="table table-responsive">
                <tr>
                    <td style="width: 50px">
                        <input type="checkbox" [(ngModel)]="chk3" binary="true" inputId="binary">
                    </td>
                    <td>
                        <strong>Electronic Delivery Disclosure</strong><br />
                        I agree to the electronic insurance transaction and delivery of my
                        policy(ies) and related documents electronically as set forth in the
                        Consent to Electronic Delivery, Disclosures, and Signature. To
                        request a paper copy of any document that was originally provided to
                        you, at no charge, or to withdraw, please call (866) 532-2899.
                    </td>
                </tr>
            </table>

            <table class="table table-responsive" style="width: 100% !important; display: table">
                <tr>
                    <td style="width: 50px">
                        <input type="checkbox" [(ngModel)]="chk11" binary="true" inputId="binary"
                            (change)="ViewDoc($event)">

                    </td>
                    <td>
                        <strong>Check the box to view and agree to the Consent to Electronic
                            Delivery, Disclosures, and Signature</strong>
                    </td>
                </tr>
            </table>

            <table class="table table-responsive">
                <tr>
                    <td style="width: 50px">
                        <input type="checkbox" [(ngModel)]="chk4" binary="true" inputId="binary">
                    </td>
                    <td>
                        <strong>Payment Disclosure</strong><br />
                        I understand that if I have any questions prior to receiving my
                        membership package I can call 90Degree Benefits (866) 532-2899.
                        <br /><br />
                        I understand that I am joining the AFEUSA Membership for the level
                        listed above and that I have elected the insurance plans listed. The
                        first month’s membership dues and premiums for the insurance
                        benefits elected will be billed today and I agree to be billed
                        monthly for future monthly membership dues and insurance premiums
                        elected. I understand that I may receive a full refund of membership
                        dues and premiums for the insurance benefits elected, provided
                        membership is canceled within the first 30 days and no claims were
                        incurred. I may cancel my membership by contacting 90Degree Benefits
                        at (866) 532-2899. I understand that Membership dues and insurance
                        premiums are to be paid when due. If payment is not received, I will
                        have 31 days from the date due to pay the amount due; or my
                        membership and any benefits purchased will terminate without notice.
                        <br /><br />
                        I authorize AFEUSA to charge the credit card or bank account I
                        provide for the monthly cost. This authorization will remain in
                        effect until otherwise notified, in writing, by me. Monthly
                        transactions will appear as "AFEUSA" on my bank or credit card
                        account. The first month’s cost will be billed today, and I agree to
                        be automatically billed each month for the future monthly cost. I
                        have read and understand the rates, one of which applies to my
                        enrollment based on my enrollment and any additional spouse and/or
                        dependent enrollment as noted in the rates.
                    </td>
                </tr>
            </table>

            <table class="table table-responsive">
                <tr>
                    <td style="width: 50px">
                        <input type="checkbox" [(ngModel)]="chk5" binary="true" inputId="binary">
                    </td>
                    <td>
                        <strong>Fraud Warning</strong><br />
                        WARNING: Any person who, knowingly and with intent to defraud any
                        insurance company or other person, files an application for
                        insurance containing any false information, or conceals for the
                        purpose of misleading, information concerning any material fact
                        thereto, commits a fraudulent insurance act, which is a crime.
                        Penalties include imprisonment and/or fines. In addition, an insurer
                        may deny insurance benefits if false information materially related
                        to a claim was provided by the enrollee or Insured Person. PLEASE
                        CLICK
                        <a (click)="overlapOpen = true" class="text-primary mousepointer">PLAN DOCUMENTS</a>
                        TO VIEW STATE-SPECIFIC FRAUD WARNINGS.
                    </td>
                </tr>
            </table>

            <table class="table table-responsive" style="width: 100% !important; display: table">
                <tr>
                    <td style="width: 50px">
                        <input type="checkbox" [(ngModel)]="chk6" binary="true" inputId="binary">
                    </td>
                    <td>
                        <strong>Chubb Privacy</strong><br />
                        You can view
                        <a class="text-primary" href="https://www.chubb.com/us-en/online-privacy-policy.html "
                            target="_blank">Chubb’s Online Privacy statement</a> here,
                        <a class="text-primary"
                            href="https://www.chubb.com/us-en/online-privacy-policy.html#CCPA-section"
                            target="_blank">Chubb’s California Resident Privacy Policy</a> here
                        and our
                        <a class="text-primary"
                            href="https://www.chubb.com/content/dam/chubb-sites/chubb-com/us-en/business-insurance/accident-health/documents/pdf/chubb-hipaa-privacy-notice-final-6-15-18.pdf"
                            target="_blank">HIPAA notice</a> here.
                    </td>
                </tr>
            </table>

            <table class="table table-responsive">
                <tr>
                    <td style="width: 50px">
                        <input type="checkbox" [(ngModel)]="chk7" binary="true" inputId="binary">
                    </td>
                    <td>
                        <strong>Pre-Existing Conditions</strong><br />

                        For <span *ngIf="addToChubbContract=='ACE'">Group</span> Critical Illness: I acknowledge that
                        the policy contains a
                        Pre-Existing Condition Exclusion and that no Benefit Amount will be
                        paid under this policy for a Covered Condition caused by or
                        resulting directly from a Pre-Existing Condition. This Pre-Existing
                        Condition exclusion only applies for twelve (12) months, beginning
                        with the effective date of the Insured Person’s insurance. However,
                        in the event of any increase in Benefit Amount, the twelve (12)
                        month period will begin anew with respect to the amount of such
                        increase. Pre-Existing Condition means any illness, disease, injury,
                        mental illness or condition for which medical advice, Diagnosis,
                        care or treatment: 1) was received by an Insured Person; 2) was
                        recommended to an Insured Person; or 3) would have been sought by a
                        reasonably prudent person, during the six (6) month period before
                        becoming insured under this policy. In the case of an increase in an
                        Insured Person’s Benefit Amount, the Pre-Existing Condition applies:
                        1) only to the amount of the increase, and 2) during the six (6)
                        month period immediately prior to the increase. <br /><br />


                        <span *ngIf="addToChubbContract=='ACE'">For Group Hospital Indemnity: </span>
                        <span *ngIf="addToChubbContract!='ACE'">For Accident and Sickness Hospital Indemnity: </span>
                        I acknowledge that the
                        policy(ies) contains a Pre-Existing Condition Exclusion and the
                        Insurance Company will not pay any benefits for Sickness caused by
                        or resulting from a Covered Person’s Pre-existing Condition if the
                        Sickness occurs during the first 12 months that a Covered Person is
                        insured under this policy. A Pre-existing Condition means an
                        accident or a sickness for which, in the six months before the
                        Covered Person becomes insured under the policy, medical advice,
                        treatment or care was sought by a Covered Person, or was recommended
                        by, prescribed by or received from a Physician.
                    </td>
                </tr>
            </table>

            <table class="table table-responsive">
                <tr>
                    <td style="width: 50px">
                        <input type="checkbox" [(ngModel)]="chk8" binary="true" inputId="binary">
                    </td>
                    <td>
                        <strong>Non Insurance Disclosures</strong><br />
                        I understand that I have selected a membership that includes many
                        benefits and services which are not insurance and are not
                        underwritten by nor affiliated with <span *ngIf="addToChubbContract=='ACE'">ACE American
                            Insurance Company and </span> Federal Insurance Company.
                    </td>
                </tr>
            </table>

            <table class="table table-responsive">
                <tr>
                    <td style="width: 50px">
                        <input type="checkbox" [(ngModel)]="chk9" binary="true" inputId="binary">
                    </td>
                    <td>
                        <strong>I have read, understand and agree to the Terms and Conditions in
                            the
                            <a (click)="overlapOpen = true" class="text-primary mousepointer">Link Here</a>, including the limitations and exclusions for the insurance
                            policies I have selected. I also understand that the policy(ies) I
                            selected do not constitute comprehensive health insurance coverage
                            (often referred to as “major medical coverage”) and do not satisfy
                            a person’s individual obligation to secure the requirement of
                            minimum essential coverage under the Affordable Care Act (ACA).
                            For more information about the ACA, please refer to
                            <a href="http://www.HealthCare.gov" class="text-primary"
                                target="_blank">http://www.HealthCare.gov</a></strong>.<br />
                    </td>
                </tr>
            </table>
            <table class="table table-responsive" style="width: 100% !important; display: table">
                <tr>
                    <td>
                        <strong>By checking all the boxes above, I confirm the following:</strong>
                        <br /><br />
                        <ul>
                            <li>The information I provide is accurate.</li>
                            <li>
                                I understand the details of the AFEUSA membership including the
                                membership dues.
                            </li>
                            <li>I have read and agree to the Non-Insurance Disclosures.</li>
                            <li>
                                I understand the terms and conditions of the insurance plans
                                elected, including limitations and exclusions.
                            </li>
                            <li>I have read and agree to the TERMS AND CONDITIONS above.</li>
                            <li>I have read and agree to the Fraud Warning Notice.</li>
                        </ul>
                    </td>
                </tr>
            </table>

            <br>
            <button class="btn btn-primary" (click)="GoQuoteInfo()">Continue Enrollment</button>


            <br />

            <br /><br /><br /><br />
        </div>
    </div>
</div>


<div id="overlay" *ngIf="overlapOpen">
    <div id="overlay2">
        <div>
            <div class="row">
                <div class="col-md-10 text-left"></div>
                <div class="col-md-2 text-right">
                    <button class="btn btn-danger btn-sm" (click)="overlapOpen = false">
                        <i class="far fa-window-close"></i> Close
                    </button>
                </div>
            </div>
        </div>
        <div class="stuck">
            <h2>Plan Documents</h2>
            <hr />
            <br />
            <div>
                <div class="row">
                    <div class="col-md-8">AFEUSA Terms & Conditions</div>
                    <div class="col-md-4 text-right">
                        <a class="btn btn-secondary btn-sm text-white" (click)="showTOS=true"><i
                                class="fas fa-open"></i>
                            View</a>
                    </div>
                </div>
                <hr />
            </div>
            <div>
                <div class="row">
                    <div class="col-md-8">Privacy Policy</div>
                    <div class="col-md-4 text-right">
                        <a class="btn btn-secondary btn-sm text-white" (click)="showPP=true"><i class="fas fa-open"></i>
                            View</a>
                    </div>
                </div>
                <hr />
            </div>
            <div *ngIf="Quote?.selections.medical.selection != '0'">
                <div class="row">
                    <div class="col-md-8">
                        AFEUSA Accident Plan {{ Quote.selections.medical.col }}
                        {{ Quote.selections.medical.deductible }} Deductible {{ state }} DOC
                    </div>
                    <div class="col-md-4 text-right">
                        <a class="btn btn-secondary btn-sm" target="_blank"
                            href="assets/pdf/{{ Quote.selections.medical.col }}{{ state }}.pdf"><i
                                class="fas fa-file-pdf"></i>
                            Download PDF</a>
                    </div>
                </div>
                <hr />
            </div>
            <div *ngIf="Quote?.selections.hospital.selection != '0'">
                <div class="row">
                    <div class="col-md-8">
                        AFEUSA Accident & Sickness Hospital Indemnity Plan
                        {{ Quote.selections.medical.col }} {{ state }} DOC
                    </div>
                    <div class="col-md-4 text-right">
                        <a class="btn btn-secondary btn-sm" target="_blank"
                            href="assets/pdf/aship{{ Quote.selections.hospital.col }}{{ state }}.pdf"><i
                                class="fas fa-file-pdf"></i> Download PDF</a>
                    </div>
                </div>
                <hr />
            </div>
            <div *ngIf="Quote?.selections.critical.selection != '0'">
                <div class="row">
                    <div class="col-md-8">
                        AFE Critical Illness Plan {{ Quote.selections.critical.col }} DOC
                    </div>
                    <div class="col-md-4 text-right">
                        <a class="btn btn-secondary btn-sm" target="_blank"
                            href="assets/pdf/critical{{ Quote.selections.critical.col }}.pdf"><i
                                class="fas fa-file-pdf"></i> Download
                            PDF</a>
                    </div>
                </div>
                <hr />
            </div>
            <div>
                <div class="row">
                    <div class="col-md-8">Consent to E-Delivery</div>
                    <div class="col-md-4 text-right">
                        <a class="btn btn-secondary btn-sm" target="_blank" href="assets/pdf/Consent-e-delivery.pdf"><i
                                class="fas fa-file-pdf"></i> Download PDF</a>
                    </div>
                </div>
                <hr />
            </div>
            <div
                *ngIf="Quote?.selections.hospital65.selection != '0' || Quote?.selections.critical65.selection != '0' || Quote?.selections.critical.selection != '0' || Quote.selections.hospital.selection != '0' || Quote.selections.medical.selection != '0'">
                <div class="row">
                    <div class="col-md-8">FRAUD WARNING NOTICES AME-CI and ASHIP</div>
                    <div class="col-md-4 text-right">
                        <a class="btn btn-secondary btn-sm" target="_blank"
                            href="assets/pdf/FRAUD-WARNING-NOTICES-AME-CI-and-ASHIP.pdf"><i class="fas fa-file-pdf"></i>
                            Download PDF</a>
                    </div>
                </div>
                <hr />
            </div>
            <div *ngIf="Quote?.selections.hospital65.selection == '0' && Quote?.selections.critical65.selection == '0'">
                <div class="row">
                    <div class="col-md-8">AFE_Brochure_Basic.pdf</div>
                    <div class="col-md-4 text-right">
                        <a class="btn btn-secondary btn-sm" target="_blank" href="assets/pdf/{{ Brochure }}"><i
                                class="fas fa-file-pdf"></i> Download PDF</a>
                    </div>
                </div>
                <hr />
            </div>
            <div *ngIf="Quote?.selections.hospital65.selection != '0' || Quote?.selections.critical65.selection != '0'">
                <div class="row">
                    <div class="col-md-8">Chubb Senior</div>
                    <div class="col-md-4 text-right">
                        <a class="btn btn-secondary btn-sm" target="_blank" href="assets/pdf/Chubb-AFEUSA-senior.pdf"><i
                                class="fas fa-file-pdf"></i> Download PDF</a>
                    </div>
                </div>
                <hr />
            </div>
        </div>
    </div>
</div>



<!--
    ****************************************
    ****************************************
    ****************************************
    STEP 4 - ACCOUNT INFORMATION
    ****************************************
    ****************************************
    ****************************************=
-->

<div *ngIf="Step=='4'">
    <div class="container">
        <div class="about-content">
            <div>
                <br>
                <div *ngIf="!IsProcessing">
                    <div class="row">
                        <div class="col-6">
                            <h2>Member Application</h2>
                        </div>
                        <div class="col-6 text-right">
                            <button class="btn btn-primary" (click)="Step='2'" style="margin-right: 10px;">Change
                                Products Selection</button>


                            <button class="btn btn-warning text-white" (click)="SaveForLater()">Save & Continue
                                Later</button>

                        </div>
                    </div>

                    <hr>
                    <h5>About You</h5>
                    <hr>


                    <div class="row">
                        <div class="col-md-3">
                            <label>First Name <sup class="text-danger">(*)</sup></label><br>
                            <input type="text" maxlength=30 class="form-control"
                                [(ngModel)]="Quote?.EmpDemographics.FirstName">
                        </div>
                        <div class="col-md-3">
                            <label>Middle Name</label><br>
                            <input type="text" maxlength=30 class="form-control"
                                [(ngModel)]="Quote?.EmpDemographics.MiddleName">
                        </div>
                        <div class="col-md-3">
                            <label>Last Name <sup class="text-danger">(*)</sup></label><br>
                            <input type="text" maxlength=30 class="form-control"
                                [(ngModel)]="Quote?.EmpDemographics.LastName">
                        </div>
                        <div class="col-md-3">
                            <label>Date of Birth <sup class="text-danger">(*)</sup></label><br>
                            <input type="text" maxlength=30 class="form-control" disabled
                                [(ngModel)]="Quote?.EmpDemographics.BirthDate" placeholder="mm/dd/yyyy">
                        </div>

                        <div class="col-md-6">
                            <label>Address <sup class="text-danger">(*)</sup></label><br>
                            <input type="text" maxlength=30 class="form-control"
                                [(ngModel)]="Quote?.EmpDemographics.Address1">
                        </div>
                        <div class="col-md-6">
                            <label>Address 2</label><br>
                            <input type="text" maxlength=30 class="form-control"
                                [(ngModel)]="Quote?.EmpDemographics.Address2">
                        </div>
                        <div class="col-md-3">
                            <label>City <sup class="text-danger">(*)</sup></label><br>
                            <input type="text" maxlength=30 class="form-control"
                                [(ngModel)]="Quote?.EmpDemographics.City">
                        </div>
                        <div class="col-md-3">
                            <label>State <sup class="text-danger">(*)</sup></label><br>
                            <input type="text" maxlength=30 class="form-control" disabled
                                [(ngModel)]="Quote?.EmpDemographics.State_Name" disabled>
                        </div>
                        <div class="col-md-3">
                            <label>ZIP Code <sup class="text-danger">(*)</sup></label><br>
                            <input type="text" maxlength=5 class="form-control" [(ngModel)]="Quote?.EmpDemographics.Zip"
                                disabled>
                        </div>
                        <div class="col-md-3">
                            <label>Phone Number <sup class="text-danger">(*)</sup></label><br>
                            <input type="text" maxlength=30 class="form-control"
                                [(ngModel)]="Quote?.EmpDemographics.Phone" mask="(000) 000-0000"
                                [dropSpecialCharacters]="true">
                        </div>


                        <div class="col-md-3">
                            <label>Gender <sup class="text-danger">(*)</sup></label><br>
                            <select class="form-control" [(ngModel)]="Quote?.EmpDemographics.Gender">
                                <option value=""></option>
                                <option value="M">Male</option>
                                <option value="F">Female</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label>Social Security Number <sup class="text-danger">(*)</sup></label><br>
                            <input type="text" maxlength=30 class="form-control"
                                [(ngModel)]="Quote?.EmpDemographics.ESSN" mask="000-00-0000"
                                [dropSpecialCharacters]="true">
                        </div>
                        <div class="col-md-3">
                            <label>Marital Status <sup class="text-danger">(*)</sup></label><br>
                            <select class="form-control" [(ngModel)]="Quote?.EmpDemographics.MaritalStatus">
                                <option value=""></option>
                                <option value="S">Single</option>
                                <option value="M">Married</option>
                                <option value="D">Divorced</option>
                                <option value="W">Widowed</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label>Email Address <sup class="text-danger">(*)</sup></label><br>
                            <input type="text" maxlength=70 class="form-control"
                                [(ngModel)]="Quote?.EmpDemographics.Email">
                        </div>

                        <div class="col-md-3" *ngIf="origin=='backoffice'">
                            <label>Member's Mother's Maiden Name <sup class="text-danger">(*)</sup></label><br>
                            <input type="text" maxlength=30 class="form-control"
                                [(ngModel)]="Quote?.EmpDemographics.MothersMaidenName">
                        </div>

                    </div>
                    <br>
                    <h5>Create your member portal password so you can log in and access your benefits once you have
                        finished enrollment.</h5>
                    <hr>
                    <div class="row" *ngIf="origin=='backoffice'">
                        <div class="col-md-4">
                            <label>Login Email <sup class="text-danger">(*)</sup></label><br>
                            <input type="text" maxlength="70" class="form-control"
                                [(ngModel)]="this.Quote.EmpDemographics.Email" disabled>
                        </div>
                        <div class="col-md-4">
                            <label>Password (at least 8 characters long) <sup class="text-danger">(*)</sup></label><br>
                            <input type="text" maxlength=30 class="form-control"
                                [(ngModel)]="Quote?.RegistrationDetails.Password">
                        </div>
                        <div class="col-md-4">
                            <label>Confirm your password <sup class="text-danger">(*)</sup></label><br>
                            <input type="text" maxlength=30 class="form-control" [(ngModel)]="upassword2">
                        </div>
                    </div>

                    <div class="row" *ngIf="origin!='backoffice'">
                        <div class="col-md-4">
                            <label>Login Email</label><br>
                            <input type="text" maxlength="70" class="form-control"
                                [(ngModel)]="this.Quote.EmpDemographics.Email" disabled>
                        </div>
                        <div class="col-md-4">
                            <label>Password (at least 8 characters long) <sup class="text-danger">(*)</sup></label><br>
                            <input type="password" maxlength=30 class="form-control"
                                [(ngModel)]="Quote?.RegistrationDetails.Password">
                        </div>
                        <div class="col-md-4">
                            <label>Confirm your password</label><br>
                            <input type="password" maxlength=30 class="form-control" [(ngModel)]="upassword2">
                        </div>
                    </div>


                    <br><br>


                    <!--GROUP PLANS SETUP-->
                    <div *ngIf="groupplan=='1'">
                        <h5>Family Members</h5>
                        <hr>
                        <div>
                            <div *ngFor="let child of Quote.DependentDemographics; let i=index">
                                <div class="row text-muted">
                                    <div class="col-md-3">
                                        {{child.FirstName}} {{child.LastName}}
                                    </div>
                                    <div class="col-md-2">
                                        {{child.Gender}}
                                    </div>
                                    <div class="col-md-2">
                                        {{child.BirthDate}}
                                    </div>
                                    <div class="col-md-3">
                                        {{child.Relationship}}
                                    </div>
                                    <div class="col-md-2 text-right">
                                        <div class="input-group">
                                            <button class="btn btn-primary btn-sm mr-2"
                                                (click)="editChildren(i, child.AFEtype)">Edit</button>
                                            <button class="btn btn-danger btn-sm"
                                                (click)="DeleteChildden(i)">Delete</button>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                            </div>
                        </div>
                        <div>
                            <button class="btn btn-warning btn-xs text-white" (click)="AddDependent('w')"
                                *ngIf="showWife" style="margin-right: 10px;">+ Add Spouse</button>
                            <button class="btn btn-warning btn-xs text-white" (click)="AddDependent('c')"
                                *ngIf="showChild">+ Add
                                Children</button>

                        </div>
                        <br>
                    </div>
                    <br>

                    <!--LIFE INSURANCE BENEFICIARIES-->
                    <div *ngIf="Quote?.selections.life.selection > 0">
                        <h5>Life Insurance Beneficiaries</h5>
                        <hr>
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                                <div>
                                    <br>
                                    <h6>Primary Beneficiaries</h6>
                                    <hr>
                                    <div *ngFor="let beneficiary of Quote.Beneficiaries; let i=index">
                                        <div *ngIf="beneficiary.BType=='P'">
                                            <div class="row text-muted">
                                                <div class="col-md-8">
                                                    {{beneficiary.FirstName}} {{beneficiary.LastName}}
                                                    ({{beneficiary.Type}})
                                                </div>

                                                <div class="col-md-2">
                                                    {{beneficiary.Allocations[0].Percentage}}%
                                                </div>

                                                <div class="col-md-2 text-left">
                                                    <button class="btn btn-danger btn-sm"
                                                        (click)="DeleteBeneficiary(i)">X</button>
                                                </div>
                                            </div>
                                            <hr>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-md-8">
                                            <strong>Total %</strong>
                                        </div>

                                        <div class="col-md-2">
                                            <strong>
                                                {{lisumpercentage}}%
                                            </strong>
                                        </div>

                                        <div class="col-md-2 text-left">

                                        </div>
                                    </div>
                                    <br>
                                    <button *ngIf="lisumpercentage < 100" class="btn btn-warning btn-xs text-white"
                                        (click)="AddBeneficiaries('p')" style="margin-right: 10px;">+ Add
                                        Beneficiary</button>
                                </div>

                            </div>

                            <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                                <div>
                                    <br>
                                    <h6>Contingent Beneficiaries</h6>
                                    <hr>
                                    <div *ngFor="let beneficiary of Quote.Beneficiaries; let i=index">
                                        <div *ngIf="beneficiary.BType=='C'">
                                            <div class="row text-muted">
                                                <div class="col-md-8">
                                                    {{beneficiary.FirstName}} {{beneficiary.LastName}}
                                                    ({{beneficiary.Type}})
                                                </div>
                                                <div class="col-md-2">
                                                    {{beneficiary.Allocations[0].Percentage}}%
                                                </div>

                                                <div class="col-md-2 text-left">
                                                    <button class="btn btn-danger btn-sm"
                                                        (click)="DeleteContingentBeneficiary(i)">X</button>
                                                </div>
                                            </div>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-8">
                                            <strong>Total %</strong>
                                        </div>

                                        <div class="col-md-2">
                                            <strong>
                                                {{liContsumpercentage}}%
                                            </strong>
                                        </div>

                                        <div class="col-md-2 text-left">

                                        </div>
                                    </div>

                                    <br>
                                    <button *ngIf="liContsumpercentage < 100" class="btn btn-warning btn-xs text-white"
                                        (click)="AddBeneficiaries('c')" style="margin-right: 10px;">+ Add
                                        Beneficiary</button>
                                </div>

                            </div>
                        </div>
                        <br>
                    </div>



                </div>
                <div class="text-right">
                    <button class="btn btn-primary" (click)="Finish()">Continue</button>
                    <br><br>

                </div>
                <br><br><br> <br><br><br>
            </div>
        </div>



        <div id="spinner" style="height: 100vh;" *ngIf="ShowSpinner">
            <div class="centered">
                <img src="assets/images/processing.gif" style="width:50px;">
                <br><br>
                <span class="text-white">Processing Enrollment. Please wait...</span>
            </div>
        </div>





        <!--*******************************-->
        <!--*******************************-->
        <!--*******************************-->
        <!--LIGHTBOXES FAMILY for all but life-->
        <!--*******************************-->
        <!--*******************************-->
        <!--*******************************-->

        <p-dialog [(visible)]="ShowAddCWife" modal="true" [draggable]="false" [closeOnEscape]="true" [responsive]="true"
            [style]="{width: '1100px'}" [baseZIndex]="1">
            <p-header style="width:100%">
                <h3>Add Spouse</h3>

            </p-header>
            <div>
                <div class="row">
                    <div class="col-md-3">
                        <label>Relationship <sup class="text-danger">(*)</sup></label><br>
                        <select class="form-control" [(ngModel)]="chrelationship">
                            <option value=""></option>
                            <option value="Spouse">Spouse</option>
                            <option value="Domestic Partner">Domestic Partner</option>
                            <!--<option value="Spouse Divorced">Spouse Divorced</option>-->
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label>First Name <sup class="text-danger">(*)</sup></label><br>
                        <input type="text" maxlength=100 class="form-control" [(ngModel)]="chfirstname">
                    </div>
                    <div class="col-md-3">
                        <label>Middle Initial</label><br>
                        <input type="text" maxlength=1 class="form-control" [(ngModel)]="chmiddlename">
                    </div>
                    <div class="col-md-3">
                        <label>Last Name <sup class="text-danger">(*)</sup></label><br>
                        <input type="text" maxlength=100 class="form-control" [(ngModel)]="chlastname">
                    </div>
                    <div class="col-md-3">
                        <label>Gender <sup class="text-danger">(*)</sup></label><br>
                        <select class="form-control" [(ngModel)]="chgender">
                            <option value=""></option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label>Date of Birth <sup class="text-danger">(*)</sup></label><br>
                        <input type="text" maxlength=100 class="form-control" [(ngModel)]="chdob" mask="M0/d0/0000"
                            [leadZeroDateTime]="true" [dropSpecialCharacters]="false" placeholder="mm/dd/yyyy"
                            [leadZeroDateTime]="true">
                    </div>
                    <!--
                    <div class="col-md-3">
                        <label>Social Security Number</label><br>
                        <input type="text" maxlength=100 class="form-control" [(ngModel)]="chssn" mask="000-00-0000"
                            [dropSpecialCharacters]="false">
                    </div>
                    <div class="col-md-3">
                        <label>Is this dependent disabled</label><br>
                        <select class="form-control" [(ngModel)]="chdisabled">
                            <option value=""></option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </select>
                    </div>
                    -->
                    <div class="col-md-6">
                        <label>Address <sup class="text-danger">(*)</sup></label><br>
                        <input type="text" maxlength=30 class="form-control" [(ngModel)]="chaddress">
                    </div>
                    <div class="col-md-6">
                        <label>Address 2</label><br>
                        <input type="text" maxlength=30 class="form-control" [(ngModel)]="chaddress2">
                    </div>
                    <div class="col-md-3">
                        <label>City <sup class="text-danger">(*)</sup></label><br>
                        <input type="text" maxlength=30 class="form-control" [(ngModel)]="chcity">
                    </div>
                    <div class="col-md-3">
                        <label>State <sup class="text-danger">(*)</sup></label><br>
                        <input type="text" maxlength=30 class="form-control" disabled [(ngModel)]="chstate">
                    </div>
                    <div class="col-md-3">
                        <label>ZIP Code <sup class="text-danger">(*)</sup></label><br>
                        <input type="text" maxlength=5 class="form-control" [(ngModel)]="chzip">
                    </div>
                    <div class="col-md-3">
                        <label>Phone Number</label><br>
                        <input type="text" maxlength=30 class="form-control" [(ngModel)]="chphone" mask="(000) 000-0000"
                            [dropSpecialCharacters]="false">
                    </div>

                </div>
            </div>
            <p-footer>
                <div class="text-right">
                    <button class="btn btn-danger" (click)="SaveChildren('S')">+ Save</button>
                </div>
            </p-footer>
        </p-dialog>


        <!--Add Children-->
        <p-dialog [(visible)]="ShowAddChildren" modal="true" [draggable]="false" [closeOnEscape]="true"
            [responsive]="true" [style]="{width: '1100px'}" [baseZIndex]="1">

            <p-header style="width:100%">
                <h3>Add Dependent Child</h3>

            </p-header>
            <div>
                <div class="row">
                    <div class="col-md-3">
                        <label>Relationship <sup class="text-danger">(*)</sup></label><br>
                        <select class="form-control" [(ngModel)]="chrelationship">
                            <option value=""></option>
                            <option value="Dependent Child">Dependent Child</option>
                            <option value="Dependent of Domestic Partner ">Dependent of Domestic Partner </option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label>First Name <sup class="text-danger">(*)</sup></label><br>
                        <input type="text" maxlength=100 class="form-control" [(ngModel)]="chfirstname">
                    </div>
                    <div class="col-md-3">
                        <label>Middle Name</label><br>
                        <input type="text" maxlength=1 class="form-control" [(ngModel)]="chmiddlename">
                    </div>
                    <div class="col-md-3">
                        <label>Last Name <sup class="text-danger">(*)</sup></label><br>
                        <input type="text" maxlength=100 class="form-control" [(ngModel)]="chlastname">
                    </div>
                    <div class="col-md-3">
                        <label>Gender <sup class="text-danger">(*)</sup></label><br>
                        <select class="form-control" [(ngModel)]="chgender">
                            <option value=""></option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label>Date of Birth <sup class="text-danger">(*)</sup></label><br>
                        <input type="text" maxlength=100 class="form-control" [(ngModel)]="chdob" mask="M0/d0/0000"
                            [leadZeroDateTime]="true" [dropSpecialCharacters]="false" placeholder="mm/dd/yyyy">
                    </div>
                    <!--
                    <div class="col-md-3">
                        <label>SSN (Optional)</label><br>
                        <input type="text" maxlength=100 class="form-control" [(ngModel)]="chssn" mask="000-00-0000"
                            [dropSpecialCharacters]="false">
                    </div>
                    -->
                    <div class="col-md-3">
                        <label>Is this dependent disabled <sup class="text-danger">(*)</sup></label><br>
                        <select class="form-control" [(ngModel)]="chdisabled">
                            <option value=""></option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </select>
                    </div>
                    <div class="col-md-6">
                        <label>Address <sup class="text-danger">(*)</sup></label><br>
                        <input type="text" maxlength=30 class="form-control" [(ngModel)]="chaddress">
                    </div>
                    <div class="col-md-6">
                        <label>Address 2</label><br>
                        <input type="text" maxlength=30 class="form-control" [(ngModel)]="chaddress2">
                    </div>
                    <div class="col-md-3">
                        <label>City <sup class="text-danger">(*)</sup></label><br>
                        <input type="text" maxlength=30 class="form-control" [(ngModel)]="chcity">
                    </div>
                    <div class="col-md-3">
                        <label>State <sup class="text-danger">(*)</sup></label><br>
                        <input type="text" maxlength=30 class="form-control" disabled [(ngModel)]="chstate">
                    </div>
                    <div class="col-md-3">
                        <label>ZIP Code <sup class="text-danger">(*)</sup></label><br>
                        <input type="text" maxlength=5 class="form-control" [(ngModel)]="chzip">
                    </div>
                    <div class="col-md-3">
                        <label>Phone Number</label><br>
                        <input type="text" maxlength=30 class="form-control" [(ngModel)]="chphone" mask="(000) 000-0000"
                            [dropSpecialCharacters]="false">
                    </div>

                </div>
            </div>
            <p-footer>
                <div class="text-right">
                    <button class="btn btn-danger" (click)="SaveChildren('C')">+ Save</button>
                </div>
            </p-footer>
        </p-dialog>



        <!--Add Life Beneficiary-->
        <p-dialog [(visible)]="ShowAddBeneficiary" modal="true" [draggable]="false" [closeOnEscape]="true"
            [responsive]="true" [style]="{width: '1100px'}" [baseZIndex]="1">

            <p-header style="width:100%">
                <h3>Add Life Insurance Beneficiary</h3>

            </p-header>
            <div>
                <div class="row">
                    <div class="col-md-2 col-sm-12 col-12">
                        <label>Beneficiary Type</label><br>
                        <select class="form-control" [(ngModel)]="litype">
                            <option value="Person">Person</option>
                            <option value="Trust">Trust Fund</option>
                        </select>
                    </div>
                    <div class="col-md-2 col-sm-12 col-12">
                        <label>Percentage</label><br>
                        <select class="form-control" [(ngModel)]="lipercentage">
                            <option *ngFor="let item of [].constructor(lirestpercentage); let i = index" value="{{i}}">
                                {{i}}
                                %</option>
                        </select>
                    </div>
                    <div class="col-md-4 col-sm-12 col-12" *ngIf="litype=='Person'">
                        <label>First Name</label><br>
                        <input type="text" maxlength=30 class="form-control" [(ngModel)]="lifirstname">
                    </div>
                    <div class="col-md-4 col-sm-12 col-12" *ngIf="litype=='Person'">
                        <label>Last Name</label><br>
                        <input type="text" maxlength=30 class="form-control" [(ngModel)]="lilastname">
                    </div>
                    <div class="col-md-8 col-sm-12 col-12" *ngIf="litype=='Trust'">
                        <label>Trust Name</label><br>
                        <input type="text" maxlength=30 class="form-control" [(ngModel)]="lilastname">
                    </div>


                    <div class="col-md-4 col-sm-12 col-12">
                        <label>Address</label><br>
                        <input type="text" maxlength=30 class="form-control" [(ngModel)]="liaddress">
                    </div>

                    <div class="col-md-4 col-sm-12 col-12">
                        <label>City</label><br>
                        <input type="text" maxlength=30 class="form-control" [(ngModel)]="licity">
                    </div>
                    <div class="col-md-2 col-sm-12 col-12">
                        <label>State</label><br>
                        <select [(ngModel)]="listate" class="form-control" name="residentstate" id="residentstate"
                            style="border-radius: 7px;">
                            <option value=""></option>
                            <option *ngFor="let USstate of USstates" value="{{USstate.Code}}">{{USstate.Name}}</option>
                        </select>
                    </div>
                    <div class="col-md-2 col-sm-12 col-12">
                        <label>ZIP Code</label><br>
                        <input type="text" maxlength=5 class="form-control" [(ngModel)]="lizip">
                    </div>


                </div>

            </div>
            <p-footer>
                <div class="text-right">
                    <button class="btn btn-danger" (click)="SaveLifePrimary()">+ Add Beneficiary</button>
                </div>
            </p-footer>
        </p-dialog>


        <!--Add Contingent Life Beneficiary-->
        <p-dialog [(visible)]="ShowAddContingentBeneficiary" modal="true" [draggable]="false" [closeOnEscape]="true"
            [responsive]="true" [style]="{width: '1100px'}" [baseZIndex]="1">

            <p-header style="width:100%">
                <h3>Add Life Insurance Contingent Beneficiary</h3>

            </p-header>
            <div>
                <div class="row">
                    <div class="col-md-2 col-sm-12 col-12">
                        <label>Beneficiary Type</label><br>
                        <select class="form-control" [(ngModel)]="litype">
                            <option value="Person">Person</option>
                            <option value="Trust">Trust Fund</option>
                        </select>
                    </div>
                    <div class="col-md-2 col-sm-12 col-12">
                        <label>Percentage</label><br>
                        <select class="form-control" [(ngModel)]="liContpercentage">
                            <option *ngFor="let item of [].constructor(liContrestpercentage); let i = index"
                                value="{{i}}">
                                {{i}}
                                %</option>
                        </select>
                    </div>
                    <div class="col-md-4 col-sm-12 col-12" *ngIf="litype=='Person'">
                        <label>First Name</label><br>
                        <input type="text" maxlength=30 class="form-control" [(ngModel)]="lifirstname">
                    </div>
                    <div class="col-md-4 col-sm-12 col-12" *ngIf="litype=='Person'">
                        <label>Last Name</label><br>
                        <input type="text" maxlength=30 class="form-control" [(ngModel)]="lilastname">
                    </div>
                    <div class="col-md-8 col-sm-12 col-12" *ngIf="litype=='Trust'">
                        <label>Trust Name</label><br>
                        <input type="text" maxlength=30 class="form-control" [(ngModel)]="lilastname">
                    </div>


                    <div class="col-md-4 col-sm-12 col-12">
                        <label>Address</label><br>
                        <input type="text" maxlength=30 class="form-control" [(ngModel)]="liaddress">
                    </div>

                    <div class="col-md-4 col-sm-12 col-12">
                        <label>City</label><br>
                        <input type="text" maxlength=30 class="form-control" [(ngModel)]="licity">
                    </div>
                    <div class="col-md-2 col-sm-12 col-12">
                        <label>State</label><br>
                        <select [(ngModel)]="listate" class="form-control" name="residentstate" id="residentstate"
                            style="border-radius: 7px;">
                            <option value=""></option>
                            <option *ngFor="let USstate of USstates" value="{{USstate.Code}}">{{USstate.Name}}</option>
                        </select>
                    </div>
                    <div class="col-md-2 col-sm-12 col-12">
                        <label>ZIP Code</label><br>
                        <input type="text" maxlength=5 class="form-control" [(ngModel)]="lizip">
                    </div>


                </div>

            </div>
            <p-footer>
                <div class="text-right">
                    <button class="btn btn-danger" (click)="SaveLifeContingent()">+ Add Beneficiary</button>
                </div>
            </p-footer>
        </p-dialog>





    </div>

</div>


<!--
    ****************************************
    ****************************************
    ****************************************
    STEP 10 - agreements
    ****************************************
    ****************************************
    ****************************************
-->
<div *ngIf="Step=='5'">
    <div class="container">
        <div class="about-content">
            <div>
                <br> <br>
                <div class="row">
                    <div class="col-6">
                        <h2>Member Application</h2>
                    </div>
                    <!--
                    <div class="col-6 text-right">
                        <button class="btn btn-primary" (click)="changeProductsSelection()" style="margin-right: 10px;">Change
                            Products Selection</button>
        
                        <button class="btn btn-warning text-white" (click)="SaveForLater()">Save & Continue
                            Later</button>

                    </div>
                    -->
                </div>
                <hr>
                <div *ngIf="Quote?.selections.membership.amount > 0">
                    <div class="row text-muted">
                        <div class="col-8">

                            {{Quote.selections.membership.name}}
                        </div>
                        <div class="col-4 text-right">
                            ${{Quote.selections.membership.amount  | number:'1.2-2'}}
                        </div>
                    </div>
                    <hr class="greyhr">
                </div>



                <div *ngIf="Quote?.selections.life.amount > 0">
                    <div class="row text-muted">
                        <div class="col-8">

                            {{Quote.selections.life.name}}
                        </div>
                        <div class="col-4 text-right">
                            ${{Quote.selections.life.amount  | number:'1.2-2'}}
                        </div>
                    </div>
                    <hr class="greyhr">
                </div>
                <div *ngIf="Quote?.selections.concordia.amount > 0">
                    <div class="row text-muted">
                        <div class="col-8">

                            {{Quote.selections.concordia.name}}
                        </div>
                        <div class="col-4 text-right">
                            ${{Quote.selections.concordia.amount  | number:'1.2-2'}}
                        </div>
                    </div>
                    <hr class="greyhr">
                </div>
                <div *ngIf="Quote?.selections.critical.amount > 0">
                    <div class="row text-muted">
                        <div class="col-8">

                            {{Quote.selections.critical.name}}
                        </div>
                        <div class="col-4 text-right">
                            ${{Quote.selections.critical.amount  | number:'1.2-2'}}
                        </div>
                    </div>
                    <hr class="greyhr">
                </div>
                <div *ngIf="Quote?.selections.critical65.amount > 0">
                    <div class="row text-muted">
                        <div class="col-8">

                            {{Quote.selections.critical65.name}}
                        </div>
                        <div class="col-4 text-right">
                            ${{Quote.selections.critical65.amount  | number:'1.2-2'}}
                        </div>
                    </div>
                    <hr class="greyhr">
                </div>
                <div *ngIf="Quote?.selections.dental.amount > 0">
                    <div class="row text-muted">
                        <div class="col-8">

                            {{Quote.selections.dental.name}}
                        </div>
                        <div class="col-4 text-right">
                            ${{Quote.selections.dental.amount  | number:'1.2-2'}}
                        </div>
                    </div>
                    <hr class="greyhr">
                </div>
                <div *ngIf="Quote?.selections.hooray.amount > 0">
                    <div class="row text-muted">
                        <div class="col-8">

                            {{Quote.selections.hooray.name}}
                        </div>
                        <div class="col-4 text-right">
                            ${{Quote.selections.hooray.amount  | number:'1.2-2'}}
                        </div>
                    </div>
                    <hr class="greyhr">
                </div>
                <div *ngIf="Quote?.selections.hospital.amount > 0">
                    <div class="row text-muted">
                        <div class="col-8">

                            {{Quote.selections.hospital.name}}
                        </div>
                        <div class="col-4 text-right">
                            ${{Quote.selections.hospital.amount  | number:'1.2-2'}}
                        </div>
                    </div>
                    <hr class="greyhr">
                </div>
                <div *ngIf="Quote?.selections.hospital65.amount > 0">
                    <div class="row text-muted">
                        <div class="col-8">

                            {{Quote.selections.hospital65.name}}
                        </div>
                        <div class="col-4 text-right">
                            ${{Quote.selections.hospital65.amount  | number:'1.2-2'}}
                        </div>
                    </div>
                    <hr class="greyhr">
                </div>
                <div *ngIf="Quote?.selections.medical.amount > 0">
                    <div class="row text-muted">
                        <div class="col-8">

                            {{Quote.selections.medical.name}}
                        </div>
                        <div class="col-4 text-right">
                            ${{Quote.selections.medical.amount  | number:'1.2-2'}}
                        </div>
                    </div>
                    <hr class="greyhr">
                </div>
                <div *ngIf="Quote?.selections.stdental.amount > 0">
                    <div class="row text-muted">
                        <div class="col-8">

                            {{Quote.selections.stdental.name}}
                        </div>
                        <div class="col-4 text-right">
                            ${{Quote.selections.stdental.amount  | number:'1.2-2'}}
                        </div>
                    </div>
                    <hr class="greyhr">
                </div>

                <div *ngIf="TotalToDisplay > 0">
                    <div class="row">
                        <div class="col-8">
                            <h6>TOTAL MONTHLY:</h6>
                        </div>
                        <div class="col-4 text-right">
                            <h6>${{TotalToDisplay | number:'1.2-2'}}</h6>
                        </div>
                    </div>
                    <hr class="greyhr">
                    <div *ngIf="Quote?.selections.concordia.remit" class="text-muted">
                        The Per Month Cost shown above includes billing fees, administrative fees, marketing expenses
                        and risk premium of ${{Quote.selections.concordia.remit | number:'1.2-2' }}. The risk premium is
                        subject to premium taxes which is the responsibility of the insurance company and paid by them.
                    </div>
                </div>
                <br>
                <br>
                <div class="alert alert-info">
                    <table class="table table-responsive"
                        style="width: 100% !important; display: table; margin-top: 15px;">

                        <tr *ngIf="Quote?.selections.life.selection > 0">
                            <td>
                                <input type="checkbox" [(ngModel)]="lifeStatement3" binary="true" inputId="binary" />
                            </td>
                            <td>
                                <strong>I confirm the following items are true and accurate; and I understand providing
                                    false or inaccurate information will cause the life insurance being applied for to
                                    be canceled and voided back to the original effective date.</strong>
                            </td>
                        </tr>
                        <tr *ngIf="Quote?.selections.dental.amount > 0 || Quote.selections.stdental.amount > 0">
                            <td style="width: 10px">
                                <input type="checkbox" [(ngModel)]="dentalStatement" binary="true" inputId="binary" />
                            </td>
                            <td><strong>
                                    I am enrolling in the dental coverage offered as a AFEUSA member and I understand in
                                    the future if I terminate or discontinue this dental coverage, I will not be able to
                                    enroll in any AFEUSA dental coverage offered for a minimum of 24 months after I
                                    terminate or discontinue this dental coverage.
                                </strong>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 10px">
                                <input type="checkbox" [(ngModel)]="TOS" binary="true" inputId="binary" />
                            </td>
                            <td><strong>
                                    Check this box to agree to AFEUSA's <a
                                        (click)="overlapOpen=true; overlapContent='tos'"
                                        class="text-primary mousepointer">Terms & Conditions</a> and <a
                                        class="text-primary mousepointer"
                                        (click)="overlapOpen=true; overlapContent='pp'">Privacy Policy.</a>
                                </strong>
                            </td>
                        </tr>
                        <tr *ngIf="Quote?.selections.life.selection > 0">
                            <td>
                                <input type="checkbox" [(ngModel)]="lifeStatement1" binary="true" inputId="binary" />
                            </td>
                            <td>
                                <strong>I am personally enrolling for membership in AFEUSA, and no other person is
                                    enrolling on my behalf.</strong>
                            </td>
                        </tr>
                        <tr *ngIf="Quote?.selections.life.selection > 0">
                            <td>
                                <input type="checkbox" [(ngModel)]="lifeStatement2" binary="true" inputId="binary" />
                            </td>
                            <td>
                                <strong>I am actively working, or if not, I’m fully capable of actively working for
                                    compensation.</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="checkbox" binary="true" inputId="binary" />
                            </td>
                            <td>
                                <strong>I expressly consent to receive and authorize Association for Entrepreneurship
                                    USA (“AFEUSA”) or its affiliates, business associates and third-party service
                                    providers acting on its behalf to deliver calls and SMS/text and voice messages to
                                    my cell phone and residential landline as applicable, possibly using an automatic
                                    telephone dialing system and/or using an artificial or pre-recorded voice,
                                    irrespective of whether my telephone number appears on any state or federal “Do Not
                                    Call” list. I understand that cellular rates may apply based on my data plan. These
                                    calls and messages will be for telemarketing, advertising and other purposes
                                    including servicing my account. Agreeing to receive such calls is not a condition of
                                    purchasing any goods or services.</strong>
                            </td>
                        </tr>
                    </table>
                </div>
                <br>
                <div class="alert alert-warning">
                    <h6><span *ngIf="origin=='backoffice'">AGENT </span>ELECTRONIC SIGNATURE:</h6>
                    <hr>
                    With this signature, I certify that all information provided is true, accurate and complete to the
                    best
                    of my knowledge.
                    <br>
                    <br>
                    <input type="text" class="form-control" maxlength="70" style="width: 250px;"
                        [(ngModel)]="eSignature">
                    <br>
                </div>
                <br>
                <div class="text-right">
                    <button class="btn btn-primary" (click)="doCheckOut()">Checkout</button>
                    <br><br>

                </div>

            </div>
        </div>
    </div>
</div>




<!--
    ****************************************
    ****************************************
    ****************************************
    STEP 5- payment
    ****************************************
    ****************************************
    ****************************************=
-->
<div [hidden]="Step!='20'">
    <div class="container">
        <div class="about-content">
            <div>
                <br> <br>
                <div class="row">
                    <div class="col-12">
                        <h2>Checkout</h2>
                    </div>

                </div>
                <hr>
                <iframe frameBorder="0" style="width: 100%; height: 1200px" src="assets/iframe.html" id="myiframe"
                    name="myiframe">

                </iframe>
            </div>
        </div>
    </div>

    <!--
    <div id="spinner" style="height: 100vh;" *ngIf="ShowSpinner">
        <div class="centered">
            <img src="assets/images/processing.gif" style="width:50px;">
            <br><br>
            <span class="text-white">Processing Enrollment. Please wait...</span>
        </div>
    </div>
-->


    <!--
    ****************************************
    ****************************************
    ****************************************
    STEP 6- membership saved - applies only to enrollment site
    ****************************************
    ****************************************
    ****************************************=
-->


    <div class="container" style="min-height: 100vh;" *ngIf="Step=='6'">
        <h2>We saved your application with AFEUSA!</h2>

        <hr />
        <br>
        <p>Thank you for learning more about the benefits of The Association of Entrepreneurship USA (AFEUSA) today.
            Your
            application is nearly complete, it will only take a few short moments to complete.
            <br><br>
            We just sent you an email containing the information you need to continue with this application at any time.
            <br>
            <br>
            We look forward to having you join AFEUSA so you can start using all the incredible benefits.
        </p>



    </div>

</div>







<!--*******************************-->
<!--*******************************-->
<!--*******************************-->
<!--LIGHTBOXES-->
<!--*******************************-->
<!--*******************************-->
<!--*******************************-->
<p-dialog [(visible)]="showPP" modal="true" [draggable]="false" [closeOnEscape]="true" [responsive]="true"
    [style]="{width: '80%', height: '700px'}" [baseZIndex]="100000">


    <div style="overflow-y: scroll; overflow-x: scroll; height:60vh; width: 100%;">
        <app-pp></app-pp>

    </div>

</p-dialog>


<p-dialog [(visible)]="showTOS" modal="true" [draggable]="false" [closeOnEscape]="true" [responsive]="true"
    [style]="{width: '80%', height: '700px'}" [baseZIndex]="100000">


    <div style="overflow-y: scroll; overflow-x: scroll; height:60vh; width: 100%;">
        <app-tos></app-tos>

    </div>

</p-dialog>