<h4 class="bluetitle">New Website</h4>
Create a new website to market the AFEUSA services. Upload a specific logo, change email and phone number and select the
products you want to market.
<hr>
<div>

    <div class="row" style="margin-left: 0px;">
        <div class="col-12 col-md-8 alert bShadow-5">
            <h5>Website Setup</h5>
            <hr>
            <div class="row">
                <div class="col-md-6 col-sm-6 col-12">
                    <label>Website Display Name</label>
                    <input class="form-control" type="text" maxlength="30" [(ngModel)]="Data.websitesetup.displayname">
                </div>
                <div class="col-md-3 col-sm-6 col-12">
                    <label>Display Email</label>
                    <input class="form-control" type="text" maxlength="60" [(ngModel)]="Data.websitesetup.displayemail">
                </div>
                <div class="col-md-3 col-sm-6 col-12">
                    <label>Display Phone</label>
                    <input class="form-control" type="text" maxlength="60" mask="(000) 000-0000"
                        [dropSpecialCharacters]="false" [(ngModel)]="Data.websitesetup.displayphone">
                </div>
                <div class="col-12">
                    <label>Website URL</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text" style="font-size: 24px !important;">
                                https://www.afeusaenroll.com/vip/</div>
                        </div>
                        <input [(ngModel)]="Data.websitesetup.url" maxLength="50" type="text" class="form-control"
                            id="inlineFormInputGroup" style="font-size: 24px !important;"
                            (keypress)="keyPressLettersNumbers($event)">
                    </div>
                </div>
            </div>
            <br>
            <h5>Available Products</h5>
            <hr>
            <app-selectproducts [Data]="Data"></app-selectproducts>

            <br><br>
        </div>
        <div class="col-12 col-md-4 ">
            <div class="alert bShadow-5 " style="min-height: 250px;">
                <h5>Logo Image</h5>
                <hr>
                <div class="text-center">
                    <img style="max-width: 300px; max-height: 300px;" src="{{logo}}">
                    <br><br>
                </div>
                <h6>Or choose your own logo: </h6>
                <small class="text-left">Recommended size: 50 by 270 pixels</small>
                <input type="file" class="form-control" accept="image/png,image/jpeg" (change)="onFileChanged($event)"
                    name="weblogo" id="weblogo" [(ngModel)]="weblogohasfile">

            </div>
        </div>
    </div>

</div>
<br><br>
<button class="btn btn-primary text-white" (click)="SaveWebsite()"><i class="fa fa-upload"></i> Save Website</button>
<br><br><br><br><br>
