<h4>
    List Prospects</h4>
<hr> Below is the list of all prospects that have been invited through the system. If they have purchased, they will show up under the Member tab.
<br><br>
<span>

    <input class="form-control" pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
        placeholder="Search criteria for every field..." />
</span>
<br>

<div class="minheight">
    <p-table #dt [value]="LeadsList" [rows]="25" [showCurrentPageReport]="true" [rowsPerPageOptions]="[25,50,200,1000]" styleClass="p-datatable-customers" [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        [filterDelay]="0" [globalFilterFields]="['fullname','email','cellphone','website','daysent', 'dayopened', 'dayclicked']" [resizableColumns]="true" [autoLayout]="true">

        <ng-template pTemplate="header">
            <tr class="FontRow f13">
                <th [pSortableColumn]="'fullname'">Lead Name</th>
                <th [pSortableColumn]="'email'">Email Address</th>
                <th [pSortableColumn]="'phone'">Phone Number</th>
                <th [pSortableColumn]="'emailsent'">Day Sent</th>
                <th [pSortableColumn]="'emailsent'">Day Opened</th>
                <th [pSortableColumn]="'emailsent'">Day Clicked</th>
                <th ></th>
            </tr>
            <tr>
                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'fullname', 'contains')" >
                </th>
                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'email', 'contains')" >
                </th>

                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'cellphone', 'contains')" >
                </th>

                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'daysent', 'contains')" >
                </th>

                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'dayopened', 'contains')" >
                </th>

                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'dayclicked', 'contains')" >
                </th>
                <th ></th>
               


            </tr>
        </ng-template>




        <ng-template pTemplate="body" let-LeadsList>
            <tr class="FontRow f13" >
                <td class="SmallFont">{{LeadsList.fullname}}</td>
                <td class="SmallFont"><a href="mailto:{{LeadsList.email}}">{{LeadsList.email}}</a></td>
                <td class="SmallFont"><a href="tel:{{LeadsList.cellphone}}">{{LeadsList.cellphone}}</a></td>
                <td class="SmallFont">{{LeadsList.daysent}}</td>
                <td class="SmallFont">{{LeadsList.dayopened}}</td>
                <td class="SmallFont">{{LeadsList.dayclicked}}</td>
                <td>
                    <button (click)="EmailLead(LeadsList.leadkey, 'lead')" class="btn btn-sm btn-warning text-white" style="margin-right: 10px;">Email Lead</button>
                    <button (click)="EmailLead(LeadsList.leadkey, 'agent')" class="btn btn-sm btn-primary">Email Me</button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>


