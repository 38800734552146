<h4>Profile</h4>
<hr />
<div class="row">
  <div class="col-12 col-sm-12 col-md-6 col-lg-6">
      <div class="bShadow-5">
          <div style="min-height: 250px;">
              <h6>Agent</h6>
              <hr>
              <table class="w100">
                  <tr class="spaceUnder">
                      <td class="text-info" style="width:150px;">
                          Signup Day:
                      </td>
                      <td class="text-black">
                          {{JSONResp.afe.signupdate}} (SMT)
                      </td>
                  </tr>
                  <tr class="spaceUnder">
                      <td class="text-info">
                          Agent ID:
                      </td>
                      <td class="text-black">
                          {{JSONResp.afe.id}}
                      </td>
                  </tr>
                  <tr class="spaceUnder">
                      <td class="text-info">
                          Full Name:
                      </td>
                      <td class="text-black">
                          {{JSONResp.FirstName}} {{JSONResp.LastName}}
                      </td>
                  </tr>
                  <tr class="spaceUnder">
                      <td class="text-info">
                          Address:
                      </td>
                      <td class="text-black">
                          {{JSONResp.Address1}} {{JSONResp.City}},
                          {{JSONResp.StateCode}} {{JSONResp.Zip}}
                      </td>
                  </tr>
                  <tr class="spaceUnder">
                      <td class="text-info">
                          Cell Phone:
                      </td>
                      <td class="text-black">
                          {{JSONResp.Phone}}
                      </td>
                  </tr>
                  <tr class="spaceUnder">
                      <td class="text-info">
                          Business Phone:
                      </td>
                      <td class="text-black">
                          {{JSONResp.BusinessPhone}}
                      </td>
                  </tr>
                  <tr class="spaceUnder">
                      <td class="text-info">
                          Fax Number:
                      </td>
                      <td class="text-black">
                          {{JSONResp.FaxPhone}}
                      </td>
                  </tr>
                  <tr class="spaceUnder">
                      <td class="text-info">
                          Email Address:
                      </td>
                      <td class="text-black">
                          {{JSONResp.Email}}
                      </td>
                  </tr>
              </table>
          </div>
      </div>
  </div>



  <div class="col-12 col-sm-12 col-md-6 col-lg-6">
      <div class="bShadow-5">
          <div style="min-height: 250px;">
              <h6>Products and Grid</h6>
              <hr>
              <table class="w100">
                  <tr class="spaceUnder">
                      <td></td>
                      <td class="text-title">Year 1</td>
                      <td class="text-title">Year 2+</td>
                  </tr>
                  <tr class="spaceUnder" *ngFor="let product of JSONResp.afe.afeproducts">
                      <td class="text-info">{{product.RealName}}</td>
                      <td class="text-black">{{product.Y1}}%</td>
                      <td class="text-black">{{product.Y2}}%</td>
                  </tr>
              </table>
          </div>
      </div>
  </div>
</div>
<br><br>

<div class="bShadow-5">
  <div style="min-height: 105px;">
      <h6>Electronic Signature</h6>
      <hr> Electronically signed from IP address {{JSONResp.afe.esignature.ipaddress}} on
      {{JSONResp.afe.esignature.signupdate}} SMT. Entered and confirmed signature: <span
          class="text-danger">{{JSONResp.afe.esignature.eSignature}}</span>
  </div>
</div>

<br><br>

<div class="bShadow-5">
  <div style="min-height: 105px;">
      <h6>Files available for download</h6>
      <div class="table-responsive">
          <table class="table">
              <tr>
                  <td>Document Code</td>
                  <td>Date Processes</td>
                  <td>File Description</td>
                  <td></td>
              </tr>
              <tr *ngFor="let file of APIresult[0].files">
                  <td>{{file.documenttype}}</td>
                  <td>{{file.datetime}}</td>
                  <td>{{file.description}}</td>
                  <td class="text-right">
                      <a class="btn btn-sm btn-warning text-white mousepointer" href="{{endpoint}}afeapi/desk/corporate/getdocument.aspx?did={{file.documentid}}&uk={{file.userkey}}" target="_blank">Download Document</a>
                  </td>
              </tr>
          </table>
      </div>

    
  </div>
</div>

<br><br><br><br><br>