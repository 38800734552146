import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-agentsprospects',
  templateUrl: './agentsprospects.component.html',
  styleUrls: ['./agentsprospects.component.css']
})
export class AgentsprospectsComponent implements OnInit {
  APIresult: any;
  ProspectsList: any;
  
  constructor(
    public _httpClient: HttpClient,
    private _router: Router,
    private r: ActivatedRoute,
  ) {


    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/listprospects.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));

    this._httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.APIresult = Array.of(result);
        if (result == null) {
       
        }
        else {
          if (this.APIresult[0].success == "1") {
            this.ProspectsList = this.APIresult[0].prospects;
            return;
          }
         
        }

      },
      error => {

      });

  }


  onRowSelect(id)
  : void {
    sessionStorage.setItem("temp", id);
    this._router.navigate(['../viewprospect'], { relativeTo: this.r });

  }

  ngOnInit(): void {
  }

}
