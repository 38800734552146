<div>
    <br />
   
    <div class="h2">
        <div >Accident and Sickness Hospital Indemnity</div>
    </div>
    <hr>
    <br />
    <br />
    <div class="row">
        <div class="col-md-3 text-center">
            <img src="assets/carriers/chubblogo.png " style="width: 150px; margin-top: 30px; margin-bottom: 60px" />
        </div>
        <div class="col-md-9">
            Even with a comprehensive medical plan, many individuals may still be
            responsible for paying deductibles, co-pays and other expenses due to
            accidents or sickness. With the rise in cost for health care coverage
            more consumers are opting for less expensive high-deductible health
            plans. AFEUSA’s Accident & Sickness Hospital Indemnity Plan pays a
            cash benefit for each day of hospital confinement due to a covered
            accident or sickness. The cash benefits can be used however the
            insured chooses, such as paying for deductibles, mortgage, rent, bills
            or other living expenses. Cash benefits for Emergency Room Visits and
            Physician Office Visits can be added.
        </div>
    </div>


    <br /><br />
   
    <div class="table-responsive">
        <table class="table">
            <tr class="bg-light text-center">
                <td ></td>
                <td style="width: 18%">Plan 1</td>
                <td style="width: 18%">Plan 2</td>
                <td style="width: 18%">Plan 3</td>
                <td style="width: 18%">Plan 4</td>
            </tr>

            <tr class="text-center">
                <td class="myblue text-left">
                    In Hospital Indemnity<br />(1 day elimination period for accident
                    or sickness)
                </td>
                <td>$250 per day</td>
                <td>$500 per day</td>
                <td>$750 per day</td>
                <td>$1,000 per day</td>
            </tr>
            <tr class="text-center">
                <td class="myblue text-left">Max days per confinement</td>
                <td>5</td>
                <td>5</td>
                <td>5</td>
                <td>5</td>
            </tr>
            <tr class="text-center">
                <td class="myblue text-left">
                    Max number of days per plan year
                </td>
                <td>10</td>
                <td>10</td>
                <td>10</td>
                <td>10</td>
            </tr>
            <tr class="text-center">
                <td class="myblue text-left">
                    Intensive care unit<br />(1 day elimination period for accident or
                    sickness)
                </td>
                <td>$250 per day</td>
                <td>$500 per day</td>
                <td>$750 per day</td>
                <td>$1,000 per day</td>
            </tr>
            <tr class="text-center">
                <td class="myblue text-left">Max days per confinement</td>
                <td>5</td>
                <td>5</td>
                <td>5</td>
                <td>5</td>
            </tr>
            <tr class="text-center">
                <td class="myblue text-left">
                    Max number of days per plan year
                </td>
                <td>10</td>
                <td>10</td>
                <td>10</td>
                <td>10</td>
            </tr>

            <tr *ngFor="let record of matrix; let row = index">
                <td class="myblue">{{ record.typename }}</td>
                <td class="text-center"><a
                        (click)="View(row+1, 1, record.col1, 'ASHIP Plan 1 '+ record.typename, 0); LightBox=true; PlanName='ASHIP Plan 1 '+ record.typename; "
                        [ngClass]="{'btn-success': SelectedRowCol === record.row + '1'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col1}}<br><small>View
                            Plan Details</small></a>
                </td>
                <td class="text-center"><a
                        (click)="View(row+1, 2, record.col2, 'ASHIP Plan 2 '+ record.typename, 0); LightBox=true; PlanName='AMASHIP Plan 2 '+record.typename; "
                        [ngClass]="{'btn-success': SelectedRowCol ===  record.row + '2'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col2}}<br><small>View
                            Plan Details</small></a>
                </td>
                <td class="text-center"><a
                        (click)="View(row+1, 3, record.col3, 'ASHIP Plan 3 '+record.typename, 0); LightBox=true; PlanName='ASHIP Plan 3 '+ record.typename; "
                        [ngClass]="{'btn-success': SelectedRowCol === record.row + '3'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col3}}<br><small>View
                            Plan Details</small></a>
                </td>
                <td class="text-center"><a
                        (click)="View(row+1, 4, record.col4, 'ASHIP Plan 4 '+record.typename, 0); LightBox=true; PlanName='ASHIP Plan 4 '+ record.typename; "
                        [ngClass]="{'btn-success': SelectedRowCol === record.row + '4'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col4}}<br><small>View
                            Plan Details</small></a>
                </td>
            </tr>

            <tr>
                <td colspan="5">
                    <small>
                        In Hospital and Intensive Care Unit benefits commence on the
                        second day of confinement. Benefits for dependent children are
                        paid at 50% of the benefit amount. Benefits are reduced by 50%
                        when the Covered Person attains the age of 65. Pre-existing
                        Condition limitation may be applicable. Please see Plan Documents
                        for details.


                    </small>
                </td>
            </tr>
        </table>
    </div>
    <small>
        THIS POLICY PROVIDES SUPPLEMENTAL BENEFITS ON A FIXED INDEMNITY
        BASIS. IT DOES NOT CONSTITUTE COMPREHENSIVE HEALTH INSURANCE COVERAGE
        (OFTEN REFERRED TO AS “MAJOR MEDICAL COVERAGE”) AND DOES NOT SATISFY A
        PERSON’S INDIVIDUAL OBLIGATION TO SECURE THE REQUIREMENT OF MINIMUM
        ESSENTIAL COVERAGE UNDER THE AFFORDABLE CARE ACT (ACA). FOR MORE
        INFORMATION ABOUT THE ACA, PLEASE REFER TO
        <a href="http://www.HealthCare.gov" target="_blank">
            http://www.HealthCare.gov</a>.

        <br /><br>Quoted plans underwritten by Federal Insurance Company, a Chubb
        company.
    </small>
    <br />
    <br />
    <h3>
        Accident and Sickness Hospital Indemnity w/ ER visits and physician
        office visit.
    </h3>
    <br />
    <table class="table table-responsives">
        <tr class="bg-light text-center">
            <td ></td>
            <td style="width: 18%">Plan 5</td>
            <td style="width: 18%">Plan 6</td>
            <td style="width: 18%">Plan 7</td>
            <td style="width: 18%">Plan 8</td>
        </tr>

        <tr class="text-center">
            <td class="myblue text-left">
                In Hospital Indemnity<br />(1 day elimination period for accident or
                sickness)
            </td>
            <td>$250 per day</td>
            <td>$500 per day</td>
            <td>$750 per day</td>
            <td>$1,000 per day</td>
        </tr>
        <tr class="text-center">
            <td class="myblue text-left">Max days per confinement</td>
            <td>5</td>
            <td>5</td>
            <td>5</td>
            <td>5</td>
        </tr>
        <tr class="text-center">
            <td class="myblue text-left">
                Max number of days per plan year
            </td>
            <td>10</td>
            <td>10</td>
            <td>10</td>
            <td>10</td>
        </tr>
        <tr class="text-center">
            <td class="myblue text-left">
                Intensive care unit<br />(1 day elimination period for accident or
                sickness)
            </td>
            <td>$250 per day</td>
            <td>$500 per day</td>
            <td>$750 per day</td>
            <td>$1,000 per day</td>
        </tr>
        <tr class="text-center">
            <td class="myblue text-left">Max days per confinement</td>
            <td>5</td>
            <td>5</td>
            <td>5</td>
            <td>5</td>
        </tr>
        <tr class="text-center">
            <td class="myblue text-left">
                Max number of days per plan year
            </td>
            <td>10</td>
            <td>10</td>
            <td>10</td>
            <td>10</td>
        </tr>
        <tr class="text-center">
            <td class="myblue text-left">
                ER Visit (Limit one Visit per Day)
            </td>
            <td>$100 per day</td>
            <td>$150 per day</td>
            <td>$200 per day</td>
            <td>$250 per day</td>
        </tr>
        <tr class="text-center">
            <td class="myblue text-left">
                Max Number of Days Per Plan Year
            </td>
            <td>2</td>
            <td>2</td>
            <td>2</td>
            <td>2</td>
        </tr>
        <tr class="text-center">
            <td class="myblue text-left">
                Physician Office Visit (Limit one Visit per Day)
            </td>
            <td>$25 per day</td>
            <td>$50 per day</td>
            <td>$75 per day</td>
            <td>$100 per day</td>
        </tr>
        <tr class="text-center">
            <td class="myblue text-left">
                Max Number of Days per Plan Year
            </td>
            <td>2</td>
            <td>2</td>
            <td>2</td>
            <td>2</td>
        </tr>
        <tr *ngFor="let record of matrix2; let row = index">
            <td class="myblue">{{ record.typename }}</td>
            <td class="text-center"><a
                    (click)="View(row+5, 1, record.col1, 'ASHIP Plan 5 '+ record.typename, 0); LightBox=true; PlanName='ASHIP Plan 5 '+ record.typename; "
                    [ngClass]="{'btn-success': SelectedRowCol === record.row + '1'}"
                    class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col1}}<br><small>View
                        Plan Details</small></a>
            </td>
            <td class="text-center"><a
                    (click)="View(row+5, 2, record.col2, 'ASHIP Plan 6 '+ record.typename, 0); LightBox=true; PlanName='AMASHIP Plan 6 '+record.typename; "
                    [ngClass]="{'btn-success': SelectedRowCol ===  record.row + '2'}"
                    class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col2}}<br><small>View
                        Plan Details</small></a>
            </td>
            <td class="text-center"><a
                    (click)="View(row+5, 3, record.col3, 'ASHIP Plan 7 '+record.typename, 0); LightBox=true; PlanName='ASHIP Plan 7 '+ record.typename; "
                    [ngClass]="{'btn-success': SelectedRowCol === record.row + '3'}"
                    class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col3}}<br><small>View
                        Plan Details</small></a>
            </td>
            <td class="text-center"><a
                    (click)="View(row+5, 4, record.col4, 'ASHIP Plan 8 '+record.typename, 0); LightBox=true; PlanName='ASHIP Plan 8 '+ record.typename; "
                    [ngClass]="{'btn-success': SelectedRowCol === record.row + '4'}"
                    class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col4}}<br><small>View
                        Plan Details</small></a>
            </td>
        </tr>
        <tr>
            <td colspan="5" class="text-muted">
                In Hospital and Intensive Care Unit benefits commence on the second
                day of confinement. Benefits for dependent children are paid at 50%
                of the benefit amount. Benefits are reduced by 50% when the Covered
                Person attains the age of 65. Pre-existing Condition limitation may
                be applicable. Please see Plan Documents for details.

            </td>
        </tr>
    </table>
    <small>
        THIS POLICY PROVIDES SUPPLEMENTAL BENEFITS ON A FIXED INDEMNITY
        BASIS. IT DOES NOT CONSTITUTE COMPREHENSIVE HEALTH INSURANCE COVERAGE
        (OFTEN REFERRED TO AS “MAJOR MEDICAL COVERAGE”) AND DOES NOT SATISFY A
        PERSON’S INDIVIDUAL OBLIGATION TO SECURE THE REQUIREMENT OF MINIMUM
        ESSENTIAL COVERAGE UNDER THE AFFORDABLE CARE ACT (ACA). FOR MORE
        INFORMATION ABOUT THE ACA, PLEASE REFER TO
        <a class="text-primary" href="www.HealthCare.gov" target="_blank">www.HealthCare.gov</a>

        <br /><br>Quoted plans underwritten by Federal Insurance Company, a Chubb
        company.
    </small>
    <br />

</div>



<!--*******************************-->
<!--*******************************-->
<!--*******************************-->
<!--LIGHTBOXES-->
<!--*******************************-->
<!--*******************************-->
<!--*******************************-->
<p-dialog [(visible)]="LightBox" modal="true" [draggable]="false" [closeOnEscape]="true" [responsive]="true"
    [style]="{ width: '80%' }" [baseZIndex]="100">
    <p-header style="width: 100%">
        <h3>{{ PlanName }}</h3>
        <p>
            Benefits and plans are offered by Dylan Consulting, LLC, a licensed
            independent insurance agency, and any of its subagents, and underwritten
            by licensed insurance companies. Not all products are available in all
            states.
        </p>
    </p-header>
    <h4>Accident and Sickness Hospital Indemnity</h4>
    <div style="overflow-y: scroll; overflow-x: scroll; height: 40vh; width: 100%">
        <div class="table-responsive" style="font-size: 14px">
            <table class="table">
                <tr>
                    <td style="width: 20%">Description of Coverage</td>
                    <td style="width: 80%">
                        This policy has a Pre-Existing condition Limitation. Please click
                        <strong>Plan Documents</strong> to review full terms and conditions,
                        including limitations and exclusions. <br /><br />
                        The benefit amount payable is reduced by 50% if the Covered Person
                        is age 65 or older on the date of a covered loss. The benefit amount
                        cannot be increased after age 65. (This benefit reduction does not
                        apply to residents of CA.)<br /><br />
                        If elected and in return for payment of the required premium,
                        benefits under the Policy may be extended for your Dependents:
                        Benefits for Member’s Spouse or Domestic Partner are paid at 100% of
                        the benefit amount for any covered loss, and Dependent Child
                        benefits are paid at 50% of the benefit amount listed for any
                        covered loss.
                    </td>
                </tr>
                <tr>
                    <td>Disclaimer</td>
                    <td>
                        <small>
                            This information is a brief description of the important features of
                            this insurance plan. It is not an insurance contract. Insurance
                            underwritten and provided by Federal Insurance Company, a Chubb
                            company. Chubb is the marketing name used to refer to subsidiaries
                            of Chubb Limited providing insurance and related services. For a
                            list of these subsidiaries, please visit our website at
                            <a href="http://www.chubb.com" target="_blank">
                                http://www.chubb.com</a>. All products may not be available in all states or certain
                            terms
                            may be different where required by state law. This communication
                            contains product summaries only. Coverage is subject to the language
                            of the policies as actually issued. Chubb, 202 Hall's Mill Road,
                            Whitehouse Station, NJ 08889-1600.<br /><br />
                            THIS POLICY PROVIDES SUPPLEMENTAL BENEFITS ON A FIXED INDEMNITY
                            BASIS. IT DOES NOT CONSTITUTE COMPREHENSIVE HEALTH INSURANCE
                            COVERAGE (OFTEN REFERRED TO AS “MAJOR MEDICAL COVERAGE”) AND DOES
                            NOT SATISFY A PERSON’S INDIVIDUAL OBLIGATION TO SECURE THE
                            REQUIREMENT OF MINIMUM ESSENTIAL COVERAGE UNDER THE AFFORDABLE CARE
                            ACT (ACA). FOR MORE INFORMATION ABOUT THE ACA, PLEASE REFER TO
                            <a href="http://www.HealthCare.gov" target="_blank">
                                http://www.HealthCare.gov</a>. <br /><br />
                            Quoted plans underwritten by Federal Insurance Company, a Chubb
                            company. <br /><br />
                            W121720-DT
                        </small>
                    </td>
                </tr>

                <tr>
                    <td>Benefits</td>
                    <td>
                        <table style="width: 100%">
                            <tr>
                                <th>Indemnity Benefit</th>
                                <th>Description</th>
                            </tr>
                            <tr>
                                <td>
                                    In Hospital Indemnity Benefit<br />
                                    (1 Day Elimination Period for Accident or Sickness)
                                </td>
                                <td>
                                    Daily Benefit Amount: {{ inHospital }} per day<br />
                                    Max Days per Confinement: 5<br />
                                    Max # of Days per Plan Year: 10<br /><br />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Intensive Care Unit Indemnity Benefit<br />
                                    (1 Day Elimination Period for Accident or Sickness)
                                </td>
                                <td>
                                    Daily Benefit Amount: {{ inHospital }} per day<br />
                                    Max Days per Confinement: 5<br />
                                    Max # of Days per Plan Year: 10<br /><br />
                                </td>
                            </tr>
                            <tr *ngIf="showVisits">
                                <td>
                                    ER Visit Indemnity Benefit<br />(Limit one visit per day)
                                </td>
                                <td>
                                    Daily Benefit Amount: {{ ERVisits }} per day<br />
                                    Max # of Days per Plan Year: 2<br /><br />
                                </td>
                            </tr>
                            <tr *ngIf="showVisits">
                                <td>
                                    Physician Office Visit Indemnity Benefit<br />(Limit one visit
                                    per day)
                                </td>
                                <td>
                                    Daily Benefit Amount: {{ DOCvisit }} per day <br />
                                    Max # of Days per Plan Year: 2<br />
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <p-footer>
        <div class="text-right">
            <div class="row">
                <div class="col-md-6 text-left">
                    <a href="assets/pdf/aship{{ SelectedCol }}{{ FileCA }}.pdf" target="_blank"
                        class="btn btn-outline-danger"><i class="fas fa-file-pdf text-danger btn-danger-"></i> Download
                        Plan Documents</a>
                </div>
                <div class="col-md-6 text-right">
                    <button (click)="SetPlan()" class="btn btn-danger">
                        + Add to Cart (${{ SelectedAmount }} per month)
                    </button>
                </div>
            </div>
        </div>
    </p-footer>
</p-dialog>