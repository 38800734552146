import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public Quotes: any;
  ShowSpinner: boolean = false;
  constructor(
    private httpClient: HttpClient,
  ) {

  }


  clickRecord(id): void {
    
  }

  clickDelete(id): void {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {

        this.ShowSpinner = true;
        var APIURL = sessionStorage.getItem('endpoint') + "afeapi/desk/deletequote.aspx";    // set header for http call
        var options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
        var body = new URLSearchParams();
        body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
        body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
        body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
        body.set('quoteid', id);

        this.httpClient.post(APIURL, body.toString(), options).subscribe(
          result => {

            this.Quotes = result;
          },
          error => {

          },
          () => {
            this.ShowSpinner = false;

          });

      }
    })

  }


  ngOnInit(): void {
    this.getRecords();
  }

  getRecords(): void {
    this.ShowSpinner = true;
    var APIURL = sessionStorage.getItem('endpoint') + "afeapi/desk/listquotes.aspx";    // set header for http call
    var options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    var body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {

        this.Quotes = result;
      },
      error => {

      },
      () => {
        this.ShowSpinner = false;

      });

  }

}
