

<div class="level1page ">

    <div class="stick-ontop">
        <h4 style="color:#f1f1f1; padding-top: 20px; ">
            Prospects Management
        </h4>
        <hr>

        <div>
            <div class="row">
                <div class="col-6 col-md-4 col-xl-2" *ngIf="application">
                    <div class="productbox2 mousepointer" [routerLink]="['membership']" >
                        <div class="text-left">
                            <span class="superfont">Member Application</span>
                            <br><span class="smallfont">Start a new application</span>
                        </div>
                    </div>
                </div>                
                <div class="col-6 col-md-4 col-xl-2" *ngIf="application">
                    <div class="productbox2 mousepointer" [routerLink]="['pendingapplications']" >
                        <div class="text-left">
                            <span class="superfont">Pending Applications</span>
                            <br><span class="smallfont">View saved/unfinished applications</span>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-xl-2">
                    <div class="productbox2 mousepointer" [routerLink]="['leads']" >
                        <div class="text-left">
                            <span class="superfont">Invite Prospect</span>
                            <br><span class="smallfont">Invite a potential member to learn more</span>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-xl-2">
                    <div class="productbox2 mousepointer" [routerLink]="['viewcontacts']" >
                        <div class="text-left">
                            <span class="superfont">List Prospects</span>
                            <br><span class="smallfont">List prospect member invites</span>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-xl-2">
                    <div class="productbox2 mousepointer" [routerLink]="['dashboard']" >
                        <div class="text-left">
                            <span class="superfont">Website Leads</span>
                            <br><span class="smallfont">Quotes generated through your website</span>
                        </div>
                    </div>
                </div>
               

            </div>
        </div>
        <br>



    </div>

   
    <div >
        <div class="subbox text-left" >
            <router-outlet></router-outlet>
        </div>
    </div>
    <br>
</div>



<!--

<div class="fixed-top" style="z-index: 90;">

    <div style="padding-left: 90px; ">
        <nav class="navbar navbar-expand-lg navbar-dark" style="background-color: #3e669c;">
            <a class="navbar-brand" href="#">
                  Prospects Management: 
                </a>

            <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
                  <span class="navbar-toggler-icon"></span>
                </button>


            <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }">
                <ul class="navbar-nav mr-auto">

                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="btn btn-sm btn-dark" routerLink="application">Member Application</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="btn btn-sm btn-dark" routerLink="pendingapplications">Pending Applications</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="btn btn-sm btn-dark" routerLink="leads">Invite Prospect</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="btn btn-sm btn-dark" routerLink="viewcontacts">List Prospects</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="btn btn-sm btn-dark" routerLink="dashboard">Analytics</a>
                    </li>
                   
                </ul>
            </div>
        </nav>
    </div>
</div>
<div class="body-text" style="z-index: 1; padding-right:20px;">
    <router-outlet></router-outlet>
</div>
-->