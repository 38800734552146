import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { AngularEditorConfig } from '@kolkov/angular-editor';
import Swal from 'sweetalert2';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.css']
})


export class LeadsComponent implements OnInit {


  //form general

  public MyColor: string = "bg-primary";
  public APIresult: any;

  //basic
  public firstname: string = "";
  public lastname: string = "";
  public email: string = "";
  public cellphone: string = "";

  public sendemail: string = "No";
  public sendsms: string = "No";
  public website: string;


  public sites: any;



  constructor(
    public _httpClient: HttpClient,
    public router: Router,
    private r: ActivatedRoute,
  ) {

    this.LoadProducts();

  }

  LoadProducts(): void {

    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/websites/listwebsites.aspx";
    var options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    var body = new URLSearchParams();

    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));

    this._httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {

        this.APIresult = result;
        if (this.APIresult.success == "1") {
          this.sites = this.APIresult.websites;
        }
      },
      error => {

      });
  }



  submitLead(): void {



    if (!this.firstname) {
      Swal.fire({ title: 'Enter First Name', text: 'First Name is a mandatory field.', icon: 'warning' });
      return;
    }
    if (!this.lastname) {
      Swal.fire({ title: 'Enter Last Name', text: 'Last Name is a mandatory field.', icon: 'warning' });
      return;
    }
    if (!this.validateEmail(this.email)) {
      Swal.fire({ title: 'Enter Email Address', text: 'Email Address is a mandatory field.', icon: 'warning' });
      return;
    }
    if (!this.website) {
      Swal.fire({ title: 'Select Website', text: 'Select the website for this prospect.', icon: 'warning' });
      return;
    }
    // calls API
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/postlead.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));

    // Additionals
    var saveadditional: string = "N";

    // basic variables
    body.set('contacttype', 'lead');
    body.set('firstname', encodeURIComponent(this.firstname));
    body.set('lastname', encodeURIComponent(this.lastname));
    body.set('email', encodeURIComponent(this.email));
    body.set('cellphone', encodeURIComponent(this.cellphone));

    body.set('sendemail', encodeURIComponent(this.sendemail));
    body.set('sendsms', encodeURIComponent(this.sendsms));
    body.set('website', encodeURIComponent(this.website));



    this._httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.APIresult = Array.of(result);
        if (result == null) {
          Swal.fire({
            title: 'Try again',
            text: 'Invalid credentials. Please try again.',
            icon: 'error',
          })
          return;
        }
        else {
          if (this.APIresult[0].success == "1") {
            Swal.fire({
              title: 'Contact added',
              text: this.APIresult[0].message,
              icon: 'success',
            })
            this.firstname = "";
            this.lastname = "";
            this.email = "";
            this.cellphone = "";

            this.sendemail = "Yes";
            this.sendsms = "No";
            this.website = "";
            return;
          }
          else {
            Swal.fire({
              title: 'Please check your input fields',
              text: this.APIresult[0].message,
              icon: 'error',

            })
          }

        }

      },
      error => {

      });

  }
  ngOnInit(): void {

  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


}
