import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { SelectedProducts } from '../../../../classes/classes'
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-customnew',
  templateUrl: './customnew.component.html',
  styleUrls: ['./customnew.component.css']
})
export class CustomnewComponent implements OnInit {

  public productslist: SelectedProducts[] = [];
  public Data: any;
  mycode: string;
  templatedescription: string = "";
  templatecode: string = "";
  firstname: string = "";
  lastname: string = "";
  email: string = "";
  cellphone: string = "";
  constructor(
    public _httpClient: HttpClient,
    private _router: Router,
    private r: ActivatedRoute,
  ) {
  }

  isNumber(value: string | number): boolean {
    return ((value != null) &&
      (value !== '') &&
      !isNaN(Number(value.toString())));
  }
  SaveTemplate(): void {
    this.templatedescription = this.templatedescription.trim();
    this.templatecode = this.templatecode.trim();
    if (!this.templatedescription) {
      Swal.fire({
        title: 'Enter Template Description',
        text: 'Enter your template description, for example "Template for Dental and Life Only"',
        icon: 'warning',
      });
      return;
    }

    if (!this.templatecode) {
      Swal.fire({
        title: 'Enter Enrollment Code',
        text: 'Enter the code your prospect agents will utilize to enroll',
        icon: 'warning',
      });
      return;
    }
    console.log(this.Data)
    //call API
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/savetemplate.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    //api specific
    body.set('description', encodeURIComponent(this.templatedescription));
    body.set('code', encodeURIComponent(this.templatecode));
    body.set('products', encodeURIComponent(JSON.stringify(this.Data)));

    this._httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        var APIresult: any = result;
        if (result == null) {
          Swal.fire({
            title: 'Template Not Saved',
            text: 'There was an error saving this template. Please contact our support team.',
            icon: 'error',
          });
          return;
        }
        else {
          if (APIresult.success == "1") {
            Swal.fire({
              title: 'Template Saved',
              text: APIresult.message,
              icon: 'success',
            });
            this._router.navigate(['../customlist'], { relativeTo: this.r });
            return;
          }

          if (APIresult.success == "0") {
            Swal.fire({
              title: 'Review your entry',
              text: APIresult.message,
              icon: 'warning',
            });
            return;
          }

          if (APIresult.success == "99") {
            Swal.fire({
              title: 'Error',
              text: APIresult.message,
              icon: 'error',
            });
            return;
          }
        }
      },
      error => {
        Swal.fire({
          title: 'Template Not Saved',
          text: 'There was an error saving this template. Please contact our support team.',
          icon: 'error',
        });
        return;
      });
  }

  keyPressLettersNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9

    if (charCode >= 48 && charCode <= 57) {
      return true;
      return;
    }

    if (charCode >= 65 && charCode <= 90) {
      return true;
      return;
    }

    if (charCode >= 97 && charCode <= 122) {
      return true;
      return;
    }

    event.preventDefault();
    return false;

  }

  ngOnInit(): void {
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/getagentcomp.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('pl', encodeURIComponent(sessionStorage.getItem('plid')));
    this._httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.Data = result;
        for (let product of this.Data) {
          product.agentpercent = 0;
          if (product.productid == 1) {
            product.enabled = "1";
          }
          if (product.productid == 7) {
            product.enabled = "1";
          }
        }
      },
      error => {
        return;
      });
  }

}
