import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2'
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-componentinfo',
  templateUrl: './componentinfo.component.html',
  styleUrls: ['./componentinfo.component.css']
})
export class ComponentinfoComponent implements OnInit {
  @Input() ID: string;
  //new

  public Data: any;
  public Data2: any;
  public Data3: any;
  public AdditionalData: any;
  public AdditionalData2: any;
  public AdditionalData3: any;
  public Sponsorpl: string;

  public newNote: boolean = false;

  public TempHideLog: boolean = true;

  public CurrentAgent: string;
  public endpoint: string;
  public CurrentPL: string;
  public JSONResp;
  public AllowCreate: boolean = false;
  public AllowViewMembers: boolean = false;
  public AllowDownline: boolean = false;
  public AllowViewAgentPassword: boolean = false;



  public LogActions: any;

  public ShowSponsor: boolean = false;
  public APISponsor: any;
  public editAgent: boolean = false;
  public newAgent: boolean = false;

  BackofficeType: string;
  agreement: string;
  eo: string;


  DownlineType: string = "Direct Downline";
  ShowProductsBox: boolean = false;
  public AgentType: string; //business or individual

  public ResendEmailDialog: boolean = false;

  public showspinner: boolean = false;

  public adm: boolean = false;

  ClientsList: any;
  clientAPIresult: any;
  ProductsList: any;
  tempAPIresult: any;
  APIedit: any;
  status: string;


  logfrom: string;
  logto: string;
  logaction: string;
  logactionby: string;

  logHeaders: any;
  logRecords: any;

  logFullName: string;
  logDate: string;
  LogActionName: string;
  logIP: string;
  logTitle: string;
  logDetails: string;

  showLog: boolean = false;
  logDescription: string;


  public NewSponsorID: string;
  public NewSponsorName: string;
  public NewSponsorHCI: string;

  //**********************+ */
  //step 1 Personal Data
  //**********************+ */
  fullcompanyname: string = "";
  firstname: string = "";
  middlename: string = "";
  lastname: string = "";
  address: string = "";
  address2: string = "";
  city: string = "";
  state: string = "";
  zip: string = "";
  cellphone: string = "";
  businessphone: string = "";
  faxnumber: string = "";
  contactemail: string = "";
  email: string = "";
  password: string = "";
  password2: string = "";
  agreemobile: boolean = false;
  dob: string = "";
  dlnumber: string = "";
  dlstate: string = "";


  //business only
  domicilestate: string = "";
  owners: string = "";
  authfirstname: string = "";
  authlastname: string = "";
  authemail: string = "";
  authphone: string = "";
  hasstaffmember: string = "N";


  //**********************+ */
  //step 2 Compensation
  //**********************+ */
  payee: string = "";
  taxid: string = "";
  routing: string = "";
  account: string = "";
  accounttype: string = "";
  bankname: string = "";
  beneficiaryname: string = "";

  //**********************+ */
  //step 3 License
  //**********************+ */
  typeofbusiness: string = "";
  typeoflicense: string = "";
  residentlicense: string = "";
  residentstate: string = "";
  npmnumber: string = "";
  haseocoverage: string = "y";
  eoamount: string = "";
  eofile: File;
  eoexpiration: string = "";

  public APIresult: any = [];

  public APIresult2: any;
  public APIresult3: any;
  public APIresult4: any;

  public APIresult5: any;

  public AgentsList: any;
  public TotalAgents: number;

  public Q1: string;
  public Q2: string;
  public Q3: string;
  public Q4: string;
  public Q5: string;
  public Q6: string;
  public Q7: string;
  public Q8: string;
  public Q9: string;
  public Q10: string;
  public Q11: string;
  public Q12: string;
  public Q13: string;
  public Q14: string;
  public Q15: string;

  A1: string = "";
  A2: string = "";
  A3: string = "";
  A4: string = "";
  A5: string = "";
  A6: string = "";
  A7: string = "";
  A8: string = "";
  A9: string = "";
  A10: string = "";
  A11: string = "";
  A12: string = "";
  A13: string = "";
  A14: string = "";
  A15: string = "";


  //**********************+ */
  //step 4 Website
  //**********************+ */
  webcompanyname: string = "";
  webfirstname: string = "";
  weblastname: string = "";
  webemail: string = "";
  webphone: string = "";
  webfulladdress: string = "";
  webaboutus: string = "";

  //**********************+ */
  //step 5 Review and Sign
  //**********************+ */
  agreesales: boolean = false;
  agreemarketing: boolean = false;
  esignature: string = "";
  pdfSrc: string;


  //json variables
  public products: any[];

  //titles and operations not sent to the API
  public addressTitle: string;
  public havestaff: boolean = false;
  public tabindex: number = 0;
  public inputError: boolean = true;
  public doPDF: boolean = false;


  public USstates = [
    {
      "Id": 83,
      "Code": "AL",
      "Name": "Alabama"
    },
    {
      "Id": 82,
      "Code": "AK",
      "Name": "Alaska"
    },
    {
      "Id": 87,
      "Code": "AZ",
      "Name": "Arizona"
    },
    {
      "Id": 85,
      "Code": "AR",
      "Name": "Arkansas"
    },
    {
      "Id": 88,
      "Code": "CA",
      "Name": "California"
    },
    {
      "Id": 89,
      "Code": "CO",
      "Name": "Colorado"
    },
    {
      "Id": 90,
      "Code": "CT",
      "Name": "Connecticut"
    },
    {
      "Id": 92,
      "Code": "DE",
      "Name": "Delaware"
    },
    {
      "Id": 91,
      "Code": "DC",
      "Name": "District of Columbia"
    },
    {
      "Id": 93,
      "Code": "FL",
      "Name": "Florida"
    },
    {
      "Id": 95,
      "Code": "GA",
      "Name": "Georgia"
    },
    {
      "Id": 96,
      "Code": "GU",
      "Name": "Guam"
    },
    {
      "Id": 97,
      "Code": "HI",
      "Name": "Hawaii"
    },
    {
      "Id": 99,
      "Code": "ID",
      "Name": "Idaho"
    },
    {
      "Id": 100,
      "Code": "IL",
      "Name": "Illinois"
    },
    {
      "Id": 101,
      "Code": "IN",
      "Name": "Indiana"
    },
    {
      "Id": 98,
      "Code": "IA",
      "Name": "Iowa"
    },
    {
      "Id": 102,
      "Code": "KS",
      "Name": "Kansas"
    },
    {
      "Id": 103,
      "Code": "KY",
      "Name": "Kentucky"
    },
    {
      "Id": 104,
      "Code": "LA",
      "Name": "Louisiana"
    },
    {
      "Id": 107,
      "Code": "ME",
      "Name": "Maine"
    },
    {
      "Id": 106,
      "Code": "MD",
      "Name": "Maryland"
    },
    {
      "Id": 105,
      "Code": "MA",
      "Name": "Massachusetts"
    },
    {
      "Id": 109,
      "Code": "MI",
      "Name": "Michigan"
    },
    {
      "Id": 110,
      "Code": "MN",
      "Name": "Minnesota"
    },
    {
      "Id": 113,
      "Code": "MS",
      "Name": "Mississippi"
    },
    {
      "Id": 111,
      "Code": "MO",
      "Name": "Missouri"
    },
    {
      "Id": 114,
      "Code": "MT",
      "Name": "Montana"
    },
    {
      "Id": 117,
      "Code": "NE",
      "Name": "Nebraska"
    },
    {
      "Id": 121,
      "Code": "NV",
      "Name": "Nevada"
    },
    {
      "Id": 118,
      "Code": "NH",
      "Name": "New Hampshire"
    },
    {
      "Id": 119,
      "Code": "NJ",
      "Name": "New Jersey"
    },
    {
      "Id": 120,
      "Code": "NM",
      "Name": "New Mexico"
    },
    {
      "Id": 122,
      "Code": "NY",
      "Name": "New York"
    },
    {
      "Id": 115,
      "Code": "NC",
      "Name": "North Carolina"
    },
    {
      "Id": 116,
      "Code": "ND",
      "Name": "North Dakota"
    },
    {
      "Id": 79,
      "Code": "00",
      "Name": "Not Determined"
    },
    {
      "Id": 123,
      "Code": "OH",
      "Name": "Ohio"
    },
    {
      "Id": 124,
      "Code": "OK",
      "Name": "Oklahoma"
    },
    {
      "Id": 125,
      "Code": "OR",
      "Name": "Oregon"
    },
    {
      "Id": 126,
      "Code": "PA",
      "Name": "Pennsylvania"
    },
    {
      "Id": 127,
      "Code": "PR",
      "Name": "Puerto Rico"
    },
    {
      "Id": 128,
      "Code": "RI",
      "Name": "Rhode Island"
    },
    {
      "Id": 129,
      "Code": "SC",
      "Name": "South Carolina"
    },
    {
      "Id": 130,
      "Code": "SD",
      "Name": "South Dakota"
    },
    {
      "Id": 131,
      "Code": "TN",
      "Name": "Tennessee"
    },
    {
      "Id": 132,
      "Code": "TX",
      "Name": "Texas"
    },
    {
      "Id": 133,
      "Code": "UT",
      "Name": "Utah"
    },
    {
      "Id": 136,
      "Code": "VT",
      "Name": "Vermont"
    },
    {
      "Id": 11142,
      "Code": "VI",
      "Name": "Virgin Islands"
    },
    {
      "Id": 134,
      "Code": "VA",
      "Name": "Virginia"
    },
    {
      "Id": 137,
      "Code": "WA",
      "Name": "Washington"
    },
    {
      "Id": 139,
      "Code": "WV",
      "Name": "West Virginia"
    },
    {
      "Id": 138,
      "Code": "WI",
      "Name": "Wisconsin"
    },
    {
      "Id": 140,
      "Code": "WY",
      "Name": "Wyoming"
    }
  ]


  public EmailContent: string;
  public EmailTo: string;
  public EmailCc: string;
  public EmailBcc: string;


  editorConfig: AngularEditorConfig = {
    editable: false,
    spellcheck: false,
    height: '25rem',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: false,
    placeholder: '',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize'],
      ['insertImage'],
      ['insertVideo'],
    ]
  };

  // custom paygrid


  isCode: boolean = false;

  APIafe1: any;
  APIafe2: any;
  APIdental: any;
  APIlife: any;
  APIchubb: any;
  APItemplates: any;
  APIdownline: any;

  uplineAPIafe1: any;
  uplineAPIafe2: any;
  uplineAPIdental: any;
  uplineAPIlife: any;
  uplineAPIchubb: any;
  uplineAPItemplates: any;
  uplineAPIdownline: any;


  allowcode: boolean = true;

  templatecode: any;

  mycode: string;
  sendemail: boolean = true;
  sendsms: boolean = true;
  myself: string = "";
  myid: string = "";


  /*Gone*/
  myafe1: number;
  myafe2: number;
  mydental: number;
  mylife: number;
  mychubb: number;
  /*Gone*/
  uplinemyafe1: number;
  uplinemyafe2: number;
  uplinemydental: number;
  uplinemylife: number;
  uplinemychubb: number;

  selectedafe1: number;
  selectedafe2: number;
  selecteddental: number;
  selectedlife: number;
  selectedchubb: number;

  uplineselectedafe1: number;
  uplineselectedafe2: number;
  uplineselecteddental: number;
  uplineselectedlife: number;
  uplineselectedchubb: number;

  showdental: boolean = false;
  showlife: boolean = false;
  showchubb: boolean = false;

  uplineshowdental: boolean = false;
  uplineshowlife: boolean = false;
  uplineshowchubb: boolean = false;
  selectedPaygrid: string;

  //for this specific module
  agentname: string;


  constructor(
    public httpClient: HttpClient,
    private _router: Router,
    private r: ActivatedRoute,
    private toastr: ToastrService
  ) {
    if (sessionStorage.getItem("afetype") == "24485168") {
      this.adm = true;
    }
    if (sessionStorage.getItem("agency") == "1") {
      this.AllowCreate = true;
      this.AllowViewMembers = true;
      this.AllowDownline = true;
      this.AllowViewAgentPassword = true
    }

    if (sessionStorage.getItem("agency") == "2") {
      this.AllowCreate = false;
      this.AllowViewMembers = true;
      this.AllowDownline = true;
      this.AllowViewAgentPassword = true;
    }


  }

  ngOnInit(): void {
    this.getAgentInfo('y')
  }

  getAgentInfo(track): void {
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/viewagent.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('pl', encodeURIComponent(this.ID));
    body.set('track', track);

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.APIresult = Array.of(result);
        if (result == null) {

        }
        else {
          if (this.APIresult[0].success == "1") {

            try {
              this.EmailTo = this.APIresult[0].agentinfo.email
              this.BackofficeType = this.APIresult[0].type;
              this.LogActions = this.APIresult[0].actions

              this.status = this.APIresult[0].agentinfo.status.toString();
              this.Q1 = this.APIresult[0].questions[0].qAnswer;
              this.A1 = this.APIresult[0].questions[0].qExplanation;

              this.Q2 = this.APIresult[0].questions[1].qAnswer;
              this.A2 = this.APIresult[0].questions[1].qExplanation;

              this.Q3 = this.APIresult[0].questions[2].qAnswer;
              this.A3 = this.APIresult[0].questions[2].qExplanation;

              this.Q4 = this.APIresult[0].questions[3].qAnswer;
              this.A4 = this.APIresult[0].questions[3].qExplanation;

              this.Q5 = this.APIresult[0].questions[4].qAnswer;
              this.A5 = this.APIresult[0].questions[4].qExplanation;

              this.Q6 = this.APIresult[0].questions[5].qAnswer;
              this.A6 = this.APIresult[0].questions[5].qExplanation;

              this.Q7 = this.APIresult[0].questions[6].qAnswer;
              this.A7 = this.APIresult[0].questions[6].qExplanation;

              this.Q8 = this.APIresult[0].questions[7].qAnswer;
              this.A8 = this.APIresult[0].questions[7].qExplanation;

              this.Q9 = this.APIresult[0].questions[8].qAnswer;
              this.A9 = this.APIresult[0].questions[8].qExplanation;

              this.Q10 = this.APIresult[0].questions[9].qAnswer;
              this.A10 = this.APIresult[0].questions[9].qExplanation;

              this.Q11 = this.APIresult[0].questions[10].qAnswer;
              this.A11 = this.APIresult[0].questions[10].qExplanation;

              this.Q12 = this.APIresult[0].questions[11].qAnswer;
              this.A12 = this.APIresult[0].questions[11].qExplanation;

              this.Q13 = this.APIresult[0].questions[12].qAnswer;
              this.A13 = this.APIresult[0].questions[12].qExplanation;

              this.Q14 = this.APIresult[0].questions[13].qAnswer;
              this.A14 = this.APIresult[0].questions[13].qExplanation;

              this.Q15 = this.APIresult[0].questions[14].qAnswer;
              this.A15 = this.APIresult[0].questions[14].qExplanation;
            }
            catch {

            }

            if (!this.APIresult[0].agentinfo.agentprincipalid) {
              this.AllowCreate = false;
            }
            else {
              this.AllowCreate = true;
            }

            this.Sponsorpl = this.APIresult[0].agentinfo.SponsorPL;

            if (this.APIresult[0].agentinfo.status == 50) {
              this.AllowViewMembers = false;
            }
            else {
              this.AllowViewMembers = true;
            }

            //files

            if (this.APIresult[0].files.agr) {
              this.agreement = sessionStorage.getItem("endpoint") + "afeapi/desk/corporate/getdocument.aspx?filename=" + this.APIresult[0].files.agr + "&sessionkey=" + encodeURIComponent(sessionStorage.getItem("sessionkey")) + "&plid=" + encodeURIComponent(sessionStorage.getItem("plid")) + "&userkey=" + encodeURIComponent(sessionStorage.getItem("userkey"))
            }
            if (this.APIresult[0].files.eo) {
              this.eo = sessionStorage.getItem("endpoint") + "afeapi/desk/corporate/getdocument.aspx?filename=" + this.APIresult[0].files.eo + "&sessionkey=" + encodeURIComponent(sessionStorage.getItem("sessionkey")) + "&plid=" + encodeURIComponent(sessionStorage.getItem("plid")) + "&userkey=" + encodeURIComponent(sessionStorage.getItem("userkey"))

            }

            this.toastr.success('You switched the agent to ' + this.APIresult[0].agentinfo.firstname + " " + this.APIresult[0].agentinfo.lastname, 'Agent Switched');

        


          }

        }

      },
      error => {

      });
  }

  RevealPassword(): void {
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/GetPassword.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('pl', encodeURIComponent(this.ID));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.tempAPIresult = Array.of(result);
        if (result == null) {

        }
        else {
          if (this.tempAPIresult[0].success == "1") {

            Swal.fire({
              title: this.tempAPIresult[0].password,
              text: 'Find above the unencrypted password for this agent.',
              icon: 'info',
            });

            return;
          }

        }

      },
      error => {

      });
  }


  /*
  FormatMoney(amount) {
    return (Number(amount).toLocaleString())

  }
*/


  EnableAgent(): void {



    Swal.fire({
      title: 'Enable Agent?',
      text: "Please confirm if you want to enable this account. A notification email will be sent to this agent.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Enable Agent'
    }).then((result) => {
      if (result.isConfirmed) {

        var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/corporate/EnableAgent.aspx";    // set header for http call
        let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
        // set body
        let body = new URLSearchParams();
        body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
        body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
        body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
        body.set('status', encodeURIComponent(status));
        body.set('pl', encodeURIComponent(this.ID));

        this.httpClient.post(APIURL, body.toString(), options).subscribe(
          result => {
            this.tempAPIresult = Array.of(result);
            if (result == null) {

            }
            else {
              if (this.tempAPIresult[0].success == "1") {
                Swal.fire({
                  title: 'Agent Enabled',
                  text: 'This agent has been enabled, and the welcome email was sent.',
                  icon: 'info',
                });
                this.getAgentInfo('n');
                return;
              }
              else {
                Swal.fire({
                  title: 'Warning',
                  text: this.tempAPIresult[0].message,
                  icon: 'error',
                });
                return;
              }

            }

          },
          error => {

          });
      }
    })

  }






  DisableAgent(): void {



    Swal.fire({
      title: 'Disable Agent?',
      text: "Please confirm if you want to disable this account.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Disable Agent'
    }).then((result) => {
      if (result.isConfirmed) {

        var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/corporate/DisableAgent.aspx";    // set header for http call
        let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
        // set body
        let body = new URLSearchParams();
        body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
        body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
        body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
        body.set('status', encodeURIComponent(status));
        body.set('pl', encodeURIComponent(this.ID));

        this.httpClient.post(APIURL, body.toString(), options).subscribe(
          result => {
            this.tempAPIresult = Array.of(result);
            if (result == null) {

            }
            else {
              if (this.tempAPIresult[0].success == "1") {
                Swal.fire({
                  title: 'Agent Disabled',
                  text: 'This agent has been disabled.',
                  icon: 'info',
                });
                this.getAgentInfo('n');
                return;
              }

            }

          },
          error => {

          });
      }
    })

  }



  ChangeAgencyType(type: string): void {
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/corporate/ChangeAgencyType.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('type', encodeURIComponent(type));
    body.set('pl', encodeURIComponent(this.ID));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.tempAPIresult = Array.of(result);
        if (result == null) {

        }
        else {
          if (this.tempAPIresult[0].success == "1") {
            Swal.fire({
              title: 'Agent Type Changed',
              text: 'The platform model for this account has been changed.',
              icon: 'info',
            });
            return;
          }
          else {
            Swal.fire({
              title: 'Error',
              text: 'Invalid Parameters.',
              icon: 'error',
            });
          }

        }

      },
      error => {

      });
  }



  ngOnChanges() {

  }

}
