<h4>List Users</h4>
<hr>


<div class="minheight">
    <p-table #dt [value]="Users" [rows]="25" [showCurrentPageReport]="true" [rowsPerPageOptions]="[25,50,200,1000]" styleClass="p-datatable-customers" [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} codes" [filterDelay]="0"
        [globalFilterFields]="['code','description','status','timesallowed','timesused']" [resizableColumns]="true" [autoLayout]="true" selectionMode="single">

        <ng-template pTemplate="header">
            <tr class="FontRow f13">
                <th [pSortableColumn]="'fname'">First Name</th>
                <th [pSortableColumn]="'lname'">Last Name</th>
                <th [pSortableColumn]="'email'">Email</th>
                <th></th>
            </tr>
            <tr>
                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'fname', 'contains')">
                </th>
                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'lname', 'contains')">
                </th>
                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'email', 'contains')">
                </th>
                <th>

                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-Users>
            <tr class="FontRow f13" [ngStyle]="{
                backgroundColor: Users.status != 1 ? '#FFE0E0' : 'transparent'
                }">
                <td class="SmallFont">{{Users.fname}}</td>
                <td class="SmallFont">{{Users.lname}}</td>
                <td class="SmallFont">{{Users.email}}</td>              
                <td class="text-right">
                    <button class="btn btn-sm btn-primary" (click)="onRowSelect(Users.agentid)" style="margin-right: 10px;">EDIT</button>
                    <button class="btn btn-sm btn-info" routerLink="../logs/{{Users.agentid}}"  style="margin-right: 10px;">LOGS</button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>