import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2'
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-websitestatus',
  templateUrl: './websitestatus.component.html',
  styleUrls: ['./websitestatus.component.css']
})
export class WebsitestatusComponent implements OnInit {
  APIdata: any;
  OpenVideo: boolean = false;
  VideoTitle: string="";
  VideoURL: any;

  activecategory: string;
  activesection: string;
  APItraining: any;
  SELECTEDsection: any;
  SELECTEDcategory: any;
  SELECTEDcontent: any = {};

  public BreadCrumb: string;
  public BreadCrumbSub: string;

  newcategory: boolean = false;
  newcategoryname: string = "";

  newsection: boolean = false;
  newsectionname: string = "";

  newcontent: boolean = false;
  editcontent: boolean = false;
  newcontentType: string = "video";
  newcontentTitle: string;
  newcontentDescription: string;
  newcontentValue: string;
  newcontentSortorder: string = "0";
  editContentID: string;

  showspinner: boolean = false;
  numbers: any;
  eofile: File;

  constructor(
    public httpClient: HttpClient,
    private toastr: ToastrService,
    private _sanitizer: DomSanitizer,
  ) {
    this.ReadAPI()
      .then((data) => {
        this.APItraining = (data);
      })
      .catch((error) => {

      });

      //loop from 1 to 99
      let N=99, i=0, a=Array(N);
      while(i<N) {      
        a[i++]=i;
      }
      this.numbers=a;

  }

  ngOnInit(): void {
  }

  

  ReadAPI() {
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/training/viewcontent.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    return this.httpClient.post(APIURL, body.toString(), options).toPromise();
  }


  enableCategory(category): void {
    this.BreadCrumb = "Browsing: "
    for (let result of this.APItraining) {
      if (result.categoryid.toString() == category) {
        this.SELECTEDcategory = result.TrainingSections
        this.activecategory = category;
        this.BreadCrumb = result.categorytext + " >> ";
        this.BreadCrumbSub = "";
      }
    }
    this.SELECTEDsection = undefined;
    this.activesection = undefined;

  }

  enableSection(section): void {
    //this.SELECTEDsection = this.SELECTEDcategory[section - 1].TrainingContent
    //this.activesection = section
    for (let result of this.SELECTEDcategory) {
      if (result.sectionid.toString() == section) {
        this.SELECTEDsection = result.TrainingContent
        this.activesection = section;
        this.BreadCrumbSub = result.sectionname;
      }
    }


  }

  deleteContent(id, action): void {
    if (id == undefined) {
      return;
    }

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert operation",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {

        var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/training/deletecontent.aspx";    // set header for http call
        let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
        // set body
        let body = new URLSearchParams();
        body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
        body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
        body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
        body.set('contentid', id);
        this.httpClient.post(APIURL, body.toString(), options).subscribe(
          result => {
            var APIresult: any = result;
            if (APIresult == null) {

            }
            else {
              if (APIresult.success == "1") {
                this.ReadAPI()
                this.newcontent = false;
                this.newcontentType = "video";
                this.newcontentValue = "";
                this.newcontentTitle = "";
                this.newcontentDescription = "";
                this.showspinner = false;
                this.ReadAPI()
                  .then((data) => {
                    this.APItraining = data;
                    //refreshes page content
                    for (let result of this.APItraining) {
                      if (result.categoryid.toString() == this.activecategory) {
                        this.SELECTEDcategory = result.TrainingSections
                      }
                    }

                    for (let result of this.SELECTEDcategory) {
                      if (result.sectionid.toString() == this.activesection) {
                        this.SELECTEDsection = result.TrainingContent
                      }
                    }

                    this.toastr.success('New Content Added', 'All done!');
                  })

                  .catch((error) => {

                  });
                return;

              }
              else {
                Swal.fire({
                  title: 'Error',
                  text: APIresult.message,
                  icon: 'error',
                })
              }
            }

          },
          error => {

          });

      }
    })


  }


  NewCategory(): void {
    this.newcategory = true;
  }


  NewSection() {
    this.newsection = true;
  }

  NewContent() {
    this.newcontent = true;
  }


  SaveCategory(): void {
    if (!this.newcategoryname.trim()) {
      return;
    }
    else {
      var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/training/addcategory.aspx";    // set header for http call
      let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
      // set body
      let body = new URLSearchParams();
      body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
      body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
      body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
      body.set('categoryname', encodeURIComponent(this.newcategoryname));
      this.httpClient.post(APIURL, body.toString(), options).subscribe(
        result => {
          var APIresult: any = result;
          if (APIresult == null) {

          }
          else {
            if (APIresult.success == "1") {
              this.ReadAPI()
                .then((data) => {
                  this.APItraining = data;
                  this.newcategory = false;
                  this.newcategoryname = "";
                  this.toastr.success('New Category Added', 'All done!');

                })
                .catch((error) => {

                });

            }
            else {
              Swal.fire({
                title: 'Error',
                text: APIresult.message,
                icon: 'error',
              })
            }
          }

        },
        error => {

        });

    }
  }

  SaveSection(): void {
    if (!this.newsectionname.trim()) {
      return;
    }
    else {
      var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/training/addsection.aspx";    // set header for http call
      let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
      // set body
      let body = new URLSearchParams();
      body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
      body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
      body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
      body.set('sectionname', encodeURIComponent(this.newsectionname));
      body.set('categoryid', this.activecategory);
      this.httpClient.post(APIURL, body.toString(), options).subscribe(
        result => {
          var APIresult: any = result;
          if (APIresult == null) {

          }
          else {
            if (APIresult.success == "1") {

              this.ReadAPI()
                .then((data) => {
                  this.APItraining = data;
                  this.enableCategory(this.activecategory);
                  this.newsectionname = "";
                  this.newsection = false;
                  this.toastr.success('New Section Added', 'All done!');
                })
                .catch((error) => {

                });


            }
            else {
              Swal.fire({
                title: 'Error',
                text: APIresult.message,
                icon: 'error',
              })
            }
          }

        },
        error => {

        });

    }
  }



  SaveContent(): void {

    if (this.newcontentType == 'video') {
      if (!this.newcontentValue) {
        Swal.fire({ title: 'Video Missing', text: 'Enter YouTube video v=??? identificator', icon: 'error' })
        return;
      }
    }

    if (!this.newcontentTitle) {
      Swal.fire({ title: 'Title Missing', text: 'Enter the title for this content', icon: 'error' })
      return;
    }

    if (!this.newcontentDescription) {
      Swal.fire({ title: 'Description Missing', text: 'Enter the description of this content', icon: 'error' })
    }


    var APIURL = sessionStorage.getItem('endpoint') + "afeapi/desk/training/addcontent.aspx";    // set header for http call
    let formData = new FormData();
    if (this.eofile) {
      formData.append("myfile", this.eofile, this.eofile.name);
    }
    // set body
    let body = new URLSearchParams();
    formData.append('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    formData.append('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    formData.append('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    formData.append('contentType', this.newcontentType);
    formData.append('contentValue', encodeURIComponent(this.newcontentValue));
    formData.append('contentTitle', encodeURIComponent(this.newcontentTitle));
    formData.append('contentDescription', encodeURIComponent(this.newcontentDescription));
    formData.append('sortorder', this.newcontentSortorder);
    formData.append('categoryid', this.activecategory);
    formData.append('sectionId', this.activesection);
    this.showspinner = true;
    this.httpClient.post(APIURL, formData).subscribe(
      result => {
        if (result == null) {
          Swal.fire({
            title: 'Error',
            text: "API Endpoint unreachable",
            icon: 'error',
          })
          this.showspinner = false;
        }
        else {
          var APIdata: any = result;
          if (APIdata.success == "1") {
            this.newcontent = false;
            this.newcontentType = "video";
            this.newcontentValue = "";
            this.newcontentTitle = "";
            this.newcontentDescription = "";
            this.showspinner = false;
            this.ReadAPI()
              .then((data) => {
                this.APItraining = data;
                //refreshes page content
                for (let result of this.APItraining) {
                  if (result.categoryid.toString() == this.activecategory) {
                    this.SELECTEDcategory = result.TrainingSections
                  }
                }

                for (let result of this.SELECTEDcategory) {
                  if (result.sectionid.toString() == this.activesection) {
                    this.SELECTEDsection = result.TrainingContent
                  }
                }

                this.toastr.success('New Content Added', 'All done!');
              })

              .catch((error) => {

              });
            return;
          }
          else {
            Swal.fire({
              title: 'Error',
              text: APIdata.message,
              icon: 'error',
            })
            this.showspinner = false;
            return;
          }
        }


      },
      error => {
        this.showspinner = false;
      });

  }



  editContent(id): void {

    for (let result of this.SELECTEDsection) {
      if (result.contentid == id) {
        this.SELECTEDcontent = result;
      }
    }

    this.editContentID=id;

  }


  viewContent(id): void {

    for (let result of this.SELECTEDsection) {
      if (result.contentid == id) {
        this.SELECTEDcontent = result;
        if (result.contenttype=='video') {
          this.OpenVideo=true;
          this.VideoTitle=result.title;
          this.VideoURL=this._sanitizer.bypassSecurityTrustResourceUrl(result.destination)
          this.VideoTitle=result.destination;
        }
        if (result.contenttype=='file') {
          window.open(result.destination, '_blank').focus();
        }
      }
    }

    

  }


  saveEdittedContent(): void {
  

    if (!this.SELECTEDcontent.title) {
      Swal.fire({ title: 'Title Missing', text: 'Enter the title for this content', icon: 'error' })
      return;
    }

    if (!this.SELECTEDcontent.description) {
      Swal.fire({ title: 'Description Missing', text: 'Enter the description of this content', icon: 'error' })
    }


    var APIURL = sessionStorage.getItem('endpoint') + "afeapi/desk/training/editcontent.aspx";    // set header for http call
    let formData = new FormData();
   
    // set body
    let body = new URLSearchParams();
    formData.append('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    formData.append('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    formData.append('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    formData.append('contentTitle', encodeURIComponent(this.SELECTEDcontent.title));
    formData.append('contentDescription', encodeURIComponent(this.SELECTEDcontent.description));
    formData.append('sortorder', this.SELECTEDcontent.sortorder);
    formData.append('contentid', this.editContentID);
    this.showspinner = true;
    this.httpClient.post(APIURL, formData).subscribe(
      result => {
        if (result == null) {
          Swal.fire({
            title: 'Error',
            text: "API Endpoint unreachable",
            icon: 'error',
          })
          this.showspinner = false;
        }
        else {
          var APIdata: any = result;
          if (APIdata.success == "1") {
            this.newcontent = false;
            this.newcontentType = "video";
            this.newcontentValue = "";
            this.newcontentTitle = "";
            this.newcontentDescription = "";
            this.showspinner = false;
            this.ReadAPI()
              .then((data) => {
                this.APItraining = data;
                //refreshes page content
                for (let result of this.APItraining) {
                  if (result.categoryid.toString() == this.activecategory) {
                    this.SELECTEDcategory = result.TrainingSections
                  }
                }

                for (let result of this.SELECTEDcategory) {
                  if (result.sectionid.toString() == this.activesection) {
                    this.SELECTEDsection = result.TrainingContent
                  }
                }

                this.toastr.success('New Content Added', 'All done!');
                this.editContentID=undefined;
              })

              .catch((error) => {

              });
            return;
          }
          else {
            Swal.fire({
              title: 'Error',
              text: APIdata.message,
              icon: 'error',
            })
            this.showspinner = false;
            return;
          }
        }


      },
      error => {
        this.showspinner = false;
      });

  }







  //no direct use
  onFileChanged(event) {
    this.eofile = event.target.files[0]
    if (this.eofile) {
      if (this.eofile.size > 10240000) {
        Swal.fire({
          title: 'File Too Big',
          text: 'Your E&O Certificate file size cannot be greater than 10Mb',
          icon: 'error',
        })

        return;
      }
    }
  }
}
