import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { AngularEditorConfig } from '@kolkov/angular-editor';


@Component({
  selector: 'app-emailmanagement',
  templateUrl: './emailmanagement.component.html',
  styleUrls: ['./emailmanagement.component.css']
})
export class EmailmanagementComponent implements OnInit {
  EmailsList: any;
  APIresult: any;
  EmailContent: string;
  EmailID: string;
  superuser: string = "Puccino";

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    height: '40rem',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: '',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize'],
      ['insertImage'],
      ['insertVideo'],
    ]
  };



  constructor(
    public _httpClient: HttpClient,
    private _router: Router,
    private r: ActivatedRoute,
  ) {
    this.GetEmailsList();
  }

  GetEmailsList(): void {
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/corporate/emailGetTriggers.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));

    this._httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.EmailsList = result;


      },
      error => {

      });
  }


  GetEmailContent(emailid): void {
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/corporate/emailGetContent.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('triggerid', emailid);

    this._httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        var APIresults: any = result;
     
        this.EmailID=emailid;
        this.EmailContent=APIresults.emailcontent;
      },
      error => {

      });
  }

  onRowSelect(id: string){
    this.GetEmailContent(id);
    //alert(id)
    
   }


   SaveEmail(): void {
     
     var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/corporate/emailUpdate.aspx";    // set header for http call
     let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
     // set body
     let body = new URLSearchParams();
     body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
     body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
     body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));

     body.set('emailid', encodeURIComponent(this.EmailID));
     body.set('superuser', encodeURIComponent(this.superuser));
     body.set('emailcontent', (this.EmailContent));

     
     this._httpClient.post(APIURL, body.toString(), options).subscribe(
       result => {
         var SaveResult: any = result;
         if (SaveResult.success=="1") {
        Swal.fire(
          'Trigger Updated',
          'This Trigger is updated and live.',
          'info'
        )
      }
      else
      {
        Swal.fire(
          'Trigger Updated Failed',
          SaveResult.message,
          'warning'
        )
      }
       },
       error => {
 
       });


   }


  ngOnInit(): void {
  }

}
