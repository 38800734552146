<h4>Password</h4>
<hr>
<div class="w100">

    <div class="bShadow-5">
        <br>
        <h5>Enter your new password below</h5>
        <hr>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-3">
                <label>Current Password</label><br>
                <input type="password" [(ngModel)]="oldpassword" maxlength="50" class="form-control">
            </div>
            <div class="col-12 col-sm-12 col-md-3">
                <label>New Password</label><br>
                <input type="password" [(ngModel)]="newpassword" maxlength="50" class="form-control">
            </div>
            <div class="col-12 col-sm-12 col-md-3">
                <label>Confirm New Password</label><br>
                <input type="password" [(ngModel)]="newpassword2" maxlength="50" class="form-control">

            </div>
        </div>
        <br>
        <button class="btn btn-warning btn-sm text-white " (click)="changePassword()" style="margin-right: 10px;"><i class="fa fa-key"></i> Update Password</button>
        <br>
        <br>

    </div>
    <br><br>
   
</div>