<div class="minheight">
    <h5>Agent Activity Log</h5>
            <hr>
            <div class="alert alert-info">
                This platform tracks down almost every activity performed by an agent, and the changes at an account
                level. This activity log shows all the activity for this agent. Select a filter below and click the
                "View Activity Log" button.
            </div>

            <h6>List Selection</h6>
            <hr>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-3 col-lg-2">
                    <div class="bShadow-5">
                        <!--Timegrame-->
                        <strong>Timeframe</strong>
                        <hr>
                        From Date:
                        <input type="text" class="form-control" mask="M0/d0/0000" [dropSpecialCharacters]="false"
                            [(ngModel)]="logfrom" placeholder="mm/dd/yyyy">
                        <div style="padding-top: 10px;">To Date:</div>
                        <input type="text" class="form-control" mask="M0/d0/0000" [dropSpecialCharacters]="false"
                            [(ngModel)]="logto" placeholder="mm/dd/yyyy">
                        <br>
                        <!--Action-->
                        <strong>Action</strong>
                        <hr>
                        <select class="form-control" [(ngModel)]="logaction">
                            <option value="0">All</option>
                            <option *ngFor="let action of LogActions" [value]="action.ActivityCode">
                                {{action.ActionName}}</option>
                        </select>
                        <br>
                        <!--Originating Agent-->
                        <strong>Action by</strong>
                        <hr>
                       

                        <button class="btn btn-sm btn-warning text-white w100" (click)="ViewLog()">View Activity
                            Log</button>
                        <br><br>
                        <small>* Up to 2,000 records will be displayed</small>
                        <br><br>
                    </div>
                    <br>

                </div>
                <div class="col-12 col-sm-6 col-md-9 col-lg-10">
                    <div class="bShadow-5" *ngIf="logHeaders">
                        <div class="table-responsive" style="min-height: 475px;">
                            <table class="table">
                                <tr style="font-weight: 600;">
                                    <td *ngFor="let header of logHeaders">{{header.header}}</td>
                                    <td></td>
                                </tr>
                                <tr *ngFor="let record of logRecords"
                                    [style.background]="record.activitycode==7? '#ffd4da': 'white'">
                                    <td class="text-muted">{{record.firstname}}</td>
                                    <td class="text-muted">{{record.lastname}}</td>
                                    <td class="text-muted">{{record.datetime}}</td>
                                    <td class="text-muted">{{record.ActionName}}</td>
                                    <td class="text-muted">{{record.actiontitle}}</td>
                                    <td class="text-muted text-right"><button (click)="OpenLog(record.activityid)"
                                            class="btn btn-primary btn-xs"
                                            style="margin-top: 0px; margin-bottom: 0px;">Open Log</button></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
</div>




<p-dialog [(visible)]="showLog" modal="true" appendToBody="true" [draggable]="true" [closeOnEscape]="false"
    [responsive]="true" [style]="{ width: '800px' }" [baseZIndex]="1">
    <p-header style="width: 100%">
        <h3>Viewing Agent Log</h3>
    </p-header>

    <div class="row">
        <div class="col-12 col-md-4">
            <div class="smallfont">Action performed by</div>
            <div class="form-control">{{logFullName}}</div>
        </div>
        <div class="col-12 col-md-4">
            <div class="smallfont">Date and Time</div>
            <div class="form-control">{{logDate}}</div>
        </div>
        <div class="col-12 col-md-4">
            <div class="smallfont">From IP Address</div>
            <div class="form-control">{{logIP}}</div>
        </div>
        <div class="col-12 col-md-4">
            <div class="smallfont">Action Type</div>
            <div class="form-control">{{LogActionName}}</div>
        </div>
        <div class="col-12 col-md-8">
            <div class="smallfont">Action</div>
            <div class="form-control">{{logTitle}}</div>
        </div>
    </div>
    <div class="smallfont">Details</div>
    <angular-editor [config]="editorConfig " [(ngModel)]="logDetails"></angular-editor>


</p-dialog>