import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxMaskModule } from 'ngx-mask'
import { NgxCurrencyModule } from "ngx-currency";
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ToastrModule } from 'ngx-toastr';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DatePipe } from '@angular/common';


//Prime NG
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { ChartModule } from 'primeng/chart';
import { TooltipModule } from 'primeng/tooltip';
import { TableModule } from 'primeng/table';
import { FileUploadModule } from 'primeng/fileupload';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';


//third parties
import { ColorPickerModule } from 'ngx-color-picker';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PlatformComponent } from './platform/platform.component';
import { CrmComponent } from './platform/crm/crm.component';
import { WebsiteComponent } from './platform/website/website.component';
import { ProductivityComponent } from './platform/productivity/productivity.component';
import { MetricsComponent } from './platform/metrics/metrics.component';
import { SetupComponent } from './platform/setup/setup.component';
import { LoginComponent } from './login/login.component';

//CRM
import { DashboardComponent } from './platform/crm/dashboard/dashboard.component';
import { LeadsComponent } from './platform/crm/leads/leads.component';
import { IntroComponent } from './platform/intro/intro.component';
import { ViewcontactsComponent } from './platform/crm/viewcontacts/viewcontacts.component';


//Website
import { BrandingComponent } from './platform/website/branding/branding.component';
import { ProductsComponent } from './platform/website/products/products.component';
import { PagesComponent } from './platform/website/pages/pages.component';
import { FrameworkComponent } from './platform/website/framework/framework.component';
import { SeoComponent } from './platform/website/seo/seo.component';
import { AnalyticsComponent } from './platform/website/analytics/analytics.component';
import { WebdashboardComponent } from './platform/website/webdashboard/webdashboard.component';
import { HomeComponent } from './platform/website/pages/home/home.component';
import { AboutusComponent } from './platform/website/pages/aboutus/aboutus.component';
import { ContactusComponent } from './platform/website/pages/contactus/contactus.component';
import { BlogComponent } from './platform/website/pages/blog/blog.component';
import { ProductspageComponent } from './platform/website/pages/productspage/productspage.component';

//agents management
import { AgentsComponent } from './platform/agents/agents.component';
import { AgentsdashboardComponent } from './platform/agents/agentsdashboard/agentsdashboard.component';
import { NewagentComponent } from './platform/agents/newagent/newagent.component';
import { AgentshierarchyComponent } from './platform/agents/agentshierarchy/agentshierarchy.component';
import { AgentscodesmanagerComponent } from './platform/agents/agentscodesmanager/agentscodesmanager.component';
import { CustomlistComponent } from './platform/agents/agentscodesmanager/customlist/customlist.component';
import { CustomnewComponent } from './platform/agents/agentscodesmanager/customnew/customnew.component';
import { HardcodedlistComponent } from './platform/agents/agentscodesmanager/hardcodedlist/hardcodedlist.component';
import { ApplicationComponent } from './platform/crm/application/application.component';





/*
import { SectionmembershipComponent } from './components/sectionmembership/sectionmembership.component';
import { SectiondentalComponent } from './components/sectiondental/sectiondental.component';
import { SectionmedicalComponent } from './components/sectionmedical/sectionmedical.component';
import { SectionsicknessComponent } from './components/sectionsickness/sectionsickness.component';
import { SectioncriticalComponent } from './components/sectioncritical/sectioncritical.component';
import { Sectionlife25Component } from './components/sectionlife25/sectionlife25.component';
import { Sectionlife75Component } from './components/sectionlife75/sectionlife75.component';
*/
import { Application2Component } from './platform/crm/application2/application2.component';
import { ProfileComponent } from './platform/setup/profile/profile.component';
import { PasswordComponent } from './platform/setup/password/password.component';
import { SupportComponent } from './platform/setup/support/support.component';

import { AgentsprospectsComponent } from './platform/agents/agentsprospects/agentsprospects.component';
import { ClientsComponent } from './platform/clients/clients.component';
import { ContractedComponent } from './platform/clients/contracted/contracted.component';
import { ClientsdashboardComponent } from './platform/clients/clientsdashboard/clientsdashboard.component';
import { CommissionsComponent } from './platform/commissions/commissions.component';
import { HciComponent } from './platform/commissions/hci/hci.component';
import { ViewagentComponent } from './platform/agents/viewagent/viewagent.component';
import { ViewprospectComponent } from './platform/agents/viewprospect/viewprospect.component';
import { BrandingeditComponent } from './platform/website/brandingedit/brandingedit.component';
import { CorporateComponent } from './platform/corporate/corporate.component';
import { CorporatedashboardComponent } from './platform/corporate/corporatedashboard/corporatedashboard.component';
import { CorpagentsComponent } from './platform/corporate/corpagents/corpagents.component';
import { CorpmembersComponent } from './platform/corporate/corpmembers/corpmembers.component';
import { ActivateComponent } from './platform/activate/activate.component';
//import { NewclientComponent } from './platform/clients/newclient/newclient.component';
//import { NewclientenrollmentComponent } from './platform/clients/newclientenrollment/newclientenrollment.component';
//import { NewclientquoteinfoComponent } from './platform/clients/newclientquoteinfo/newclientquoteinfo.component';
import { PendingapplicationsComponent } from './platform/crm/pendingapplications/pendingapplications.component';
import { SiteadminComponent } from './platform/corporate/siteadmin/siteadmin.component';
import { EmailmanagementComponent } from './platform/corporate/siteadmin/emailmanagement/emailmanagement.component';
import { AfeusaadministratorsComponent } from './platform/corporate/siteadmin/afeusaadministrators/afeusaadministrators.component';
import { WebsitestatusComponent } from './platform/corporate/siteadmin/websitestatus/websitestatus.component';
import { SuperuserComponent } from './platform/corporate/superuser/superuser.component';
import { SupaycommissionComponent } from './platform/corporate/superuser/supaycommission/supaycommission.component';
import { SudeletememberComponent } from './platform/corporate/superuser/sudeletemember/sudeletemember.component';
import { SudeleteagentComponent } from './platform/corporate/superuser/sudeleteagent/sudeleteagent.component';
import { SudeletecommissionComponent } from './platform/corporate/superuser/sudeletecommission/sudeletecommission.component';
import { UpdateaccountComponent } from './platform/setup/updateaccount/updateaccount.component';

import { NoRightClickDirective } from './norightclick';
import { SignaddendumComponent } from './platform/signaddendum/signaddendum.component';
import { ClientinfoComponent } from './platform/clients/clientinfo/clientinfo.component';
import { MembershipComponent } from './platform/clients/membership/membership.component';
import { P1Component } from './platform/clients/membership/p1/p1.component';
import { P2Component } from './platform/clients/membership/p2/p2.component';
import { P3Component } from './platform/clients/membership/p3/p3.component';
import { P4Component } from './platform/clients/membership/p4/p4.component';
import { P5Component } from './platform/clients/membership/p5/p5.component';
import { P6Component } from './platform/clients/membership/p6/p6.component';
import { P8Component } from './platform/clients/membership/p8/p8.component';
import { SetcompComponent } from './components/setcomp/setcomp.component';
import { SelectproductsComponent } from './components/selectproducts/selectproducts.component';
import { CustomeditComponent } from './platform/agents/agentscodesmanager/customedit/customedit.component';
import { NotesComponent } from './components/notes/notes.component';
import { ToolsComponent } from './platform/tools/tools.component';
import { NotesmoduleComponent } from './platform/tools/notesmodule/notesmodule.component';
import { UpdateniprComponent } from './platform/corporate/superuser/updatenipr/updatenipr.component';
import { QuerybuilderComponent } from './platform/clients/querybuilder/querybuilder.component';
import { P9Component } from './platform/clients/membership/p9/p9.component';
import { P10Component } from './platform/clients/membership/p10/p10.component';
import { AuditfileComponent } from './platform/corporate/superuser/auditfile/auditfile.component';
import { ManageusersComponent } from './platform/setup/manageusers/manageusers.component';
import { ListusersComponent } from './platform/setup/manageusers/listusers/listusers.component';
import { NewuserComponent } from './platform/setup/manageusers/newuser/newuser.component';
import { EdituserComponent } from './platform/setup/manageusers/edituser/edituser.component';
import { LogsComponent } from './platform/setup/manageusers/logs/logs.component';
import { TosComponent } from './components/tos/tos.component';
import { PpComponent } from './components/pp/pp.component';
import { AgenteditionComponent } from './platform/agents/agentedition/agentedition.component';
import { ComponentinfoComponent } from './platform/agents/agentedition/componentinfo/componentinfo.component';
import { ComponentmembersComponent } from './platform/agents/agentedition/componentmembers/componentmembers.component';
import { ComponentdownlineComponent } from './platform/agents/agentedition/componentdownline/componentdownline.component';
import { NiprComponent } from './platform/setup/nipr/nipr.component';
import { P11Component } from './platform/clients/membership/p11/p11.component';
import { P12Component } from './platform/clients/membership/p12/p12.component';

@NgModule({
  declarations: [
    AppComponent,
    PlatformComponent,
    CrmComponent,
    WebsiteComponent,
    ProductivityComponent,
    MetricsComponent,
    SetupComponent,
    DashboardComponent,
    LeadsComponent,
    IntroComponent,
    LoginComponent,
    ViewcontactsComponent,
    BrandingComponent,
    ProductsComponent,
    PagesComponent,
    FrameworkComponent,
    SeoComponent,
    AnalyticsComponent,
    WebdashboardComponent,
    HomeComponent,
    AboutusComponent,
    ContactusComponent,
    BlogComponent,
    ProductspageComponent,
    AgentsComponent,
    AgentsdashboardComponent,
    NewagentComponent,
    AgentshierarchyComponent,
    AgentscodesmanagerComponent,
    CustomlistComponent,
    CustomnewComponent,
    HardcodedlistComponent,
    ApplicationComponent,
    TosComponent, PpComponent,
    Application2Component, ProfileComponent, PasswordComponent, SupportComponent,
    AgentsprospectsComponent, ClientsComponent, ContractedComponent, ClientsdashboardComponent, CommissionsComponent,
    HciComponent, ViewagentComponent, ViewprospectComponent, BrandingeditComponent, CorporateComponent, CorporatedashboardComponent,
    CorpagentsComponent, CorpmembersComponent, ActivateComponent,
    PendingapplicationsComponent, SiteadminComponent, EmailmanagementComponent, AfeusaadministratorsComponent, WebsitestatusComponent,
    SuperuserComponent, SupaycommissionComponent, SudeletememberComponent, SudeleteagentComponent, SudeletecommissionComponent, NoRightClickDirective,
     UpdateaccountComponent, SignaddendumComponent, ClientinfoComponent, MembershipComponent, P1Component, P2Component, P3Component, P4Component, P5Component,
      P6Component, P8Component, SetcompComponent, SelectproductsComponent, CustomeditComponent, NotesComponent, ToolsComponent, NotesmoduleComponent, 
      UpdateniprComponent, QuerybuilderComponent, P9Component, P10Component, AuditfileComponent, ManageusersComponent, ListusersComponent, NewuserComponent, 
      EdituserComponent, LogsComponent, AgenteditionComponent, ComponentinfoComponent, ComponentmembersComponent, ComponentdownlineComponent, NiprComponent,
      P11Component, P12Component
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule, HttpClientModule, NgxMaskModule.forRoot(), NgxCurrencyModule, BrowserAnimationsModule,
    InputTextModule, ButtonModule, ChartModule, AngularEditorModule, FileUploadModule,
    TooltipModule, TableModule, ColorPickerModule, ToastrModule.forRoot(), DialogModule, TabViewModule, PdfViewerModule


  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
