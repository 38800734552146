import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-p2',
  templateUrl: './p2.component.html',
  styleUrls: ['./p2.component.css']
})
export class P2Component implements OnInit {
  @Output("Calculate") Calculate: EventEmitter<any> = new EventEmitter();
  @Input() Data: any;

  public matrix: any;
  public selection: any;

  public LightBox: boolean = false;
  public PlanName: string = "";
  public AnnualDeductible: string = "";
  public MaximumBenefit: string = "";
  public Preventive: string = "";
  public BasicServices: string = "";
  public Fee: number=0;


  public SelectedCol: number=0;
  public SelectedRow: number=0;
  public SelectedAmount: number=0
  public SelectedName: string="";
  public SelectedRowCol: string="";

  constructor(
    private toastr: ToastrService
  ) {

   
    
  

  }

  ngOnInit(): void {
    this.matrix = this.Data.dental;
    this.selection = this.Data.selections.dental;
    this.SelectedRowCol=this.Data.selections.dental.row.toString() + this.Data.selections.dental.col.toString()
  }

  View(row, col, amount, name): void {
    this.SelectedCol=col;
    this.SelectedRow=row;
    this.SelectedAmount=amount;
    this.SelectedName=name

  }

  SetPlan(): void {
    this.SelectedRowCol=this.SelectedRow.toString() + this.SelectedCol.toString()

    this.Data.selections.stdental.selection=0
    this.Data.selections.stdental.amount= 0;
    this.Data.selections.stdental.col=0;
    this.Data.selections.stdental.row=0;
    this.Data.selections.stdental.name = "";

    this.Data.selections.concordia.selection=0
    this.Data.selections.concordia.amount= 0;
    this.Data.selections.concordia.col=0;
    this.Data.selections.concordia.row=0;
    this.Data.selections.concordia.name = "";

    this.Data.selections.dental.selection=this.SelectedRow.toString() + this.SelectedCol.toString()
    this.Data.selections.dental.amount= +this.SelectedAmount;
    this.Data.selections.dental.col=this.SelectedCol;
    this.Data.selections.dental.row=this.SelectedRow;
    this.Data.selections.dental.name = this.SelectedName;
  
    this.toastr.success('Plan added to your cart!', 'Plan Added');
    this.Calculate.emit();
    this.LightBox=false;
  }

}
