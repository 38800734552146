import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-brandingedit',
  templateUrl: './brandingedit.component.html',
  styleUrls: ['./brandingedit.component.css']
})
export class BrandingeditComponent implements OnInit {

  logo: string;
  EndPoint: string;
  Data: any;
  isCode: boolean = false;
  APIresult: any;
  deletelogo: string = "n";
  public preloader: boolean = false;
  weblogo: File;

  constructor(
    public _httpClient: HttpClient,
    public router: Router,
    private r: ActivatedRoute,
  ) {

  }



  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  onUploadLogo(): void {

  }


  GetWebsite(): void {
    this.preloader = true;
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/websites/viewwebsite.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('websiteid', encodeURIComponent(sessionStorage.getItem('temp')));

    this._httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.Data = result;
        if (result == null) {
          Swal.fire({
            title: 'Try again',
            text: 'Invalid credentials. Please try again.',
            icon: 'error',
          })
        }
        else {
          if (this.Data.success == "1") {
            for (let enabledproduct of this.Data.productssetup) {
              for (let availableproduct of this.Data.available) {
                if (enabledproduct.productid == availableproduct.productid) {
                  availableproduct.selected = 1;
                }
              }
            }
            if (this.APIresult[0].websitesetup.logoimage) {
              this.logo = sessionStorage.getItem("endpoint") + "/logos/" + this.APIresult[0].websitesetup.logoimage
            }
            else {
              this.logo = sessionStorage.getItem("endpoint") + "/logos/1.png"
            }
            console.log(this.Data)
          }
          else {
            Swal.fire({
              title: 'Check your input', text: this.APIresult[0].message, icon: 'error',
            })
          }
        }
      },
      error => {

      });
    this.preloader = false;
  }


  keyPressLettersNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9

    if (charCode >= 48 && charCode <= 57) {
      return true;
      return;
    }

    if (charCode >= 65 && charCode <= 90) {
      return true;
      return;
    }

    if (charCode >= 97 && charCode <= 122) {
      return true;
      return;
    }

    event.preventDefault();
    return false;

  }

  onFileChanged(event) {
    this.weblogo = event.target.files[0]
  }


  SaveWebsite(): void {

    if (!this.Data.websitesetup.displayname) {
      Swal.fire({ title: 'Attention', text: 'Enter Website Name.', icon: 'warning' });
      return;
    }

    if (!this.Data.websitesetup.displayemail) {
      Swal.fire({ title: 'Attention', text: 'Enter Display Email.', icon: 'warning' });
      return;
    }
    if (!this.validateEmail(this.Data.websitesetup.displayemail)) {
      Swal.fire({ title: 'Attention', text: 'Enter a Valid Display Email.', icon: 'warning' });
      return;
    }

    if (!this.Data.websitesetup.displayphone) {
      Swal.fire({ title: 'Attention', text: 'Enter Display Phone.', icon: 'warning' });
      return;
    }

    for (let enabledproduct of this.Data.available) {
      if (enabledproduct.productid == 1 && enabledproduct.selected == 0) {
        Swal.fire({ title: 'Attention', text: 'AFEUSA Basic Membership is required.', icon: 'warning' });
        return;
      }
      if (enabledproduct.productid == 7 && enabledproduct.selected == 0) {
        Swal.fire({ title: 'Attention', text: 'AFEUSA Silver Membership is required.', icon: 'warning' });
        return;
      }

    }


    if (this.weblogo) {
      if (this.weblogo.size > 400000) {
        Swal.fire({ title: 'Logo Too Big', text: 'Your logo size cannot be greater than 400Kb.', icon: 'error', })
        return;
      }
    }

    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/websites/addwebsite.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    let formData = new FormData();
    formData.append("plid", encodeURIComponent(sessionStorage.getItem('plid')));
    formData.append("userkey", encodeURIComponent(sessionStorage.getItem('userkey')));
    formData.append("sessionkey", encodeURIComponent(sessionStorage.getItem('sessionkey')));
    formData.append("websiteid", sessionStorage.getItem('temp'));
    formData.append("deletelogo", this.deletelogo);

    formData.append("payload", JSON.stringify(this.Data));
    if (this.weblogo) {
      formData.append("myfile", this.weblogo, this.weblogo.name);
    }
    this._httpClient.post(APIURL, formData).subscribe(
      result => {
        this.APIresult = result;
        if (result == null) {
          Swal.fire({
            title: 'Try again',
            text: 'Invalid credentials. Please try again.',
            icon: 'error',
          })
          return;
        }
        else {
          if (this.APIresult.success == "1") {
            Swal.fire({
              title: 'Website Saved',
              text: this.APIresult.message,
              icon: 'success',
            })
            window.scrollTo(0, 0)
            this.router.navigate(['../products'], { relativeTo: this.r });
            return;
          }
          else {
            Swal.fire({
              title: 'Changes Not Saved',
              text: this.APIresult.message,
              icon: 'warning',

            })
          }
        }
      },
      error => {

      });


  }


  UseAfeLogo(): void {
    this.logo = this.logo = sessionStorage.getItem("endpoint") + "/logos/1.png"
    this.deletelogo = "y";
  }


  ngOnInit(): void {
    this.GetWebsite();
  }

}
