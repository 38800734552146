import { Component, OnInit, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2'
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-auditfile',
  templateUrl: './auditfile.component.html',
  styleUrls: ['./auditfile.component.css']
})
export class AuditfileComponent implements OnInit {
  Password: string;
  ShowSpinner: boolean = false;
  from: string;
  to: string;
  cols: any[];
  reportTransaction: any;
  filename: string = "";
  showGrid: boolean = false
  constructor(
    public httpClient: HttpClient,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.cols = [
      { field: 'clientCode', header: 'clientCode' },
      { field: 'firstName', header: 'firstName' },
      { field: 'lastName', header: 'lastName' },
      { field: 'DOB', header: 'DOB' },
      { field: 'hciMemberId', header: 'hciMemberId' },
      { field: 'PersonCode', header: 'PersonCode' },

      { field: 'planIdentifier', header: 'planIdentifier' },
      { field: 'tierCode', header: 'tierCode' },
      { field: 'planId', header: 'planId' },
      { field: 'productcode', header: 'productcode' },
      { field: 'effectiveDate', header: 'effectiveDate' }
    ];

  }


  runQuery(): void {
    var date = new Date();
    this.filename="AFEUSA_To_HCIActive_EnrollAudit_" + this.datePipe.transform(date,"yyyyMMddhhmmss")
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/corporate/auditfile.aspx";
    var options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    var body = new URLSearchParams();

    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('from', (this.from));
    body.set('to', (this.to));
    this.ShowSpinner = true;
    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.ShowSpinner = false;
        this.reportTransaction = result;
        if (this.reportTransaction.success == "99") {
          Swal.fire('Error', this.reportTransaction.message, "error")
          return;
        }
        this.showGrid = true;
      },
      error => {
        this.ShowSpinner = false;
      });
  }

}
