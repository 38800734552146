import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NumberValueAccessor } from '@angular/forms';
@Component({
  selector: 'app-agentscodesmanager',
  templateUrl: './agentscodesmanager.component.html',
  styleUrls: ['./agentscodesmanager.component.css']
})
export class AgentscodesmanagerComponent implements OnInit {
APIresult: any;
public dOnly: number;
  constructor(
    public _httpClient: HttpClient,
  ) { 
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/checkdentalonly.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));

    this._httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.APIresult = Array.of(result);
        if (result == null) {

          return;
        }
        else {
          if (this.APIresult[0].success == "1") {
            this.dOnly=this.APIresult[0].dentalonly;
            return;
          }


        }

      },
      error => {

      });
  }

  ngOnInit(): void {
  }

}
