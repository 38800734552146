<div>
    <br />

    <div class="h2">
        <div>Group Hospital Indemnity</div>
    </div>
    <hr>
    <br />
    <br />
    <div class="row">
        <div class="col-md-3 text-center">
            <img src="assets/carriers/chubblogo.png " style="width: 150px; margin-top: 30px; margin-bottom: 60px" />
        </div>
        <div class="col-md-9">
            Even with a comprehensive medical plan, many individuals may still be
            responsible for paying deductibles, co-pays and other expenses due to
            accidents or sickness. With the rise in cost for health care coverage
            more consumers are opting for less expensive high-deductible health
            plans. AFEUSA’s Group Hospital Indemnity Plan pays a
            cash benefit for each day of hospital confinement due to a covered
            accident or sickness. The cash benefits can be used however the
            insured chooses, such as paying for deductibles, mortgage, rent, bills
            or other living expenses.
        </div>
    </div>


    <br /><br />

    <div class="table-responsive">
        <table class="table">
            <tr class="bg-light text-center">
                <td></td>
                <td style="width: 15%">Plan 1</td>
                <td style="width: 15%">Plan 2</td>
                <td style="width: 15%">Plan 3</td>
                <td style="width: 15%">Plan 4</td>
                <td style="width: 15%">Plan 5</td>
            </tr>

            <tr class="text-center">
                <td class="myblue text-left">
                    Hospital Confinement
                </td>
                <td>$250 per day</td>
                <td>$350 per day</td>
                <td>$500 per day</td>
                <td>$750 per day</td>
                <td>$1,000 per day</td>
            </tr>
            <tr class="text-center">
                <td class="myblue text-left"> &nbsp;&nbsp;&nbsp; Max Number of Days Per <br> &nbsp;&nbsp;&nbsp; Plan
                    Year</td>
                <td>10</td>
                <td>10</td>
                <td>10</td>
                <td>10</td>
                <td>10</td>
            </tr>
            <tr class="text-center">
                <td class="myblue text-left">Observation Benefit</td>
                <td>$250 per day</td>
                <td>$350 per day</td>
                <td>$500 per day</td>
                <td>$750 per day</td>
                <td>$1,000 per day</td>
            </tr>
            <tr class="text-center">
                <td class="myblue text-left">
                    &nbsp;&nbsp;&nbsp; Max number of days per<br> &nbsp;&nbsp;&nbsp; plan year
                </td>
                <td>5</td>
                <td>5</td>
                <td>5</td>
                <td>5</td>
                <td>5</td>
            </tr>

            <tr *ngFor="let record of matrix; let row = index">
                <td class="myblue">{{ record.typename }}</td>
                <td class="text-center"><a
                        (click)="View(row+1, 1, record.col1, 'HIP Plan 1 '+ record.typename, 0); LightBox=true; PlanName='HIP Plan 1 '+ record.typename; "
                        [ngClass]="{'btn-success': SelectedRowCol === record.row + '1'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col1}}<br><small>View
                            Plan Details</small></a>
                </td>
                <td class="text-center"><a
                        (click)="View(row+1, 2, record.col2, 'HIP Plan 2 '+ record.typename, 0); LightBox=true; PlanName='HIP Plan 2 '+record.typename; "
                        [ngClass]="{'btn-success': SelectedRowCol ===  record.row + '2'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col2}}<br><small>View
                            Plan Details</small></a>
                </td>
                <td class="text-center"><a
                        (click)="View(row+1, 3, record.col3, 'HIP Plan 3 '+record.typename, 0); LightBox=true; PlanName='HIP Plan 3 '+ record.typename; "
                        [ngClass]="{'btn-success': SelectedRowCol === record.row + '3'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col3}}<br><small>View
                            Plan Details</small></a>
                </td>
                <td class="text-center"><a
                        (click)="View(row+1, 4, record.col4, 'HIP Plan 4 '+record.typename, 0); LightBox=true; PlanName='HIP Plan 4 '+ record.typename; "
                        [ngClass]="{'btn-success': SelectedRowCol === record.row + '4'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col4}}<br><small>View
                            Plan Details</small></a>
                </td>
                <td class="text-center"><a
                        (click)="View(row+1, 5, record.col5, 'HIP Plan 5 '+record.typename, 0); LightBox=true; PlanName='HIP Plan 5 '+ record.typename; "
                        [ngClass]="{'btn-success': SelectedRowCol === record.row + '5'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col5}}<br><small>View
                            Plan Details</small></a>
                </td>
            </tr>

            <tr>
                <td colspan="5">
                    <small>
                        <strong>Group Hospital Indemnity Benefit Descriptions</strong> <br>
                        <br>
                        We will pay the daily Hospital Confinement Benefit Amount shown above, for each day a Covered
                        Person is confined as an inpatient in a Hospital if:
                        <br>
                        <ol>
                            <li>Confinement is for the treatment of a covered Injury or Sickness; and</li>
                            <li>Confinement begins within 7 days of the date of a Covered Accident or the first display
                                of symptoms for a Covered Sickness; and</li>
                            <li>Confinement is ordered by a Doctor as Medically Necessary; and</li>
                            <li>Coverage under the Policy is in force when confinement beings; and</li>
                            <li>The Benefit Waiting Period, if any, shown in the Schedule of Benefits for this benefit
                                is satisfied</li>
                        </ol>

                        We will pay the benefit for the Maximum Benefit Period shown above.
                        <br><br>
                        In computing the number of days payable under this benefit, the date of admission will be
                        counted but not the date of discharge. We will not pay benefits if a Covered Person is confined
                        in a Hospital and We determine the kind of care needed could be provided elsewhere.
                        <br><br>We will not pay for any confinement to an Observation Room or Emergency Room for
                        outpatient treatment under this benefit.
                        <br><br>Coverage of Recurring Hospitalization: A series of hospital confinements for the same or
                        related causes, when speared by 30 days or more, will be treated as new confinements
                        <br><br>
                        <strong>Observation Benefit</strong><br><br>
                        We will pay the amount shown above if, as the result of a Medical Emergency, the Covered Person
                        receives treatment in a Hospital Emergency Room and as a result is held in an Observation Room
                        without being admitted as an inpatient.
                        <br><br>This benefit is not payable for treatment received in an Emergency Room or confinement
                        to an Observation Room following an outpatient surgical procedure.
                        <br><br>“Medical Emergency” means a condition caused by an Injury that manifests itself by
                        symptoms of sufficient severity that a prudent layperson possessing an average knowledge of
                        health and medicine would reasonably expect that failure to receive immediate medical attention
                        would place the health of the person in serious jeopardy.

                    </small>
                </td>
            </tr>
        </table>
    </div>
    <small>
        THIS POLICY PROVIDES LIMITED BENEFITS ON A FIXED INDEMNITY BASIS. IT DOES NOT CONSTITUTE COMPREHENSIVE HEALTH
        INSURANCE COVERAGE (OFTEN REFERRED TO AS “MAJOR MEDICAL COVERAGE”) AND DOES NOT SATISFY A PERSON’S INDIVIDUAL
        OBLIGATION TO SECURE THE REQUIREMENT OF MINIMUM ESSENTIAL COVERAGE UNDER THE AFFORDABLE CARE ACT (ACA). FOR MORE
        INFORMATION ABOUT THE ACA, PLEASE REFER TO
        <a href="http://www.HealthCare.gov" target="_blank">
            http://www.HealthCare.gov</a>.

        <br /><br>This plan is underwritten by ACE American Insurance Company.
    </small>
    <br />
    <br />

    <br />

</div>



<!--*******************************-->
<!--*******************************-->
<!--*******************************-->
<!--LIGHTBOXES-->
<!--*******************************-->
<!--*******************************-->
<!--*******************************-->
<p-dialog [(visible)]="LightBox" modal="true" [draggable]="false" [closeOnEscape]="true" [responsive]="true"
    [style]="{ width: '80%' }" [baseZIndex]="100">
    <p-header style="width: 100%">
        <h3>{{ PlanName }}</h3>
        <p>
            Benefits and plans are offered by Dylan Consulting, LLC, a licensed
            independent insurance agency, and any of its subagents, and underwritten
            by licensed insurance companies. Not all products are available in all
            states.
        </p>
    </p-header>
    <h4>Group Hospital Indemnity</h4>
    <div style="overflow-y: scroll; overflow-x: scroll; height: 40vh; width: 100%">
        <div class="table-responsive" style="font-size: 14px">
            <table class="table">
                <tr>
                    <td style="width: 20%">Description of Coverage</td>
                    <td style="width: 80%">
                        Membership effective on the first of the month following enrollment; check your member portal
                        for your effective date.
                        <br><br>
                        Enrollment ages: 65 – 67 Coverage will terminate the last day of the month in which the members
                        turns 75.
                        <br><br>
                        If elected and in return for payment of the required premium, benefits under the Policy may be
                        extended to your Spouse or Domestic Partner.
                        <br><br>
                        A benefit will not be paid during the first 12 months of coverage under this policy for any
                        confinement in a hospital or observation room that is caused by or results from a Pre-Existing
                        Condition. “Pre-Existing Condition” means an Injury or Sickness for which a Covered Person
                        incurred expenses, received medical treatment, care or services including diagnostic measures,
                        took prescribed drugs or medicines, or for which a person would have consulted a Doctor within 6
                        months before his or her most recent effective date of insurance. Pre-Existing Conditions will
                        be covered after the Covered Person is treatment free while covered under this Policy or after
                        the Covered Person has been continuously covered under this Policy for 12 consecutive months.
                    </td>
                </tr>
                <tr>
                    <td>Disclaimer</td>
                    <td>
                        <small>
                            This information is a brief description of the important features of
                            this insurance plan. It is not an insurance contract. Insurance
                            underwritten and provided by ACE American Insurance Company, a Chubb
                            company. Chubb is the marketing name used to refer to subsidiaries
                            of Chubb Limited providing insurance and related services. For a
                            list of these subsidiaries, please visit our website at
                            <a href="http://www.chubb.com" target="_blank">
                                http://www.chubb.com</a>. All products may not be available in all states or certain
                            terms
                            may be different where required by state law. This communication
                            contains product summaries only. Coverage is subject to the language
                            of the policies as actually issued. Chubb, 202 Hall's Mill Road,
                            Whitehouse Station, NJ 08889-1600.<br /><br />
                            THIS POLICY PROVIDES SUPPLEMENTAL BENEFITS ON A FIXED INDEMNITY
                            BASIS. IT DOES NOT CONSTITUTE COMPREHENSIVE HEALTH INSURANCE
                            COVERAGE (OFTEN REFERRED TO AS “MAJOR MEDICAL COVERAGE”) AND DOES
                            NOT SATISFY A PERSON’S INDIVIDUAL OBLIGATION TO SECURE THE
                            REQUIREMENT OF MINIMUM ESSENTIAL COVERAGE UNDER THE AFFORDABLE CARE
                            ACT (ACA). FOR MORE INFORMATION ABOUT THE ACA, PLEASE REFER TO
                            <a href="http://www.HealthCare.gov" target="_blank">
                                http://www.HealthCare.gov</a>. <br /><br />
                            Quoted plans underwritten by ACE American Insurance Company, a Chubb
                            company. <br /><br />
                            W121720-DT
                        </small>
                    </td>
                </tr>

                <tr>
                    <td>Benefits</td>
                    <td>
                        <table style="width: 100%">
                            <tr>
                                <th>Indemnity Benefit</th>
                                <th>Description</th>
                            </tr>
                            <tr>
                                <td>
                                    Hospital Confinment
                                    <br>
                                </td>
                                <td>
                                    Daily Benefit Amount: {{ inHospital }} per day<br />
                                    Max Days per Confinement: 10<br />
                                    Max # of Days per Plan Year: 10<br /><br />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Observation Benefits
                                    <br>
                                </td>
                                <td>
                                    Daily Benefit Amount: {{inHospital}}<br>
                                    Max # of Days per Plan Year: 5

                                </td>
                            </tr>
                            <tr *ngIf="showVisits">
                                <td>
                                    ER Visit Indemnity Benefit<br />(Limit one visit per day)
                                </td>
                                <td>
                                    Daily Benefit Amount: {{ ERVisits }} per day<br />
                                    Max # of Days per Plan Year: 2<br /><br />
                                </td>
                            </tr>
                            <tr *ngIf="showVisits">
                                <td>
                                    Physician Office Visit Indemnity Benefit<br />(Limit one visit
                                    per day)
                                </td>
                                <td>
                                    Daily Benefit Amount: {{ DOCvisit }} per day <br />
                                    Max # of Days per Plan Year: 2<br />
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <p-footer>
        <div class="text-right">
            <div class="row">
                <div class="col-md-6 text-left">
                    <a href="assets/pdf/senior{{ SelectedCol }}.pdf" target="_blank" class="btn btn-outline-danger"><i
                            class="fas fa-file-pdf text-danger btn-danger-"></i> Download
                        Plan Documents</a>
                </div>
                <div class="col-md-6 text-right">
                    <button (click)="SetPlan()" class="btn btn-danger">
                        + Add to Cart (${{ SelectedAmount }} per month)
                    </button>
                </div>
            </div>
        </div>
    </p-footer>
</p-dialog>