<div>
    <br />



    <div class="h2">
        <div>Critical Illness Insurance</div>
    </div>
    <hr>

    <br /><br />
    <div class="row">
        <div class="col-md-3 text-center">
            <img src="assets/carriers/chubblogo.png " style="width: 150px; margin-top: 30px; margin-bottom: 60px" />
        </div>
        <div class="col-md-9">
            The AFEUSA Critical Illness Plan provides cash benefits for Type 1
            Cancer, Heart Attack and Stroke to help cover out-of-pocket medical
            and other non-medical expenses. Insureds can use the lump sum benefit
            payment for any purpose they choose: paying off deductibles, child
            care, transportation costs for themselves or family members, loss of
            income, or any other financial need.
        </div>
    </div>
    <br><br>
    <div class="table-responsive">
        <table class="table">
            <tr class="bg-light text-center">
                <td ></td>
                <td style="width: 18%">Chubb Critical Illness Plan 1</td>
                <td style="width: 18%">Chubb Critical Illness Plan 2</td>
                <td style="width: 18%">Chubb Critical Illness Plan 3</td>
                <td style="width: 18%">Chubb Critical Illness Plan 4</td>
            </tr>

            <tr class="text-center">
                <td class="myblue text-left">
                    Primary Insured Benefit Amount
                </td>
                <td>$5,000</td>
                <td>$10,000</td>
                <td>$15,000</td>
                <td>$20,000</td>
            </tr>
            <tr *ngFor="let record of matrix; let row = index">
                <td class="myblue">{{record.typename}} </td>
                <td class="text-center"><a
                        (click)="View(row+1, 1, record.col1, 'Chubb Critical Illness 1 '+ record.typename); LightBox=true; PlanName='Chubb Critical Illness 1 '+ record.typename; AnnualDeductible='$50'; MaximumBenefit='$1,000'; Preventive='50%'; BasicServices='50%'; Fee=record.col1"
                        [ngClass]="{'btn-success': SelectedRowCol === record.row + '1'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col1}}<br><small>View
                            Plan Details</small></a>
                </td>
                <td class="text-center"><a
                        (click)="View(row+1, 2, record.col2, 'Chubb Critical Illness 2 '+ record.typename); LightBox=true; PlanName='Chubb Critical Illness 2 '+record.typename; AnnualDeductible='$100'; MaximumBenefit='$3,000'; Preventive='100%'; BasicServices='70%'; Fee=record.col2"
                        [ngClass]="{'btn-success': SelectedRowCol ===  record.row + '2'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col2}}<br><small>View
                            Plan Details</small></a>
                </td>
                <td class="text-center"><a
                        (click)="View(row+1, 3, record.col3, 'Chubb Critical Illness 3 '+record.typename); LightBox=true; PlanName='Chubb Critical Illness 3 '+ record.typename; AnnualDeductible='$150'; MaximumBenefit='$3,000'; Preventive='100%'; BasicServices='70%'; Fee=record.col3"
                        [ngClass]="{'btn-success': SelectedRowCol === record.row + '3'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col3}}<br><small>View
                            Plan Details</small></a>
                </td>
                <td class="text-center"><a
                        (click)="View(row+1, 4, record.col4, 'Chubb Critical Illness 4 '+record.typename); LightBox=true; PlanName='Chubb Critical Illness 4 '+ record.typename; AnnualDeductible='$150'; MaximumBenefit='$3,000'; Preventive='100%'; BasicServices='70%'; Fee=record.col3"
                        [ngClass]="{'btn-success': SelectedRowCol === record.row + '4'}"
                        class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.col4}}<br><small>View
                            Plan Details</small></a>
                </td>
            <tr>
                <td colspan="5" class="text-muted">
                    There is a pre-existing condition limitation.
                </td>
            </tr>
        </table>
    </div>
    <small>
        THIS POLICY PROVIDES SUPPLEMENTAL BENEFITS ON A FIXED INDEMNITY
        BASIS. IT DOES NOT CONSTITUTE COMPREHENSIVE HEALTH INSURANCE COVERAGE
        (OFTEN REFERRED TO AS “MAJOR MEDICAL COVERAGE”) AND DOES NOT SATISFY A
        PERSON’S INDIVIDUAL OBLIGATION TO SECURE THE REQUIREMENT OF MINIMUM
        ESSENTIAL COVERAGE UNDER THE AFFORDABLE CARE ACT (ACA). FOR MORE
        INFORMATION ABOUT THE ACA, PLEASE REFER TO
        <a class="text-primary" href="www.HealthCare.gov" target="_blank">www.HealthCare.gov</a>

        <br />Quoted plans underwritten by Federal Insurance Company, a Chubb
        company.
    </small>
    <br>
    <br />
</div>


<!--*******************************-->
<!--*******************************-->
<!--*******************************-->
<!--LIGHTBOXES-->
<!--*******************************-->
<!--*******************************-->
<!--*******************************-->
<p-dialog [(visible)]="LightBox" modal="true" [draggable]="false" [closeOnEscape]="true" [responsive]="true"
    [style]="{ width: '80%' }" [baseZIndex]="100">
    <p-header style="width: 100%">
        <h3>{{ SelectedName }}</h3>
        <p>
            Benefits and plans are offered by Dylan Consulting LLC, a licensed
            independent insurance agency, and any of its subagents, and underwritten
            by licensed insurance companies. Not all products are available in all
            states.
        </p>
    </p-header>
    <div style="overflow-y: scroll; overflow-x: scroll; height: 40vh; width: 100%">
        <div class="table-responsive" style="font-size: 14px">
            <table class="table">
                <tr>
                    <td style="width: 20%">Description of Coverage</td>
                    <td style="width: 80%">
                        This policy has a Pre-Existing Condition Limitation. For complete
                        terms and conditions, including limitations and exclusions, please
                        click <strong>Plan Documents</strong> to review.
                    </td>
                </tr>
                <tr>
                    <td>
                        Primary Insured Benefit Amount<br />
                        (Per plan year for<br />Covered Critical Conditions Only
                    </td>
                    <td>
                        {{ SelectedAmount }}
                    </td>
                </tr>
                <tr>
                    <td>Covered Critical Conditions</td>
                    <td>
                        <table style="width: 100%">
                            <tr>
                                <th>Category</th>
                                <th>Covered Critical Condition</th>
                                <th>Percentage of Benefit Amount</th>
                            </tr>
                            <tr>
                                <td>Cancer/Tumors</td>
                                <td>Type 1 Cancer</td>
                                <td>100%</td>
                            </tr>
                            <tr>
                                <td>Heart and Circulatory</td>
                                <td>Heart Attack</td>
                                <td>100%</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Stroke</td>
                                <td>100%</td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>Disclaimer</td>
                    <td>
                        For the purpose of this policy, Type 1 Cancer means a malignant
                        neoplasm, which is characterized by the uncontrolled growth and
                        spread of malignant cells and the invasion of tissue and which is
                        not specifically hereafter excluded. Leukemia’s are included, except
                        Stage 0 chronic lymphocytic leukemia, and lymphomas are included,
                        with the exception of HIV-related lymphoma and cutaneous lymphoma.
                        Diagnosis of Type 1 Cancer will be the date on which the Type 1
                        Cancer is first confirmed through either a Pathological Diagnosis or
                        a Clinical Diagnosis. If a Pathological Diagnosis is not available
                        then We will accept a Clinical Diagnosis if the following conditions
                        are met: 1) a Pathological Diagnosis cannot be made because it is
                        medically inappropriate or life threatening; 2) there is medical
                        evidence in the form of laboratory testing including tumor markers,
                        if available, physical examination findings, and imaging study or
                        other diagnostic study findings to support the Diagnosis; and 3) a
                        licensed Physician is treating the Insured Person for Type 1 Cancer.
                        <br /><br />
                        This information is a brief description of the important features of
                        this insurance plan. It is not an insurance contract. Insurance
                        underwritten and provided by Federal Insurance Company, a Chubb
                        company. Chubb is the marketing name used to refer to subsidiaries
                        of Chubb Limited providing insurance and related services. For a
                        list of these subsidiaries, please visit our website at<a href="http://www.chubb.com"
                            target="_blank">
                            www.chubb.com</a>. All products may not be available in all states or certain terms
                        may be different where required by state law. This communication
                        contains product summaries only. Coverage is subject to the language
                        of the policies as actually issued. Chubb, 202 Hall's Mill Road,
                        Whitehouse Station, NJ 08889-1600.<br /><br />

                        THIS IS AN ACCIDENT ONLY POLICY AND IT DOES NOT PAY BENEFITS FOR
                        LOSS FROM SICKNESS. THIS POLICY PROVIDES SUPPLEMENTAL BENEFITS ON AN
                        EXPENSE INCURRED BASIS. IT DOES NOT CONSTITUTE COMPREHENSIVE HEALTH
                        INSURANCE COVERAGE (OFTEN REFERRED TO AS “MAJOR MEDICAL COVERAGE”)
                        AND DOES NOT SATISFY A PERSON’S INDIVIDUAL OBLIGATION TO SECURE THE
                        REQUIREMENT OF MINIMUM ESSENTIAL COVERAGE UNDER THE AFFORDABLE CARE
                        ACT (ACA). FOR MORE INFORMATION ABOUT THE ACA, PLEASE REFER TO
                        <a href="http://www.HealthCare.gov" target="_blank">
                            www.HealthCare.gov</a>. <br /><br />
                        Quoted plans underwritten by Federal Insurance Company, a Chubb
                        company. <br /><br />
                        W121720-DT
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <p-footer>
        <div class="row">
            <div class="col-md-6 text-left">
                <a href="assets/pdf/critical{{ SelectedCol }}.pdf" target="_blank" class="btn btn-outline-danger"><i
                        class="fas fa-file-pdf text-danger btn-danger-"></i> Download Plan
                    Documents</a>
            </div>
            <div class="col-md-6 text-right">
                <button (click)="SetPlan()" class="btn btn-danger">
                    + Add to Cart (${{ SelectedAmount }} per month)
                </button>
            </div>
        </div>
    </p-footer>
</p-dialog>