import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public APIresult: any;
  public username: string;
  public password: string;
  public server: string;
  public version: string;
  public recoverpassword: boolean=false;
  APIdata: any;
  constructor(
    public httpClient: HttpClient,
    public _router: Router,
  ) { 

    this.httpClient.get("assets/endpoint.json").subscribe(data =>{
      this.APIdata = data;
      sessionStorage.setItem('endpoint', this.APIdata.endpoint);
      sessionStorage.setItem('server', this.APIdata.legend);
      sessionStorage.setItem('version', this.APIdata.version);
      this.server=sessionStorage.getItem("server");
      this.version=this.APIdata.version;
    })

    
  }


  doLogin(): void {
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/login.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('username', encodeURIComponent(this.username));
    body.set('password', encodeURIComponent(this.password));

    
    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.APIresult = Array.of(result);
        if (result == null) {
          Swal.fire({
            title: 'Try again',
            text: 'Invalid credentials. Please try again.',
            icon: 'error',
          })
          return;
        }
        else {
          if (this.APIresult[0].success == "1") {
            sessionStorage.setItem('plid', this.APIresult[0].plid);
            sessionStorage.setItem('userid', this.APIresult[0].userid);
            sessionStorage.setItem('userkey', this.APIresult[0].userkey);
            sessionStorage.setItem('sessionkey', this.APIresult[0].sessionkey);
            sessionStorage.setItem('firstname', this.APIresult[0].firstname);
            sessionStorage.setItem('lastname', this.APIresult[0].lastname);
            sessionStorage.setItem('codeidentifier', this.APIresult[0].codeidentifier);
            sessionStorage.setItem('ClientPrincipalID', this.APIresult[0].ClientPrincipalID);
            sessionStorage.setItem('AgentPrincipalId', this.APIresult[0].AgentPrincipalId);
            sessionStorage.setItem('afetype', this.APIresult[0].afetype)
            sessionStorage.setItem('agency', this.APIresult[0].agency)
            sessionStorage.setItem('status', this.APIresult[0].status)
            sessionStorage.setItem('selections', this.APIresult[0].selections)

            sessionStorage.setItem('origin','backoffice');
            this._router.navigateByUrl("/platform/intro");
            return;
          }
          else{
            Swal.fire({
              title: 'Try again',
              text: this.APIresult[0].message,
              icon: 'error',
                
            })
          }
          
        }
        
      },
      error => {

      });

  }



  doPassword(): void {
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/passwordrecovery.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('username', encodeURIComponent(this.username));

    
    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.APIresult = Array.of(result);
        Swal.fire({
          title: 'Password Recovery Email Sent',
          text: "If the email address you entered matches, you will receive an email with password recovery instructions.",
          icon: 'success',
            
        })
        this.recoverpassword=false;
      },
      error => {

      });

  }

  onEnter1(): void {
    if (!this.username){return};
    if (!this.password){return};
    
    this.doLogin();
  }

  onEnter2(): void {
    if (!this.username){return};
    
    this.doPassword();
  }


  ngOnInit(): void {
  }

}
