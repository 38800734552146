<div>
    <br>
     <div class="h2">
         <div >AFEUSA Membership Plans</div>
     </div>
     <hr>
     <br>
     <br>
     <div class="row">
        <div class="col-md-3 text-center">
            <img src="assets/afelogoreal.png" style="width: 150px; margin-top: 30px; margin-bottom: 60px;">
        </div>
        <div class="col-md-9">
            Association for Entrepreneurship USA Members enjoy services and discounts on a variety of business, health and travel services. There are multiple memberships of the association. Association members will receive a separate access or mailing with complete
            details on how to access their benefits.app-buycritical.

        </div>
    </div>
            <table class="table table-responsives">

         <tr class="bg-light" >
             <td style="width:50%;"></td>
             <td class="text-center p10" style="width:25%;">Basic</td>
             <td class="text-center p10" style="width:25%;">Silver</td>

         </tr>

         <tr>
             <td>1-800 MD</td>
             <td class="text-center"><i class='fa fa-check'></i></td>
             <td  class="text-center"><i class='fa fa-check'></i></td>

         </tr>
         <tr>
             <td>1Rx Valet</td>
             <td class="text-center"><i class='fa fa-check'></i></td>
             <td class="text-center"><i class='fa fa-check'></i></td>

         </tr>
         <tr>
             <td>Vision Service Plan (VSP)</td>
             <td class="text-center"><i class='fa fa-check'></i></td>
             <td  class="text-center"><i class='fa fa-check'></i></td>

         </tr>
         <tr>
             <td>ACI Legal Plan</td>
             <td class="text-center"></td>
             <td class="text-center"><i class='fa fa-check'></i></td>

         </tr>
         <tr>
             <td>ACI Child Care</td>
             <td class="text-center"></td>
             <td class="text-center"><i class='fa fa-check'></i></td>

         </tr>
         <tr>
             <td>Answer Financial</td>
             <td class="text-center"></td>
             <td class="text-center"><i class='fa fa-check'></i></td>

         </tr>

         <tr class="bg-light text-center">
             <td colspan="6">Tier Cost per Month </td>
         </tr>
         <tr >
            <td class="text-primary"></td>
            <td class="text-center"  *ngFor="let record of matrix; let row = index"><a
                    (click)="View(record.planid, record.fee, 'AFEUSA Membership '+ record.membership); PLAN=+record.planid; LightBox=true; PlanName='AFEUSA Membership '+ record.membership; "
                    [ngClass]="{'btn-success': SelectedPlan === record.planid}"
                    class="btn btn-primary btn-xs btn-sm text-white w100button">${{record.fee}}<br><small>View
                        Plan Details</small></a>
            </td>
          
        </tr>



     </table>


 </div>


<br>



<!--*******************************-->
<!--*******************************-->
<!--*******************************-->
<!--LIGHTBOXES-->
<!--*******************************-->
<!--*******************************-->
<!--*******************************-->
<p-dialog [(visible)]="LightBox" modal="true" [draggable]="false" [closeOnEscape]="true" [responsive]="true" [style]="{width: '80%'}" [baseZIndex]="100">

<p-header style="width:100%">
 <h3>{{PlanName}}</h3>
 <p>Benefits and plans are offered by Dylan Consulting, LLC, a licensed independent insurance agency, and any of its subagents, and underwritten by licensed insurance companies and registered associations. Not all products are available in all states.

 </p>
</p-header>
<div style="overflow-y: scroll; overflow-x: scroll; height:40vh; width: 100%;">
 <div class="table-responsive" style="font-size:14px;">
     <table class="table">
         <tr class="bg-secondary text-white">
             <td style="width:20%">BENEFIT</td>
             <td style="width:80%">
                 DETAILS
             </td>
         </tr>
         <tr>
             <td style="width:20%">1-800 MD</td>
             <td style="width:80%">
                 1-800MD is an industry-leading telehealth company backed by a national network of board-certified, credentialed physicians throughout the United States. 1.800MD physicians diagnose illnesses, recommend treatment plans and prescribe medications, when deemed
                 appropriate, for its members over the telephone or via secure bi-directional video and email.
             </td>
         </tr>
         <tr>
             <td>Rx VALET</td>
             <td>
                 Rx Valet was created to offer a new level of service by assisting members to navigate the challenges of rising prescription drug cost. As consumers we are constantly being bombarded with the loss leaders, low cost promises on one medication, just to be
                 overcharged on another. Insurance plans have recently re-structured co-payments and tiered prescriptions levels that are unfavorable to consumers. We shop and compare all national retailers and online services to find you the lowest
                 price options, and let you decide. Have every prescription sent directly to us and rest assured to have the lowest price service to find you the lowest price options, and let you decide.
             </td>
         </tr>
         <tr>
             <td>VSP VISION SAVINGS PASS</td>
             <td>
                 VSP® Vision Savings PassTM is a discount vision program that offers immediate savings on eye care and eyewear. This is not an insurance plan.
             </td>
         </tr>
         <tr *ngIf="PLAN >= 2">
             <td>ACI LEGAL</td>
             <td>
                 Members and family members are eligible to receive legal and financial consultation for an unlimited number of issues at no cost. 24/7 access is also available to a secured site for information, resources and personal documents.
                 <br> Support is available for any legal need including:
                 <br> <br>
                 <ul style="font-size: 14px;">

                     <li>Document Preparation</li>

                     <li>Civil/Consumer/Criminal Issues</li>

                     <li>Domestic/Family Disputes</li>
                     <li>Real Estate</li>
                     <li> Landlord/Tenant</li>

                     <li>Motor Vehicle</li>
                     <li>Immigration</li>

                     <li>Estate Planning</li>
                     <li>Will Preparation</li>

                 </ul>

                 <br> Services include initial assessment and phone consultation regarding the issue. If additional assistance is needed and the attorney is retained, the member or family member will receive a 25% discount on continued services.

             </td>
         </tr>
         <tr *ngIf="PLAN >= 2">
             <td>ACI CLINICAL & WORK-LIFE BENEFITS</td>
             <td>
                 ACI Specialty Benefits is your partner for emotional wellness, and offers best-in-class Member Assistance Program (MAP) solutions designed to improve member engagement and emotional health. reduce stress and make life easier. Through ACI’s network of
                 clinicians, life coaches and work-life specialists, members receive comprehensive support for any personal or professional need.
             </td>
         </tr>
         <tr *ngIf="PLAN >= 2">
             <td>ANSWER FINANCIAL</td>
             <td>
                 Answer Financial offers shoppers the opportunity to compare prices and purchase auto and home insurance from top companies through its award-winning website or customer call center.
             </td>
         </tr>

         <tr class="text-left bg-secondary text-white">

             <td colspan="2">BENEFITS INCLUDED IN EVERY MEMBERSHIP LEVEL</td>
         </tr>
         <tr>
             <td colspan="2">
                 <table style="height: 200px; width: 100%; margin-left: auto; margin-right: auto;">
                     <tbody>
                         <tr style="height: 25px;" class="bg-light">
                             <th style="width: 90px; height: 6px; text-align: left; vertical-align: top;">
                                 Health &amp; Well-Being</th>
                             <th style="width: 90px; height: 6px; text-align: left; vertical-align: top;">
                                 Travel &amp; Auto</th>
                             <th style="width: 90px; height: 6px; text-align: left; vertical-align: top;">
                                 Home &amp; Family&nbsp;</th>
                             <th style="width: 90px; height: 6px; text-align: left; vertical-align: top;">
                                 Financial, HR &amp; Credit</th>
                             <th style="width: 90px; height: 6px; text-align: left; vertical-align: top;">
                                 Business &amp; Office
                             </th>
                             <th style="width: 90px; height: 6px; text-align: left; vertical-align: top;">
                                 Ed/Business Coaching</th>
                             <th style="width: 90px; height: 6px; text-align: left; vertical-align: top;">
                                 Legal &amp; Identity Shield</th>
                         </tr>
                         <tr style="height: 6px;">
                             <td style="width: 76px; height: 6px; text-align: left; vertical-align: top;">
                                 <ul>
                                     <li>Burnalong</li>
                                     <li>Needy-Meds</li>
                                     <li>American Hearing Benefits</li>
                                 </ul>
                             </td>
                             <td style="width: 103px; height: 6px; text-align: left; vertical-align: top;">
                                 <ul>
                                     <li>Avis/Budget Car Rental</li>
                                     <li>Sky Med Emergency Travel</li>
                                     <li>Sky Med Travel</li>
                                     <li>Car Chex</li>
                                     <li>True Car</li>
                                 </ul>
                             </td>
                             <td style="width: 90px; height: 6px; text-align: left; vertical-align: top;">
                                 <ul>
                                     <li>Costo Wholesale</li>
                                     <li>Benefit Hub</li>
                                     <li>Home Chef</li>
                                     <li>Long Term Care Rcs</li>
                                     <li>Griswold Home Caref</li>
                                     <li></li>
                                 </ul>
                             </td>
                             <td style="width: 90px; height: 6px; text-align: left; vertical-align: top;">
                                 <ul>
                                     <li>Gusto</li>
                                     <li>Take Charge America</li>
                                     <li>The Credit Clinic</li>
                                     <li>EJ Pro Lease</li>
                                     <li>First American</li>
                                 </ul>
                             </td>
                             <td style="width: 90px; height: 6px; text-align: left; vertical-align: top;">
                                 <ul>
                                     <li>Eric's Jobs</li>
                                     <li>Trapp Tech</li>
                                     <li>UPS Deliver</li>
                                     <li>Office Depot</li>
                                     <li>E6 Agency</li>
                                     <li>Newsletter Pro</li>
                                 </ul>
                             </td>
                             <td style="width: 90px; height: 6px; text-align: left; vertical-align: top;">
                                 <ul>
                                     <li>Genius Network</li>
                                     <li>Big Results Academy</li>
                                     <li>goSmallBiz.com</li>
                                     <li>The Messenger Institute</li>
                                     <li>SocialCore Marketing</li>
                                     <li>Joel Weldon</li>
                                     <li>Empowerd Couples Univ</li>
                                 </ul>
                             </td>
                             <td style="width: 118px; height: 6px; text-align: left; vertical-align: top;">
                                 <ul>
                                     <li>InfoAmor by Allstate</li>
                                     <li>Legal Shield</li>
                                     <li>ID Shield</li>
                                 </ul>
                             </td>
                         </tr>
                     </tbody>
                 </table>

             </td>
         </tr>
     </table>
 </div>

</div>
<p-footer>
 <div class="text-right">
     <button (click)="SetPlan()" class="btn btn-danger">+ Add to Cart (${{SelectedAmount}} per month)</button>
 </div>
</p-footer>
</p-dialog>