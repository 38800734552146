<div class="row">
    <div class="col-md-8">
        <h4>{{APIresult[0]?.agentinfo.firstname}} {{APIresult[0]?.agentinfo.middlename}}
            {{APIresult[0]?.agentinfo.lastname}}</h4>

    </div>
    <div class="col-md-4 text-right">
        <button class="btn  btn-primary text-white" (click)="newNote=true" style="margin-right: 10px;">Manage
            Notes</button>
    </div>
</div>

<hr>

<div>


    <p-tabView>
        <!--
            ******************************
            AGENT INFORMATION TAB
            ******************************
        -->
        <p-tabPanel header="Agent Info" class="tabfont" [headerStyle]="{'font-size':'14px', 'margin-top':'2px'}">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                    <div class="bShadow-5">
                        <div style="min-height: 340px;">
                            <h6>Agent</h6>
                            <hr>
                            <table class="w100">
                                <tr class="spaceUnder">
                                    <td class="text-info" style="width:150px;">
                                        Signup Day:
                                    </td>
                                    <td class="text-black">
                                        {{APIresult[0]?.esignature.signupdate}} (SMT)
                                    </td>
                                </tr>
                                <tr class="spaceUnder">
                                    <td class="text-info">
                                        Agent ID:
                                    </td>
                                    <td class="text-black">
                                        {{APIresult[0]?.agentinfo.plid}}
                                    </td>
                                </tr>
                                <tr class="spaceUnder">
                                    <td class="text-info">
                                        Full Name:
                                    </td>
                                    <td class="text-black">
                                        {{APIresult[0]?.agentinfo.firstname}} {{APIresult[0]?.agentinfo.middlename}}
                                        {{APIresult[0]?.agentinfo.lastname}}
                                    </td>
                                </tr>
                                <tr class="spaceUnder">
                                    <td class="text-info">
                                        Address:
                                    </td>
                                    <td class="text-black">
                                        {{APIresult[0]?.agentinfo.address}} {{APIresult[0]?.agentinfo.city}},
                                        {{APIresult[0]?.agentinfo.state}} {{APIresult[0]?.agentinfo.zip}}
                                    </td>
                                </tr>
                                <tr class="spaceUnder">
                                    <td class="text-info">
                                        Cell Phone:
                                    </td>
                                    <td class="text-black">
                                        {{APIresult[0]?.agentinfo.cellphone}}
                                    </td>
                                </tr>
                                <tr class="spaceUnder">
                                    <td class="text-info">
                                        Business Phone:
                                    </td>
                                    <td class="text-black">
                                        {{APIresult[0]?.agentinfo.businessphone}}
                                    </td>
                                </tr>
                                <tr class="spaceUnder">
                                    <td class="text-info">
                                        Fax Number:
                                    </td>
                                    <td class="text-black">
                                        {{APIresult[0]?.agentinfo.faxnumber}}
                                    </td>
                                </tr>
                                <tr class="spaceUnder">
                                    <td class="text-info">
                                        Email Address:
                                    </td>
                                    <td class="text-black">
                                        {{APIresult[0]?.agentinfo.email}}
                                    </td>
                                </tr>
                                <tr class="spaceUnder" *ngIf="AllowViewAgentPassword">
                                    <td class="text-black" colspan="2">
                                        <br>
                                        <button class="btn  btn-warning text-white" (click)="RevealPassword()"
                                            style="margin-right: 10px;">Reveal Password</button>
                                        <button class="btn  btn-warning text-white" (click)="ResendEmail()"
                                            style="margin-right: 10px;" *ngIf="status==1 || status==50">Resend
                                            Email</button>
                                        <button class="btn  btn-danger text-white" (click)="EditMyAgent()"
                                            *ngIf="status==1 && !ownPL">Edit &
                                            Update</button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>




                <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                    <div class="bShadow-5">
                        <div style="min-height: 340px;">
                            <h6>Products and Grid</h6>
                            <hr>
                            <table class="w100">
                                <tr class="spaceUnder">
                                    <td></td>
                                    <td class="text-title">Year 1</td>
                                    <td class="text-title">Year 2+</td>
                                </tr>
                                <tr class="spaceUnder" *ngFor="let product of APIresult[0]?.products ">
                                    <td class="text-info">{{product.RealName}}</td>
                                    <td class="text-black">{{product.Y1}}%</td>
                                    <td class="text-black">{{product.Y2}}%</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>


                <div class="col-12 col-sm-12 col-md-6 col-lg-4" *ngIf="AllowCreate">
                    <div class="bShadow-5" *ngIf="CurrentAgent != CurrentPL">
                        <div style="min-height: 340px;">
                            <h6>Miscellaneous</h6>
                            <hr>
                            <table class="w100">
                                <tr class="spaceUnder">
                                    <td class="text-info" style="width:150px;">
                                        Sponsor:
                                    </td>
                                    <td class="text-black">
                                        ({{APIresult[0]?.agentinfo.SponsorPL}}) -
                                        {{APIresult[0]?.agentinfo.SponsorFname}}
                                        {{APIresult[0]?.agentinfo.SponsorLname}}
                                    </td>
                                </tr>
                                <tr class="spaceUnder">
                                    <td class="text-info">
                                        Type of Business:
                                    </td>
                                    <td class="text-black">
                                        {{APIresult[0]?.agentinfo.licTypeOfBusiness}}
                                    </td>
                                </tr>
                                <tr class="spaceUnder">
                                    <td class="text-info">
                                        License:
                                    </td>
                                    <td class="text-black">
                                        {{APIresult[0]?.agentinfo.licTypeInsuranceLicense}}
                                    </td>
                                </tr>
                                <tr class="spaceUnder">
                                    <td class="text-info">
                                        Insurance #:
                                    </td>
                                    <td class="text-black">
                                        {{APIresult[0]?.agentinfo.licResidentInsuranceLicense}}
                                    </td>
                                </tr>
                                <tr class="spaceUnder">
                                    <td class="text-info">
                                        Resident State:
                                    </td>
                                    <td class="text-black">
                                        {{APIresult[0]?.agentinfo.licResidentState}}
                                    </td>
                                </tr>
                                <tr class="spaceUnder">
                                    <td class="text-info">
                                        NPN Number:
                                    </td>
                                    <td class="text-black">
                                        {{APIresult[0]?.agentinfo.licNPMnumber}}
                                    </td>
                                </tr>
                                <tr class="spaceUnder">
                                    <td class="text-info">
                                        E&O Coverage?
                                    </td>
                                    <td class="text-black">
                                        {{APIresult[0]?.agentinfo.eoCoverage}}
                                    </td>
                                </tr>
                                <tr class="spaceUnder">
                                    <td class="text-info">
                                        $ and Expiration:
                                    </td>
                                    <td class="text-black">
                                        ${{FormatMoney(APIresult[0]?.agentinfo.eoAmount)}} -
                                        {{APIresult[0]?.agentinfo.eoExp}}
                                    </td>
                                </tr>
                                <tr class="spaceUnder" *ngIf="adm && status==1">
                                    <td class="text-info">
                                        Platform Model:
                                    </td>
                                    <td class="text-black">
                                        <select class="form-control" [(ngModel)]="BackofficeType"
                                            (change)="ChangeAgencyType($event.target.value)">
                                            <option value="0">Standard Agent</option>
                                            <option value="2">GA</option>
                                            <option value="1">IMO / Agency</option>
                                        </select>
                                    </td>
                                </tr>
                            </table>

                        </div>
                    </div>

                </div>
            </div>
            <br><br>
            <div *ngIf="APIresult[0]?.agentinfo.status !=50">
                <div *ngIf="adm">

                    <div class="row">

                        <div class="col-12">
                            <div class="bShadow-5">
                                <div style="height: 105px;">
                                    <h6>Switch Agent Status</h6>
                                    <hr>
                                    <div class="row">
                                        <div class="col-12 col-md-6">
                                            Current Status:<strong>
                                                <div class="text-info" *ngIf="APIresult[0]?.agentinfo.status==1">
                                                    ENABLED</div>
                                                <div class="text-danger" *ngIf="APIresult[0]?.agentinfo.status==99">
                                                    DISABLED /
                                                    PERMANENTLY SUSPENDED</div>
                                                <div class="text-warning" *ngIf="APIresult[0]?.agentinfo.status==0">
                                                    ACTIVATION PENDING
                                                </div>
                                            </strong>
                                        </div>
                                        <div class="col-12 col-md-6 text-right">
                                            <button class="btn btn-success text-white" style="margin-right: 10px;"
                                                (click)="EnableAgent()"
                                                *ngIf="APIresult[0]?.agentinfo.status ==0 || APIresult[0]?.agentinfo.status ==99">Enable
                                                Agent</button>
                                            <button class="btn btn-danger text-white" style="margin-right: 10px;"
                                                (click)="DisableAgent()" *ngIf="APIresult[0]?.agentinfo.status ==1">
                                                Disable Agent</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <br>
                            <div class="bShadow-5">
                                <div style="min-height: 105px;">
                                    <h6>Files available for download</h6>
                                    <div class="table-responsive">
                                        <table class="table">
                                            <tr>
                                                <td>Document Code</td>
                                                <td>Date Processes</td>
                                                <td>File Description</td>
                                                <td></td>
                                            </tr>
                                            <tr *ngFor="let file of APIresult[0]?.files">
                                                <td>{{file.documenttype}}</td>
                                                <td>{{file.datetime}}</td>
                                                <td>{{file.description}}</td>
                                                <td class="text-right">
                                                    <a class="btn btn-sm btn-warning text-white mousepointer"
                                                        href="{{endpoint}}afeapi/desk/corporate/getdocument.aspx?did={{file.documentid}}&uk={{file.userkey}}"
                                                        target="_blank">Download</a>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>

                                    <a *ngIf="eo" href="{{eo}}" class="btn btn-warning text-white text-left"
                                        style="margin-right: 10px" target="_blank"><i class="fa fa-download"></i> E&O
                                        Coverage</a>

                                    <a *ngIf="agreement" href="{{agreement}}"
                                        class="btn text-white btn-warning text-left" style="margin-right: 10px"
                                        target="_blank"><i class="fa fa-download"></i> Sales Agreement</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>

                </div>
                <div class="bShadow-5">
                    <div style="height: 105px;">
                        <h6>Electronic Signature</h6>
                        <hr> Electronically signed from IP address {{APIresult[0]?.esignature.ipaddress}} on
                        {{APIresult[0]?.esignature.signupdate}} SMT. Entered and confirmed signature: <span
                            class="text-danger">{{APIresult[0]?.esignature.eSignature}}</span>
                    </div>
                </div>


                <br><br>
            </div>





            <div class="bShadow-5" *ngIf="adm">
                <div style="min-height: 320px;">
                    <h6>Background Questions</h6>
                    <hr>

                    <div class="table-responsive">
                        <table class="table table-responsive table-borderless">
                            <tr>
                                <td class="text-left text-title" [ngClass]="{'text-danger': Q1 === '1'}">
                                    1. Are you now or have you ever been included in litigation with an insurance
                                    company?
                                    <br><strong>Answer: <span *ngIf="Q1=='1'">YES</span><span *ngIf="Q1=='0'">NO</span>
                                    </strong>
                                    <textarea [(ngModel)]="A1" class="form-control" rows="5"
                                        placeholder="Please explain your affirmative answer for the above question"
                                        *ngIf="Q1=='1'"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left text-title" [ngClass]="{'text-danger': Q2 === '1'}">
                                    2. Do you currently have a debit balance with any insurance company?
                                    <br><strong>Answer: <span *ngIf="Q2=='1'">YES</span><span *ngIf="Q2=='0'">NO</span>
                                    </strong>
                                    <textarea [(ngModel)]="A2" class="form-control" rows="5"
                                        placeholder="Please explain your affirmative answer for the above question"
                                        *ngIf="Q2=='1'"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left text-title" [ngClass]="{'text-danger': Q3 === '1'}">

                                    3. Have you ever had your insurance or securities license suspended or revoked?
                                    <br><strong>Answer: <span *ngIf="Q3=='1'">YES</span><span *ngIf="Q3=='0'">NO</span>
                                    </strong>
                                    <textarea [(ngModel)]="A3" class="form-control" rows="5"
                                        placeholder="Please explain your affirmative answer for the above question"
                                        *ngIf="Q3=='1'"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left text-title" [ngClass]="{'text-danger': Q4 === '1'}">

                                    4a. Have you ever been charged with, convicted of, or plead guilty or nolo
                                    contendere ("no contest") to a felony of any kind?
                                    <br><strong>Answer: <span *ngIf="Q4=='1'">YES</span><span *ngIf="Q4=='0'">NO</span>
                                    </strong>
                                    <textarea [(ngModel)]="A4" class="form-control" rows="5"
                                        placeholder="Please explain your affirmative answer for the above question"
                                        *ngIf="Q4=='1'"></textarea>
                                </td>

                            </tr>


                            <tr>
                                <td class="text-left text-title" [ngClass]="{'text-danger': Q5 === '1'}">

                                    4b. Have you ever been charged with, convicted of, or plead guilty or nolo
                                    contendere ("no contest") to a misdemeanor involving insurance or fraud?
                                    <br><strong>Answer: <span *ngIf="Q5=='1'">YES</span><span *ngIf="Q5=='0'">NO</span>
                                    </strong>
                                    <textarea [(ngModel)]="A5" class="form-control" rows="5"
                                        placeholder="Please explain your affirmative answer for the above question"
                                        *ngIf="Q5=='1'"></textarea>
                                </td>

                            </tr>

                            <tr>
                                <td class="text-left text-title" [ngClass]="{'text-danger': Q6 === '1'}">
                                    5a. Has any domestic or foreign court ever found you guilty in connection with any
                                    insurance or investment-related activity?
                                    <br><strong>Answer: <span *ngIf="Q6=='1'">YES</span><span *ngIf="Q6=='0'">NO</span>
                                    </strong>
                                    <textarea [(ngModel)]="A6" class="form-control" rows="5"
                                        placeholder="Please explain your affirmative answer for the above question"
                                        *ngIf="Q6=='1'"></textarea>
                                </td>

                            </tr>

                            <tr>
                                <td class="text-left text-title" [ngClass]="{'text-danger': Q7 === '1'}">
                                    5b. Has any domestic or foreign court ever found that you violated any
                                    insurance-related statutes or regulations?
                                    <br><strong>Answer: <span *ngIf="Q7=='1'">YES</span><span *ngIf="Q7=='0'">NO</span>
                                    </strong>
                                    <textarea [(ngModel)]="A7" class="form-control" rows="5"
                                        placeholder="Please explain your affirmative answer for the above question"
                                        *ngIf="Q7=='1'"></textarea>
                                </td>

                            </tr>

                            <tr>
                                <td class="text-left text-title" [ngClass]="{'text-danger': Q8 === '1'}">

                                    6a. Has any Federal regulatory agency or any state regulatory agency or foreign
                                    financial regulatory authority ever found you to have made a false statement or
                                    omission or been dishonest, unfair or unethical?
                                    <br><strong>Answer: <span *ngIf="Q8=='1'">YES</span><span *ngIf="Q8=='0'">NO</span>
                                    </strong>
                                    <textarea [(ngModel)]="A8" class="form-control" rows="5"
                                        placeholder="Please explain your affirmative answer for the above question"
                                        *ngIf="Q8=='1'"></textarea>
                                </td>
                            </tr>

                            <tr>
                                <td class="text-left text-title" [ngClass]="{'text-danger': Q9 === '1'}">

                                    6b. Has any Federal regulatory agency or any state regulatory agency or foreign
                                    financial regulatory authority ever found you to have been involved in a violation
                                    of insurance or investment regulations or statutes?
                                    <br><strong>Answer: <span *ngIf="Q9=='1'">YES</span><span *ngIf="Q9=='0'">NO</span>
                                    </strong>
                                    <textarea [(ngModel)]="A9" class="form-control" rows="5"
                                        placeholder="Please explain your affirmative answer for the above question"
                                        *ngIf="Q9=='1'"></textarea>
                                </td>

                            </tr>

                            <tr>
                                <td class="text-left text-title" [ngClass]="{'text-danger': Q10 === '1'}">

                                    6c. Has any Federal regulatory agency or any state regulatory agency or foreign
                                    financial regulatory authority ever found you to have been the cause of any
                                    insurance or investment-related business having its authorization to do business
                                    denied, suspended,
                                    revoked or restricted?
                                    <br><strong>Answer: <span *ngIf="Q10=='1'">YES</span><span
                                            *ngIf="Q10=='0'">NO</span> </strong>
                                    <textarea [(ngModel)]="A10" class="form-control" rows="5"
                                        placeholder="Please explain your affirmative answer for the above question"
                                        *ngIf="Q10=='1'"></textarea>
                                </td>


                            </tr>

                            <tr>
                                <td class="text-left text-title" [ngClass]="{'text-danger': Q11 === '1'}">

                                    6d. Has any Federal regulatory agency or any state regulatory agency or foreign
                                    financial regulatory authority ever entered an order against you in connection with
                                    any insurance or investment-related activity?
                                    <br><strong>Answer: <span *ngIf="Q11=='1'">YES</span><span
                                            *ngIf="Q11=='0'">NO</span> </strong>
                                    <textarea [(ngModel)]="A11" class="form-control" rows="5"
                                        placeholder="Please explain your affirmative answer for the above question"
                                        *ngIf="Q11=='1'"></textarea>
                                </td>

                            </tr>

                            <tr>
                                <td class="text-left text-title" [ngClass]="{'text-danger': Q12 === '1'}">

                                    6e. Has any Federal regulatory agency or any state regulatory agency or foreign
                                    financial regulatory authority ever denied, suspended, or revoked your registration
                                    or license or otherwise prevented you from associating with any insurance or
                                    investment-related
                                    business, or disciplined you by restricting your activities?
                                    <br><strong>Answer: <span *ngIf="Q12=='1'">YES</span><span
                                            *ngIf="Q12=='0'">NO</span> </strong>
                                    <textarea [(ngModel)]="A12" class="form-control" rows="5"
                                        placeholder="Please explain your affirmative answer for the above question"
                                        *ngIf="Q12=='1'"></textarea>
                                </td>

                            </tr>

                            <tr>
                                <td class="text-left text-title" [ngClass]="{'text-danger': Q13 === '1'}">

                                    7a. Have you ever been the subject of any insurance or investment-related, consumer-
                                    initiated complaint or proceeding that alleged compensatory damages of $2,500 or
                                    more, or found fraud or the wrongful taking of property?
                                    <br><strong>Answer: <span *ngIf="Q13=='1'">YES</span><span
                                            *ngIf="Q13=='0'">NO</span> </strong>
                                    <textarea [(ngModel)]="A13" class="form-control" rows="5"
                                        placeholder="Please explain your affirmative answer for the above question"
                                        *ngIf="Q13=='1'"></textarea>
                                </td>

                            </tr>

                            <tr>
                                <td class="text-left text-title" [ngClass]="{'text-danger': Q14 === '1'}">

                                    7b. Have you ever been the subject of any insurance or investment-related, consumer-
                                    initiated complaint or proceeding that was settled or decided against you for $1,000
                                    or more, or found fraud or the wrongful taking of property?
                                    <br><strong>Answer: <span *ngIf="Q14=='1'">YES</span><span
                                            *ngIf="Q14=='0'">NO</span> </strong>
                                    <textarea [(ngModel)]="A14" class="form-control" rows="5"
                                        placeholder="Please explain your affirmative answer for the above question"
                                        *ngIf="Q14=='1'"></textarea>
                                </td>

                            </tr>

                            <tr>
                                <td class="text-left text-title" [ngClass]="{'text-danger': Q15 === '1'}">

                                    8. Are you now the subject of any complaint, investigation, or proceeding that could
                                    result in a "yes" answer to questions 1 - 7?
                                    <br><strong>Answer: <span *ngIf="Q15=='1'">YES</span><span
                                            *ngIf="Q15=='0'">NO</span> </strong>
                                    <textarea [(ngModel)]="A15" class="form-control" rows="5"
                                        placeholder="Please explain your affirmative answer for the above question"
                                        *ngIf="Q15=='1'"></textarea>
                                </td>


                            </tr>



                        </table>
                    </div>

                </div>
            </div>



        </p-tabPanel>


        <!--
            ******************************
            MEMBERS TAB
            ******************************
        -->
        <p-tabPanel header="Members" *ngIf="AllowViewMembers" class="tabfont"
            [headerStyle]="{'font-size':'14px', 'margin-top':'2px'}">

            <app-clientsdashboard [PL]="ID"></app-clientsdashboard>

            <app-contracted [PL]="ID"></app-contracted>

        </p-tabPanel>
        <!--
            ******************************
            DOWNLINE TAB
            ******************************
        -->
        <p-tabPanel header="Downline" *ngIf="AllowDownline" class="tabfont"
            [headerStyle]="{'font-size':'14px', 'margin-top':'2px'}">

            <button (click)="Me()" class="btn btn-outline-dark btn-sm"
                [ngClass]="{ 'btn-dark text-white': DownlineType == 'Direct Downline' }"
                style="margin-right: 10px; margin-top: 10px">
                Direct Downline
            </button>
            <button (click)="Full()" class="btn btn-outline-dark btn-sm"
                [ngClass]="{ 'btn-dark text-white': DownlineType == 'Full Downline' }"
                style="margin-right: 10px; margin-top: 10px">
                Full Downline
            </button>
            <button *ngIf="AllowCreate && status =='1'" (click)="newAgent=true" class="btn btn-outline-dark btn-sm"
                style="margin-right: 10px; margin-top: 10px">
                Create a New Agent Under {{APIresult[0]?.agentinfo.firstname}} {{APIresult[0]?.agentinfo.lastname}}
            </button>
            <br /><br />
            <div class="alert alert-info">
                <span style="padding-top: 14px">{{ TotalAgents }} Agents Found for {{ DownlineType }}</span>
            </div>

            <ol class="breadcrumb" style="width: 100%; background-color: white;" *ngIf="this.APIresult4">
                <li *ngFor="let agent of this.APIresult4[0].upline" class="breadcrumb-item">
                    <img src="assets/images/icouser.png" style="margin-right: 5px" pTooltip="Summary" height="15px" />
                    <a class="link text-primary mousepointer" style="font-size: 11px;"
                        (click)="SelectAgent(agent.id, 'n')">{{ agent.fullname }}</a>
                </li>
            </ol>

            <div class="minheight">
                <p-table #dt [value]="AgentsList" [rows]="100" [showCurrentPageReport]="true"
                    [rowsPerPageOptions]="[100, 250, 500, 1000]" styleClass="p-datatable-customers" [paginator]="true"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} agents" [filterDelay]="0"
                    [globalFilterFields]="[
                    'fullname',
                    'email',
                    'phone',
                    'downline',
                    'companyname',
                    'signupdate',
                    'city',
                    'state',
                    'products',
                    'status',
                    'policies',
                    'statustext'
                  ]" [resizableColumns]="true" [autoLayout]="true">
                    <ng-template pTemplate="header">
                        <tr class="FontRow" style="font-size: 11px !important">
                            <th></th>
                            <th [pSortableColumn]="'companyname'">Company Name</th>
                            <th [pSortableColumn]="'fullname'">Agent Name</th>
                            <th [pSortableColumn]="'email'">Email</th>
                            <th [pSortableColumn]="'phone'">Phone</th>
                            <th [pSortableColumn]="'downline'">Direct Agents</th>
                            <th [pSortableColumn]="'signupdate'">Created</th>
                            <th [pSortableColumn]="'products'">Products</th>
                            <th [pSortableColumn]="'city'">City</th>
                            <th [pSortableColumn]="'state'">State</th>
                            <th [pSortableColumn]="'policies'">Sales</th>
                            <th [pSortableColumn]="'statustext'">Status</th>
                            <th [pSortableColumn]="''"></th>
                        </tr>
                        <tr>
                            <th></th>
                            <th>
                                <input class="form-control" pInputText type="text"
                                    (input)="dt.filter($event.target.value, 'companyname', 'contains')" />
                            </th>
                            <th>
                                <input class="form-control" pInputText type="text"
                                    (input)="dt.filter($event.target.value, 'fullname', 'contains')" />
                            </th>
                            <th>
                                <input class="form-control" pInputText type="text"
                                    (input)="dt.filter($event.target.value, 'email', 'contains')" />
                            </th>

                            <th>
                                <input class="form-control" pInputText type="text"
                                    (input)="dt.filter($event.target.value, 'phone', 'contains')" />
                            </th>

                            <th>
                                <input class="form-control" pInputText type="text"
                                    (input)="dt.filter($event.target.value, 'downline', 'equals')" />
                            </th>

                            <th>
                                <input class="form-control" pInputText type="text"
                                    (input)="dt.filter($event.target.value, 'signupdate', 'contains')" />
                            </th>

                            <th>
                                <input class="form-control" pInputText type="text"
                                    (input)="dt.filter($event.target.value, 'products', 'equal')" />
                            </th>

                            <th>
                                <input class="form-control" pInputText type="text"
                                    (input)="dt.filter($event.target.value, 'city', 'contains')" />
                            </th>

                            <th>
                                <input class="form-control" pInputText type="text"
                                    (input)="dt.filter($event.target.value, 'state', 'contains')" />
                            </th>

                            <th>
                                <input class="form-control" pInputText type="text"
                                    (input)="dt.filter($event.target.value, 'sales', 'equals')" />
                            </th>

                            <th>
                                <select class="form-control"
                                    (input)="dt.filter($event.target.value, 'statustext', 'equals')">
                                    <option value="">List All</option>
                                    <option value="Active">Active</option>
                                    <option value="Suspended">Suspended</option>
                                    <option value="Pending">Pending</option>
                                    <option value="Agent Pending">Agent Pending</option>
                                </select>
                            </th>

                            <th></th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-AgentsList let-rowIndex="rowIndex">
                        <tr class="FontRow f13" style="cursor: pointer; font-size: 11px" [ngStyle]="{
                        backgroundColor:
                          AgentsList.status == 0
                            ? '#FFF2B5'
                            : AgentsList.status == 50
                            ? '#EAEAEA'
                            : AgentsList.status == 99
                            ? '#FFE0E0'
                            : 'transparent'
                      }">
                            <td class="SmallFont">{{ rowIndex + 1 }}</td>
                            <td>{{ AgentsList.companyname }}</td>
                            <td class="SmallFont">
                                <a (click)="SelectAgent(AgentsList.id, 'n')" class="mousepointer">
                                    <img src="assets/images/icolupa.png" style="margin-right: 5px" pTooltip="Summary" />
                                    <strong>{{ AgentsList.fullname.toUpperCase() }}</strong>
                                </a>
                            </td>
                            <td class="SmallFont">
                                <a href="mailto:{{ AgentsList.email }}"
                                    pTooltip="Send {{ AgentsList.fullname }} an email">{{ AgentsList.email }}</a>
                            </td>
                            <td class="SmallFont">
                                <a href="tel:{{ AgentsList.phone }}" pTooltip="Call {{ AgentsList.fullname }}">
                                    {{ AgentsList.phone }}
                                </a>
                            </td>
                            <td class="SmallFont">
                                <div *ngIf="AgentsList.downline > 0">
                                    <a (click)="ListAgents(AgentsList.id, 'direct')" class="mouserpointer"
                                        pTooltip="View Hierarchy">
                                        <img src="assets/images/icotreeview.png" style="margin-right: 5px" />
                                    </a>
                                    {{ AgentsList.downline }} agents
                                </div>
                            </td>

                            <td>{{ AgentsList.signupdate }}</td>
                            <td>
                                <a class="mousepointer" (click)="ShowProducts(AgentsList.id)"
                                    *ngIf="AgentsList.products > 0">
                                    <img src="assets/images/icoproducts.png" style="margin-right: 5px" />
                                    {{ AgentsList.products }}
                                </a>
                            </td>
                            <td>{{ AgentsList.city }}</td>
                            <td>{{ AgentsList.state }}</td>
                            <td>{{ AgentsList.policies }}</td>
                            <td>{{ AgentsList.statustext }}</td>
                            <td class="SmallFont text-right">

                                <a>
                                    <img src="assets/images/icolupa.png" style="margin-right: 5px" pTooltip="Summary"
                                        (click)="SelectAgent(AgentsList.id, 'n')" />
                                </a>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>

        </p-tabPanel>

        <!--
            ******************************
            NEW AGENT TAB
            ******************************
        -->

        <p-tabPanel header="Activity Log" *ngIf="adm && TempHideLog" class="tabfont"
            [headerStyle]="{'font-size':'14px', 'margin-top':'2px'}">
            <h5>Agent Activity Log</h5>
            <hr>
            <div class="alert alert-info">
                This platform tracks down almost every activity performed by an agent, and the changes at an account
                level. This activity log shows all the activity for this agent. Select a filter below and click the
                "View Activity Log" button.
            </div>

            <h6>List Selection</h6>
            <hr>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-3 col-lg-2">
                    <div class="bShadow-5">
                        <!--Timegrame-->
                        <strong>Timeframe</strong>
                        <hr>
                        From Date:
                        <input type="text" class="form-control" mask="M0/d0/0000" [dropSpecialCharacters]="false"
                            [(ngModel)]="logfrom" placeholder="mm/dd/yyyy">
                        <div style="padding-top: 10px;">To Date:</div>
                        <input type="text" class="form-control" mask="M0/d0/0000" [dropSpecialCharacters]="false"
                            [(ngModel)]="logto" placeholder="mm/dd/yyyy">
                        <br>
                        <!--Action-->
                        <strong>Action</strong>
                        <hr>
                        <select class="form-control" [(ngModel)]="logaction">
                            <option value="0">All</option>
                            <option *ngFor="let action of LogActions" [value]="action.ActivityCode">
                                {{action.ActionName}}</option>
                        </select>
                        <br>
                        <!--Originating Agent-->
                        <strong>Action by</strong>
                        <hr>
                        <select class="form-control" [(ngModel)]="logactionby">
                            <option value="0">All</option>
                            <option value="1">Agent himself</option>
                            <option value="2">Upline</option>
                        </select>
                        <br>

                        <button class="btn btn-sm btn-warning text-white w100" (click)="ViewLog()">View Activity
                            Log</button>
                        <br><br>
                        <small>* Up to 2,000 records will be displayed</small>
                        <br><br>
                    </div>
                    <br>

                </div>
                <div class="col-12 col-sm-6 col-md-9 col-lg-10">
                    <div class="bShadow-5" *ngIf="logHeaders">
                        <div class="table-responsive" style="min-height: 475px;">
                            <table class="table">
                                <tr style="font-weight: 600;">
                                    <td *ngFor="let header of logHeaders">{{header.header}}</td>
                                    <td></td>
                                </tr>
                                <tr *ngFor="let record of logRecords"
                                    [style.background]="record.activitycode==7? '#ffd4da': 'white'">
                                    <td class="text-muted">{{record.firstname}}</td>
                                    <td class="text-muted">{{record.lastname}}</td>
                                    <td class="text-muted">{{record.datetime}}</td>
                                    <td class="text-muted">{{record.ActionName}}</td>
                                    <td class="text-muted">{{record.actiontitle}}</td>
                                    <td class="text-muted text-right"><button (click)="OpenLog(record.activityid)"
                                            class="btn btn-primary btn-xs"
                                            style="margin-top: 0px; margin-bottom: 0px;">Open Log</button></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </p-tabPanel>

    </p-tabView>
</div>


<p-dialog [(visible)]="ShowProductsBox" modal="true" [draggable]="false" [closeOnEscape]="true" [responsive]="true"
    [style]="{ width: '800px' }" [baseZIndex]="1">
    <p-header style="width: 100%">
        <h3>Products Available</h3>
    </p-header>

    <div class="table-responsive">
        <table class="table">
            <tr>
                <td>
                    <strong>PRODUCT NAME</strong>
                </td>
                <td>
                    <strong>YEAR 1</strong>
                </td>
                <td>
                    <strong>YEAR 2+</strong>
                </td>
                <td>
                    <strong>SELLING SINCE</strong>
                </td>
            </tr>
            <tr *ngFor="let product of ProductsList">
                <td>
                    {{ product.productname }}
                </td>
                <td>{{ product.year1 }}%</td>
                <td>{{ product.year2 }}%</td>
                <td>
                    {{ product.sellingsince }}
                </td>
            </tr>
        </table>
    </div>
</p-dialog>



<!--Resend email dialog-->

<p-dialog [(visible)]="ResendEmailDialog" modal="true" appendToBody="true" [draggable]="true" [closeOnEscape]="false"
    [responsive]="true" [style]="{ width: '800px' }" [baseZIndex]="1">
    <p-header style="width: 100%">
        <h3>Resend Welcome Email</h3>
    </p-header>


    <div class="table-responsive">
        <table class="table">
            <tr>
                <td style="width: 50px;">
                    To:
                </td>
                <td><input type="text" class="form-control" [(ngModel)]="EmailTo"></td>
            </tr>
            <tr>
                <td style="width: 50px;">
                    Cc:
                </td>
                <td><input type="text" class="form-control" [(ngModel)]="EmailCc"></td>
            </tr>
            <tr>
                <td style="width: 50px;">
                    Bcc:
                </td>
                <td><input type="text" class="form-control" [(ngModel)]="EmailBcc"></td>
            </tr>
            <tr>
                <td colspan="2">
                    <angular-editor id="EmailContent" name="EmailContent" [placeholder]=" 'Enter text here...' "
                        [config]="editorConfig " [(ngModel)]="EmailContent"></angular-editor>
                </td>
            </tr>
        </table>
        <br>
        <button class="btn  btn-warning text-white" (click)="ResendEmailAPI()" style="margin-right: 10px;">Resend
            Email</button>
        <br>
    </div>
</p-dialog>



<!--OPEN LOG-->

<p-dialog [(visible)]="showLog" modal="true" appendToBody="true" [draggable]="true" [closeOnEscape]="false"
    [responsive]="true" [style]="{ width: '800px' }" [baseZIndex]="1">
    <p-header style="width: 100%">
        <h3>Viewing Agent Log</h3>
    </p-header>

    <div class="row">
        <div class="col-12 col-md-4">
            <div class="smallfont">Action performed by</div>
            <div class="form-control">{{logFullName}}</div>
        </div>
        <div class="col-12 col-md-4">
            <div class="smallfont">Date and Time</div>
            <div class="form-control">{{logDate}}</div>
        </div>
        <div class="col-12 col-md-4">
            <div class="smallfont">From IP Address</div>
            <div class="form-control">{{logIP}}</div>
        </div>
        <div class="col-12 col-md-4">
            <div class="smallfont">Action Type</div>
            <div class="form-control">{{LogActionName}}</div>
        </div>
        <div class="col-12 col-md-8">
            <div class="smallfont">Action</div>
            <div class="form-control">{{logTitle}}</div>
        </div>
    </div>
    <div class="smallfont">Details</div>
    <angular-editor [config]="editorConfig " [(ngModel)]="logDetails"></angular-editor>


</p-dialog>



<!--edit agent dialog-->

<!--LIGHTBOX EDIT AGENT-->
<div id="overlay" *ngIf="editAgent">
    <div id="overlay3" style="overflow: auto">
        <button class="btn btn-sm btn-danger" (click)="editAgent= false">
            <i class="fa fa-window-close" aria-hidden="true"></i> Close
        </button>

        <br /><br />
        <p-tabView>
            <p-tabPanel header="Contracting Information" class="tabfont"
                [headerStyle]="{'font-size':'14px', 'margin-top':'2px'}">



                <h3>Contracting Information</h3>
                <hr>

                <h5>Agent Personal Information</h5>
                <hr>
                <div class="row">

                    <div class="col-xl-3 col-md-6 col-12">
                        <label>First Name</label><br>
                        <input type="text" maxlength=30 class="form-control" [(ngModel)]="APIedit.FirstName">
                    </div>

                    <div class="col-xl-3 col-md-6 col-12">
                        <label>Last Name</label><br>
                        <input type="text" maxlength=30 class="form-control" [(ngModel)]="APIedit.LastName">
                    </div>

                    <div class="col-xl-3 col-md-6 col-12">
                        <label>Date of Birth</label><br>
                        <input type="text" maxlength=30 class="form-control" mask="M0/d0/0000"
                            [(ngModel)]="APIedit.BirthDate" placeholder="mm/dd/yyyy" [dropSpecialCharacters]="false">
                    </div>

                </div>
                <br>
                <h5>Address</h5>
                <hr>
                <div class="row">
                    <div class="col-xl-6 col-md-6 col-12">
                        <label>Address</label><br>
                        <input type="text" maxlength="100" class="form-control" [(ngModel)]="APIedit.Address1">
                    </div>
                    <div class="col-xl-6 col-md-6 col-12">
                        <label>Address 2</label><br>
                        <input type="text" maxlength="100" class="form-control" [(ngModel)]="APIedit.Address2">
                    </div>
                    <div class="col-xl-6 col-md-6 col-12">
                        <label>City</label><br>
                        <input type="text" maxlength="50" class="form-control" [(ngModel)]="APIedit.City">
                    </div>
                    <div class="col-xl-3 col-md-6 col-12">
                        <label>State</label><br>
                        <select [(ngModel)]="APIedit.StateCode" class="form-control" name="residentstate"
                            id="residentstate" style="border-radius: 7px;">
                            <option value=""></option>
                            <option *ngFor="let USstate of USstates" value="{{USstate.Code}}">{{USstate.Name}}</option>
                        </select>
                    </div>
                    <div class="col-xl-3 col-md-6 col-12">
                        <label>ZIP Code</label><br>
                        <input type="text" maxlength=5 class="form-control" [(ngModel)]="APIedit.Zip">
                    </div>
                </div>
                <br>

                <h5>Contact Information</h5>
                <hr>
                <div class="row">
                    <div class="col-xl-3 col-md-6 col-12">
                        <label>Cell Phone Number</label><br>
                        <input [(ngModel)]="APIedit.Phone" type="text" maxlength=30 class="form-control"
                            mask="(000) 000-0000" [dropSpecialCharacters]="true">
                    </div>
                    <div class="col-xl-3 col-md-6 col-12">
                        <label>Business Phone Number</label><br>
                        <input [(ngModel)]="APIedit.BusinessPhone" type="text" maxlength=30 class="form-control"
                            mask="(000) 000-0000" [dropSpecialCharacters]="true">
                    </div>
                    <div class="col-xl-3 col-md-6 col-12">
                        <label>Fax Number</label><br>
                        <input [(ngModel)]="APIedit.FaxPhone" type="text" maxlength=30 class="form-control"
                            mask="(000) 000-0000" [dropSpecialCharacters]="true">
                    </div>
                    <div class="col-xl-3 col-md-6 col-12">
                        <label>Email Address</label><br>
                        <input [(ngModel)]="APIedit.Email" type="text" maxlength="70" class="form-control">
                    </div>
                </div>


                <br>



            </p-tabPanel>

            <p-tabPanel header="Products and Payout" *ngIf="AllowViewMembers" class="tabfont"
                [headerStyle]="{'font-size':'14px', 'margin-top':'2px'}">

                <h3>Products and Payout Setup</h3>
                <hr>
                <app-setcomp [Data]="Data3"></app-setcomp>



            </p-tabPanel>

            <p-tabPanel header="Deposits" *ngIf="adm" class="tabfont"
                [headerStyle]="{'font-size':'14px', 'margin-top':'2px'}">
                <h3>Deposits</h3>
                <hr>


                <h5>Banking Information</h5>
                <hr>

                <div class="row">
                    <div class="col-md-4">
                        <label>Account Beneficiary Name</label><br>
                        <input type="text" maxlength="40" class="form-control"
                            [(ngModel)]="APIedit.BankInfo.AccountName">
                    </div>
                    <div class="col-md-4">
                        <label>Bank Routing Number</label><br>
                        <input type="text" maxlength="9" class="form-control"
                            [(ngModel)]="APIedit.BankInfo.BankRoutingNumber" (keypress)="keyPressNumbers($event)">
                    </div>
                    <div class="col-md-4">
                        <label>Bank Account Number</label><br>
                        <input type="text" maxlength="17" class="form-control"
                            [(ngModel)]="APIedit.BankInfo.BankAccountNumber" (keypress)="keyPressNumbers($event)">
                    </div>
                    <div class="col-md-4">
                        <label>Bank Account Type</label><br>
                        <select class="form-control" [(ngModel)]="APIedit.BankInfo.BankAccountType">
                            <option value=""></option>
                            <option value="Checking">Checking Account</option>
                            <option value="Savings">Savings Account</option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <label>Bank Name</label><br>
                        <input type="text" maxlength="60" class="form-control" [(ngModel)]="APIedit.BankInfo.BankName">
                    </div>

                    <div class="col-md-4">
                        <label>EIN/SSN</label><br>
                        <input type="text" maxlength="60" class="form-control" [(ngModel)]="APIedit.BankInfo.EIN"
                            maxlength="9" (keypress)="keyPressNumbers($event)">
                    </div>

                </div>
                <br>
            </p-tabPanel>

            <p-tabPanel header="License" class="tabfont" [headerStyle]="{'font-size':'14px', 'margin-top':'2px'}">
                <br />
                <h3>License Information</h3>
                <hr>
                <h5>Agency Type </h5>
                <hr>
                <br>
                <select class="form-control" style="border-radius: 7px;" [(ngModel)]="APIedit.type" (change)="resetAgencyType()">
                    <option value="individual">Individual Agent</option>
                    <option value="business">Agency</option>
                </select>

                <br>

                <span class="text-danger">IMPORTANT: </span>

                <div *ngIf="APIedit.type=='business'">
                    AFEUSA will validate all Agency licenses and Individual licenses against NIPR. Please fill out
                    both
                    Agency and Individual information for contracting. If you have an Agency license, we will
                    appoint
                    using the Agency License, however if you don’t have an Agency License in a specific state, we
                    will
                    appoint using your Individual license though NIPR. Please complete the following information,
                    all
                    contract information is private and for internal use only.
                    <br><br>
                </div>
                <div>
                    Please complete the following information based upon your
                    <strong>Resident State & NPN information</strong>. Licensing
                    must match your resident license information. Dylan Consulting, LLC validates all licenses
                    against
                    NIPR database.
                    <br>
                </div>

                <br>
                <div *ngIf="APIedit.type=='business'">
                    <h5>Agency Resident License Information <span class="text-danger">(AS SHOWN ON RESIDENT
                            LICENSE)</span></h5>
                    <hr>
                    <div class="row">
                        <div class="col-xl-4 col-sm-6 col-md-6 col-12">
                            <label>Licensed Agency Name (as shown on NPN)</label><br>
                            <input type="text" maxlength=30 class="form-control" [(ngModel)]="npnAg.AgencyName">
                        </div>
                        <div class="col-xl-4 col-sm-6 col-md-6 col-12">
                            <label>Resident Insurance License #</label><br>
                            <input type="text" maxlength=30 class="form-control"
                                [(ngModel)]="npnAg.ResidentInsuranceNumber">
                        </div>
                        <div class="col-xl-4 col-sm-6 col-md-6 col-12">
                            <label>Resident State License</label><br>
                            <select class="form-control" style="border-radius: 7px;"
                                [(ngModel)]="npnAg.ResidentStateLicense">
                                <option value=""></option>
                                <option *ngFor="let USstate of USstates" value="{{USstate.Code}}">{{USstate.Name}}
                                </option>
                            </select>
                        </div>
                        <div class="col-xl-4 col-sm-6 col-md-6 col-12">
                            <label>Agency Type </label><br>
                            <select class="form-control" style="border-radius: 7px;" [(ngModel)]="npnAg.AgencyType">
                                <option value=""></option>
                                <option value="Sole Proprietor">Sole Proprietor</option>
                                <option value="C Corporation">C Corporation</option>
                                <option value="S Corporation">S Corporation</option>
                                <option value="Partnership">Partnership</option>
                                <option value="Trust/estate">Trust/estate</option>
                                <option value="LLC">Limited Liability Company</option>
                            </select>
                        </div>
                        <div class="col-xl-4 col-sm-6 col-md-6 col-12">
                            <label>EIN</label><br>
                            <input type="text" maxlength="60" class="form-control" [(ngModel)]="npnAg.EIN"
                                mask="00-0000000" [dropSpecialCharacters]="false">
                        </div>
                        <div class="col-xl-4 col-sm-6 col-md-6 col-12">
                            <label>Type of Insurance License</label><br>
                            <select class="form-control" style="border-radius: 7px;" [(ngModel)]="npnAg.TypeOfLicense">
                                <option value=""></option>
                                <option value="Life Only">Life Only</option>
                                <option value="Health Only">Health Only</option>
                                <option value="Life and Health">Life and Health</option>
                                <option value="P&C">P&C</option>
                                <option value="Life, Health, and P&C">Life, Health, and P&C</option>
                            </select>
                        </div>

                        <div class="col-xl-6 col-sm-6 col-md-6 col-12">
                            <label>Agency Address (as Listed on NPN)</label>
                            <input type="text" maxlength="50" class="form-control" [(ngModel)]="npnAg.Address">
                        </div>
                        <div class="col-xl-2 col-sm-6 col-md-6 col-12">
                            <label>City</label>
                            <input type="text" maxlength="50" class="form-control" [(ngModel)]="npnAg.city">
                        </div>

                        <div class="col-xl-2 col-sm-6 col-md-6 col-12">
                            <label>State</label><br>
                            <select class="form-control" style="border-radius: 7px;" [(ngModel)]="npnAg.state">
                                <option value=""></option>
                                <option *ngFor="let USstate of USstates" value="{{USstate.Code}}">{{USstate.Name}}
                                </option>
                            </select>
                        </div>
                        <div class="col-xl-2 col-sm-6 col-md-6 col-12">
                            <label>ZIP Code</label>
                            <input type="text" maxlength="5" class="form-control" [(ngModel)]="npnAg.zip">
                        </div>

                        <div class="col-md-6">
                            <label>Agency NPN Number</label><br>
                            <input type="text" maxlength=30 class="form-control" [(ngModel)]="npnAg.NPNnumber">
                        </div>
                        <div class="col-md-6">
                            <label> </label><br><br>
                            <a target="_blank" href="https://nipr.com/help/look-up-your-npn" class="text-primary">Click
                                here
                                to search</a>
                        </div>

                    </div>
                    <br>
                </div>
                <br>
                <h5>Resident Individual License Information <span class="text-danger">(AS SHOWN ON RESIDENT
                        LICENSE)</span></h5>
                        <hr>
                <div class="row">
                    <div class="col-xl-2 col-sm-6 col-md-6 col-12">
                        <label>First Name</label><br>
                        <input type="text" maxlength="50" class="form-control" [(ngModel)]="npnInd.firstname">
                    </div>
                    <div class="col-xl-2 col-sm-6 col-md-6 col-12">
                        <label>Middle Name</label><br>
                        <input type="text" maxlength="50" class="form-control" [(ngModel)]="npnInd.middlename">
                    </div>
                    <div class="col-xl-2 col-sm-6 col-md-6 col-12">
                        <label>Last Name</label><br>
                        <input type="text" maxlength="50" class="form-control" [(ngModel)]="npnInd.lastname">
                    </div>
                    <div class="col-xl-2 col-sm-6 col-md-6 col-12">
                        <label>DOB</label><br>
                        <input type="text" maxlength="50" class="form-control" mask="M0/d0/0000"
                            [(ngModel)]="npnInd.DOB" placeholder="mm/dd/yyyy" [dropSpecialCharacters]="false">
                    </div>
                    <div class="col-xl-4 col-sm-6 col-md-6 col-12">
                        <label>SSN</label><br>
                        <input type="text" maxlength="60" class="form-control" [(ngModel)]="npnInd.SSN"
                            mask="000-00-0000" [dropSpecialCharacters]="false">
                    </div>

                    <div class="col-xl-4 col-sm-6 col-md-6 col-12">
                        <label>Type of Insurance License</label><br>
                        <select class="form-control" style="border-radius: 7px;" [(ngModel)]="npnInd.TypeOfLicense">
                            <option value=""></option>
                            <option value="Life Only">Life Only</option>
                            <option value="Health Only">Health Only</option>
                            <option value="Life and Health">Life and Health</option>
                            <option value="P&C">P&C</option>
                            <option value="Life, Health, and P&C">Life, Health, and P&C</option>
                        </select>
                    </div>
                    <div class="col-xl-4 col-sm-6 col-md-6 col-12">
                        <label>Resident Insurance License #</label><br>
                        <input type="text" maxlength=30 class="form-control"
                            [(ngModel)]="npnInd.ResidentInsuranceNumber">
                    </div>
                    <div class="col-xl-4 col-sm-6 col-md-6 col-12">
                        <label>Resident State License</label><br>
                        <select class="form-control" style="border-radius: 7px;"
                            [(ngModel)]="npnInd.ResidentStateLicense">
                            <option value=""></option>
                            <option *ngFor="let USstate of USstates" value="{{USstate.Code}}">{{USstate.Name}}</option>
                        </select>
                    </div>

                    <div class="col-xl-6 col-sm-6 col-md-6 col-12">
                        <label>Resident Address (as Listed on NPN)</label>
                        <input type="text" maxlength="50" class="form-control" [(ngModel)]="npnInd.Address">
                    </div>
                    <div class="col-xl-2 col-sm-6 col-md-6 col-12">
                        <label>City</label>
                        <input type="text" maxlength="50" class="form-control" [(ngModel)]="npnInd.city">
                    </div>

                    <div class="col-xl-2 col-sm-6 col-md-6 col-12">
                        <label>State</label><br>
                        <select class="form-control" style="border-radius: 7px;" [(ngModel)]="npnInd.state">
                            <option value=""></option>
                            <option *ngFor="let USstate of USstates" value="{{USstate.Code}}">{{USstate.Name}}</option>
                        </select>
                    </div>
                    <div class="col-xl-2 col-sm-6 col-md-6 col-12">
                        <label>ZIP Code</label>
                        <input type="text" maxlength="5" class="form-control" [(ngModel)]="npnInd.zip">
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label>Individual NPN Number</label><br>
                        <input type="text" maxlength="50" class="form-control" [(ngModel)]="npnInd.NPNnumber">
                    </div>
                    <div class="col-md-6">
                        <label> </label><br><br>
                        <a target="_blank" href="https://nipr.com/help/look-up-your-npn" class="text-primary">Click here
                            to search</a>
                    </div>
                </div>
                <br>


                <br /><br />
                <h5>E&O Coverage</h5>
                <hr>
                <div class="row">
                    <div class="col-xl-3 col-sm-6 col-md-6 col-12">
                        <label>E&O Coverage</label><br>
                        <select class="form-control" style="border-radius: 7px;"
                            [(ngModel)]="APIedit.LicenseInfo.EOICoverage">
                            <option value=""></option>
                            <option [ngValue]="true">Yes</option>
                            <option [ngValue]="false">No</option>
                        </select>
                    </div>
                    <div class="col-xl-3 col-sm-6 col-md-6 col-12" *ngIf="APIedit.LicenseInfo.EOICoverage==true">
                        <label>E&O Amount</label><br>
                        <input type="text" maxlength="70" class="form-control"
                            [(ngModel)]="APIedit.LicenseInfo.EOIAmount" [dropSpecialCharacters]="true"
                            (keypress)="keyPressNumbers($event)" mask="separator.0" thousandSeparator="," maxlength="9">
                    </div>
                    <div class="col-xl-3 col-sm-6 col-md-6 col-12" *ngIf="APIedit.LicenseInfo.EOICoverage==true">
                        <label>E&O Expiration</label><br>
                        <input type="text" maxlength="70" class="form-control"
                            [(ngModel)]="APIedit.LicenseInfo.EOIExpiration" mask="M0/d0/0000" placeholder="mm/dd/yyyy"
                            [dropSpecialCharacters]="false">
                    </div>

                    <div class="col-xl-3 col-sm-6 col-md-6 col-12" *ngIf="APIedit.LicenseInfo.EOICoverage==true">
                        <label>Attach E&O Certificate</label><br>
                        <input type="file" class="form-control" name="eofile2" id="eofile2"
                            (change)="onFileChanged($event)" accept="application/pdf,image/png,image/jpeg">
                    </div>

                </div>

                <br>
            </p-tabPanel>


            <p-tabPanel header="Change Hierarchy" *ngIf="adm" class="tabfont"
                [headerStyle]="{'font-size':'14px', 'margin-top':'2px'}">
                <h3>Change Hierarchy</h3>
                <hr>

                <div class="bShadow-5">
                    <div>
                        <h6>Current Sponsor</h6>
                        <hr>
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-3">
                                <label>Sponsor ID:</label><br>
                                <span class="form-control">{{APIresult[0]?.agentinfo.SponsorPL}}</span>
                            </div>

                            <div class="col-12 col-sm-6 col-md-3">
                                <label>Sponsor:</label><br>
                                <span class="form-control">{{APIresult[0]?.agentinfo.SponsorFname}}
                                    {{APIresult[0]?.agentinfo.SponsorLname}}</span>
                            </div>
                        </div>

                    </div>
                </div>
                <br>
                <div class="bShadow-5">
                    <div>
                        <h6>New Sponsor</h6>
                        <hr>
                        <div class="row">
                            <div class="col-6 col-sm-4 col-md-2">
                                <label>Search Sponsor ID:</label><br>
                                <input type="text" class="form-control" [(ngModel)]="NewSponsorID"
                                    (keypress)="keyPressNumbers($event)" maxlength="7">
                            </div>
                            <div class="col-6 col-sm-2 col-md-1 text-right">
                                <label><br></label><br>
                                <button class="btn btn-sm btn-primary" style="width: 100%"
                                    (click)="ViewDownline()">Search</button>
                            </div>

                            <div class="col-12 col-sm-6 col-md-9" *ngIf="ShowSponsor">
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <div class="bShadow-5">
                                            <div style="min-height: 340px;">
                                                <h6>Sponsor Agent</h6>
                                                <hr>
                                                <table class="w100">

                                                    <tr class="spaceUnder">
                                                        <td class="text-info">
                                                            Agent ID:
                                                        </td>
                                                        <td class="text-black">
                                                            {{APISponsor.agentinfo.plid}}
                                                        </td>
                                                    </tr>
                                                    <tr class="spaceUnder">
                                                        <td class="text-info">
                                                            Full Name:
                                                        </td>
                                                        <td class="text-black">
                                                            {{APISponsor.agentinfo.firstname}}
                                                            {{APISponsor.agentinfo.middlename}}
                                                            {{APISponsor.agentinfo.lastname}}
                                                        </td>
                                                    </tr>
                                                    <tr class="spaceUnder">
                                                        <td class="text-info">
                                                            Address:
                                                        </td>
                                                        <td class="text-black">
                                                            {{APISponsor.agentinfo.address}}
                                                            {{APISponsor.agentinfo.city}},
                                                            {{APISponsor.agentinfo.state}} {{APISponsor.agentinfo.zip}}
                                                        </td>
                                                    </tr>
                                                    <tr class="spaceUnder">
                                                        <td class="text-info">
                                                            Cell Phone:
                                                        </td>
                                                        <td class="text-black">
                                                            {{APISponsor.agentinfo.cellphone}}
                                                        </td>
                                                    </tr>
                                                    <tr class="spaceUnder">
                                                        <td class="text-info">
                                                            Business Phone:
                                                        </td>
                                                        <td class="text-black">
                                                            {{APISponsor.agentinfo.businessphone}}
                                                        </td>
                                                    </tr>
                                                    <tr class="spaceUnder">
                                                        <td class="text-info">
                                                            Fax Number:
                                                        </td>
                                                        <td class="text-black">
                                                            {{APISponsor.agentinfo.faxnumber}}
                                                        </td>
                                                    </tr>
                                                    <tr class="spaceUnder">
                                                        <td class="text-info">
                                                            Email Address:
                                                        </td>
                                                        <td class="text-black">
                                                            {{APISponsor.agentinfo.email}}
                                                        </td>
                                                    </tr>
                                                    <tr class="spaceUnder">
                                                        <td colspan="2">
                                                            <br>
                                                            <button class="btn btn-danger btn-sm"
                                                                (click)="SetDownline()">Set Agent as Upline</button>

                                                        </td>
                                                    </tr>

                                                </table>
                                            </div>
                                        </div>
                                    </div>




                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <div class="bShadow-5">
                                            <div style="min-height: 340px;">
                                                <h6>Products and Grid</h6>
                                                <hr>
                                                <table class="w100">
                                                    <tr class="spaceUnder">
                                                        <td></td>
                                                        <td class="text-title">Year 1</td>
                                                        <td class="text-title">Year 2+</td>
                                                    </tr>
                                                    <tr class="spaceUnder" *ngFor="let product of APISponsor.products ">
                                                        <td class="text-info">{{product.RealName}}</td>
                                                        <td class="text-black">{{product.Y1}}%</td>
                                                        <td class="text-black">{{product.Y2}}%</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>


                    </div>
                </div>

            </p-tabPanel>
        </p-tabView>
        <br><br>
        <button class="btn btn-warning text-white" (click)="SaveCompensation()">Save Changes</button>
        <br><br><br>
    </div>
</div>







<!--LIGHTBOX NOTES-->
<div id="overlay" *ngIf="newNote">
    <div id="overlay2" style="overflow: auto">
        <div class="text-right">
            <button class="btn btn-sm btn-danger" (click)="newNote= false">
                <i class="fa fa-window-close" aria-hidden="true"></i> Close
            </button>
        </div>
        <br><br>
        <app-notes [NoteType]="'agent'" [ElementID]="APIresult[0]?.agentinfo.plid"></app-notes>
    </div>
</div>




<!--LIGHTBOX NEW AGENT-->
<div id="overlay" *ngIf="newAgent">
    <div id="overlay2" style="overflow: auto">
        <button class="btn btn-sm btn-danger" (click)="newAgent= false">
            <i class="fa fa-window-close" aria-hidden="true"></i> Close
        </button>
        <br><br>
        <div class="bShadow-5 bg-white">
            <h5>Creating New Agent</h5>
            <hr>



            <p-tabView [activeIndex]="tabindex" (onChange)="onTabChange($event)">
                <p-tabPanel header="Get Started" class="tabfont"
                    [headerStyle]="{'font-size':'14px', 'margin-top':'2px'}">




                    <div *ngIf="AgentType=='business'">
                        <h5>Business Information</h5>
                        <hr>
                        <div class="row">
                            <div class="col-xl-8 col-md-6 col-12">
                                <label>Legal Name</label><br>
                                <input type="text" maxlength="100" class="form-control" [(ngModel)]="fullcompanyname">
                            </div>
                            <div class="col-xl-4 col-md-6 col-12">
                                <label>Domicile State</label><br>
                                <select [(ngModel)]="domicilestate" class="form-control" name="residentstate"
                                    id="residentstate" style="border-radius: 7px;">
                                    <option value=""></option>
                                    <option *ngFor="let USstate of USstates" value="{{USstate.Code}}">
                                        {{USstate.Name}}</option>
                                </select>
                            </div>
                            <div class="col-md-12">
                                <label>Owner(s) / Member(s)</label><br>
                                <textarea type="text" rows="5" class="form-control" [(ngModel)]="owners"></textarea>
                            </div>
                            <div class="col-md-12">
                                <br>
                                <input type="checkbox" class="form-check-input" id="havestaff" name="havestaff"
                                    value="y" style="margin-left: 0px;" (change)="changestaff($event)">
                                <label class="form-check-label nolabel" for="havestaff" style="margin-left: 20px;">I
                                    have an
                                    authorized staff member that assists me in licensing or contracting of
                                    agents.</label>

                            </div>
                        </div>
                        <div *ngIf="havestaff==true">
                            <br>
                            <h5>Authorized person assisting in licensing or contracting agents</h5>
                            <hr>
                            <p>If you would have an authorized staff that assists you in licensing or contracting of
                                agents. Please complete the information below to setup a login for this support
                                person. They will not have access to the commission module.</p>
                            <div class="row">
                                <div class="col-xl-3 col-md-6 col-12">
                                    <label>First Name</label><br>
                                    <input type="text" maxlength=30 class="form-control" [(ngModel)]="authfirstname">
                                </div>

                                <div class="col-xl-3 col-md-6 col-12">
                                    <label>Last Name</label><br>
                                    <input type="text" maxlength=30 class="form-control" [(ngModel)]="authlastname">
                                </div>
                                <div class="col-xl-3 col-md-6 col-12">
                                    <label>Email</label><br>
                                    <input type="text" maxlength=30 class="form-control" [(ngModel)]="authemail">
                                </div>
                                <div class="col-xl-3 col-md-6 col-12">
                                    <label>Phone Number</label><br>
                                    <input type="text" maxlength=30 class="form-control" [(ngModel)]="authphone"
                                        mask="(000) 000-0000" [dropSpecialCharacters]="false">
                                </div>

                            </div>
                        </div>
                    </div>
                    <br>
                    <h5>Agent Personal Information</h5>
                    <hr>

                    <div class="row">
                        <!--
                        <div class="col-md-12" *ngIf="AgentType=='individual'">
                            <label>Company Name OR Full Name</label><br>
                            <input type="text" maxlength="100" class="form-control" [(ngModel)]="fullcompanyname">
                        </div>
                        -->
                        <div class="col-xl-3 col-md-6 col-12">
                            <label>First Name</label><br>
                            <input type="text" maxlength=30 class="form-control" [(ngModel)]="firstname">
                        </div>
                        <div class="col-xl-3 col-md-6 col-12">
                            <label>Middle Name</label><br>
                            <input type="text" maxlength=30 class="form-control" [(ngModel)]="middlename">
                        </div>
                        <div class="col-xl-3 col-md-6 col-12">
                            <label>Last Name</label><br>
                            <input type="text" maxlength=30 class="form-control" [(ngModel)]="lastname">
                        </div>

                        <div class="col-xl-3 col-md-6 col-12">
                            <label>Date of Birth</label><br>
                            <input type="text" maxlength=30 class="form-control" mask="M0/d0/0000" [(ngModel)]="dob"
                                placeholder="mm/dd/yyyy" [dropSpecialCharacters]="false">
                        </div>

                    </div>
                    <br>
                    <h5>{{addressTitle}}</h5>
                    <hr>
                    <div class="row">
                        <div class="col-xl-6 col-md-6 col-12">
                            <label>Address</label><br>
                            <input type="text" maxlength="100" class="form-control" [(ngModel)]="address">
                        </div>
                        <div class="col-xl-6 col-md-6 col-12">
                            <label>Address 2</label><br>
                            <input type="text" maxlength="100" class="form-control" [(ngModel)]="address2">
                        </div>
                        <div class="col-xl-6 col-md-6 col-12">
                            <label>City</label><br>
                            <input type="text" maxlength="50" class="form-control" [(ngModel)]="city">
                        </div>
                        <div class="col-xl-3 col-md-6 col-12">
                            <label>State</label><br>
                            <select [(ngModel)]="state" class="form-control" name="residentstate" id="residentstate"
                                style="border-radius: 7px;">
                                <option value=""></option>
                                <option *ngFor="let USstate of USstates" value="{{USstate.Code}}">{{USstate.Name}}
                                </option>
                            </select>
                        </div>
                        <div class="col-xl-3 col-md-6 col-12">
                            <label>ZIP Code</label><br>
                            <input type="text" maxlength=5 class="form-control" [(ngModel)]="zip">
                        </div>
                    </div>
                    <br>

                    <h5>Contact Information</h5>
                    <hr>
                    <div class="row">
                        <div class="col-xl-3 col-md-6 col-12">
                            <label>Cell Phone Number</label><br>
                            <input [(ngModel)]="cellphone" type="text" maxlength=30 class="form-control"
                                mask="(000) 000-0000" [dropSpecialCharacters]="true">
                        </div>
                        <div class="col-xl-3 col-md-6 col-12">
                            <label>Business Phone Number</label><br>
                            <input [(ngModel)]="businessphone" type="text" maxlength=30 class="form-control"
                                mask="(000) 000-0000" [dropSpecialCharacters]="true">
                        </div>
                        <div class="col-xl-3 col-md-6 col-12">
                            <label>Fax Number</label><br>
                            <input [(ngModel)]="faxnumber" type="text" maxlength=30 class="form-control"
                                mask="(000) 000-0000" [dropSpecialCharacters]="true">
                        </div>
                        <div class="col-xl-3 col-md-6 col-12">
                            <label>Email Address</label><br>
                            <input [(ngModel)]="contactemail" type="text" maxlength="70" class="form-control">
                        </div>
                    </div>
                    <br>


                    <h5>Create Login Information</h5>
                    <hr>
                    <div class="row">
                        <div class="col-xl-4 col-md-4 col-12">
                            <label>Email Address</label><br>
                            <input type="text" maxlength="70" class="form-control" [(ngModel)]="contactemail" disabled>
                        </div>
                        <div class="col-xl-4 col-md-4 col-12">
                            <label>Password</label><br>
                            <input type="text" maxlength="30" class="form-control" [(ngModel)]="password">
                        </div>
                        <div class="col-xl-4 col-md-4 col-12">
                            <label>Re-Enter Password</label><br>
                            <input type="text" maxlength="30" class="form-control" [(ngModel)]="password2">
                        </div>
                    </div>
                    <br>

                    <button class="btn btn-warning text-white" (click)="changeTab(1)">Continue </button>
                </p-tabPanel>

                <p-tabPanel header="Compensation" class="tabfont"
                    [headerStyle]="{'font-size':'14px', 'margin-top':'2px'}">
                    <br>
                    <h5>
                        Products and Payout Setup</h5>
                    <hr>

                    <app-setcomp [Data]="Data2"></app-setcomp>



                    <div>
                        <br><br>
                        <h5>W9 Information</h5>
                        <hr>
                        <div class="row">
                            <div class="col-md-6">
                                <label>First and Last Name</label><br>
                                <input type="text" maxlength="60" class="form-control" [(ngModel)]="payee">
                            </div>
                            <div class="col-md-6">
                                <label>SSN (Social Security Number)</label><br>
                                <input type="text" maxlength="60" class="form-control" [(ngModel)]="taxid"
                                    mask="000-00-0000" [dropSpecialCharacters]="false">
                            </div>
                        </div>
                    </div>

                    <br>
                    <h5>Deposit Information</h5>
                    <hr>

                    <div class="row">
                        <div class="col-md-4">
                            <label>Account Beneficiary Name</label><br>
                            <input type="text" maxlength="40" class="form-control" [(ngModel)]="payee">
                        </div>
                        <div class="col-md-4">
                            <label>Bank Routing Number</label><br>
                            <input type="text" maxlength="9" class="form-control" [(ngModel)]="routing"
                                (keypress)="keyPressNumbers($event)">
                        </div>
                        <div class="col-md-4">
                            <label>Bank Account Number</label><br>
                            <input type="text" maxlength="17" class="form-control" [(ngModel)]="account"
                                (keypress)="keyPressNumbers($event)">
                        </div>
                        <div class="col-md-4">
                            <label>Bank Account Type</label><br>
                            <select class="form-control" [(ngModel)]="accounttype">
                                <option value=""></option>
                                <option value="Checking">Checking Account</option>
                                <option value="Savings">Savings Account</option>
                            </select>
                        </div>
                        <div class="col-md-4">
                            <label>Bank Name</label><br>
                            <input type="text" maxlength="60" class="form-control" [(ngModel)]="bankname">
                        </div>
                        <!--
                        <div class="col-md-8">
                            <label>Account Beneficiary Name</label><br>
                            <input type="text" maxlength="60" class="form-control" [(ngModel)]="beneficiaryname">
                        </div>
                        -->
                    </div>
                    <br>
                    <button class="btn btn-warning text-white" (click)="changeTab(2)">Continue </button>
                </p-tabPanel>

                <p-tabPanel header="License" class="tabfont" [headerStyle]="{'font-size':'14px', 'margin-top':'2px'}">




                    <h5>Agency Type</h5>
                    <hr>
                    <br>
                    <select class="form-control" style="border-radius: 7px;" [(ngModel)]="newAgentType">
                        <option value="individual">Individual Agent</option>
                        <option value="business">Agency</option>
                    </select>

                    <br> <br>



                    <h5>License Information</h5>
                    <hr>
                    <span class="text-danger">IMPORTANT: </span>

                    <div *ngIf="newAgentType!='individual'">
                        AFEUSA will validate all Agency licenses and Individual licenses against NIPR. Please fill out
                        both
                        Agency and Individual information for contracting. If you have an Agency license, we will
                        appoint
                        using the Agency License, however if you don’t have an Agency License in a specific state, we
                        will
                        appoint using your Individual license though NIPR. Please complete the following information,
                        all
                        contract information is private and for internal use only.
                    </div>
                    <div *ngIf="newAgentType=='individual'">
                        Please complete the following information based upon your
                        <strong>Resident State & NPN information</strong>. Licensing
                        must match your resident license information. Dylan Consulting, LLC validates all licenses
                        against
                        NIPR database.
                    </div>


                    <br>
                    <div *ngIf="newAgentType!='individual'">
                        <h5>Agency Resident License Information <span class="text-danger">(AS SHOWN ON RESIDENT
                                LICENSE)</span></h5>

                        <div class="row">
                            <div class="col-xl-4 col-sm-6 col-md-6 col-12">
                                <label>Licensed Agency Name (as shown on NPN)</label><br>
                                <input type="text" maxlength=30 class="form-control" [(ngModel)]="npnaname">
                            </div>
                            <div class="col-xl-4 col-sm-6 col-md-6 col-12">
                                <label>Resident Insurance License #</label><br>
                                <input type="text" maxlength=30 class="form-control" [(ngModel)]="npnaresidentnumber">
                            </div>
                            <div class="col-xl-4 col-sm-6 col-md-6 col-12">
                                <label>Resident State License</label><br>
                                <select class="form-control" style="border-radius: 7px;"
                                    [(ngModel)]="npnaresidentstate">
                                    <option value=""></option>
                                    <option *ngFor="let USstate of USstates" value="{{USstate.Code}}">{{USstate.Name}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-xl-4 col-sm-6 col-md-6 col-12">
                                <label>Agency Type </label><br>
                                <select class="form-control" style="border-radius: 7px;" [(ngModel)]="npnatype">
                                    <option value=""></option>
                                    <option value="Sole Proprietor">Sole Proprietor</option>
                                    <option value="C Corporation">C Corporation</option>
                                    <option value="S Corporation">S Corporation</option>
                                    <option value="Partnership">Partnership</option>
                                    <option value="Trust/estate">Trust/estate</option>
                                    <option value="LLC">Limited Liability Company</option>
                                </select>
                            </div>
                            <div class="col-xl-4 col-sm-6 col-md-6 col-12">
                                <label>EIN</label><br>
                                <input type="text" maxlength="60" class="form-control" [(ngModel)]="npnaein"
                                    mask="00-0000000" [dropSpecialCharacters]="false">
                            </div>
                            <div class="col-xl-4 col-sm-6 col-md-6 col-12">
                                <label>Type of Insurance License</label><br>
                                <select class="form-control" style="border-radius: 7px;"
                                    [(ngModel)]="npnatypeoflicense">
                                    <option value=""></option>
                                    <option value="Life Only">Life Only</option>
                                    <option value="Health Only">Health Only</option>
                                    <option value="Life and Health">Life and Health</option>
                                    <option value="P&C">P&C</option>
                                    <option value="Life, Health, and P&C">Life, Health, and P&C</option>
                                </select>
                            </div>

                            <div class="col-xl-6 col-sm-6 col-md-6 col-12">
                                <label>Agency Address (as Listed on NPN)</label>
                                <input type="text" maxlength="50" class="form-control" [(ngModel)]="npnaaddress">
                            </div>
                            <div class="col-xl-2 col-sm-6 col-md-6 col-12">
                                <label>City</label>
                                <input type="text" maxlength="50" class="form-control" [(ngModel)]="npnacity">
                            </div>

                            <div class="col-xl-2 col-sm-6 col-md-6 col-12">
                                <label>State</label><br>
                                <select class="form-control" style="border-radius: 7px;" [(ngModel)]="npnastate">
                                    <option value=""></option>
                                    <option *ngFor="let USstate of USstates" value="{{USstate.Code}}">{{USstate.Name}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-xl-2 col-sm-6 col-md-6 col-12">
                                <label>ZIP Code</label>
                                <input type="text" maxlength="5" class="form-control" [(ngModel)]="npnazip">
                            </div>

                            <div class="col-md-6">
                                <label>Agency NPN Number</label><br>
                                <input type="text" maxlength=30 class="form-control" [(ngModel)]="npnanumber">
                            </div>
                            <div class="col-md-6">
                                <label> </label><br><br>
                                <a target="_blank" href="https://nipr.com/help/look-up-your-npn"
                                    class="text-primary">Click
                                    here
                                    to search</a>
                            </div>

                        </div>
                        <br>
                    </div>
                    <h5>Resident Individual License Information <span class="text-danger">(AS SHOWN ON RESIDENT
                            LICENSE)</span></h5>

                    <div class="row">
                        <div class="col-xl-2 col-sm-6 col-md-6 col-12">
                            <label>First Name</label><br>
                            <input type="text" maxlength="50" class="form-control" [(ngModel)]="npnifirstname">
                        </div>
                        <div class="col-xl-2 col-sm-6 col-md-6 col-12">
                            <label>Middle Name</label><br>
                            <input type="text" maxlength="50" class="form-control" [(ngModel)]="npnimiddlename">
                        </div>
                        <div class="col-xl-2 col-sm-6 col-md-6 col-12">
                            <label>Last Name</label><br>
                            <input type="text" maxlength="50" class="form-control" [(ngModel)]="npnilastname">
                        </div>
                        <div class="col-xl-2 col-sm-6 col-md-6 col-12">
                            <label>DOB</label><br>
                            <input type="text" maxlength="50" class="form-control" mask="M0/d0/0000"
                                [(ngModel)]="npnidob" placeholder="mm/dd/yyyy" [dropSpecialCharacters]="false">
                        </div>
                        <div class="col-xl-4 col-sm-6 col-md-6 col-12">
                            <label>SSN</label><br>
                            <input type="text" maxlength="60" class="form-control" [(ngModel)]="npnissn"
                                mask="000-00-0000" [dropSpecialCharacters]="false">
                        </div>

                        <div class="col-xl-4 col-sm-6 col-md-6 col-12">
                            <label>Type of Insurance License</label><br>
                            <select class="form-control" style="border-radius: 7px;" [(ngModel)]="npnitypeoflicense">
                                <option value=""></option>
                                <option value="Life Only">Life Only</option>
                                <option value="Health Only">Health Only</option>
                                <option value="Life and Health">Life and Health</option>
                                <option value="P&C">P&C</option>
                                <option value="Life, Health, and P&C">Life, Health, and P&C</option>
                            </select>
                        </div>
                        <div class="col-xl-4 col-sm-6 col-md-6 col-12">
                            <label>Resident Insurance License #</label><br>
                            <input type="text" maxlength=30 class="form-control" [(ngModel)]="rnpniesidentlicense">
                        </div>
                        <div class="col-xl-4 col-sm-6 col-md-6 col-12">
                            <label>Resident State License</label><br>
                            <select class="form-control" style="border-radius: 7px;" [(ngModel)]="residentstate">
                                <option value=""></option>
                                <option *ngFor="let USstate of USstates" value="{{USstate.Code}}">{{USstate.Name}}
                                </option>
                            </select>
                        </div>

                        <div class="col-xl-6 col-sm-6 col-md-6 col-12">
                            <label>Resident Address (as Listed on NPN)</label>
                            <input type="text" maxlength="50" class="form-control" [(ngModel)]="npniaddress">
                        </div>
                        <div class="col-xl-2 col-sm-6 col-md-6 col-12">
                            <label>City</label>
                            <input type="text" maxlength="50" class="form-control" [(ngModel)]="npnicity">
                        </div>

                        <div class="col-xl-2 col-sm-6 col-md-6 col-12">
                            <label>State</label><br>
                            <select class="form-control" style="border-radius: 7px;" [(ngModel)]="npnistate">
                                <option value=""></option>
                                <option *ngFor="let USstate of USstates" value="{{USstate.Code}}">{{USstate.Name}}
                                </option>
                            </select>
                        </div>
                        <div class="col-xl-2 col-sm-6 col-md-6 col-12">
                            <label>ZIP Code</label>
                            <input type="text" maxlength="5" class="form-control" [(ngModel)]="npnizip">
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label>Individual NPN Number</label><br>
                            <input type="text" maxlength="50" class="form-control" [(ngModel)]="npmnumber">
                        </div>
                        <div class="col-md-6">
                            <label> </label><br><br>
                            <a target="_blank" href="https://nipr.com/help/look-up-your-npn" class="text-primary">Click
                                here
                                to search</a>
                        </div>
                    </div>
                    <br>














                    <h5>E&O Coverage</h5>
                    <hr>
                    <div class="row">
                        <div class="col-xl-3 col-sm-6 col-md-6 col-12">
                            <label>E&O Coverage</label><br>
                            <select class="form-control" style="border-radius: 7px;" [(ngModel)]="haseocoverage">
                                <option value=""></option>
                                <option value="y">Yes</option>
                                <option value="n">No</option>
                            </select>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-md-6 col-12" *ngIf="haseocoverage=='y'">
                            <label>E&O Amount</label><br>
                            <input type="text" maxlength="70" class="form-control" [(ngModel)]="eoamount"
                                (keypress)="keyPressNumbers($event)" mask="separator.0" thousandSeparator=","
                                maxlength="9">
                        </div>
                        <div class="col-xl-3 col-sm-6 col-md-6 col-12" *ngIf="haseocoverage=='y'">
                            <label>E&O Expiration</label><br>
                            <input type="text" maxlength="70" class="form-control" [(ngModel)]="eoexpiration"
                                mask="M0/d0/0000" placeholder="mm/dd/yyyy" [dropSpecialCharacters]="false">
                        </div>
                        <div class="col-xl-3 col-sm-6 col-md-6 col-12" *ngIf="haseocoverage=='y'">
                            <label>Attach E&O Certificate</label><br>
                            <input type="file" class="form-control" name="eofile" id="eofile"
                                (change)="onFileChanged($event)" accept="application/pdf,image/png,image/jpeg">
                        </div>
                    </div>
                    <br>
                    <h5>Background questions to ask to new agent</h5>
                    <hr>
                    <div class="table-responsive">
                        <table class="table table-responsive table-borderless">
                            <tr>
                                <td class="text-left">
                                    1. Are you now or have you ever been included in litigation with an insurance
                                    company?
                                    <textarea [(ngModel)]="A1" class="form-control" rows="5"
                                        placeholder="Please explain your affirmative answer for the above question"
                                        *ngIf="Q1=='y'"></textarea>
                                </td>
                                <td style="width: 70px"></td>
                                <td style="width: 70px">
                                    <input type="radio" class="form-check-input" id="lq1-1" name="q1" value="y"
                                        [(ngModel)]="Q1">
                                    <label class="form-check-label nolabel" for="lq1-1">Yes</label>
                                </td>
                                <td style="width: 70px">
                                    <input type="radio" class="form-check-input" id="lq1-2" name="q1" value="n"
                                        [(ngModel)]="Q1">
                                    <label class="form-check-label nolabel" for="lq1-2">No</label>
                                </td>

                            </tr>
                            <tr>
                                <td class="text-left">
                                    2. Do you currently have a debit balance with any insurance company?
                                    <textarea [(ngModel)]="A2" class="form-control" rows="5"
                                        placeholder="Please explain your affirmative answer for the above question"
                                        *ngIf="Q2=='y'"></textarea>
                                </td>
                                <td style="width: 70px"></td>
                                <td style="width: 70px">
                                    <input type="radio" class="form-check-input" id="lq2-1" name="q2" value="y"
                                        [(ngModel)]="Q2">
                                    <label class="form-check-label nolabel" for="lq2-1">Yes</label>
                                </td>
                                <td style="width: 70px">
                                    <input type="radio" class="form-check-input" id="lq2-2" name="q2" value="n"
                                        [(ngModel)]="Q2">
                                    <label class="form-check-label nolabel" for="lq2-2">No</label>
                                </td>

                            </tr>
                            <tr>
                                <td class="text-left">
                                    3. Have you ever had your insurance or securities license suspended or revoked?
                                    <textarea [(ngModel)]="A3" class="form-control" rows="5"
                                        placeholder="Please explain your affirmative answer for the above question"
                                        *ngIf="Q3=='y'"></textarea>
                                </td>
                                <td style="width: 70px"></td>
                                <td style="width: 70px">
                                    <input type="radio" class="form-check-input" id="lq3-1" name="q3" value="y"
                                        [(ngModel)]="Q3">
                                    <label class="form-check-label nolabel" for="lq3-1">Yes</label>
                                </td>
                                <td style="width: 70px">
                                    <input type="radio" class="form-check-input" id="lq3-2" name="q3" value="n"
                                        [(ngModel)]="Q3">
                                    <label class="form-check-label nolabel" for="lq3-2">No</label>
                                </td>

                            </tr>
                            <tr>
                                <td class="text-left">
                                    4a. Have you ever been charged with, convicted of, or plead guilty or nolo
                                    contendere ("no contest") to a felony of any kind?
                                    <textarea [(ngModel)]="A4" class="form-control" rows="5"
                                        placeholder="Please explain your affirmative answer for the above question"
                                        *ngIf="Q4=='y'"></textarea>
                                </td>
                                <td style="width: 70px"></td>
                                <td style="width: 70px">
                                    <input type="radio" class="form-check-input" id="lq4-1" name="q4" value="y"
                                        [(ngModel)]="Q4">
                                    <label class="form-check-label nolabel" for="lq4-1">Yes</label>
                                </td>
                                <td style="width: 70px">
                                    <input type="radio" class="form-check-input" id="lq4-2" name="q4" value="n"
                                        [(ngModel)]="Q4">
                                    <label class="form-check-label nolabel" for="lq4-2">No</label>
                                </td>

                            </tr>


                            <tr>
                                <td class="text-left">
                                    4b. Have you ever been charged with, convicted of, or plead guilty or nolo
                                    contendere ("no contest") to a misdemeanor involving insurance or fraud?
                                    <textarea [(ngModel)]="A5" class="form-control" rows="5"
                                        placeholder="Please explain your affirmative answer for the above question"
                                        *ngIf="Q5=='y'"></textarea>
                                </td>
                                <td style="width: 70px"></td>
                                <td style="width: 70px">
                                    <input type="radio" class="form-check-input" id="lq5-1" name="q5" value="y"
                                        [(ngModel)]="Q5">
                                    <label class="form-check-label nolabel" for="lq5-1">Yes</label>
                                </td>
                                <td style="width: 70px">
                                    <input type="radio" class="form-check-input" id="lq5-2" name="q5" value="n"
                                        [(ngModel)]="Q5">
                                    <label class="form-check-label nolabel" for="lq5-2">No</label>
                                </td>

                            </tr>

                            <tr>
                                <td class="text-left">
                                    5a. Has any domestic or foreign court ever found you guilty in connection with
                                    any insurance or investment-related activity?
                                    <textarea [(ngModel)]="A6" class="form-control" rows="5"
                                        placeholder="Please explain your affirmative answer for the above question"
                                        *ngIf="Q6=='y'"></textarea>
                                </td>
                                <td style="width: 70px"></td>
                                <td style="width: 70px">
                                    <input type="radio" class="form-check-input" id="lq6-1" name="q6" value="y"
                                        [(ngModel)]="Q6">
                                    <label class="form-check-label nolabel" for="lq6-1">Yes</label>
                                </td>
                                <td style="width: 70px">
                                    <input type="radio" class="form-check-input" id="lq6-2" name="q6" value="n"
                                        [(ngModel)]="Q6">
                                    <label class="form-check-label nolabel" for="lq6-2">No</label>
                                </td>

                            </tr>

                            <tr>
                                <td class="text-left">
                                    5b. Has any domestic or foreign court ever found that you violated any
                                    insurance-related statutes or regulations?
                                    <textarea [(ngModel)]="A7" class="form-control" rows="5"
                                        placeholder="Please explain your affirmative answer for the above question"
                                        *ngIf="Q7=='y'"></textarea>
                                </td>
                                <td style="width: 70px"></td>
                                <td style="width: 70px">
                                    <input type="radio" class="form-check-input" id="lq7-1" name="q7" value="y"
                                        [(ngModel)]="Q7">
                                    <label class="form-check-label nolabel" for="lq7-1">Yes</label>
                                </td>
                                <td style="width: 70px">
                                    <input type="radio" class="form-check-input" id="lq7-2" name="q7" value="n"
                                        [(ngModel)]="Q7">
                                    <label class="form-check-label nolabel" for="lq7-2">No</label>
                                </td>

                            </tr>

                            <tr>
                                <td class="text-left">
                                    6a. Has any Federal regulatory agency or any state regulatory agency or foreign
                                    financial regulatory authority ever found you to have made a false statement or
                                    omission or been dishonest, unfair or unethical?
                                    <textarea [(ngModel)]="A8" class="form-control" rows="5"
                                        placeholder="Please explain your affirmative answer for the above question"
                                        *ngIf="Q8=='y'"></textarea>
                                </td>
                                <td style="width: 70px"></td>
                                <td style="width: 70px">
                                    <input type="radio" class="form-check-input" id="lq8-1" name="q8" value="y"
                                        [(ngModel)]="Q8">
                                    <label class="form-check-label nolabel" for="lq8-1">Yes</label>
                                </td>
                                <td style="width: 70px">
                                    <input type="radio" class="form-check-input" id="lq8-2" name="q8" value="n"
                                        [(ngModel)]="Q8">
                                    <label class="form-check-label nolabel" for="lq8-2">No</label>
                                </td>

                            </tr>

                            <tr>
                                <td class="text-left">
                                    6b. Has any Federal regulatory agency or any state regulatory agency or foreign
                                    financial regulatory authority ever found you to have been involved in a
                                    violation of insurance or investment regulations or statutes?
                                    <textarea [(ngModel)]="A9" class="form-control" rows="5"
                                        placeholder="Please explain your affirmative answer for the above question"
                                        *ngIf="Q9=='y'"></textarea>
                                </td>
                                <td style="width: 70px"></td>
                                <td style="width: 70px">
                                    <input type="radio" class="form-check-input" id="lq9-1" name="q9" value="y"
                                        [(ngModel)]="Q9">
                                    <label class="form-check-label nolabel" for="lq9-1">Yes</label>
                                </td>
                                <td style="width: 70px">
                                    <input type="radio" class="form-check-input" id="lq9-2" name="q9" value="n"
                                        [(ngModel)]="Q9">
                                    <label class="form-check-label nolabel" for="lq9-2">No</label>
                                </td>

                            </tr>

                            <tr>
                                <td class="text-left">
                                    6c. Has any Federal regulatory agency or any state regulatory agency or foreign
                                    financial regulatory authority ever found you to have been the cause of any
                                    insurance or investment-related business having its authorization to do business
                                    denied, suspended,
                                    revoked or restricted?
                                    <textarea [(ngModel)]="A10" class="form-control" rows="5"
                                        placeholder="Please explain your affirmative answer for the above question"
                                        *ngIf="Q10=='y'"></textarea>
                                </td>
                                <td style="width: 70px"></td>
                                <td style="width: 70px">
                                    <input type="radio" class="form-check-input" id="lq10-1" name="q10" value="y"
                                        [(ngModel)]="Q10">
                                    <label class="form-check-label nolabel" for="lq10-1">Yes</label>
                                </td>
                                <td style="width: 70px">
                                    <input type="radio" class="form-check-input" id="lq10-2" name="q10" value="n"
                                        [(ngModel)]="Q10">
                                    <label class="form-check-label nolabel" for="lq10-2">No</label>
                                </td>

                            </tr>

                            <tr>
                                <td class="text-left">
                                    6d. Has any Federal regulatory agency or any state regulatory agency or foreign
                                    financial regulatory authority ever entered an order against you in connection
                                    with any insurance or investment-related activity?
                                    <textarea [(ngModel)]="A11" class="form-control" rows="5"
                                        placeholder="Please explain your affirmative answer for the above question"
                                        *ngIf="Q11=='y'"></textarea>
                                </td>
                                <td style="width: 70px"></td>
                                <td style="width: 70px">
                                    <input type="radio" class="form-check-input" id="lq11-1" name="q11" value="y"
                                        [(ngModel)]="Q11">
                                    <label class="form-check-label nolabel" for="lq11-1">Yes</label>
                                </td>
                                <td style="width: 70px">
                                    <input type="radio" class="form-check-input" id="lq11-2" name="q11" value="n"
                                        [(ngModel)]="Q11">
                                    <label class="form-check-label nolabel" for="lq11-2">No</label>
                                </td>

                            </tr>

                            <tr>
                                <td class="text-left">
                                    6e. Has any Federal regulatory agency or any state regulatory agency or foreign
                                    financial regulatory authority ever denied, suspended, or revoked your
                                    registration or license or otherwise prevented you from associating with any
                                    insurance or investment-related
                                    business, or disciplined you by restricting your activities?
                                    <textarea [(ngModel)]="A12" class="form-control" rows="5"
                                        placeholder="Please explain your affirmative answer for the above question"
                                        *ngIf="Q12=='y'"></textarea>
                                </td>
                                <td style="width: 70px"></td>
                                <td style="width: 70px">
                                    <input type="radio" class="form-check-input" id="lq12-1" name="q12" value="y"
                                        [(ngModel)]="Q12">
                                    <label class="form-check-label nolabel" for="lq12-1">Yes</label>
                                </td>
                                <td style="width: 70px">
                                    <input type="radio" class="form-check-input" id="lq12-2" name="q12" value="n"
                                        [(ngModel)]="Q12">
                                    <label class="form-check-label nolabel" for="lq12-2">No</label>
                                </td>

                            </tr>

                            <tr>
                                <td class="text-left">
                                    7a. Have you ever been the subject of any insurance or investment-related,
                                    consumer- initiated complaint or proceeding that alleged compensatory damages of
                                    $2,500 or more, or found fraud or the wrongful taking of property?
                                    <textarea [(ngModel)]="A13" class="form-control" rows="5"
                                        placeholder="Please explain your affirmative answer for the above question"
                                        *ngIf="Q13=='y'"></textarea>
                                </td>
                                <td style="width: 70px"></td>
                                <td style="width: 70px">
                                    <input type="radio" class="form-check-input" id="lq13-1" name="q13" value="y"
                                        [(ngModel)]="Q13">
                                    <label class="form-check-label nolabel" for="lq13-1">Yes</label>
                                </td>
                                <td style="width: 70px">
                                    <input type="radio" class="form-check-input" id="lq13-2" name="q13" value="n"
                                        [(ngModel)]="Q13">
                                    <label class="form-check-label nolabel" for="lq13-2">No</label>
                                </td>

                            </tr>

                            <tr>
                                <td class="text-left">
                                    7b. Have you ever been the subject of any insurance or investment-related,
                                    consumer- initiated complaint or proceeding that was settled or decided against
                                    you for $1,000 or more, or found fraud or the wrongful taking of property?
                                    <textarea [(ngModel)]="A14" class="form-control" rows="5"
                                        placeholder="Please explain your affirmative answer for the above question"
                                        *ngIf="Q14=='y'"></textarea>
                                </td>
                                <td style="width: 70px"></td>
                                <td style="width: 70px">
                                    <input type="radio" class="form-check-input" id="lq14-1" name="q14" value="y"
                                        [(ngModel)]="Q14">
                                    <label class="form-check-label nolabel" for="lq14-1">Yes</label>
                                </td>
                                <td style="width: 70px">
                                    <input type="radio" class="form-check-input" id="lq14-2" name="q14" value="n"
                                        [(ngModel)]="Q14">
                                    <label class="form-check-label nolabel" for="lq14-2">No</label>
                                </td>

                            </tr>

                            <tr>
                                <td class="text-left">
                                    8. Are you now the subject of any complaint, investigation, or proceeding that
                                    could result in a "yes" answer to questions 1 - 7?
                                    <textarea [(ngModel)]="A15" class="form-control" rows="5"
                                        placeholder="Please explain your affirmative answer for the above question"
                                        *ngIf="Q15=='y'"></textarea>
                                </td>
                                <td style="width: 70px"></td>
                                <td style="width: 70px">
                                    <input type="radio" class="form-check-input" id="lq15-1" name="q15" value="y"
                                        [(ngModel)]="Q15">
                                    <label class="form-check-label nolabel" for="lq15-1">Yes</label>
                                </td>
                                <td style="width: 70px">
                                    <input type="radio" class="form-check-input" id="lq15-2" name="q15" value="n"
                                        [(ngModel)]="Q15">
                                    <label class="form-check-label nolabel" for="lq15-2">No</label>
                                </td>

                            </tr>



                        </table>
                    </div>
                    <br>
                    <button class="btn btn-warning text-white" (click)="changeTab(3)">Continue </button>
                </p-tabPanel>
                <p-tabPanel header="Marketing Website" class="tabfont"
                    [headerStyle]="{'font-size':'14px', 'margin-top':'2px'}">
                    <br>
                    <h5>Marketing Website Information</h5>
                    <hr>
                    <p>
                        Dylan Consulting LLC provides agents with a dedicated marketing website to enroll members
                        and prospects. Please verify the following information is accurate so that we can add it to
                        your agent's website. Once your agent is approved, he/she can modify
                        his/her website information from within the agent Back Office.
                    </p>

                    <div class="row">

                        <div class="col-xl-3 col-sm-6 col-md-6 col-12">
                            <label>First Name</label><br>
                            <input type="text" maxlength=30 class="form-control" [(ngModel)]="webfirstname">
                        </div>
                        <div class="col-xl-3 col-sm-6 col-md-6 col-12">
                            <label>Last Name</label><br>
                            <input type="text" maxlength=30 class="form-control" [(ngModel)]="weblastname">
                        </div>
                        <div class="col-xl-3 col-sm-6 col-md-6 col-12">
                            <label>Email Address</label><br>
                            <input type="text" maxlength="70" class="form-control" [(ngModel)]="webemail">
                        </div>
                        <div class="col-xl-3 col-sm-6 col-md-6 col-12">
                            <label>Phone Number</label><br>
                            <input type="text" maxlength=30 class="form-control" [(ngModel)]="webphone"
                                mask="(000) 000-0000" [dropSpecialCharacters]="false">
                        </div>
                        <!--
                        <div class="col-md-12" style="display: none;">
                            <label>Full Address</label><br>
                            <input type="text" maxlength="150" class="form-control" [(ngModel)]="webfulladdress">
                        </div>
                        
                        <div class="col-md-12">
                            <label>About Us Summary (up to 3,000 characters)</label><br>
                            <textarea type="text" rows="10" maxlength="3000" class="form-control" [(ngModel)]="webaboutus"></textarea>
                        </div>
                        -->
                    </div>

                    <br>
                    <button class="btn btn-warning text-white" (click)="changeTab(4)">Continue </button>
                </p-tabPanel>



                <p-tabPanel header="Send Agreement" class="tabfont"
                    [headerStyle]="{'font-size':'14px', 'margin-top':'2px'}">
                    <br>
                    <h5>Send Agreement for eSignature</h5>
                    <hr> We consider it a great privilege to partner with your agents. We believe the marketplace
                    needs solutions that can solve immediate needs with products and lifestyle benefits. We look
                    forward to working with your agent.



                    <br><br>
                    <h6>With this signature, I certify that all information provided for this agent is true,
                        accurate and complete to the best of my knowledge:</h6>
                    <br>
                    <label class="text-danger">Expected Signature: {{agentname}}</label>
                    <input [(ngModel)]="esignature" type="text" maxlength="100" class="form-control"
                        placeholder="Type your name...">
                    <br>
                    <button class="btn btn-warning text-white" (click)="SaveAgent()">Send to Agent</button>
                </p-tabPanel>
            </p-tabView>
            <br><br><br>
        </div>

    </div>
</div>




<div id="spinner" *ngIf="showspinner">
    <div class="centeredHorizontal">
        <img src="assets/images/processing.gif" style="width: 50px" />
        <br /><br />
        <span class="text-white">Processing. Please wait...</span>
    </div>
</div>