<div class="container pt-100">
    <div class="about-content">
        <span>AFEUSA</span>
        <h2>Privacy Policy</h2>

        <br> This privacy statement describes how AFEUSA collects and uses the personal information you provide on our website: www.AFEUSA.org. It also describes the choices available to you regarding our use of your personal information and how you can
        access and update this information. How We Use Personal Information
        <br><br>We collect the following personal information from you:<br> • Contact information such as name, email address, mailing address, and phone number<br> • Billing information such as credit card number and billing address<br> • Unique identifiers
        such as username, account number, and password<br> • Preferences information such as product wish lists, order history, and marketing preferences<br> • Information about your computer such as your IP address, browser type, referring/exit pages,
        and operating system, which we automatically gather, as is true of most websites<br><br> We use this information to:<br> • fulfill your order;<br> • send you an order confirmation;<br> • assess the needs of your business to determine suitable
        products;
        <br> • send you requested product or service information;<br> • send product updates or warranty information;<br> • respond to customer service requests;<br> • administer your account;<br> • send you newsletters;<br> • send you marketing communications;
        <br> • respond to your questions and concerns; and<br> • prove our website and marketing efforts.<br><br> Choice/Opt-Out
        <br> You may choose to stop receiving our newsletter or marketing emails by following the unsubscribe instructions included in these emails, or you can contact us at info@AFEUSA.org.<br><br> Information Sharing<br>
        <br>When using our website, any information you provide when using our services to populate a document will be kept strictly confidential.
        <br>We will share your personal information with third parties only in the ways that are described in this privacy statement. We do not sell your personal information to third parties.
        <br>We may provide your personal information to companies that provide services to help us with our business activities, such as shipping your order or offering customer service. In order to provide our services and products, we may provide such
        information to trusted third parties, including, but not limited to, selected third-party marketers and vendors as well as third-party contractors providing services to AFEUSA for the operation of the website. However, your email address will
        only be shared with our mailing partners for the purpose of sending third-party email communications. These emails will be sent from AFEUSA and will be easily identifiable. AFEUSA will use commercially reasonable efforts to limit the use of such
        information by such third parties to the specific uses set forth above. AFEUSA also utilizes electronic and physical security to reduce the risk of improper access to or manipulation of such information during transmission and storage, but cannot
        guarantee the security or integrity of such information. If you are having trouble logging in to your account or need a new password, we can only contact you via the email address associated with your account. We will never give your password
        out over the phone. This means it is important that the email address you provide is valid and will not block emails from AFEUSA.
        <br>AFEUSA may contact you with a particular product offering of interest on our own behalf or on the behalf of third-party business partners. In such cases, your unique personally identifiable information (email, name, address, telephone number)
        will not be transferred to the third party without your explicit consent. In addition, AFEUSA may share data with trusted partners to help us perform statistical analysis, send you emails or postal mail, or provide customer support. All such third
        parties are prohibited from using your personal information except to provide these services to AFEUSA, and they are required to maintain the confidentiality of your information.
        <br>AFEUSA.org will not use or disclose sensitive personal information, such as race, religion, or political affiliations, without your explicit consent. We may also disclose your personal information under the following circumstances: • As required
        by law such as to comply with a subpoena or similar legal process<br> • When we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government
        request
        <br> • If AFEUSA is involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice on our website of any change in ownership or uses of your personal information, as well
        as any choices you may have regarding your personal information<br> • To any other third party with your prior consent to do so.<br><br> Cookies and Other Tracking Technologies<br>
        <br>We may use cookies and similar technologies, for example, to identify your browser or device and keep track of your preferences and profile information. These are used as a convenience feature to load your prior preferences and save you time.
        Cookies are also used to collect general usage and volume statistical information that does not include personal information. Any use of your information collected by cookies on our site (such as Google Analytics or advertising cookies) will be
        used according to our information sharing policy above. You also have the ability to change your browser settings to decline cookies if you so choose. However, declining cookies may prevent you from fully experiencing and interacting with our
        features and Services as intended.
        <br>Google Analytics is a web analysis service provided by Google. Google utilizes the data collected to track and examine your use of our website, to prepare reports on its activities, and to share them with other Google services. Google may
        use the data collected to contextualize and personalize the ads of its own advertising network. Our site has enabled the following Google Analytics Advertising features: Remarketing with Google Analytics, Google Display Network Impression Reporting,
        Google Analytics Demographics and Interest Reporting, and integrated services that require Google Analytics to collect data via advertising cookies and anonymous identifiers. When used in conjunction with Google’s advertising services Google Analytics
        information is linked, by the Google Analytics customer or by Google, using Google technology, with information about visits to multiple sites. You have the ability to opt-out of Google Analytics Advertising features through Ads Settings, Ad Settings
        for mobile apps, or other available means, for instance through the Google Analytics Opt-Out Add On.
        <br><br>Security
        <br>The security of your personal information is important to us. We follow generally accepted industry standards to protect the personal information submitted to us, both during transmission and once we receive it. No method of transmission over
        the Internet, or method of electronic storage, is 100% secure, however. Therefore, we cannot guarantee its absolute security. If you have any questions about security on our website, you can contact us at info@AFEUSA.org.
        <br>We provide two means for you to make a purchase on our site. You can either use our order form or a third-party payment option. If you use our shopping cart, the transmission of sensitive information collected on our order form is encrypted
        using secure socket layer technology (SSL). If you choose to use a third-party payment option to pay for your order, its privacy statement and security practices will apply to your information. We encourage you to read that privacy statement before
        providing your information.
        <br><br>Public Forums
        <br>Our website offers publicly accessible blogs or community forums. You should be aware that any information you provide in these areas may be read, collected, and used by others who access them.
        <br><br>Correcting and Updating Your Personal Information
        <br>To review and update your personal information to ensure it is accurate, contact us at info@AFEUSA.org.
        <br><br>Minors
        <br>AFEUSA.org does not knowingly gather personal information from minors under the age of thirteen. We ask that minors under the age of thirteen only use our website with permission and supervision of a parent or guardian.
        <br><br>Notification of Privacy Statement Changes We reserve the right to update this privacy statement at any time to reflect changes to our information practices. If we make any material changes, then we may notify you by email (sent to the
        email address specified in your account) or by posting the change on this privacy policy page and indicating the date of the most recent change. We encourage you to periodically review this page for the latest information on our privacy practices.
        <br>Contact Information
        <br><br>You can contact us about this privacy statement by writing or emailing us at the address below:
        <br><br>AFEUSA
        <br>16427 North Scottsdale Road, #410,
        <br>Scottsdale, AZ 85254
        <br><br>Phone: (405) 437-1783
        <br>Fax: (888) 289-7001
        <br> Business hours: 8AM-5PM MST Email: info@AFEUSA.org







    </div>
</div>