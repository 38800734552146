import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { FormGroup } from '@angular/forms'
import { SelectedProducts } from '../../../classes/classes'
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-newagent',
  templateUrl: './newagent.component.html',
  styleUrls: ['./newagent.component.css']
})
export class NewagentComponent implements OnInit {
  //new code 
  Data: any;
  Codes: any;

  public productslist: SelectedProducts[] = [];

  isCode: boolean = false;

  APIresult: any;


  allowcode: boolean = true;

  templatecode: any;
  public preloader: boolean = false;

  mycode: string;
  sendemail: boolean = true;
  sendsms: boolean = true;
  myself: string = "";
  myid: string = "";




  showdental: boolean = false;
  showlife: boolean = false;
  showchubb: boolean = false;

  firstname: string = "";
  lastname: string = "";
  email: string = "";
  cellphone: string = "";
  selectedHierarchy: string;
  selectedPaygrid: string;

  HierarchyUserMatch: boolean = true;

  constructor(
    public _httpClient: HttpClient,
    private _router: Router,
    private r: ActivatedRoute,

  ) {
    sessionStorage.removeItem("temp");
    this.getCode();
  }

  isNumber(value: string | number): boolean {
    return ((value != null) &&
      (value !== '') &&
      !isNaN(Number(value.toString())));
  }





  checkAgent(): void {
    if (this.selectedHierarchy == sessionStorage.getItem('plid')) {
      this.HierarchyUserMatch = true;
    }
    else {
      this.HierarchyUserMatch = false;
    }
  }

  yyyymmdd() {
    var x = new Date();
    var y = x.getFullYear().toString();
    var m = (x.getMonth() + 1).toString();
    var d = x.getDate().toString();
    var hs = x.getHours().toString();
    var mn = x.getMinutes().toString();
    var sc = x.getSeconds().toString();

    (d.length == 1) && (d = '0' + d);
    (m.length == 1) && (m = '0' + m);
    (hs.length == 1) && (hs = '0' + hs);
    (mn.length == 1) && (mn = '0' + mn);
    (sc.length == 1) && (sc = '0' + sc);
    var yyyymmdd = y + m + d + hs + mn + sc;
    return yyyymmdd;
  }

  getCode(): void {
    this.templatecode = "CM-" + sessionStorage.getItem("codeidentifier") + "-" + this.yyyymmdd();
  }





  keyPressLettersNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9

    if (charCode >= 48 && charCode <= 57) {
      return true;
      return;
    }

    if (charCode >= 65 && charCode <= 90) {
      return true;
      return;
    }

    if (charCode >= 97 && charCode <= 122) {
      return true;
      return;
    }

    event.preventDefault();
    return false;

  }





  InviteAgent(): void {

    this.templatecode = this.templatecode.trim();


    if (!this.templatecode) {
      Swal.fire({
        title: 'Enter Enrollment Code',
        text: 'Enter the code your prospect agents will utilize to enroll',
        icon: 'warning',
      });
      return;
    }

    let has1: boolean=false;
    let has7: boolean=false;

    for (let result of this.Data) {
      if (result.productid == 1 && result.enabled=="1") {
        has1=true;
      }
      if (result.productid == 7 && result.enabled=="1") {
        has7=true;
      }
    }

    
    if (!has1) {
      Swal.fire({
        title: 'Basic Membership Missing',
        text: 'The Basic Membership is a mandatory selection',
        icon: 'warning',
      });
      return;
    }

    if (!has7) {
      Swal.fire({
        title: 'Silver Membership Missing',
        text: 'The Silver Membership is a mandatory selection',
        icon: 'warning',
      });
      return;
    }

  



    //call API
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/inviteagent.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    //api specific
    body.set('description', encodeURIComponent("SSC for " + this.firstname + " " + this.lastname));
    body.set('code', encodeURIComponent(this.templatecode));
    body.set('data', encodeURIComponent(JSON.stringify(this.Data)));
    body.set('timesallowed', encodeURIComponent("1"));

    body.set('firstname', encodeURIComponent(this.firstname));
    body.set('lastname', encodeURIComponent(this.lastname));
    body.set('email', encodeURIComponent(this.email));
    body.set('cellphone', encodeURIComponent(this.cellphone));
    body.set('newcode', encodeURIComponent(this.allowcode));


    this._httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.APIresult = Array.of(result);
        if (result == null) {
          Swal.fire({
            title: 'API ERROR',
            text: 'There was an error calling AFEUSA API. Please check your internet connection.',
            icon: 'error',
          });
          return;
        }
        else {
          if (this.APIresult[0].success == "1") {
            Swal.fire({
              title: 'Prospect Agent Invited ',
              text: this.firstname + " has been invited to join AFEUSA.",
              icon: 'success',
            });
            this._router.navigate(['../agentsprospects'], { relativeTo: this.r });
            return;
          }

          if (this.APIresult[0].success == "0") {
            Swal.fire({
              title: 'Review your entry',
              text: this.APIresult[0].message,
              icon: 'warning',
            });
            return;
          }

          if (this.APIresult[0].success == "99") {
            Swal.fire({
              title: 'Error',
              text: this.APIresult[0].message,
              icon: 'error',
            });
            return;
          }




        }

      },
      error => {
        Swal.fire({
          title: 'API ERROR',
          text: 'There was an error calling AFEUSA API. Please check your internet connection.',
          icon: 'error',
        });
        return;
      });


  }



  ngOnInit(): void {
    this.GetComp();
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/getagentcodes.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('pl', encodeURIComponent(sessionStorage.getItem('plid')));
    this._httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.Codes = result;
      },
      error => {
        return;
      });

  }

  GetComp(): void {
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/getagentcomp.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('pl', encodeURIComponent(sessionStorage.getItem('plid')));
    this._httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.Data = result;
        for (let product of this.Data) {
          product.agentpercent = 0;
          if (product.productid == 1) {
            product.enabled = "1";
          }
          if (product.productid == 7) {
            product.enabled = "1";
          }
        }
      },
      error => {
        return;
      });



  }


  updatePaygrid(): void {

    //for selected code
    for (let product of this.Data) {
      product.agentpercent = 0;
      product.enabled = "0";
    }
    for (let code of this.Codes) {
      if (code.Code == this.selectedPaygrid) {
        //elements for that code
        for (let products of code.products) {
          //comp Data Feed
          for (let comp of this.Data) {
            if (comp.productid == products.productid) {
              comp.agentpercent = products.payout;
              comp.enabled = "1";

            }
          }
        }
      }
    }
    var NewElement: any = {
      "productid": 0,
      "agentpercent": 0,
      "enabled": "1",
      "newelement": Math.floor(Math.random() * 362548547889)
    }
    //forces DATA to update on SetComp.ts
    this.Data.push(NewElement);
    this.Data = this.Data.slice(0, -1);
  }

}
