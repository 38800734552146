import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SelectItem, FilterService, FilterMatchMode } from 'primeng/api';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-clientinfo',
  templateUrl: './clientinfo.component.html',
  styleUrls: ['./clientinfo.component.css']
})
export class ClientinfoComponent implements OnInit {
  @Input()
  policyid: string;
  //SIDE VARIABLES
  openSide: boolean=false;
  public showDependedBox: boolean = false;
  public showBeneficiaryBox: boolean = false;
  public personData: any = {
    FirstName: "",
    LastName: "",
    BirthDate: "",
    Gender: "",
    Relationship: "",
    Address1: "",
    City: "",
    State: "",
    State_Name: "",
    Zip: "",
    Phone: "",
    IsDisabled: "",
    AFEtype: "",
    PersonCode: ""
  }
  APIresults: any;
  agentinfo: any;
  agentproducts: any;
  memberData: any;
  upline: any;
  public dependents: any;
  public selectedPlanName: string = "";
  public selectedPlanId: string = "";
  public selectedTierCode: string = ""
  constructor(
    public httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private filterService: FilterService
  ) { }

  ngOnInit(): void {
  }

  onRowSelect(id): void {
    this.APIresults = undefined;
    this.dependents = undefined;
    this.selectedPlanName = "";
    this.selectedPlanId = "";
    this.selectedTierCode = "";
    this.agentinfo = undefined;
    this.agentproducts = undefined;
    this.memberData = undefined;
    this.upline = undefined;


  
    this.openSide = true;
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/clients/clientdata.aspx";
    var options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    var body = new URLSearchParams();

    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('policy', encodeURIComponent(id));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.APIresults = result;
        this.agentinfo = this.APIresults.agentinfo;
        this.agentproducts = this.APIresults.agentproducts;
        this.memberData = this.APIresults.memberdata;
        this.upline = this.APIresults.uplineCompensation;
      },
      error => {

      });



  }

}
