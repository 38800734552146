<div style="overflow: hidden;  ">
    <div class="sidebar" style="text-align: left; padding-left: 15px; z-index: 99;">
        <div routerLink="/platform/intro" class="rotated monoicons"
            style="display: table; font-size: 20px; color: #ffffff; margin-left: -5px; margin-top: -50px;">


        </div>






    </div>
    <!--
        <div class="IntroDiv text-center" style="padding-left: 60px;background-image: url(../../assets/images/main-banner8.jpg); background-repeat: no-repeat;background-size: cover;">

-->
    <div class="IntroDiv text-center" style="padding-left: 60px; ">
        <div class="row h-100" *ngIf="!recoverpassword">
            <div class="col-md-4 bg-white hidden-md-down"
                style="padding-top: 80px; padding-left: 150px; padding-right: 70px; ">
                <h5>Welcome to AFEUSA Desk</h5>
                <hr>
                <br>
                <h6>Email Address</h6>
                <input type="text" class="form-control" [(ngModel)]="username" (keyup.enter)="onEnter1()" id="username"
                    name="username">
                <br>
                <h6>Password</h6>
                <input type="password" class="form-control" [(ngModel)]="password" (keyup.enter)="onEnter1()"
                    id="password" name="password">
                <br>
                <button class="btn btn-primary" (click)="doLogin()">Login to your account</button>
                <br><br>
                <a class="link" style="cursor: pointer;" (click)="recoverpassword=true">Forgot your password? click
                    here.</a>
            </div>
            <div class="col-md-8 text-right" style="position:absolute;             bottom:0;             right:0;">
                
                <div class="brand">
                    <img  src="assets/afelogorealwhite.png" style="width:300px;"></div>
                <div class="subbrand">Insurance Management Platform</div>
                <div class="text">Service Endpoint: {{server}}</div>
                <div class="text">Release: {{version}}</div>
                <div class="text" style="color: white;">Developed by HENKOMOSS, LLC .·. Advanced applications and portals</div>
                <br><br>
            </div>
        </div>

        <div class="row h-100" *ngIf="recoverpassword">
            <div class="col-md-4 bg-white hidden-md-down"
                style="padding-top: 80px; padding-left: 150px; padding-right: 70px; ">
                <h5>Recover Your AFEUSA Password</h5>
                <hr>
                <br>
                <h6>Email Address</h6>
                <input type="text" class="form-control" [(ngModel)]="username" (keyup.enter)="onEnter2()" id="username"
                    name="password">
                <br>

                <button class="btn btn-primary" (click)="doPassword()">Recover Password</button>
                <br><br>
                <a class="link" style="cursor: pointer;" (click)="recoverpassword=false">Back to Login</a>
            </div>
            <div class="col-md-8 text-right" style="position:absolute; bottom:0; right:0;">
                
                <div class="brand">
                    <img  src="assets/afelogorealwhite.png" style="width:300px;"></div>
                <div class="subbrand">Insurance Management Platform</div>
                <div class="text">Service Endpoint: {{server}}</div>
                <div class="text">Release: {{version}}</div>
                <div class="text" style="color: white;">Developed by HENKOMOSS, LLC .·. Advanced applications and portals</div>
                <br><br>

            </div>
        </div>

    </div>
</div>