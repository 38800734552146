import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-p9',
  templateUrl: './p9.component.html',
  styleUrls: ['./p9.component.css']
})
export class P9Component implements OnInit {
  @Output("Calculate") Calculate: EventEmitter<any> = new EventEmitter();
  @Input() Data: any;
  public matrix: any;
  public selection: any;

  public LightBox: boolean = false;
  public PlanName: string = "";
  public AnnualDeductible: string = "";
  public MaximumBenefit: string = "";
  public Preventive: string = "";
  public BasicServices: string = "";
  public Fee: number=0;

  public SelectedCol: number=0;
  public SelectedRow: number=0;
  public SelectedAmount: number=0
  public SelectedName: string="";
  public SelectedRowCol: string="";
  constructor(
    private toastr: ToastrService
  ) { }

  
  ngOnInit(): void {
    try {
      this.matrix = this.Data.hooray;
      this.selection = this.Data.selections.hooray;
      this.SelectedRowCol=this.Data.selections.hooray.row.toString() + this.Data.selections.hooray.col.toString()
    }
    catch (e){

    }
   
  }

  View(row, col, amount, name): void {
    this.SelectedCol=col;
    this.SelectedRow=row;
    this.SelectedAmount=amount;
    this.SelectedName=name

  }

  SetPlan(): void {
    this.SelectedRowCol=this.SelectedRow.toString() + this.SelectedCol.toString()

    this.Data.selections.hooray.selection=0
    this.Data.selections.hooray.amount= 0;
    this.Data.selections.hooray.col=0;
    this.Data.selections.hooray.row=0;
    this.Data.selections.hooray.name = "";

    this.Data.selections.hooray.selection=this.SelectedRow.toString() + this.SelectedCol.toString()
    this.Data.selections.hooray.amount= +this.SelectedAmount;
    this.Data.selections.hooray.col=this.SelectedCol;
    this.Data.selections.hooray.row=this.SelectedRow;
    this.Data.selections.hooray.name = this.SelectedName;
  
    this.toastr.success('Plan added to your cart!', 'Plan Added');
    this.Calculate.emit();
    this.LightBox=false;
  }

}
