
<div class="level1page ">

    <div class="stick-ontop">
        <h4 style="color:#f1f1f1; padding-top: 20px; ">
            Tools
        </h4>
        <hr>

        <div>
            <div class="row">
                <div class="col-6 col-md-4 col-xl-2">
                    <div class="productbox2 mousepointer" [routerLink]="['notesmodule']" >
                        <div class="text-left">
                            <span class="superfont">Notes</span>
                            <br><span class="smallfont">General Notes Management</span>
                        </div>
                    </div>
                </div>
                
                             
            </div>
        </div>
        <br>



    </div>

   
    <div >
        <div class="subbox text-left" >
            <router-outlet></router-outlet>
        </div>
    </div>
    <br>
</div>



<!--
<div class="fixed-top" style="z-index: 90;">

    <div style="padding-left: 90px;">
        <nav class="navbar navbar-expand-lg navbar-dark" style="background-color: #3e669c;">
            <a class="navbar-brand" href="#">
                  Account Setup: 
                </a>

            <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
                  <span class="navbar-toggler-icon"></span>
                </button>


            <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item">
                        <a class="nav-link" routerLink="profile" routerLinkActive="btn btn-sm btn-dark">Profile</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" routerLink="updateaccount" routerLinkActive="btn btn-sm btn-dark">Update Account</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" routerLink="password" routerLinkActive="btn btn-sm btn-dark">Password</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="agentscodesmanager/customlist" routerLinkActive="btn btn-sm btn-dark">Invite Codes</a>
                    </li>
                    <li class="nav-item">
                        <a *ngIf="Afetype=='24485168'" class="nav-link" routerLink="../corporate/corpagents" routerLinkActive="btn btn-sm btn-dark">Corporate Backoffice</a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</div>
<div class="body-text" style="z-index: -2;">
    <router-outlet></router-outlet>
</div>

-->