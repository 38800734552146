<h4>
    Home Page</h4>
<hr>
<div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-6">
        <div class="alert alert-custom" style="min-height: 380px;">
            <h5>HERO (Banner) Setup</h5>
            <hr>
            <p>This is the main image, displayed on top of your website together with a tagline.</p>
            <p>Depending on the framework (website look and feel) you selected, this option may not be available. For websites with HERO banners, please enter the tagline you would like to display:</p>
            <h6>HERO Tagline:</h6>
            <input type="text" maxlength="40" class="form-control">
            <br>
            <h6>HERO Secondary Tagline:</h6>
            <input type="text" maxlength="40" class="form-control">
        </div>
    </div>
    <div class="col-12 col-sm-12 col-md-12 col-lg-6">
        <div class="alert alert-custom" style="min-height: 380px;">
            <h5>Shortcut Summary Sections</h5>
            <hr>
            <p>These shortcuts are a summary of your pages. It is good to have shortcuts on your home page, specially for mobile users.</p>
            <label><input type="checkbox" name="checkbox" value="value" style="margin-right: 10px;">Show Products Summary</label><br>
            <label><input type="checkbox" name="checkbox" value="value" style="margin-right: 10px;">Show Latest Blog & News entries</label><br>
            <label><input type="checkbox" name="checkbox" value="value" style="margin-right: 10px;">Show Customers Testimonials</label><br>
            <label><input type="checkbox" name="checkbox" value="value" style="margin-right: 10px;">Show Profile Picture, Name and Phone Number.</label><br>
            <label><input type="checkbox" name="checkbox" value="value" style="margin-right: 10px;">Include Quote Form.</label><br>
            <label><input type="checkbox" name="checkbox" value="value" style="margin-right: 10px;">Allow Visitors to Sign Up for News & Blogs.</label><br>

        </div>
    </div>
</div>
<div class="alert alert-custom">
    <h5>Personalized section you can add to the home page</h5>
    <hr>
    <p>This is a personalized section that will be displayed right after your HERO image and tagline. You can enter HTML content on this area, or just a regular text.</p>

    <angular-editor [placeholder]=" 'Enter text here...' " [config]="editorConfig " [(ngModel)]="note"></angular-editor>

</div>
<br><br><br><br><br><br>

<div id="footer">
    <button class="btn btn-light "><i class="fa fa-upload"></i> Save Home Page Changes</button>

</div>