<h4>Update NIPR data</h4>
<hr>
<br>

Superuser Password: <br>
<input type="password" class="form-control" style="width:400px" maxlength="50" [(ngModel)]="Password">
<br>
<div class="alert alert-danger">
    <h4>Warning</h4>
    <div>This process may take several minutes to Execute.</div>
</div>
<button class="btn btn-danger" (click)="runNIPR()">RUN API</button>
<div id="spinner" style="height: 100vh;" *ngIf="ShowSpinner">
    <div class="centered">
        <img src="assets/images/processing.gif" style="width:50px;">
        <br><br>
        <span class="text-white">Updating NIPR data. This may take a few minutes.<br>PLEASE WAIT...</span>
    </div>
</div>