import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-edituser',
  templateUrl: './edituser.component.html',
  styleUrls: ['./edituser.component.css']
})
export class EdituserComponent implements OnInit {
  userid: string;
  APIresult: any;
  Users: any;
  payload: any = {
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    password2: "",
    status: 1,
    permissions: {
      application: false,
      impersonation: false,
      agentsmodule: false,
      websites: false,
      commissions: false,
      banking: false,
      reports: false
    }
  }
    constructor(
      public _httpClient: HttpClient,
      private _router: Router,
      private r: ActivatedRoute,
    ) {
      this.r.params.subscribe((params: Params) => {
        this.userid = params.id;

        var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/usersGetinfo.aspx";    // set header for http call
      let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
      // set body
      let body = new URLSearchParams();
      body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
      body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
      body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
      body.set('userid', encodeURIComponent(this.userid));
      this._httpClient.post(APIURL, body.toString(), options).subscribe(
        result => {
              let response: any = result;
              
              if (response.success=="0") {
                Swal.fire('Attention', this.Users.message, 'warning');
                return;
              };
              if (response.success=="1") {
                  this.payload=response.payload;
              }
        },
        error => {
  
        });

      });
      

    }
  
    saveChanges(): void {
      var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/usersSaveChanges.aspx";    // set header for http call
      let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
      // set body
      let body = new URLSearchParams();
      body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
      body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
      body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
      body.set('payload', encodeURIComponent(JSON.stringify(this.payload)));
      this._httpClient.post(APIURL, body.toString(), options).subscribe(
        result => {
          this.APIresult = Array.of(result);
              this.Users = result;
              if (this.Users.success=="0") {
                Swal.fire('Attention', this.Users.message, 'warning');
                return;
              };
              if (this.Users.success=="1") {
                Swal.fire('User Updated', this.Users.message, 'info');
                this._router.navigate(['../../listusers'], { relativeTo: this.r });
                
                return;
              }
        },
        error => {
  
        });
    }    
      
    
    ngOnInit(): void {
    }
  
  }
  