<h4>Website Leads</h4>

<hr> Display the quotes requested by your customers. This screen displays the last 2,000 records.

<br><br>
<span>

    <input class="form-control" pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
        placeholder="Search criteria for every field..." />
</span>
<br>

<div class="minheight">
    <p-table #dt [value]="Quotes" [rows]="25" [showCurrentPageReport]="true" [rowsPerPageOptions]="[25,50,200,1000]" styleClass="p-datatable-customers" [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} applications"
        [filterDelay]="0" [globalFilterFields]="['datetime','firstname','lastname','email', 'state', 'dob', 'age', 'website']" [resizableColumns]="true" [autoLayout]="true" >

        <ng-template pTemplate="header">
            <tr class="FontRow f13">
                <th [pSortableColumn]="'datetime'">Date</th>
                <th [pSortableColumn]="'firstname'">First Name</th>
                <th [pSortableColumn]="'lastname'">Last Name</th>
                <th [pSortableColumn]="'email'">Email Address</th>
                <th [pSortableColumn]="'state'">State</th>
                <th [pSortableColumn]="'dob'">DOB</th>
                <th [pSortableColumn]="'age'">Age</th>
                <th [pSortableColumn]="'website'">Website</th>
                <th ></th>
            </tr>
            <tr>
                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'datetime', 'contains')" >
                </th>
                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'firstname', 'contains')" >
                </th>

                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'lastname', 'contains')" >
                </th>

                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'email', 'contains')" >

                </th>
                
                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'state', 'contains')" >

                </th>
                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'dob', 'contains')" >

                </th>
                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'age', 'contains')" >

                </th>
                <th>
                    <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value, 'website', 'contains')" >

                </th>
                <th></th>

            </tr>
        </ng-template>




        <ng-template pTemplate="body" let-Quotes>
            <tr class="FontRow f13" >
                <td class="SmallFont">{{Quotes.datetime}}</td>
                <td class="SmallFont">{{Quotes.firstname}}</td>
                <td class="SmallFont">{{Quotes.lastname}}</td>
                <td class="SmallFont">{{Quotes.email}}</td>
                <td class="SmallFont">{{Quotes.state}}</td>
                <td class="SmallFont">{{Quotes.dob}}</td>
                <td class="SmallFont">{{Quotes.age}}</td>
                <td class="SmallFont">{{Quotes.websiteurl}}</td>
                <td>
                <!--
                    <button class="btn btn-primary btn-sm" (click)="clickRecord(Quotes.quoteid)" style="margin-right: 10px;">Get Application</button>
                    -->
                <button class="btn btn-danger btn-sm" (click)="clickDelete(Quotes.quoteid)">Delete</button></td>
            </tr>
        </ng-template>
    </p-table>
</div>




<div id="spinner" style="height: 100vh;" *ngIf="ShowSpinner">
    <div class="centered">
        <img src="assets/images/processing.gif" style="width:50px;">
        <br><br>
        <span class="text-white">Processing Enrollment. Please wait...</span>
    </div>
</div>

