import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-updatenipr',
  templateUrl: './updatenipr.component.html',
  styleUrls: ['./updatenipr.component.css']
})
export class UpdateniprComponent implements OnInit {
  public Password: string;
  public ShowSpinner: boolean = false;
  constructor(
    public httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
  }

  runNIPR(): void {
    if (!this.Password) {
      Swal.fire('Enter Superuser Password', "Superuser Password is a mandatory field. ", 'error');
      return;
    }
    if (this.Password != "Puccino703!") {
      Swal.fire('Try Again', "Superuser Password does not match. ", 'error');
      return;
    }
    this.ShowSpinner=true;
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/corporate/getnipr.aspx";
        var options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
        var body = new URLSearchParams();

        body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
        body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
        body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
        body.set('nothing', 'nada')
        this.httpClient.post(APIURL, body.toString(), options).subscribe(
          result => {
            var APIRESULT: any = result;
            if (APIRESULT.success == "1") {
              Swal.fire('NIPR Updated', APIRESULT.message, 'success');
              this.ShowSpinner=false;
              return;
            }
            else{
              
              Swal.fire('System Message', APIRESULT.message, 'error');
              this.ShowSpinner=false;
              return;
            }
          },
          error => {

          });


  }

}
