import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'app-sudeletecommission',
  templateUrl: './sudeletecommission.component.html',
  styleUrls: ['./sudeletecommission.component.css']
})
export class SudeletecommissionComponent implements OnInit {
  public PolicyId: string;
  public Password: string;
  constructor(
    public httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
  }

  
  DeleteCommission(): void {

    if (!this.PolicyId) {
      Swal.fire('Enter Policy ID', "Policy ID is a mandatory field. ", 'error');
      return;
    }

    if (!this.Password) {
      Swal.fire('Enter Superuser Password', "Superuser Password is a mandatory field. ", 'error');
      return;
    }

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this process. Proceed with delete commissions?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete Now!'
    }).then((result) => {
      if (result.isConfirmed) {
        var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/corporate/sudeletecommission.aspx";
        var options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
        var body = new URLSearchParams();

        body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
        body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
        body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));

        body.set('policyid', this.PolicyId);
        body.set('password', encodeURIComponent(this.Password));

        this.httpClient.post(APIURL, body.toString(), options).subscribe(
          result => {
            var APIRESULT: any = result;
            if (APIRESULT.success == "1") {
              Swal.fire('Commissions Deleted', "Commissions for this policy have been deleted. ", 'success');
              this.Password="";
              this.PolicyId="";
              return;
            }
            else{
              Swal.fire('System Message', APIRESULT.message, 'error');
              return;
            }
          },
          error => {

          });



      }
    })


  }

}
