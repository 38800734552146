import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-newuser',
  templateUrl: './newuser.component.html',
  styleUrls: ['./newuser.component.css']
})
export class NewuserComponent implements OnInit {
  APIresult: any;
  Users: any;
  payload: any = {
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    password2: "",
    status: 1,
    permissions: {
      application: true,
      impersonation: true,
      agentsmodule: true,
      websites: true,
      commissions: false,
      banking: false,
      reports: false
    }
  }
  constructor(
    public _httpClient: HttpClient,
    private _router: Router,
    private r: ActivatedRoute,
  ) {

  }

  createUser(): void {    
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/agents/usersNew.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    body.set('payload', encodeURIComponent(JSON.stringify(this.payload)));
    this._httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.APIresult = Array.of(result);
        this.Users = result;
        if (this.Users.success == "0") {
          Swal.fire('Attention', this.Users.message, 'warning');
          return;
        };
        if (this.Users.success == "1") {
          Swal.fire('User Created', this.Users.message, 'info');

          this._router.navigate(['../listusers'], { relativeTo: this.r });

          return;
        }
      },
      error => {

      });
  }


  ngOnInit(): void {
  }

}
