<h4>Edit Agent Invite</h4>
<hr>
<div class="w100">

    <div class="bShadow-5">
        <br>
        <h5>Prospect Agent Information</h5>
        <hr>
        <div class="row">

            <div class="col-xl-2 col-lg-4 col-sm-6 col-md-4 col-sm-6 col-12">
                <label>First Name</label>
                <input type="text" maxlength=50 class="form-control" [(ngModel)]="firstname">
            </div>
            <div class="col-xl-2 col-lg-4 col-sm-6 col-md-4 col-sm-6 col-12">
                <label>Last Name</label>
                <input type="text" maxlength=50 class="form-control" [(ngModel)]="lastname">
            </div>
            <div class="col-xl-2 col-lg-4 col-sm-6 col-md-4 col-sm-6 col-12">
                <label>Email Address</label>
                <input type="text" maxlength=100 class="form-control" [(ngModel)]="email">
            </div>
            <div class="col-xl-2 col-lg-4 col-sm-6 col-md-4 col-sm-6 col-12">
                <label>Cell Phone</label>
                <input type="text " maxlength=50 class="form-control " mask="(000) 000-0000" [(ngModel)]="cellphone" [dropSpecialCharacters]="false">
            </div>
            <!--
            <div class="col-xl-2 col-lg-4 col-sm-6 col-md-4 col-sm-6 col-12">
                <label>Select Hierarchy</label>
                <select class="form-control" (change)="checkAgent()" [(ngModel)]="selectedHierarchy">
                    <option value="{{myid}}">+ {{myself}}</option>
                    <option value="{{myid}}">---------------------------------------------</option>
                    <option *ngFor="let agent of APIdownline" value="{{agent.id}}">{{agent.agentname}}</option>

                </select>
            </div>
            -->
            

            <div class="col-xl-2 col-lg-4 col-sm-6 col-md-4 col-sm-6 col-12">
                <label>Actions</label><br>
                <button class="btn btn-sm btn-danger" style="margin-right: 5px;" (click)="deleteProspect()">Delete</button>
                <button class="btn btn-sm btn-primary" (click)="resendEmail()">Send Email</button>
                <input [disabled]="!allowcode" class="form-control" (keypress)="keyPressLettersNumbers($event)" style="text-transform:uppercase; display: none" [(ngModel)]="templatecode" type="text" maxlength="15">

            </div>

        </div>
        <br>
        <app-setcomp [Data]="Data" ></app-setcomp>
        <br><br>
        <button class="btn btn-primary text-white " style="margin-right: 10px;" (click)="InviteAgent()"><i class="fa fa-user"></i>
            Save Changes and Resubmit Email</button>
            <br><br>
    </div>
    <br>
    <br>
    <br>
    <br>
</div>
