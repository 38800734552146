import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2'
import { SelectedProducts } from '../../classes/classes'

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.css']
})
export class ActivateComponent implements OnInit {
  public JSONResp;
  public AllowCreate: boolean = false;
  npnInd: any;
  npnAg: any;

  public productslist: SelectedProducts[] = [];


  public AgentType: string; //business or individual

  public showspinner: boolean = false;


  //**********************+ */
  //step 1 Personal Data
  //**********************+ */
  fullcompanyname: string = "";
  firstname: string = "";
  middlename: string = "";
  lastname: string = "";
  address: string = "";
  address2: string = "";
  city: string = "";
  state: string = "";
  zip: string = "";
  cellphone: string = "";
  businessphone: string = "";
  faxnumber: string = "";
  contactemail: string = "";
  email: string = "";
  password: string = "";
  password2: string = "";
  agreemobile: boolean = false;
  dob: string = "";
  dlnumber: string = "";
  dlstate: string = "";


  //business only
  domicilestate: string = "";
  owners: string = "";
  authfirstname: string = "";
  authlastname: string = "";
  authemail: string = "";
  authphone: string = "";
  hasstaffmember: string = "N";


  //**********************+ */
  //step 2 Compensation
  //**********************+ */
  payee: string = "";
  taxid: string = "";
  routing: string = "";
  account: string = "";
  accounttype: string = "";
  bankname: string = "";
  beneficiaryname: string = "";

  //**********************+ */
  //step 3 License
  //**********************+ */
  typeofbusiness: string = "";
  typeoflicense: string = "";
  residentlicense: string = "";
  residentstate: string = "";
  npmnumber: string = "";
  haseocoverage: string = "y";
  eoamount: string = "";
  eofile: File;
  eoexpiration: string = "";

  public APIresult: any;
  public APIPDFresult: any;
  public Q1: string;
  public Q2: string;
  public Q3: string;
  public Q4: string;
  public Q5: string;
  public Q6: string;
  public Q7: string;
  public Q8: string;
  public Q9: string;
  public Q10: string;
  public Q11: string;
  public Q12: string;
  public Q13: string;
  public Q14: string;
  public Q15: string;

  A1: string = "";
  A2: string = "";
  A3: string = "";
  A4: string = "";
  A5: string = "";
  A6: string = "";
  A7: string = "";
  A8: string = "";
  A9: string = "";
  A10: string = "";
  A11: string = "";
  A12: string = "";
  A13: string = "";
  A14: string = "";
  A15: string = "";


  //**********************+ */
  //step 4 Website
  //**********************+ */
  webcompanyname: string = "";
  webfirstname: string = "";
  weblastname: string = "";
  webemail: string = "";
  webphone: string = "";
  webfulladdress: string = "";
  webaboutus: string = "";

  //**********************+ */
  //step 5 Review and Sign
  //**********************+ */
  agreesales: boolean = false;
  agreemarketing: boolean = false;
  esignature: string = "";
  pdfSrc: string;


  //titles and operations not sent to the API
  public addressTitle: string;
  public havestaff: boolean = false;
  public tabindex: number = 0;
  public inputError: boolean = true;
  public doPDF: boolean = false;


  public USstates = [
    {
      "Id": 83,
      "Code": "AL",
      "Name": "Alabama"
    },
    {
      "Id": 82,
      "Code": "AK",
      "Name": "Alaska"
    },
    {
      "Id": 87,
      "Code": "AZ",
      "Name": "Arizona"
    },
    {
      "Id": 85,
      "Code": "AR",
      "Name": "Arkansas"
    },
    {
      "Id": 88,
      "Code": "CA",
      "Name": "California"
    },
    {
      "Id": 89,
      "Code": "CO",
      "Name": "Colorado"
    },
    {
      "Id": 90,
      "Code": "CT",
      "Name": "Connecticut"
    },
    {
      "Id": 92,
      "Code": "DE",
      "Name": "Delaware"
    },
    {
      "Id": 91,
      "Code": "DC",
      "Name": "District of Columbia"
    },
    {
      "Id": 93,
      "Code": "FL",
      "Name": "Florida"
    },
    {
      "Id": 95,
      "Code": "GA",
      "Name": "Georgia"
    },
    {
      "Id": 96,
      "Code": "GU",
      "Name": "Guam"
    },
    {
      "Id": 97,
      "Code": "HI",
      "Name": "Hawaii"
    },
    {
      "Id": 99,
      "Code": "ID",
      "Name": "Idaho"
    },
    {
      "Id": 100,
      "Code": "IL",
      "Name": "Illinois"
    },
    {
      "Id": 101,
      "Code": "IN",
      "Name": "Indiana"
    },
    {
      "Id": 98,
      "Code": "IA",
      "Name": "Iowa"
    },
    {
      "Id": 102,
      "Code": "KS",
      "Name": "Kansas"
    },
    {
      "Id": 103,
      "Code": "KY",
      "Name": "Kentucky"
    },
    {
      "Id": 104,
      "Code": "LA",
      "Name": "Louisiana"
    },
    {
      "Id": 107,
      "Code": "ME",
      "Name": "Maine"
    },
    {
      "Id": 106,
      "Code": "MD",
      "Name": "Maryland"
    },
    {
      "Id": 105,
      "Code": "MA",
      "Name": "Massachusetts"
    },
    {
      "Id": 109,
      "Code": "MI",
      "Name": "Michigan"
    },
    {
      "Id": 110,
      "Code": "MN",
      "Name": "Minnesota"
    },
    {
      "Id": 113,
      "Code": "MS",
      "Name": "Mississippi"
    },
    {
      "Id": 111,
      "Code": "MO",
      "Name": "Missouri"
    },
    {
      "Id": 114,
      "Code": "MT",
      "Name": "Montana"
    },
    {
      "Id": 117,
      "Code": "NE",
      "Name": "Nebraska"
    },
    {
      "Id": 121,
      "Code": "NV",
      "Name": "Nevada"
    },
    {
      "Id": 118,
      "Code": "NH",
      "Name": "New Hampshire"
    },
    {
      "Id": 119,
      "Code": "NJ",
      "Name": "New Jersey"
    },
    {
      "Id": 120,
      "Code": "NM",
      "Name": "New Mexico"
    },
    {
      "Id": 122,
      "Code": "NY",
      "Name": "New York"
    },
    {
      "Id": 115,
      "Code": "NC",
      "Name": "North Carolina"
    },
    {
      "Id": 116,
      "Code": "ND",
      "Name": "North Dakota"
    },
    {
      "Id": 79,
      "Code": "00",
      "Name": "Not Determined"
    },
    {
      "Id": 123,
      "Code": "OH",
      "Name": "Ohio"
    },
    {
      "Id": 124,
      "Code": "OK",
      "Name": "Oklahoma"
    },
    {
      "Id": 125,
      "Code": "OR",
      "Name": "Oregon"
    },
    {
      "Id": 126,
      "Code": "PA",
      "Name": "Pennsylvania"
    },
    {
      "Id": 127,
      "Code": "PR",
      "Name": "Puerto Rico"
    },
    {
      "Id": 128,
      "Code": "RI",
      "Name": "Rhode Island"
    },
    {
      "Id": 129,
      "Code": "SC",
      "Name": "South Carolina"
    },
    {
      "Id": 130,
      "Code": "SD",
      "Name": "South Dakota"
    },
    {
      "Id": 131,
      "Code": "TN",
      "Name": "Tennessee"
    },
    {
      "Id": 132,
      "Code": "TX",
      "Name": "Texas"
    },
    {
      "Id": 133,
      "Code": "UT",
      "Name": "Utah"
    },
    {
      "Id": 136,
      "Code": "VT",
      "Name": "Vermont"
    },
    {
      "Id": 11142,
      "Code": "VI",
      "Name": "Virgin Islands"
    },
    {
      "Id": 134,
      "Code": "VA",
      "Name": "Virginia"
    },
    {
      "Id": 137,
      "Code": "WA",
      "Name": "Washington"
    },
    {
      "Id": 139,
      "Code": "WV",
      "Name": "West Virginia"
    },
    {
      "Id": 138,
      "Code": "WI",
      "Name": "Wisconsin"
    },
    {
      "Id": 140,
      "Code": "WY",
      "Name": "Wyoming"
    }
  ]


  // custom paygrid


  isCode: boolean = false;

  APIafe1: any;
  APIafe2: any;
  APIdental: any;
  APIlife: any;
  APIchubb: any;
  APItemplates: any;
  APIdownline: any;

  allowcode: boolean = true;

  templatecode: any;
  public preloader: boolean = false;

  mycode: string;
  sendemail: boolean = true;
  sendsms: boolean = true;
  myself: string = "";
  myid: string = "";

  allowafe1: boolean = true;
  allowafe2: boolean = true;
  allowdental: boolean = false;
  allowlife: boolean = false;
  allowchubb: boolean = false;

  myafe1: number;
  myafe2: number;
  mydental: number;
  mylife: number;
  mychubb: number;

  selectedafe1: number = 0;
  selectedafe2: number = 0;
  selecteddental: number = 0;
  selectedlife: number = 0;
  selectedchubb: number = 0;

  showdental: boolean = false;
  showlife: boolean = false;
  showchubb: boolean = false;
  selectedPaygrid: string;

  //for this specific module
  agentname: string;


  constructor(
    public httpClient: HttpClient,
    private _router: Router,
    private r: ActivatedRoute,
  ) {

    this.GetAgentInfo();
  }

  ngOnInit(): void {
  }


  GetAgentInfo(): void {
    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/getActivationData.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    // set body
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.APIresult = Array.of(result);
        if (result == null) {

        }
        else {
          if (this.APIresult[0].success == "1") {
            this.agentname = this.APIresult[0].agentinfo.firstname + " " + this.APIresult[0].agentinfo.lastname

            for (let recs of this.APIresult[0].npnInfo) {
              if (recs.type == "individual") {
                this.npnInd = recs;
              };
              if (recs.type == "agency") {
                this.npnAg = recs;
              };
            }
            if (!this.APIresult[0].agentinfo.licTypeOfBusiness) {
              this.APIresult[0].agentinfo.licTypeOfBusiness="Individual"
            }
            return;
          }

        }

      },
      error => {

      });
  }


  changeTab(value: number): void {

    this.CopyToWebsite();
    this.CheckEntry(value);

  }


  onTabChange(event): void {
    this.tabindex = event.index;
    this.doPDF = false;
    this.CopyToWebsite();
  }


  CopyToWebsite(): void {
    if (!this.webcompanyname) {
      this.webcompanyname = this.fullcompanyname;
    }
    if (!this.webfirstname) {
      this.webfirstname = this.firstname;
    }
    if (!this.weblastname) {
      this.weblastname = this.lastname;
    }
    if (!this.webemail) {
      this.webemail = this.contactemail;
    }

    if (!this.webphone) {
      this.webphone = this.cellphone;
    }

  }



  CheckEntry(tab: number): void {
    this.inputError = false;

    //check tab 1
    if (this.AgentType == 'business') {
      if (!this.fullcompanyname) { this.WarningMessage("Enter your legal name"); this.tabindex = 0; this.inputError = true; return; }
      if (!this.domicilestate) { this.WarningMessage("Select your domicile state"); this.tabindex = 0; this.inputError = true; return; }
      if (!this.owners) { this.WarningMessage("Enter your company's owners names"); this.tabindex = 0; this.inputError = true; return; }
      if (this.havestaff == true) {
        if (!this.authfirstname) { this.WarningMessage("Enter the first name for your authorized staff member"); this.tabindex = 0; this.inputError = true; this.inputError = true; return; }
        if (!this.authlastname) { this.WarningMessage("Enter the last name for your authorized staff member"); this.tabindex = 0; this.inputError = true; return; }
        if (!this.authemail) { this.WarningMessage("Enter the email address for your authorized staff member"); this.tabindex = 0; this.inputError = true; return; }
        if (this.validateEmail(this.authemail) == false) { this.WarningMessage("Enter a valid email address for your authorized staff member"); this.tabindex = 0; this.inputError = true; return; }
        if (!this.authphone) { this.WarningMessage("Enter the phone number name for the authorized staff"); this.tabindex = 0; this.inputError = true; return; }
      }
    }
    if (!this.APIresult[0].agentinfo.firstname) { this.WarningMessage("Enter your first name"); this.tabindex = 0; this.inputError = true; return; }
    if (!this.APIresult[0].agentinfo.lastname) { this.WarningMessage("Enter your last name"); this.tabindex = 0; this.inputError = true; return; }
    if (!this.IsDate(this.APIresult[0].agentinfo.DOB)) { this.WarningMessage("Enter a valid date of birth. Must be 18 or older to join AFEUSA."); this.tabindex = 0; this.inputError = true; return; }
    if (!this.APIresult[0].agentinfo.address) { this.WarningMessage("Enter your address"); this.tabindex = 0; this.inputError = true; return; }
    if (!this.APIresult[0].agentinfo.city) { this.WarningMessage("Enter your city"); this.tabindex = 0; this.inputError = true; return; }
    if (!this.APIresult[0].agentinfo.state) { this.WarningMessage("Select your state"); this.tabindex = 0; this.inputError = true; return; }
    if (this.isNumber(this.APIresult[0].agentinfo.zip) == false) { this.WarningMessage("Enter your ZIP code"); this.tabindex = 0; this.inputError = true; return; }

    if (!this.APIresult[0].agentinfo.companyname && this.APIresult[0].agentinfo.type == 'individual') {
      this.APIresult[0].agentinfo.companyname = this.APIresult[0].agentinfo.firstname + " " + this.APIresult[0].agentinfo.lastname;
    }


    if (!this.APIresult[0].agentinfo.cellphone) { this.WarningMessage("Enter your Cell Phone number"); this.tabindex = 0; this.inputError = true; return; }

    if (tab == 1) {
      this.tabindex = tab;
      window.scrollTo(0, 0)
      return
    }
    // end check tab 1


    //check step 2
    if (!this.APIresult[0].w9.Payee) { this.WarningMessage("Enter first and last name"); this.tabindex = 1; this.inputError = true; return; }
    if (!this.APIresult[0].w9.TaxID) { this.WarningMessage("Enter Tax ID (SSN or EIN) for payee"); this.tabindex = 1; this.inputError = true; return; }
    if (!this.APIresult[0].w9.Routing) { this.WarningMessage("Enter routing number"); this.tabindex = 1; this.inputError = true; return; }
    if (this.APIresult[0].w9.Routing.length < 8) { this.WarningMessage("Your routing number should be at least 8 characters long"); this.tabindex = 1; this.inputError = true; return; }

    if (!this.APIresult[0].w9.Account) { this.WarningMessage("Enter bank account number"); this.tabindex = 1; this.inputError = true; return; }

    if (this.APIresult[0].w9.Account.length < 8) { this.WarningMessage("Your account number should be at least 8 characters long"); this.tabindex = 1; this.inputError = true; return; }
    if (!this.APIresult[0].w9.BankType) { this.WarningMessage("Select bank account type"); this.tabindex = 1; this.inputError = true; return; }
    if (!this.APIresult[0].w9.BankName) { this.WarningMessage("Enter Bank Name"); this.tabindex = 1; this.inputError = true; return; }
    if (tab == 2) {
      this.tabindex = tab;
      window.scrollTo(0, 0)
      return
    }
    //end step 2


    //check step 3
    if (!this.APIresult[0].agentinfo.eoCoverage) { this.WarningMessage("Indicate if you do or do not have E&O coverage"); this.tabindex = 2; this.inputError = true; return; }
    if (this.APIresult[0].agentinfo.eoCoverage == 'y') {
      if (!this.APIresult[0].agentinfo.eoAmount) { this.WarningMessage("Enter your E&O coverage amount"); this.tabindex = 2; this.inputError = true; return; }
      if (!this.APIresult[0].agentinfo.eoExp) { this.WarningMessage("Enter your E&O expiration date"); this.tabindex = 2; this.inputError = true; return; }
      if (!this.IsFutureDate(this.APIresult[0].agentinfo.eoExp)) { this.WarningMessage("Enter a valid E&O expiration date"); this.tabindex = 2; this.inputError = true; return; }
      if (!this.eofile) {
        this.WarningMessage("Please attach your E&O Certificate"); this.tabindex = 2; this.inputError = true; return;
      }
    }


    var Qok: boolean = true;
    if (!this.APIresult[0].questions[0].qAnswer) { Qok = false; }
    if (!this.APIresult[0].questions[1].qAnswer) { Qok = false; }
    if (!this.APIresult[0].questions[2].qAnswer) { Qok = false; }
    if (!this.APIresult[0].questions[3].qAnswer) { Qok = false; }
    if (!this.APIresult[0].questions[4].qAnswer) { Qok = false; }
    if (!this.APIresult[0].questions[5].qAnswer) { Qok = false; }
    if (!this.APIresult[0].questions[6].qAnswer) { Qok = false; }
    if (!this.APIresult[0].questions[7].qAnswer) { Qok = false; }
    if (!this.APIresult[0].questions[8].qAnswer) { Qok = false; }
    if (!this.APIresult[0].questions[9].qAnswer) { Qok = false; }
    if (!this.APIresult[0].questions[10].qAnswer) { Qok = false; }
    if (!this.APIresult[0].questions[11].qAnswer) { Qok = false; }
    if (!this.APIresult[0].questions[12].qAnswer) { Qok = false; }
    if (!this.APIresult[0].questions[13].qAnswer) { Qok = false; }
    if (!this.APIresult[0].questions[14].qAnswer) { Qok = false; }
    if (this.APIresult[0].questions[0].qAnswer == "1" && !this.APIresult[0].questions[0].qExplanation) { Qok = false };
    if (this.APIresult[0].questions[1].qAnswer == "1" && !this.APIresult[0].questions[1].qExplanation) { Qok = false };
    if (this.APIresult[0].questions[2].qAnswer == "1" && !this.APIresult[0].questions[2].qExplanation) { Qok = false };
    if (this.APIresult[0].questions[3].qAnswer == "1" && !this.APIresult[0].questions[3].qExplanation) { Qok = false };
    if (this.APIresult[0].questions[4].qAnswer == "1" && !this.APIresult[0].questions[4].qExplanation) { Qok = false };
    if (this.APIresult[0].questions[5].qAnswer == "1" && !this.APIresult[0].questions[5].qExplanation) { Qok = false };
    if (this.APIresult[0].questions[6].qAnswer == "1" && !this.APIresult[0].questions[6].qExplanation) { Qok = false };
    if (this.APIresult[0].questions[7].qAnswer == "1" && !this.APIresult[0].questions[7].qExplanation) { Qok = false };
    if (this.APIresult[0].questions[8].qAnswer == "1" && !this.APIresult[0].questions[8].qExplanation) { Qok = false };
    if (this.APIresult[0].questions[9].qAnswer == "1" && !this.APIresult[0].questions[9].qExplanation) { Qok = false };
    if (this.APIresult[0].questions[10].qAnswer == "1" && !this.APIresult[0].questions[10].qExplanation) { Qok = false };
    if (this.APIresult[0].questions[11].qAnswer == "1" && !this.APIresult[0].questions[11].qExplanation) { Qok = false };
    if (this.APIresult[0].questions[12].qAnswer == "1" && !this.APIresult[0].questions[12].qExplanation) { Qok = false };
    if (this.APIresult[0].questions[13].qAnswer == "1" && !this.APIresult[0].questions[13].qExplanation) { Qok = false };
    if (this.APIresult[0].questions[14].qAnswer == "1" && !this.APIresult[0].questions[14].qExplanation) { Qok = false };


    if (Qok == false) { this.WarningMessage('Answer all questions and enter an explanation for all questions answered as "Yes"'); this.tabindex = 2; this.inputError = true; return; }

    if (tab == 3) {
      this.tabindex = tab;
      window.scrollTo(0, 0)
      return
    }
    //end check step 3





  }





  yyyymmdd() {
    var x = new Date();
    var y = x.getFullYear().toString();
    var m = (x.getMonth() + 1).toString();
    var d = x.getDate().toString();
    var hs = x.getHours().toString();
    var mn = x.getMinutes().toString();
    var sc = x.getSeconds().toString();

    (d.length == 1) && (d = '0' + d);
    (m.length == 1) && (m = '0' + m);
    (hs.length == 1) && (hs = '0' + hs);
    (mn.length == 1) && (mn = '0' + mn);
    (sc.length == 1) && (sc = '0' + sc);
    var yyyymmdd = y + m + d + hs + mn + sc;
    return yyyymmdd;
  }

  getCode(): void {
    this.templatecode = "CM-" + sessionStorage.getItem("codeidentifier") + "-" + this.yyyymmdd();
  }

  getPaygrid(): void {

    if (this.selectedPaygrid == "") {
      this.isCode = false;
      //get Dental
      if (this.APIresult[0].hasOwnProperty('Dental')) {
        this.showdental = true;
      }
      //get Chubb
      if (this.APIresult[0].hasOwnProperty('Chubb')) {
        this.showchubb = true;
      }

      //get Life
      if (this.APIresult[0].hasOwnProperty('Life')) {
        this.showlife = true;
      }
      this.allowcode = true;
      this.getCode();
    }
    else {
      this.isCode = true;
      this.allowcode = false;
      for (let result of this.APIresult[0].templates) {
        if (result.id == this.selectedPaygrid) {
          this.templatecode = result.code
          if ("AFEUSA" in result.payout[0]) {
            this.selectedafe1 = result.payout[0].AFEUSA;
          }

          if ("AFEUSA2" in result.payout[0]) {
            this.selectedafe2 = result.payout[0].AFEUSA2;
          }

          if ("Dental" in result.payout[0]) {
            this.selecteddental = result.payout[0].Dental;
            this.allowdental = true;
            this.showdental = true;
          }
          else {
            this.showdental = false;
          }


          if ("Chubb" in result.payout[0]) {
            this.selectedchubb = result.payout[0].Chubb;
            this.allowchubb = true;
            this.showchubb = true;
          }
          else {
            this.showchubb = false;
          }


          if ("Life" in result.payout[0]) {
            this.selectedlife = result.payout[0].Life;
            this.allowlife = true;
            this.showlife = true;
          }
          else {
            this.showlife = false;
          }


        }
      }




    }





  }




  keyPressLettersNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9

    if (charCode >= 48 && charCode <= 57) {
      return true;
      return;
    }

    if (charCode >= 65 && charCode <= 90) {
      return true;
      return;
    }

    if (charCode >= 97 && charCode <= 122) {
      return true;
      return;
    }

    event.preventDefault();
    return false;

  }


  HardCode1(): void {
    let SelectedValue: number = this.selectedafe1;
    this.selectedafe2 = SelectedValue * 2;
    this.selecteddental = this.selectedafe1;
  }
  HardCode2(): void {
    let SelectedValue: number = this.selectedafe2;
    this.selectedafe1 = SelectedValue / 2;
    this.selecteddental = this.selectedafe1;
  }

  HardCode3(): void {
    let SelectedValue: number = this.selecteddental;
    this.selectedafe2 = SelectedValue * 2;
    this.selectedafe1 = SelectedValue;
    if (this.allowdental == true) {
      Swal.fire({
        title: 'Attention',
        text: 'Selecting Delta Dental will automatically set the AFEUSA Basic and Silver Membership compensation to match.',
        icon: 'warning',
      });
    }
  }





  WarningMessage(message: string): void {
    Swal.fire({
      title: 'Check the required fields',
      text: message,
      icon: 'warning',

    })
  }

  changestaff(values: any): void {
    if (values.currentTarget.checked == true) {
      this.havestaff = true;
      this.hasstaffmember = "Y";
    }
    else {
      this.havestaff = false;
      this.hasstaffmember = "N";
    }
  }

  IsFutureDate(mydate: string): boolean {
    var isdate = Date.parse(mydate);
    if (isNaN(isdate)) {
      return false;
    }

    var EnteredDate = new Date(isdate)
    var TodayIs = new Date()
    if (EnteredDate > TodayIs) {
      return true;
    }
    return false;
  }

  IsDate(mydate: string): boolean {
    var isdate = Date.parse(mydate);
    if (isNaN(isdate)) {
      return false;
    }

    var EnteredDate = new Date(isdate)
    var TodayIs = new Date()
    if (EnteredDate > TodayIs) {
      return false;
    }
    var Difference_In_Time = EnteredDate.getTime() - new Date().getTime();
    var Difference_In_Days = ((Difference_In_Time / (1000 * 3600 * 24)) / 365) * (-1);
    if (Difference_In_Days > 100) {
      return false;
    }
    if (Difference_In_Days < 18) {
      return false;
    }
    return true;
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


  isNumber(value: string | number): boolean {
    return ((value != null) &&
      (value !== '') &&
      !isNaN(Number(value.toString())));
  }




  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9

    if (charCode >= 48 && charCode <= 57) {
      return true;
      return;
    }

    event.preventDefault();
    return false;

  }



  TryParse(item): boolean {
    item = typeof item !== "string"
      ? JSON.stringify(item)
      : item;

    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }

    if (typeof item === "object" && item !== null) {
      return true;
    }

    return false;
  }

  onFileChanged(event) {
    this.eofile = event.target.files[0]
    if (this.eofile) {
      if (this.eofile.size > 1024000) {
        Swal.fire({
          title: 'File Too Big',
          text: 'Your E&O Certificate file size cannot be greater than 1Mb (1024 Kb)',
          icon: 'error',
        })

        return;
      }
    }
  }


  PrepareAgreement(): void {
    this.inputError == false;
    this.CheckEntry(4);
    if (this.inputError == true) {
      this.WarningMessage("Please correct your entry errors.");
      return;
    }
    else {
      this.showspinner = true;
      var APIURL = sessionStorage.getItem('endpoint') + "afeapi/desk/activation/previewpdf.aspx";    // set header for http call
      let formData = new FormData();

      this.APIresult[0].npnInfo=[]
      this.APIresult[0].npnInfo[0]=this.npnInd;
      this.APIresult[0].npnInfo[1]=this.npnAg;

      if (this.eofile) {
        formData.append("myfile", this.eofile, this.eofile.name);
      }
      formData.append('plid', encodeURIComponent(sessionStorage.getItem('plid')));
      formData.append('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
      formData.append('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
      formData.append('payload', (JSON.stringify(this.APIresult[0])));
      this.httpClient.post(APIURL, formData).subscribe(
        result => {
          this.APIPDFresult = Array.of(result);
          if (result == null) {
            this.showspinner = false;
            Swal.fire({
              title: 'Connection aborted',
              text: "A connection to AFEUSA servers could not be established.",
              icon: 'error',

            })
          }
          else {
            if (this.APIPDFresult[0].success == "1") {

              this.doPDF = true;
              this.inputError = false;
              this.pdfSrc = this.APIPDFresult[0].server + "/afeapi/enrollment/getpreview.aspx?did=" + this.APIPDFresult[0].did;
              //this.showspinner = false;
              return;
            }

            if (this.APIPDFresult[0].success == "0") {
              this.showspinner = false;

              Swal.fire({
                title: 'Error',
                text: this.APIPDFresult[0].message,
                icon: 'error',

              })
            }


          }

        },
        error => {
          this.showspinner = false;
          Swal.fire({
            title: 'Connection aborted',
            text: "A connection to AFEUSA servers could not be established.",
            icon: 'error',

          })
        });
    }
  }


  SignAgreement(): void {
    let newObj = this.USstates.filter((value) => {
      return value.Code.indexOf(this.state) != -1 ? value : null
    });
    let StateID: number = newObj[0].Id;
    let StateName: string = newObj[0].Name
    this.APIresult[0].agentinfo.StateID=StateID;
    this.APIresult[0].agentinfo.StateName=StateName;

    if (!this.agreesales) {
      this.WarningMessage("To create your account, you need to accept the Sales Agreement.");
      return;
    }

    if (!this.agreemarketing) {
      this.WarningMessage("To create your account, you need to accept to the Marketing Agreement.");
      return;
    }

    if (!this.esignature) {
      this.WarningMessage("Please eSign the agreement.");
      return;
    }

    if (this.esignature != this.APIresult[0].agentinfo.firstname + " " + this.APIresult[0].agentinfo.lastname) {
      this.WarningMessage("The expected eSignature is: " + this.APIresult[0].agentinfo.firstname + " " + this.APIresult[0].agentinfo.lastname);
      return;
    }


    this.inputError == false;
    this.CheckEntry(4);
    if (this.inputError == true) {
      this.WarningMessage("Please correct your entry errors.");
      return;
    }

    if (this.eofile) {
      if (this.eofile.size > 1024000) {
        Swal.fire({
          title: 'File Too Big',
          text: 'Your E&O Certificate file size cannot be greater than 1Mb (1024 Kb)',
          icon: 'error',
        })
        return;
      }
    }

   
    var APIURL = sessionStorage.getItem('endpoint') + "afeapi/desk/activation/activateagent.aspx";    // set header for http call
    let formData = new FormData();
    if (this.eofile) {
      formData.append("myfile", this.eofile, this.eofile.name);
    }
    formData.append('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    formData.append('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    formData.append('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));
    formData.append('payload', (JSON.stringify(this.APIresult[0])));
    formData.append('esignature', encodeURIComponent(this.esignature));

    this.httpClient.post(APIURL, formData).subscribe(
      result => {
        this.APIresult = Array.of(result);
        if (result == null) {
          this.showspinner = false;
          Swal.fire({
            title: 'Connection aborted',
            text: "A connection to AFEUSA servers could not be established.",
            icon: 'error',

          })
        }
        else {
          this.showspinner = false;
          if (this.APIresult[0].success == "1") {
            sessionStorage.setItem("status", "1");
            Swal.fire({
              title: 'Welcome!',
              text: "Your account is now activated. You will be notified once it is approved by AFEUSA.",
              icon: 'info',

            })
            var endpoint=sessionStorage.getItem("endpoint");
            var server=sessionStorage.getItem("server");
            sessionStorage.clear();
            sessionStorage.setItem("endpoint", endpoint);
            sessionStorage.setItem("server", server);
            this._router.navigateByUrl("/login");
            return;
          }

          if (this.APIresult[0].success == "0") {
            Swal.fire({
              title: 'Error',
              text: this.APIresult[0].message,
              icon: 'error',

            })
          }


        }

      },
      error => {
        this.showspinner = false;
        Swal.fire({
          title: 'Connection aborted',
          text: "A connection to AFEUSA servers could not be established.",
          icon: 'error',

        })
      });




  }


}
