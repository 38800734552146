import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-p6',
  templateUrl: './p6.component.html',
  styleUrls: ['./p6.component.css']
})
export class P6Component implements OnInit {
  @Output("Calculate") Calculate: EventEmitter<any> = new EventEmitter();
  @Input() Data: any;

  public matrix: any;
  public selection: any;

  public LightBox: boolean = false;
  public LightBox2: boolean = false;
  public PlanName: string = "";

  public SelectedPlan: string;
  public SelectedAmount: number=0
  public SelectedName: string="";
  public SelectedRowCol: string="";

  public PLAN: number=0;

  constructor(
    private toastr: ToastrService
  ) { 
   
     
  
  }

  ngOnInit(): void {
    this.matrix = this.Data.life;
    this.selection = this.Data.selections.life;
    this.SelectedPlan=this.Data.selections.life.selection;
  }

  View(planid, amount, name): void {
    this.SelectedPlan=planid;
    this.SelectedAmount=amount;
    this.SelectedName=name

  }

  SetPlan(plan, amount, name): void {
    this.Data.selections.life.selection=plan
    this.Data.selections.life.amount= +amount;
    this.SelectedPlan=plan;
    this.Data.selections.life.name = name;
    this.toastr.success('Plan added to your cart!', 'Plan Added');
    this.Calculate.emit();
    this.LightBox=false;
  }


  Click25(): void {
    this.LightBox=true;
  }

  Click75(): void {
    this.LightBox2=true;
  }

}
