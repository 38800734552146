import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SelectItem, FilterService, FilterMatchMode } from 'primeng/api';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  application: boolean=false;

  APIresult: any; //api result for listing all products - Permanent


  public ProductsList: any;

  constructor(

    public httpClient: HttpClient,
    private _router: Router,

    private r: ActivatedRoute,
    private filterService: FilterService
  ) {
    let selections = sessionStorage.getItem("selections")
    this.application=selections.includes("z8IBbsJFRBfYDd/due/axw==")
    this.LoadProducts();
  }



  LoadProducts(): void {

    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/websites/listwebsites.aspx";
    var options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    var body = new URLSearchParams();

    body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
    body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
    body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {

        this.APIresult = result;
        if (this.APIresult.success == "1") {
          this.ProductsList = this.APIresult.websites;
        }
      },
      error => {

      });
  }

  copyToClipboard(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
    Swal.fire({ title: 'Copied to Clipboard', text: 'Your website URL was copied to your Clipboard', icon: 'success' });

  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    Swal.fire({ title: 'Copied to Clipboard', text: 'Your website URL was copied to your Clipboard', icon: 'success' });

  }


  onRowSelect(id, option): void {
    if (option == 'e') {
      sessionStorage.setItem("temp", id)
      this._router.navigate(['../brandingedit'], { relativeTo: this.r });
    }

    if (option == 'd') {
      Swal.fire({
        title: 'Delete website?',
        text: "You won't be able to revert this operation",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {

          var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/websites/deletewebsite.aspx";    // set header for http call
          let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
          let formData = new FormData();
          formData.append("plid", encodeURIComponent(sessionStorage.getItem('plid')));
          formData.append("userkey", encodeURIComponent(sessionStorage.getItem('userkey')));
          formData.append("sessionkey", encodeURIComponent(sessionStorage.getItem('sessionkey')));
          formData.append("websiteid", id);
         
          this.httpClient.post(APIURL, formData).subscribe(
            result => {
              this.APIresult = result;
              if (result == null) {
                Swal.fire({
                  title: 'Try again',
                  text: 'Invalid credentials. Please try again.',
                  icon: 'error',
                })
                return;
              }
              else {
                if (this.APIresult.success == "1") {
                  Swal.fire({
                    title: 'Website deleted',
                    text: this.APIresult.message,
                    icon: 'success',
                  })
                  this.ProductsList = this.APIresult.websites;
                  return;
                }
                
              }
            },
            error => {

            });



        }
      })

    }

  }


  ngOnInit(): void {
  }

}
