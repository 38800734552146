import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-p1',
  templateUrl: './p1.component.html',
  styleUrls: ['./p1.component.css']
})
export class P1Component implements OnInit {
  @Output("Calculate") Calculate: EventEmitter<any> = new EventEmitter();
  @Input() Data: any;

  public matrix: any;
  public selection: any;

  public LightBox: boolean = false;
  public PlanName: string = "";

  public SelectedPlan: number = 0;
  public SelectedAmount: number = 0
  public SelectedName: string = "";
  public SelectedRowCol: string = "";

  public PLAN: number = 0;

  constructor(
    private toastr: ToastrService
  ) {




  }

  ngOnInit(): void {
    this.matrix = this.Data.membership;
    this.selection = this.Data.selections.membership;
    this.SelectedPlan = this.Data.selections.membership.selection;
  }

  View(planid, amount, name): void {
    this.SelectedPlan = planid;
    this.SelectedAmount = amount;
    this.SelectedName = name

  }

  SetPlan(): void {
    this.Data.selections.membership.selection = this.SelectedPlan
    if (+this.SelectedAmount==0) {
      this.Data.selections.membership.amount = 0.001;
    }
    else
    {
      this.Data.selections.membership.amount = +this.SelectedAmount;
    }
    

    this.Data.selections.membership.name = this.SelectedName;
    this.toastr.success('Plan added to your cart!', 'Plan Added');
    this.Calculate.emit();
    this.LightBox = false;
  }


}
