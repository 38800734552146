<div class="row">
    <div class="col-12 col-sm-12 col-md-6 col-lg-4">
        <div class="bShadow-5">
            <div style="min-height: 340px;">
                <h6>Agent</h6>
                <hr>
                <table class="w100">
                    <tr class="spaceUnder">
                        <td class="text-info" style="width:150px;">
                            Signup Day:
                        </td>
                        <td class="text-black">
                            {{APIresult[0].esignature.signupdate}} (SMT)
                        </td>
                    </tr>
                    <tr class="spaceUnder">
                        <td class="text-info">
                            Agent ID:
                        </td>
                        <td class="text-black">
                            {{APIresult[0].agentinfo.plid}}
                        </td>
                    </tr>
                    <tr class="spaceUnder">
                        <td class="text-info">
                            Full Name:
                        </td>
                        <td class="text-black">
                            {{APIresult[0].agentinfo.firstname}} {{APIresult[0].agentinfo.middlename}}
                            {{APIresult[0].agentinfo.lastname}}
                        </td>
                    </tr>
                    <tr class="spaceUnder">
                        <td class="text-info">
                            Address:
                        </td>
                        <td class="text-black">
                            {{APIresult[0].agentinfo.address}} {{APIresult[0].agentinfo.city}},
                            {{APIresult[0].agentinfo.state}} {{APIresult[0].agentinfo.zip}}
                        </td>
                    </tr>
                    <tr class="spaceUnder">
                        <td class="text-info">
                            Cell Phone:
                        </td>
                        <td class="text-black">
                            {{APIresult[0].agentinfo.cellphone}}
                        </td>
                    </tr>
                    <tr class="spaceUnder">
                        <td class="text-info">
                            Business Phone:
                        </td>
                        <td class="text-black">
                            {{APIresult[0].agentinfo.businessphone}}
                        </td>
                    </tr>
                    <tr class="spaceUnder">
                        <td class="text-info">
                            Fax Number:
                        </td>
                        <td class="text-black">
                            {{APIresult[0].agentinfo.faxnumber}}
                        </td>
                    </tr>
                    <tr class="spaceUnder">
                        <td class="text-info">
                            Email Address:
                        </td>
                        <td class="text-black">
                            {{APIresult[0].agentinfo.email}}
                        </td>
                    </tr>
                    <tr class="spaceUnder" *ngIf="AllowViewAgentPassword">
                        <td class="text-black" colspan="2">
                            <br>
                            <button class="btn  btn-warning text-white" (click)="RevealPassword()"
                                style="margin-right: 10px;">View Pwd</button>
                            <button class="btn  btn-warning text-white" (click)="ResendEmail()"
                                style="margin-right: 10px;" *ngIf="status==1 || status==50">Resend
                                Email</button>
                            <button class="btn  btn-danger text-white" (click)="EditMyAgent()"
                                *ngIf="status==1">Edit &
                                Update</button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>




    <div class="col-12 col-sm-12 col-md-6 col-lg-4">
        <div class="bShadow-5">
            <div style="min-height: 340px;">
                <h6>Products and Grid</h6>
                <hr>
                <table class="w100">
                    <tr class="spaceUnder">
                        <td></td>
                        <td class="text-title">Year 1</td>
                        <td class="text-title">Year 2+</td>
                    </tr>
                    <tr class="spaceUnder" *ngFor="let product of APIresult[0].products ">
                        <td class="text-info">{{product.RealName}}</td>
                        <td class="text-black">{{product.Y1}}%</td>
                        <td class="text-black">{{product.Y2}}%</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>


    <div class="col-12 col-sm-12 col-md-6 col-lg-4" *ngIf="AllowCreate">
        <div class="bShadow-5" >
            <div style="min-height: 340px;">
                <h6>Miscellaneous</h6>
                <hr>
                <table class="w100">
                    <tr class="spaceUnder">
                        <td class="text-info" style="width:150px;">
                            Sponsor:
                        </td>
                        <td class="text-black">
                            ({{APIresult[0].agentinfo.SponsorPL}}) - {{APIresult[0].agentinfo.SponsorFname}}
                            {{APIresult[0].agentinfo.SponsorLname}}
                        </td>
                    </tr>
                    <tr class="spaceUnder">
                        <td class="text-info">
                            Type of Business:
                        </td>
                        <td class="text-black">
                            {{APIresult[0].agentinfo.licTypeOfBusiness}}
                        </td>
                    </tr>
                    <tr class="spaceUnder">
                        <td class="text-info">
                            License:
                        </td>
                        <td class="text-black">
                            {{APIresult[0].agentinfo.licTypeInsuranceLicense}}
                        </td>
                    </tr>
                    <tr class="spaceUnder">
                        <td class="text-info">
                            Insurance #:
                        </td>
                        <td class="text-black">
                            {{APIresult[0].agentinfo.licResidentInsuranceLicense}}
                        </td>
                    </tr>
                    <tr class="spaceUnder">
                        <td class="text-info">
                            Resident State:
                        </td>
                        <td class="text-black">
                            {{APIresult[0].agentinfo.licResidentState}}
                        </td>
                    </tr>
                    <tr class="spaceUnder">
                        <td class="text-info">
                            NPN Number:
                        </td>
                        <td class="text-black">
                            {{APIresult[0].agentinfo.licNPMnumber}}
                        </td>
                    </tr>
                    <tr class="spaceUnder">
                        <td class="text-info">
                            E&O Coverage?
                        </td>
                        <td class="text-black">
                            {{APIresult[0].agentinfo.eoCoverage}}
                        </td>
                    </tr>
                    <tr class="spaceUnder">
                        <td class="text-info">
                            $ and Expiration:
                        </td>
                        <td class="text-black">
                            ${{APIresult[0].agentinfo.eoAmount | number:'1.2-2'}} -
                            {{APIresult[0].agentinfo.eoExp}}
                        </td>
                    </tr>
                    <tr class="spaceUnder" *ngIf="adm && status==1">
                        <td class="text-info">
                            Platform Model:
                        </td>
                        <td class="text-black">
                            <select class="form-control" [(ngModel)]="BackofficeType"
                                (change)="ChangeAgencyType($event.target.value)">
                                <option value="0">Standard Agent</option>
                                <option value="2">GA</option>
                                <option value="1">IMO / Agency</option>
                            </select>
                        </td>
                    </tr>
                </table>
                
            </div>
        </div>

    </div>
</div>
<br><br>
<div *ngIf="APIresult[0].agentinfo.status !=50">
    <div *ngIf="adm">

        <div class="row">

            <div class="col-12">
                <div class="bShadow-5">
                    <div style="height: 105px;">
                        <h6>Switch Agent Status</h6>
                        <hr>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                Current Status:<strong>
                                    <div class="text-info" *ngIf="APIresult[0].agentinfo.status==1">
                                        ENABLED</div>
                                    <div class="text-danger" *ngIf="APIresult[0].agentinfo.status==99">
                                        DISABLED /
                                        PERMANENTLY SUSPENDED</div>
                                    <div class="text-warning" *ngIf="APIresult[0].agentinfo.status==0">
                                        ACTIVATION PENDING
                                    </div>
                                </strong>
                            </div>
                            <div class="col-12 col-md-6 text-right">
                                <button class="btn btn-success text-white" style="margin-right: 10px;"
                                    (click)="EnableAgent()"
                                    *ngIf="APIresult[0].agentinfo.status ==0 || APIresult[0].agentinfo.status ==99">Enable
                                    Agent</button>
                                <button class="btn btn-danger text-white" style="margin-right: 10px;"
                                    (click)="DisableAgent()" *ngIf="APIresult[0].agentinfo.status ==1">
                                    Disable Agent</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <br>
                <div class="bShadow-5">
                    <div style="min-height: 105px;">
                        <h6>Files available for download</h6>
                        <div class="table-responsive">
                            <table class="table">
                                <tr>
                                    <td>Document Code</td>
                                    <td>Date Processes</td>
                                    <td>File Description</td>
                                    <td></td>
                                </tr>
                                <tr *ngFor="let file of APIresult[0].files">
                                    <td>{{file.documenttype}}</td>
                                    <td>{{file.datetime}}</td>
                                    <td>{{file.description}}</td>
                                    <td class="text-right">
                                        <a class="btn btn-sm btn-warning text-white mousepointer"
                                            href="{{endpoint}}afeapi/desk/corporate/getdocument.aspx?did={{file.documentid}}&uk={{file.userkey}}"
                                            target="_blank">Download</a>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <a *ngIf="eo" href="{{eo}}" class="btn btn-warning text-white text-left"
                            style="margin-right: 10px" target="_blank"><i class="fa fa-download"></i> E&O
                            Coverage</a>

                        <a *ngIf="agreement" href="{{agreement}}"
                            class="btn text-white btn-warning text-left" style="margin-right: 10px"
                            target="_blank"><i class="fa fa-download"></i> Sales Agreement</a>
                    </div>
                </div>
            </div>
        </div>
        <br>

    </div>
    <div class="bShadow-5">
        <div style="height: 105px;">
            <h6>Electronic Signature</h6>
            <hr> Electronically signed from IP address {{APIresult[0].esignature.ipaddress}} on
            {{APIresult[0].esignature.signupdate}} SMT. Entered and confirmed signature: <span
                class="text-danger">{{APIresult[0].esignature.eSignature}}</span>
        </div>
    </div>


    <br><br>
</div>





<div class="bShadow-5" *ngIf="adm">
    <div style="min-height: 320px;">
        <h6>Background Questions</h6>
        <hr>

        <div class="table-responsive">
            <table class="table table-responsive table-borderless">
                <tr>
                    <td class="text-left text-title" [ngClass]="{'text-danger': Q1 === '1'}">
                        1. Are you now or have you ever been included in litigation with an insurance
                        company?
                        <br><strong>Answer: <span *ngIf="Q1=='1'">YES</span><span *ngIf="Q1=='0'">NO</span>
                        </strong>
                        <textarea [(ngModel)]="A1" class="form-control" rows="5"
                            placeholder="Please explain your affirmative answer for the above question"
                            *ngIf="Q1=='1'"></textarea>
                    </td>
                </tr>
                <tr>
                    <td class="text-left text-title" [ngClass]="{'text-danger': Q2 === '1'}">
                        2. Do you currently have a debit balance with any insurance company?
                        <br><strong>Answer: <span *ngIf="Q2=='1'">YES</span><span *ngIf="Q2=='0'">NO</span>
                        </strong>
                        <textarea [(ngModel)]="A2" class="form-control" rows="5"
                            placeholder="Please explain your affirmative answer for the above question"
                            *ngIf="Q2=='1'"></textarea>
                    </td>
                </tr>
                <tr>
                    <td class="text-left text-title" [ngClass]="{'text-danger': Q3 === '1'}">

                        3. Have you ever had your insurance or securities license suspended or revoked?
                        <br><strong>Answer: <span *ngIf="Q3=='1'">YES</span><span *ngIf="Q3=='0'">NO</span>
                        </strong>
                        <textarea [(ngModel)]="A3" class="form-control" rows="5"
                            placeholder="Please explain your affirmative answer for the above question"
                            *ngIf="Q3=='1'"></textarea>
                    </td>
                </tr>
                <tr>
                    <td class="text-left text-title" [ngClass]="{'text-danger': Q4 === '1'}">

                        4a. Have you ever been charged with, convicted of, or plead guilty or nolo
                        contendere ("no contest") to a felony of any kind?
                        <br><strong>Answer: <span *ngIf="Q4=='1'">YES</span><span *ngIf="Q4=='0'">NO</span>
                        </strong>
                        <textarea [(ngModel)]="A4" class="form-control" rows="5"
                            placeholder="Please explain your affirmative answer for the above question"
                            *ngIf="Q4=='1'"></textarea>
                    </td>

                </tr>


                <tr>
                    <td class="text-left text-title" [ngClass]="{'text-danger': Q5 === '1'}">

                        4b. Have you ever been charged with, convicted of, or plead guilty or nolo
                        contendere ("no contest") to a misdemeanor involving insurance or fraud?
                        <br><strong>Answer: <span *ngIf="Q5=='1'">YES</span><span *ngIf="Q5=='0'">NO</span>
                        </strong>
                        <textarea [(ngModel)]="A5" class="form-control" rows="5"
                            placeholder="Please explain your affirmative answer for the above question"
                            *ngIf="Q5=='1'"></textarea>
                    </td>

                </tr>

                <tr>
                    <td class="text-left text-title" [ngClass]="{'text-danger': Q6 === '1'}">
                        5a. Has any domestic or foreign court ever found you guilty in connection with any
                        insurance or investment-related activity?
                        <br><strong>Answer: <span *ngIf="Q6=='1'">YES</span><span *ngIf="Q6=='0'">NO</span>
                        </strong>
                        <textarea [(ngModel)]="A6" class="form-control" rows="5"
                            placeholder="Please explain your affirmative answer for the above question"
                            *ngIf="Q6=='1'"></textarea>
                    </td>

                </tr>

                <tr>
                    <td class="text-left text-title" [ngClass]="{'text-danger': Q7 === '1'}">
                        5b. Has any domestic or foreign court ever found that you violated any
                        insurance-related statutes or regulations?
                        <br><strong>Answer: <span *ngIf="Q7=='1'">YES</span><span *ngIf="Q7=='0'">NO</span>
                        </strong>
                        <textarea [(ngModel)]="A7" class="form-control" rows="5"
                            placeholder="Please explain your affirmative answer for the above question"
                            *ngIf="Q7=='1'"></textarea>
                    </td>

                </tr>

                <tr>
                    <td class="text-left text-title" [ngClass]="{'text-danger': Q8 === '1'}">

                        6a. Has any Federal regulatory agency or any state regulatory agency or foreign
                        financial regulatory authority ever found you to have made a false statement or
                        omission or been dishonest, unfair or unethical?
                        <br><strong>Answer: <span *ngIf="Q8=='1'">YES</span><span *ngIf="Q8=='0'">NO</span>
                        </strong>
                        <textarea [(ngModel)]="A8" class="form-control" rows="5"
                            placeholder="Please explain your affirmative answer for the above question"
                            *ngIf="Q8=='1'"></textarea>
                    </td>
                </tr>

                <tr>
                    <td class="text-left text-title" [ngClass]="{'text-danger': Q9 === '1'}">

                        6b. Has any Federal regulatory agency or any state regulatory agency or foreign
                        financial regulatory authority ever found you to have been involved in a violation
                        of insurance or investment regulations or statutes?
                        <br><strong>Answer: <span *ngIf="Q9=='1'">YES</span><span *ngIf="Q9=='0'">NO</span>
                        </strong>
                        <textarea [(ngModel)]="A9" class="form-control" rows="5"
                            placeholder="Please explain your affirmative answer for the above question"
                            *ngIf="Q9=='1'"></textarea>
                    </td>

                </tr>

                <tr>
                    <td class="text-left text-title" [ngClass]="{'text-danger': Q10 === '1'}">

                        6c. Has any Federal regulatory agency or any state regulatory agency or foreign
                        financial regulatory authority ever found you to have been the cause of any
                        insurance or investment-related business having its authorization to do business
                        denied, suspended,
                        revoked or restricted?
                        <br><strong>Answer: <span *ngIf="Q10=='1'">YES</span><span
                                *ngIf="Q10=='0'">NO</span> </strong>
                        <textarea [(ngModel)]="A10" class="form-control" rows="5"
                            placeholder="Please explain your affirmative answer for the above question"
                            *ngIf="Q10=='1'"></textarea>
                    </td>


                </tr>

                <tr>
                    <td class="text-left text-title" [ngClass]="{'text-danger': Q11 === '1'}">

                        6d. Has any Federal regulatory agency or any state regulatory agency or foreign
                        financial regulatory authority ever entered an order against you in connection with
                        any insurance or investment-related activity?
                        <br><strong>Answer: <span *ngIf="Q11=='1'">YES</span><span
                                *ngIf="Q11=='0'">NO</span> </strong>
                        <textarea [(ngModel)]="A11" class="form-control" rows="5"
                            placeholder="Please explain your affirmative answer for the above question"
                            *ngIf="Q11=='1'"></textarea>
                    </td>

                </tr>

                <tr>
                    <td class="text-left text-title" [ngClass]="{'text-danger': Q12 === '1'}">

                        6e. Has any Federal regulatory agency or any state regulatory agency or foreign
                        financial regulatory authority ever denied, suspended, or revoked your registration
                        or license or otherwise prevented you from associating with any insurance or
                        investment-related
                        business, or disciplined you by restricting your activities?
                        <br><strong>Answer: <span *ngIf="Q12=='1'">YES</span><span
                                *ngIf="Q12=='0'">NO</span> </strong>
                        <textarea [(ngModel)]="A12" class="form-control" rows="5"
                            placeholder="Please explain your affirmative answer for the above question"
                            *ngIf="Q12=='1'"></textarea>
                    </td>

                </tr>

                <tr>
                    <td class="text-left text-title" [ngClass]="{'text-danger': Q13 === '1'}">

                        7a. Have you ever been the subject of any insurance or investment-related, consumer-
                        initiated complaint or proceeding that alleged compensatory damages of $2,500 or
                        more, or found fraud or the wrongful taking of property?
                        <br><strong>Answer: <span *ngIf="Q13=='1'">YES</span><span
                                *ngIf="Q13=='0'">NO</span> </strong>
                        <textarea [(ngModel)]="A13" class="form-control" rows="5"
                            placeholder="Please explain your affirmative answer for the above question"
                            *ngIf="Q13=='1'"></textarea>
                    </td>

                </tr>

                <tr>
                    <td class="text-left text-title" [ngClass]="{'text-danger': Q14 === '1'}">

                        7b. Have you ever been the subject of any insurance or investment-related, consumer-
                        initiated complaint or proceeding that was settled or decided against you for $1,000
                        or more, or found fraud or the wrongful taking of property?
                        <br><strong>Answer: <span *ngIf="Q14=='1'">YES</span><span
                                *ngIf="Q14=='0'">NO</span> </strong>
                        <textarea [(ngModel)]="A14" class="form-control" rows="5"
                            placeholder="Please explain your affirmative answer for the above question"
                            *ngIf="Q14=='1'"></textarea>
                    </td>

                </tr>

                <tr>
                    <td class="text-left text-title" [ngClass]="{'text-danger': Q15 === '1'}">

                        8. Are you now the subject of any complaint, investigation, or proceeding that could
                        result in a "yes" answer to questions 1 - 7?
                        <br><strong>Answer: <span *ngIf="Q15=='1'">YES</span><span
                                *ngIf="Q15=='0'">NO</span> </strong>
                        <textarea [(ngModel)]="A15" class="form-control" rows="5"
                            placeholder="Please explain your affirmative answer for the above question"
                            *ngIf="Q15=='1'"></textarea>
                    </td>


                </tr>



            </table>
        </div>

    </div>
</div>