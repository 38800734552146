import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2'
import { Router, ActivatedRoute } from '@angular/router';
import { Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { interval, Subscription } from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.css']
})
export class ApplicationComponent implements OnInit {
  @ViewChild('username') input;

  public MemberShowCritical: string = "";
  public MemberShowDental: string = "";
  public MemberShowMedical: string = "";
  public MemberShowHospital: string = "";
  public MemberShowLife: string = "";
  public iContent: string;
  public IsProcessing: boolean = false;
  public counter: number=0;
  public showfinal: boolean=false;
  public mothersmaiden: string="";
  public ShowSpinner: boolean = false;

  ContinuationURL: string = "";
  MemberAccessToken: string = "";
  HCItoken: string="";
  Endpoint: string="";
  
  public PayLoad = {
    "ClientPrincipalId": "",
    "AgentPrincipalId": "",
    "TransactionToken": "",
    "EmpDemographics": {
      "ActionType": "ADD",
      "PersonCode": "00",
      "EmployeeId": "",
      "ESSN": "",
      "FirstName": "",
      "LastName": "",
      "MI": "",
      "BirthDate": "",
      "Gender": "",
      "Address1": "",
      "Address2": "",
      "City": "",
      "State": 0,
      "State_Name": "",
      "Zip": "",
      "Phone": "",
      "Email": "",
      "Location": "",
      "Area": "",
      "MothersMaidenName": ""
    },
    "RegistrationDetails": {
      "UserName": "",
      "Password": "",
      "AcknowledgementSignature": "",
      "HasAgreedToAuthorization": true
    },
    "DependentDemographics": [],
    "Beneficiaries": [],
    "ProductSelections": [],
    "TasksCompleted": ["DemographicBasics", "AvailablePackages", "EligQuestions", "BeneficiariesForm"]
  }


  public USstates = [
    {
      "Id": 83,
      "Code": "AL",
      "Name": "Alabama"
    },
    {
      "Id": 87,
      "Code": "AZ",
      "Name": "Arizona"
    },
    {
      "Id": 85,
      "Code": "AR",
      "Name": "Arkansas"
    },
    {
      "Id": 88,
      "Code": "CA",
      "Name": "California"
    },
    {
      "Id": 89,
      "Code": "CO",
      "Name": "Colorado"
    },
    {
      "Id": 90,
      "Code": "CT",
      "Name": "Connecticut"
    },
    {
      "Id": 92,
      "Code": "DE",
      "Name": "Delaware"
    },
    {
      "Id": 91,
      "Code": "DC",
      "Name": "District of Columbia"
    },
    {
      "Id": 93,
      "Code": "FL",
      "Name": "Florida"
    },
    {
      "Id": 95,
      "Code": "GA",
      "Name": "Georgia"
    },
    {
      "Id": 97,
      "Code": "HI",
      "Name": "Hawaii"
    },
    {
      "Id": 99,
      "Code": "ID",
      "Name": "Idaho"
    },
    {
      "Id": 100,
      "Code": "IL",
      "Name": "Illinois"
    },
    {
      "Id": 101,
      "Code": "IN",
      "Name": "Indiana"
    },
    {
      "Id": 98,
      "Code": "IA",
      "Name": "Iowa"
    },
    {
      "Id": 102,
      "Code": "KS",
      "Name": "Kansas"
    },
    {
      "Id": 103,
      "Code": "KY",
      "Name": "Kentucky"
    },
    {
      "Id": 104,
      "Code": "LA",
      "Name": "Louisiana"
    },
    {
      "Id": 106,
      "Code": "MD",
      "Name": "Maryland"
    },
    {
      "Id": 105,
      "Code": "MA",
      "Name": "Massachusetts"
    },
    {
      "Id": 109,
      "Code": "MI",
      "Name": "Michigan"
    },
    {
      "Id": 110,
      "Code": "MN",
      "Name": "Minnesota"
    },
    {
      "Id": 113,
      "Code": "MS",
      "Name": "Mississippi"
    },
    {
      "Id": 117,
      "Code": "NE",
      "Name": "Nebraska"
    },
    {
      "Id": 121,
      "Code": "NV",
      "Name": "Nevada"
    },
    {
      "Id": 118,
      "Code": "NH",
      "Name": "New Hampshire"
    },
    {
      "Id": 119,
      "Code": "NJ",
      "Name": "New Jersey"
    },
    {
      "Id": 116,
      "Code": "ND",
      "Name": "North Dakota"
    },
    {
      "Id": 123,
      "Code": "OH",
      "Name": "Ohio"
    },
    {
      "Id": 124,
      "Code": "OK",
      "Name": "Oklahoma"
    },
    {
      "Id": 126,
      "Code": "PA",
      "Name": "Pennsylvania"
    },
    {
      "Id": 128,
      "Code": "RI",
      "Name": "Rhode Island"
    },
    {
      "Id": 129,
      "Code": "SC",
      "Name": "South Carolina"
    },
    {
      "Id": 131,
      "Code": "TN",
      "Name": "Tennessee"
    },
    {
      "Id": 132,
      "Code": "TX",
      "Name": "Texas"
    },
    {
      "Id": 136,
      "Code": "VT",
      "Name": "Vermont"
    },
    {
      "Id": 134,
      "Code": "VA",
      "Name": "Virginia"
    },
    {
      "Id": 139,
      "Code": "WV",
      "Name": "West Virginia"
    },
    {
      "Id": 138,
      "Code": "WI",
      "Name": "Wisconsin"
    },
    {
      "Id": 140,
      "Code": "WY",
      "Name": "Wyoming"
    }
  ]









  public USstatesFull = [
    {
      "Id": 83,
      "Code": "AL",
      "Name": "Alabama"
    },
    {
      "Id": 82,
      "Code": "AK",
      "Name": "Alaska"
    },
    {
      "Id": 87,
      "Code": "AZ",
      "Name": "Arizona"
    },
    {
      "Id": 85,
      "Code": "AR",
      "Name": "Arkansas"
    },
    {
      "Id": 88,
      "Code": "CA",
      "Name": "California"
    },
    {
      "Id": 89,
      "Code": "CO",
      "Name": "Colorado"
    },
    {
      "Id": 90,
      "Code": "CT",
      "Name": "Connecticut"
    },
    {
      "Id": 92,
      "Code": "DE",
      "Name": "Delaware"
    },
    {
      "Id": 91,
      "Code": "DC",
      "Name": "District of Columbia"
    },
    {
      "Id": 93,
      "Code": "FL",
      "Name": "Florida"
    },
    {
      "Id": 95,
      "Code": "GA",
      "Name": "Georgia"
    },
    {
      "Id": 96,
      "Code": "GU",
      "Name": "Guam"
    },
    {
      "Id": 97,
      "Code": "HI",
      "Name": "Hawaii"
    },
    {
      "Id": 99,
      "Code": "ID",
      "Name": "Idaho"
    },
    {
      "Id": 100,
      "Code": "IL",
      "Name": "Illinois"
    },
    {
      "Id": 101,
      "Code": "IN",
      "Name": "Indiana"
    },
    {
      "Id": 98,
      "Code": "IA",
      "Name": "Iowa"
    },
    {
      "Id": 102,
      "Code": "KS",
      "Name": "Kansas"
    },
    {
      "Id": 103,
      "Code": "KY",
      "Name": "Kentucky"
    },
    {
      "Id": 104,
      "Code": "LA",
      "Name": "Louisiana"
    },
    {
      "Id": 107,
      "Code": "ME",
      "Name": "Maine"
    },
    {
      "Id": 106,
      "Code": "MD",
      "Name": "Maryland"
    },
    {
      "Id": 105,
      "Code": "MA",
      "Name": "Massachusetts"
    },
    {
      "Id": 109,
      "Code": "MI",
      "Name": "Michigan"
    },
    {
      "Id": 110,
      "Code": "MN",
      "Name": "Minnesota"
    },
    {
      "Id": 113,
      "Code": "MS",
      "Name": "Mississippi"
    },
    {
      "Id": 111,
      "Code": "MO",
      "Name": "Missouri"
    },
    {
      "Id": 114,
      "Code": "MT",
      "Name": "Montana"
    },
    {
      "Id": 117,
      "Code": "NE",
      "Name": "Nebraska"
    },
    {
      "Id": 121,
      "Code": "NV",
      "Name": "Nevada"
    },
    {
      "Id": 118,
      "Code": "NH",
      "Name": "New Hampshire"
    },
    {
      "Id": 119,
      "Code": "NJ",
      "Name": "New Jersey"
    },
    {
      "Id": 120,
      "Code": "NM",
      "Name": "New Mexico"
    },
    {
      "Id": 122,
      "Code": "NY",
      "Name": "New York"
    },
    {
      "Id": 115,
      "Code": "NC",
      "Name": "North Carolina"
    },
    {
      "Id": 116,
      "Code": "ND",
      "Name": "North Dakota"
    },
    {
      "Id": 79,
      "Code": "00",
      "Name": "Not Determined"
    },
    {
      "Id": 123,
      "Code": "OH",
      "Name": "Ohio"
    },
    {
      "Id": 124,
      "Code": "OK",
      "Name": "Oklahoma"
    },
    {
      "Id": 125,
      "Code": "OR",
      "Name": "Oregon"
    },
    {
      "Id": 126,
      "Code": "PA",
      "Name": "Pennsylvania"
    },
    {
      "Id": 127,
      "Code": "PR",
      "Name": "Puerto Rico"
    },
    {
      "Id": 128,
      "Code": "RI",
      "Name": "Rhode Island"
    },
    {
      "Id": 129,
      "Code": "SC",
      "Name": "South Carolina"
    },
    {
      "Id": 130,
      "Code": "SD",
      "Name": "South Dakota"
    },
    {
      "Id": 131,
      "Code": "TN",
      "Name": "Tennessee"
    },
    {
      "Id": 132,
      "Code": "TX",
      "Name": "Texas"
    },
    {
      "Id": 133,
      "Code": "UT",
      "Name": "Utah"
    },
    {
      "Id": 136,
      "Code": "VT",
      "Name": "Vermont"
    },
    {
      "Id": 11142,
      "Code": "VI",
      "Name": "Virgin Islands"
    },
    {
      "Id": 134,
      "Code": "VA",
      "Name": "Virginia"
    },
    {
      "Id": 137,
      "Code": "WA",
      "Name": "Washington"
    },
    {
      "Id": 139,
      "Code": "WV",
      "Name": "West Virginia"
    },
    {
      "Id": 138,
      "Code": "WI",
      "Name": "Wisconsin"
    },
    {
      "Id": 140,
      "Code": "WY",
      "Name": "Wyoming"
    }
  ]



  public upassword: string = "";
  public upassword2: string = "";

  public AllFine: boolean = true;


  //employee
  public firstname: string;
  public middlename: string;
  public lastname: string;
  public dob: string;
  public state: string;
  public email: string;
  public gender: string;


  public hasmembership: string;
  public hasdental: string;
  public hasmedical: string;
  public hashospital: string;
  public hascritical: string;
  public haslife: string;

  public address: string;
  public address2: string = "";
  public city: string;
  public zip: string;
  public phone: string;
  public ssn: string;
  public marritalstatus: string;

  //life primary beneficiaries for filling out class
  public litype: string = "Person";
  public lifirstname: string;
  public lilastname: string;
  public liaddress: string;
  public licity: string;
  public lizip: string;
  public listate: string;

  public lipercentage: number = 0; // selected percentage
  public lisumpercentage: number = 0; //sumatory of %
  public lirestpercentage: number = 101; //remaining % to split

  public liContpercentage: number = 0; // selected percentage
  public liContsumpercentage: number = 0; //sumatory of %
  public liContrestpercentage: number = 101; //remaining % to split



  //children
  public chrelationship: string;
  public chfirstname: string;
  public chmiddlename: string;
  public chlastname: string;
  public chgender: string;
  public chdob: string;
  public chssn: string;
  public chphone: string;
  public chemail: string;
  public chaddress: string;
  public chaddress2: string;
  public chcity: string;
  public chstate: string;
  public chzip: string;
  public chdisabled: string;

  //contingent beneficiary
  public liconttype: string = "Person";
  public licontfirstname: string;
  public licontlastname: string;
  public licontaddress: string;
  public licontcity: string;
  public licontzip: string;
  public licontstate: string;

  public spadded: boolean = false;

  //form general
  public ShowAddChildren: boolean = false;
  public ShowAddCWife: boolean = false;
  public ShowAddBeneficiary: boolean = false;
  public ShowAddContingentBeneficiary: boolean = false;
  public MyColor: string = "bg-primary";
  public APIresult: any;

  public groupplan: string = "0";
  public lifeplan = "0";

  //basic


  public ShowEnroll: boolean = false;






  //overlays
  public overlapOpen: boolean = false;
  public overlapContent: string = "";


  public ShowCart: boolean = false;

  public MembershipQuote: string;
  public DentalQuote: string;
  public MedicalQuote: string;
  public HospitalQuote: string;
  public CriticalQuote: string;
  public Life25Quote: string;
  public Life75Quote: string;
  public QuoteOk: boolean = true;

  public Total: number = 0;
  public TotalToDisplay: string;

  public currentRoute: string;
  //colors
  public colorMembershipQuote: string = "benefitbox";
  public colorDentalQuote: string = "unselected";
  public colorMedicalQuote: string = "unselected";
  public colorHospitalQuote: string = "unselected";
  public colorCriticalQuote: string = "unselected";
  public colorLife75Quote: string = "unselected";

  //icons
  public iconCritical: string = "fa-procedures";
  public iconMedical: string = "fa-notes-medical";
  public iconHospital: string = "fa-thermometer-half";
  public iconDental: string = "fa-tooth";
  public iconLife25: string = "far fa-life-ring";
  public iconLife75: string = "fa-life-ring";


  //text legends
  public legendCritical: string = "Plans starting at"
  public legendMedical: string = "Plans starting at"
  public legendHospital: string = "Plans starting at"
  public legendDental: string = "Plans starting at"
  public legendLife25: string = "Plans starting at"
  public legendLife75: string = "Plans starting at"
  public legendMembership: string = "Plans starting at"

  //button legend
  public buttonCritical: string = "Learn More"
  public buttonMedical: string = "Learn More"
  public buttonHospital: string = "Learn More"
  public buttonMembership: string = "Learn More"
  public buttonDental: string = "Learn More"
  public buttonLife25: string = "Learn More"
  public buttonLife75: string = "Learn More"

  //deleteplan buttons
  public deleteCritical: boolean = false;
  public deleteMedical: boolean = false;
  public deleteHospital: boolean = false;
  public deleteDental: boolean = false;
  public deleteLife25: boolean = false;
  public deleteLife75: boolean = false;
  public deleteMembership: boolean = false;
  public showDeleteMembership: boolean = false;
  //Does and Donts
  public DoDental: boolean = false;
  public DoMedical: boolean = false;
  public DoHospital: boolean = false;
  public DoCritical: boolean = false;
  public DoLife: boolean = false;

  public OpenProducts: boolean = false;

  public TotalProducts: number = 0;


  subscription: Subscription;
  intervalId: number;

  

  constructor(
    public httpClient: HttpClient,
    private _router: Router,
    private r: ActivatedRoute,
    private location: Location,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer
  ) {

    var myobj = document.getElementById("Henry");
    if (myobj) {
      myobj.remove();
    };

    sessionStorage.removeItem("qk")
    sessionStorage.removeItem("state")
    sessionStorage.removeItem("appsessionkey")
    sessionStorage.removeItem('critical');
    sessionStorage.removeItem('dental');
    sessionStorage.removeItem('hospital');
    sessionStorage.removeItem('medical');
    sessionStorage.removeItem('sickness');
    sessionStorage.removeItem('life25');
    sessionStorage.removeItem('life75');

    sessionStorage.removeItem('greenmembership');
    sessionStorage.removeItem('greencritical');
    sessionStorage.removeItem('greendental');
    sessionStorage.removeItem('greenhospital');
    sessionStorage.removeItem('greenmedical');
    sessionStorage.removeItem('greensickness');
    sessionStorage.removeItem('greenlife');


    sessionStorage.removeItem('membership');
    this.ShowCart = false;
    this.ShowEnroll = false;




  }

  GetRates(): void {


    sessionStorage.removeItem("qk")
    sessionStorage.removeItem("state")
    sessionStorage.removeItem("appsessionkey")
    sessionStorage.removeItem('critical');
    sessionStorage.removeItem('dental');
    sessionStorage.removeItem('hospital');
    sessionStorage.removeItem('medical');
    sessionStorage.removeItem('sickness');
    sessionStorage.removeItem('life25');
    sessionStorage.removeItem('life75');

    sessionStorage.removeItem('greenmembership');
    sessionStorage.removeItem('greencritical');
    sessionStorage.removeItem('greendental');
    sessionStorage.removeItem('greenhospital');
    sessionStorage.removeItem('greenmedical');
    sessionStorage.removeItem('greensickness');
    sessionStorage.removeItem('greenlife');




    sessionStorage.removeItem('membership');

    var APIURL = sessionStorage.getItem('endpoint') + "afeapi/simplequotedesk.aspx";    // set header for http call
    
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    let body = new URLSearchParams();
    body.set('plid', encodeURIComponent(sessionStorage.getItem("plid")));
    body.set('state', encodeURIComponent(this.state));
    body.set('dob', encodeURIComponent(this.dob));
    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.APIresult = Array.of(result);
        if (result == null) {
          Swal.fire('We cannot process your quote', "We appologize, but we cannot retrieve your quote now. Please try again later. ", 'error');
          return;
        }
        else {
          if (this.APIresult[0].success == "1") {
            sessionStorage.setItem('appsessionkey', this.APIresult[0].result);
            sessionStorage.setItem('qk', this.APIresult[0].quotekey);
            sessionStorage.setItem('state', this.state);
            //sessionStorage.setItem('quoteresult', JSON.stringify(result));
            sessionStorage.removeItem('critical');
            sessionStorage.removeItem('dental');
            sessionStorage.removeItem('hospital');
            sessionStorage.removeItem('medical');
            sessionStorage.removeItem('sickness');
            sessionStorage.removeItem('life25');
            sessionStorage.removeItem('life75');

            sessionStorage.removeItem('greenmembership');
            sessionStorage.removeItem('greencritical');
            sessionStorage.removeItem('greendental');
            sessionStorage.removeItem('greenhospital');
            sessionStorage.removeItem('greenmedical');
            sessionStorage.removeItem('greensickness');
            sessionStorage.removeItem('greenlife');


            sessionStorage.removeItem('membership');

            this.Calculate();
            this.ShowEnroll = true;
            return;
          }
          if (this.APIresult[0].success == "0") {
            Swal.fire(this.APIresult[0].message, "", 'error');

            return;
          }
          if (this.APIresult[0].success == "99") {
            Swal.fire("Too many quotes detected", this.APIresult[0].message, 'error');

            return;
          }

        }

      },
      error => {
        Swal.fire('We cannot process your quote', "We appologize, but we cannot retrieve your quote now. Please try again later.", 'error');
        return;
      });

  }


  Calculate(): void {
    this.TotalProducts = 0;
    this.groupplan = "0"
    this.lifeplan = "0";
    this.overlapOpen = false
    sessionStorage.removeItem("elegibilityconfirmed")

    this.currentRoute = this._router.url;
    this.Total = 0;


    var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/decrypt.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    let body = new URLSearchParams();
    body.set('string', sessionStorage.getItem('appsessionkey'));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {
        this.APIresult = Array.of(result);
        if (result == null) {
          Swal.fire('We cannot process your quote', "We appologize, but we cannot retrieve your quote now. Please try again later. ", 'error');
          return;
        }
        if (this.APIresult[0].success == "1") {

          if (this.APIresult[0].critical[0].hdp == "True") { this.DoCritical = false } else { this.DoCritical = true }
          if (this.APIresult[0].dental[0].hdp == "True") { this.DoDental = false } else { this.DoDental = true }
          if (this.APIresult[0].medical250[0].hdp == "True") { this.DoMedical = false } else { this.DoMedical = true }
          if (this.APIresult[0].hospital[0].hdp == "True") { this.DoHospital = false } else { this.DoHospital = true }
          if (this.APIresult[0].life[0].hdp == "True") { this.DoLife = false } else { this.DoLife = true }

          this.chstate = this.APIresult[0].user[0].state;

          //    this.APIresult = Array.of(myJson);

          if (this.APIresult[0].success == "1") {
            //Check Does and Donts
            //check if dental
            try {
              this.DentalQuote = this.APIresult[0].dental[0].silver;
              if (this.TryParse(sessionStorage.getItem('dental'))) {
                this.colorDentalQuote = "selected"
                var DENTALJSON = Array.of(JSON.parse(sessionStorage.getItem('dental')))
                this.DentalQuote = parseFloat(DENTALJSON[0].amount).toFixed(2);
                this.Total = this.Total + DENTALJSON[0].amount
                this.legendDental = DENTALJSON[0].planname;
                this.buttonDental = "Change Plan"
                this.deleteDental = true;
                this.TotalProducts = this.TotalProducts + 1;
                if (DENTALJSON[0].isgroup == "1") { this.groupplan = "1" }

              } else {

                this.DentalQuote = this.APIresult[0].dental[0].silver;
                this.colorDentalQuote = "unselected"
                this.iconDental = "fa-procedures"
                this.deleteDental = false;
                this.legendDental = "Plans starting at"
              }
            }
            catch (e) { }



            //check FOR MEMBERSHIP
            try {
              this.MembershipQuote = this.APIresult[0].membership[0].fee;
              if (this.TryParse(sessionStorage.getItem('membership'))) {
                this.colorMembershipQuote = "benefitbox2"
                var MEMBERSHIPJSON = Array.of(JSON.parse(sessionStorage.getItem('membership')))
                this.MembershipQuote = parseFloat(MEMBERSHIPJSON[0].amount).toFixed(2);

                this.Total = this.Total + MEMBERSHIPJSON[0].amount
                this.legendMembership = MEMBERSHIPJSON[0].planname;
                this.buttonMembership = "Change Plan"
                this.deleteMembership = true;
                this.showDeleteMembership = false;
                this.TotalProducts = this.TotalProducts + 1;

              } else {

                this.MembershipQuote = this.APIresult[0].membership[0].fee;
                this.colorMembershipQuote = "benefitbox"
                this.legendMembership = "Plans starting at"
                this.buttonMembership = "Learn More"
                this.deleteMembership = false;
                this.showDeleteMembership = true;
              }
            } catch (e) {
            }


            //check if MEdical
            try {
              this.MedicalQuote = this.APIresult[0].medical250[0].plan1;

              if (this.TryParse(sessionStorage.getItem('medical'))) {
                this.colorMedicalQuote = "selected"
                var MEDICALJSON = Array.of(JSON.parse(sessionStorage.getItem('medical')))
                this.MedicalQuote = parseFloat(MEDICALJSON[0].amount).toFixed(2);

                this.Total = this.Total + MEDICALJSON[0].amount
                this.legendMedical = MEDICALJSON[0].planname;
                this.buttonMedical = "Change Plan"
                this.deleteMedical = true;
                this.TotalProducts = this.TotalProducts + 1;
                if (MEDICALJSON[0].isgroup == "1") { this.groupplan = "1" }
              } else {

                this.MedicalQuote = this.APIresult[0].medical250[0].plan1;
                this.colorMedicalQuote = "unselected"
                this.iconMedical = "fa-notes-medical"
                this.deleteMedical = false;
                this.legendMedical = "Plans starting at"
              }
            } catch (e) { }




            //hospital
            try {
              if (this.APIresult[0].hospital[0].hasOwnProperty('plan1')) {
                this.HospitalQuote = this.APIresult[0].hospital[0].plan1;
                if (this.TryParse(sessionStorage.getItem('hospital'))) {
                  this.colorHospitalQuote = "selected"
                  var HOSPITALJSON = Array.of(JSON.parse(sessionStorage.getItem('hospital')))
                  this.HospitalQuote = parseFloat(HOSPITALJSON[0].amount).toFixed(2);
                  this.Total = this.Total + HOSPITALJSON[0].amount
                  this.iconHospital = "fa-thermometer-half"
                  this.legendHospital = HOSPITALJSON[0].planname;
                  this.buttonHospital = "Change Plan"
                  this.deleteHospital = true;
                  this.TotalProducts = this.TotalProducts + 1;
                  if (HOSPITALJSON[0].isgroup == "1") { this.groupplan = "1" }
                } else {

                  this.HospitalQuote = this.APIresult[0].hospital[0].plan1;
                  this.colorHospitalQuote = "unselected"
                  this.iconHospital = "fa-notes-medical"
                  this.deleteHospital = false;
                  this.legendHospital = "Plans starting at"
                }
              }
            } catch (e) {
            }

            //critical
            try {
              this.CriticalQuote = this.APIresult[0].critical[0].plan1;
              if (this.TryParse(sessionStorage.getItem('critical'))) {
                this.colorCriticalQuote = "selected"
                var CRITICALJSON = Array.of(JSON.parse(sessionStorage.getItem('critical')))
                this.CriticalQuote = parseFloat(CRITICALJSON[0].amount).toFixed(2);
                this.Total = this.Total + CRITICALJSON[0].amount
                this.legendCritical = CRITICALJSON[0].planname;
                this.buttonCritical = "Change Plan"
                this.deleteCritical = true;
                this.TotalProducts = this.TotalProducts + 1;
                if (CRITICALJSON[0].isgroup == "1") { this.groupplan = "1" }
              } else {

                this.CriticalQuote = this.APIresult[0].critical[0].plan1;
                this.colorCriticalQuote = "unselected"
                this.iconCritical = "fa-procedures"
                this.deleteCritical = false;
                this.legendCritical = "Plans starting at"
              }
            } catch (e) {
            }


            //life25
            this.Life75Quote = this.APIresult[0].life[0].plan75premium;
            this.colorLife75Quote = "unselected"
            this.iconLife75 = " fa-life-ring"
            this.deleteLife75 = false;
            this.legendLife75 = "Plans starting at"
            this.Life25Quote = this.APIresult[0].life[0].plan75premium;
            this.iconLife25 = " fa-life-ring"
            this.deleteLife25 = false;
            this.legendLife25 = "Plans starting at"
            try {
              this.Life25Quote = this.APIresult[0].life[0].plan25premium;
              if (this.TryParse(sessionStorage.getItem('life25'))) {
                this.colorLife75Quote = "selected"
                var LIFE25JSON = Array.of(JSON.parse(sessionStorage.getItem('life25')))
                this.Life25Quote = parseFloat(LIFE25JSON[0].amount).toFixed(2);
                this.Total = this.Total + LIFE25JSON[0].amount
                this.legendLife25 = LIFE25JSON[0].planname;
                this.buttonLife25 = "Change Plan"
                this.deleteLife25 = true;
                this.lifeplan = "1";
                this.TotalProducts = this.TotalProducts + 1;
              } else {
                if (sessionStorage.getItem("greenlife") == "25") {
                  sessionStorage.removeItem("greenlife");
                }

              }
            } catch (e) { }

            //life75
            try {
              this.Life75Quote = this.APIresult[0].life[0].plan75premium;
              if (this.TryParse(sessionStorage.getItem('life75'))) {
                this.colorLife75Quote = "selected"
                var Life75JSON = Array.of(JSON.parse(sessionStorage.getItem('life75')))
                this.Life75Quote = parseFloat(Life75JSON[0].amount).toFixed(2);
                this.Total = this.Total + Life75JSON[0].amount
                this.legendLife75 = Life75JSON[0].planname;
                this.buttonLife75 = "Change Plan"
                this.deleteLife75 = true;
                this.lifeplan = "1";
                this.TotalProducts = this.TotalProducts + 1;
              } else {
                if (sessionStorage.getItem("greenlife") == "75") {
                  sessionStorage.removeItem("greenlife");
                }

              }
            } catch (e) { }

            this.ShowCart = false;
            if (this.deleteCritical) { this.ShowCart = true };
            if (this.deleteMedical) { this.ShowCart = true };
            if (this.deleteHospital) { this.ShowCart = true };
            if (this.deleteDental) { this.ShowCart = true };
            if (this.deleteLife25) { this.ShowCart = true };
            if (this.deleteLife75) { this.ShowCart = true };
            if (this.deleteMembership) { this.ShowCart = true };



            this.TotalToDisplay = this.Total.toFixed(2);
          }


        }
        else {
          sessionStorage.removeItem("sessionkey");
          Swal.fire('Manual Key Modification Detected', "IP Address and geolocation recorded for further investigation.", 'error');
          this._router.navigate(['../default'], { relativeTo: this.r });
          return;
        }
        error => {
          this.ShowCart = false;

          Swal.fire('We cannot process your quote', "We appologize, but we cannot retrieve your quote now. Please try again later.", 'error');
          return;
        }
      });











  }



  //delete critical
  DeleteCriticalQuote(): void {

    Swal.fire({
      title: 'Are you sure?',
      text: "Are you sure you want to delete this product from your cart?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.removeItem('critical');
        this.CriticalQuote = this.APIresult[0].critical[0].plan1;
        this.colorCriticalQuote = "unselected"
        this.iconCritical = "fa-procedures"
        this.deleteCritical = false;
        this.legendCritical = "Plans starting at"
        this.buttonCritical = "Learn More"
        sessionStorage.removeItem("greencritical");
        this.Calculate();
        this.toastr.warning('Plan deleted from your cart!', 'Plan Deleted');
        // Swal.fire({title: 'Plan Deleted!', timer: 1000, icon: 'success', showConfirmButton: false, showCancelButton: false, })

      }
    })
  }


  //delete medical
  DeleteMedicalQuote(): void {

    Swal.fire({
      title: 'Are you sure?',
      text: "Are you sure you want to delete this product from your cart?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.removeItem('medical');
        this.MedicalQuote = this.APIresult[0].medical250[0].plan1;
        this.colorMedicalQuote = "unselected"
        this.iconMedical = "fa-notes-medical"
        this.deleteMedical = false;
        this.legendMedical = "Plans starting at"
        this.buttonMedical = "Learn More"
        sessionStorage.removeItem("greenmedical");
        this.Calculate();
        this.toastr.warning('Plan deleted from your cart!', 'Plan Deleted');
        // Swal.fire({title: 'Plan Deleted!', timer: 1000, icon: 'success', showConfirmButton: false, showCancelButton: false, })

      }
    })



  }



  //delete sickness
  DeleteHospitalQuote(): void {

    Swal.fire({
      title: 'Are you sure?',
      text: "Are you sure you want to delete this product from your cart?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.removeItem('hospital');
        this.HospitalQuote = this.APIresult[0].hospital[0].plan1;
        this.colorHospitalQuote = "unselected"
        this.iconHospital = "fa-thermometer-half"
        this.deleteHospital = false;
        this.legendHospital = "Plans starting at"
        this.buttonHospital = "Learn More"
        sessionStorage.removeItem("greenhospital");
        this.Calculate();
        this.toastr.warning('Plan deleted from your cart!', 'Plan Deleted');
        // Swal.fire({title: 'Plan Deleted!', timer: 1000, icon: 'success', showConfirmButton: false, showCancelButton: false, })

      }
    })
  }


  //delete sickness
  DeleteDentalQuote(): void {

    Swal.fire({
      title: 'Are you sure?',
      text: "Are you sure you want to delete this product from your cart?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.removeItem('dental');
        this.DentalQuote = this.APIresult[0].dental[0].silver;
        this.colorDentalQuote = "unselected"
        this.iconDental = "fa-tooth"
        this.deleteDental = false;
        this.legendDental = "Plans starting at"
        this.buttonDental = "Learn More"
        sessionStorage.removeItem("greendental");
        this.Calculate();
        this.toastr.warning('Plan deleted from your cart!', 'Plan Deleted');
        // Swal.fire({title: 'Plan Deleted!', timer: 1000, icon: 'success', showConfirmButton: false, showCancelButton: false, })

      }
    })
  }




  //delete life25
  DeleteLife25Quote(): void {

    Swal.fire({
      title: 'Are you sure?',
      text: "Are you sure you want to delete this product from your cart?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.removeItem('life25');
        this.Life25Quote = this.APIresult[0].life[0].plan25premium;
        this.colorLife75Quote = "unselected"
        this.iconLife25 = "far fa-life-ring"
        this.deleteLife25 = false;
        this.legendLife25 = "Plans starting at"
        this.buttonLife25 = "Learn More"
        if (sessionStorage.getItem("greenlife") == "25") {
          sessionStorage.removeItem("greenlife");
        }
        this.Calculate();
        this.toastr.warning('Plan deleted from your cart!', 'Plan Deleted');
        // Swal.fire({title: 'Plan Deleted!', timer: 1000, icon: 'success', showConfirmButton: false, showCancelButton: false, })

      }
    })
  }



  //delete life25
  DeleteLife75Quote(): void {

    Swal.fire({
      title: 'Are you sure?',
      text: "Are you sure you want to delete this product from your cart?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.removeItem('life75');
        this.Life75Quote = this.APIresult[0].life[0].plan75premium;
        this.colorLife75Quote = "unselected"
        this.iconLife75 = "fa-life-ring"
        this.deleteLife75 = false;
        this.legendLife75 = "Plans starting at"
        this.buttonLife75 = "Learn More"
        this.Calculate();
        this.toastr.warning('Plan deleted from your cart!', 'Plan Deleted');
        // Swal.fire({title: 'Plan Deleted!', timer: 1000, icon: 'success', showConfirmButton: false, showCancelButton: false, })
        if (sessionStorage.getItem("greenlife") == "75") {
          sessionStorage.removeItem("greenlife");
        }
      }
    })
  }



  //delete life25
  DeleteMembershipQuote(): void {

    Swal.fire({
      title: 'Are you sure?',
      text: "Are you sure you want to delete this product from your cart?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.removeItem('membership');
        this.MembershipQuote = this.APIresult[0].membership[0].fee;
        this.colorMembershipQuote = "benefitbox"
        this.deleteMembership = false;
        this.showDeleteMembership = true;
        this.legendMembership = "Plans starting at"
        this.buttonMembership = "Learn More"
        sessionStorage.removeItem("greenmembership");
        this.Calculate();
        this.toastr.warning('Membership deleted from your cart!', 'Membership Deleted');
        // Swal.fire({title: 'Plan Deleted!', timer: 1000, icon: 'success', showConfirmButton: false, showCancelButton: false, })

      }
    })
  }



  TryParse(item): boolean {
    item = typeof item !== "string"
      ? JSON.stringify(item)
      : item;

    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }

    if (typeof item === "object" && item !== null) {
      return true;
    }

    return false;
  }



  CheckOut(): void {
    if (this.TryParse(sessionStorage.getItem('membership')) == false) {
      Swal.fire({
        title: 'Select a membership plan',
        text: "Select a membership plan before proceeding to checkout.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Select Membership'
      }).then((result) => {
        if (result.isConfirmed) {
          //this._router.navigate(['../buymembership'], { relativeTo: this.r });
          window.scrollTo(0, 0)

        }
      })
      return;
    }
    this._router.navigate(['../application2'], { relativeTo: this.r });


  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }


  startover(): void {
    Swal.fire({
      title: 'Start Over?',
      text: "Are you sure you want to start over with a new enrollment?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Start Over'
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.removeItem("qk")
        sessionStorage.removeItem("state")
        sessionStorage.removeItem("appsessionkey")
        sessionStorage.removeItem('critical');
        sessionStorage.removeItem('dental');
        sessionStorage.removeItem('hospital');
        sessionStorage.removeItem('medical');
        sessionStorage.removeItem('sickness');
        sessionStorage.removeItem('life25');
        sessionStorage.removeItem('life75');

        sessionStorage.removeItem('greenmembership');
        sessionStorage.removeItem('greencritical');
        sessionStorage.removeItem('greendental');
        sessionStorage.removeItem('greenhospital');
        sessionStorage.removeItem('greenmedical');
        sessionStorage.removeItem('greensickness');
        sessionStorage.removeItem('greenlife');


        sessionStorage.removeItem('membership');
        this.ShowCart = false;
        this.ShowEnroll = false;
      }
    })


  }







  DeleteChildden(i: number): void {
    Swal.fire({
      title: 'Delete Dependent?',
      text: "Do you want to delete this dependent?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, Delete',
      cancelButtonText: 'No, Keep It'
    }).then((result) => {
      if (result.isConfirmed) {
        this.PayLoad.DependentDemographics.splice(i, 1);
      }
    })

  }


  DeleteBeneficiary(i: number): void {
    Swal.fire({
      title: 'Delete Beneficiary?',
      text: "Do you want to delete this beneficiary?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, Delete',
      cancelButtonText: 'No, Keep It'
    }).then((result) => {
      if (result.isConfirmed) {
        this.PayLoad.Beneficiaries.splice(i, 1);
        this.lisumpercentage = 0;
        this.lirestpercentage = 100;
        for (let result of this.PayLoad.Beneficiaries) {
          if (result.BType == "P") {
            let Allocation: any = result.Allocations;
            let Percent: number = parseInt(Allocation[0].Percentage)
            this.lisumpercentage = this.lisumpercentage + Percent;
          }
        }
        this.lirestpercentage = 100 - this.lisumpercentage + 1;
      }
    })

  }

  DeleteContingentBeneficiary(i: number): void {
    Swal.fire({
      title: 'Delete Contingent Beneficiary?',
      text: "Do you want to delete this beneficiary?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, Delete',
      cancelButtonText: 'No, Keep It'
    }).then((result) => {
      if (result.isConfirmed) {

        this.PayLoad.Beneficiaries.splice(i, 1);
        this.liContsumpercentage = 0;
        this.liContrestpercentage = 100;
        for (let result of this.PayLoad.Beneficiaries) {
          if (result.BType == "C") {
            let Allocation: any = result.Allocations;
            let Percent: number = parseInt(Allocation[0].Percentage)
            this.liContsumpercentage = this.liContsumpercentage + Percent;
          }
        }
        this.liContrestpercentage = 100 - this.liContsumpercentage + 1;
      }
    })

  }

  SaveLifeContingent(): void {

    let newObj = this.USstates.filter((value) => {
      return value.Code.indexOf(this.listate) != -1 ? value : null
    });
    let EMPStateCode: number = newObj[0].Id;
    let EMPStateName: string = newObj[0].Name

    if (this.liContpercentage == 0) {
      Swal.fire('Select a Percentage', "Selected % cannot be zero", 'warning');
      return;
    }
    else {
      let SelPlan
      if (sessionStorage.getItem("greenlife") == "25") {
        SelPlan = "08b159bb-707e-48a4-a7cd-8b3588756528"
      }
      if (sessionStorage.getItem("greenlife") == "75") {
        SelPlan = "5c5ccb7b-9ba9-489c-bb54-064303a4fec3"
      }
      let benef = {
        "BeneficiaryId": "",
        "Type": this.litype,
        "BType": "C",
        "LastName": this.lilastname,
        "FirstName": this.lifirstname,
        "Address1": this.liaddress,
        "City": this.licity,
        "State": EMPStateCode,
        "State_Name": EMPStateName,
        "Zip": this.lizip,
        "Allocations": [{
          "PlanPrincipalId": SelPlan,
          "Percentage": this.liContpercentage,
          "BeneType": 2
        }]
      }

      this.PayLoad.Beneficiaries.push(benef);
      this.ShowAddContingentBeneficiary = false;
      this.lipercentage = 0;
      this.litype = "Person";
      this.lifirstname = "";
      this.lilastname = "";
      this.liaddress = "";
      this.licity = "";
      this.lizip = "";
      this.liContsumpercentage = 0;
      this.liContrestpercentage = 100;
      for (let result of this.PayLoad.Beneficiaries) {
        if (result.BType == "C") {
          let Allocation: any = result.Allocations;
          let Percent: number = parseInt(Allocation[0].Percentage)
          this.liContsumpercentage = this.liContsumpercentage + Percent;
        }
      }
      this.liContrestpercentage = 100 - this.liContsumpercentage + 1;
    }

  }

  SaveLifePrimary(): void {

    let newObj = this.USstates.filter((value) => {
      return value.Code.indexOf(this.listate) != -1 ? value : null
    });
    let EMPStateCode: number = newObj[0].Id;
    let EMPStateName: string = newObj[0].Name

    if (this.lipercentage == 0) {
      Swal.fire('Select a Percentage', "Selected % cannot be zero", 'warning');
      return;
    }
    else {
      let SelPlan
      if (sessionStorage.getItem("greenlife") == "25") {
        SelPlan = "08b159bb-707e-48a4-a7cd-8b3588756528"
      }
      if (sessionStorage.getItem("greenlife") == "75") {
        SelPlan = "5c5ccb7b-9ba9-489c-bb54-064303a4fec3"
      }
      let benef = {
        "BeneficiaryId": "",
        "Type": this.litype,
        "BType": "P",
        "LastName": this.lilastname,
        "FirstName": this.lifirstname,
        "Address1": this.liaddress,
        "City": this.licity,
        "State": EMPStateCode,
        "State_Name": EMPStateName,
        "Zip": this.lizip,
        "Allocations": [{
          "PlanPrincipalId": SelPlan,
          "Percentage": this.lipercentage,
          "BeneType": 1
        }]
      }

      this.PayLoad.Beneficiaries.push(benef);
      this.ShowAddBeneficiary = false;
      this.lipercentage = 0;
      this.litype = "Person";
      this.lifirstname = "";
      this.lilastname = "";
      this.liaddress = "";
      this.licity = "";
      this.lizip = "";
      this.lisumpercentage = 0;
      this.lirestpercentage = 100;
      for (let result of this.PayLoad.Beneficiaries) {
        if (result.BType == "P") {
          let Allocation: any = result.Allocations;
          let Percent: number = parseInt(Allocation[0].Percentage)
          this.lisumpercentage = this.lisumpercentage + Percent;
        }
      }
      this.lirestpercentage = 100 - this.lisumpercentage + 1;
    }

  }

  SaveChildren(type: string): void {
    if (this.chrelationship == "Spouse" || this.chrelationship == "Domestic Partner" || this.chrelationship == "Spouse Divorced") {

      var HasSpouse: boolean = false;
      for (let result of this.PayLoad.DependentDemographics) {
        if (result.Relationship == 'Spouse') { HasSpouse = true };
        if (result.Relationship == 'Domestic Partner') { HasSpouse = true };
        if (result.Relationship == 'Spouse Divorced') { HasSpouse = true };
      }

      if (HasSpouse == true) {
        Swal.fire('You already entered a spouse/partner', "You already entered a spouse/partner dependent. Please check your entry.", 'error');
        this.ShowAddCWife = false
        this.ShowAddChildren = false;
        this.ShowAddCWife = false;


        this.chrelationship = "";
        this.chfirstname = "";
        this.chlastname = "";
        this.chmiddlename = "";
        this.chgender = "";
        this.chdob = "";
        this.chssn = "";
        this.chphone = "";
        this.chemail = "";
        this.chaddress = "";
        this.chaddress2 = "";
        this.chcity = "";
        this.chzip = "";

        this.chdisabled = "";


        return;
      }

    }
    if (!this.chrelationship) { Swal.fire({ title: 'Enter Relationship', text: 'Enter all mandatory fields', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (!this.chfirstname) { Swal.fire({ title: 'Enter First Name', text: 'Enter all mandatory fields', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (!this.chlastname) { Swal.fire({ title: 'Enter Last Name', text: 'Enter all mandatory fields', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (!this.chgender) { Swal.fire({ title: 'Select Gender', text: 'Enter all mandatory fields', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (!this.IsDate(this.chdob)) { Swal.fire({ title: 'Enter Date of Birth', text: 'Enter all mandatory fields', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (type == "S") {
      if (this.chssn.length != 11) { Swal.fire({ title: 'Enter A Valid SSN', text: 'Enter all mandatory fields', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    }

    if (!this.chssn) { Swal.fire({ title: 'Enter SSN', text: 'Enter all mandatory fields', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (!this.chdisabled) { Swal.fire({ title: 'Indicate if Disabled', text: 'Enter all mandatory fields', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (!this.chaddress) { Swal.fire({ title: 'Enter Address', text: 'Enter all mandatory fields', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (!this.chcity) { Swal.fire({ title: 'Enter City', text: 'Enter all mandatory fields', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (!this.chstate) { Swal.fire({ title: 'Enter State', text: 'Enter all mandatory fields', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (!this.chzip) { Swal.fire({ title: 'Enter ZIP Code', text: 'Enter all mandatory fields', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (this.isNumber(this.chzip) == false) { Swal.fire({ title: 'Enter A Valid US ZIP Code', text: 'Enter all mandatory fields', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (!this.chphone) { Swal.fire({ title: 'Enter Phone Number', text: 'Enter all mandatory fields', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }
    if (this.chphone.length != 14) { Swal.fire({ title: 'Enter A Valid US Phone Number', text: 'Enter all mandatory fields', icon: 'warning', timer: 2000, showConfirmButton: false }); return; }

    if (type == "C") {
      var Age = this.CalculateAge(this.chdob)
      if (this.chdisabled == "N" && Age > 26) {
        Swal.fire('You cannot add ' + this.chfirstname, this.chfirstname + " cannot be added to your plan, since children should be 26 years of age or younger", 'error');
        return
      }
      if (this.chdisabled == "Y" && Age > 65) {
        Swal.fire('You cannot add ' + this.chfirstname, this.chfirstname + " cannot be added to your plan, since disabled children should be 65 years of age or younger", 'error');
        return
      }

    }


    let newObj = this.USstates.filter((value) => {
      return value.Code.indexOf(this.chstate) != -1 ? value : null
    });
    let EMPStateCode: number = newObj[0].Id;
    let EMPStateName: string = newObj[0].Name


    let IsDisabled: boolean
    if (this.chdisabled == "Y") { IsDisabled = true } else { IsDisabled = false }
    let dep = {
      "AFEtype": type,
      "PersonCode": "",
      "ActionType": "ADD",
      "ESSN": "",
      "SSN": this.chssn,
      "FirstName": this.chfirstname,
      "LastName": this.chlastname,
      "MI": this.chmiddlename,
      "BirthDate": this.chdob,
      "Gender": this.chgender,
      "Relationship": this.chrelationship,
      "Address1": this.chaddress,
      "Address2": this.chaddress2,
      "City": this.chcity,
      "State": EMPStateCode,
      "State_Name": EMPStateName,
      "Zip": this.chzip,
      "Country": "US",
      "Phone": this.chphone,
      "Email": this.chemail,
      "IsDisabled": IsDisabled,

    }

    //marks spouse as added
    //if (type == "S") { this.spadded = true };

    this.PayLoad.DependentDemographics.push(dep);
    //this.children.push(child);
    this.ShowAddChildren = false;
    this.ShowAddCWife = false;


    this.chrelationship = "";
    this.chfirstname = "";
    this.chlastname = "";
    this.chmiddlename = "";
    this.chgender = "";
    this.chdob = "";
    this.chssn = "";
    this.chphone = "";
    this.chemail = "";
    this.chaddress = "";
    this.chaddress2 = "";
    this.chcity = "";
    this.chzip = "";

    this.chdisabled = "";




  }




  //check a date
  IsDate(mydate: string): boolean {
    var isdate = Date.parse(mydate);
    if (isNaN(isdate)) {
      return false;
    }

    var EnteredDate = new Date(isdate)
    var TodayIs = new Date()
    if (EnteredDate > TodayIs) {
      return false;
    }
    var Difference_In_Time = EnteredDate.getTime() - new Date().getTime();
    var Difference_In_Days = ((Difference_In_Time / (1000 * 3600 * 24)) / 365) * (-1);
    if (Difference_In_Days > 100) {
      return false;
    }
    return true;
  }

  CalculateAge(mydate: string): number {
    var isdate = Date.parse(mydate);
    if (isNaN(isdate)) {
      return 999;
    }

    var EnteredDate = new Date(isdate)
    var TodayIs = new Date()
    if (EnteredDate > TodayIs) {
      return 999;
    }
    var Difference_In_Time = EnteredDate.getTime() - new Date().getTime();
    var Difference_In_Days = ((Difference_In_Time / (1000 * 3600 * 24)) / 365) * (-1);

    return Difference_In_Days;
  }


  isNumber(value: string | number): boolean {
    return ((value != null) &&
      (value !== '') &&
      !isNaN(Number(value.toString())));
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }






  CheckStep1(): void {
    if (!this.firstname) { Swal.fire({ title: 'Enter First Name', text: 'Enter all mandatory fields', icon: 'warning', timer: 2000, showConfirmButton: false }); this.AllFine = false; return; }
    if (!this.lastname) { Swal.fire({ title: 'Enter Last Name', text: 'Enter all mandatory fields', icon: 'warning', timer: 2000, showConfirmButton: false }); this.AllFine = false; return; }
    if (!this.address) { Swal.fire({ title: 'Enter Your Address', text: 'Enter all mandatory fields', icon: 'warning', timer: 2000, showConfirmButton: false }); this.AllFine = false; return; }
    if (!this.city) { Swal.fire({ title: 'Enter Your City', text: 'Enter all mandatory fields', icon: 'warning', timer: 2000, showConfirmButton: false }); this.AllFine = false; return; }
    if (!this.state) { Swal.fire({ title: 'Enter Your State', text: 'Enter all mandatory fields', icon: 'warning', timer: 2000, showConfirmButton: false }); this.AllFine = false; return; }
    if (!this.zip) { Swal.fire({ title: 'Enter Your ZIP Code', text: 'Enter all mandatory fields', icon: 'warning', timer: 2000, showConfirmButton: false }); this.AllFine = false; return; }
    if (this.isNumber(this.zip) == false) { Swal.fire({ title: 'Enter A Valid US ZIP Code', text: 'Enter all mandatory fields', icon: 'warning', timer: 2000, showConfirmButton: false }); this.AllFine = false; return; }
    if (!this.phone) { Swal.fire({ title: 'Enter Your Phone Number', text: 'Enter all mandatory fields', icon: 'warning', timer: 2000, showConfirmButton: false }); this.AllFine = false; return; }
    if (this.phone.length != 14) { Swal.fire({ title: 'Enter A Valid US Phone Number', text: 'Enter all mandatory fields', icon: 'warning', timer: 2000, showConfirmButton: false }); this.AllFine = false; return; }
    if (!this.gender) { Swal.fire({ title: 'Select Your Gender', text: 'Enter all mandatory fields', icon: 'warning', timer: 2000, showConfirmButton: false }); this.AllFine = false; return; }
    if (!this.ssn) { Swal.fire({ title: 'Enter Your SSN', text: 'Enter all mandatory fields', icon: 'warning', timer: 2000, showConfirmButton: false }); this.AllFine = false; return; }
    if (this.ssn.length != 11) { Swal.fire({ title: 'Enter A Valid SSN', text: 'Enter all mandatory fields', icon: 'warning', timer: 2000, showConfirmButton: false }); this.AllFine = false; return; }
    if (!this.marritalstatus) { Swal.fire({ title: 'Enter Your Marrital Status', text: 'Enter all mandatory fields', icon: 'warning', timer: 2000, showConfirmButton: false }); this.AllFine = false; return; }
    if (!this.validateEmail(this.email)) { Swal.fire({ title: 'Enter a Valid Email Address', text: 'Enter all mandatory fields', icon: 'warning', timer: 2000, showConfirmButton: false }); this.AllFine = false; return; }
    if (!this.upassword) { Swal.fire({ title: 'Enter Password', text: 'Enter the password you want to utilize to access your account', icon: 'warning', timer: 2000, showConfirmButton: false }); this.AllFine = false; return; }
    if (this.upassword.length < 8) { Swal.fire({ title: 'Enter Password', text: 'Your password should be at least 8 characters long', icon: 'warning', timer: 2000, showConfirmButton: false }); this.AllFine = false; return; }
    if (this.upassword != this.upassword2) { Swal.fire({ title: 'Password Do Not Match', text: 'Your password and password confirmation do not match', icon: 'warning', timer: 2000, showConfirmButton: false }); this.AllFine = false; return; }
    if (!this.mothersmaiden) { Swal.fire({ title: "Enter Mother's Maiden Name", text: 'Enter all mandatory fields', icon: 'warning', timer: 2000, showConfirmButton: false }); this.AllFine = false; return; }


  }

  CheckStep2(): void {
    if (Object.keys(this.PayLoad.DependentDemographics).length == 0) {
      Swal.fire({ title: 'Enter Family Members', text: 'You selected group products that cover your wife and/or children. Please enter your family members to be covered by these products.', icon: 'warning', timer: 4000 });
      this.AllFine = false;
      return;
    }



  }

  CheckStep3(): void {
    if (Object.keys(this.PayLoad.Beneficiaries).length == 0) {
      Swal.fire({ title: 'Enter Beneficiaries', text: 'You selected a product (Life Insurance) that require you to enter your beneficiaries.', icon: 'warning', timer: 4000 });
      return;
    }

    if (this.lisumpercentage != 100) {
      Swal.fire({ title: 'Enter all primary beneficiaries', text: 'The sumatory of the % assigned to each primary beneficiary must equal 100%.', icon: 'warning', timer: 4000 });
      return;
    }
    if (this.liContsumpercentage != 100 && this.liContsumpercentage != 0) {
      Swal.fire({ title: 'Enter contingent beneficiaries', text: 'The sumatory of the % assigned to each contingent beneficiary must equal 100%.', icon: 'warning', timer: 4000 });
      return;
    }





  }



  Finish(): void {

    this.AllFine = true;
    this.CheckStep1()

    if (this.AllFine != true) {
      return;
    }
    if (this.groupplan == "1") {
      this.CheckStep2()
      if (!this.AllFine == true) {
        return;
      }
    }

    if (this.lifeplan == "1") {
      this.CheckStep3()
      if (!this.AllFine == true) {
        return;
      }
    }




    let newObj = this.USstates.filter((value) => {
      return value.Code.indexOf(this.state) != -1 ? value : null
    });
    let EMPStateCode: number = newObj[0].Id;
    let EMPStateName: string = newObj[0].Name




    this.ShowSpinner = true;


    // If all fine


    //REdo Beneficiaries for PERSONCODE
    let myindex: number = 0;
    for (let result of this.PayLoad.Beneficiaries) {
      myindex = myindex + 1;
      let stringindex: string = "0" + myindex;
      stringindex = stringindex.slice(stringindex.length - 2, stringindex.length) //right 2 characters
      result.BeneficiaryId = stringindex;
      let Allocation: any = result.Allocations;
      Allocation[0].BeneficiaryId = stringindex;
    }
    //redo Dependents for PERSONCODE
    myindex = 0;
    for (let result of this.PayLoad.DependentDemographics) {
      myindex = myindex + 1;
      let stringindex: string = "0" + myindex;
      stringindex = stringindex.slice(stringindex.length - 2, stringindex.length) //right 2 characters
      result.PersonCode = stringindex;
    }

    //find state

 this.ShowSpinner = false;
    this.IsProcessing = true;

    //Member Information
    this.PayLoad.ClientPrincipalId = sessionStorage.getItem('ClientPrincipalID');
    this.PayLoad.AgentPrincipalId = sessionStorage.getItem('AgentPrincipalId');
    this.PayLoad.EmpDemographics.ESSN = this.ssn;
    this.PayLoad.EmpDemographics.FirstName = this.firstname;
    this.PayLoad.EmpDemographics.LastName = this.lastname;
    //this.PayLoad.EmpDemographics.MI = this.middlename.substr(0, 1);
    this.PayLoad.EmpDemographics.BirthDate = this.dob;
    this.PayLoad.EmpDemographics.Gender = this.gender;
    this.PayLoad.EmpDemographics.Address1 = this.address;
    this.PayLoad.EmpDemographics.Address2 = this.address2;
    this.PayLoad.EmpDemographics.City = this.city;
    this.PayLoad.EmpDemographics.State = EMPStateCode;
    this.PayLoad.EmpDemographics.State_Name = EMPStateName;
    this.PayLoad.EmpDemographics.Zip = this.zip;
    this.PayLoad.EmpDemographics.Phone = this.phone;
    this.PayLoad.EmpDemographics.Email = this.email;
    this.PayLoad.EmpDemographics.Location = this.state;
    this.PayLoad.EmpDemographics.Area = this.zip.substr(0, 3)
    this.PayLoad.EmpDemographics.MothersMaidenName=this.mothersmaiden;
    this.PayLoad.RegistrationDetails.UserName = this.email;
    this.PayLoad.RegistrationDetails.Password = this.upassword;
    this.PayLoad.RegistrationDetails.AcknowledgementSignature = "";


    //delete all product selections
    this.PayLoad.ProductSelections.splice(0, 100);

    //PRODUCTS

    //****************** */
    //membership
    //****************** */
    let product: string;
    let SelPlan: string;
    let SelTier: string;
    let memProduct: number;

    if (sessionStorage.getItem("greenmembership")) {
      product = sessionStorage.getItem("greenmembership")

      //TIER
      memProduct = 11423
      if (product == "1") { SelPlan = "3d29a01b-b38d-45b3-9a26-0ac306a51bf8" };
      if (product == "7") { SelPlan = "fe794017-a014-4720-bab2-5662e3af1202" };

      let productJson =
      {
        "ProductId": memProduct,
        "SelectedPlanId": SelPlan,
        "SelectedTierCode": "IND",
        "EffectiveDate": "2021-02-14T18:58:24.6894238-05:00",
        "MemberElections": [
          {
            "PersonCode": "00",
            "CurrentlyElecting": true,
          }
        ]
      }

      for (let result of this.PayLoad.DependentDemographics) {
        let MemElectNew = {
          "PersonCode": "",
          "CurrentlyElecting": true,
        }
        //if individual + 1
        if (SelTier == "IND+1") {
          if (result.AFEtype == "S") {
            MemElectNew.PersonCode = result.PersonCode;
            productJson.MemberElections.push(MemElectNew)
          }
        }
        //if family
        if (SelTier == "FAM") {
          MemElectNew.PersonCode = result.PersonCode;
          productJson.MemberElections.push(MemElectNew)
        }
      }
      this.PayLoad.ProductSelections.push(productJson)
    }

    //****************** */
    //dental
    //****************** */

    if (sessionStorage.getItem("greendental")) {
      product = sessionStorage.getItem("greendental").substr(0, 1)
      SelPlan = sessionStorage.getItem("greendental").slice(sessionStorage.getItem("greendental").length - 1, sessionStorage.getItem("greendental").length)

      //TIER
      if (product == "1") { SelTier = "IND" };
      if (product == "2") { SelTier = "IND+1" };
      if (product == "3") { SelTier = "FAM" };
      //PLAN
      if (SelPlan == "1") { SelPlan = "0bcf0ac5-54fd-4ff0-958a-b01235ad83b9" }
      if (SelPlan == "2") { SelPlan = "7628d02d-a41d-4022-bd96-a68fc7c04acf" }
      if (SelPlan == "3") { SelPlan = "d8d9e6ae-44ad-433d-89cc-4c7ad5e44a53" }
      let productJson =
      {
        "ProductId": 1109,
        "SelectedPlanId": SelPlan,
        "SelectedTierCode": SelTier,
        "EffectiveDate": "2021-02-14T18:58:24.6894238-05:00",
        "MemberElections": [
          {
            "PersonCode": "00",
            "CurrentlyElecting": true,
          }
        ]
      }

      for (let result of this.PayLoad.DependentDemographics) {
        let MemElectNew = {
          "PersonCode": "",
          "CurrentlyElecting": true,
        }
        //if individual + 1
        if (SelTier == "IND+1") {
          if (result.AFEtype == "S") {
            MemElectNew.PersonCode = result.PersonCode;
            productJson.MemberElections.push(MemElectNew)
          }
        }
        //if family
        if (SelTier == "FAM") {
          MemElectNew.PersonCode = result.PersonCode;
          productJson.MemberElections.push(MemElectNew)
        }
      }
      this.PayLoad.ProductSelections.push(productJson)
    }

     //critical
     if (sessionStorage.getItem("greencritical")) {
      product = sessionStorage.getItem("greencritical").substr(0, 1)
      SelPlan = sessionStorage.getItem("greencritical").slice(sessionStorage.getItem("greencritical").length - 1, sessionStorage.getItem("greencritical").length)
      if (product == "1") { this.MemberShowCritical = "IND" };
      if (product == "2") { this.MemberShowCritical = "ISP" };
      if (product == "3") { this.MemberShowCritical = "ICH" };
      if (product == "4") { this.MemberShowCritical = "FAM" };

      //TIER
      if (product == "1") { SelTier = "IND" };
      if (product == "2") { SelTier = "ISP" };
      if (product == "3") { SelTier = "ICH" };
      if (product == "4") { SelTier = "FAM" };
      //PLAN
      if (SelPlan == "1") { SelPlan = "4c129a66-71fe-427d-b655-9e90ac41bfde" }
      if (SelPlan == "2") { SelPlan = "20abab2c-64cf-4806-bcf6-9129defd16e6" }
      if (SelPlan == "3") { SelPlan = "dda95996-ab6c-4eae-800f-b84ea9e14c12" }
      if (SelPlan == "4") { SelPlan = "a45fcdd5-cec5-44a0-b751-721a6e4eadcc" }
      let productJson =
      {
        "ProductId": 11413,
        "SelectedPlanId": SelPlan,
        "SelectedTierCode": SelTier,
        "EffectiveDate": "2021-02-14T18:58:24.6894238-05:00",
        "MemberElections": [
          {
            "PersonCode": "00",
            "CurrentlyElecting": true,
          }
        ]
      }
      for (let result of this.PayLoad.DependentDemographics) {
        let MemElectNew = {
          "PersonCode": "",
          "CurrentlyElecting": true,
        }
        //if spouse
        if (SelTier == "ISP") {
          if (result.AFEtype == "S") {
            MemElectNew.PersonCode = result.PersonCode;
            productJson.MemberElections.push(MemElectNew)
          }
        }
        //if children
        if (SelTier == "ICH") {
          if (result.AFEtype == "C") {
            MemElectNew.PersonCode = result.PersonCode;
            productJson.MemberElections.push(MemElectNew)
          }
        }
        //if family
        if (SelTier == "FAM") {
          MemElectNew.PersonCode = result.PersonCode;
          productJson.MemberElections.push(MemElectNew)
        }
      }
      this.PayLoad.ProductSelections.push(productJson)
    }

    //AME
    if (sessionStorage.getItem("greenmedical")) {
      let HCIproductid: number
      product = sessionStorage.getItem("greenmedical").substr(0, 1)
      SelPlan = sessionStorage.getItem("greenmedical").slice(sessionStorage.getItem("greenmedical").length - 1, sessionStorage.getItem("greenmedical").length)

      if (product == "1" || product == "5") { this.MemberShowMedical = "IND" };
      if (product == "2" || product == "6") { this.MemberShowMedical = "ISP" };
      if (product == "3" || product == "7") { this.MemberShowMedical = "ICH" };
      if (product == "4" || product == "8") { this.MemberShowMedical = "FAM" };

      //TIER
      if (product == "1") { SelTier = "IND" };
      if (product == "2") { SelTier = "ISP" };
      if (product == "3") { SelTier = "ICH" };
      if (product == "4") { SelTier = "FAM" };
      if (product == "5") { SelTier = "IND" };
      if (product == "6") { SelTier = "ISP" };
      if (product == "7") { SelTier = "ICH" };
      if (product == "8") { SelTier = "FAM" };

      if (product=="5" || product == "6" || product == "7" || product == "8") {
        if (SelPlan=="1") {SelPlan="5"}
        if (SelPlan=="2") {SelPlan="6"}
        if (SelPlan=="3") {SelPlan="7"}
        if (SelPlan=="4") {SelPlan="8"}
      }

      //PLAN
      if (SelPlan == "1") { SelPlan = "c133b6b9-feeb-4e57-9976-45a62b14334b"; HCIproductid = 11424; }
      if (SelPlan == "2") { SelPlan = "f92aabd9-395e-4d82-bc70-9fdd6d14ebb7"; HCIproductid = 11424; }
      if (SelPlan == "3") { SelPlan = "c3096038-e2dc-4390-8b1a-6c9bf699a079"; HCIproductid = 11424; }
      if (SelPlan == "4") { SelPlan = "9be7f470-a2ff-4932-8aa5-fb1c99fe4933"; HCIproductid = 11424; }
      if (SelPlan == "5") { SelPlan = "542c53a7-e8a1-4418-827b-39e3ca5de20c"; HCIproductid = 9544; }
      if (SelPlan == "6") { SelPlan = "da00eb8c-8d56-4809-b79c-b0498026ae13"; HCIproductid = 9544; }
      if (SelPlan == "7") { SelPlan = "37bf3a87-75a3-479d-af44-5437e9dc3e12"; HCIproductid = 9544; }
      if (SelPlan == "8") { SelPlan = "65baef72-bbd7-4f53-b680-436b95db74fc"; HCIproductid = 9544; }

      let productJson =
      {
        "ProductId": HCIproductid,
        "SelectedPlanId": SelPlan,
        "SelectedTierCode": SelTier,
        "EffectiveDate": "2021-02-14T18:58:24.6894238-05:00",
        "MemberElections": [
          {
            "PersonCode": "00",
            "CurrentlyElecting": true,
          }
        ]
      }
      for (let result of this.PayLoad.DependentDemographics) {
        let MemElectNew = {
          "PersonCode": "",
          "CurrentlyElecting": true,
        }
        //if spouse
        if (SelTier == "ISP") {
          if (result.AFEtype == "S") {
            MemElectNew.PersonCode = result.PersonCode;
            productJson.MemberElections.push(MemElectNew)
          }
        }
        //if children
        if (SelTier == "ICH") {
          if (result.AFEtype == "C") {
            MemElectNew.PersonCode = result.PersonCode;
            productJson.MemberElections.push(MemElectNew)
          }
        }
        //if family
        if (SelTier == "FAM") {
          MemElectNew.PersonCode = result.PersonCode;
          productJson.MemberElections.push(MemElectNew)
        }
      }
      this.PayLoad.ProductSelections.push(productJson)
    }

    //sickness 
    if (sessionStorage.getItem("greenhospital")) {
      let HCIproductid: number
      product = sessionStorage.getItem("greenhospital").substr(0, 1)
      SelPlan = sessionStorage.getItem("greenhospital").slice(sessionStorage.getItem("greenhospital").length - 1, sessionStorage.getItem("greenhospital").length)
      if (product == "1" || product == "5") { this.MemberShowHospital = "IND" };
      if (product == "2" || product == "6") { this.MemberShowHospital = "ISP" };
      if (product == "3" || product == "7") { this.MemberShowHospital = "ICH" };
      if (product == "4" || product == "8") { this.MemberShowHospital = "FAM" };

      //TIER
      if (product == "1") { SelTier = "IND" };
      if (product == "2") { SelTier = "ISP" };
      if (product == "3") { SelTier = "ICH" };
      if (product == "4") { SelTier = "FAM" };
      if (product == "5") { SelTier = "IND" };
      if (product == "6") { SelTier = "ISP" };
      if (product == "7") { SelTier = "ICH" };
      if (product == "8") { SelTier = "FAM" };
      //PLAN
      if (SelPlan == "1") { SelPlan = "157bdc47-e512-4f49-aaea-594bd64d08a7"; HCIproductid = 9649; }
      if (SelPlan == "2") { SelPlan = "498d943a-2634-4cf3-9fa6-5138f380e2ec"; HCIproductid = 9649; }
      if (SelPlan == "3") { SelPlan = "9e07ccff-0232-444f-9ba0-4e61ed041365"; HCIproductid = 9649; }
      if (SelPlan == "4") { SelPlan = "edeaf7fa-0069-40eb-bc82-7e6c24a602e8"; HCIproductid = 9649; }
      if (SelPlan == "5") { SelPlan = "73a3e0b1-9a26-43ae-a153-a14b53826292"; HCIproductid = 11432; }
      if (SelPlan == "6") { SelPlan = "5f25b4a5-bb56-4b9f-aa2f-e23aed6bb53d"; HCIproductid = 11432; }
      if (SelPlan == "7") { SelPlan = "db45e260-abc7-48af-9837-c2733059cb09"; HCIproductid = 11432; }
      if (SelPlan == "8") { SelPlan = "5cce1d35-79ec-4b18-9855-8887c805f936"; HCIproductid = 11432; }

      let productJson =
      {
        "ProductId": HCIproductid,
        "SelectedPlanId": SelPlan,
        "SelectedTierCode": SelTier,
        "EffectiveDate": "2021-02-14T18:58:24.6894238-05:00",
        "MemberElections": [
          {
            "PersonCode": "00",
            "CurrentlyElecting": true,
          }
        ]
      }
      for (let result of this.PayLoad.DependentDemographics) {
        let MemElectNew = {
          "PersonCode": "",
          "CurrentlyElecting": true,
        }
        //if spouse
        if (SelTier == "ISP") {
          if (result.AFEtype == "S") {
            MemElectNew.PersonCode = result.PersonCode;
            productJson.MemberElections.push(MemElectNew)
          }
        }
        //if children
        if (SelTier == "ICH") {
          if (result.AFEtype == "C") {
            MemElectNew.PersonCode = result.PersonCode;
            productJson.MemberElections.push(MemElectNew)
          }
        }
        //if family
        if (SelTier == "FAM") {
          MemElectNew.PersonCode = result.PersonCode;
          productJson.MemberElections.push(MemElectNew)
        }
      }
      this.PayLoad.ProductSelections.push(productJson)
    }

    //Life25 
    if (sessionStorage.getItem("greenlife")) {
      let HCIproductid: number
      let SelPlan: string;
      if (sessionStorage.getItem("greenlife") == "25") {
        HCIproductid = 8339
        SelPlan = "08b159bb-707e-48a4-a7cd-8b3588756528"
      }
      if (sessionStorage.getItem("greenlife") == "75") {
        HCIproductid = 8676
        SelPlan = "5c5ccb7b-9ba9-489c-bb54-064303a4fec3"
      }
      let productJson =
      {
        "ProductId": HCIproductid,
        "SelectedPlanId": SelPlan,
        "EffectiveDate": "2021-02-14T18:58:24.6894238-05:00",
        "SelectedTierCode": "IND",
        "MemberElections": [
          {
            "PersonCode": "00",
            "CurrentlyElecting": true,
          }
        ]
      }
      this.PayLoad.ProductSelections.push(productJson)

    }

    //delete from here




    //window.location.href = this.APIresult[0].message


    var APIURL = sessionStorage.getItem('endpoint') + "afeapi/checkout.aspx";    // set header for http call
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    let body = new URLSearchParams();
    body.set('payload', JSON.stringify(this.PayLoad));
    body.set('qk', sessionStorage.getItem('qk'));

    this.httpClient.post(APIURL, body.toString(), options).subscribe(
      result => {

        this.APIresult = Array.of(result);
        if (result == null) {
          Swal.fire('We cannot process your quote', "We appologize, but we cannot retrieve your quote now. Please try again later. ", 'error');
          return;
        }
        if (this.APIresult[0].success == "1") {
          this.ContinuationURL = this.APIresult[0].ContinuationURL;
          this.MemberAccessToken = this.APIresult[0].MemberAccessToken;
          this.HCItoken=this.APIresult[0].HCItoken;
          this.Endpoint=this.APIresult[0].EndPoint
          //window.location.href = this.APIresult[0].message
          if (this.ContinuationURL) {
            
            this.IsProcessing = true; //eliminates the thank you screen
            this.showfinal=true;
            this.ShowSpinner=false
            return;

          }


        }
        if (this.APIresult[0].success == "99") {
          Swal.fire('We cannot process your quote', this.APIresult[0].message, 'error');
          this.ShowSpinner = false;
          return;
        }
      },
      error => {

        Swal.fire('We cannot process your quote', "We appologize, but we cannot retrieve your quote now. Please try again later.", 'error');
        this.ShowSpinner = false;
        return;
      },
      () => {


      });





  }


  ngOnDestroy() { 
    var myobj = document.getElementById("Henry");
    if (myobj) {
      myobj.remove();
    };
 }

  ngOnInit(): void {
    /*
    const source = interval(2000);
    this.subscription = source.subscribe(val => {
      var iframe = document.getElementById("MyIframe");
      if (iframe) {
        var currentURL: string = iframe.getAttribute("src");
        this.counter=this.counter+1
        this.iContent= this.counter + currentURL;
        
      }
    }
    );
    */
    

  }

}