import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-p3',
  templateUrl: './p3.component.html',
  styleUrls: ['./p3.component.css']
})
export class P3Component implements OnInit {
  @Output("Calculate") Calculate: EventEmitter<any> = new EventEmitter();
  @Input() Data: any;
  public matrix: any;
  public matrix2: any;
  public selection: any;

  public SelectedCol: number = 0;
  public SelectedRow: number = 0;
  public SelectedAmount: number = 0
  public SelectedName: string = "";
  public SelectedRowCol: string = "";

  public LightBox: boolean = false;
  public PlanName: string = "";

  //product variables
  public principalSum: string = "";
  public maximumBenefit: string = "";
  public selecteddeductible: string = "";
  public FileCA: string = "";

  constructor(
    private toastr: ToastrService
  ) {


  }

  View(row, col, amount, name, deductible): void {
    this.SelectedCol = col;
    this.SelectedRow = row;
    this.SelectedAmount = amount;
    this.SelectedName = name

    if (col == "1") { this.principalSum = "5,000"; this.maximumBenefit = "2,500" }
    if (col == "2") { this.principalSum = "10,000"; this.maximumBenefit = "5,000" }
    if (col == "3") { this.principalSum = "15,000"; this.maximumBenefit = "7,500" }
    if (col == "4") { this.principalSum = "20,000"; this.maximumBenefit = "10,000" }
    this.selecteddeductible = deductible;


  }

  SetPlan(): void {
    this.SelectedRowCol = this.SelectedRow.toString() + this.SelectedCol.toString()
    this.Data.selections.medical.selection = this.SelectedRow.toString() + this.SelectedCol.toString()
    this.Data.selections.medical.amount = +this.SelectedAmount;
    this.Data.selections.medical.col = this.SelectedCol;
    this.Data.selections.medical.row = this.SelectedRow;
    if (this.SelectedRow >=5) {this.Data.selections.medical.deductible=250} else {this.Data.selections.medical.deductible=0}
    this.Data.selections.medical.name = this.SelectedName;
    this.toastr.success('Plan added to your cart!', 'Plan Added');
    this.Calculate.emit();
    this.LightBox = false;
    console.log(this.Data)
  }

  ngOnInit(): void {
    this.matrix = this.Data.medical0;
    this.matrix2 = this.Data.medical250;
    this.selection = this.Data.selections.medical;
    this.SelectedRowCol = this.Data.selections.medical.row.toString() + this.Data.selections.medical.col.toString()
    if (sessionStorage.getItem("state") == "CA") {
      this.FileCA = "CA";
    }
  }

}
