import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})
export class PasswordComponent implements OnInit {
  oldpassword: string;
  newpassword: string;
  newpassword2: string;
  constructor(
    public httpClient: HttpClient,
  ) { }

  changePassword(): void {
    if(!this.oldpassword){
      Swal.fire({
        title: 'Enter Current Password',
        text: "All fields are mandatory",
        icon: 'warning',
      })
      return;
    }

    if(!this.newpassword){
      Swal.fire({
        title: 'Enter New Password',
        text: "All fields are mandatory",
        icon: 'warning',
      })
      return;
    }

    if(this.newpassword.length < 8){
      Swal.fire({
        title: 'Password Too Short',
        text: "Your password should be at least 8 characters long",
        icon: 'warning',
      })
      return;
    }

    if(this.newpassword != this.newpassword2){
      Swal.fire({
        title: 'Password Do Not Match',
        text: "Your new password and the password confirmation do not match",
        icon: 'error',
      })
      return;
    }


    Swal.fire({
      title: 'Do you want to change your password?',
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: `Yes, Change Password`,
      customClass: {
        cancelButton: 'order-1 right-gap',
        confirmButton: 'order-2',
      }
    }).then((result) => {
      if (result.isConfirmed) {
        var APIURL = sessionStorage.getItem('endpoint') + "/afeapi/desk/setup/changepassword.aspx";    // set header for http call
        let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
        // set body
        let body = new URLSearchParams();
        body.set('plid', encodeURIComponent(sessionStorage.getItem('plid')));
        body.set('userkey', encodeURIComponent(sessionStorage.getItem('userkey')));
        body.set('sessionkey', encodeURIComponent(sessionStorage.getItem('sessionkey')));

        body.set('oldpassword', encodeURIComponent(this.oldpassword));
        body.set('newpassword', encodeURIComponent(this.newpassword));

        this.httpClient.post(APIURL, body.toString(), options).subscribe(
          result => {
            let APIresult: any = Array.of(result);
            if (result == null) {
              Swal.fire({
                title: 'Try again',
                text: 'There was an error connecting to our Endpoint. Please try again later.',
                icon: 'error',
              })
              return;
            }
            else {
              if (APIresult[0].success == "1") {
                Swal.fire('Password Changed', '', 'success');
                this.oldpassword="";
                this.newpassword="";
                this.newpassword2="";
                return;
              }
              else {
                Swal.fire({
                  title: 'Something went wrong...',
                  text: APIresult[0].message,
                  icon: 'error',
    
                })
              }
    
            }
    
          },
          error => {
    
          });
    
        // Additionals


        
      } 
    })

  }
  ngOnInit(): void {
  }

}
