<h4>Enter AFE Policy ID</h4>
<hr>
<br>

AFE Policy ID: <br>
<input type="text" class="form-control" style="width:400px" maxlength="50" [(ngModel)]="PolicyId">
<br>
Superuser Password: <br>
<input type="password" class="form-control" style="width:400px" maxlength="50" [(ngModel)]="Password">
<br>
<button class="btn btn-danger" (click)="DoPay()">Pay and Mark</button>